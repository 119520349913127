import React, {useState, useEffect} from "react";
import {Icon, Tabs} from '../ui';
import {Button, Input} from 'rlabui';
import {Content, ContentBody, ContentFooter, ContentHeader} from "../template/ContentParts";
import Table from "../ui/Table/Table";
import {PINVEST_SLASH, OBJECT_STATE, getUniqueGroups, uploadPIFile, 
	deletePIFileByName} from "./pi_utils";
import {base64ToArrayBuffer} from "../ui/utils/gen_utils";
import {getDropdown} from "../ui/utils/gen_utils";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import {toast} from "react-toastify";
import {piApi, storageApi} from "../../api/api";
import {fullName, isAuth} from "../../helpers/auth";
import {loadPIObjects} from "../../redux/slices/lists";
import {useDispatch, useSelector} from "react-redux";
import {storageClass} from "../../redux/slices/storage";

const PInvestObjectsList = (props) => {
	const [uniqueObjectGroups, setUniqueObjectGroups] = useState([]);
	const [testFilter, setTestFilter] = useState('');
	const [selectedGroupId, setSelectedGroupId] = useState(-1);
	const [filteredObjects, setFilteredObjects] = useState([]);
	const [isUpdated, setIsUpdated] = useState(false);
	//
	const [idBeDeleted, setIdBeDeleted] = useState(undefined);
	const [showConfirmDlg, setShowConfirmDlg] = useState(false);
	const [deletionConfirmed, setDeletionConfirmed] = useState(false);
	//
	const [filesBeCopied, setFilesBeCopied] = useState([]);
	const [objBeCopied, setObjBeCopied] = useState(undefined);
	const [loadedFileName, setLoadedFileName] = useState('');
	const [isLockUE, setIsLockUE] = useState(false);
	const [canDeleteIDs, setCanDeleteIDs] = useState([]);
	const [tableTabInd, setTableTabInd] = useState(isAuth().role < 3 ? OBJECT_STATE.WORK.status : 0);

	const {lists: {pi}} = useSelector(state => state);
	const documentClass = useSelector(storageClass);
	const dispatch = useDispatch();

    useEffect(() => {
		if (!pi.objects) return;
        setUniqueObjectGroups(getUniqueGroups(pi.objects));
    }, [pi.objects]);

	useEffect(() => {
		dispatch(loadPIObjects(documentClass._id));
		if (isUpdated) setIsUpdated(false);
	}, [dispatch, documentClass._id, isUpdated]);

	useEffect(() => {
		let list = pi.objects.map(item => ({
			linkName: item.name,
			type: item.type,
			group: item.group,
			author: fullName(item.owner, 'Шаблон'),
			actions: '',
			id: item._id,
			owner: item.owner
		}))
		.filter((row) => (Object.values(row).slice(0, -1).some(
				v => v.toLowerCase().includes(testFilter.trim().toLowerCase())))
			&& (selectedGroupId < 0 || (row.group === uniqueObjectGroups.find(item => item.value === selectedGroupId).label))
		);

		if (tableTabInd === OBJECT_STATE.TEMPLATE.status)
		 	list = list.filter(item => !item.owner);
		else if (tableTabInd === OBJECT_STATE.WORK.status)
			list = list.filter(item => item.owner);

		setFilteredObjects(list); 
	}, [pi.objects, testFilter, selectedGroupId, uniqueObjectGroups, tableTabInd]);

	const handleCreate = () => {
		if (isAuth().role === 0)
			toast.warn('Только преподаватель может добавить новый объект.');
		else
			handleSelectObject(-1);
	};

    const canEditIDs = () => filteredObjects.filter(item => isAuth().role === 3 || item.author !== 'Шаблон').map(item => item.id);

	const handleSelectObject = (id=-1) => {
		props.history.push("/pi/obj/" + id);
	};

	//--COPYING ---
	const handleCopyObject = async (id) => {
		const obj = await piApi.getObjectById(id);
		obj.name = "Копия - " + obj.name;
		const isTemplate = !obj.owner;

		if (isTemplate && isAuth().role === 3) {
		 	copyTemplate(obj);
		} else if (!isTemplate) {
		 	copyObject(obj);
		} else { //copy template to online test
		 	copyTemplateToObject(obj);
		}
	};

	const copyTemplate = (object) => {
		addObject(object);
	};

	const copyObject = async (object) => {
		//copy attachments:
		for (let i = 0; i < object.files.length; i ++) {
			const name = PINVEST_SLASH + object.files[i];
			const {newKey} = await storageApi.copy(name, name);
			object.files[i] = newKey.split('/').pop();
		}

		addObject(object);
	};

	const addObject = async (object) => {
		piApi.addObject(object)
		.then((result) => {
			toast.success("Объект '" + result.name + "' добавлен.");
			setIsUpdated(true);
		}).catch((err) => {
			toast.error(err.response.data.errors);
		});
	};

	//копирование templates в объект (3 шага)
  	//step 1 of 3: готовим информацию о файлах в шаблоне
  	const copyTemplateToObject = async (object) => {
    	object.room = documentClass._id;
		//см. подробное описание в копировании onlinbe tests and scenarios

		//получим массив с сохраненными индексами
		const fileIndexes = object.files.map((item, ind) => ({fileInd: ind}));
		setFilesBeCopied(fileIndexes);
		setObjBeCopied(object);
  	};

  	//step 2 of 3: готовим очередной файл и посылаем запрос на загрузку ИЛИ сохраняем сценарий
  	useEffect(() => {
		//templates contain the images inside. When copying the templates it's necessary to create 
		//image file, save them and add references to the files as attachmentName:
		if (!objBeCopied || loadedFileName || isLockUE) return;
		setIsLockUE(true);

		if (filesBeCopied.length > 0) {
			//попадаем сюда. если список файлов еще не пуст. создаем соответствующий файл и затем удаляем ссылку на него из списка
			const fileInfo = objBeCopied.files[filesBeCopied[0].fileInd];
			const fileOpts = JSON.parse(fileInfo);

			const fileData = fileOpts.content.split(';base64,');
			const type = fileData[0].split(':')[1]; //keep type like 'image/jpeg'
			const content = fileData[1];

			const file = new File(
				[base64ToArrayBuffer(content)],
				fileOpts.name, 
				{ type: type }
			);

			uploadPIFile(file, setLoadedFileName);
		} else {
			//попадаем сюда, когда все файлы созданы. осталось создать сам тест
			addObject(objBeCopied);
			setObjBeCopied(undefined);
			setIsLockUE(false);
		}
	}, [filesBeCopied, isLockUE, loadedFileName, objBeCopied]);

  	//step 3 of 3: сохраняем инфу об очередном загруженном файле в данные объекта, 
  	//уменьшаем список filesBeCopied. Затем снова возвращаемся в шаг 2.
  	useEffect(() => {
		if (!loadedFileName || filesBeCopied.length === 0) return;
		const _obj = {...objBeCopied};
		const _filesBeCopied = [...filesBeCopied];
		const f = _filesBeCopied[0];
		_obj.files[f.fileInd] = loadedFileName;
	
		_filesBeCopied.shift(); //удаляем инфу о созданном файле из списка
		setFilesBeCopied(_filesBeCopied); //сохраняем обновленный список
		setObjBeCopied(_obj);
		setLoadedFileName('');
		setIsLockUE(false);
	}, [loadedFileName, filesBeCopied, objBeCopied]);
	
	//-- DELETION ---
	useEffect(() => {
		const getResarches = async () => {
			let researches = await piApi.getResearches(documentClass._id, -2);
			const fresearches = researches.filter(item => item.owner && item.objects && item.objects.length > 0);
			const objects = fresearches.map(item => item.objects.map(obj => obj.id));
			const ids = [];
			for (let i = 0; i < objects.length; i ++) {
				const list = objects[i];
				for (let k = 0; k < list.length; k ++)
					ids.push(list[k]);
			}

			const fo = filteredObjects.filter(item => isAuth().role === 3 || item.author !== 'Шаблон')
				.map(item => item.id)
				.filter(item => !ids.includes(item));
			setCanDeleteIDs(fo);
		};

		getResarches();
	}, [documentClass._id, filteredObjects, isUpdated]);

	const handleRequestDeleteObject = id => {
	 	setIdBeDeleted(id);
	 	setShowConfirmDlg(true);
	};

	const handleDeleteObjectNo = id => {
		setShowConfirmDlg(false);
		setDeletionConfirmed(false);
		setIdBeDeleted(undefined);
	};

	const handleDeleteObjectYes = () => {
		setShowConfirmDlg(false);
		setDeletionConfirmed(true);
	};
	useEffect(() => {
		const deleteObject = async () => {
			if (!deletionConfirmed) return;
			setShowConfirmDlg(false);
			const obj = await piApi.getObjectById(idBeDeleted);

			//delete attachments
			if (obj.owner) {
				for (const item of obj.files) {
					await deletePIFileByName(dispatch, item);
				}
			}
	
			//delete the object
			piApi.deleteObjectById(idBeDeleted)
			.then((result) => {
				toast.success('Объект удален.');
				setIsUpdated(true);
			}).catch((err) => {
				toast.error(err.response.data.errors);
			});
	
			setDeletionConfirmed(false);
			setIdBeDeleted(undefined);
		};

		deleteObject();
	}, [deletionConfirmed, dispatch, idBeDeleted]);
	
	const handleSelectTab = (ind) => setTableTabInd(ind);

	const getTable = () => {
		const header = [
			{column: 'Название', name: 'linkName', style: { width: '30%'} }, 
			{column: 'Раздел', name: 'type', style: { width: '20%'} }, 
			{column: 'Подраздел', name: 'group', style: { width: '20%'} }, 
			{column: 'Автор', name: 'author', style: { width: '20%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '10%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];
	
		return (
			<Table 
				table={{header: header, 
					data: filteredObjects,
				}}
				link={{
					handleLink: id => handleSelectObject(id),
				}}
				sort={{
					hasSorting: true, 
					initSortInd: 1,
				}}
				actions={{
					handleEdit: id => handleSelectObject(id),
					canEditIDs: canEditIDs(),
					handleCopy: id => handleCopyObject(id),
					handleDelete: id => handleRequestDeleteObject(id),
					canDeleteIDs: canDeleteIDs,
				}}
			/>);
	};

	return (
		<Content>
			<ContentHeader>
				<div className="cor-net__row">
					{isAuth().role < 3 && 
					<div className="cor-net__col col-3">
							<Tabs>
								{Object.values(OBJECT_STATE).map((tab) => (
									<Tabs.Item 
										active={tableTabInd === tab.status}
										onClick={() => handleSelectTab(tab.status)}
										key={tab.status}
									>
										{tab.name}
									</Tabs.Item>)
								)}
							</Tabs>
					</div>
					}
					
					<div className="cor-net__col col-3 col-icon">
						<Input 
							value={testFilter}
							onInput={e => setTestFilter(e.target.value)} 
							placeholder={'Поиск по объектам'}/>
						<Icon name={'search'} />
					</div>
					
					<div className="cor-net__col col-3">  
						{getDropdown(uniqueObjectGroups, selectedGroupId, value => setSelectedGroupId(value), '', '', 'grp01')}
					</div>
				</div>
			</ContentHeader>
			<ContentBody>
				<div key='tbl01'>
					{getTable()}
				</div>
			</ContentBody>
			<ContentFooter>
				<Button onClick={handleCreate}>{isAuth().role < 3 ? "Создать" : "Создать шаблон"}</Button>
			</ContentFooter>

			{showConfirmDlg && 
			<ModalConfirmDialog
				showConfirmDlg={showConfirmDlg} 
				handleNo={handleDeleteObjectNo}
				handleYes={handleDeleteObjectYes}
				question={'Вы действительно хотите удалить этот объект?'}
				btnTextYes={'Удалить'}
				btnTextNo={'Оставить'}
				redWarning={true}
			/>
			}
		</Content>
	)
}

export default PInvestObjectsList;