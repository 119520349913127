import React, { useState, useEffect, useRef } from "react";
import { AddFile, Icon, Trash } from '../ui';
import {Button} from 'rlabui';
import {useDispatch} from "react-redux";
import { isAuth } from "../../helpers/auth";
import htmlParser from "html-react-parser";
import {getDropdown} from "../ui/utils/gen_utils";
import {QUESTION_TYPES, UNDEFINED_QUESTION_TYPE, MAX_DESC_LENGTH, FORMAT_TYPE, OT_SAVE_ACTION,
    addQuestion, replaceQuestion, getOneOption, getOTUrl,
    uploadOTFile, deleteOTFileByName, clearAllQuestionAttachments,
    getQuestionTypeList, hasNoErrors, getCurrentQuestion, } from "./ot_utils";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import ImageDlg from "../ui/ModalDialogs/ImageDlg";
import ModalConfirmChangeTypeDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import ModalConfirmLeavePageDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import Constructor1RestoreSequence from "./Constructor1RestoreSequence";
import Constructor2OneAnswer from "./Constructor2OneAnswer";
import Constructor3NonVerbal from "./Constructor3NonVerbal";
import {toast} from "react-toastify";
import { Back, Content, ContentBody, ContentFooter, ContentHead, ContentHeader } from "../template/ContentParts";
import ss from './OnlineTestCreateQuestions.module.scss';

const OnlineTestCreateQuestions = (props) => {
    const [currQuestionNum, setCurrQuestionNum] = useState(0);
    const [questionName, setQuestionName] = useState("");
    const [qIllustration, setQIllustration] = useState("");
    const [qIllustrationUrl, setQIllustrationUrl] = useState("");
    const [currQuestionType, setCurrQuestionType] = useState(props.firstQuestionType);
    const [desirableQuestionType, setDesirableQuestionType] = useState(UNDEFINED_QUESTION_TYPE);
	const [dataError, setDataError] = useState('');
    const [currAnswerOptions, setCurrAnswerOptions] = useState([]);
    const [currCorrectOptions, setCurrCorrectOptions] = useState('');
    const [changedData, setChangedData] = useState(false);
    const [isNewQuestion, setIsNewQuestion] = useState(props.questionList.length === 0);
    const [showConfirmDeleteDlg, setShowConfirmDeleteDlg] = useState(false);
    const [showConfirmChangeTypeDlg, setShowConfirmChangeTypeDlg] = useState(false);
    const [showConfirmLeavePageDlg, setShowConfirmLeavePageDlg] = useState(false);
    const [loadedFileName, setLoadedFileName] = useState('');
    const [showPictureDlg, setShowPictureDlg] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [currFormatType, setCurrFormatType] = useState(FORMAT_TYPE.IMAGE);
    const [isInit, setIsInit] = useState(false);
    const [reinitQuestion, setReinitQuestion] = useState(false); //to be used???

    const isSA = props.isSA;
    const isTemplate = props.isTemplate;
    const dispatch = useDispatch();
	const changeNameCnt = useRef(0);
	const nextRef = useRef(-1);
	const saveActionRef = useRef(OT_SAVE_ACTION.NONE);

    useEffect(() => {
        if (isInit) return;
        setIsInit(true);
        if (!isNewQuestion)
            setQuestionData(props.questionList[0], isTemplate);
        props.setIsSaved(false);    
    }, [isInit, props, isNewQuestion, isTemplate]);

    useEffect(() => {
        if (props.questionList.length > 0) return;
        cleanupQuestionOpts(props.firstQuestionType);
    }, [props.questionList, props.firstQuestionType]);

    const setQuestionData = async (question, _isTemplate) => {
        setQuestionName(question.questionName);
        setCurrQuestionType(Number(question.questionType));
        const _qIll = question.illustration ? JSON.parse(question.illustration) : '';
        setQIllustration(_qIll);
        if (question.illustration && !_isTemplate) {
            const url = await getOTUrl(_qIll.name);
            setQIllustrationUrl(url);
        } else 
            setQIllustrationUrl('');
        
        setCurrAnswerOptions(question.answerOptions);
        setCurrCorrectOptions(question.correctOptions);
    };

    const cleanupQuestionOpts = (firstQType) => {
        setIsNewQuestion(true);
        setQuestionName('');
        setCurrQuestionType(firstQType);
        setQIllustration('');
        setQIllustrationUrl('');
        setCurrAnswerOptions([]);
        setCurrCorrectOptions('');
    };

    const getQuestionData = () => {
        const questionData = {
            questionName: questionName,
            questionType: currQuestionType,
            illustration: qIllustration ? JSON.stringify(qIllustration) : undefined,
            answerOptions: currAnswerOptions,
            correctOptions: currCorrectOptions,
        };
        return questionData;
    };

    const lockUpdateQList = (qList, next) => {
        const saveQuestions = async () => {
            setChangedData(false);
            nextRef.current = next;
            await props.doSave(qList);
        };
        saveQuestions();
    };

    const canSaveQuestion = () => {
        return hasNoErrors(isNewQuestion, questionName, currQuestionType, dataError);
    };

    useEffect(() => {
        if (!props.isSaved) return;
        props.setIsSaved(false);

        if (props.questionList.length === 0) {
            return;
        }

        //сначала проверяем nextRef
        const next = nextRef.current;
        if (next !== -1) { //переходим влево или вправо, если не крайние
            if (next !== currQuestionNum) {
                setCurrQuestionNum(next); //curr and next должны совпадать
            }
        
            setQuestionData(props.questionList[next], isTemplate);
            nextRef.current = -1;
        } 

        //теперь проверяем saveActionRef
        if (saveActionRef.current === OT_SAVE_ACTION.NEW) { //после сохранения предыдущего вопроса создаем новый вопрос
            cleanupQuestionOpts(props.firstQuestionType);
            saveActionRef.current = OT_SAVE_ACTION.NONE;
        } else if (saveActionRef.current === OT_SAVE_ACTION.EXIT) {
            props.doLeave();
        }
    }, [props, currQuestionNum, isTemplate]); //, saveAction

    const handleSaveQuestion = (action = OT_SAVE_ACTION.NONE) => {
        if (!canSaveQuestion()) return;
        saveActionRef.current = action;

        const questionData = getQuestionData();

        const newList = isNewQuestion ? 
            addQuestion(props.questionList, questionData, currQuestionNum) : 
            replaceQuestion(props.questionList, questionData, currQuestionNum);            

        let next;
        if (isNewQuestion) {
            next = newList.length === 1 ? 0 : currQuestionNum + 1;
            setIsNewQuestion(false);
        } else {
            next = currQuestionNum; //curr and next должны совпадать
        }
        lockUpdateQList(newList, next);
        toast.success('Изменения сохранены.');
    };

    const handlePrevNext = dir => {
        if (!canSaveQuestion()) return;

        let next = dir === 1 && currQuestionNum < props.questionList.length - 1 ? currQuestionNum + 1 : 
                   dir === -1 && currQuestionNum > 0 ? currQuestionNum - 1 : 
                   currQuestionNum;

        if (next !== currQuestionNum) {
            if (changedData && props.isEditable) {
                //save changes
                const questionData = getQuestionData();
                const newList = replaceQuestion(props.questionList, questionData, currQuestionNum);
                lockUpdateQList(newList, next);
                if (changeNameCnt.current > 1) toast.success('Изменения сохранены.');
            } else{
                setCurrQuestionNum(next); //go to next question
                setQuestionData(props.questionList[next], isTemplate);
            }
        }
        changeNameCnt.current = 0;
	};
    
    const handleBack = () => {
        if (!changedData || !props.isEditable) handleLeavePageYes();
        else if (changedData) setShowConfirmLeavePageDlg(true);
        else if (!isNewQuestion) props.setShowGeneral(true);
	};

    const handleLeavePageYes = () => {
        setIsNewQuestion(false);
        setShowConfirmLeavePageDlg(false);
        props.setShowGeneral(true);
    };

    const handleAddQuestion = () => {
        if (isNewQuestion) {
            if (hasNoErrors(isNewQuestion, questionName, currQuestionType, dataError)) {
                toast.warn("Сохраните введенный вопрос и перейдите к добавлению следующего.");
            }
        } else {
            cleanupQuestionOpts(props.firstQuestionType); //initialize a new question
        }
	};

    const handleDeleteQuestion = () => {
        setShowConfirmDeleteDlg(true);
    };

    const handleDeleteQuestionYes = () => {
		setShowConfirmDeleteDlg(false);
        if (isAuth().role < 3)
		    clearAllQuestionAttachments(props.questionList[currQuestionNum], props.onlineTestId, dispatch);

        const questions = props.questionList.filter((item, index) => currQuestionNum !== index);
        const next = currQuestionNum > 0 ? currQuestionNum - 1 : 0;
        lockUpdateQList(questions, next);
	};
	
    const handleChangeQuestionName = value => {
        setQuestionName(value);
        if (changeNameCnt.current > 0) {
            setChangedData(true);
        }
        changeNameCnt.current ++;
    };

    const handleChangeQuestionIllustration = async (value, _isTemplate) => {
        setQIllustration(value);
        if (value && !_isTemplate) {
            const url = await getOTUrl(value.name);
            setQIllustrationUrl(url);
        } else 
            setQIllustrationUrl('');

        setChangedData(true);
    };

    const handleChangeQuestionType = value => {
        const isEmptyQuestion = (currQuestionType, _currAnswerOptions, _currCorrectOptions) => {
            return _currAnswerOptions.length === 0 && _currCorrectOptions === '';
        };

        const qType = Number(value);

        if (isEmptyQuestion(currQuestionType, currAnswerOptions, currCorrectOptions)) {
            //select a type
            setCurrQuestionType(qType);
            setChangedData(true);
        }
        else {
            //open a dialog to get confirmation to change the type
            setShowConfirmChangeTypeDlg(true);
            setDesirableQuestionType(qType);
        }
    };
    const handleClearQuestionTypeYes = () => {
        //only for new questions
		setShowConfirmChangeTypeDlg(false);
        setCurrQuestionType(desirableQuestionType);
        setDesirableQuestionType(UNDEFINED_QUESTION_TYPE);
    };

    const getButtonDisabled = (criteria) => (criteria ? false : true);

    //step 1 of 2: get file
    const handleChangeIllustration = (event) => {
        if (!isTemplate) {
            if (qIllustration) {
                deleteOTFileByName(dispatch, qIllustration.name);
                setQIllustrationUrl('');
            }
            uploadOTFile(event.target.files[0], setLoadedFileName);
        } else {
            setLoadedFileName(event.target.files[0]); //keep the file name and content
        }
        event.target.value = '';
    };
    //step 2 of 2: save file name (SA) or file (teacher)
    useEffect(() => {
        if (!loadedFileName) return;
        if (!isTemplate) {
            handleChangeQuestionIllustration({name: loadedFileName, illFileType: currFormatType}, isTemplate);
            setLoadedFileName('');
        } else {
            const reader = new FileReader();
            reader.onload = (event) => {
                //DB will keep the file data: name and content
                const fileOpts = {name: loadedFileName.name, illFileType: currFormatType, content: event.target.result};
                handleChangeQuestionIllustration(fileOpts, isTemplate);
                setLoadedFileName('');
            };
            reader.readAsDataURL(loadedFileName); //if isTemplate the param contains the file (not just its name)
        }
    }, [isTemplate, loadedFileName, currFormatType]);

    const handleDeleteIllustrationFile = () => {
        if (!isTemplate) {
            deleteOTFileByName(dispatch, qIllustration.name);
            setQIllustrationUrl('');
        }
        handleChangeQuestionIllustration('', isTemplate);
    };

    const getQuestionTypeName = qType => 
        getQuestionTypeList(isTemplate, props.enabledQuestionTypes).find(item => item.value === qType).label;

    const getIllustration = () => {
        const getFormatList = () => {
            const list = [];
            list.push({label: 'Изображение', value: FORMAT_TYPE.IMAGE});
            if (!isTemplate)
                list.push({label: 'Видео', value: FORMAT_TYPE.VIDEO});
            list.push({label: 'Аудио', value: FORMAT_TYPE.AUDIO});
            return list;
        };


        const getAcceptableFormat = () => {
            if (currFormatType === FORMAT_TYPE.IMAGE) return "image/*";
            if (currFormatType === FORMAT_TYPE.VIDEO) return "video/*";
            return "audio/*";
        };

        const handleShowPicture = (_file) => {
            setFileUrl({Url: _file});
            setShowPictureDlg(true);
        };
    
        const foundFiles = !qIllustration ? [] : [!isTemplate ? qIllustrationUrl : qIllustration];
        let content;
        const ifType = Number(qIllustration.illFileType);

        if (foundFiles.length > 0) {
            content = !isTemplate ? foundFiles[0] : qIllustration.content;
        }
        
        return (
            <>
                {ifType || !props.isEditable ? <></> :
                    <div className="cor-net__row">
                        <div className="cor-net__col col-grow">
                            <div className="cor-net__label">Добавьте к вопросу</div>
                            {getDropdown(getFormatList(), currFormatType, v => setCurrFormatType(v), '', '', 'ft01')}
                        </div>
                        <div className="cor-net__col">
                            <div className="cor-net__label hide"></div>
                            <AddFile 
                                accept={getAcceptableFormat()}
                                onChange={(e) => handleChangeIllustration(e)}
                                text="Добавить"
                            />
                        </div>
                    </div>
                }

                {qIllustration && foundFiles.length > 0 && (
                    <div className={ss.preview}>
                        {ifType === FORMAT_TYPE.IMAGE && 
                        <div className={ss.preview__view}>
                            <img 
                                src={content} alt=''
                                onClick={() => handleShowPicture(!isTemplate ? foundFiles[0] : JSON.stringify(foundFiles[0]))} 
                            />
                            {(!isTemplate || isSA) && 
                            <Trash onClick={() => handleDeleteIllustrationFile()} bg={true} className={ss.preview__trash} />}
                        </div>
                        }
                        {ifType === FORMAT_TYPE.VIDEO && !isSA &&
                        <div className={ss.preview__view}>
                           <video src={content} controls={true}></video>
                 
                            {(!isTemplate || isSA) && 
                            <Trash onClick={() => handleDeleteIllustrationFile()} bg={true} className={ss.preview__trash} />}
                        </div>
                        }
                        {ifType === FORMAT_TYPE.AUDIO && 
                        <div className={ss.preview__audio}>
                            <audio src={content} volume={0} controls={true} width="100" height="30" />
                            {(!isTemplate || isSA) && <Trash onClick={() => handleDeleteIllustrationFile()} />}
                        </div>
                        }
                    </div>
                )}
            </>
        );
    };

    return (
	    <>
            <ContentHead flex={false}>
                <Back onClick={handleBack} icon="back" margin="bottom" text="Вернуться в редактор для сохранения теста" />
                <div className="cor_content__title">Добавление задания: Онлайн тестирование</div>
            </ContentHead>

            <Content>
                <ContentHeader className={ss.header}>
                    <div className={ss.header__col}>
                        {isNewQuestion ? 
                            ('Новый вопрос '  + (props.questionList.length > 0 ? currQuestionNum+2 : currQuestionNum+1)) 
                        : 
                            ('Вопрос ' + (props.questionList.length > 0 ? (currQuestionNum+1) : currQuestionNum)) + ' / ' + props.questionList.length
                        }
                    </div>

                    {props.isEditable && <>
                    <div className={ss.header__col}>
                        <Button className={ss.header__btn} onClick={handleAddQuestion} icon={true} border={true}>
                            <Icon name="plus_bold" />
                            {/* Добавить новый вопрос */}
                            Добавить
                        </Button>

                        {/* <Button className={ss.header__btn} onClick={() => handleSaveQuestion(OT_SAVE_ACTION.NEW)} icon={true}>
                            <Icon name="save" />
                            Сохранить вопрос и добавить новый
                        </Button> */}

                        <Button className={ss.header__btn} onClick={handleSaveQuestion} icon={true}>
                            <Icon name="save" />
                            Сохранить
                        </Button>

                        <Button className={ss.header__btn} onClick={handleDeleteQuestion} icon={true} color='danger'> 
                            {/* Удалить вопрос */}
                            Удалить
                            <Icon name="trash" />
                        </Button>
                    </div>

                    <div className={ss.header__col}>
                        <Button className={ss.header__btn} onClick={() => handleSaveQuestion(OT_SAVE_ACTION.EXIT)} icon={true}>
                            <Icon name="save" />
                            {/* Сохранить вопрос и выйти */}
                            Сохранить и выйти
                        </Button>
                    </div>

                    </>}
                </ContentHeader>

                <ContentBody>
                    {(isNewQuestion || props.questionList.length > 0) && currQuestionType !== UNDEFINED_QUESTION_TYPE ? 
                    (
                        <div className={ss.body}>

                            <div className="cor-net__section border">
                                <div className="cor-net__row">
                                    <div className="cor-net__col col-2">
                                        <div className={!isNewQuestion ? "cor-net__title" : "cor-net__label"}>
                                            Тип вопроса{!isNewQuestion && ': ' + getQuestionTypeName(currQuestionType)}
                                        </div>
                                        {isNewQuestion &&
                                        <div>
                                            {getDropdown(
                                                getQuestionTypeList(isTemplate, props.enabledQuestionTypes), 
                                                currQuestionType, handleChangeQuestionType, 
                                                '', '', 'quest01')}
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="cor-net__row">
                                    <div className={ss.question}>
                                        <div className={ss.question__title}>
                                            {props.isEditable ?
                                            getOneOption(0, 'Вопрос', questionName, 
                                                handleChangeQuestionName, null, MAX_DESC_LENGTH)
                                            : 
                                            htmlParser(questionName)
                                            }
                                        </div>
                                        <div className={ss.question__file}>
                                            {getIllustration()}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {currQuestionType !== UNDEFINED_QUESTION_TYPE && !props.isSaved && (
                            <>
                                {(
                                currQuestionType === QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE ||
                                currQuestionType === QUESTION_TYPES.CORRECT_WORD_SEQUENCE) && 
                                    <Constructor1RestoreSequence
                                        isNewQuestion={isNewQuestion}
                                        currQuestionNum={currQuestionNum}
                                        questionList={props.questionList}

                                        currQuestionType={Number(currQuestionType)}
                                        currQuestion={getCurrentQuestion(props.questionList, isNewQuestion, currQuestionNum)} 
                                        setDataError={setDataError}
                                        setCurrAnswerOptions={setCurrAnswerOptions}
                                        setCurrCorrectOptions={setCurrCorrectOptions} //we keep the order in the correctOptions field
                                        setChangedData={setChangedData}
                                        isEditable={props.isEditable}
                                        isTemplate={isTemplate}
                                    />
                                }
        
                                {(currQuestionType === QUESTION_TYPES.CORRECT_ANSWER_ONE || 
                                currQuestionType === QUESTION_TYPES.CORRECT_ANSWER_MANY ||
                                currQuestionType === QUESTION_TYPES.COMPLETE_SENTENCE ||
                                currQuestionType === QUESTION_TYPES.VIDEO ||
                                currQuestionType === QUESTION_TYPES.FREE_WRITING) && 
                                    <Constructor2OneAnswer 
                                        isNewQuestion={isNewQuestion}
                                        currQuestionNum={currQuestionNum}
                                        questionList={props.questionList}
                                        currQuestionType={Number(currQuestionType)}
                                        reinitQuestion={reinitQuestion}
                                        setReinitQuestion={setReinitQuestion}
                                        dataError={dataError}
                                        setDataError={setDataError}
                                        setCurrAnswerOptions={setCurrAnswerOptions}
                                        setCurrCorrectOptions={setCurrCorrectOptions}
                                        changedData={changedData}
                                        setChangedData={setChangedData}
                                        onlineTestId={props.onlineTestId}
                                        isEditable={props.isEditable}
                                        isTemplate={isTemplate}
                                    />
                                }
                                {(currQuestionType === QUESTION_TYPES.NON_VERBAL ||
                                  currQuestionType === QUESTION_TYPES.AUDIO) &&
                                    <Constructor3NonVerbal
                                        isNewQuestion={isNewQuestion}
                                        currQuestionNum={currQuestionNum}
                                        questionList={props.questionList}
                                        currQuestionType={Number(currQuestionType)}
                                        setDataError={setDataError}
                                        setCurrAnswerOptions={setCurrAnswerOptions}
                                        setCurrCorrectOptions={setCurrCorrectOptions}
                                        setChangedData={setChangedData}
                                        isEditable={props.isEditable}
                                        isTemplate={isTemplate}
                                    />
                                }
                            </>
                            )}

                        </div>
                    )
                    : 
                        <></>
                    }
                </ContentBody>

                <ContentFooter className={ss.nav}>
                    <span></span>

                    <div>
                        <button
                            type="button"
                            onClick={() => handlePrevNext(-1)}
                            className={ss.btn}
                            disabled={getButtonDisabled(currQuestionNum > 0 && !isNewQuestion)}
                        >
                            <Icon name="arrow-round-prev" />
                            Предыдущий вопрос
                        </button>
                    </div>

                    <div>
                        <button
                            type="button"
                            onClick={() => handlePrevNext(+1)}
                            className={ss.btn}
                            disabled={getButtonDisabled(currQuestionNum < props.questionList.length - 1 && !isNewQuestion)}
                        >
                            Следующий вопрос
                            <Icon name="arrow-round-next" />
                        </button>
                    </div>

                    <span></span>
                </ContentFooter>
            </Content>

            {showConfirmDeleteDlg &&
			<ModalConfirmDialog
				showConfirmDlg={showConfirmDeleteDlg} 
				question={'Вы действительно хотите удалить этот вопрос?'}
				handleYes={handleDeleteQuestionYes}
				handleNo={() => setShowConfirmDeleteDlg(false)}
			/>
            }
            {showConfirmChangeTypeDlg && 
			<ModalConfirmChangeTypeDialog
				showConfirmDlg={showConfirmChangeTypeDlg} 
				question={'При изменении типа вопроса несохраненные данные будут потеряны. Продолжить?'}
				handleYes={handleClearQuestionTypeYes}
				handleNo={() => setShowConfirmChangeTypeDlg(false)}
			/>
            }
            {showConfirmLeavePageDlg &&
            <ModalConfirmLeavePageDialog
                showConfirmDlg={showConfirmLeavePageDlg} 
                question={'При выходе несохраненные данные будут потеряны. Продолжить?'}
                handleYes={handleLeavePageYes}
                handleNo={() => setShowConfirmLeavePageDlg(false)}
            /> 
            }

            {showPictureDlg &&
                <ImageDlg
                    showModal={showPictureDlg}
                    setShowModal={setShowPictureDlg}
                    file={fileUrl}
                />
            }
        </>
    )
}

export default OnlineTestCreateQuestions;
