import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import socket from "../../../socket";
import {toast} from "react-toastify";
import {rlabApi} from "../../../api/api";
import "./Dashboard.scss";
import ss from "./DashboardRlab.module.scss";

import Aside from "../../../rlab/src/components/Template/Aside";
import Dashboard from '../../../rlab/src/components/Template/Dashboard';
import Footer from '../../../rlab/src/components/Template/Footer';
import ChartStyles from '../../../rlab/src/components/ChartStyles';
import CompanyInfo from "../../../rlab/src/components/Template/CompanyInfo";
import {Connection} from '../../../rlab/src/components/Connection';
import SensorsSettings from '../../../rlab/src/components/SensorsSettings';
import {Graduation} from '../../../rlab/src/components/Graduation';
import ModalPrint from '../../../rlab/src/components/ModalPrint';
import MobileAreaSwicher from '../../../rlab/src/components/Template/MobileAreaSwicher';
import TimerModal from "../../../rlab/src/components/TimerModal";
import Help from "../../../rlab/src/components/Help";
import {mapRlabDispatchToProps, mapRlabStateToProps} from "./redux";
import ModalBattery from "../../../rlab/src/components/ModalBattery";
import {BundleModalDel} from "../../../rlab/src/components/Bundles/AsideBundles";
import ConnectedSensorsModal from "../../../rlab/src/components/ConnectedSensorsModal";
import DemoLearn from "../../../rlab/src/components/Demo/DemoLearn";
import DemoModal from "../../../rlab/src/components/Demo/DemoModal";
import UnitDetailInfo from "../../RlabScenarios/UnitDetailInfo";
import SensorDataModal from "../../../rlab/src/components/SensorDataModal";
import ChartCommentImageModal from "../../../rlab/src/components/ChartComment/ChartCommentImageModal";

export const DashboardRlabAnon = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const {
        portOpen,
        isMobile,
        imported,
        options,
        addDevicesConnected,
        removeDevicesConnected,
        setDeviceConnectedCount,
        setSensor,
        setSensorImported,
        setSensorList,
        setTheme,
        setMobile,
        setPlay,
        setChartMarkers,
        setChartView,
        setConnectionType,
        togglePort,
        setSensorReading,
    } = props;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    function closeNetSensor(notify) {
        setPlay(false);
        setConnectionType(null);
        removeDevicesConnected('fake');
        setDeviceConnectedCount(0);
        togglePort(false);
        if (notify) toast.info('Датчик отключен');
    }

    useEffect(() => {
        toast.dismiss();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isMobile) {
            setChartView('one');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    useEffect(() => {
        if (width <= 768) {
            if (width > height) {
                setMobile('landscape');
            } else {
                setMobile('portrait');
            }
        } else {
            setMobile(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height]);

    useEffect(() => {
        setTheme(options.theme??'light');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.theme]);

    useEffect(() => {
        if (props.match?.params.id) {
            if (props.match.params.opt){
                socket.emit("BE-join-anon-rlab", {roomId: props.match.params.id,
                    options: JSON.parse(atob(decodeURIComponent(props.match.params.opt)))});
            } else {
                rlabApi.getById(props.match.params.id).then((rlab) => {
                    try {
                        if (rlab.isShared > 0) {
                            const data = JSON.parse(rlab.data);
                            for (let rec in data.records) data.records[rec].num = 'imp' + data.records[rec].num;
                            setSensorReading(true);
                            setSensorImported(data.records);
                            for (let m in data.markers) {
                                setChartMarkers({num:'imp' + m, data: data.markers[m]});
                            }
                            setSensorReading(false);
                        }
                    } catch (err) {
                        console.log(err);
                    }
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match?.params.id]);


    useEffect(() => {
        socket.on("FE-sensor-start", ({sensorList, devicesList}) => {
            if (sensorList && devicesList) {
                setConnectionType('net');
                for (let key in devicesList) {
                    addDevicesConnected({key, data: devicesList[key]});
                }
                setDeviceConnectedCount(Object.keys(devicesList).length);
                setSensorList(sensorList);
                togglePort(true);
                toast.success('Соединение установлено');
            }
        });
        socket.on("FE-sensor-item", (item) => {
            setSensor(item);
        });
        socket.on("FE-sensor-close", () => {
            closeNetSensor(true);
        });

        return () => {
            socket.off("FE-sensor-start");
            socket.off("FE-sensor-item");
            socket.off("FE-sensor-close");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<div className={"cor_dashboard"+(options.theme==="dark"?" dark":"")}>
        <div className="cor_dashboard__content_anon">
            <MobileAreaSwicher/>
            <div className={ss.main}>
                {(portOpen || imported?.length > 0) && !isMobile && <Aside/>}
                <Dashboard/>
            </div>
            <Footer />
            <ChartStyles />
            <SensorsSettings />
            <Graduation />
            <TimerModal />
	          <ModalPrint />
            <ModalBattery />
            <CompanyInfo />
            <BundleModalDel />
            <Connection />
            <ConnectedSensorsModal />
            <DemoLearn />
            <DemoModal />
            <SensorDataModal />
            <ChartCommentImageModal />
            <UnitDetailInfo {...props}/>
            {!portOpen && !imported.length > 0 && <Help dashboard={false} />}
        </div>
    </div>);
}
export default connect(mapRlabStateToProps, mapRlabDispatchToProps)(DashboardRlabAnon)
