import { saveAs } from 'file-saver';
import { Tabs, Button, Icon, Switcher, AddFile, Trash } from '../ui';
import Grid from '../ui/Grid';
import {getRoundValue, uploadFile, doDeleteFileByName, isImageFile, getUrl, getUrls,
    getLimitedString} from '../ui/utils/gen_utils';
import htmlParser from "html-react-parser";
import socket from "../../socket";
import {DB_REFRESH} from "../ui/utils/gen_utils";
import {printOnlyDate} from "../../helpers/text";
import {IMAGE_SHIFT_X, IMAGE_SHIFT_Y, sizeUnitList} from '../../rlab/src/components/VideoEditor/utils';
import {parse} from 'equation-parser'; //yarn add react-equation equation-resolver
import {resolve, defaultVariables, defaultFunctions} from 'equation-resolver';
import {loadCountersPi} from "../../redux/slices/lists";
import {Equation, EquationContext, EquationEvaluate, EquationOptions, defaultErrorHandler} from "react-equation";
import {toast} from "react-toastify";

export const USE_FORMULA = true;

export const WS_TYPE = {
    ALL: -2,  
    TEMPLATE: 0,
    WORK: 1,
    CHECK_IN_PROGRESS: 2,
    CHECKED: 3,
    PUBLISHED: -1,
};

export const WORK_STATE = {
  ALL: {status: WS_TYPE.ALL, name: 'Все'},
  TEMPLATE: {status: WS_TYPE.TEMPLATE, name: 'Шаблоны'},
  WORK: {status: WS_TYPE.WORK, name: 'В работе'},
  CHECK_IN_PROGRESS: {status: WS_TYPE.CHECK_IN_PROGRESS, name: 'На проверке'},
  CHECKED: {status: WS_TYPE.CHECKED, name: 'Проверено'},
  PUBLISHED: {status: WS_TYPE.PUBLISHED, name: 'Опубликовано'},
};

export const getWorkStateByStatus = status => Object.values(WORK_STATE).find(v => v.status === status)?.name;

export const OBJECT_STATE = {
    ALL: {status: WS_TYPE.ALL, name: 'Все'},
    TEMPLATE: {status: WS_TYPE.TEMPLATE, name: 'Шаблоны'},
    WORK: {status: WS_TYPE.WORK, name: 'Созданные'},
};

export const WORD_LIMIT = 20;
export const LINE_LIMIT = 170;
export const DEFAULT_WORK_LEVEL = 7;
export const BLANK_FILE = "/Instrument/blank.png";

export const COL_TYPE = {
    UNDEFINED: 'undefined',
    ORDER: 'order',
    NUMBER: 'number',
    TEXT: 'text',
    IMAGE: 'image',
    FORMULA: 'formula',
    DATE_TIME: 'datetime'
};

export const INV_ELEM_TYPE = {
    MEASUREMENT_ID: 'mesurment',
    COMMENT_ID: 'comment',
    OBJECT_ID: 'object',
    DRAW_TOOL_ID: 'paintTool',
    PLOT_ID: 'plot'
};

export const METHOD = {
    LIST: true,
    MANUAL: false
};

export const SEL_OBJECT_ACTION = {
    GET_OBJECTS: 1,
    SEND_DATA_TO_OBJECT: 2,
};

export const UNIT_RELATION = {
    BASIC: 'основная',
    UP: 'кратная',
    DOWN: 'дольная',
};

export const OBJECT_TAB = {
    PROPS: 1,
    IMAGES: 2,
    FILES: 3
};

export const INVEST_MODE_TAB = {
	DESCR: 1,
	EXECUTE: 2,
	ANALYSIS: 3,
	VIEW: 4
};

export const TABLE_HEADER = [
    {title: 'N', type: 'order'},
    {title: 'Форма', type: 'text'},
    {title: 'Длина', type: 'text'},
    {title: 'Ширина', type: 'text'},
    {title: 'Радиус', type: 'text'},
    {title: 'Площадь', type: 'text'},
    {title: 'Комментарий', type: 'text'}
];

const PINVEST_PREFIX = 'PhysInvest%2F';
export const PINVEST_SLASH = 'PhysInvest/';

export const getPiUrl = async (name) => {
    return await getUrl(PINVEST_PREFIX, name); 
};

export const getPiUrls = async (list) => {
    return await getUrls(PINVEST_PREFIX, list); 
};

export const uploadPIFile = (file, setLoadedFileName) => {
    uploadFile(PINVEST_PREFIX, file, setLoadedFileName);
}

export const deletePIFileByName = async (dispatch, fileName) => {
    doDeleteFileByName(PINVEST_PREFIX, dispatch, fileName);
};

export const sendChangePi = (roomId, dispatch) => {
    socket.emit('BE-refresh-db', {type: DB_REFRESH.PI, roomId: roomId});
    dispatch(loadCountersPi(roomId));
};

export const getSizeUnitList = () => sizeUnitList;
export const getUnitName = _currUnitId => {
    const unit = getSizeUnitList().find(item => item.value === _currUnitId);
    return unit.label;
};
export const getTableByMeasures = (currUnitId, measureList) => {
    const unitName = getUnitName(currUnitId);
    const rows = [];

    for (let i = 0; i < measureList.length; i ++) {
        const measure = measureList[i];
        const type = measure.type;

        const row = [
            i + 1,                                              //N
            type === 1 ? 'Прямоугольник' : type === 2 ? 'Линейка' : 'Круг',
            type !== 3 ? measure.length + unitName : '',        //длина (у прямоугольника и линейки)
            type === 1 ? measure.width + unitName : '',         //ширина (у прямоугольника)
            type === 3 ? measure.length + unitName : '',        //радиус (у круга)
            type !== 2 ? measure.square + unitName + '2' : '',  //площадь (у прямоугольника и круга)
            !!measure.comment ? measure.comment : ''            //комментарий
        ];

        rows.push(row);
    }
    return rows;
};

export const viewComment = (commentId, title, content) => {
    return (
        <div id={commentId} key={'vc1'+commentId}>
            <div className={'cor-net__title'} key={'vc2'+commentId}>
                {title}
            </div>
            <div className='cor_te__preview' key={'vc3'+commentId}> 
                {content ? htmlParser(content) : ''}
            </div>
        </div>
    );
};

export const getSiValueByBasic = (dependency, fromUnit, roundNum = 4) => {
    let value = dependency.formula;
    let res;
    if (!value) {
        const ft = dependency.formulaText;
        if (ft === "x+273.15")
            res = '-273.15°С';
        else if (ft === "5*(x-32)/9")
            res = '-17.22°С';
        else if (ft === "9*x/5+32")
            res = ''; //'0°С';
        else if (ft === "x-273.15")
            res = ''; //'0°K';
    } else if (value < 10000 && value >= 0.001) {
        res = '' + value + fromUnit?.sign;
    } else {
        let order = 0;

        if (value >= 10000) {
            while(value >= 10) {
                value /= 10;
                order ++;
            }
        } else { //value < 0.001
            while(value < 1) {
                value *= 10;
                order --;
            }
        }

        res = '' + getRoundValue(value, roundNum) + '·10^+' + order + '^+' + fromUnit?.sign;
    }

    return res;
};

export const getApproxSiValueByBasic = (dependency, fromUnit, roundNum = 2) => {
    let value = dependency.formula;
    if (!value) {
        return getSiValueByBasic(dependency, fromUnit);
    } else {
        let order = 0;
        if (value >= 1) {
            while(value >= 10) {
                value /= 10;
                order ++;
            }
        } else { //value < 0.001
            while(value < 1) {
                value *= 10;
                order --;
            }
        }
        let approx = getRoundValue(value, roundNum);
        if (order > 3 || order < -3)
            return '' + approx + '·10^+' + order + '^+' + fromUnit?.sign;
        else if (order === 1) approx *= 10;
        else if (order === 2) approx *= 100;
        else if (order === 3) approx *= 1000;
        else if (order === -1) approx = '0.' + approx.toString().replace('.', '');
        else if (order === -2) approx = '0.0' + approx.toString().replace('.', '');
        else if (order === -3) approx = '0.00' + approx.toString().replace('.', '');

        return '' + approx + fromUnit?.sign;
    }
}

export const WorkLevelList = [
    {label: '7 класс', value: 7},
    {label: '8 класс', value: 8},
    {label: '9 класс', value: 9},
    {label: '10 класс', value: 10},
    {label: '11 класс', value: 11},
];

export const getFullUnitName = unit => {
    if (!unit) return '';
    return unit.sign + ' (' + unit.name + ')';
};

export const getColumnTypeList = (hasCols) => {
    const arr = [];
    arr.push({label: 'Выберите тип данных', value: COL_TYPE.UNDEFINED});
    arr.push({label: 'Число', value: COL_TYPE.NUMBER});
    arr.push({label: 'Описание', value: COL_TYPE.TEXT});
    arr.push({label: 'Изображение', value: COL_TYPE.IMAGE});
    if (USE_FORMULA && hasCols)
        arr.push({label: 'Формула', value: COL_TYPE.FORMULA});
    arr.push({label: 'Дата', value: COL_TYPE.DATE_TIME});
    return arr;
};

export const getUniqueTypes = (objects) => [{value: -1, label: 'Выберите раздел'},
    ...[...new Set(objects.map(o => o.type))].map((label,value ) => ({value, label}))];

export const getUniqueGroups = (objects) => [{value: -1, label: 'Выберите подраздел'},
    ...[...new Set(objects.map(o => o.group))].map((label,value ) => ({value, label}))].filter(item => item.label.length > 0);

export const doDownloadResearch = (research) => {
    if (!research.name) {
        toast.warn('Введите название работы.');
        return;
    }
    const serialized = JSON.stringify(research);
    const blob = new Blob([serialized], {type: "text/plain;charset=utf-8",});
    const fileName = research.name + '.corinv';
    saveAs(blob, fileName);
    toast.success("Работа '" + research.name  +"' сохранена.");
};

export const getListItems = (objectType, list, setList, editVal, setEditVal, mKey, placeholder,
        editModeData, setEditModeData, deleteById) => {
    const doAddListItem = (list, setList, editVal, setEditVal) => {
        if (!!editVal) {
            const newList = [...list];
            const elem = {text: editVal, checked: false};
            newList.push(elem);
            setList(newList);
        }
        setEditVal(''); // clean up editVal
    };
    const handleEditStart = (objectType, list, setList, setEditVal, ind) => {
        setEditVal(list[ind].text);
        setEditModeData({objectType: objectType, list: list, setList: setList, ind: ind});
    };
    const handleEditComplete = (editVal, setEditVal, isSave) => {
        if (isSave && editVal.trim()) {
            const newList = [...editModeData.list];
            newList[editModeData.ind].text = editVal;
            editModeData.setList(newList);
        }
        setEditVal('');
        setEditModeData(undefined);
    };
    const getItem = (objectType, i) => {
        return (
            <div className='targetList__item' key={mKey+'dv01'+i}>
                <p className='targetList__content' key={mKey+'sp01'+i}>
                    <span>{i+1}</span>
                    <span>{list[i].text}</span>
                </p>
                <div className='targetList__action'>
                    <div className="pi-options">
                        <Icon className="pi-options__toggle" name="dots" />
                        <div className="pi-options__content">
                            <Icon
                                name="brush"
                                key={mKey+'sp01'+i}
                                onClick={() => handleEditStart(objectType, list, setList, setEditVal, i)}
                            />
                            <Icon
                                name="trash"
                                key={mKey+'sp02'+i}
                                onClick={() => deleteById(i)}
                            />
                        </div>
                    </div>

                    <Icon key={mKey+'sp03'+i} className='drag' name="drag"/>
                </div>
            </div>);
    };

    const resList = [];

    const isEditMode =  !!editModeData && editModeData.objectType === objectType;
    const doSetEditVal = value => {
        setEditVal(getLimitedString(WORD_LIMIT, LINE_LIMIT, value));
    };

    if (!isEditMode) {
        for (let i = 0; i < list.length; i ++) {
            resList.push(getItem(objectType, i));
        }

        const newRes = (
            <div className='targetList__item add' key={mKey+'dv20'}>
                <div className='targetList__enter' key={mKey+'spp01'}>
                    <div className="cor-net__length">
                        <span>{editVal.length}/{LINE_LIMIT}</span>
                        <textarea
                            key={mKey+'inp01'}
                            placeholder={placeholder}
                            value={editVal}
                            onInput={e => doSetEditVal(e.target.value)} 
                        />
                    </div>
                </div>

                <div className='targetList__action' key={mKey+'spp02'}>
                    <div>
                        <div>
                            <Button
                                className='targetList__toggle'
                                disabled={editVal.length === 0}
                                onClick={() => doAddListItem(list, setList, editVal, setEditVal)}>
                                    Добавить
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
        resList.push(newRes);
        //=======================
    } else { //Edit mode
        //=======================
        for (let i = 0; i < editModeData.ind; i ++) {
            resList.push(getItem(objectType, i));
        }
        const editRes = (
            <div className='targetList__item edit' key={mKey+'dvp03'}>
                <div className="targetList__enter" key={mKey+'sp10'}>
                    <div className="cor-net__length">
                        <span>{editVal.length}/{LINE_LIMIT}</span>
                        <textarea 
                            key={mKey+'inp02'} 
                            value={editVal} 
                            onInput={e => doSetEditVal(e.target.value)} 
                        />
                    </div>
                </div>
                <span className='targetList__action'>
                    <Icon key={mKey+'ic01'} name="save" 
                        onClick={() => handleEditComplete(editVal, setEditVal, true)}
                    />
                    <Icon key={mKey+'ic02'} name="close" 
                        onClick={() => handleEditComplete(editVal, setEditVal, false)}
                    />
                </span>
            </div>
        );

        resList.push(editRes);

        for (let i = editModeData.ind + 1; i < list.length; i ++) {
            resList.push(getItem(objectType, i));
        }
    }
    return resList;
};

const getTargetCheckBox = (value, handle, id) => {
    return (
        <div className='targetList__action' key={'tl-a'+id}>
            <input type="checkbox"
                key={'tl-checkbox'+id}
                id={id}
                value={value}
                checked={value}
                onChange={() => handle(!value)}
            >
            </input>
            <label htmlFor={id} key={'tl-label'+id}>Выполнено</label>
        </div>
    );
};

export const getWorkTargetCheckBoxes = (_workTargets, _setWorkTargets) => {
    const handle = (ind, val) => {
        const wt = [..._workTargets];
        wt[ind] = {text: wt[ind].text, checked: !!val};
        _setWorkTargets(wt);
    };
    const targetItem = _workTargets.map((item, ind) => {
        return (
            <div className='targetList__item' key={'wtg01'+ind}>
                <p className='targetList__content' key={'wtg02'+ind}>
                    <span key={'wtg03'+ind}>{ind+1}</span>
                    <span key={'wtg04'+ind}>{item.text}</span>
                </p>
                {getTargetCheckBox(item.checked, val => handle(ind, val), ind)}
            </div>
        )
    })

    return (
        <div className="targetList">
            {targetItem}
        </div>
    );

};

export const getRbBox = (options, value, setValue, key) => {
    return (
        options.split('|').map((item, ind) =>
            <span key={key+ind}>
                <input type="checkbox"
                    value={ind}
                    name={item}
                    checked={ind === value}
                    onClick={(e) => setValue(Number(e.target.value))}
                    onChange={() => {}}
                    >
                </input>
                &nbsp;<label htmlFor={ind}>{item}</label>
            </span>)
    );
};

const getArrayRbBox = (options, hypo, index, _workHypothesis, _setWorkHypothesis) => {
    const handle = (_index, value) => {
        const wh = [..._workHypothesis];
        wh[_index] = {text: wh[_index].text, checked: value === 0};
        _setWorkHypothesis(wh);
    };

    const selectedInd = hypo.checked ? 0 : 1;

    const option = options.split('|').map((item, ind) => {
        return (
            <label key={"rbkey"+index + ind}>
                <input type="checkbox"
                    className='checkbox_square'
                    value={ind}
                    name={item}
                    checked={ind === selectedInd}
                    onClick={(e) => handle(index, Number(e.target.value))}
                    onChange={() => {}}
                    >
                </input>
                <span>{item}</span>
            </label>
        )
    })

    return (
        <div className="targetList__action targetList__action_opton">
            {option}
        </div>
    );
};

export const getWorkHypothesisRbCheckBoxes = (_workHypothesis, _setWorkHypothesis) => {
    const hypothesisItem = _workHypothesis.map((item, index) => {
        return (
            <div className="targetList__item" key={'hypo-item'+index}>
                <p className="targetList__content">
                    <span>{index+1}</span>
                    <span>{item.text}</span>
                </p>
                {getArrayRbBox('Достигнута|Не достигнута', item, index, _workHypothesis, _setWorkHypothesis)}
            </div>
        )
    })

    return (
        <div className="targetList">
            {hypothesisItem}
        </div>
    );
};

export const getWorkTargetResults = (_workTargets) => {
    const targetResultItem = _workTargets.map((item, ind) => {
        return (
            <div className='targetList__item' key={'wtgr01'+ind}>
                <p className="targetList__content">
                    <span>{ind+1}</span>
                    <span>{item.text}</span>
                </p>
                <div className={item.checked ? 'targetList__status success' : 'targetList__status'}>
                    {item.checked ? 'Достигнуто' : 'Не достигнуто'}
                </div>
            </div>
        )
    })

    return (
        <div className="targetList">
            {targetResultItem}
        </div>
    )
};

export const getWorkHypothesisResults = (_workHypothesis) => {
    const hypothesResultItem = _workHypothesis.map((item, ind) => {
        return (
            <div className='targetList__item' key={'wtgr01'+ind}>
                <p className="targetList__content">
                    <span>{ind+1}</span>
                    <span>{item.text}</span>
                </p>
                <div className={item.checked ? 'targetList__status success' : 'targetList__status'}>{item.checked ? 'Подтверждена' : 'Опровергнуто'}</div>
            </div>

        )
    })

    return (
        <div className="targetList">
            {hypothesResultItem}
        </div>
    )
};

export const getViewCommentId = (uid) => 'COMMENT'+uid;
export const getStructureId = uid => "STRUCT" + uid;
export const getCanvasId = (uid) => "CANVAS" + uid;
export const getDrawToolImageId = uid => "DTIMAGE" + uid;

const getCategoryList = (piQuants) => {
    const qList = [];
    for (let [value, label] of Object.entries(piQuants))
        qList.push({label: label.name + (label.sign ? ' (' + label.sign + ')' : ''), value: value});
    const quants = qList.sort((a, b) => a.label.localeCompare(b.label));
    return quants;
};
const getCapital = s => s[0].toUpperCase() + s.slice(1);

export const getCategories = (piQuants) => {
    const list = [];
    list.push({label: 'Выберите категорию', value: -1});
    const categories = getCategoryList(piQuants);
    for (let i = 0; i < categories.length; i ++)
        list.push({label: categories[i].label, value: categories[i].value});
    return list;
};

export const getSystemList = (piSystems) => {
    const sList = [];
    for (let [value, obj] of Object.entries(piSystems)) {
        sList.push({label: getCapital(obj.name), value: value});
    }
    return sList;
};

export const isCustomSystem = (piSystems, id) => {
    for (let [value, obj] of Object.entries(piSystems)) {
        if (value === id) {
            return !!obj.owner;
        }
    }
    return false;
};

export const getSystems = (piSystems) => {
    const list = [];
    list.push({label: 'Выберите систему измерения', value: -1});
    const systems = getSystemList(piSystems);
    for (let i = 0; i < systems.length; i ++)
        list.push({label: systems[i].label, value: systems[i].value});
    return list;
};

//is it needed?
// export const getHtmlLine = (text, key = '') => {
//     if (!text.includes('<sup>')) return text;
//     const SEP = '^+';
//     const sepText = text.replaceAll('<sup>', SEP).replaceAll('</sup>', SEP);
//     const arr = sepText.split(SEP);
//     const res = arr.map((item, ind) => ind % 2 === 0 ? 
//         <span key={'spn1'+key+ind}>{item}</span> : 
//         <sup key={'sup1'+key+ind}>{item}</sup>);
//     return <span key={'spr1'+key}>{res.join()}</span>;
// };

export const getTValue = (selUnit, curUnit) => {
    const c = 'Цельсия';
    const f = 'Фаренгейта';
    const k = 'Кельвина';
    if (!selUnit) return curUnit.sign;

    if (selUnit.sign === curUnit.sign) {
        return '0 градусов ' + (selUnit.sign === '°С' ? c : selUnit.sign === '°Ф' ? f : k);
    }

    if (selUnit.sign === '°С') {
        return '0°С = ' + (curUnit.sign	=== '°Ф' ? '32 ' + f : '273.15 ' + k);
    } else if (selUnit.sign === '°К') {
        return '0°К = ' + (curUnit.sign	=== '°Ф' ? '-459.67 ' + f : '-273.15 ' + c);;
    } else if (selUnit.sign === '°Ф') { //°С
        return '0°Ф = ' + (curUnit.sign === '°С' ? '-17.8 ' + c : '255.37 ' + k);
    }
};

export const getObjTabs = () => [
	{tabId: OBJECT_TAB.PROPS, tabName: 'Свойства'},
	{tabId: OBJECT_TAB.IMAGES, tabName: 'Изображения'},
	{tabId: OBJECT_TAB.FILES, tabName: 'Файлы'},
];

export const getSwitcherHasLabel = (options, isChecked, _handleClickToggle) => {
    const optList = options.split('|');

    const getToggleOption = (isChecked, isUnique) => {
        const active = (isChecked && isUnique) || (!isChecked && !isUnique);
        return (
            <span className={active ? 'active' : ''}>
                {isUnique ? optList[0] : optList[1]}
            </span>
        );
    };

    return (
        <label className='cor_switcher_has_labels'>
            {getToggleOption(isChecked, true)}
            <Switcher
                onChange={_handleClickToggle}
                checked={isChecked ? false : true}
                className="contrast"
            />
            {getToggleOption(isChecked, false)}
        </label>
    );
};

export const showObjProperty = (label, item, ind, isEdit, handleDeleteProp) => {
    const isLink = item.value.startsWith('http');
    return (
        <li key={'dds01'+ind}>
            <span key={'tbs02'+ind}>{label}{'  '}</span>
            {isLink ?
            <a key={'tbs03'+ind} href={item.value} target="_blank" rel="noreferrer">
                Перейти по ссылке
                <Icon name="share-slim" />
            </a>
            :
            <b key={'tbs03'+ind}>{item.value}</b>
            }

            {isEdit &&
                <Icon name="trash" onClick={() => handleDeleteProp(ind)} key={'ei02'+ind} />
            }
        </li>
    );
};

export const getObjectView = (isTemplate, withTabs, objName, objType, objGroup, 
    objDescription, objProps,
    piProperties, tabInd, setTabInd, handleDeleteProp,
    fileUrls,
    objFiles, handleAddFile, handleDeleteFile, uploadProgress,
    handleShowImage, handleDownloadFile) => {

    return (
        <div className="cor-net mt_lg" key={'vn01'}>
            <div className="cor-net__section">
                <ul className="cor-net__list">
                    <li key={'vn02'}>
                        <span>Раздел:</span>
                        <b>{objType}</b>
                    </li>
                    <li key={'vn03'}>
                        <span>Подраздел:</span>
                        <b>{objGroup}</b>
                    </li>
                </ul>
            </div>

            {objName &&
            <div className="cor-net__section">
                <div className="cor-net__title" key={'vn04'}>Название {htmlParser(objName)}</div>
            </div>
            }

            <div className="cor-net__section">
                <div className="cor-net__title" key={'vn04'}>Описание</div>
                <div className="pi__content" key={'vn05'}>{htmlParser(objDescription)}</div>
            </div>

            <div className="cor-net__section">
                <div className="cor-net__row">
                    <div className="cor-net__col col-2">
                        {withTabs &&
                            <Tabs key={'vn10'}>
                            {getObjTabs().map(tab => (
                                <Tabs.Item active={tabInd === tab.tabId}
                                    onClick={() => setTabInd(tab.tabId)}
                                    key={'tab'+ tab.tabId}>
                                    {tab.tabName}
                                </Tabs.Item>)
                            )}
                            </Tabs>
                        }
                    </div>
                </div>
            </div>
            <div className="cor-net__section">
                {(tabInd === OBJECT_TAB.PROPS || (!withTabs && objProps.length > 0)) &&
                    <div key={'tb0'}>
                        <div className="cor-net__title">Свойства</div>
                        <ul className="cor-net__list">
                            {objProps.map((item, ind) => showObjProperty(piProperties[item.id], item, ind, false, handleDeleteProp))}
                        </ul>
                        <div className="cor-net__title"></div>
                    </div>
                }
                {(tabInd === OBJECT_TAB.IMAGES || (!withTabs && objFiles.length > 0)) &&
                    <div key={'tb3'}>
                        <div className="cor-net__title">Изображения</div>
                        {getFileList(isTemplate, false, true,
                            fileUrls, 
                            objFiles, handleAddFile, handleDeleteFile, uploadProgress,
                            handleShowImage)}
                        <div className="cor-net__title"></div>
                    </div>
                }
                {(tabInd === OBJECT_TAB.FILES || (!withTabs && objFiles.length > 0)) &&
                    <div key={'tb4'}>
                        <div className="cor-net__title">Файлы</div>
                        {getFileList(isTemplate, false, false,
                            fileUrls, 
                            objFiles, handleAddFile, handleDeleteFile, uploadProgress,
                            handleDownloadFile)}
                    </div>
                }
            </div>
        </div>
    );
};

const hasStringifiedFiles = (files) => {
    if (files.length === 0) return false;
    const f = files[0].name;
    return (f.includes('isImage') && f.includes('content'));
};

export const loadObjectFileUrls = async (objFiles, setFileUrls) => {
    const list = [];
    for (let i = 0; i < objFiles.length; i ++) {
        const name = objFiles[i].name;
        if (!name.includes('content')) {
            const url = await getPiUrl(name);
            list.push({name: objFiles[i].name, url: url});
        }
    }
    setFileUrls(list);
};

const getFiles = (fileUrls, fileNameList, isImgFileAcceptable) => {
    //we need to find all files from fileNameList that are not removed from the collection on any reason
    if (fileUrls.length === 0 || fileNameList.length === 0) return [];
    const checkedFiles = [];

    for (let k = 0; k < fileNameList.length; k ++) {
        const fn = fileNameList[k];

        const isImage = isImageFile(fn);
        const isAcceptable = (isImgFileAcceptable && isImage) || (!isImgFileAcceptable && !isImage);

        if (isAcceptable) {
            const url = fileUrls.find(item => item.name === fn);
            if (url)
                checkedFiles.push(url);
        }
    }

    return checkedFiles; //the files exist in the collection
};

export const getFileList = (isTemplate, isEdit, isImage, 
        fileUrls, objFiles, 
        handleAddFile, handleDeleteFile, uploadProgress, handleShowFile) => {
    let foundFiles;
    let isCurrTemplate = isTemplate;
    let _objFiles = [...objFiles];

    if (!isTemplate) {
        let currFiles = objFiles.filter(item => !item.isDeleted); //.map(item => item.name);
        if (isImage) {
            currFiles = currFiles.filter(item => isImageFile(item.name));
        } else {
            currFiles = currFiles.filter(item => !isImageFile(item.name));
        }
        foundFiles = getFiles(fileUrls, currFiles.map(item => item.name), isImage);

        //==SPECIAL CASE WHEN we have a user research but it uses template objects:
        if (foundFiles.length === 0 && hasStringifiedFiles(objFiles)) {
            _objFiles = objFiles.filter(item => item.name).map(item => item.name);
            if (_objFiles.length > 0) {
                foundFiles = _objFiles.map(item => JSON.parse(item))
                    .filter(item => item.isImage === isImage)
                    .map(item => JSON.stringify(item));
                isCurrTemplate = true;
            }
        }
    } else {
        // const x = objFiles.map(item => JSON.parse(item));
        // const y = x.filter(item => item.isImage === isImage)
        // foundFiles = y.map(item => JSON.stringify(item));
        foundFiles = objFiles.map(item => JSON.parse(item))
            .filter(item => item.isImage === isImage)
            .map(item => JSON.stringify(item));
    }

    const accept = isImage ? 'image/*' :
        'audio/*,video/*, .txt, .csv, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf';

    return (
        <div className="objectGrid__wrap">
            <Grid className="objectGrid cor-storage" key='grid01'>
                {foundFiles.map((f, i) => (
                    getFileInfo(isCurrTemplate, isImage, _objFiles, f, i, 
                        isEdit, handleDeleteFile, handleShowFile)
                ))}

            </Grid>
            {isEdit &&
                <div className="objectGrid__add">
                    <AddFile 
                        accept={accept}
                        onChange={(e) => handleAddFile(e)}
                        progress={uploadProgress}
                    />
                </div>
            }
        </div>
    );
};

const getFileInfo = (isTemplate, isImage, _objFiles, f, i, isEdit, handleDeleteFile, handleShowFile) => {
    const fileIndex = i;
    const isShowPreview = isImage;
    let content;
    let shortFileName;
    
    if (!isTemplate) {
        content = f.url;
        shortFileName = f.name;
    } else {
        const fileBody = JSON.parse(f);
        content = fileBody.content;
        shortFileName = fileBody.name;
    }

    return (
        <Grid.Col className={isShowPreview ? "objectGrid__item objectGrid__item_img" : "objectGrid__item"} key={'objitem'+i}>
            {isShowPreview ?
                <div
                    className="objectGrid__img"
                    key={'oiimage'+i}
                    onClick={() => handleShowFile(f)}>
                    <img src={content} alt={''} />
                </div>
                :
                <div
                    className="objectGrid__file"
                    key={'oifile'+i} >

                    <a href={content} download 
                            className="objectGrid__a" target="_blank" rel="noreferrer"
                            onClick={() => handleShowFile({Url: f.url})}> 
                        <div className="objectGrid__caption">{shortFileName}</div>
                        {/* {!isTemplate &&
                        <div>
                            <div className="objectGrid__date info">{printOnlyDate(f.LastModified)}</div>
                            <div className="objectGrid__size info">{formatBytes(f.Size)}</div>
                        </div>
                        } */}
                        {/* <div className="objectGrid__icon">
                            <Icon name={typeIconName} />
                        </div> */}
                    </a>
                </div>
            }

            {isEdit &&
                <Trash
                    className="objectGrid__trash"
                    bg={true}
                    onClick={() => handleDeleteFile(fileIndex, shortFileName)} 
                    key={'oitrash'+i}
                />
            }
        </Grid.Col>
    );
};

const fileTypeIcon = (name) => {
    const getExtension = (name) => name.lastIndexOf('.') > -1 ? name.split('.').pop().toLowerCase() : '';
    switch (getExtension(name)) {
        case "mp4":
            return "film";
        case "csv":
        case "xls":
        case "xlsx":
            return "file-excel";
        case "doc":
        case "docx":
            return "file-word";
        case "ppt":
        case "pptx":
        case "pdf":
            return "file-pp";
        default:
            return "file";
    }
};

const selectTableFields = (arr) => {
    return arr.map(item => ({
        author: item.author,
        compactDate: item.compactDate,
        index: item.index,
        title: item.title,
        rows: item.rows, //проблемы
        properties: item.properties,
        uid: item.uid,
    }));
};

export const clearLoadedTables = (arr) => {
    const list = selectTableFields(arr);
    const updList = [];

    for (let i = 0; i < list.length; i ++) {
        let table = {...list[i]};

        //1 - clear non-existing image
        for (let k = 0; k < table.rows.length; k ++) {
            const row = table.rows[k];
            for (let m = 0; m < row.length; m ++) {
                if (row[m] && row[m].toString().includes('data:image'))
                    row[m] = '';
            }
        }

        //2 - remove forula from columns and rows
        for (let k = table.properties.length - 1; k >= 0 ; k --)
            if (table.properties[k].type === 'formula') {
                table.properties = table.properties.filter((item, ind) => ind !== k);
                for (let m = 0; m < table.rows.length; m ++)
                    table.rows[m] = table.rows[m].filter((item, ind) => ind !== k);
            }

        //3
        updList.push(table);
    }

    return updList;
};

export const calcImageSize = (naturalWidth, naturalHeight, mainR) => {
    const WIDTH_PCT = 0.95;
    let width = mainR.clientWidth * WIDTH_PCT;
    let height = width * (naturalHeight / naturalWidth);
    return [Math.floor(width - IMAGE_SHIFT_X), Math.floor(height - IMAGE_SHIFT_Y)];
};

export const checkDrawTools = (drawTools) => {
    const list = [];
    for (let i = 0; i < drawTools.length; i ++) {
        let dt = drawTools[i];
        if (!dt.image)
            dt = {...dt,
            image: {
                name: undefined, //image name
                frameData: null, //image data
                measureList: [],
            }
        };
        list.push(dt);
    }
    return list;
};

export const getHeaderCell = (tableIndex, cell, cellProperties, cellInd, pi) => {
    let unit = '';
    if (pi && pi.units.length > 0 && cellProperties.unit && (cellProperties.type === COL_TYPE.NUMBER || cellProperties.type === COL_TYPE.FORMULA)) {
        unit = getPhysicalUnitById(pi, cellProperties.unit);
        if (unit.includes('(')) {
            const arr = unit.split('(');
            unit = arr[1].replace(')', '');
        }
        unit = ' [' + unit + ']';
    }

    if (cellProperties.type === COL_TYPE.FORMULA) {
        return (
            <div key={'hf01'+tableIndex+cellInd}>
                {cell}{unit} ({cellProperties.formula})
            </div>
        );
    } else {
        const fRef = USE_FORMULA && cellInd !== 0 ? ('(C' + cellInd + ')') : '';
        return <>{cell}{unit} {fRef}</>;
    }
};

export const getFormulaValue = (rows, cols, rowIndex, cellIndex) => {
    const row = rows[rowIndex];
    let formula = cols[cellIndex].formula.toLowerCase().replaceAll(' ', '');

    const c = defaultVariables;
    const f = defaultFunctions;
    const e = defaultErrorHandler;

    for (let i = 0; i < cellIndex; i ++) {
        const c = 'c' + (i+1);
        if (formula.includes(c)) {
            const value = row[i+1].toString();
            if (formula.includes('sqrt('+c+')')) {
                formula = formula.replaceAll('sqrt('+c+')', 'pow('+c+',0.5)');
            }
            if (!value) return '';
            formula = formula.replaceAll(c, Number(value));
        }
    }

    if (formula.includes('π')) formula = formula.replaceAll('π', '3.141592653');
    const equation = parse(formula, null, { variables: defaultVariables });
    if (equation.type === "parser-error") { //see https://github.com/kgram/equation-parser
        let err = 'Формула содержит ошибку. ';
        return err + equation.errorType;
    }
    const result = resolve(equation, {functions: defaultFunctions});//π
    if (result.type === "resolve-error") { //see https://github.com/kgram/equation-resolver#defaultfunctions
        let err = 'Вычисление по формуле приводит к ошибке. ';
        if (result.errorType === 'functionSqrt1Positive') return 'Аргумент функции должен быть положительным.';
        if (result.errorType === 'variableUnknown') return 'Аргумент функции содержит неизвестную переменную.';
        
        return err + result.errorType;
    } else if (isNaN(result.value) || result.value === -Infinity || result.value === Infinity) {
        return 'Недопустимое значение аргумента.';
    }

    return parseInt(result.value * 1000) / 1000;
};

export const getViewTableContent = (imgCellUrls, _header, _rows, _tableIndex, handleShowFile, pi) => {
    const getViewCell = (cellValue, cellType, rowIndex, cellIndex) => {
        const uniqueInd = ""+_tableIndex+"|"+rowIndex+"|"+cellIndex;
        const type = cellType;

        if (type === COL_TYPE.ORDER) {
            return (<div key={'cl01'+uniqueInd}>{cellValue}</div>);
       }

        let cell;
        if (type === COL_TYPE.IMAGE) {
            const rec = imgCellUrls.find(item => item.col === cellIndex && item.row === rowIndex);
            if (!rec) return <></>;

			cell = (<div key={'img01'+uniqueInd} onClick={() => {handleShowFile({Url: rec.url})}}>
				<img src={rec.url}
                    alt=''
                    style={{width: 30, height: 30}}
                    key={'img02'+uniqueInd}
                    crossOrigin="anonymous"
                />
			</div>);
        } else if (type === COL_TYPE.DATE_TIME) {
            cell = printOnlyDate(cellValue);
        } else if (type === COL_TYPE.NUMBER) {
            cell = cellValue;
        } else if (type === COL_TYPE.FORMULA) {
            cell = getFormulaValue(_rows, _header, rowIndex, cellIndex);
		} else { //if (type === COL_TYPE.TEXT) {
            cell = cellValue;
        }

        return cell;
    };

    const tableHeader = _header.map(item => item.title);

    return (
            <table className="cor_table pi-table" key={'tb01'+_tableIndex}>
                <thead key={'thd01'+_tableIndex}>
                    <tr key={'tr01'+_tableIndex}>
                        {tableHeader.map((cell, cellInd) =>
                            <th key={'th01'+_tableIndex+'_'+cellInd}>
                                {getHeaderCell(_tableIndex, cell, _header[cellInd], cellInd, pi)}
                            </th>)}
                    </tr>
                </thead>
                <tbody key={'tbd01'+_tableIndex}>{_rows.map((row, rowInd) =>
                    <tr key={'tr01'+_tableIndex+'_'+rowInd}>
                        {row.map((cell, cellInd) => <td key={'cell01'+_tableIndex+'_'+rowInd+'_'+cellInd}>
                            {getViewCell(cell, _header[cellInd].type, rowInd, cellInd)}
                        </td>)}
                    </tr>)}
                </tbody>
            </table>
    );
};

export const getPhysicalUnitById = (pi, id) => {
    const list = getPhysicalUnitList(pi).find(item => item.value === id);
    return list ? list.label : '';
}

export const getPhysicalUnitList = (pi) => {
    const arr = [];
    for (let i = 1; i < pi.units.length; i ++) {
        const unit = pi.units[i];
        const sign = unit.sign.replace('<sup>', '').replace('</sup>', '');
        const fullUnitName = unit.name + ' (' + sign + ')';
        arr.push({label: '' + fullUnitName, value: unit._id});
    }
    const SHIFTED_UNITS_NUM = 4;
    let arr2 = arr.sort((a, b) => a.label.localeCompare(b.label));
    const beMoved = arr2.filter((item, ind) => ind < SHIFTED_UNITS_NUM);
    arr2.splice(0, SHIFTED_UNITS_NUM); //удалим 3 в начале
    const arr3 = [...arr2, ...beMoved]; //добавим их в конце
    return arr3;
};

export const getCellText = (text, cn, key) => {
    const  isNumber = (value) => typeof value === 'number';
    const SUP = '^+';
    const SUB = '^-';
    let shift = 0;
    let arr;

    if (!isNumber(text) && text?.includes('<sup>')) {
        text = text.replaceAll('<sup>', SUP).replaceAll('</sup>', SUP);
    }

    if (!isNumber(text) && text?.includes(SUP)) { //item обязательно должен быть строкой
        shift = 1;
        arr = text.split(SUP);
    } else if (!isNumber(text) && text?.includes(SUB)) {
        shift = -1;
        arr = text.split(SUB);
    } else
        return <span key={'ab'+key} className={cn}>{text}</span>;

    const list = [];
    for (let i = 0; i < arr.length; i ++)
        if (i%2 === 0)
            list.push(<span key={'cd'+key + 100 * i}>{arr[i]}</span>);
        else {
            if (shift === 1)
                list.push(<sup key={'form1'+i} >{arr[i]}</sup>);
            else 
                list.push(<sub key={'form2'+i} >{arr[i]}</sub>);
       }
    
    return (
        <span className={cn} key={'spn01'+key}>
            {list.map(item => item)}
        </span>
    );
};

//парсер и резолвер
//https://github.com/kgram/react-equation?tab=readme-ov-file
// import {parse} from 'equation-parser';
// import {resolve} from 'equation-resolver';
// const eq = parse('1+2*3+2/4');        const result = resolve(eq);        const value = result.value;
//yarn add react-equation equation-resolver