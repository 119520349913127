import React, {useEffect, useState} from "react";
import TextEditor from '../ui/TextEditor/TextEditor';
import {Content, ContentBody, ContentFooter, ContentHeader, ContentSubHeader} from "../template/ContentParts";
import {toast} from "react-toastify";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import PISelectObjectDlg from "./PISelectObjectDlg";
import PISelectReviewerDlg from "./PISelectReviewerDlg";
import PIChangeCompositionDlg from "./PIChangeCompositionDlg";
import {INV_ELEM_TYPE, INVEST_MODE_TAB,	WORK_STATE, BLANK_FILE,
	checkDrawTools,	clearLoadedTables, DEFAULT_WORK_LEVEL, doDownloadResearch, getCanvasId, 
	getListItems, getStructureId, getWorkHypothesisRbCheckBoxes, getWorkHypothesisResults, 
	WorkLevelList, getWorkStateByStatus, getWorkTargetCheckBoxes, getWorkTargetResults, viewComment,
	deletePIFileByName, sendChangePi} from "./pi_utils";
import ModalPrint from '../ui/ModalDialogs/ModalPrint';
import parser from 'html-react-parser';
import {getDropdown} from "../ui/utils/gen_utils";
import {Button, Icon, InputLimit, Tabs} from '../ui';
import ImageDlg from "../ui/ModalDialogs/ImageDlg";
import {piApi} from "../../api/api";
import PIOneTable from "./PIOneTable";
import PIOneComment from "./PIOneComment";
import PIOneObject from "./PIOneObject";
import PIOneDrawTool from "./PIOneDrawTool";
import PIOneGraphics, {convertTableToPlot} from "./PIOneGraphics";
import PIOneDragAndDropList from "./PIOneDragAndDropList";
import {storageClass} from "../../redux/slices/storage";
import {fullName, isAuth} from "../../helpers/auth";
import {useDispatch, useSelector} from "react-redux";
import {loadPIObjects} from "../../redux/slices/lists";
import {FEATURES} from "../../api/config";
import {getStyles} from "../Charts/ChartHelper";
import "../ui/ModalDialogs/PracticumTests.scss";
import "./PInvest.scss";

const OBJECT_TYPE = {
	TARGET: 1,
	HYPOTHESIS: 2, 
};

const THEME_LIMIT = 40;

const INVESTIGATION_ID = 'investigationId';
const TOPIC_ID = 'topicId';
const TARGETS_ID = 'targetsId'
const HYPOTHESIS_ID = 'hypothesisId';
const DESCRIPTION_ID = 'workDescriptionId';
const PLAN_ID = 'workPlanId';
const CONCLUSION_ID = 'workConclusionsId';

const PInvestigationsOne = (props) => {
	const selectedWorkId = props.match.params.id ?? '-1';

	const [tabInd, setTabInd] = useState(selectedWorkId === '-1' ? INVEST_MODE_TAB.DESCR : INVEST_MODE_TAB.VIEW);
	const [selectedResearch, setSelectedResearch] = useState(undefined);
	const [isTemplate, setIsTemplate] = useState(false);
	const [isDisabledTemplate, setIsDisabledTemplate] = useState(false);
	//
	const [workName, setWorkName] = useState("");
	const [workTheme, setWorkTheme] = useState("");
	const [workAuthor, setWorkAuthor] = useState();
	const [workLevel, setWorkLevel] = useState(DEFAULT_WORK_LEVEL);
	const [workStatus, setWorkStatus] = useState(WORK_STATE.WORK.status);
	const [workDescription, setWorkDescription] = useState("");
	const [workTargets, setWorkTargets] = useState([]);
	const [workHypothesis, setWorkHypothesis] = useState([]);
	const [workPlan, setWorkPlan] = useState("");
	const [workConclusions, setWorkConclusions] = useState("");
	const [workMeasurements, setWorkMeasurements] = useState([]);
	const [workComments, setWorkComments] = useState([]);
	const [workObjects, setWorkObjects] = useState([]);
	const [workPlots, setWorkPlots] = useState([]);
	const [workDrawTools, setWorkDrawTools] = useState([]);
	const [workComposition, setWorkComposition] = useState([]);
	//
	const [targetValue, setTargetValue] = useState([]);
	const [hypoValue, setHypoValue] = useState([]);
	const [editModeData, setEditModeData] = useState(undefined);
	const [showSelectObjectDlg, setShowSelectObjectDlg] = useState(false);
	const [showChangeCompositionDlg, setShowChangeCompositionDlg] = useState(false);
	const [showPrint, setShowPrint] = useState(false);
	const [drawToolImages, setDrawToolImages] = useState([]);
	const [showImageDlg, setShowImageDlg] = useState(false); //show table or object image
	const [shownFile, setShownFile] = useState('');
    const [confirmQuestion, setConfirmQuestion] = useState('');
    const [showConfirmDeleteDlg, setShowConfirmDeleteDlg] = useState(false);
    const [showSelectReviewerDlg, setShowSelectReviewerDlg] = useState(false);
	
	const documentClass = useSelector(storageClass);
    const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadPIObjects(documentClass._id));
	}, [dispatch, documentClass._id]);
	
	useEffect(() => {
		const _isTemplate = !selectedResearch ? 
			isAuth().role === 3 : workStatus === WORK_STATE.TEMPLATE.status;
		setIsTemplate(_isTemplate);
		setIsDisabledTemplate(isAuth().role < 3 && workStatus === WORK_STATE.TEMPLATE.status);
	}, [selectedResearch, workStatus]);
	
	//Измерение мощности и работы тока в электрической лампе
	useEffect(() => {
		const getLevelByTheme = theme => {
			const arr = theme.split(', ');
			if (arr.length < 2) return DEFAULT_WORK_LEVEL;
			const arr2 = arr[1].split(' ');
			return Number(arr2[0]);
		};

		const readSelectedResearch = async () => {
			//upload the reseach from DB
			const research = await piApi.getResearchById(selectedWorkId);
			setSelectedResearch(research);
			setWorkName(research.name);
			setWorkTheme(research.theme);
			setWorkLevel(research.level ? research.level : getLevelByTheme(research.theme));
			setWorkAuthor(research.owner);
			setWorkStatus(research.status !== undefined ? research.status : WORK_STATE.WORK.status);
			setWorkDescription(research.desc);
			setWorkTargets(research.targets.map(item => ({text: item.text, checked: item.checked})));
			setWorkHypothesis(research.hypothesis.map(item => ({text: item.text, checked: item.checked})));
			setWorkPlan(research.plan);
			setWorkMeasurements(!research.owner ? clearLoadedTables(research.measurements) : research.measurements);
			setWorkComments(!research.comments ? [] : research.comments);
			setWorkObjects(research.objects);
			setWorkPlots(research.plots);
			setWorkDrawTools(checkDrawTools(research.paintTools));
			setWorkConclusions(research.conclusion);
			setWorkComposition(research.composition.filter(item => item.type !== 'drawTool'));
		};

		if (selectedWorkId === '-1') {
			const research = props.location.state?.content ? JSON.parse(props.location.state?.content) : undefined;
			setWorkName(!research ? '' : research.title ? research.title : research.name);
			setWorkTheme(!research ? '' : research.theme);
			setWorkLevel(!research || !research.level ? DEFAULT_WORK_LEVEL : research.level);
			setWorkAuthor(!research ? isAuth() : research.owner);
			setWorkStatus(!research ? (isAuth().role === 3 ? WORK_STATE.TEMPLATE.status : WORK_STATE.WORK.status) : research.status);
			setWorkDescription(!research ? '' : research.desc ? research.desc : research.description); //WRONG????????????????
			setWorkTargets(!research ? [] : research.targets); 
			setWorkHypothesis(!research ? [] : research.hypothesis ? research.hypothesis : research.hepothesis); //warning: our internal name is different from name in .corinv
			setWorkPlan(!research ? '' : research.plan);
			setWorkMeasurements(!research ? [] : research.measurements ? research.measurements : clearLoadedTables(research.mesurments));
			setWorkComments(!research ? [] : research.comments);
			setWorkObjects(!research ? [] : research.objects ? research.objects : []);
			setWorkPlots(!research ? [] : research.plots);
			setWorkDrawTools(!research ? [] : checkDrawTools(research.paintTools));
			setWorkConclusions(!research ? '' : research.conclusion);
			setWorkComposition(!research ? [] : research.composition);
		} else {
			readSelectedResearch();
		}
	}, [props.location.state?.content, selectedWorkId]);

	useEffect(() => { //check Composition Content
		const list = [];
		for (let i = 0; i < workComposition.length; i ++) {
			const content = workComposition[i];
			if (content.type !== INV_ELEM_TYPE.COMMENT_ID ||
				!!workComments.find(item => item.uid === content.uid))
					list.push(content);
		}
		if (workComposition.length !== list.length) setWorkComposition(list);
	}, [workComposition, workComments]);

	const getCurrentResearch = () => {
		const research = {...selectedResearch, 
			name: workName, 
			desc: workDescription, 
			theme: workTheme, 
			level: workLevel,
			targets: workTargets, 
			hypothesis: workHypothesis, 
			plan: workPlan,
			measurements: workMeasurements,
			comments: workComments,
			objects: workObjects,
			plots: workPlots,
			paintTools: workDrawTools,
			composition: workComposition,
			conclusion: workConclusions
		};

		if (!research.status) {
			research.status = WORK_STATE.WORK.status;
		}
		if (!research.room) {
			research.room = documentClass._id;
		}

		return research;
	};

	const getInvestModeTabs = () => { 
		if (isDisabledTemplate) {
			return [{tabId: INVEST_MODE_TAB.VIEW, tabName: 'Просмотр'}];
		}

		return [
			{tabId: INVEST_MODE_TAB.DESCR, tabName: 'Описание'},
			{tabId: INVEST_MODE_TAB.EXECUTE, tabName: 'Проведение'},
			{tabId: INVEST_MODE_TAB.ANALYSIS, tabName: 'Анализ'},
			{tabId: INVEST_MODE_TAB.VIEW, tabName: 'Просмотр'},
		];
	};

	const handleBack = () => {
		props.history.push({pathname: FEATURES.pi.to, state:{status: workStatus}});
	};

	const handleDeleteTargetById = (ind) => {  
		const t = [...workTargets];
		t.splice(ind, 1);
		setWorkTargets(t);
	};
	const handleDeleteHypothesisById = (ind) => { 
		const h = [...workHypothesis];
		h.splice(ind, 1);
		setWorkHypothesis(h);
	};
	
	const handleSaveResearchToComputer = () => {
		const research = getCurrentResearch();
		doDownloadResearch(research);
	};

	const handleSaveResearch = () => {
		const addResearch = async (research) => {
			research.room = documentClass._id;
			research.status = isAuth().role === 3 ? WORK_STATE.TEMPLATE.status : WORK_STATE.WORK.status;
			piApi.addResearch(research)
			.then((result)=>{
				props.history.push(FEATURES.pi.to + "/" + result._id);
				sendChangePi(documentClass._id, dispatch);
				toast.success("Работа '" + workName + "' сохранена в раздел '" +
					(isAuth().role === 3 ? WORK_STATE.TEMPLATE.name : WORK_STATE.WORK.name) + "'.");
			}).catch((err) => {
				toast.error(err.response.data.errors);
			});
		};
		const updateResearch = async (research, id) => {
			piApi.updateResearchById(id, research)
			.then((result)=>{
				setSelectedResearch(result);
				toast.success("Работа '" + workName + "' сохранена.");
				sendChangePi(documentClass._id, dispatch);
			}).catch((err) => {
				toast.error(err.response.data.errors);
			});
		};

		const research = getCurrentResearch();
		if (!research.name) {
			toast.warn('Введите название работы.');
			return;
		}
		if (selectedWorkId === '-1' || (isAuth().role < 3 && !research.owner)) {
			addResearch(research);
		} else {
			updateResearch(research, selectedWorkId);
		}
	};

	const handleStudentSendToCheck = () => {
		if (!selectedResearch) {
			toast.warn('Сохраните задание перед отправкой на проверку.');
			return;
		}
		const teachers = documentClass.classUsers.filter(item => item.role > 0);
		if (teachers.length === 1) {
			handleChangeResearchReviewerState(teachers[0]._id);
		} else {
			setShowSelectReviewerDlg(true);
		}
	};
	const handleTeacherSendToReject = () => {
		handleChangeResearchState(WORK_STATE.WORK.status, "Работа возвращена ученику.");
	};
	const handleTeacherSendToAccept = () => {
		handleChangeResearchState(WORK_STATE.CHECKED.status, "Работа проверена учителем.");
	};
	const handleTeacherSendToPublish = () => {
		handleChangeResearchState(WORK_STATE.PUBLISHED.status, "Работа опубликована учителем.");
	};

	const handleChangeResearchReviewerState = (reviewer) => {
		if (selectedWorkId === '-1') return;
		const status = WORK_STATE.CHECK_IN_PROGRESS.status 
		const msg = "Работа отправлена учителю на проверку.";

		piApi.reviewerStatusResearchById(selectedWorkId, reviewer, status, selectedResearch.__v).then((result) => {
			setSelectedResearch(result);
			setWorkStatus(result.status);
			toast.success(msg);
			sendChangePi(documentClass._id, dispatch);
		}).catch((err) => {
			toast.error(err.response.data.errors);
		});
	};

	const handleChangeResearchState = (status, msg) => {
		if (selectedWorkId === '-1') return;
		piApi.statusResearchById(selectedWorkId, status, selectedResearch.__v).then((result) => {
			setSelectedResearch(result);
			setWorkStatus(result.status);
			toast.success(msg);
			sendChangePi(documentClass._id, dispatch);
		}).catch((err) => {
			toast.error(err.response.data.errors);
		});
	};

	const handleDeleteResearch = () => {
		setConfirmQuestion('Вы действительно хотите удалить эту работу?');
		setShowConfirmDeleteDlg(true);
	};

	const handleDeleteResearchYes = () => {
		setShowConfirmDeleteDlg(false);
		piApi.deleteResearchById(selectedWorkId).then(() => {
			toast.success("Работа удалена.");
			sendChangePi(documentClass._id, dispatch);
			props.history.push(FEATURES.pi.to);
		}).catch((err) => {
			toast.error(err.response.data.errors);
		});
	};

	const getNextUid = (composition = undefined) => {
		const arr = !composition ? workComposition : composition;
		const uids = arr.map(item => item.uid);
		return uids.length > 0 ? Math.max(...uids) + 1 : 1;
	};
	
	const addElementToComposition = (type, newUid, _workComposition) => {
		const composeElem = {
			type:type,
			uid: newUid, 
			isShown: true
		};
		const wcList = [..._workComposition];
		wcList.unshift(composeElem);
		setWorkComposition(wcList);
		return wcList;
	};

	const handleAddComment = (withMsg = true) => {
		const newUid = getNextUid();
		const commentName = 'Комментарий N' + (workComments.length + 1);

		//1. create comment and add it to workComments
		const comment = {
			title: commentName,
			text: '',
			uid: newUid
		};

		const wcList = [...workComments];
		wcList.push(comment);
		setWorkComments(wcList);

		//2. add reference to workComposition
		addElementToComposition(INV_ELEM_TYPE.COMMENT_ID, newUid, workComposition);
		if (withMsg)
			toast.success("Комментарий '" + commentName + "' добавлен.");
	};

	const handleAddDrawTool = () => {
		const newUid = getNextUid();
		const title = 'Иллюстрация N' + (workDrawTools.length + 1);

		//1. create comment and add it to workComments
		const drawtool = {
			title: title,
			uid: newUid,
			image: {
				name: undefined, //image name
				frameData: null, //image data
				measureList: [],
			}
		};

		const wdList = [...workDrawTools];
		wdList.push(drawtool);
		setWorkDrawTools(wdList);

		//2. add reference to workComposition
		addElementToComposition(INV_ELEM_TYPE.DRAW_TOOL_ID, newUid, workComposition);
		toast.success("Иллюстрация '" + title + "' добавлена.");
	};

	const handleAddTable = () => {
		const DEFAULT_COLUMNS = [
			{formula: '', title: 'N', type: 'order', unit: null},
			{formula: '', title: 'Длина', type: 'number', unit: "65dca1fb92708dee8e7cad1d"},
			{formula: '', title: 'Описание', type: 'text', unit: null},
		];
		
		const tableName = 'Измерение N' + (workMeasurements.length + 1);
		const newUid = getNextUid();

		//1. create table and add to workMeasurements
		const table = {
			author: '',
			compactDate: new Date(), //today "22.06.2015"
			index: tableName, 
			title: tableName, 
			rows: [[1, '', '']],
			properties: DEFAULT_COLUMNS,
			uid: newUid,
		};
		const wmList = [...workMeasurements];
		wmList.push(table);
		setWorkMeasurements(wmList);

		//2. add reference to workComposition
		addElementToComposition(INV_ELEM_TYPE.MEASUREMENT_ID, newUid, workComposition);
		toast.success("Таблица '" + tableName + "' добавлена.");
	};

	const handleAddDetector = () => props.history.push(FEATURES.rlab.to);

	const handleAddGraph  = (count, tableUid = undefined) => {
		const wp = {
			title: 'График N' + (workPlots.length + 1),
			uid: getNextUid(),
			data: [[],[]],
			styles:[],
		};

		if (tableUid) {
			const wm = workMeasurements.find((table) => table.uid === tableUid);
			[wp.data, wp.styles] = convertTableToPlot(wm, wp.styles, workPlots.length);
		}

		if (count > 1){
			wp.multiple = 1;
			const wp2 = {
				title: 'График N' + (workPlots.length + 2),
				uid: getNextUid() + 1,
				data: [[],[]],
				multiple: 2
			};

			setWorkPlots([...workPlots,wp,wp2]);
			const wc = addElementToComposition(INV_ELEM_TYPE.PLOT_ID, wp.uid, workComposition);
			addElementToComposition(INV_ELEM_TYPE.PLOT_ID, wp2.uid, wc);
			toast.success("Графики '" + wp.title + ' и ' + wp2.title  + "' добавлены.");
		} else {
			setWorkPlots([...workPlots, wp]);
			addElementToComposition(INV_ELEM_TYPE.PLOT_ID, wp.uid, workComposition);
			toast.success("График '" + wp.title + "' добавлен.");
		}
	};

	const handleAddObjects = (idsArr) => {
		const objects = [];
		//игрорируем ранее добавленные объекты
		const composition = workComposition.filter(item => item.type !== INV_ELEM_TYPE.OBJECT_ID);
		//добавляем список выделенных объектов (веключая старые)
		for (let i = 0; i < idsArr.length; i ++) {
			const title = 'Объект работы N' + (i + 1);
			const newUid = getNextUid(composition);
			const obj = {title: title, id: idsArr[i], uid: newUid};
			objects.push(obj);

			const composeElem = {type: INV_ELEM_TYPE.OBJECT_ID, uid: newUid, isShown: true};
			composition.unshift(composeElem);
		}

		setWorkObjects(objects);
		setWorkComposition(composition);

		// //2. add reference to workComposition
		toast.success('Выбранные объекты добавлены.');
	}; 
	
	const handleChangeCompositionDlg = () => {
		if (workComposition.length === 0) 
			toast.warn('В настоящий момент остуствуют, которые переместить.');
		else
			setShowChangeCompositionDlg(true);
	};

	const getIconPanel = () => {
		return (
			<ul className='pi__iconPanel'>
				<li className="tp tp_center_left" data-tp="Оглавление">
					<Icon name="bullet" onClick={handleChangeCompositionDlg} />
				</li> 
				<li className="tp tp_center_left" data-tp="Новый объект">
					<Icon name="change-replace"  onClick={() => setShowSelectObjectDlg(true)} />
				</li>
				{/* add active class for active element */}
				<li className="tp tp_center_left" data-tp="Новый комментарий">
					<Icon name="messages" onClick={handleAddComment} />
				</li>
				<li className="tp tp_center_left" data-tp="Новая иллюстрация">
					<Icon name="pen-ruler" onClick={handleAddDrawTool} />
				</li>
				<li className="tp tp_center_left" data-tp="Новая таблица">
					<Icon name="table_edit" onClick={handleAddTable} />
				</li>
				{/* <li className="tp tp_center_left" data-tp="Импорт из csv">
					<Icon name="file"/>
				</li> */}
				{isAuth().role < 3 &&
					<li className="tp tp_center_left" data-tp="Датчики">
						<Icon name="usb" onClick={handleAddDetector}/>
					</li>}
				<li className="tp tp_center_left" data-tp="Графики">
					<Icon name="graph_sm" onClick={() => handleAddGraph(1)}/>
				</li>
				<li className="tp tp_center_left" data-tp="Два новых графика рядом">
					<Icon name="graph" onClick={()=>handleAddGraph(2)}/>
				</li>
			</ul>
		);
	};

	const handleSelectGraph = (tableUid, graphUid) => {
		if (graphUid === -1) {
			handleAddGraph(1, tableUid);
		} else {
			const wm = workMeasurements.find((table) => table.uid === tableUid);
			const wp = workPlots.find((wb) => wb.uid === graphUid);
			const wpi = workPlots.indexOf(wp);
			[wp.data, wp.styles] = convertTableToPlot(wm, wp.styles, wpi);
			setWorkPlot(wpi, wp)
		}
	};

	const doDeleteHideSection = (isDelete, uid) => {	
        const list = [];
        for (let i = 0; i < workComposition.length; i ++) {
            const content = workComposition[i];
			if (content.uid === uid) {
				if (isDelete) {
					if (content.type === INV_ELEM_TYPE.MEASUREMENT_ID) setWorkMeasurements(workMeasurements.filter(item => item.uid !== uid));
					else if (content.type === INV_ELEM_TYPE.DRAW_TOOL_ID) {
						if (!isTemplate) {
							const wdt = workDrawTools.find((item) => item.uid === uid);
							const fileName = wdt.image.name;
							if (fileName && fileName !== BLANK_FILE) {
								deletePIFileByName(dispatch, fileName);
							}
						}
						setWorkDrawTools(workDrawTools.filter((item) => item.uid !== uid));
					} else if (content.type === INV_ELEM_TYPE.COMMENT_ID) setWorkComments(workComments.filter((item) => item.uid !== uid));
					else if (content.type === INV_ELEM_TYPE.OBJECT_ID) setWorkObjects(workObjects.filter((item) => item.uid !== uid));
					else if (content.type === INV_ELEM_TYPE.PLOT_ID) {
						const wp = workPlots.find((wb) => wb.uid === uid);
						const wpi = workPlots.indexOf(wp);
						const wps = workPlots.filter((item) => item.uid !== uid);
						if (wp.multiple === 1) {
							delete wps[wpi].multiple;
						} else if (wp.multiple === 2) {
							delete wps[wpi-1].multiple;
						}
						setWorkPlots(wps);
					}
				} else { //hide:
					const elem = {...content, isShown: false};
					list.push(elem);
				}
			} else {
				list.push(content);
			}
        }

        setWorkComposition(list);
	};

	const convertPlot = wp => ({
		title: wp.title,
		uid: wp.uid,
		data: [[], []],
		styles: [{axe: wp.xscales?.[0]?.title ?? "Ось X"},
			{
				axe: wp.yscales?.[0]?.title ?? "Ось Y",
				weight: 2,
				color: wp.curvSettings?.[0]?.color ?? "#F35151",
				point: {
					width: 0,
					color: wp.curvSettings?.[0]?.color ?? "#F35151",
				},
				interpolation: "basis",
			}]
	});

	const showInvestElements = (isEdit) => {
		const elements = [];

		for (let i = 0; i < workComposition.length; i++) {
			const content = workComposition[i];
			const type = content.type;
			const uid = content.uid;
			const isShown = content.isShown;

			if (isShown) {
				if (type === INV_ELEM_TYPE.MEASUREMENT_ID) { //таблица
					const wm = workMeasurements.find((table) => table.uid === uid);
					const tableIndex = workMeasurements.indexOf(wm);

					const table = <PIOneTable
						isEdit={isEdit}
						uid={uid}
						id={getStructureId(uid)}
						workMeasurement={wm}
						setWorkMeasurement={setWorkMeasurement}
						tableIndex={tableIndex}
						doDeleteHideSection={isDelete => doDeleteHideSection(isDelete, uid)}
						setShowImageDlg={setShowImageDlg}
						setShownFile={setShownFile}
						workPlots={workPlots}
						handleSelectGraph={handleSelectGraph}
					/>;
					elements.push(table);
				} else if (type === INV_ELEM_TYPE.COMMENT_ID) { //комментарий
					const wc = workComments.find((comment) => comment.uid === uid);
					const commentIndex = workComments.indexOf(wc);
					const comment = <PIOneComment
						isEdit={isEdit}
						uid={uid}
						id={getStructureId(uid)}
						workComment={wc}
						setWorkComment={setWorkComment}
						commentIndex={commentIndex}
						doDeleteHideSection={isDelete => doDeleteHideSection(isDelete, uid)}
					/>;
					elements.push(comment);
				} else if (type === INV_ELEM_TYPE.OBJECT_ID) { //объект
					const wo = workObjects.find((object) => object.uid === uid);
					const objectIndex = workObjects.indexOf(wo);

					const object = <PIOneObject
						isEdit={isEdit}
						uid={uid}
						id={getStructureId(uid)}
						invModeTabInd={tabInd}
						workObject={wo}
						setWorkObject={setWorkObject}
						objectIndex={objectIndex}
						doDeleteHideSection={isDelete => doDeleteHideSection(isDelete, uid)}
						setShowImageDlg={setShowImageDlg}
						setShownFile={setShownFile}
						isTemplate={isTemplate}
					/>;
					elements.push(object);
				} else if (type === INV_ELEM_TYPE.DRAW_TOOL_ID) { //иллюстрация
					const wdt = workDrawTools.find((wb) => wb.uid === uid);
					const drawToolIndex = workDrawTools.indexOf(wdt);

					const drawTool = <PIOneDrawTool
						isEdit={isEdit}
						uid={uid}
						id={getStructureId(uid)}
						isTemplate={isTemplate}
						workDrawTool={wdt}
						setWorkDrawTool={setWorkDrawTool}
						drawToolIndex={drawToolIndex}
						doDeleteHideSection={isDelete => doDeleteHideSection(isDelete, uid)}
						drawToolImages={drawToolImages}
						setDrawToolImages={setDrawToolImages}
						tabInd={tabInd}
					/>;
					elements.push(drawTool);
				} else if (type === INV_ELEM_TYPE.PLOT_ID) { //график
					let workPlot = workPlots.find((wb) => wb.uid === uid);
					const plotIndex = workPlots.indexOf(workPlot);
					workPlot.styles = getStyles(workPlot, plotIndex);
					if (workPlot.cid) workPlot = convertPlot(workPlot);

					const graph = <PIOneGraphics
						isEdit={isEdit}
						uid={uid}
						id={getStructureId(uid)}
						workPlot={workPlot}
						setWorkPlot={(wp) => setWorkPlot(plotIndex, wp)}
						plotIndex={plotIndex}
						doDeleteHideSection={isDelete => doDeleteHideSection(isDelete, uid)}
					/>;

					if (workPlot.multiple) {
						const workPlot2 = workPlots.find((wb) => wb.uid === uid + 1);
						if ((workPlot.multiple === 1) && (workPlot2?.multiple === 2)) {
							const plotIndex2 = workPlots.indexOf(workPlot2);
							workPlot2.styles = getStyles(workPlot2, plotIndex2);

							const graph2 = <PIOneGraphics
								isEdit={isEdit}
								uid={uid + 1}
								id={getStructureId(uid + 1)}
								workPlot={workPlot2}
								setWorkPlot={(wp) => setWorkPlot(plotIndex2, wp)}
								plotIndex={plotIndex2}
								doDeleteHideSection={isDelete => doDeleteHideSection(isDelete, uid + 1)}
							/>;

							const mGraph = (<div className="cor-net__row">
								<div className="cor-net__col col-2">{graph}</div>
								<div className="cor-net__col col-2">{graph2}</div>
							</div>);
							elements.push(mGraph);
						}
					} else {
						elements.push(graph);
					}
				}
			}
		}

		return ( 
			elements.map((item, ind) => (
				<div className="cor-net__section" key={'elm01'+ind}>
					<div className="cor-net__row" key={'elm02'+ind}>
						<div className="cor-net__col col-grow" key={'elm03'+ind}>
							{item}
						</div>
					</div>
				</div>
			))
		)
	};

	const setWorkMeasurement = (measurementIndex, wm) => {
		const list = [...workMeasurements];
		list[measurementIndex] = wm;
		setWorkMeasurements(list);
	};

	const setWorkComment = (commentIndex, wc) => {
		const list = [...workComments];
		list[commentIndex] = wc;
		setWorkComments(list);
	};

	const setWorkObject = (objectIndex, wo) => {
		const list = [...workObjects];
		list[objectIndex] = wo;
		setWorkObjects(list);
	};

	const setWorkDrawTool = (drawToolIndex, wdt) => {
		const list = [...workDrawTools];
		list[drawToolIndex] = wdt;
		setWorkDrawTools(list);
	};

	const setWorkPlot = (drawToolIndex, wp) => {
		const list = [...workPlots];
		list[drawToolIndex] = wp;
		setWorkPlots(list);
	};

	const handlePrint = () => {
		setShowPrint(true);
	};

	const closePrint = () => {
		setShowPrint(false);
	};

	const getPrintElemById = (id) => {
		const elem = document.getElementById(id) ? 
			document.getElementById(id).innerHTML : "<div key={'empty'+id}></div>";
		return parser(elem);
	};

	const getPrintData = () => {
		if (!showPrint || tabInd !== INVEST_MODE_TAB.VIEW) return [];
		
		const elems = [];
		elems.push(getPrintElemById(TOPIC_ID));
		if (workDescription) elems.push(getPrintElemById(DESCRIPTION_ID));
		if (workTargets.length > 0) elems.push(getPrintElemById(TARGETS_ID));
		if (workHypothesis.length > 0) elems.push(getPrintElemById(HYPOTHESIS_ID));
		if (workPlan) elems.push(getPrintElemById(PLAN_ID));

		for (let i = 0; i < workComposition.length; i ++) { 
			const content = workComposition[i];
			const uid = content.uid;
			const type = content.type;

			if (type === INV_ELEM_TYPE.DRAW_TOOL_ID) {
				const dti = workDrawTools.find(item => item.uid === uid);
				if (dti) {
					const innerHTML = document.getElementById(getStructureId(uid)).innerHTML;
					const arr = innerHTML.split('<canvas id="' + getCanvasId(uid) + '" ');
					const dtName = arr[0];
					const arr2 = arr[1].split('></canvas>');
					const dtTable = arr2[1];
					const dtImage = dti.image.printFrameData;
					elems.push(parser("<div key={" + uid + "}>" + dtName + '<img src="' + dtImage + '" alt="" />' + dtTable + "</div>"));
				}
			} else	if (type === INV_ELEM_TYPE.PLOT_ID) {
				const wp = workPlots.find(item => item.uid === uid);
				if (wp) {
					const struct = document.getElementById(getStructureId(uid));
					const canvas = struct.querySelector('canvas');
					const dtImage = `<img src="${canvas.toDataURL('image/svg')}" alt=""/>`;
					const arr = struct.innerHTML.split('<canvas');
					const dtName = arr[0];
					const arr2 = arr[1].split('></canvas>');
					const dtTable = arr2[1];
					elems.push(parser("<div key={" + uid + "}>" + dtName + dtImage + dtTable + "</div>"));
				}
			} else if (type === INV_ELEM_TYPE.OBJECT_ID) {
				elems.push(getPrintElemById(getStructureId(uid)));
			} else {
				elems.push(getPrintElemById(getStructureId(uid)));
			}
		}

		if (workConclusions) elems.push(getPrintElemById(CONCLUSION_ID));
		return elems;
	};

	const getTargetArray = () => {
		const isEditMode =  !!editModeData && editModeData.objectType === OBJECT_TYPE.TARGET;
		const items = getListItems(OBJECT_TYPE.TARGET, workTargets, setWorkTargets, 
			targetValue, setTargetValue, 'target', 'Введите цель', 
			editModeData, setEditModeData, handleDeleteTargetById);
			return !isEditMode ? [items.filter((item, ind) => ind < items.length - 1), items[items.length - 1]]
			: [items, ''];
	};
	const getHypothesisArray = () => {
		const isEditMode =  !!editModeData && editModeData.objectType === OBJECT_TYPE.HYPOTHESIS;
		const items = getListItems(OBJECT_TYPE.HYPOTHESIS, workHypothesis, setWorkHypothesis, 
			hypoValue, setHypoValue, 'hyp', 'Введите гипотезу', 
			editModeData, setEditModeData, handleDeleteHypothesisById);
			return !isEditMode ? [items.filter((item, ind) => ind < items.length - 1), items[items.length - 1]]
			: [items, ''];
	};

	const handleChangeTabInd = (_tabInd) => { 
		setTabInd(_tabInd);

		if (_tabInd !== INVEST_MODE_TAB.VIEW)
		 	setDrawToolImages([]);
		if (_tabInd === INVEST_MODE_TAB.EXECUTE && workComposition.length === 0)
			handleAddComment(false);	
	};

    return (
		<Content>
			<ContentHeader>
				<Button back={true} onClick={() => handleBack()} />
			</ContentHeader>
			<ContentSubHeader>
				<div className="cor-net__row">
					<div className="cor-net__col">
						<Tabs>
							{getInvestModeTabs().map(tab => (
								<Tabs.Item 
									active={tabInd === tab.tabId} 
									onClick={() => handleChangeTabInd(tab.tabId)} 
									key={'tab'+ tab.tabId}
								>
									{tab.tabName}
								</Tabs.Item>)
							)}
						</Tabs>
					</div>
				</div>
			</ContentSubHeader>

			<ContentBody>
				{tabInd === INVEST_MODE_TAB.DESCR && 
					<div className="pi__main">
						<div className="pi__body">
							<div className="cor-net__section">
								<div className="cor-net__row">
									<div className="cor-net__col col-3">
										<div className="cor-net__label">Название</div>
										<InputLimit
											value={workName}
											onInput={(e) => setWorkName(e.target.value)}
											placeholder={'Введите название задания'}
										/>
									</div>
									<div className="cor-net__col col-3">
										<div className="cor-net__label">Тематика</div>
										<InputLimit
											value={workTheme}
											max={THEME_LIMIT}
											onInput={(e) => setWorkTheme(e.target.value)}
											placeholder={'Введите тематику задания'}
										/ >
									</div>
									<div className="cor-net__col col-3">
										<div className="cor-net__label">Уровень</div>
										{getDropdown(WorkLevelList, workLevel, v => setWorkLevel(v), '', '', 'wl01')}
									</div>
								</div>
								<div className="cor-net__row">
									<div className="cor-net__col col-grow">
										<div className="cor-net__label">Описание</div>
										<TextEditor value={workDescription} setValue={setWorkDescription} />
									</div>
								</div>
							</div>

							<div className="cor-net__section">
								<div className="cor-net__row ai-start">
									<div className="cor-net__col col-2">
										<div className="cor-net__title">Цели</div>
										<div className='targetList' key={'trg'}>
											<PIOneDragAndDropList
												elems={workTargets}
												setElems={setWorkTargets}
												keyVal={'target'}
												list={getTargetArray()[0]}
											/>
											<>{getTargetArray()[1]}</>
										</div>
									</div>
									<div className="cor-net__col col-2">
										<div className="cor-net__title">Гипотезы</div>
										<div className='targetList' key={'hp'}>
											<PIOneDragAndDropList	
												elems={workHypothesis}
												setElems={setWorkHypothesis}
												keyVal={'hyp'}
												list={getHypothesisArray()[0]}
											/>
											<>{getHypothesisArray()[1]}</>
										</div>
									</div>
								</div>
							</div>

							<div className="cor-net__section">
								<div className="cor-net__row">
									<div className="cor-net__col col-grow">
										<div className="cor-net__label">Плановый ход выполнения работы</div>
										<TextEditor value={workPlan} setValue={setWorkPlan} />
									</div>
								</div>
							</div>
						</div>
					</div>
				}

				{tabInd === INVEST_MODE_TAB.EXECUTE && 
					<div className="pi__main">
						<div className="pi__body">
							{showInvestElements(true)}
						</div>						
						{getIconPanel()}
					</div>
				}

				{tabInd === INVEST_MODE_TAB.ANALYSIS && 
					<div className="pi__main">
						<div className="pi__body">
							<div className="cor-net__section">
								<div className="cor-net__row ai-start">
									<div className="cor-net__col col-2">
									{workTargets.length > 0 ?
										<>
										<div className="cor-net__title">Отметьте достигнутые цели</div>
										{getWorkTargetCheckBoxes(workTargets, setWorkTargets)}
										</>
										:
										<div className="cor-net__title">Цели не установлены</div>
									}
									</div>
									<div className="cor-net__col col-2">
									{workHypothesis.length > 0 ? 
										<>
										<div className="cor-net__title">Укажите статуc по гипотезам</div>
										{getWorkHypothesisRbCheckBoxes(workHypothesis, setWorkHypothesis)}
										</>
										:
										<div className="cor-net__title">Гипотезы отсутствуют</div>
									}
									</div>
								</div>
							</div>
							<div className="cor-net__section">
								<div className="cor-net__row">
									<div className="cor-net__col col-grow">
										<div className="cor-net__label">Выводы</div>
										<TextEditor value={workConclusions} setValue={setWorkConclusions} />
									</div>
								</div>
							</div>
						</div>
					</div>
				}

				{tabInd === INVEST_MODE_TAB.VIEW && 
					<div className="pi__main view" id={INVESTIGATION_ID}>
						<div className="pi__body">

							<div className="cor-net__section" key={'top01'} id={TOPIC_ID}>
								<div className="cor-net__row ai-start" key={'top02'}>
									<div className="cor-net__col col-2" key={'top03'}>
										<div className="cor-net__title" key={'top04'}>Название</div>
										<div className="cor-net__title lg" key={'top05'}>{workName}</div>
									</div>
									
									<div className="cor-net__col col-4" key={'top06'}>
										<div className="cor-net__title" key={'top07'}>Тематика</div>
										<div className="cor-net__title lg" key={'top08'}>{workTheme}</div>
									</div>

									<div className="cor-net__col col-4" key={'top010'}>
										<div className="cor-net__title" key={'top11'}>Уровень</div>
										<div className="cor-net__title lg" key={'top12'}>
											{(workLevel??DEFAULT_WORK_LEVEL) + " класс"}
										</div>
									</div>
								</div>

								<div className="cor-net__row ai-start" key={'top100'}>
									<div className="cor-net__col col-2" key={'top20'}>
										<div className="cor-net__title" key={'top21'}>Автор</div>
										<div className="cor-net__title lg" key={'top22'}>
										{fullName(workAuthor, 'Шаблон')}
										</div>
									</div>

									<div className="cor-net__col col-2" key={'top30'}>
										<div className="cor-net__title" key={'top31'}>Статус</div>
										<div className="cor-net__title lg" key={'top32'}>
										{getWorkStateByStatus(workStatus)}
										</div>
									</div>
								</div>
							</div>

							{workDescription && 
							<div className="cor-net__section" key={'dsc01'}>
								<div className="cor-net__row" key={'dsc02'}>
									{viewComment(DESCRIPTION_ID, 'Описание', workDescription)}
								</div>
							</div>
							}

							{workTargets.length > 0 &&
							<div className="cor-net__section" key={'trd01'} id={TARGETS_ID}>
								<div className="cor-net__title" key={'trd02'}>Цели</div>
								<div className="cor-net__row" key={'trd03'}>
									{getWorkTargetResults(workTargets)}
								</div>
							</div>
							}

							{workHypothesis.length > 0 &&
							<div className="cor-net__section" key={'hp01'} id={HYPOTHESIS_ID}>
								<div className="cor-net__title" key={'hp02'}>Гипотезы</div>
								<div className="cor-net__row" key={'hp03'}>
									{getWorkHypothesisResults(workHypothesis)}
								</div>
							</div>
							}

							{workPlan && 
							<div className="cor-net__section" key={'wrk01'}>
								<div className="cor-net__row" key={'wrk02'}>
									{viewComment(PLAN_ID, 'Плановый  ход выполнения работы', workPlan)}
								</div>
							</div>
							}

							{showInvestElements(false)}
					
							{workConclusions && 
							<div className="cor-net__section" key={'con01'}>
								<div className="cor-net__row" key={'con02'}>
									{viewComment(CONCLUSION_ID, 'Выводы', workConclusions)}
								</div>								
							</div>
							}

						</div>
					</div>
				}
			</ContentBody>

			<ContentFooter className="gap jc-start">
				{!isDisabledTemplate &&
					<Button color="primary" size="medium" border={false} onClick={() => handleSaveResearch()}>
    	                Сохранить
        	       	</Button>
				}
				<Button color="primary" size="medium" border={true} onClick={() => handleSaveResearchToComputer()}>
                    Сохранить на компьютер
                </Button> 
				{workStatus === WORK_STATE.WORK.status && isAuth().role === 0 && 
					<Button color="primary" size="medium" onClick={handleStudentSendToCheck}>Отправить на проверку</Button>
				}
				{!isDisabledTemplate &&
					<Button color="primary" size="medium" onClick={handleDeleteResearch}>Удалить</Button>
				}
				{(workStatus === WORK_STATE.CHECKED.status || workStatus === WORK_STATE.CHECK_IN_PROGRESS.status) && isAuth().role > 0 &&
					<Button color="primary" size="medium" onClick={handleTeacherSendToReject}>Вернуть</Button>
				}
				{workStatus === WORK_STATE.CHECK_IN_PROGRESS.status && isAuth().role > 0 &&
					<Button color="primary" size="medium" onClick={handleTeacherSendToAccept}>Проверено</Button>
				}
				{workStatus === WORK_STATE.PUBLISHED.status && isAuth().role > 0 &&
					<Button color="primary" size="medium" onClick={handleTeacherSendToAccept}>Вернуть</Button>
				}
				{(workStatus === WORK_STATE.CHECKED.status) &&
					<Button color="primary" size="medium" onClick={handleTeacherSendToPublish}>Опубликовать</Button>
				}

				{tabInd === INVEST_MODE_TAB.VIEW && 
				<Button color="primary" size="medium" border={true}  
					onClick={handlePrint} className="cor_btn_icon_left">
					<Icon name="print" />
					Печать
				</Button> 
				}
			</ContentFooter>

			{showSelectObjectDlg && <PISelectObjectDlg
				showDlg={showSelectObjectDlg}
				setShowDlg={setShowSelectObjectDlg}
				objectIds={workObjects}
				handleAddObjects={handleAddObjects}
			/>
			}
			{showChangeCompositionDlg && <PIChangeCompositionDlg
				showDlg={showChangeCompositionDlg}
				setShowDlg={setShowChangeCompositionDlg}
				workComposition={workComposition}
				setWorkComposition={setWorkComposition}
				//
				workMeasurements={workMeasurements}
				workComments={workComments}
				workObjects={workObjects}
				workDrawTools={workDrawTools}
				workPlots={workPlots}
				//
				setWorkMeasurements={setWorkMeasurements}
				setWorkComments={setWorkComments}
				setWorkObjects={setWorkObjects}
				setWorkDrawTools={setWorkDrawTools}
				setWorkPlots={setWorkPlots}
			/>
			}
            {showPrint && <ModalPrint
                showPrint={showPrint} 
				closePrint={closePrint}
                printData={getPrintData()}
            />}

			{showImageDlg && <ImageDlg 
                showModal={showImageDlg}
                setShowModal={setShowImageDlg}
                file={shownFile}
            />
			}

			{showConfirmDeleteDlg && <ModalConfirmDialog
				showConfirmDlg={showConfirmDeleteDlg} 
				handleNo={() => setShowConfirmDeleteDlg(false)}
				handleYes={handleDeleteResearchYes}
				question={confirmQuestion}
			/>}
			  
			{showSelectReviewerDlg && <PISelectReviewerDlg
				showModal={showSelectReviewerDlg} 
				setShowModal={() => setShowSelectReviewerDlg(false)}
				reviewer={selectedResearch.reviewer}
				handleSelectReviewer={handleChangeResearchReviewerState}
			/>}

		</Content>
    )
}

export default PInvestigationsOne;
