import {Icon, Warning, InputLimit, AddFile} from '../ui';
import {Checkbox} from 'rlabui';
import {onlineTestApi, onlineTestInfoApi} from "../../api/api";
import {SCORE_TYPE} from '../ui/utils/score_utils';
import {CHECK_TIME_TYPE, checkTimeRange, getDropdown, getUrl, getUrls, uploadFile, 
    doDeleteFileByName, cleanupDivs } from '../ui/utils/gen_utils';
import {toast} from "react-toastify";
import OneOption from "./OneOption";
import htmlParser from "html-react-parser";
import "../Grids/Grid.scss";
import ss from './OnlineTestCreateQuestions.module.scss';

export const UNDEFINED_QUESTION_TYPE = -1;
const DEFAULT_STATE = '001100000'; //pos 0 - AUDIO, pos 1 - CORRECT_WORD_SEQUENCE, ///
export const QUESTION_TYPES = {
	AUDIO: 0,
	CORRECT_WORD_SEQUENCE: 1,
	CORRECT_ANSWER_ONE: 2,  //active
	CORRECT_ANSWER_MANY: 3, //active
	CORRECT_IMAGE_SEQUENCE: 4,
	NON_VERBAL: 5,
	COMPLETE_SENTENCE: 6,
	FREE_WRITING: 7,
	VIDEO: 8
};

export const QUESTIONS = [
    {name: 'Звуковой', id: QUESTION_TYPES.AUDIO, order: 5},
    {name: 'Восстановление последовательности', id: QUESTION_TYPES.CORRECT_WORD_SEQUENCE, order: 8},
    {name: 'С одним правильным ответом', id: QUESTION_TYPES.CORRECT_ANSWER_ONE, order: 0},
    {name: 'С несколькими правильными ответами', id: QUESTION_TYPES.CORRECT_ANSWER_MANY, order: 1},
    {name: 'Перетаскивание элементов и рисунков', id: QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE, order: 2},
    {name: 'Невербальные, без словесного описания (картинки, слайды, схемы)', id: QUESTION_TYPES.NON_VERBAL, order: 3},
    {name: 'На завершение предложений', id: QUESTION_TYPES.COMPLETE_SENTENCE, order: 7},
    {name: 'Свободное изложение', id: QUESTION_TYPES.FREE_WRITING, order: 6},
    {name: 'Видео', id: QUESTION_TYPES.VIDEO, order: 4},
];

export const FORMAT_TYPE = {
	IMAGE: 1,
    VIDEO: 2, 
    AUDIO: 3
};

export const STATUS = {
    INIT: 1,
    USE: 2
};

export const OT_MODE_TYPE = {
    TESTS: 1,
    HISTORY: 2
};

export const OT_MODE_STATE = [
    {status: OT_MODE_TYPE.TESTS, name: 'Каталог тестов', path: '/practicum/ot'},
    {status: OT_MODE_TYPE.HISTORY, name: 'История тестирования', path: '/reviewtest/ot'},
];

export const OT_TYPE = {
    TEMPLATES: -2,
    ALL: 0,
    LIST: 1,
    RUNNING: 2,
    COMPLETED: 3,
    BE_CHECKED: 4
};

export const RT_TAB_STATE = [
    {status: OT_TYPE.ALL, name: 'Все'},
    {status: OT_TYPE.RUNNING, name: 'Запущенные'},
    {status: OT_TYPE.COMPLETED, name: 'История'},
    {status: OT_TYPE.BE_CHECKED, name: 'На проверке'},
];

export const OT_TAB_STATE = [
    {status: OT_TYPE.ALL, name: 'Все'},
    {status: OT_TYPE.TEMPLATES, name: 'Шаблоны'},
    {status: OT_TYPE.LIST, name: 'Созданные'},
];

export const MAX_ANSWER_LENGTH = 75;
export const MAX_DESC_LENGTH = 1000;

export const OT_SAVE_ACTION = {
    NONE: 0,
    EXIT: 1,
    NEW: 2
};

export const COMPLETE_TEST_HR = 2;

const ONLINETEST_PREFIX = 'OnlineTests%2F';
export const ONLINETEST_SLASH = 'OnlineTests/';

export const clearAllQuestionAttachments = async (question, _otId, _dispatch) => {
    if (question.illustration) {
        const illustration = JSON.parse(question.illustration);
        deleteOTFileByName(_dispatch, illustration.name);
    }

    if (isQuestionWithVideo(question)) {
        const videoFileName = question.correctOptions.split('|')[1];
        deleteOTFileByName(_dispatch, videoFileName);
    } else if (isQuestionWithAttachments(question)) {
        const attachmentList = question.answerOptions;
        for (let i = 0; i < attachmentList.length; i ++) {
            await deleteOTFileByName(_dispatch, attachmentList[i]);
        }
    }
};

export const getQuestionTypes = (qtState) => {
    const states = qtState ? 
        qtState.split('').map(item => item === '1') //from saved test
        : 
        DEFAULT_STATE.split('').map(item => item === '1'); //new test: qtState is empty

    const qTypes = QUESTIONS.map((item, ind) => ({
        name: item.name, 
        id: item.id, 
        value: states[ind],
        order: item.order        
    }));

    return qTypes;
};

export const getComboboxes = (orderList, setOrderList) => {
    const varArr = [];
    let hasOrder = true;

    for (let i = 0; i < orderList.length; i ++) {
        const variants = [];
        const selIndex = Number(orderList[i]);
        if (selIndex >= 0) {
            variants.push({label: 'Выберите вариант', value: -(i + 1)  });   
            for (let j = 0; j < orderList.length; j ++) {
                let isFound = false;
                for (let k = 0; k < i; k ++) {
                    if (Number(orderList[k]) === j) isFound = true;
                }
                if (!isFound)
                    variants.push({label: 'Вариант ' + (j+1), value: j});   
            }
        } 
        else if (hasOrder) {
            const remNonOrderedNum = orderList.length - orderList.filter(item=> Number(item) !== -1).length;
            if (remNonOrderedNum > 1) 
                variants.push({label: 'Выберите вариант', value: -(i + 1)});
            hasOrder = false;

            for (let j = 0; j < orderList.length; j ++) {
                let isFound = false;
                for (let k = 0; k < orderList.length; k ++) {
                    if (Number(orderList[k]) === j) isFound = true;
                }
                if (!isFound) {
                    variants.push({label: 'Вариант ' + (j+1), value: j});   

                    if (remNonOrderedNum === 1) {
                        const orders = orderList.map(item => item);
                        orders.pop();
                        orders.push(j);
                        setOrderList(orders);
                    }
                }
            }                    
        } else {
            variants.push({label: 'Выберите вариант', value: -(i + 1)}); //следующий
        }
        varArr.push(variants);
    }
    return varArr;
};

export const getMaxOfArray = numArray => Math.max.apply(null, numArray);

export const getOptionFieldList = (list, handleChangeAnswer, handleDeleteOption, handleAddOption, 
        isEditable) => {
    return (
        <div className="cor-net__section border mt_lg">
            <div className="cor-net__title">Варианты ответа</div>
            <>
                {list.map((item, ind) => (
                    <div className="cor-net__row" key={200 * (ind+1)}>
                        <div className="cor-net__col col-2">
                            <div className="cor-net__label" key={ind+1}>Вариант {ind+1}</div>
                            <InputLimit 
                                id={ind} 
                                value={item.name} 
                                key={-ind-1} 
                                disabled={!isEditable}
                                onInput={e => handleChangeAnswer(e.target.value, ind)} 
                                placeholder={'Введите ответ'}
                                max={MAX_ANSWER_LENGTH}
                            />
                        </div>

                        {isEditable && 
                        <div className="cor-net__col">
                            <div className="cor-net__label hide"></div>
                            <Icon className={ss.trash} name="trash" onClick={() => handleDeleteOption(ind)} key={300 * (ind+1)} />
                        </div>}              
                    </div>
                ))}
            </>

            {isEditable && 
            <div className="cor-net__row">
                <div onClick={handleAddOption} className={ss.addOption}>
                    <Icon name="plus"/>
                    Добавить вариант
                </div>
            </div>}
        </div>
    );
};

export const getOneCorrectAnswer = (list, opt, setOpt, isEditable) => {
    const getList = list => {
        const _list = [];
        for (let i = 0; i < list.length; i ++)
            _list.push({label: 'Вариант ' + (i+1), value: '' + i});
        return _list;
    };
    const optList = getList(list);

    return (
        <div className="cor-net__section border">
            <div className="cor-net__title">Правильный ответ</div>
            <div className="cor-net__row">
                <div className="cor-net__col col-6">
                    {
                    isEditable ? 
                    getDropdown(optList, opt, setOpt, '', '', 'opts01', 'top')
                    : 
                    optList.length > 0 ? optList[Number(opt)].label : <></>
                    }
                </div>
            </div>
        </div>
    );
};

export const getMultipleCorrectAnswers = (list, handleCorrectAnswers) => {
    return (
        <div className="cor-net__section border" key={'mcs0'}>
            <div className="cor-net__title" key={'mcs1'}>Правильные ответы</div>
            <div className="cor-net__row" key={'mcs2'}>
                {list.map((item, ind) => 
                    <div key={'mcs3'+ind}>
                        <Checkbox
                            className={ss.checkbox}
                            label={"Вариант" + (ind + 1)} 
                            checked={item.correct ? "checked" : ""} 
                            onChange={() => handleCorrectAnswers(ind) }
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export const isQuestionWithAttachments = question => {
    const qt = Number(question.questionType);
    return (qt === QUESTION_TYPES.AUDIO || qt === QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE ||
        qt === QUESTION_TYPES.NON_VERBAL || qt === QUESTION_TYPES.VIDEO);
};

export const isQuestionWithVideo = question => Number(question.questionType) === QUESTION_TYPES.VIDEO;

export const uploadOTFile = (file, setLoadedFileName) => {
    uploadFile(ONLINETEST_PREFIX, file, setLoadedFileName);
}

export const getOTUrl = async (name) => {
    return await getUrl(ONLINETEST_PREFIX, name); 
};

export const getOTUrls = async (list) => {
    return await getUrls(ONLINETEST_PREFIX, list); 
};

export const deleteOTFileByName = async (dispatch, fileName) => {
    doDeleteFileByName(ONLINETEST_PREFIX, dispatch, fileName);
}; 

export const getAnswerUrl = async (name, setUrl) => {
    const _url = await getOTUrl(name);
    setUrl(_url);
};

export const getAnswerUrls = async (isTemplate, answerList, setUrls) => {
    let _urls = answerList;
    if (!isTemplate) {
        _urls = await getOTUrls(answerList.map(item => item.name));
    }
    setUrls(_urls);
};

export const getExistingRefs = (answers, _files) => {
    let removedIndeces = [];

    for (let k = 0; k < answers.length; k ++) {
        const answerFileName = answers[k].name;
        let found = false;

        for (let i = 0; i < _files.length; i ++) 
            if (answerFileName === _files[i].Key.split('/')[1]) 
                found = true;

        if (!found)
            removedIndeces.unshift(k);
    }

    let cleanedAnswers =  [...answers];
    if (removedIndeces.length > 0) { //Exclude refs to lost files
        for (let i = 0; i < removedIndeces.length; i ++)
            cleanedAnswers = cleanedAnswers.filter((map, ind) => ind !== removedIndeces[i]);
    } 

    return cleanedAnswers;
};

export const getContentByFile = (isTemplate, f) => {
    if (!isTemplate) {
        return f.Url;
    } else {
        const fileBody = JSON.parse(f.name);
        return fileBody.content;
    }
};

const getNonVerbalOption = (f, i, isTemplate, _answerList, _setAnswerList, _dispatch, 
    _handleShowPicture, _handleDeleteImageAnswerOption) => {
    return (
        <div className={ss.grid__item} key={'cnt01'+i}>
            <div className={ss.grid__label} key={'top01'+i}>
                <p key={'top02'+i}>Вариант {i+1}</p>
                <Icon 
                    name="trash"
                    onClick={() => _handleDeleteImageAnswerOption( 
                    _answerList, _setAnswerList, _dispatch, i)} 
                    key={'top03'+i} 
                />
            </div>

            <div className={ss.grid__img} key={'mid01'+i}>
                <img 
                    src={!isTemplate ? f : JSON.parse(f.name).content} 
                    alt='' 
                    key={'mid02'+i} 
                    onClick={() => _handleShowPicture(f)} 
                />
                <div className={ss.grid__fullScreen}>
                    <Icon name="full-screen" />
                </div>
            </div>
        </div>        
    );
};

export const getNonVerbalOptionFieldList = (isTemplate, _urls, _lostRefNumber, 
        _answerList, _setAnswerList, _minAnswerNum, 
        _dispatch, _acceptableFileFormat, 
        _handleAddImageAnswerOption, _handleDeleteImageAnswerOption, _handleShowPicture) => {

    return (
        <div className="cor-net__section border mt_lg">
            <div className="cor-net__title">
                Варианты ответа (вопрос должен содержать {_minAnswerNum} и более вариантов)
            </div>
            
            {_lostRefNumber > 0 && 
            <div className="cor-net__row">
                <div className="cor-net__col col-3">
                    <Warning>Добавьте еще {_lostRefNumber === 1 ? 
                        '1 вариант': _lostRefNumber + ' варианта'}
                    </Warning>
                </div>
            </div>}

            <div className="cor-net__row">
                <div className={ss.grid}  key='grid01'>
                    {_urls.map((f, i) => getNonVerbalOption(f, i, isTemplate, _answerList, _setAnswerList, _dispatch, 
                        _handleShowPicture, _handleDeleteImageAnswerOption)
                    )}
                </div>
            </div>

            <div className="cor-net__row" key='cnt3'>
                <AddFile
                    key='lbl03'
                    text='Добавить вариант'
                    accept={_acceptableFileFormat}
                    onChange={(e) => _handleAddImageAnswerOption(e)} 
                 />
            </div>

        </div>
    );
};

export const getDurationList = () => {
    const arr = [];
    for (let i = 1; i < 37; i ++) 
        arr.push({label: '' + (5 * i) + ' минут', value: (5 * i).toString()});
    return arr;
};

export const getYesNoOpts = () => {
    const list = [];
    list.push({label: 'Да', value: 'yes'});
    list.push({label: 'Нет', value: 'no'});
    return list;
};

export const getListElementNameById = (list, id) => {
    const elem = list.find(item => item.value.toString() === id?.toString());
    return elem?.label;
};
  
export const getNonCheckAnswerList = (_onlineTest, _onlineTestStudentInfo) => {
    if (!_onlineTest) return [];
    const nonCheckedAnswers = [];
    
    for (let i = 0; i < _onlineTest.questions.length; i ++) {
        const quesiton = _onlineTest.questions[i];
        if (Number(quesiton.questionType) === QUESTION_TYPES.FREE_WRITING) 
            if (i < _onlineTestStudentInfo.answers.length && 
                    _onlineTestStudentInfo.answers[i].isCorrect === SCORE_TYPE.UNDEFINED)
                nonCheckedAnswers.push(i); //save indexes of unchecked free write answers
    }
    return nonCheckedAnswers;
};

export const isAnswersChanged = (existAnswers, newAnswers) => {
    let changed = false;
    if (newAnswers.length !== existAnswers.length)
        changed = true;
    else {
        for (let i = 0; i < newAnswers.length && !changed; i ++)
            if (newAnswers[i].name !== existAnswers[i].name || 
                newAnswers[i].correct !== existAnswers[i].correct)
                    changed = true;
    }
    return changed;
};

export const unpackAutoEstimatesAsDoubleArray = autoEstimates => {
    return autoEstimates.split('|').map(item => item.split('/'));
};

export const packAutoEstimateArrayAsString = list => {
    return list.map(item => item[0] + '/' + item[1]).join('|');
};

export const addQuestion = (questionList, question, ind) => {
    let questions = [];
    if (questionList.length === 0) {
        questions.push(question);
    } else if (ind === questionList.length - 1) {
        questions = questionList.map(item => item);
        questions.push(question);
    } else {
        for (let i = 0; i < questionList.length; i ++) {
            questions.push(questionList[i]);
            if (i === ind) questions.push(question);
        }
    }
    return questions;
};

export const replaceQuestion = (questionList, question, index) => {
    const questions = questionList.map((item, ind) => ind === Number(index) ? question : item);
    return questions;
};

export const getQuestionTypeList = (isTemplate, _qTypes) => {
    const qTypes = getSortedQuestionTypeList(_qTypes);

    const list = []; 
    //list.push({label: 'Выберите тип вопроса', value: UNDEFINED_QUESTION_TYPE});
    for (let i = 0; i < qTypes.length; i ++) {
        const q = qTypes[i];
        if (q.id !== QUESTION_TYPES.VIDEO || !isTemplate) //не используем опцию Видео в шаблонах 
            list.push({label: q.name, value: q.id});
    }
    
    return list;
};

export const getSortedQuestionTypeList = list => {
    const _list = [...list];
    const sorted = _list.sort((a,b) => a.order - b.order);
    return sorted;
};

export const hasNoErrors = (__isNewQuestion, _questionName, _currQuestionType, _dataError) => {
    const errMsg = getErrorMsg(__isNewQuestion, _questionName, _currQuestionType, _dataError);
    if (!errMsg) return true;
    toast.warn(errMsg);
    return false;
};

export const getErrorMsg = (_isNewQuestion, _questionName, _currQuestionType, _dataError) => {
    let commonErr = '';
    if (!cleanupDivs(_questionName))
        commonErr = 'Введите вопрос.';
    else if (_isNewQuestion && _currQuestionType === UNDEFINED_QUESTION_TYPE) 
        commonErr = 'Выберите тип вопроса.';

    if (!commonErr && !_dataError) return '';
    return commonErr ? commonErr : _dataError;
};

export const getCurrentQuestion = (questionList, isNewQuestion, ind) => {
    if (questionList.length > 0 && !isNewQuestion)
        return questionList[ind];
    return undefined;
};

export const getOneOption = (itemIndex, title, description, handleDescription, 
        handleDeleteDescription, descLimit) => {
    return (
        <OneOption 
            itemIndex={itemIndex}
            title={title}
            description={description}
            handleDescription={handleDescription} 
            handleDeleteDescription={handleDeleteDescription}
            descLimit={descLimit}
    />
    );
};

const getOneTE = (answer, ind, handleChangeAnswer, handleDeleteOption, isEditable) => {
    const setAnswer = v => {
        handleChangeAnswer(v, ind);
    };

    return (
        <div className="cor-net__row" key={'ca'+(ind+1)}>
        <div className="cor-net__col" key={'ca2'+(ind+1)}>
        {/* <textarea key={'ta01'+ind} 
            value={answer} onInput={e => setAnswer(e.target.value)}/> */}

            {isEditable ? 
                getOneOption(ind, 'Вариант ' + (ind+1), answer, setAnswer, 
                    handleDeleteOption, MAX_DESC_LENGTH)
            :
                htmlParser(answer)
            }
        </div>
    </div>

    );
};

export const getTextEditorOptionFieldList = (list, handleChangeAnswer, handleDeleteOption, 
        handleAddOption, isEditable) => {

    return (
        <div className="cor-net__section mt_lg border">
            <div className="cor-net__title">Варианты ответа</div>
                {list.map((item, ind) => getOneTE(item.name, ind, handleChangeAnswer, 
                    handleDeleteOption, isEditable))}
            
            {isEditable && 
            <div className="cor-net__row">
                <div onClick={handleAddOption} className={ss.addOption}>
                    <Icon name="plus"/>
                    Добавить вариант
                </div>
            </div>}
        </div>
    );
};

///// --- onlineTestApi ---------------------------------------------------------------------------
export const getOnlineTestById = async (onlineTestId) => {
    return await onlineTestApi.getOnlineTestById(onlineTestId)
        .then (res => res)
        .catch(err => console.log('err=', err));
}

///// --- onlineTestInfoApi ---------------------------------------------------------------------------
export const getOnlineTestInfoByRunId = async (owner, otRunId) => {
    return await onlineTestInfoApi.getOnlineTestInfoByRunId(owner, otRunId)
        .then (res => res.data)
        .catch(err => {console.log('err=', err);}
    );
};

export const updateOnlineTestInfoById = async (id, onlineTestData) => {
    const result = await onlineTestInfoApi.updateOnlineTestInfoById(id, onlineTestData)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
}

export const updateOnlineTestInfoScoreById = async (id, score) => {
    const result = await onlineTestInfoApi.updateOnlineTestInfoScoreById(id, {score: score})
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
}

export const isDisabled = isEditable => !isEditable ? 'disabled' : '';

const isOneAnswer = qType => qType === QUESTION_TYPES.CORRECT_ANSWER_ONE;
const isMultipleAnswers = qType => qType === QUESTION_TYPES.CORRECT_ANSWER_MANY;
const isCompleteSentence = qType => qType === QUESTION_TYPES.COMPLETE_SENTENCE;
const isFreeWriting = qType => qType === QUESTION_TYPES.FREE_WRITING;    
const isVideo = qType => qType === QUESTION_TYPES.VIDEO;

export const getC2MinAnswerNum = (qType) => {
    return (isOneAnswer(qType) || isCompleteSentence(qType) || isVideo(qType)) ? 2 : 
            isMultipleAnswers(qType) ? 3 : 0;
};

const cleanupAnswerList = list => {
    const cleanedList = list.map(item => cleanupDivs(item.name));
    return cleanedList;
};

export const checkC2Error = (_answerList, _currQuestionType, _video, minAnswerNum) => {
    const hasEmptyAnswers = cleanupAnswerList(_answerList).filter(item => !item).length > 0;
    if (hasEmptyAnswers) return 'Заполните все варианты ответов.';

    const isNotEnoughAnswers = _answerList.length < minAnswerNum; 
    if (isNotEnoughAnswers) return 'Количество предлагаемых ответов должно быть не меньше ' + minAnswerNum + '.';

    if (!isMultipleAnswers(_currQuestionType)) {
        if (isVideo(_currQuestionType) && !_video) 
            return 'Добавьте видео.';
    } else  {
        const isNotEnoughCorrectOptions = _answerList.filter(item => item.correct).length < minAnswerNum - 1;
        if (isNotEnoughCorrectOptions) return 'Количество вариантов ответа должно быть не меньше ' + (minAnswerNum - 1) + '.';

        const isTooManyCorrectOptions = _answerList.filter(item => item.correct).length === _answerList.length;
        if (isTooManyCorrectOptions) return 'Нельзя указать все варианты как правильные.';
    } 

    return '';
};

//===============================
const getTaskNumber = (data) => {
    let num = 0;
    for (let i = 0; i < data.length; i ++) {
        const run = data[i].delayOpts;
        if (checkTimeRange(run.runDate, run.completeDate, CHECK_TIME_TYPE.INSIDE))
            num ++;
    }
    return num;
};

export const getOtInfoMessage = async (room, owner) => { //use in Aside 
    const result = await onlineTestInfoApi.getAllNonCompletedOtisByOwner(room, owner);
    const data = result.data; //.filter(item => !item.isComplete);

    const num = getTaskNumber(data);
    if (num > 0) 
        return 'Вы должны выполнить ' +  (num === 1 ? '1 новый онлайн тест ' : 'новые онлайн тесты') + '.';
    return '';
};

export const getTestScoreOpts = (isAutoScore, isGood, type) => {
    let iconName, iconText, status, info;
    const name1 = type === 'nb' ? 'Задание' : 'Тест';
    const name2 = type === 'nb' ? 'задания' : 'теста';
    const name3 = type === 'nb' ? 'ваше задание' : 'ваш тест';
    const name4 = type === 'nb' ? 'зачтено' : 'зачтен';

    if (isAutoScore) {
        iconName = isGood ? 'info' : 'block-ban';
        iconText = name1 + ' ' + (isGood ? name4 : 'не ' + name4);
        status = isGood ? 'good' : 'bad';
        info = (isGood ? 'Поздравляем! ' : '') +  'Вы закончили выполнение ' + name2;
    } else {
        iconName = 'warning';
        iconText = name1 + ' на проверке у преподавателя';
        status = "unknown";
        info = 'Вы закончили выполнение ' + name2 + '. ' + 
            'Преподаватель проверит ' + name3 + ' и выставит оценку. ' +
            'Вы сможете увидеть вашу оценку в вашем профиле';
    }

    return [iconName, iconText, status, info];
};

