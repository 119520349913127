import React from 'react';
import "../Content.scss";

const ContentHead = (props) => {
    const {
        children,
        className = '',
        title,
        flex = true,
        column = false,
    } = props;
    
    let headClass = "cor_content__head ";
    
    if (!flex) {
        headClass += " no-flex ";
    }

    if (column) {
        headClass += " fd-column ";
    }
    return (
        <div className={headClass + className}>
            {title && <div className="cor_content__title">{title}</div>}
            {children}
        </div>
    )
};
export default ContentHead;
