import React, {useState, useEffect, useRef} from "react";
import {SCALEPOS, CALC_MODEL, GraphicOpts, getSvgWithHeightWidth, getMinMax, getInputRange,
	getPlus, getMinus, getMarkerInfo, getArrowImage, getSvgRect, getRopeLinesArray, //getB, 
	getScaleData, getScaleValues, getAxis, getStrokes, getGrid, getSvgMouseCrossCursor, 
	getMagneticFieldAsLinesWithArrows, getBackground, getBorder, transInnerXYtoScreenXY, getBSize, 
	getEllipseTangent, getHeaderBox, getModeColorOpts, getDashedAxis, //getMagneticFieldAsWholeEllipses, 
	addCalcModelInfo, getCalcModelInfoById} from "./cm_utils";
import {getInitMagneticFieldData, getB2} from "./magneticField_utils";
import {getRoundValue} from "../ui/utils/gen_utils";
import {storageClass} from "../../redux/slices/storage";
import {useSelector} from "react-redux";
import CoilMagneticFieldGraphs from "./CalcModel_CoilMagneticField_Graphs";
import {Icon} from '../ui';
import {Button} from 'rlabui';
import {toast} from "react-toastify";
import {isAuth} from "../../helpers/auth";
import {InputRange} from '../ui';
import {Content, ContentBody} from "../template/ContentParts";
import "./CalcModel.scss";

const dY = 0; //20; //высота края
const arrowDX = 20; //коэффициент возрастания X радиуса 
const arrowDY = 3; //коэффициент возрастания Y радиуса 
const ropeStartDY = 0; // 30; //насколько смещена y-координата начала веревки
const ropeDY = 1; //толщина веревки
const DEFAULT_SVG_X_RANGE2 = 800;
const DEFAULT_SVG_Y_RANGE2 = 800;
const COIL_RADIUS_RANGE = [50, 500];
const COIL_LENGTH_RANGE = [100, 500];
const WIND_NUMBER_RANGE = [1, 20];
const CURRENT_POWER_RANGE = [1, 5000];
const idMainSvg = 'mainSvg';
const svgXRange = [-DEFAULT_SVG_X_RANGE2, DEFAULT_SVG_X_RANGE2]; //can be chaged by horizontal scroll //setSvgXRange
const svgYRange = [-DEFAULT_SVG_Y_RANGE2, DEFAULT_SVG_Y_RANGE2]; //can be chaged by vertical scroll //setSvgYRange

const CoilMagneticField = ({history, match, isInnerCM, isLightMode}) => {
	const [initCoilRadius, setInitCoilRadius] = useState(50); //высота катушки (50 - 500 mm)
	const [initCoilLength, setInitCoilLength] = useState(100); //длина катушки (100 - 500 mm)
	const [initWindingNumber, setInitWindingNumber] = useState(5); //кол-во обмоток (1 - 20 / mm)
	const [initCurrentPower, setInitCurrentPower] = useState(1000); //сила тока (0 - 5000 mA)
	const [coilRadius, setCoilRadius] = useState(50); //высота катушки (50 - 500 mm)
	const [coilLength, setCoilLength] = useState(100); //длина катушки (100 - 500 mm)
	const [windingNumber, setWindingNumber] = useState(5); //кол-во обмоток (1 - 20 / mm)
	const [currentPower, setCurrentPower] = useState(1000); //сила тока (0 - 5000 mA)
	const [scaleCoordX, setScaleCoordX] = useState(50);
	const [scaleCoordY, setScaleCoordY] = useState(50);
	const [svgWidth, setSvgWidth] = useState(undefined);
	const [svgHeight, setSvgHeight] = useState(undefined);
	const [centerX, setCenterX] = useState(0);
	const [centerY, setCenterY] = useState(0);
	const [cursorX, setCursorX] = useState(0);
	const [cursorY, setCursorY] = useState(0);

	const [svgArr, setSvgArr] = useState([]);
	const [isMouseButtonClicked, setIsMouseButtonClicked] = useState(false);
	const [markerInfo, setMarkerInfo] = useState(undefined);
	const [markerArrowInfo, setMarkerArrowInfo] = useState(undefined);
	const [isRecalcMarkerArrowInfo, setIsRecalcMarkerArrowInfo] = useState(false);
	const [showMagneticFieldGraphs, setShowMagneticFieldGraphs] = useState(false);
	const [initMagneticFieldData, setInitMagneticFieldData] = useState(undefined);
	const [bxMinMax, setBxMinMax] = useState([undefined, undefined]);
	const [byMinMax, setByMinMax] = useState([undefined, undefined]);
	const [lockRecalcState, setLockRecalcState] = useState(0);
	const [resize, setResize] = useState(0);

    const documentClass = useSelector(storageClass);
	const refMainBox = useRef();
	const refMainSvg = useRef();
	const coilRadiusRef = useRef();
	const coilLengthRef = useRef();
	const windingNumberRef = useRef();
	const currentPowerRef = useRef();

	const handleMouseClickEvent = event => {
		const rect = getSvgRect();
		if (!rect || event.clientX < rect.left || event.clientX > rect.right || 
			event.clientY < rect.top || event.clientY > rect.bottom) {
				return;
		} 

		setCursorX(event.clientX);
		setCursorY(event.clientY);
		setIsMouseButtonClicked(true);
	};

	const handleInputMouseDownEvent = event => {
		setLockRecalcState(1);
	};

	const handleInputMouseUpEvent = event => {
		setLockRecalcState(2);
	};

	useEffect(() => {
		if (match.params.opt) {
			getCalcModelInfoById(match.params.opt).then((data) => {
				const cmInfoId = data[0].options;
				setCoilRadius(cmInfoId.coilRadius);
				setCoilLength(cmInfoId.coilLength);
				setWindingNumber(cmInfoId.windingNumber);
				setCurrentPower(cmInfoId.currentPower);
			});
		}
	}, [match.params.opt]);

	useEffect(() => {
		const handleResize = e => setResize(e[0].contentBoxSize[0].inlineSize);
		const observer = new ResizeObserver(handleResize);
		observer.observe(refMainBox.current);
		return () => {
			observer.disconnect();
		};
	}, []);

	useEffect(() => {
		const width = refMainSvg.current.offsetWidth;
		const height = refMainSvg.current.offsetHeight;
		setSvgWidth(width);
		setSvgHeight(height);
		setCenterX(width * 0.5);
		setCenterY(height * 0.5);
	}, [resize]);

	useEffect(() => {
		const addEventsById = id => {
			const obj = document.getElementById(id);
			obj?.addEventListener("mousedown", handleInputMouseDownEvent);
			obj?.addEventListener("mouseup", handleInputMouseUpEvent);
		};
		const removeEventsById = id => {
			const obj = document.getElementById(id);
			obj?.removeEventListener("mousedown", handleInputMouseDownEvent);
			obj?.removeEventListener("mouseup", handleInputMouseUpEvent);
		};

		document.getElementById(idMainSvg)?.addEventListener("click", handleMouseClickEvent);
		addEventsById('idCoilRadius');
		addEventsById('idCoilLength');
		addEventsById('idCurrentPower');
		addEventsById('idWindingNumber');

		return () => {
			document.getElementById(idMainSvg)?.removeEventListener("click", handleMouseClickEvent);
			removeEventsById('idCoilRadius');
			removeEventsById('idCoilLength');
			removeEventsById('idCurrentPower');
			removeEventsById('idWindingNumber');
		};
	}, []);

	useEffect(() => {
		if (lockRecalcState > 0) return; //ignore redrawing if one of inputs is pressed
		const _markerInfo = getMarkerInfo(svgXRange, svgYRange, coilLength, coilRadius, windingNumber, currentPower, 
			svgWidth, svgHeight, cursorX, cursorY);
		setMarkerInfo(_markerInfo);
	}, [coilLength, coilRadius, currentPower, cursorX, cursorY, svgHeight, svgWidth, windingNumber, lockRecalcState]);

	const getScalePos = (isHoriz, coord, width) => {
		return (width - 1) * 0.01 * (isHoriz ? coord : 100 - coord);
		//if (isHoriz) return w * coord;else return w * (100 - coord);
	};

	const getAxisesPos = (xRange, yRange, _scaleCoordX, _scaleCoordY) => {
		const xPos = (_scaleCoordX - 50) / 100 * (xRange[1] - xRange[0]); //in mm
		const yPos = (_scaleCoordY - 50) / 100 * (yRange[1] - yRange[0]); //in mm
		return [xPos, yPos];
	};

	const getInitBData = (_svgHeight, _svgWidth, _svgXRange, _svgYRange, _coilLength, _coilRadius, _windingNumber, _currentPower) => {
		const Z1 = transInnerXYtoScreenXY([_svgWidth, _svgHeight], _svgXRange, _svgYRange, -_coilLength/2, 0)[0];
		const Z2 = transInnerXYtoScreenXY([_svgWidth, _svgHeight], _svgXRange, _svgYRange, _coilLength/2, 0)[0];
		const _initMagneticFieldData = getInitMagneticFieldData(_coilRadius, 0.05, 
			_windingNumber * _coilLength, _currentPower, _coilLength, Z1, Z2);
		setInitMagneticFieldData(_initMagneticFieldData);
	};

	useEffect(() => {
		if (lockRecalcState > 0) return; //ignore redrawing if one of inputs is pressed
		const _initMagneticFieldData = getInitBData(svgHeight, svgWidth, svgXRange, svgYRange, 
			coilLength, coilRadius, windingNumber, currentPower);
		setInitMagneticFieldData(_initMagneticFieldData);
	}, [coilLength, coilRadius, currentPower, svgHeight, svgWidth, windingNumber, lockRecalcState]);

	const getGraphOpts = (_svgWidth, _svgHeight, _scaleCoordX, _scaleCoordY, _svgXRange, _svgYRange, 
		_coilLength, _coilRadius, _currentPower, _windingNumber, _initMagneticFieldData) => {
		const [xPos, yPos] = getAxisesPos(_svgXRange, _svgYRange, _scaleCoordX, _scaleCoordY);
		//const B = getB(xPos, yPos, coilLength, coilRadius, windingNumber, currentPower);
		const B = getB2([xPos, yPos], _initMagneticFieldData);

		const graphOpts = {
			xPos: xPos, 
			yPos: yPos,
			B: B,
			svgWidth: _svgWidth,
			svgHeight: _svgHeight,
			scaleCoordX: _scaleCoordX,
			scaleCoordY: _scaleCoordY,
			svgXRange: _svgXRange,
			svgYRange: _svgYRange,
			coilLength: _coilLength, 
			coilRadius: _coilRadius, 
			windingNumber: _windingNumber, 
			currentPower: _currentPower
		};

		return graphOpts;
	};

	const getPlusAndMinus = (_svgWidth, _svgHeight, _svgXRange, _svgYRange, _centerX, _centerY, _coilLength, _coilRadius) => {
		let [lm,rm] = transInnerXYtoScreenXY([_svgWidth, _svgHeight], _svgXRange, _svgYRange, _coilLength/2, _coilRadius);
		const minus = getMinus(_centerX - lm + 4, _centerY + rm + 8, 4);
		const plus = getPlus(_centerX + lm - 4, _centerY + rm + 8, 4);
		return <path key={"plusminus01"} d={plus + minus} fill={GraphicOpts.plusMinusColor} stroke={GraphicOpts.plusMinusColor} strokeWidth={1}/>;
	};
	
	const getСoil = (_svgWidth, _svgHeight, _svgXRange, _svgYRange, _centerX, _centerY, 
		_coilLength, _coilRadius, varX2, varY2) => {
		//Method N2:
		let [lm,rm] = transInnerXYtoScreenXY([_svgWidth, _svgHeight], _svgXRange, _svgYRange, _coilLength/2, _coilRadius);
		return (<rect key="coilrect12"  x={_centerX-lm} y={_centerY-rm} width={2*lm} height={2*rm} 
				stroke={GraphicOpts.solenoidColor} fill={GraphicOpts.solenoidColor} 
				strokeWidth="3px" rx="3px" ry="3px" strokeLinejoin="round" />);

		//Method N1:
		//const polygonPoints = getPolygonPointArray(_centerX, _centerY, varX2, varY2, _dX, _dY);
		//const points = polygonPoints.map(item => " " + item[0] + " " + item[1] + " ").join();
		//return <polygon points={points} stroke={GraphicOpts.solenoidColor} fill={GraphicOpts.solenoidColor} strokeWidth="5"/>;
	};

	const getRope = (_centerX, _centerY, _windingNumber, _coilLength, varX2, varY2, _ropeDY, _ropeStartDY, _dY) => {
		const ropePoints = getRopeLinesArray(_centerX, _centerY, varX2, varY2, _windingNumber, _coilLength, 
			_ropeDY, _ropeStartDY, _dY);
		let strPoints = '';
		for (let i = 0; i < ropePoints.length; i += 2) {
			strPoints += 'M ' + ropePoints[i][0]   + ' ' + ropePoints[i][1]   + ' ' + 
						 'L ' + ropePoints[i+1][0] + ' ' + ropePoints[i+1][1] + ' ';
		}
		return <path key={"rope01"} d={strPoints} fill={GraphicOpts.ropeColor} 
			stroke={GraphicOpts.ropeColor} strokeWidth={GraphicOpts.ropeWidth} opacity={GraphicOpts.ropeOpacity} />;
	};
 
	const getMagneticFieldArrows = (_centerX, _centerY, radiusX, radiusY, _arrowDX, _arrowDY, _isLightMode) => {
		const getEllipse = (_centerX, _centerY, radiusX, radiusY, index, isTop, showAllEllipse, _isLightMode, arr) => {
			//SHOW WHOLE ELLIPSES:
			getMagneticFieldAsLinesWithArrows(_centerX, _centerY, radiusX, radiusY, index, isTop, showAllEllipse, _isLightMode, arr);
		};		
		const arrows = [];
		const coefList = [1, 2, 4, 8, 12, 16];
		for (let i = 0; i < coefList.length; i++) 
			getEllipse(_centerX, _centerY, radiusX * coefList[i], radiusY * coefList[i], 1, true, i === 0, _isLightMode, arrows);

		return arrows;
	};

	useEffect(() => { //get Br Bz min and max
		if (lockRecalcState > 0) return; //ignore redrawing if one of inputs is pressed
		const rangeSizes = [0.25]; //, 0.5, 1];
		const expNumber = 50;

		const bxValues = [];
		const byValues = [];
		const _initMagneticFieldData = getInitBData(svgHeight, svgWidth, svgXRange, svgYRange, 500, 500, 20, 5000);

		for (let i = 0; i < rangeSizes.length; i++) {
			const xRange = [svgXRange[0] * rangeSizes[i], svgXRange[1] * rangeSizes[i]];
			const yRange = [svgYRange[0] * rangeSizes[i], svgYRange[1] * rangeSizes[i]];

			for (let k = 0; k < expNumber; k++) {
				const x = (Math.random() - 0.5) * (xRange[1] - xRange[0]);
				const y = (Math.random() - 0.5) * (yRange[1] - yRange[0]);
				const [Bx, By] = getB2([x, y], _initMagneticFieldData);
				bxValues.push(Bx);
				byValues.push(By);
			}
		}
		const [BxMin, BxMax] = getMinMax(bxValues);
		const [ByMin, ByMax] = getMinMax(byValues);

		setBxMinMax([BxMin, BxMax]);
		setByMinMax([ByMin, ByMax]);
	}, [svgHeight, svgWidth, lockRecalcState]);

	//clicked mouse cursor
	const getClickedMouseCursorAndCalcData = (_centerX, _centerY, _svgHeight, _ellPars, 
		_bxMinMax, _byMinMax, _markerInfo, _currentPower, _coilLength,
		_cursorX, _cursorY, _isMouseButtonClicked, _isLightMode, _mai, 
		_isRecalcMarkerArrowInfo, _setIsMouseButtonClicked) => {
		if (_isMouseButtonClicked && !_currentPower) {
			toast.warn("Установите силу тока. В настоящее время она равна 0.");
			_setIsMouseButtonClicked(false);
		}
		else if (_isMouseButtonClicked) {
			_setIsMouseButtonClicked(false);
			const arrowLength = getBSize([_markerInfo.Bx, _markerInfo.By], _bxMinMax, _byMinMax);
			const rect = getSvgRect();
			const [x1, y1] = [_cursorX - rect.left, _cursorY - rect.top]; //cursor position
			const __markerArrowInfo = getEllipseTangent(_ellPars, x1, y1, _centerX, _centerY, 
				_currentPower, _coilLength, arrowLength);
			setMarkerArrowInfo(__markerArrowInfo);
			return getArrowImage(_isLightMode, __markerArrowInfo);
		} else if (_mai && _isRecalcMarkerArrowInfo) {
			const arrowLength = getBSize([_markerInfo.Bx, _markerInfo.By], _bxMinMax, _byMinMax);
			const __markerArrowInfo = getEllipseTangent(_ellPars, _mai[0][0], _mai[0][1], _centerX, _centerY, 
				_currentPower, _coilLength, arrowLength);
			setMarkerArrowInfo(__markerArrowInfo);
			setIsRecalcMarkerArrowInfo(false);
			return getArrowImage(_isLightMode, __markerArrowInfo);
		} else if (_mai) {
			return getArrowImage(_isLightMode, _mai);
		}

		return undefined;
	};

	const getTangentToEllipsePoint = (_svgWidth, _svgHeight, _svgXRange, _svgYRange, _centerX, _centerY, _cursorX, _cursorY, 
			_coilLength, _coilRadius) => {
		if (_cursorX === 0 && _cursorY === 0) return;
		//https://byjus.com/jee/equation-of-tangent-to-ellipse/ - как найти касательную к эллипсу
		//x1**2/a**2 + (y1-b)**2/b2 = where b = ka => a = (k**2 * x1**2 + y1**2)/(2*k*y1)
		//tangent: 
		let [lm, rm] = transInnerXYtoScreenXY([_svgWidth, _svgHeight], _svgXRange, _svgYRange, _coilLength/2, _coilRadius);
		const rlRatio = rm / lm;

		const rect = getSvgRect();
		const rleft = rect.left;
		const rtop = rect.top;
		const [cursorPosX, cursorPosY]  = [_cursorX - rleft, _cursorY - rtop];  //cursor position
		const x1 = cursorPosX - _centerX;
		const y1 = cursorPosY - _centerY;
		const isCursorInsideSolenoid = (x1 >= -lm && x1 <= lm && y1 >= -rm && y1 <= rm);

		//1 - get the ellipse:
		const kx1 = rlRatio * x1;
		const kx1q = kx1 * kx1;
		const y1q = y1 * y1;
		const isBottom = y1 >= 0;
		let ellRA = (kx1q + y1q) / (2 * rlRatio * y1);
		ellRA *= (ellRA >= 0 ? +1 : -1);
		const ellRB = rlRatio * ellRA;

		return {ellRA: ellRA, ellRB: ellRB, isBottom: isBottom, isCursorInsideSolenoid: isCursorInsideSolenoid};
	};

	useEffect(() => {
		if (lockRecalcState !== 2) return; //ignore redrawing if one of inputs is pressed
		setLockRecalcState(0);
		setCoilRadius(initCoilRadius);
		setCoilLength(initCoilLength);
		setWindingNumber(initWindingNumber);
		setCurrentPower(initCurrentPower);

		if (!!markerArrowInfo && !isRecalcMarkerArrowInfo) {
			setIsRecalcMarkerArrowInfo(true);
		}
	}, [lockRecalcState, initCoilRadius, initCoilLength, initWindingNumber, initCurrentPower, 
		isRecalcMarkerArrowInfo, markerArrowInfo]);

	useEffect(() => {  //Q1
		//https://www.w3schools.com/graphics/svg_stroking.asp - svg options
		if (lockRecalcState > 0) return; //ignore redrawing if one of inputs is pressed
		if (!svgWidth || !svgHeight) return;
	
		const [radiusX, radiusY] = transInnerXYtoScreenXY([svgWidth, svgHeight], svgXRange, svgYRange, 0.5 * coilLength, coilRadius);

		//0 - массив для хранения всех элементов svg:
		const svgData = [];

		//1 - get full rectangle with backround color:
		svgData.push(getBackground(svgWidth, svgHeight, GraphicOpts.backgroundOpacity, getModeColorOpts(isLightMode).calcModelBknd));

		//2 - get magnetic field as as a set of ellipses with arrows:
		svgData.push(getMagneticFieldArrows(centerX, centerY, radiusX, radiusY, arrowDX, arrowDY, isLightMode));

		//3 - initialize scale arrays:
		const horizScaleArr = getScaleData(SCALEPOS.horiz, SCALEPOS.right, 17, svgXRange[0], svgXRange[1],   0, svgWidth, svgHeight * 0.5,    1, 0, 0);
		const vertScaleArr = getScaleData(SCALEPOS.vert, SCALEPOS.right, 9, svgYRange[0], svgYRange[1],   0, svgHeight, svgWidth * 0.5,    6, 0, 0);
		
		//4 - get grid:
		svgData.push(getGrid(horizScaleArr, SCALEPOS.horiz, 0, 0, svgWidth, svgHeight, getModeColorOpts(isLightMode).gridColor, GraphicOpts.gridWidth, GraphicOpts.gridOpacity));
		svgData.push(getGrid(vertScaleArr, SCALEPOS.vert, 0, 0, svgWidth, svgHeight, getModeColorOpts(isLightMode).gridColor, GraphicOpts.gridWidth, GraphicOpts.gridOpacity));

		//5 - get center axises and scales:
		//x-axis:
		const horizStrokes = getStrokes(horizScaleArr, GraphicOpts.timeColor, 1, 0.9, "horiz");
		svgData.push(horizStrokes); //1) strokes
		const horizScaleValues = getScaleValues(horizScaleArr, SCALEPOS.horiz, SCALEPOS.left, GraphicOpts.timeColor, "horiz1", true);
		svgData.push(horizScaleValues); //2) values on xAxis 
		svgData.push(getAxis(0, svgHeight * 0.5, svgWidth, 1, GraphicOpts.scaleLineColor, GraphicOpts.scaleLineWidth, GraphicOpts.scaleLineOpacity)); //3) xAxis

		//y-axis:
		const vertStrokes = getStrokes(vertScaleArr, GraphicOpts.timeColor, 1, 0.9, "vert");
		svgData.push(vertStrokes);  //1) strokes
		const vertScaleValues = getScaleValues(vertScaleArr, SCALEPOS.vert, SCALEPOS.right, GraphicOpts.timeColor, "vert1", true);
		svgData.push(vertScaleValues); //2) values on yAxis 
		svgData.push(getAxis(svgWidth * 0.5, 0, 1, svgHeight, GraphicOpts.scaleLineColor, GraphicOpts.scaleLineWidth, GraphicOpts.scaleLineOpacity)); //3) yAxis

		//6 - get dynamic axises:
		const xScalePos = getScalePos(true, scaleCoordX, svgWidth);
		svgData.push(getDashedAxis(xScalePos, 0, 1, svgHeight, GraphicOpts.selectionLineColor, 1, GraphicOpts.selectionLineOpacity, "40 10"));

		const yScalePos = getScalePos(false, scaleCoordY, svgHeight);
		svgData.push(getDashedAxis(0, yScalePos, svgWidth, 1, GraphicOpts.selectionLineColor, 1, GraphicOpts.selectionLineOpacity, "40 10"));

		//7 - get coil:
		svgData.push(getСoil(svgWidth, svgHeight, svgXRange, svgYRange, centerX, centerY, 
			coilLength, coilRadius, radiusX, radiusY));

		//8 - get rope:
		svgData.push(getRope(centerX, centerY, windingNumber, coilLength, radiusX, radiusY, ropeDY, ropeStartDY, dY));

		//9 - draw plus and minus:
		svgData.push(getPlusAndMinus(svgWidth, svgHeight, svgXRange, svgYRange, centerX, centerY, coilLength, coilRadius));

		//10 - get moved mouse cursor
		svgData.push(getSvgMouseCrossCursor(cursorX, cursorY, idMainSvg));

		//11 - get Tangent when clicking
		const ellPars = getTangentToEllipsePoint(svgWidth, svgHeight, svgXRange, svgYRange, 
			centerX, centerY, cursorX, cursorY, coilLength, coilRadius);
		
		//?12 - get current ellipse (optional) - for internal use
		//getMagneticFieldAsWholeEllipses(centerX, centerY, ellPars?.ellRA, ellPars?.ellRB, 0, ellPars?.isBottom, "grey", 0.9, svgData);

		//13 - draw clicked mouse cursor and keep cursor data if needed:
		svgData.push(getClickedMouseCursorAndCalcData(centerX, centerY, svgHeight, ellPars, 
			bxMinMax, byMinMax, markerInfo, currentPower, coilLength, cursorX, cursorY, 
			isMouseButtonClicked, isLightMode, markerArrowInfo, isRecalcMarkerArrowInfo, setIsMouseButtonClicked));

		//14 - get border:
		svgData.push(getBorder(svgWidth, svgHeight-1, GraphicOpts.extBoxColor, "_border01", 0.5));

		setSvgArr(svgData);
	}, [lockRecalcState, coilLength, coilRadius, scaleCoordX, scaleCoordY, 
		cursorX, cursorY, svgWidth, svgHeight, centerX, centerY, 
		windingNumber, bxMinMax, byMinMax, markerInfo, currentPower, 
		isMouseButtonClicked, markerArrowInfo, isRecalcMarkerArrowInfo, isLightMode]);

	const getSvg = (obj) => {
		return getSvgWithHeightWidth(svgHeight, svgWidth, obj, 'svg1');
	};

	const  handleCoilRadius = e => {
		setInitCoilRadius(Number(e.target.value));
	};

	const handleWindingNumber = e => {
		let value = Number(e.target.value);
		setInitWindingNumber(value);
		e.target.style.setProperty('--val', value);
	}

	const  handleCoilLength = e => {
		setInitCoilLength(Number(e.target.value));
	};
	const  handleCurrentPower = e => {
		setInitCurrentPower(Number(e.target.value));
	};

	// const getScaleRange = (value) => { //value is between 0 and 100.
	// 	let rng;
	// 	if (value >= 50) rng = DEFAULT_SVG_X_RANGE2 * (1 + (SVG_RANGE_MULTIPLIER - 1) * (value-50) * 0.02); //увеличиваем до SVG_RANGE_MULTIPLIER раз
	// 	else  rng = DEFAULT_SVG_X_RANGE2 / (1 + (SVG_RANGE_MULTIPLIER - 1) * (50-value) * 0.02); //уменьшаем до SVG_RANGE_MULTIPLIER раз
	// 	return [-rng, rng];
	// };

	const  handleScaleCoordX = e => {
		setScaleCoordX(Number(e.target.value));
		//setSvgXRange(getScaleRange(Number(e.target.value)));
	};

	const  handleScaleCoordY = e => {
		setScaleCoordY(Number(e.target.value));
		//setSvgYRange(getScaleRange(Number(e.target.value)));
	};

	const getBattery = () => {
		const batteryList = [];
		for (let i = 0; i < currentPower / 1000; i ++) 
			batteryList.push(<Icon name="battery" className="calcModel__btnIcon" key={"battery"+i} />);
		return (<>{batteryList.map(item => item)}</>);
	};

	const handleShowGraphs = () => {
		if (!currentPower)
			toast.warn("Установите силу тока. В настощее время она равна 0.");
			//else if (!marker) toast.warn("Установите точку на карте.");			
		else 
			setShowMagneticFieldGraphs(true);
	};

	const saveProfile = async (name, description) => {
		// const opts = getGraphOpts(svgWidth, svgHeight, scaleCoordX, scaleCoordY, svgXRange, svgYRange, 
		// 	coilLength, coilRadius, currentPower, windingNumber, initMagneticFieldData)
		const options = {
			coilLength: coilLength, 
			coilRadius: coilRadius, 
			currentPower: currentPower, 
			windingNumber: windingNumber,
			scaleCoordX: scaleCoordX, 
			scaleCoordY: scaleCoordY,
		};
        const profile = {
            owner: isAuth()._id,
			room: documentClass._id,
            cmType: CALC_MODEL.MAGNETIC_FIELD,
            name: name,
            description: description,
			options: options,
        };

        await addCalcModelInfo(profile, documentClass._id);
    };

	const getOpts = () => {
		const coilRadiusData = { 
			value: initCoilRadius,
			ref: coilRadiusRef,
			handleChange: e => handleCoilRadius(e),
			id: 'idCoilRadius',
			min: COIL_RADIUS_RANGE[0],
			max: COIL_RADIUS_RANGE[1],
			step: 1,
			currValue: coilRadius,
			setValue: setCoilRadius,
			key: 'coilrad01',
		};
		const coilLengthData = {
			value: initCoilLength,
			ref: coilLengthRef,
			handleChange: e => handleCoilLength(e),
			id: 'idCoilLength',
			min: COIL_LENGTH_RANGE[0],
			max: COIL_LENGTH_RANGE[1],
			step: 1,
			currValue: coilLength,
			setValue: setCoilLength,
			key: 'coillen01',
		};
		const windingNumberData = {
			value: initWindingNumber,
			ref: windingNumberRef,
			handleChange: e => handleWindingNumber(e),
			id: 'idWindingNumber',
			min: WIND_NUMBER_RANGE[0],
			max: WIND_NUMBER_RANGE[1],
			step: 1,
			currValue: windingNumber,
			setValue: setWindingNumber,
			key: 'wind01',
		};
		const currentPowerData = {
			value: initCurrentPower,
			ref: currentPowerRef,
			handleChange: e => handleCurrentPower(e),
			id: 'idCurrentPower',
			min: CURRENT_POWER_RANGE[0],
			max: CURRENT_POWER_RANGE[1],
			step: 1,
			currValue: currentPower,
			setValue: setCurrentPower,
			key: 'currpd01',
		};

		return (
			<div className="calcModel__stretch">
			<div className="calcModel__rangeCol">
				<div className="calcModel__range calcModel__rangeCmf">  
					<div className="calcModel__label">Радиус соленоида <b>{getRoundValue(coilRadius, 0)} мм</b></div>
					{getInputRange(coilRadiusData)}
				</div>
				<div className="calcModel__range calcModel__rangeCmf"> 
					<div className="calcModel__label">Длина соленоида <b>{getRoundValue(coilLength, 0)} мм</b></div>
					{getInputRange(coilLengthData)}
				</div>
			</div>
			<div className="calcModel__rangeCol">
				<div className="calcModel__range calcModel__rangeCmf"> 
					<div className="calcModel__label">Плотность обмотки <b>{getRoundValue(windingNumber, 0)} витков/мм</b></div>
					{getInputRange(windingNumberData)}
				</div>
				<div className="calcModel__range calcModel__rangeCmf"> 
					<div className="calcModel__label">Сила тока <b>{getRoundValue(currentPower, 0)} mA</b> {getBattery()}</div>
					{getInputRange(currentPowerData)}
				</div>
			</div> 
		</div>
		);
	};

	const getButtonAndGraphInfo = () => {
		return (
		<div id="bottomBtn" className="calcModel__bottomButtons calcModel__bottomButtonsJustLeft">
			<div className="calcModel__leftBottomBtn">
				<Button onClick={handleShowGraphs}>
					Показать графики в точке ({getRoundValue(getAxisesPos(svgXRange, svgYRange, scaleCoordX, scaleCoordY)[0], 2)},&nbsp;
					{getRoundValue(getAxisesPos(svgXRange, svgYRange, scaleCoordX, scaleCoordY)[1], 2)})
				</Button>
			</div>
			<div className="calcModel__nowrap calcModel__nowrapPad">
				<span className="calcModel__cursorPos">X=<b>{!!markerInfo && getRoundValue(markerInfo.currSvgX, 2)} мм</b></span>
				<span className="calcModel__cursorPos">Y=<b>{!!markerInfo && getRoundValue(markerInfo.currSvgY, 2)} мм</b></span>
				<span className="calcModel__cursorPosSpace" />
				<span className="calcModel__cursorPos">Bx=<b>{!!markerInfo && getRoundValue(markerInfo.Bx, 3)} нТл</b></span>
				<span className="calcModel__cursorPos">By=<b>{!!markerInfo && getRoundValue(markerInfo.By, 3)} нТл</b></span>
			</div>
		</div>
		);
	};

    return (
		<>
			{getHeaderBox(history, match.params.id, isInnerCM, 'Магнитное поле катушки')}
			<Content>
				<ContentBody scrolled={false}>
					<div id="mainBox" ref={refMainBox} className="calcModel">
						<div className="calcModel__flexColumn calcModel__flexColumnCoil">
							<div className="calcModel__flexColumn calcModel__flexColumnCoilRange">
								<div className="calcModel__stretch_ScaleSvg"> 
									{/* Vertical axis control */}
									<div className="calcModel__inputRangeVert" style={{height: svgHeight}}>
										<InputRange value={scaleCoordY} onChange={e => {handleScaleCoordY(e)}} 
											min={0} max={100} step={1} showBar={false} orientation={'vertical'} />
									</div>
									<div id={idMainSvg} ref={refMainSvg} className="calcModel__coil">
										{getSvg(svgArr)}
									</div>
								</div>
								{/* Horizontal axis control */}
								<div className="calcModel__inputRangeBottom" style={{width: svgWidth}}>
									<InputRange value={scaleCoordX} onChange={e => {handleScaleCoordX(e)}} 
										min={0} max={100} step={1} showBar={false}  />
								</div>							
							</div>

							{getOpts()}
						</div>
						{getButtonAndGraphInfo()}
					</div>
				</ContentBody>
				
				{showMagneticFieldGraphs &&
            	<CoilMagneticFieldGraphs
                	showModal={showMagneticFieldGraphs}
                	setShowModal={setShowMagneticFieldGraphs}
					isLightMode={isLightMode}
					saveProfile={saveProfile}
                	graphicOptions={getGraphOpts(svgWidth, svgHeight, scaleCoordX, scaleCoordY, svgXRange, 
						svgYRange, coilLength, coilRadius, currentPower, windingNumber, initMagneticFieldData)}
					isInnerCM={isInnerCM}
    	        />
				}
			</Content>
		</>
    );
}

export default CoilMagneticField;
