import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {enabledEditScore} from "../practicum/lw_utils";
import {notebookInfoApi} from "../../api/api";
import {printOnlyDate} from "../../helpers/text";
import {loadCountersNotebook, loadCountersNotebookOnSubject} from "../../redux/slices/lists";
import socket from "../../socket";
import {DB_REFRESH, CHECK_TIME_TYPE, checkTimeRange} from "../ui/utils/gen_utils";
import {isAuth} from "../../helpers/auth";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import Table from "../ui/Table/Table";
import {SCORE_TYPE, getTableScoreList} from '../ui/utils/score_utils';
import {updateNotebookInfoScoreById, getNotebookById, getAllNotebookInfoByOwner} from "../Notebook/nb_utils";
import NotebooksViewtResultByTeacherDlg from '../Notebook/NotebooksViewtResultByTeacherDlg';

const ProfileNotebooksTable = ({profile, setScores}) => {
	const [tableData, setTableData] = useState([]);
	const [scoreUpdated, setScoreUpdated] = useState(false);
	const [canEditScore, setCanEditScore] = useState(false);
	const [showNbiResultDlg, setShowNbiResultDlg] = useState(false);
	const [selectedIds, setSelectedIds] = useState('');
	const [idBeDeleted, setIdBeDeleted] = useState(undefined);
    const [showConfirmDlg, setShowConfirmDlg] = useState(false);
    const [counter, setCounter] = useState(0);

	const documentClass = useSelector(storageClass);
    const dispatch = useDispatch();

	useEffect(() => {
		setCanEditScore(enabledEditScore(isAuth(), documentClass.classUsers));
	},[documentClass.classUsers]);

	useEffect(() => {
        socket.on("FE-refresh-db", ({type}) => {
            if (type === DB_REFRESH.NBI) setCounter(counter => counter + 1);
        });
        return () => {socket.off("FE-refresh-db");};
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const result = await getAllNotebookInfoByOwner(documentClass._id, profile._id);

			const list = [];
			let scores = [0, 0, 0, 0, 0];

			if (result && result.data.length > 0) {
				const nbis = result.data;

				for (let i = 0; i < nbis.length; i ++) {
					const nbi = nbis[i];
					const notebookId = nbi.notebook;
					const nb = await getNotebookById(notebookId);
					const correctAnswerNumber = nbi.tasks.filter(item => item.isCorrect === SCORE_TYPE.CORRECT).length;
					const totalNumber = nbi.tasks.length;
					const answerNumber = nb.settings.isAutoScore ?
						nbi.tasks.filter(item => item.isCorrect !== SCORE_TYPE.UNDEFINED).length
						:
						nbi.tasks.filter(item => item.userAnswerHistory).length
					const isComplete = nbi.isComplete;
					const execution = answerNumber === 0 ? 'Не приступал' : !isComplete ? 'Не завершено' : 'Завершено';
					const score = answerNumber === 0 && nbi.score === -1 ? undefined : nbi.score;

					const run = nb.run;
					let show = true;
					const tr = checkTimeRange(run.runFullDate, run.completeFullDate, CHECK_TIME_TYPE.AFTER)
					if (run && !tr && !isComplete) {
						show = false;
					}
	
					if (show) {
						const row = {
							titleLink: nb.titles.homeWorkName,
							date: nbi.startDate ? printOnlyDate(nbi.startDate) : '',
							answeredTasks: '' + answerNumber + ' из ' + totalNumber,
							solvedTasks: '' + correctAnswerNumber + ' из ' + totalNumber,
							execution: execution,
							score: score,
							actions: '',
							id: nbi.owner + '|' + nbi._id 
						};
		
						list.push(row);
						scores = getTableScoreList(scores, nbi.score);
					}
				}
            } 

			setScores(scores);
			setTableData(list);
			if (scoreUpdated) setScoreUpdated(false);
		}

		fetchData();
    },[documentClass._id, profile._id, scoreUpdated, setScores, canEditScore, showNbiResultDlg, counter]);

	const getNotebookInfoId = ids => ids.split('|')[1];

	const handleStudentTableDataUpdate = async (score, ids) => { //ids = owner_id + '|' + nbi_id
		const notebookInfoId = getNotebookInfoId(ids); //ids.split('|')[1];
		const scoreVal = score !== '' ? Number(score) : null;
		await updateNotebookInfoScoreById(notebookInfoId, {score: scoreVal}); //set score
		doRefreshDb();
		setScoreUpdated(true);
	};

	const doRefreshDb = () => {
		socket.emit('BE-refresh-db', {type: DB_REFRESH.NBI, roomId: documentClass._id});
		dispatch(loadCountersNotebook(documentClass._id)); 
		dispatch(loadCountersNotebookOnSubject(documentClass._id)); 
	};

	const handleShowDetails = ids => {
        setSelectedIds(ids);
        setShowNbiResultDlg(true);
	};

	const handleDelete = id => {
		setIdBeDeleted(id);
		setShowConfirmDlg(true);
	};
	const handleDeleteNo = () => {
		setShowConfirmDlg(false);
		setIdBeDeleted(undefined);
	};
	const handleDeleteYes = () => {
		setShowConfirmDlg(false);
		notebookInfoApi.deleteNotebookInfoById(getNotebookInfoId(idBeDeleted))
		.then(res => {
			setIdBeDeleted(undefined);
			setScoreUpdated(true);
			doRefreshDb();
		});
	};

	const canDeleteIDs = () => {
		if (isAuth().role === 0) return [];
		return tableData.map(item=> item.id);
	};

	const getTableHeader = () => {
		if (isAuth().role === 0)
			return [ 
				{column: 'Название работы', name: 'titleLink', style: { width: '23%'} }, 
				{column: 'Дата', name: 'date', style: { width: '15%'} }, 
				{column: 'Ответы', name: 'answeredTasks', style: { width: '15%'} }, 
				{column: 'Правильные ответы', name: 'solvedTasks', style: { width: '17%'} }, 
				{column: 'Выполнение', name: 'execution', style: { width: '15%'} }, 
				{column: 'Оценка учителя', name: 'score', style: { width: '15%'} }, 
				{column: 'id', name: 'id', style: { width: '0%'} }, 
			];

		return [ 
			{column: 'Название работы', name: 'titleLink', style: { width: '15%'} }, 
			{column: 'Дата', name: 'date', style: { width: '15%'} }, 
			{column: 'Ответы', name: 'answeredTasks', style: { width: '13%'} }, 
			{column: 'Правильные ответы', name: 'solvedTasks', style: { width: '12%'} }, 
			{column: 'Выполнение', name: 'execution', style: { width: '15%'} }, 
			{column: 'Оценка учителя', name: 'score', style: { width: '15%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '15%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];

	};

    return ( 
		<div>
			<Table 
				table={{
					header: getTableHeader(), 
					data: tableData, 
					handleTableDataUpdate: handleStudentTableDataUpdate, 
				}}
				sort={{
					hasSorting: true, 
					initSortInd: -2,
				}}
				link={{
					handleLink: id => handleShowDetails(id),
				}}
				actions={{
					handleDelete: ids => handleDelete(ids),
					canDeleteIDs: canDeleteIDs(),
				}}
				canEditScore={canEditScore}
			/>

			{showNbiResultDlg &&
			<NotebooksViewtResultByTeacherDlg
				showDlg={showNbiResultDlg}
				setShowDlg={setShowNbiResultDlg}
				selectedIds={selectedIds}
				setScoreUpdated={setScoreUpdated}
			/>
			}
			{showConfirmDlg &&
			<ModalConfirmDialog
				showConfirmDlg={showConfirmDlg} 
				handleNo={handleDeleteNo}
				handleYes={handleDeleteYes}
				question={'Вы действительно хотите удалить эту работу?'}
			/>
			}
		</div>
    )
}

export default ProfileNotebooksTable;
