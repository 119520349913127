export const printOnlyDate = (d) => new Date(d).toLocaleString(['ru-RU'],
  {dateStyle: 'short'});

export const printMediumOnlyDate = (d) => new Date(d).toLocaleString(['ru-RU'],
  {dateStyle: 'medium'});

export const printShortDate = (d) => new Date(d).toLocaleString(['ru-RU'],
    {dateStyle: 'short', timeStyle: 'short'}).replace(',', '');

export const printListDate = (d) => {d =new Date(d);
    return d.toLocaleString(['ru-RU'],d.toDateString() === new Date().toDateString()?
        {timeStyle:'short'}:{dateStyle:'medium', timeStyle:'short'});
};

export const printLongDate = (d) => {d =new Date(d);
    return d.toLocaleString(['ru-RU'],{dateStyle:'long', timeStyle:'long'});
};

const ROLES = [["Ученик", "Ученики", "Ученика"],
    ["Учитель", "Учители", "Учителя"],
    ["Администратор", "Администраторы", "Администратора"],
    ["Супер Админ", "Супер Админы", "Супер Админa"]];
export const printUserRole = (role) => ROLES[role ?? 0][0];
export const printUserRoleMul = (role) => ROLES[role ?? 0][1];
export const printUserRoleGen = (role) => ROLES[role ?? 0][2];

const NUM_CASES = [2, 0, 1, 1, 1, 2];
export const printNumbered = (number, titles, include=true)=> {
    return (include ? number + ' ' : '') +
        titles[(number % 100 > 4 && number % 100 < 20) ? 2 : NUM_CASES[(number % 10 < 5) ? number % 10 : 5]];
}

const SIZES = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
export const formatBytes = (bytes, dec = 2) => {
    if (bytes === 0) {
        return '0';
    } else {
        const k = 1024;
        const pow = Math.floor(Math.log(bytes) / Math.log(k));
        return +((bytes / Math.pow(k, pow)).toFixed(dec)) + ' ' + SIZES[pow];
    }
}

export const nextYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0];
