import React, {useState, useEffect} from "react";
import {isAuth} from '../../helpers/auth';
import {storageClass} from "../../redux/slices/storage";
import NotebookStudentHomeWorkExecutePages from "./NotebookStudentHomeWorkExecutePages";
import NotebookStudentHomeWorkExecuteAside from "./NotebookStudentHomeWorkExecuteAside";
import {prepareNbiData, getTaskAnswerOptions, getInitiatedTasks, findNonAnsweredPage} from './task_utils';
import {getNotebookById, getNotebookInfoById} from "./nb_utils";
import {notebookInfoApi} from "../../api/api";
import {shuffle} from "../ui/utils/gen_utils";
import {useSelector} from "react-redux";
import Footer from "../template/Footer/Footer";
import {ContentWrap} from "../template/ContentParts";
import ss from './Notebooks.module.scss';

const NotebookStudentHomeWorkExecute = (props) => {
	const [nbData, setNbData] = useState(undefined);
    const [notebookInfoId, setNotebookInfoId] = useState(null);
	const [currPage, setCurrPage] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [taskPages, setTaskPages] = useState([]);
    const [workErrorLimit, setWorkErrorLimit] = useState(-1);
    const [isTeacherPreview, setIsTeacherPreview] = useState(false);
    const [isForceComplete, setIsForceComplete] = useState(false);
    
    const documentClass = useSelector(storageClass);

    //1 - get Notebook and Notebook Info data:
    useEffect(() => {
        const fetchData = async (ids) => {
            const [_nbId, _nbiId] = ids.split('|'); 

            //1 - nbData:
			const _nbData = await getNotebookById(_nbId);
            setNbData(_nbData);

            //2 - nbiData:
            let _nbiData;
            if (_nbiId) { //real test
			    _nbiData = await getNotebookInfoById(_nbiId);
            } else { //teacher preview
                setIsTeacherPreview(true);
                _nbData.settings.isGenerateUniqueTasks = true;
                _nbiData = prepareNbiData(_nbId, _nbData, null, documentClass._id, isAuth()._id);
            }
            setNotebookInfoId(_nbiId);
            setWorkErrorLimit(_nbiData.userWorkErrorLimit);
            
            //3 - set or find start time
            if (!_nbiData.startDate) {
                const start = new Date();
                await notebookInfoApi.updateNotebookInfoById(_nbiId, {startDate: start});
                setStartDate((new Date(start)).valueOf());
            } else {
                setStartDate((new Date(_nbiData.startDate)).valueOf());
            }

            //4 - prepare task pages
            const _taskPages = prepareTaskPages(_nbiData.tasks);
            setTaskPages(_taskPages);

            //5 - find curr page index
            const pageIndToAnswer = findNonAnsweredPage(_nbData, _taskPages);
            setCurrPage(pageIndToAnswer);
        };

        if (!props.ids) return;
        fetchData(props.ids);
    }, [documentClass._id, props.ids]);

    const prepareTaskPages = (tasks) => {
        const _taskPages = [];

        for (let i = 0; i < tasks.length; i++) {
            _taskPages.push({
                ind: i,
                task: tasks[i],
                reorderedOptions: shuffle(getTaskAnswerOptions(tasks[i])), //переупорядочить ответы в каждом вопросе
            });
        }

        return _taskPages;
    };

    const handleRefreshTaskData = () => { //учитель может обновлять задачи при печати
        const newTasks = getInitiatedTasks(nbData);
        const _taskPages = prepareTaskPages(newTasks);
        setTaskPages(_taskPages);
    };

    return (
            <>
                <ContentWrap>
                    <NotebookStudentHomeWorkExecutePages {...props} 
                        notebookData={nbData} 
                        notebookInfoId={notebookInfoId}
                        taskPages={taskPages}
                        setTaskPages={setTaskPages}
                        currPage={currPage}
                        setCurrPage={setCurrPage}
                        startDate={startDate}
                        workErrorLimit={workErrorLimit}
                        setWorkErrorLimit={setWorkErrorLimit}
                        history={props.history}
                        isTeacherPreview={isTeacherPreview}
                        handleRefreshTaskData={handleRefreshTaskData}
                        isForceComplete={isForceComplete}
                    />
                    <Footer {...props} opt={props.opt} />
                </ContentWrap>
       
                {currPage < taskPages.length &&
                <div className={ss.nb__aside}>
                    <NotebookStudentHomeWorkExecuteAside {...props} 
                        currPage={currPage}
                        taskPages={taskPages}
                        notebookData={nbData}
                        workErrorLimit={workErrorLimit}
                        history={props.history}
                        setIsForceComplete={setIsForceComplete}
                    />
                </div>
                }
            </>
    );
  
}

export default NotebookStudentHomeWorkExecute;
