import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {printOnlyDate} from "../../helpers/text";
import {isAuth} from "../../helpers/auth";
import ProfileVideoEditorDetailsDlg from "./ProfileVideoEditorDetailsDlg";
import socket from "../../socket";
import {DB_REFRESH} from "../ui/utils/gen_utils";
import Table from "../ui/Table/Table";
import {videoEditorApi} from "../../api/api";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";

const ProfileVideoEditorTable = ({profile, history}) => {
	const [videoEditorData, setVideoEditorData] = useState([]);
	const [showVideoEditorDlg, setShowVideoEditorDlg] = useState(false);
	const [selectedVEId, setSelectedVEId] = useState(undefined);
	const [counter, setCounter] = useState(0);
	const [isUpdated, setIsUpdated] = useState(false);
    const [showConfirmDlg, setShowConfirmDlg] = useState(false);
	const [idBeDeleted, setIdBeDeleted] = useState(undefined);

	const documentClass = useSelector(storageClass);

	useEffect(() => {
		socket.on("FE-refresh-db", ({type}) => {
			if (type === DB_REFRESH.VE) setCounter(counter => counter + 1);
		});
		return () => {socket.off("FE-refresh-db");};
	}, []);
	
    useEffect(() => {
		const handleServerData = (veData) => {
			return veData.map(item => ({
				expName: item.videoName,
				frameNumber: item.frames.length,
				date: printOnlyDate(item.updatedAt),
				actions: '',
				id: item._id,
			}));
		};

        const fetchData = async () => {
            const result = await videoEditorApi.getAllVideoEditorsByOwner(profile._id);
			setVideoEditorData(handleServerData(result.data));
        }

		fetchData();
		if (isUpdated) setIsUpdated(false);
    },[documentClass._id, profile._id, counter, isUpdated]);

	const handleOpenVEDetails = async (id) => {
		setShowVideoEditorDlg(true);
		setSelectedVEId(id);
	};

	const handleDelete = id => {
		setIdBeDeleted(id);
		setShowConfirmDlg(true);
	};
	const handleDeleteNo = () => {
		setShowConfirmDlg(false);
		setIdBeDeleted(undefined);
	};

	const handleDeleteYes = () => {
		setShowConfirmDlg(false);
		videoEditorApi.deleteVideoEditorById(idBeDeleted)
		.then(res => {
			setIdBeDeleted(undefined);
			setIsUpdated(true);
			socket.emit('BE-refresh-db', {type: DB_REFRESH.VE, roomId: documentClass._id});
	  	});
	};

	const getTableHeader = () => {
		if (isAuth().role === 0) {
			return [ 
				{column: 'Название эксперимента', name: 'expName', style: { width: '40%'} }, 
				{column: 'Количество кадров', name: 'frameNumber', style: { width: '30%'} }, 
				{column: 'Дата', name: 'date', style: { width: '30%'} }, 
				{column: 'id', name: 'id', style: { width: '0%'} }, 
			];
		} else {
			return [ 
				{column: 'Название эксперимента', name: 'expName', style: { width: '40%'} }, 
				{column: 'Количество кадров', name: 'frameNumber', style: { width: '25%'} }, 
				{column: 'Дата', name: 'date', style: { width: '25%'} }, 
				{column: 'Действия', name: 'actions', style: { width: '10%'} }, 
				{column: 'id', name: 'id', style: { width: '0%'} }, 
			];
		}
	};

    return ( 
		<div>
			<Table
				table={{
					header: getTableHeader(),
					data: videoEditorData, 
				}}
				person={{
					handlePerson: handleOpenVEDetails,
					isCellLink: true,
				}}
				sort={{
					hasSorting: true, 
					initSortInd: -3,
				}}
				actions={{
					handleDelete: id => handleDelete(id),
				}}
			/>
			
			{showVideoEditorDlg &&
			<ProfileVideoEditorDetailsDlg 
				showDlg={showVideoEditorDlg}
				setShowDlg={setShowVideoEditorDlg}
				selectedVEId={selectedVEId}
			/>
			}

			{showConfirmDlg &&
				<ModalConfirmDialog
				showConfirmDlg={showConfirmDlg} 
				handleNo={handleDeleteNo}
				handleYes={handleDeleteYes}
				question={'Вы действительно хотите удалить эти данные?'}
				/>
			}
		</div>
    )
}

export default ProfileVideoEditorTable;
