import React, {useState, useEffect} from "react";
import {InputLimit, Icon} from '../ui';
import {NAME_LIMIT, DESC_LIMIT, uploadRlsFile, deleteRlsFileByName, 
    getScUrlByName, getDescription} from './sc_utils';
import ImageDlg from "../ui/ModalDialogs/ImageDlg";
import {useDispatch} from "react-redux";
import {registerAddNewFile, registerDeleteFile, calcDecriptionSize} from "../ui/utils/gen_utils";
import {isAuth} from "../../helpers/auth";
import ss from './Scenarios.module.scss';

const ScenarioOneItem = ({itemIndex, isTemplate, scItem, 
        handleItemChange, handleDeleteItem, images, setImages}) => {
    const [loadedFileName, setLoadedFileName] = useState('');
    const [showPictureDlg, setShowPictureDlg] = useState(false);
    const [description, setDescription] = useState(scItem.description);
    const [descriptionCounter, setDescriptionCounter] = useState(0);
    const [attachmentUrl, setAttachmentUrl] = useState(undefined);
    
    const isDisabledTemplate = isTemplate && isAuth().role < 3;
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loadedFileName) return;

        if (!isTemplate) {
            const obj = {...scItem, attachmentName: loadedFileName};
            handleItemChange(itemIndex, obj);
            registerAddNewFile(loadedFileName, images, setImages);
            setLoadedFileName('');
        } else {
            const reader = new FileReader();
            reader.onload = (event) => {
                //DB will keep the file data: name and content
                const fileOpts = {name: loadedFileName.name, content: event.target.result};
                const obj = {...scItem, attachmentName: JSON.stringify(fileOpts)};

                handleItemChange(itemIndex, obj);
                setLoadedFileName('');
            };
            reader.readAsDataURL(loadedFileName); //if isTemplate the param contains the file (not just its name)
        }
    }, [handleItemChange, itemIndex, loadedFileName, scItem, images, setImages, isTemplate]);

    useEffect(() => {
        if(!scItem?.attachmentName) 
            setAttachmentUrl('');
        else 
            getScUrlByName(scItem.attachmentName, setAttachmentUrl);
    }, [scItem]);

    const handleName = v => {
        if (v.length > NAME_LIMIT) return;
        handleItemChange(itemIndex, {...scItem, name: v});
    };
    useEffect(() => {
        const obj = {...scItem, description: description};
        handleItemChange(itemIndex, obj);
    }, [description]); //don't add scItem

    const handleDescription = v => {
        const counter = calcDecriptionSize(v);
        //if (counter > DESC_LIMIT) return;
        setDescription(v);
        setDescriptionCounter(counter);
    };
    const handleAttachImage = (e) => {
        const file = e.target.files[0];
        if (!isTemplate)
            uploadRlsFile(file, setLoadedFileName); //keep file name 
        else
            setLoadedFileName(file); //keep file contents
        e.target.value = '';
    };

    const handleDeleteImage = () => {
        if (!isTemplate) {
            registerDeleteFile(scItem.attachmentName, images, setImages, dispatch, deleteRlsFileByName);
        }

        handleItemChange(itemIndex, {...scItem, attachmentName: ''});
    };

    const getName = () => {
        return (
            <>
                <div className="cor-net__label" key={'sind02'+itemIndex}>Название пункта</div>
                <InputLimit
                    disabled={isDisabledTemplate}
                    value={scItem.name} 
                    placeholder={'Введите название пункта'}  
                    onInput={e => handleName(e.target.value)} 
				/>
            </>
        );
    };

    const getAddImage = () => {
        if (isDisabledTemplate) return <></>;
        return (
            <>
                <div className="cor-net__label hide"></div>
                <label key={'lbl01'+itemIndex}
                    className={ss.img__add}>
                    <Icon name="clip" key='lbl02' />
                    <span>Добавить фото</span>
                    <input type="file" accept={"image/*"} key={'lbl03'+itemIndex} disabled={isDisabledTemplate}
                        onChange={(e) => handleAttachImage(e)} 
                    />
                </label>
            </>
        );
    };
    
    const getShowImage = () => {
        return (
            <div className={ss.img + " test"} key={'sind10'+itemIndex}>
                {scItem.attachmentName && 
                <img 
                    onClick={() => setShowPictureDlg(true)}
                    src={!isTemplate ? 
                        attachmentUrl : JSON.parse(scItem.attachmentName).content
                    }
                    alt='' key={'sind11'+itemIndex}  
                />
                }
                {scItem.attachmentName && !isDisabledTemplate &&
                    <div 
                        className={ss.img__trash} 
                        key={'sind81'+itemIndex} 
                        disabled={isDisabledTemplate}
                        onClick={() => handleDeleteImage(itemIndex)}>
                            <Icon name={'trash'} key={'sind62'+itemIndex} />
                    </div>
                }
            </div>
        );
    };

    const getDesc = () => {
        return getDescription(descriptionCounter, DESC_LIMIT, 'Описание пункта', 
            description, handleDescription, isDisabledTemplate, itemIndex);
    };

    return (
            <div className={(!scItem || !scItem.attachmentName) ? "cor-net__section" : "cor-net__section flex" }>

                {!scItem || !scItem.attachmentName ? 
                    <>
                    <div className="cor-net__title" key={'sind31'+itemIndex}>Шаг {(itemIndex+1)}</div>
                    <div className={"cor-net__row"} key={'sind34'+itemIndex}>
                        <div className="cor-net__col col-2" key={'sind35'+itemIndex} disabled={isDisabledTemplate}>
                            {getName()}
                        </div>
                        <div className="cor-net__col col-2" key={'sind36'+itemIndex} disabled={isDisabledTemplate}>
                            {getAddImage()}
                        </div>
                    </div>
                    <div className={"cor-net__row"}  key={'sind37'+itemIndex} disabled={isDisabledTemplate}>
                        <div className="cor-net__col col-1" key={'sind38'+itemIndex}>
                            {getDesc()}
                        </div>
                    </div>
                    {!isDisabledTemplate &&
                    <div className={"cor-net__row"}  key={'sind39'+itemIndex}>
                        <div 
                            className={ss.delete} 
                            key={'sind52'+itemIndex}
                            disabled={isDisabledTemplate}
                            onClick={() => handleDeleteItem(itemIndex)}
                        >
                            Удалить шаг
                        </div>
                    </div>
                    }
                    </>
                :
                    <>
                    <div className={ss.leftContent} disabled={isDisabledTemplate}>
                        <div className="cor-net__title" key={'sind31'+itemIndex}>Шаг {(itemIndex+1)}</div>
                        <div className={"cor-net__row"} key={'sind34'+itemIndex}>
                            <div className="cor-net__col col-1" key={'sind35'+itemIndex}>
                                {getName()}
                            </div>
                        </div>
                        <div className={"cor-net__row"}  key={'sind37'+itemIndex}>
                            <div className="cor-net__col col-1" key={'sind38'+itemIndex}>
                                {getDesc()}
                            </div>
                        </div>
                        {!isDisabledTemplate &&
                        <div className="cor-net__row" key={'sind50'+itemIndex}>
                            <div 
                                className={ss.delete} 
                                key={'sind52'+itemIndex}
                                onClick={() => handleDeleteItem(itemIndex)}
                                disabled={isDisabledTemplate} 
                                >
                                Удалить шаг
                            </div>
                        </div>
                        }
                    </div>
                    {getShowImage()}
                    </>
                }

                {showPictureDlg &&
                <ImageDlg
    				showModal={showPictureDlg}
	    			setShowModal={setShowPictureDlg}
                    file={{Url: !isTemplate ? 
                        attachmentUrl : JSON.parse(scItem.attachmentName).content
                    }}
			    />
                }
            </div>
    );
}

export default ScenarioOneItem; 
