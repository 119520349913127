import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {isAuth} from "../../../helpers/auth";
import {fetchclass, fetchUsersByOrg, selectclass, selectusers} from "../../../redux/slices/classsline";
import {mapMembersDispatchToProps, mapMembersStateToProps} from "./redux";
import {printUserRoleGen, printUserRoleMul} from "../../../helpers/text";
import {MembersGrid} from "../../Grids";
import Footer from "../Footer";
import "./Dashboard.scss";
import {FEATURES} from "../../../api/config";
import {storageOrg} from "../../../redux/slices/storage";
import {Dropdown, FilterByText} from "../../ui";
import {Content, ContentBody, ContentHeader, ContentWrap} from "../ContentParts";
import {Button} from 'rlabui';
import DropdownItem from "../../ui/Dropdown/DropdownItem";

export const DashboardMembers = (props) => {
  const dispatch = useDispatch();
  const role = props.match.path === FEATURES.teachers.to ? 1 : 0;
  const documentOrg = useSelector(storageOrg);
  const [classs] = useSelector(selectclass);
  const [usersList] = useSelector(selectusers);
  const [search, setSearch] = useState('');
  const [searchClass, setSearchClass] = useState('');
  const [sorting, setSorting] = useState(0);

  useEffect(() => {
    dispatch(fetchclass(documentOrg._id));
    dispatch(fetchUsersByOrg(documentOrg._id))
  }, [dispatch, documentOrg._id]);

  const sortingLabels = ['По алфавиту (А-Я)', 'По алфавиту (Я-А)'];

  return (
    <ContentWrap>
      <Content>
        <ContentHeader>
          <div className="cor-net__row">
            <div className="cor-net__col col-8">
              <div className="cor_content__title">{printUserRoleMul(role)}</div>
            </div>

            <div className="cor-net__col col-6">
              <FilterByText
                placeholder="Введите имя"
                value={search}
                setSearch={setSearch}
              />
            </div>

            <div className="cor-net__label mb_0">Классы</div>
            <div className="cor-net__col col-8">
              <Dropdown value={classs.find(c => c._id === searchClass)?.className ?? "Все"}>
                <DropdownItem key={'all'} value={''}
                              onClick={() => setSearchClass('')}>Все</DropdownItem>
                {classs.map(cl => <DropdownItem key={cl._id}
                                                onClick={() => setSearchClass(cl._id)}
                                                value={cl._id}>{cl.className}</DropdownItem>)}
              </Dropdown>
            </div>
            <div className="cor-net__label mb_0">Сортировка</div>
            <div className="cor-net__col col-8">
              <Dropdown value={sortingLabels[sorting]}>
                {sortingLabels.map((s,i) => {
                  return <DropdownItem key={i} onClick={()=>setSorting(i)}>{s}</DropdownItem>
                })}
              </Dropdown>
            </div>
            <div className="cor-net__col col-grow"></div>
            <div className="cor-net__col">
              <Button
                border={true}
                size="small"
                onClick={() => props.showUserSettings({showModal: true, role, isAdd: true, user: {city: documentOrg.city}, owner: documentOrg._id})}
              >+ Добавить {printUserRoleGen(role).toLowerCase()}</Button>
            </div>
          </div>
        </ContentHeader>
        <ContentBody>
          <MembersGrid
            disabled={isAuth().role < 2 && role === 1}
            delete="true"
            usersList={usersList.filter(u => u.role === role &&
              ([u.name, u.name2, u.name3, u.login, u.email]).some(s => s?.toLowerCase().includes(search.toLowerCase())) &&
              (searchClass ? classs.some(c => c._id === searchClass && c.classUsers?.includes(u._id)) : true)
            ).sort((a, b) => ((b.status ?? 1) - (a.status ?? 1)) || ((sorting ? -1 : 1) *
              (a.name.localeCompare(b.name) || a.name2.localeCompare(b.name2) || a.name3.localeCompare(b.name3))))}/>
        </ContentBody>
      </Content>
      <Footer {...props}/>
    </ContentWrap>
  );
};

export default connect(mapMembersStateToProps, mapMembersDispatchToProps)(DashboardMembers)