import {NB_SAMPLE_TYPE, NB_TEMPLATE_TYPE, updateWords, getChangedWords} from './nb_utils';
import {getHourTitle, getMinuteTitle, shuffle} from '../ui/utils/gen_utils';
import {SCORE_TYPE} from '../ui/utils/score_utils';
import {getTemplatesBySubjectId} from "./templates";
import ss from './Notebooks.module.scss';


const MAX_NOD_NOK_COUNTER = 200;
const INIT_CHAR_X = 'x';
const INIT_CHAR_Y = 'y';

export const getRandomCoeffs = (selectedTemplate, formulaInd, complexityInd, ind_shift = 0, initCoeffs = undefined, topCounter = 0) => { 
    let isDebug = false;
    if (isDebug) { 
        const topCoeffs = [2,48,2]; 
        const bottomCoeffs = [1,1,1]; 
        return [topCoeffs, bottomCoeffs];
    }

    const formula = selectedTemplate.formulas[formulaInd]; 
    const sampleTypeId = selectedTemplate.sampleTypeId;

    if (sampleTypeId === NB_SAMPLE_TYPE.NOD) {//исключение: только для NOD:
        return getNodCoeffs(selectedTemplate, formula, complexityInd, ind_shift, initCoeffs, topCounter);
    } else if (sampleTypeId === NB_SAMPLE_TYPE.NOK) {//исключение: только для NOK:
        return getNokCoeffs(selectedTemplate, formula, complexityInd, ind_shift, initCoeffs, topCounter);
    } else if (!!formula.uniqueCoefSets) { //наборы коэф-тов уникальные для каждой задачи
        return getCoeffsByUniqueSets(formula, complexityInd, ind_shift, initCoeffs);
    } else { //наборы коэф-тов общие для всех задач
        return getCoeffsByRanges(selectedTemplate, formula, complexityInd);
    }
};

const getCoeffsByUniqueSets = (formula, complexityInd, ind_shift, initCoeffs) => {
    const coefSets = formula.uniqueCoefSets.find(item => item.id === complexityInd).set;

    let coefSet;
    if (!initCoeffs) {
        const randomInd = randomIntFromRange([0, coefSets.length - 1]);
        coefSet = coefSets[randomInd];
    } else { //найдем начальный элемент и найдем новый индекс
        const initStr = [initCoeffs[0].join('/'), initCoeffs[1].join('/')].join('|');
        let initInd;
        for (let i = 0; i < coefSets.length; i ++)
            if (coefSets[i] === initStr)
                initInd = i;
        let foundInd = initInd + ind_shift;
        const csl = coefSets.length;
        if (foundInd >= csl) {
            foundInd -= Math.floor(foundInd / csl) * csl;
        }

        coefSet = coefSets[foundInd];
    }

    const cs = coefSet.split('|'); //JUST FO TESTING. BE REMOVED. ===================================
    for (let i = 0; i < cs.length; i++) 
        if (!cs[i]) {
            console.log('coefSet=', coefSet);
            debugger;
        }

    let [topValues, bottomValues] = coefSet.split('|').map(item => item.split('/'));

    topValues = topValues.map(item => Number(item));
    bottomValues = bottomValues.map(item => Number(item));
    return [topValues, bottomValues];
};

const getCoeffsByRanges = (selectedTemplate, formula, complexityInd) => {
    const coefNumber = selectedTemplate.coefNumber; //кол-во требуемых коэф-тов a0/b0, a1/b1,...
    const isAnySign = selectedTemplate.isAnySign; //true is sign can be +/-, false is only +
    const isDecimal = selectedTemplate.decimal !== undefined;
    let topCoeffs = []; //[a0, a1, ...]
    let bottomCoeffs = []; // [b0, b1, ...]
    const exceptions = formula.exceptions;
    const coefMultipliers = formula.coefMultipliers;

    let ind = 0;
    let isUniqueCoef = false;

    const coefRanges = 
        !!formula.uniqueCoefRanges ? formula.uniqueCoefRanges[ind][complexityInd] : //коэф-ты уникальные для каждого параметра @
        !!formula.coefRanges ? formula.coefRanges[complexityInd] : //общие коэф-ты уникальные для каждой подзадачи
        selectedTemplate.coefRanges[complexityInd]; //общие коэф-ты для всех подзадач (используется для уравнений)

    while(ind < coefNumber) {
        const topRange = coefRanges.topRange;
        const bottomRange = coefRanges.bottomRange;

        let topValue = findCoef(topRange, isAnySign);

        let bottomValue = findCoef(bottomRange, isAnySign, isDecimal, coefRanges.decimalNumber);
        let minTopValue, minBottomValue;

        [topValue, bottomValue] = changeSigns(topValue, bottomValue);
        [minTopValue, minBottomValue] = minimizeRatio(isDecimal, topValue, bottomValue);
        isUniqueCoef = minBottomValue >= bottomRange[0] && isCoefUnique(topCoeffs, bottomCoeffs, minTopValue, minBottomValue);

        if (isUniqueCoef && !!exceptions) {
            isUniqueCoef = isSatisfyExceptions(exceptions, ind, topCoeffs, bottomCoeffs, minTopValue, minBottomValue);
        }

        if (!!coefMultipliers)
            minTopValue *= coefMultipliers[ind];

        if (isUniqueCoef) {
            topCoeffs.push(minTopValue);
            bottomCoeffs.push(minBottomValue);
            ind ++;
            isUniqueCoef = false;
        } else { 
            topCoeffs = [];
            bottomCoeffs = [];
            ind = 0;
        }
    }

    return [topCoeffs, bottomCoeffs];
};

const getNodCoeffs = (selectedTemplate, formula, complexityInd, ind_shift = 0, initCoeffs = undefined, topCounter = 0) => {
    let isUniqueCoef = false;

    const coefRanges = formula.coefRanges[complexityInd];
    const topRange = coefRanges.topRange;
    let minValue1 = 1;
    let minValue2 = 1;

    //1 - ищем случайные коэффициенты (нужно только для правильного решения)
    while(!isUniqueCoef && ind_shift === 0) { //находим значения только для решения, иначе ставим 1
        const value1 = findCoef(topRange, false);
        const value2 = findCoef(topRange, false);

        [minValue1, minValue2] = minimizeRatio(false, value1, value2);
        if (minValue1 !== minValue2 && minValue1 !== 1 && minValue2 !== 1) {
            isUniqueCoef = true;
        }
    }

    const multiplierNum = coefRanges.multiplierNum;
    const bottomRange = coefRanges.bottomRange;

    const foundNod = !initCoeffs ? undefined : initCoeffs[1][0]; //it isthe saved answer if ind_shift > 0
    const [foundValue1, foundValue2] = !initCoeffs ? [undefined, undefined] : initCoeffs[0];
    let nod;
    isUniqueCoef = false;
    let innerCounter = 0;

    //2 - ищем НОД:
    while (!isUniqueCoef) {
        nod = 1;
        let tmpArr = [];
        for (let i = 0; i < multiplierNum; i ++) {
            const range = [0, bottomRange.length-1];
            const ind = findCoef(range, false);
            let value = bottomRange[ind];

            if (!foundNod || 
                innerCounter > MAX_NOD_NOK_COUNTER || topCounter > MAX_NOD_NOK_COUNTER || 
                (nod * value < foundValue1 && nod * value < foundValue2)) { //делитель не может превышать foundValue1 и foundValue2
                nod *= value;
                tmpArr.push(value);
            }
            innerCounter ++;
        }

        //3 - ищем НОД, имитирующий настоящий:
        if (nod !== foundNod && nod !== 1) {
            if (!foundNod) { //ищем правильное значение - подходит любое значение
                isUniqueCoef = true;
            } else if (innerCounter > MAX_NOD_NOK_COUNTER || topCounter > MAX_NOD_NOK_COUNTER) { //делаем MAX_NOD_NOK_COUNTER попыток найти неправильное решение, которое напоминает правильное: одна из 2-х величин должна быть делима на найденное число
                isUniqueCoef = true;
            } else {
                //проверяем, можно ли делить НОД на одну из этих величин:
                const canBeDeleted = isDividable(nod, foundValue1) || isDividable(nod, foundValue2); 
                //проверяем - если обе величины нечетные, то и НОД должен быть нечетным:
                const isOddChecked = !isOddValue(foundValue1) || !isOddValue(foundValue2) || isOddValue(nod);
                if (canBeDeleted && isOddChecked)
                    isUniqueCoef = true;
            }
            innerCounter ++;
        }
    }

    let topCoeffs = [minValue1, minValue2];
    let bottomCoeffs = [nod];
    return [topCoeffs, bottomCoeffs];
};

const getNokCoeffs = (selectedTemplate, formula, complexityInd, ind_shift = 0, initCoeffs = undefined, topCounter = 0) => {
    const coefRanges = formula.coefRanges[complexityInd];
    const topRange = coefRanges.topRange;
    let nok;

    //1 - ищем коэффициенты и НОК для правильного решения
    while(ind_shift === 0) {
        const value1 = findCoef(topRange, false);
        const value2 = findCoef(topRange, false);

        const valueArr1 = getPrimeArray(value1);
        let valueArr2 = getPrimeArray(value2);

        for (let i = 0; i < valueArr1.length; i ++) {
            const val = valueArr1[i];
            const foundIndex = valueArr2.findIndex(item => item === val);
            if (foundIndex !== -1)
                valueArr2 = valueArr2.filter((item, ind) => ind !== foundIndex);
        }
        nok = value1;
        for (let i = 0; i < valueArr2.length; i ++) 
            nok *= valueArr2[i];

        if (value1 !== value2 && value1 !== 1 && value2 !== 1) {
            return [[value1, value2], [nok]];
        }
    }

    //2 - ищем НОК, имитирующий правильный:
    const bottomRange = coefRanges.bottomRange;
    let isUniqueCoef = false;
    let innerCounter = 0;
    const initValue1 = initCoeffs[0][0];
    const initValue2 = initCoeffs[0][1];
    const initNok = initCoeffs[1][0];

    while (!isUniqueCoef) {
        nok = initNok;

        const actor = findCoef(bottomRange, false);
        const action = findCoef([1, 4], false);

        //различные способы получить значение, имитирующее правильный НОК:
        if (action === 1 || innerCounter > MAX_NOD_NOK_COUNTER || topCounter > MAX_NOD_NOK_COUNTER) {
            const actor2 = findCoef(bottomRange, false);
            nok = Math.floor(nok * actor / actor2);
            if (nok > initValue1 && nok > initValue2)
                isUniqueCoef = true;
        } else if (action === 2 && isDividable(nok, actor)) {
            nok /= actor;
            if (nok > initValue1 && nok > initValue2)
                isUniqueCoef = true;
        } else if (action === 3) {
            nok = nok + (findCoef([0, 1], false) === 1 ? 2 : -2);
            isUniqueCoef = true;
        } else if (action === 4) {
            nok = nok + (!isOddValue(nok) ? 2 : -2);
            isUniqueCoef = true;
        }

        if ((isOddValue(initNok) && !isOddValue(nok)) || (!isOddValue(initNok) && isOddValue(nok)))
            nok ++;

        innerCounter ++;
    }

    return [[1, 1], [nok]];
};

export const getTaskSample = (selectedTemplate, formulaInd, coeffs, isReplaceVars) => {
    let form = selectedTemplate.formulas[formulaInd].formula;
    const calcCoeffRules = selectedTemplate.calcCoeffRules;
    const sampleTypeId = selectedTemplate.sampleTypeId;

    if (sampleTypeId === NB_SAMPLE_TYPE.EQUATION) { 
        for (let ind = 0; ind < calcCoeffRules.length; ind ++) { 
            const currCoeffRule = calcCoeffRules[ind];
            const value = doCalcCoeffsByRule(currCoeffRule, coeffs); //calculate values based on coeffs and rules
            form = replaceFormSymbolsWithValues(selectedTemplate, form, ind, value, isReplaceVars);
        }
    } else if (sampleTypeId === NB_SAMPLE_TYPE.TEXT_TASK ||
               sampleTypeId === NB_SAMPLE_TYPE.CALC_PHYSICS_TASK) {
        const topCoeffs = coeffs[0];
        const bottomCoeffs = coeffs[1];
        for (let ind = 0; ind < topCoeffs.length; ind ++) { 
            const value = '#' + Math.abs(topCoeffs[ind]) + '/' + bottomCoeffs[ind] + '#';
            form = replaceFormSymbolsWithValues(selectedTemplate, form, ind, value, isReplaceVars);
        }
    } else if (sampleTypeId === NB_SAMPLE_TYPE.CALC_NUMBERS) {
        const topCoeffs = coeffs[0];
        const bottomCoeffs = coeffs[1];
        for (let ind = 0; ind < topCoeffs.length; ind ++) { 
            const value = '#' + topCoeffs[ind] + '/' + bottomCoeffs[ind] + '#';
            form = replaceFormSymbolsWithValues(selectedTemplate, form, ind, value, isReplaceVars);
        }
    } else if (sampleTypeId === NB_SAMPLE_TYPE.NOD) {
        const topCoeffs = coeffs[0];
        const bottompCoeffs = coeffs[1];
        for (let ind = 0; ind < topCoeffs.length; ind ++) { 
            const value = topCoeffs[ind] * bottompCoeffs[0];
            form = replaceFormSymbolsWithValues(selectedTemplate, form, ind, value, isReplaceVars);
        }
    } else if (sampleTypeId === NB_SAMPLE_TYPE.NOK) {
        const topCoeffs = coeffs[0];
        for (let ind = 0; ind < topCoeffs.length; ind ++) { 
            const value = topCoeffs[ind];
            form = replaceFormSymbolsWithValues(selectedTemplate, form, ind, value, isReplaceVars);
        }
    } else if (sampleTypeId === NB_SAMPLE_TYPE.GROUPING) {
        for (let ind = 0; ind < calcCoeffRules.length; ind ++) { 
            const currCoeffRule = calcCoeffRules[ind];
            const value = doCalcCoeffsByRule(currCoeffRule, coeffs); //calculate values based on coeffs and rules
            form = replaceFormSymbolsWithValues(selectedTemplate, form, ind, value, isReplaceVars);
        }
    // } else if (sampleTypeId === NB_SAMPLE_TYPE.CALC_PHYSICS_TASK) { //нам не надо показывать формулу
    //     form = '';
    }
    //---------------------------
    else {
        form = 'selectedTemplate НЕ ОПРЕДЕЛЕН!!'    
    }

    //4. check workds to be updated:
    form = updateWords(form);
    return form;
};

const replaceFormSymbolsWithValues = (selectedTemplate, form, ind, value, isReplaceVars) => {
    const sampleTypeId = selectedTemplate.sampleTypeId;
    const templateTypeId = selectedTemplate.templateTypeId;
    const charSign = '$' + ind;
    const charIgnorableSign = '$-' + ind;
    const charValue = '@' + ind;
    const charMandatoryValue = '@+' + ind;
    const charX = INIT_CHAR_X + ind;
    const charY = INIT_CHAR_Y + ind;
    const vName0 = getVarNameByInd(selectedTemplate, 0);
    const vName1 = getVarNameByInd(selectedTemplate, 1);
    let val;

    if (sampleTypeId === NB_SAMPLE_TYPE.EQUATION || 
        sampleTypeId === NB_SAMPLE_TYPE.GROUPING) {
        if (value === 0) {
            form = form.replace(charSign, '');
            form = form.replace(charValue, '');
            form = form.replace(charMandatoryValue, '0');
            form = form.replace(charIgnorableSign, '');
            form = form.replaceAll(charX, '');
            form = form.replaceAll(charY, '');
        } else {
            //1. set sign instead of $0, $1, ...: 
            const sign = value > 0 ? '+' : '-';
            form = form.replaceAll(charSign, sign);
            form = form.replace(charIgnorableSign, value > 0 ? '' : sign); //such a sign can be ignored if value > 0: like '$-3'
    
            //2. set value instead of @0, @1, ...: : 
            if (value === 1 || value === -1) {
                const shouldIgnore = isIgnoreValueOne(form, charValue, charMandatoryValue, vName0);
                val = shouldIgnore ? '' : '1'; // ind === calcCoeffRules.length-1? '1' : ''; //пропускаем коэф. 1, если он не последний
            } else if (value > 0) {
                val = value;
            } else { //value < 0
                val = -value;
            }
            val = val.toString();
        }
    } else if (sampleTypeId === NB_SAMPLE_TYPE.TEXT_TASK || 
               sampleTypeId === NB_SAMPLE_TYPE.CALC_PHYSICS_TASK ||
               sampleTypeId === NB_SAMPLE_TYPE.NOD || 
               sampleTypeId === NB_SAMPLE_TYPE.NOK) {
        val = value;
    } else if (sampleTypeId === NB_SAMPLE_TYPE.CALC_NUMBERS) {
        val = value;
        val = val.replace('-', '');

        const hasMinus = value.includes('-');
        let sign = !hasMinus ? '+' : '-';
        if (templateTypeId === NB_TEMPLATE_TYPE.MATH_NUMBER_MULTIPLY) sign = '*';
        if (templateTypeId === NB_TEMPLATE_TYPE.MATH_NUMBER_DIVIDE) sign = ':';

        form = form.replaceAll(charSign, sign);
        form = form.replace(charIgnorableSign, !hasMinus ? '' : sign);
    } else {
        val = 'НЕ ОПРЕДЕЛЕНО!';
    }

    form = form.replaceAll(charValue, val);
    form = form.replaceAll(charMandatoryValue, val);

    //3.1. set the selected char instead of x0, x1, .. 
    form = form.replaceAll(charX, isReplaceVars ? vName0 : INIT_CHAR_X); //if isReplaceVars === false, сохраняем переменную в формуле

    //3.2. set the selected char instead of y0, y1, ..
    form = form.replaceAll(charY, isReplaceVars ? vName1 : INIT_CHAR_Y);

    return form;
};

const getAnswerByParams = (calcFactorRule, coeffs) => {
    let topValue, bottomValue;
    const coeffInd0 = calcFactorRule.coeffIndexes[0];
    const coeffInd1 = calcFactorRule.coeffIndexes[1]; //In this case there are 2 indeces: coeffInd0 and coeffInd1

    //we suppose that we have 2 values a0/b0 and a1/b1 in the array link.params
    const a0 = coeffs[0][coeffInd0];
    const a1 = coeffs[0][coeffInd1];
    const b0 = coeffs[1][coeffInd0];
    const b1 = coeffs[1][coeffInd1];

    if (calcFactorRule.action === 'equal') {
        //we get topValue / bottomValue. In this case there is only 1 index: coeffInd0
        topValue = coeffs[0][coeffInd0];
        bottomValue = coeffs[1][coeffInd0];
    } else if (calcFactorRule.action === '+') { 
        //we get result: topValue / bottomValue = (a0 * b1 + b0 * a1) / (b0 * b1)

        topValue = a0 * b1 + a1 * b0;
        bottomValue = b0 * b1;
    } else if (calcFactorRule.action === '*') {
        //we get result: topValue / bottomValue = (a0 * a1) / (b0 * b1)

        topValue = a0 * a1;
        bottomValue = b0 * b1;
    } else if (calcFactorRule.action === ':') {
        //we get result: topValue / bottomValue = (a0 * b1) / (b0 * a1)

        topValue = a0 * b1;
        bottomValue = b0 * a1;
    }

    //console.log('topValue0=', topValue0, ' topValue1=', topValue1, ' bottomValue0=', bottomValue0, ' bottomValue1=', bottomValue1, ' topValue=', topValue, ' bottomValue=', bottomValue);
    [topValue, bottomValue] = changeSigns(topValue, bottomValue);
    return [topValue, bottomValue];
};

const getAnswerByValues = (calcFactorRule, coeffs) => {
    const factor = calcFactorRule.factor;
    const topValue = doCalcCoeffsByRule(factor.topValue, coeffs);
    const bottomValue = doCalcCoeffsByRule(factor.bottomValue, coeffs);
    return changeSigns(topValue, bottomValue);
};

export const getTaskAnswer = (selectedTemplate, formulaInd, coeffs, extraCN = '') => { //пример ответа
    //console.log('coeffs=', coeffs);
    const sampleTypeId = selectedTemplate.sampleTypeId;

    if (sampleTypeId === NB_SAMPLE_TYPE.NOD || sampleTypeId === NB_SAMPLE_TYPE.NOK) {
        //исключение: только для НОД и НОК:
        const answerValue = coeffs[1][0];
        const elem = drawAnswer(selectedTemplate, formulaInd, 1, 1, [answerValue, 1], '');
        return [elem];
    } else if (sampleTypeId === NB_SAMPLE_TYPE.GROUPING) {
        //исключение: только для метода группировки:
        const elem = drawAnswer(selectedTemplate, formulaInd, 1, 1, coeffs[0], '');
        return [elem];
    }

    const isDecimal = selectedTemplate.decimal !== undefined;
    const cfRules = getCfRules(selectedTemplate, formulaInd);
    const list = [];

    for (let i = 0; i < cfRules.length; i ++) {
        const cfRule = cfRules[i];
        const [answerTopValue, answerBottomValue] = getAnswerValuesByCfRule(cfRule, coeffs, isDecimal);
        const elem = drawAnswer(selectedTemplate, formulaInd, i + 1, cfRules.length, [answerTopValue, answerBottomValue], extraCN);
        list.push(elem);
    }

    return list;
};

const getCfRules = (selectedTemplate, formulaInd) => {
    const formula = selectedTemplate.formulas[formulaInd];
    const cfRules = !formula.calcSolutionRules ? selectedTemplate.calcSolutionRules : formula.calcSolutionRules;
    return cfRules;
};

const getAnswerValuesByCfRule = (cfRule, coeffs, isDecimal) => {
    let topValue, bottomValue;

    //currently there are 2 methods: 'params' (by default), 'values'
    const factorMethod = !cfRule.factorMethod ? 'params' : cfRule.factorMethod; 

    if (factorMethod === 'params') {
        [topValue, bottomValue] = getAnswerByParams(cfRule, coeffs);
    } else if (factorMethod === 'values') {
        [topValue, bottomValue] = getAnswerByValues(cfRule, coeffs);
    } 

    let [answerTopValue, answerBottomValue] = minimizeRatio(isDecimal, topValue, bottomValue);
    [answerTopValue, answerBottomValue] = changeSigns(answerTopValue, answerBottomValue);

    return [answerTopValue, answerBottomValue];
};

export const getTemplateWithVarNames = (templates, id) => {
    const _selectedTemplate = templates.find(item => item.templateId === id);
    if (!_selectedTemplate) return undefined;
    const varNameNumber = _selectedTemplate.varNameNumber;
    
    if (!varNameNumber) return _selectedTemplate;
    const varNames = getRandomVariableNames(_selectedTemplate.varNameNumber);
    return {..._selectedTemplate, varNames: varNames};
};

const NB_TEXT_TYPE = {
    ORDINARY: 1,
    SUP: 2,
    RATIO: 3,
}
const NB_SEPARATOR = {
    SUP: '^+',
    RATIO: '#',
}

export const drawFormula = (selectedTemplate, form) => { //вопрос в задаче
    if (!selectedTemplate) return [<div key='df0'></div>];

    const SEPARATORS = [NB_SEPARATOR.SUP, NB_SEPARATOR.RATIO];
    let inputPieces = [{text: form, type: NB_TEXT_TYPE.ORDINARY}];
    //разбиваем строку на кусочки сначала по одному сепаратору, в следующем цикле - получившиеся кусочки по другому сепаратору
    for (let i = 0; i < SEPARATORS.length; i ++) {
        let outputPieces = [];
        for (let j = 0; j < inputPieces.length; j ++) {
            const text = inputPieces[j].text;
            const textArr = text.split(SEPARATORS[i]);
            if (textArr.length === 1) {
                outputPieces.push(inputPieces[j]);
            } else {
                for (let k = 0; k < textArr.length; k ++) {
                    const type = k % 2 === 0 ? NB_TEXT_TYPE.ORDINARY : SEPARATORS[i] === NB_SEPARATOR.SUP ? NB_TEXT_TYPE.SUP : NB_TEXT_TYPE.RATIO
                    outputPieces.push({text: textArr[k], type: type});
                }
            }
        }
        inputPieces = outputPieces;  //содержит результат разбиения
    }

    const list = []; //каждый из кусочков будем показывать в соответствии с его типом
    for (let i = 0; i < inputPieces.length; i ++) {
        const piece = inputPieces[i];
        if (piece.type === NB_TEXT_TYPE.ORDINARY) {
            const vName0 = getVarNameByInd(selectedTemplate, 0);
            const vName1 = getVarNameByInd(selectedTemplate, 1);
            list.push(piece.text.replaceAll(INIT_CHAR_X, vName0).replaceAll(INIT_CHAR_Y, vName1));
        }
        else if (piece.type === NB_TEXT_TYPE.SUP) { //x**2 или x^2
            list.push(<sup key={'form1'+i} >{piece.text}</sup>);
        }
        else if (piece.type === NB_TEXT_TYPE.RATIO) { //рациональные 2 1/3   или десятичные 3.45
            const [topValue, bottomValue] = piece.text.split('/');
            //console.log('form=', form);
            //console.log('RATIO: piece.text=', piece.text, ' topValue=', topValue, ' bottomValue=', bottomValue);

            if (Number(bottomValue) === 1) {
                list.push(<span key={'form2'+i}>{topValue}</span>);
            } else {
                const [intPart, topPart, bottomPart] = getAnswerParts(topValue, bottomValue);
                //console.log('intPart=', intPart, ' topPart=', topPart, ' bottomPart=', bottomPart);

                if (selectedTemplate.decimal === undefined) {
                    if (intPart !== 0) list.push(<span key={'form3'+i}>{getIntegerValue(i, intPart)}</span>);
                    if (topPart !== 0 && bottomPart !== 1) list.push(<span key={'form4'+i}>{getRatioValue(i, topPart, bottomPart)}</span>);
                } else {
                    list.push(<span key={'form5'+i}>{getDecimalString(intPart, topPart, bottomPart)}</span>);
                }
            }            
        }
    }
    return list;
};

const drawAnswer = (selectedTemplate, formulaInd, index, indexNum, answerValues, extraCN) => {
    const getVariableName = (index, _vName) => <span key={'rn0'+index}>{_vName}</span>;
    const getAnswerName = (index, answerName) => <span key={'rn20'+index}>{answerName}</span>;
    const getFactorIndex = (index) => <sub key={'rn1'+index}>{index}</sub>;
    const getEqualChar = (index) => <span key={'rn2'+index}>{' = '}</span>;
    const getSignChar = (index, sign) => <span key={'rn3'+index}>{sign}</span>;
    const getDecimal = (index, decimal) => <span key={'rn4'+index}>{decimal}</span>;
    const getUnits = (index, topValue, bottomValue, units) => <span key={'rn5'+index}>{drawFormula(selectedTemplate, getChangedWords(units + ',' + (topValue / bottomValue)))}</span>;
    const getSpace = (index) => <span key={'rn6'+index}>{'    '}</span>;

    const sampleTypeId = selectedTemplate.sampleTypeId;
    const [answerTopValue, answerBottomValue] = answerValues;
    const vName = getVarNameByInd(selectedTemplate, 0);
    const isDecimal = selectedTemplate.decimal !== undefined;
    const formula = selectedTemplate.formulas[formulaInd];
    const units = formula.units; 
    const unitException = units === 'час';
    const answerName = formula.answerName;
    const sign = answerTopValue >= 0 ? '' : '-';
    const [intPart, topPart, bottomPart] = getAnswerParts(answerTopValue, answerBottomValue);
    
    const showDecimalNumber = selectedTemplate.showDecimalNumber;
    const isOnlyInteger = sampleTypeId !== NB_SAMPLE_TYPE.GROUPING && intPart !== 0 && !isDecimal && !showDecimalNumber;
    const isRatio = sampleTypeId !== NB_SAMPLE_TYPE.GROUPING && topPart !== 0 && bottomPart !== 1 && !isDecimal && !showDecimalNumber;

    const res = <span className={ss.nb__showRootBox + ' ' + extraCN} key={'sprt0'+index}>
        {sampleTypeId === NB_SAMPLE_TYPE.EQUATION && getVariableName(index, vName)}
        {sampleTypeId === NB_SAMPLE_TYPE.EQUATION && indexNum !== 1 && getFactorIndex(index)}
        {sampleTypeId === NB_SAMPLE_TYPE.EQUATION && getEqualChar(index)}
        {(sampleTypeId === NB_SAMPLE_TYPE.TEXT_TASK || sampleTypeId === NB_SAMPLE_TYPE.CALC_PHYSICS_TASK) && 
            getAnswerName(index, answerName)}
        {sampleTypeId !== NB_SAMPLE_TYPE.GROUPING && getSignChar(index, sign)}
        {sampleTypeId === NB_SAMPLE_TYPE.GROUPING && getGroupingFormula(selectedTemplate, answerValues)}

        {isOnlyInteger && !unitException && getIntegerValue(index, intPart)}
        {isRatio && !unitException && getRatioValue(index, topPart, bottomPart)}
        {isDecimal && getDecimal(index, getDecimalString(intPart, topPart, bottomPart))}
        {showDecimalNumber && getDecimal(index, getTransformedToDecimal(intPart, topPart, bottomPart, showDecimalNumber))}

        {unitException && getHourValue(index, intPart, topPart, bottomPart)}
        {!!units && !unitException && getUnits(index, answerTopValue, answerBottomValue, units)} 
        {getSpace(index)}
    </span>; 

    //КОРЕНЬ - !!!
    //<span className="squareRoot">5211</span><span className="squareRoot">xxx</span>
    //USE sampleTypeId === NB_SAMPLE_TYPE.CALC_NUMBERS && ...
    //console.log('intPart=', intPart, ' topPart=', topPart, ' bottomPart=', bottomPart);
    return res;
};

const getGroupingFormula = (selectedTemplate, answerValues) => {
    let form = selectedTemplate.calcSolutionRules[0].solutionFormula;
    for (let ind = 0; ind < answerValues.length; ind ++)  
        form = replaceFormSymbolsWithValues(selectedTemplate, form, ind, answerValues[ind], true);
    return <span key={'gf0'}>{form}</span>;
};

const getHourValue = (index, hours, topPart, bottomPart) => {
    const hourUnits = getHourTitle(hours);
    const minutes = Math.floor(topPart * 60 / bottomPart);
    const minuteUnits = getMinuteTitle(minutes);
    let str = '' + hours + ' ' + hourUnits;
    if (topPart !== 0)
        str += ' ' + minutes + ' ' + minuteUnits;
    return <span key={'rn4'+index}>{str}</span>;
};

const getIntegerValue = (index, _intPart) => <span key={'rn4'+index}>{Math.abs(_intPart)}</span>;

const getDecimalString = (intPart, topPart, bottomPart) => {
    let decimalString = '' + Math.abs(intPart);
    if (Math.abs(Number(topPart)) !== 0 && Number(bottomPart) !== 1) {
        const decDigits = Number(bottomPart) === 10 ? 1 : Number(bottomPart) === 100 ? 2 : Number(bottomPart) === 1000 ? 3 : 4;
        const sTopPart = '00' + Math.abs(topPart);
        decimalString = decimalString + '.' + sTopPart.substring(sTopPart.length - decDigits);
    }
    return decimalString;
};

const getTransformedToDecimal = (intPart, topPart, bottomPart, showDecimalNumber) => {
    let decimalString = '' + Math.abs(intPart);
    let tp = Math.abs(Number(topPart));
    const bt = Number(bottomPart);
    if (tp !== 0 && bt !== 0) {
        for (let i = 0; i < showDecimalNumber; i ++) tp *= 10;
        const ratio = Math.round(tp / bt, showDecimalNumber);
        decimalString = decimalString + '.' + ratio;
    }
    return decimalString;
};

const getVarNameByInd = (selTemplate, ind) => {
    return selTemplate.varNames && selTemplate.varNames.length > ind ? selTemplate.varNames[ind] : '';
};

const getAnswerParts = (topValue, bottomValue) => {
    let intPart = 0, topPart = topValue, bottomPart = bottomValue;
    if (Math.abs(topValue) >= bottomValue) {
        intPart = Math.floor(Math.abs(topValue) / bottomValue);
        topPart = (Math.abs(topValue) - intPart * bottomValue) * (topValue > 0 ? +1 : -1);
    } else if (bottomValue === 1) {
        intPart = topValue;
    }
    return [intPart, topPart, bottomPart];
};

const getRatioValue = (index, topValue, bottomValue) => {
    return (
        <span key={'rn15'+index}>
            <sup key={'rn16'+index}>{Math.abs(topValue)}</sup>&frasl;<sub key={'rn18'+index}>{bottomValue}</sub>
        </span>
    );
};

const isSavedSolutionUnique = (selectedTemplate, cfRules, uniqueCoeffs, coeffs) => {
    if (selectedTemplate.sampleTypeId === NB_SAMPLE_TYPE.NOD || 
       selectedTemplate.sampleTypeId === NB_SAMPLE_TYPE.NOK ||
       selectedTemplate.sampleTypeId === NB_SAMPLE_TYPE.GROUPING)
        return true; //особый случай - в этой функции НОД и НОК не проверяем

    if (uniqueCoeffs.length === 0)
        for (let i = 0; i < cfRules.length; i ++) uniqueCoeffs.push([]); //создвем пустые подмассивы для заполнения

    for (let i = 0; i < cfRules.length; i ++) {
        //находим результат по заданным коэффициентам
        const [answerTopValue, answerBottomValue] = getAnswerValuesByCfRule(cfRules[i], coeffs, selectedTemplate.decimal !== undefined);
        //проверяем, уникальный ли он
        const str = '' + answerTopValue + '|' + answerBottomValue;
        const duplicated = uniqueCoeffs[i].filter(item => item === str);
        //если неуникальный, то заканчиваем
        if (duplicated.length > 0) {
            return false;
        }
        uniqueCoeffs[i].push(str);
    }
    //все введенные коэффицуиенты дают уникальные ответы
    return true;
};

//подготовить ответы - 0й правильный и остальные неправильные
export const getInitiatedTasks = (nbData) => {
    const getFormulaInd = (selectedTemplate, taskInd, _formulaInd) => {
        if (taskInd === 0) { 
            //если taskInd = 0, берем случайный номер задачи
            return randomIntFromRange([0, selectedTemplate.formulas.length - 1]);
        } else { 
            //для последующих номер задачи увеличиваем на 1.
            let formulaInd = _formulaInd + 1;
            if (formulaInd >= selectedTemplate.formulas.length)
                formulaInd = 0;
            return formulaInd;
        }
    };

    //получаем все шаблоны для данного предмета 
    const templates = getTemplatesBySubjectId(nbData.titles.subjectId).templates;
    const preparedTaskList = [];

    //добавляем требуемые задачи по всем выбранным типам taskSamples:
    for (let i = 0; i < nbData.taskSamples.length; i ++) {
        //находим текущий образец (sample)
        const sample = nbData.taskSamples[i]; 
        //находим соответствующий этому образцу шаблон
        const selectedTemplate = templates.find(item => item.templateId === sample.templateId);
        let formulaInd; //не перемещять ниже, поскольку мы должны помнить текущее значение

        //добавляем groupAmount задач заданного типа sample:
        for (let taskInd = 0; taskInd < sample.groupAmount; taskInd ++) { //цикл по примерам данного типа sample
            //найдем порядковый номер используемой формулы (для taskInd = 0 будет случайный номер, затем увеличиваем на 1)
            formulaInd = getFormulaInd(selectedTemplate, taskInd, formulaInd);
            const correctCoeffs = getRandomCoeffs(selectedTemplate, formulaInd, sample.complexity);
            const formula = getTaskSample(selectedTemplate, formulaInd, correctCoeffs, false);
            const coeffList = getAllTaskCoeffs(selectedTemplate, formulaInd, correctCoeffs, sample);

            const curTask = {
                sampleId: sample._id, //идентификатор примера - см. _id из NotebookTaskSampleSchema
                taskInd: taskInd, //порядковый номер задачи в задании
                answerType: sample.answerType, //вид ответа - 0 - выбор из списка, 1 - ввод вручную
                answerVariants: transformArrayToString(coeffList, 2), //строка содержит массив вариантов (|), где каждый вариант содержит массив пар a/b (/), разделитель пар (%)
                formulaInd: formulaInd,
                formula: formula, //уравнение/формула/задача c параметрами
                userTaskErrorLimit: nbData.settings.isErrorNumberLimit ? 
                    nbData.settings.taskErrorLimit : -1, //if notebook setting isErrorNumberLimit is ON, current num,er of available attempts
                userAnswerHistory: '', //answer. if notebook setting isErrorNumberLimit is ON, the list of answer indeces
                isCorrect: SCORE_TYPE.UNDEFINED, // -1 - not defined, 1 - correct, 0 - incorrect 
            }

            preparedTaskList.push(curTask);
        }
    }
    
    return preparedTaskList;
};

export const prepareNbiData = (_homeWorkId, homeWorkData, commonTasks, documentClassId, owner) => {
    const getReorderedTasks = (tasks) => {
        let orders = [];
        for (let i = 0; i < tasks.length; i ++) orders.push(i);
        orders = shuffle(orders);

        const reorderedTasks = [];
        for (let i = 0; i < orders.length; i ++) 
            reorderedTasks.push(tasks[orders[i]]);
        return reorderedTasks;
    };

    let tasks = homeWorkData.settings.isGenerateUniqueTasks ? getInitiatedTasks(homeWorkData) : commonTasks;
    if (homeWorkData.settings.isRandomOrder)
        tasks = getReorderedTasks(tasks);

    const notebookInfoData = {
        owner: owner,
        room: documentClassId,
        notebook: _homeWorkId,
        tasks: tasks,
        taskReordering: '',
        startDate: null,
        userWorkErrorLimit: homeWorkData.settings.isErrorNumberLimit ? homeWorkData.settings.workErrorLimit : -1,
        score: undefined,
        isComplete: false,
    };
    return notebookInfoData;
};


const getAllTaskCoeffs = (selectedTemplate, formulaInd, correctCoeffs, sample) => {
    const isFoundDuplicate = (selectedTemplate, coeffList, incorrectCoeffs) => {
        const strIncorrectCoeffs = incorrectCoeffs.join('|');
        const coeffs = [...coeffList];
        if (selectedTemplate.sampleTypeId === NB_SAMPLE_TYPE.NOD || selectedTemplate.sampleTypeId === NB_SAMPLE_TYPE.NOK) {
            const first = coeffList[0];
            coeffs.push([[1,1], [ first[1][0] ]]);
        }
        const compare = coeffs.find(item => item.join('|') === strIncorrectCoeffs);
        return compare !== undefined;
    };
    const WRONG_ANSWERS = 4;

    //1й ответ всегда правильный, затем добавляем неправильные ответы
    const coeffList = []; //будет хранить коэффициенты для всех решенний
    //const correctCoeffs = getRandomCoeffs(selectedTemplate, formulaInd, sample.complexity);
    coeffList.push(correctCoeffs);

    const cfRules = getCfRules(selectedTemplate, formulaInd);
    const uniqueCoeffs = [];
    isSavedSolutionUnique(selectedTemplate, cfRules, uniqueCoeffs, correctCoeffs); //первый раз просто сохраняем правильное решение ]

    //the next answers are wrong and added to give chance to find the correct answer.
    for (let j = 0; j < WRONG_ANSWERS; j ++) {
        let isUniqueCoeffs = false;
        let incorrectCoeffs;
        let cnt = 0;

        while(!isUniqueCoeffs) {
            //найдем j-ые неправльные коэффициенты
            incorrectCoeffs = getRandomCoeffs(selectedTemplate, formulaInd, sample.complexity, j+1+cnt, correctCoeffs, cnt);
            // eslint-disable-next-line no-loop-func

            //1. проверим, что коэффициенты уникальны
            isUniqueCoeffs = !isFoundDuplicate(selectedTemplate, coeffList, incorrectCoeffs);

            //2. теперь проверим, что получаемое решение уникально (проверяем, только если коэффициенты не из предопределенных списков)
            //перестаем подбирать, если уже сделали MAX_NOD_NOK_COUNTER-попыток
            if (isUniqueCoeffs) {
                isUniqueCoeffs = isSavedSolutionUnique(selectedTemplate, cfRules, uniqueCoeffs, incorrectCoeffs);
            }

            if (cnt > MAX_NOD_NOK_COUNTER) {
                isUniqueCoeffs = true; //пора заканчивать. возьмем найдненные коэффициенты, хоть они и не удовлетворяют условиям, лучше все равно не нйдем.
                console.log('cnt > MAX_NOD_NOK_COUNTER'); //надо будет найти причину, почему подходящие коэффициенты не найдены
                console.log('coeffList=', coeffList, ' incorrectCoeffs=', incorrectCoeffs);
                debugger; //remove later
            }
            cnt ++;
        }

        coeffList.push(incorrectCoeffs);
    }

    return coeffList;
};

const isSatisfyExceptions = (exceptions, ind, topCoeffs, bottomCoeffs, minTopValue, minBottomValue) => {
    const _topCoeffs = topCoeffs.map(item => item);
    const _bottomCoeffs = bottomCoeffs.map(item => item);
    _topCoeffs.push(minTopValue);
    _bottomCoeffs.push(minBottomValue);

    for (let i = 0; i < exceptions.length; i ++) {
        const except = exceptions[i];
        const exceptType = except.exceptType; 
        const ind0 = except.ind0;
        const ind1 = except.ind1;
        
        if (exceptType === '>') {
            if (ind0 > _topCoeffs.length - 1 || ind1 > _topCoeffs.length - 1) 
                return true; //too early to check the exception

            if (_topCoeffs[ind0] / _bottomCoeffs[ind0] <= _topCoeffs[ind1] / _bottomCoeffs[ind1]) {
                return false;
            }
        }
    }
    return true;
};

const isOddValue = value => Math.floor((value - 1) / 2) * 2 === value - 1;
const isDividable = (value, divider) => Math.floor(value / divider) * divider === value;

export const randomIntFromRange = (range) => Math.floor(Math.random() * (range[1] - range[0] + 1) + range[0]); // min and max included 
const randomSign = (canChangeSign) => canChangeSign ? (Math.floor(Math.random() * 2) === 1 ? +1 : - 1) : + 1; 
const randomIndexFromList = (list) => randomIntFromRange([0, list.length - 1]);

export const getRandomVariableNames = size => {
    const names = [];
    const chars = 'abcdghkmnpqrstuvwxyz'.split('');
    const sz = !size ? 1 : size;
    for (let i = 0; i < sz; i ++) 
        names.push(chars[randomIndexFromList(chars)]);
    return names;    
};

const findCoef = (range, canChangeSign, isDecimal = false, decimalNumber = 0) => { 
    //for a/b: rangeInd = 0 is index for a, rangeInd = 1 is index for b
    if (!isDecimal)
        return randomIntFromRange(range) * randomSign(canChangeSign);

    let val = 1;
    for (let i = 0; i < randomIntFromRange([1, decimalNumber]); i ++) val *= 10; //calc: 10, 100, 1000, ..
    return val;
};

const getPrimeArray = _value => {
    let value = _value;
    const list = [];
    let devider = 2;

    while (value !== 1) {
        if (isDividable(value, devider)) {
            value /= devider;
            list.push(devider);
        } else {
            devider ++;
        }
    }
    return list;
};

export const getActualSelectedIndex = (_reorderedOptions) => {
    const reorderedOptions =  [..._reorderedOptions];
    const orderedOptions = reorderedOptions.sort((a,b) => a.index - b.index);
    const selectedOption = orderedOptions.find(item => item.isSelected);
    return selectedOption.index;
};


export const getLastAnswerIndex = answerHistory => {
    if (answerHistory === '')
        return -1;
    const list = answerHistory.split('|');
    const lastIndex =  Number(list[list.length-1]);
    return lastIndex;
};

export const getTaskAnswerOptions = task => {
    const answerOpts = transformStringToArray(task.answerVariants);

    let options = [];
    for (let j = 0; j < answerOpts.length; j ++) {
        const opt = {
            option: answerOpts[j],
            index: j, 
            isSelected: j === getLastAnswerIndex(task.userAnswerHistory), //task.userAnswerInd,
        };
        options.push(opt);
    }
    return options;
};

const minimizeRatio = (isDecimal, _topValue, _bottomValue) => {
    let topValue = _topValue;
    let bottomValue = _bottomValue;

    if (isDecimal) {
        let completed = false;
        while (!completed) {
            if (Math.floor(topValue / 10) * 10 === topValue && Math.floor(bottomValue / 10) * 10 === bottomValue) {
                topValue = topValue / 10;
                bottomValue = bottomValue / 10;
            }
            else completed = true;
        }
        return [topValue, bottomValue];
    }

    const minDivider = Math.floor(Math.min(Math.abs(topValue), Math.abs(bottomValue)));

    if (minDivider > 1) {
        let num = 2;
        while (num <= minDivider) {
            if (Math.floor(topValue / num) * num === topValue  &&  Math.floor(bottomValue / num) * num === bottomValue) {
                topValue = topValue / num;
                bottomValue = bottomValue / num;
            }
            else 
                num ++;
        }
    }

    return [topValue, bottomValue];
};

const isCoefUnique = (topCoeffs, bottomCoeffs, currTopValue, currBottomValue) => {
    for (let i = 0; i < topCoeffs.length; i ++) {
        if (Math.abs(topCoeffs[i]) === Math.abs(currTopValue) && Math.abs(bottomCoeffs[i]) === Math.abs(currBottomValue))
            return false; //found duplicate
    }
    return true; //unique
};

const getMathAction = (action, values) => {
    if (action === 'equal') {
        return values[0];
    }

    let result; 
    if (action === '*') {
        result = 1;
        for (let i = 0; i < values.length; i ++) result *= values[i];
    }
    else { //if (action === '+') {
        result = 0;
        for (let i = 0; i < values.length; i ++) result += values[i];
    }
    return result;
};

const doCalcCoeffsByRule = (opts, coeffs) => {
    //используется например в методе группировки
    if (!!opts.next) {
        const results = [];

        for (let i = 0; i < opts.next.length; i ++) {
            const res = doCalcCoeffsByRule(opts.next[i], coeffs);
            results.push(res);
        }

        const actionResult = getMathAction(opts.action, results);
        return actionResult;
    } else {
        const values = [];

        for (let i = 0; i < opts.values.length; i ++) {
            const elem = opts.values[i];
            const [dataArrIndex, dataIndex] = elem;
            let val = coeffs[dataArrIndex][dataIndex]; 
            values.push(val);
        }

        const actionResult = getMathAction(opts.action, values);

        const multiply = !!opts.multiply ? opts.multiply : 1; //we need it to get minus in:  -(a0*b1 + a1*b0)
        return actionResult * multiply;
    }
};

const isIgnoreValueOne = (form, charValue, charMandatoryValue, vName) => {
    const arr = form.includes(charValue) ? form.split(charValue) : form.split(charMandatoryValue);
    const str = arr[arr.length - 1];
    for (let i = 0; i < str.length && i < 3; i++)
        if (str[i] === '@' || str[i] === 'x' || str[i] === vName)
            return true;
    return false;
};

const changeSigns = (topValue, bottomValue) => {
    if (topValue === 0) bottomValue = 1;
    if (bottomValue < 0) 
        return [-topValue, -bottomValue];
    else
        return [topValue, bottomValue];
};

const transformArrayToString = (arr, level) => {
    //if level = 2 => [[[1,2], [3,4], [5,6]], [[7,7], [8,8], [9,9]]] => '1%2/3%4/5%6 | 7%7/8%8/9%9'
    //if level = 1 => [[1,2], [3,4], [5,6]] => '1%2/3%4/5%6'
    //if level = 0 => [[1,2]] => '1%2'

    const SEPARATORS = ['%', '/', '|'];
    const separator = SEPARATORS[level];
    let res;

    if (level === 0) {
        res = arr.join(separator);
    } else {
        res = arr.map(item => transformArrayToString(item, level-1)).join(separator);
    }

    return res;
};

const transformStringToArray = (str) => {
    //if level = 2 => [[[1,2], [3,4], [5,6]], [[7,7], [8,8], [9,9]]] => '1%2/3%4/5%6 | 7%7/8%8/9%9'
    //if level = 1 => [[1,2], [3,4], [5,6]] => '1%2/3%4/5%6'
    //if level = 0 => [[1,2]] => '1%2'

    const SEPARATORS = ['%', '/', '|'];
    const level = str.includes(SEPARATORS[2]) ? 2 : str.includes(SEPARATORS[1]) ? 1 : 0;
    let numbers;

    if (level === 0) {
        numbers = str.split(SEPARATORS[level]).map(item => Number(item));
    } else {
        numbers = str.split(SEPARATORS[level]).map(item => transformStringToArray(item));
    }

    return numbers;
};

export const findNonAnsweredPage = (_nbData, _taskPages) => {
    let pageIndToAnswer = -1;
    let found = false;
    for (let i = 0; i < _taskPages.length && !found; i ++) {
        const task = _taskPages[i].task;
        if (!_nbData.settings.isAutoScore) {
            if (!task.userAnswerHistory) {
                pageIndToAnswer = i;
                found = true;
            }
        } else {
            if (task.isCorrect === SCORE_TYPE.UNDEFINED) {
                pageIndToAnswer = i;
                found = true;
            }
        } 
    }

    pageIndToAnswer = found ? pageIndToAnswer : _taskPages.length;
    return pageIndToAnswer;
};
