import React from "react";
import {getDescriptionEditor} from "../ui/utils/gen_utils";
import ss from './OneOption.module.scss';
import { Icon } from "../ui";

const OneOption = ({itemIndex, title, description, handleDescription, handleDeleteDescription, descLimit}) => {
    const handleDescriptionChange = v => {
        handleDescription(v, itemIndex);
    };

    return (
        <div className={ss.root} key={'root'+itemIndex}>
            <div className={ss.label} key={'lbl'+itemIndex}>
                <span key={'sp'+itemIndex}>
                    {title}
                    {handleDeleteDescription !== null &&
                    <Icon 
                        className={ss.trash} 
                        name="trash" 
                        onClick={() => handleDeleteDescription(itemIndex)} 
                        key={'itm'+itemIndex} 
                    />
                    }
                </span>
            </div>
            <div className={ss.subEditor} key={'se'+itemIndex}>
                {getDescriptionEditor(description, v => handleDescriptionChange(v), false, 
                itemIndex, descLimit)}
            </div>
        </div>
    );
}

export default OneOption; 