const initialState = {
    changeClass: {},
    userSettings: {
        showModal: false, isAdd: false, isReg: false, allowDelete: false, role: 0, owner: false,
        user: {uid: 0,login: '', name: '', name2: '', name3: '', city: '', email: '', features: {}, videoUrl: '', custom: {videoUrl: '', videoKey: ''} }
    },
    userSelect: {showModal: false, role: 0},
    userPassword: {showModal: false, user: {password: ''}},
    classSettings: {
        showModal: false, isAdd: false, allowDelete: false, isAdmin: false, owner: false,
        class: {className: '', classLevel: '', classDescription: ''}
    },
    rlabSettings: {
        showModal: false, isAdd: false, allowDelete: false,
        rlab: {name: '', desc: ''}
    },
    deviceSettings: {showModal: false, isAdd: false, allowDelete: false,
        device: {name: '', key: '', firmwares: []}
    },
    fileSettings: {
        showModal: false, isAdd: false, allowDelete: false,
        file: {key:'', name: '', prefix: ''}
    },
    videoSettings: {showModal: false},
    rlabSensors: {showModal: false, devciceList: {}},
    rlabNotification: {showModal: false, owner: {}},
    storageSettings: {showModal: false, isAdd: false, storage: {size: 1}},
    nameSettings: {
        showModal: false, isAdd: false, showDesc: true, action: '', title: '', data: {name: '', desc: ''},
        onSubmit: () => {
        }
    },
};

const cactions = (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGE_CLASS':
            const changeClass = Object.assign({}, initialState.changeClass, action.payload);
            return {
                ...state,
                changeClass
            };

        case 'SHOW_CLASS_SETTINGS':
            const classSettings = Object.assign({}, initialState.classSettings, action.payload);
            classSettings.class = Object.assign({}, initialState.classSettings.class, action.payload.class);
            return {
                ...state,
                classSettings,
            };

        case 'SHOW_USER_SETTINGS':
            const userSettings = Object.assign({}, initialState.userSettings, action.payload);
            userSettings.user = Object.assign({}, initialState.userSettings.user, action.payload.user);
            return {
                ...state,
                userSettings,
            };

        case 'SHOW_USER_SELECT':
            const userSelect = Object.assign({}, initialState.userSelect, action.payload);
            return {
                ...state,
                userSelect,
            };

        case 'SHOW_USER_PASSWORD':
            const userPassword = Object.assign({}, initialState.userPassword, action.payload);
            return {
                ...state,
                userPassword,
            };

        case 'SHOW_RLAB_SETTINGS':
            const rlabSettings = Object.assign({}, initialState.rlabSettings, action.payload);
            rlabSettings.rlab = Object.assign({}, initialState.rlabSettings.rlab, action.payload.rlab);
            return {
                ...state,
                rlabSettings,
            };

        case 'SHOW_RLAB_SENSORS':
            const rlabSensors = Object.assign({}, initialState.rlabSettings, action.payload);
            return {
                ...state,
                rlabSensors,
            };

        case 'SHOW_RLAB_NOTIFICATION':
            const rlabNotification = Object.assign({}, initialState.rlabNotification, action.payload);
            return {
                ...state,
                rlabNotification,
            };

        case 'SHOW_VIDEO_SETTINGS':
            const videoSettings = Object.assign({}, initialState.videoSettings, action.payload);
            return {
                ...state,
                videoSettings,
            };

        case 'SHOW_DEVICE_SETTINGS':
            const deviceSettings = Object.assign({}, initialState.deviceSettings, action.payload);
            deviceSettings.device = Object.assign({}, initialState.deviceSettings.device, action.payload.device);
            return {
                ...state,
                deviceSettings,
            };


        case 'SHOW_FILE_SETTINGS':
            const fileSettings = Object.assign({}, initialState.fileSettings, action.payload);
            fileSettings.file = Object.assign({}, initialState.fileSettings.file, action.payload.file);
            return {
                ...state,
                fileSettings,
            };

        case 'SHOW_STORAGE_SETTINGS':
            const storageSettings = Object.assign({}, initialState.storageSettings, action.payload);
            storageSettings.storage = Object.assign({}, initialState.storageSettings.storage, action.payload.storage);
            return {
                ...state,
                storageSettings,
            };

        case 'SHOW_NAME_SETTINGS':
            const nameSettings = Object.assign({}, initialState.nameSettings, action.payload);
            nameSettings.data = Object.assign({}, initialState.nameSettings.data, action.payload.data);
            return {
                ...state,
                nameSettings,
            };

        default:
            return state;
    }
};

export default cactions;
