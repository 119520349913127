import React, { useState, useEffect } from "react";
import Table from "../ui/Table/Table";
import {fullName, isAuth} from "../../helpers/auth";
import {onlineTestInfoApi, onlineTestRunApi} from "../../api/api";
import { getOnlineTestById } from "./ot_utils";
import {printOnlyDate} from "../../helpers/text";
import socket from "../../socket";
import {useSelector} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {DB_REFRESH, CHECK_TIME_TYPE, checkTimeRange} from '../ui/utils/gen_utils';
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import {Content, ContentBody, ContentHead} from '../template/ContentParts';
import "../practicum/TutorialSelectTab.scss";
import "./OnlineTests.scss";
import "./OnlineTestCreate.scss";

const OnlineTestStudentDelayed = ({ history }) => {
	const [tableData, setTableData] = useState([]);
	const [showConfirmDlg, setShowConfirmDlg] = useState(false);
	const [idBeRun, setIdBeRun] = useState(undefined);
	const [isUpdated, setIsUpdated] = useState(false);
	const [counter, setCounter] = useState(0);

    const documentClass = useSelector(storageClass);
	const isTeacher = () => isAuth().role > 0;

	useEffect(() => {
		socket.on("FE-refresh-db", ({type}) => {
			if (type === DB_REFRESH.OTI) setCounter(counter => counter + 1);
		});
		return () => {socket.off("FE-refresh-db");};
	  }, []);
	
	useEffect(() => {
        const fetchData = async () => {
			const result = await onlineTestInfoApi.getAllNonCompletedOtisByOwner(documentClass._id, isAuth()._id);
			const otis = result.data;
			const list = [];

			for (let i = 0; i < otis.length; i++) {
				const oti = otis[i];
				const ot = await getOnlineTestById(oti.onlineTest);
				const otRun = await onlineTestRunApi.getOnlineTestRunById(oti.otrun);
				const delayOpts = oti.delayOpts;
				const runOpts = otRun.runOpts;

				if (delayOpts && runOpts && !oti.isComplete &&
					checkTimeRange(delayOpts.runDate, delayOpts.completeDate, CHECK_TIME_TYPE.INSIDE)) {
					const rec = {
						testTitle: ot.title, 
						timeLimitEnabled: runOpts.runTimeLimitEnabled ? runOpts.runTimeLimitInMinutes + ' минут' : 'Нет',
						isRandomOrder: runOpts.runIsRandomOrder ? 'Да' : 'Нет',	
						author: fullName(ot.owner, 'Шаблон'),
						runDate: printOnlyDate(delayOpts.runDate),
						completeDate: printOnlyDate(delayOpts.completeDate),
						questionNumber: ot.questions.length + ' шт',
						actions: '',
						id: oti._id 
					};
	
					list.push(rec);
				}
			}

			setTableData(list);
			if (isUpdated) setIsUpdated(false);
        }

		fetchData();
	},[isUpdated, counter, documentClass._id]);

    const handleRunTest = (opt, id) => {
		setIdBeRun(id);
		setShowConfirmDlg(true);
    };

	const handleRunTestNo = id => {
		setShowConfirmDlg(false);
		setIdBeRun(undefined);
	};

	const handleRunTestYes = () => {
		setShowConfirmDlg(false);
		const path = '/ot/opt/' + idBeRun;
		setIdBeRun(undefined);
		history.push(path);
	};
	
	const getTableHeader = () => {
		const header = [
			{column: 'Название', name: 'testTitle', style: { width: '19%'} }, 
			{column: 'Ограничение по времени', name: 'timeLimitEnabled', style: { width: '16%'} }, 
			{column: 'Случайный порядок вопросов', name: 'isRandomOrder', style: { width: '10%'} }, 
			{column: 'Автор', name: 'author', style: { width: '10%'} }, 
			{column: 'Дата начала', name: 'runDate', style: { width: '15%'} }, 
			{column: 'Дата окончания', name: 'completeDate', style: { width: '15%'} }, 
			{column: 'Кол-во вопросов', name: 'questionNumber', style: { width: '10%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '10%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} } 
		];
		return header;
	};

    const getTable = () => {
		return (
			<Table
				table={{
					header: getTableHeader(), 
					data: tableData,
				}}
				sort={{
					hasSorting: true,
					initSortInd: -1,
				}}
				actions={{
					handleRun: id => handleRunTest(true, id),
					tooltipRun: 'Начать онлайн тест'
				}}
			/>
		);
    };

	const getInitState = () => {
		return (
			<div className="labWorkShow__first">
				<div className="labWorkShow__firstName">
					<span>Преподаватель не определил задание.</span>
				</div>
			</div>
		);
	};

	if (isTeacher()) return <></>;

	if (tableData.length === 0) {
		return getInitState();
	}

    return (
		<>
			<ContentHead title="Отложенные онлайн тесты" />

			<Content>
				<ContentBody>
					{getTable()}
				</ContentBody>
			</Content>

			{showConfirmDlg && 
			<ModalConfirmDialog
				showConfirmDlg={showConfirmDlg} 
				handleNo={handleRunTestNo}
				handleYes={handleRunTestYes}
				question={'Запустить онлайн тест'}
				btnTextYes={'Запустить тест'}
				btnTextNo={'Отменить'}
				redWarning={true}
			/>
			}
		</>
    )
}

export default OnlineTestStudentDelayed;
