import React from "react";
import {FilterByText, Tabs, FilterByDate} from "../ui";
import {NB_REVIEW_TAB} from "./nb_utils";
import "../ReviewTest/LabWorkSelect.scss";

//Выбираем таб, текстовый фильтр и временной диапазон 
const SelectNotebookFilterOptions = ({isShowNotebookCatalog, tabs, tabId, handleSelectTab,
			textPrompt, testFilter, setTestFilter, 
			startDate, setStartDate, finishDate, setFinishDate, subjectCounter
	}) => {

	return (
	<div className="cor-net">
		<div className="cor-net__section">
			<div className="cor-net__row">

				<div className="cor-net__col col-4">
					<Tabs>
						{tabs.map(tab => (
							<Tabs.Item 
								active={tabId === tab.tabId} 
								onClick={() => handleSelectTab(tab.tabId)} 
								key={'tab'+ tab.tabId}
                                notify={!isShowNotebookCatalog && tab.tabId === NB_REVIEW_TAB.BE_CHECKED ? subjectCounter : 0}
							>
								{tab.tabName}
							</Tabs.Item>)
						)}
					</Tabs>
				</div>
				
				{!isShowNotebookCatalog && 
				<>
					<div className="cor-net__col col-grow"></div>

					<div className="cor-net__col col-5">
						<FilterByText 
							placeholder={textPrompt}
							value={testFilter}
							setSearch={setTestFilter}
						/>
					</div>

					<div className="cor-net__col col-grow"></div>

					<div className="cor-net__col">
						<FilterByDate 
							startDate={startDate} 
							setStartDate={setStartDate} 
							finishDate={finishDate} 
							setFinishDate={setFinishDate} 
						/>
					</div>
				</>
				}
			</div>
		</div>
	</div>
	);
}

export default SelectNotebookFilterOptions;
