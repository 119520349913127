import React, {useEffect} from 'react';
import * as Yup from "yup";
import {toast} from "react-toastify";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {Modal, Button} from 'rlabui';
import {storageApi} from "../../api/api";
import {loadFiles} from "../../redux/slices/lists";

const FileSettings = (props) => {
    const dispatch = useDispatch();
    const {lists:files} = useSelector(state => state);

    const handleClose = () => {
        formik.resetForm();
        props.showFileSettings({showModal: false});
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) handleClose();
        };
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    Yup.addMethod(Yup.string, "checkName", function (errorMessage) {
        return this.test(`checkName`, errorMessage, function (value) {
            const { path, createError } = this;
            return (
              props.file.name === value || !files.files.some(f => f.Key.split('/').pop() === value) ||
              createError({ path, message: errorMessage })
            );
        });
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: props.file.name,
        }, validationSchema: Yup.object({
            name: Yup.string().required("Введите имя").checkName("Такое имя уже есть"),
        }), onSubmit: async (formData) => {
            try {
                if (formData.name === props.file.name) {
                    handleClose();
                    return;
                }

                const newKey = props.file.key.replace(/[^/]+$/,'')  + formData.name;
                storageApi.rename(props.file.key, newKey).then(() => {
                    dispatch(loadFiles(props.file.prefix));
                    handleClose();
                }).catch((err) => {
                    toast.error(err.response.data.errors);
                });
            } catch (err) {
                console.log(err);
            }
        },
    });

    return (
        <>
            <Modal visible={props.showModal} size="sm">
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Head modalClose={handleClose} title='Редактировать Файл' />
                    <Modal.Body>
                        <div className="cor-net__row" key="email">
                            <div className="cor-net__col col-1">
                                <div className="cor-net__label flex jc-between">
                                    Имя Файла
                                    <span className='form_error'>{formik.errors.name}</span>
                                </div>
                                <input
                                    autoComplete="off"
                                    placeholder="Имя"
                                    name="name"
                                    className={formik.errors.name?"form_error":""}
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit">
                            {props.isAdd ? "Добавить" : "Сохранить"}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
};

export default FileSettings;