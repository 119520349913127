import React, {useEffect} from 'react';
import {Icon} from '../../ui';
import "./ThemeSwitcher.scss";
import {useSelector} from "react-redux";
import {storageOptions} from "../../../redux/slices/storage";

const ThemeSwitcher = (props) => {
    const {setTheme} = props;
    const {theme} = useSelector(storageOptions);
    
    useEffect(() => {
        if (theme) {
            theme === 'dark' && document.body.classList.remove('light');
            theme === 'light' && document.body.classList.remove('dark');
            document.body.classList.add(theme);
        } else {
            document.body.classList.add('light');
        }
    },[theme]);

    return (
        <div className="cor_themeSwitcher tp tp_down" data-tp="Тема">
            <Icon
                name="sun"
                className={theme !== 'dark' ? 'active' : ''}
                onClick={() => setTheme('light')}
            />
            <input
                name="theme"
                type="checkbox"
                className="cor_switcher"
                onChange={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                checked={theme === 'dark'}
            />
            <Icon
                name="moon"
                className={theme === 'dark' ? 'active' : ''}
                onClick={() => setTheme('dark')}
            />
        </div>
    )
};
export default ThemeSwitcher;
