import React, {useState, useEffect} from "react";
import { isAuth } from "../../helpers/auth";
import Table from "../ui/Table/Table";
import socket from "../../socket";
import {DB_REFRESH} from "../ui/utils/gen_utils";
import {printOnlyDate} from "../../helpers/text";
import {STUDY_SUBJECTS, NB_ACT, getSubjectById, getDifferenceInDays, getNbSpecialStudentWorkCellStyle, 
    getAllNotebookInfoByOwner, getNotebookById, getNbStudentWorkStatus, 
    getIsNbiInProgress, getIsNbiInProgressByInfo, getIsExecutionOutOfLimit, getIsTestTimeCompleted, 
    isNbTaskStarted} from './nb_utils';
import {storageClass} from "../../redux/slices/storage";
import {getScoreValues} from '../ui/utils/score_utils';
import {useSelector, useDispatch} from "react-redux";
import {loadCountersNotebook, loadCountersNotebookOnSubject} from "../../redux/slices/lists";
import NotebooksStudentInitDlg from './NotebooksStudentInitDlg';
import { Content, ContentBody, ContentDesc, ContentHead } from "../template/ContentParts";
import SectionTabs from "../ui/SectionTabs";
import nb from "./Notebooks.module.scss";

const NotebookStudentHomeWorks = ({history}) => {
	const [tabs, setTabs] = useState([]);
    const [nbList, setNbList] = useState([]);
    const [nbiList, setNbiList] = useState([]);
	const [tableList, setTableList] = useState([]);
	const [showInitDlg, setShowInitDlg] = useState(false);
	const [selectedIds, setSelectedIds] = useState(undefined);
    const [counter, setCounter] = useState(0);

    const documentClass = useSelector(storageClass);
	const {lists: {counters}} = useSelector(state => state);
    const dispatch = useDispatch();

	useEffect(() => {
        socket.on("FE-refresh-db", ({type}) => {
            if (type === DB_REFRESH.NBI) {
                setCounter(counter => counter + 1);
                dispatch(loadCountersNotebook(documentClass._id));
                dispatch(loadCountersNotebookOnSubject(documentClass._id));
                }
        });
        return () => {socket.off("FE-refresh-db");};
    }, [dispatch, documentClass._id]);

    useEffect(() => {
		dispatch(loadCountersNotebook(documentClass._id));
		dispatch(loadCountersNotebookOnSubject(documentClass._id));
	}, [dispatch, documentClass._id]);

	useEffect(() => {
		const subjectCounters = counters.notebookOnSubject;
		const _tabs = [...STUDY_SUBJECTS];
		for (let i = 0; i < subjectCounters.length; i ++)
			if (subjectCounters[i].count !== 0) {
				_tabs[subjectCounters[i]._id].notify = {
					path: '/notebooks/' + NB_ACT.SHOW_ALL_SUBJECT_WORKS + '/' + i,
					value: subjectCounters[i].count};
			}
		
		setTabs(_tabs);
	}, [counters]);

    useEffect(() => {
        const getTableList = async () => {
            const result = await getAllNotebookInfoByOwner(documentClass._id, isAuth()._id);
            const nbis = result.data;
            setNbiList(nbis);

            const scoreValues = getScoreValues();
            const minValue = Number(scoreValues[scoreValues.length - 2]);

            const list = [];
            const _nbLst = [];

            for (let i = 0; i < nbis.length; i ++) {
                const nbi = nbis[i];
                const nb = await getNotebookById(nbi.notebook);
                const [isStarted, timeRun, timeComplete] = isNbTaskStarted(nb, nbi);
                const isCompleteTest = nbi.isComplete; 
                const isExecutionOutOfLimit = getIsExecutionOutOfLimit(nb, nbi);
                const isTimeCompleted = getIsTestTimeCompleted(timeComplete);
                const inProgress = getIsNbiInProgressByInfo(nb, nbi);
                
                if (isStarted) {
                    const status = getNbStudentWorkStatus(nbi.score, isCompleteTest, isTimeCompleted, isExecutionOutOfLimit, 
                        inProgress, minValue);

                    const rec = {
                        subject: getSubjectById(nb.titles.subjectId),
                        name: nb.titles.homeWorkName,
                        item: nb.titles.homeWorkItem,
                        dateStart: printOnlyDate(timeRun),
                        leftDays: getDifferenceInDays(timeComplete),
                        taskAmount: nbi.tasks.length + ' шт.',
                        status: status,
                        actions: '',
                        id: nbi.notebook + '|' + nbi._id, //notebook Id and notebookInfo id
                    };
                    
                    list.push(rec);
                    _nbLst.push(nb);
                }
            }

            setTableList(list);
            setNbList(_nbLst);
        };

		getTableList();
    }, [documentClass, counter]);

    const handleOpenInitDlg = ids => {
        if (!getIsNbiInProgress(nbList, nbiList, ids)) {
            setSelectedIds(ids);
            setShowInitDlg(true);
        } else {
            history.push('/notebooks/' + NB_ACT.RUN_TEST + '/' + ids);
        }
    };

    const handleExecuteTest = () => {
        setShowInitDlg(false);
        //возможно, здесь еще раз надо проверить дом.задание. если оно завершено, то вызывать handleSubjectTab.
        history.push('/notebooks/' + NB_ACT.RUN_TEST + '/' + selectedIds); //ids is nbId + '|' + nbiId
    };

    const specialCellStyle = (item, rowInd, colInd, row) => {
        const SPEC_TEXT = {
            DAY: 'день',
            DAYS: 'дня',
        };
        if (item.includes(SPEC_TEXT.DAY)) return {color: 'red'};
        if (item.includes(SPEC_TEXT.DAYS) && item.split(' ')[0] === '2') 
            return {color: '#C96203'};
        return getNbSpecialStudentWorkCellStyle(item, rowInd, colInd, row);
    };

    const getTableHeader = () => {
		const header = [
			{column: 'Предмет', name: 'subject', style: { width: '10%'} }, 
			{column: 'Название', name: 'name', style: { width: '13%'} }, 
			{column: 'Тема', name: 'item', style: { width: '14%'} }, 
			{column: 'Дата начала', name: 'dateStart', style: { width: '12%'} }, 
			{column: 'Осталось дней', name: 'leftDays', style: { width: '13%'} }, 
			{column: 'Задания', name: 'taskAmount', style: { width: '11%'} }, 
			{column: 'Статус', name: 'status', style: { width: '12%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '15%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
        ];
		return header;
	};

    const getHomeWorkTable = () => {
		return (
		<div className={nb.nb__table}>
			<Table 
				table={{
                    header: getTableHeader(), 
					data: tableList,
                    specialCellStyle: specialCellStyle
				}}
				sort={{
                    hasSorting: true, 
                    initSortInd: -4
                }}
				actions={{
					handleExecuteTest: ids => handleOpenInitDlg(ids),
					textExecuteTest: 'Выполнить задание',
				}}
			/>
		</div>
		);
    };

    return  (
        <>
            <ContentHead title="Рабочая тетрадь" />
            <ContentDesc desc="Вы можете просмотреть здесь задания по выбранным предметам" />
            <Content>
                <ContentBody>
                    <div className="cor-net__section">
                        <SectionTabs history={history} tabs={tabs}/>
                    </div>
                    <div className="cor-net__section">
                        <div className="cor-net__title">Невыполненные работы</div>
                        {getHomeWorkTable()}
                    </div>
                </ContentBody>

                {showInitDlg && 
                <NotebooksStudentInitDlg
                    showDlg={showInitDlg}
                    setShowDlg={setShowInitDlg}
                    selectedIds={selectedIds}
                    handleExecuteTest={handleExecuteTest}
                />
                }
            </Content>
        </>

    );

}

export default NotebookStudentHomeWorks;
