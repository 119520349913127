import React from "react";
import Footer from '../Footer';
import {isAuth} from "../../../helpers/auth";
import Notebooks from "../../Notebook/Notebooks";
import NotebooksOnSubject from "../../Notebook/NotebooksOnSubject";
import NotebookReviewSelectedRun from "../../Notebook/NotebookReviewSelectedRun";
import NotebookStudentHomeWorks from "../../Notebook/NotebookStudentHomeWorks";
import NotebookStudentHomeWorksOnSubject from "../../Notebook/NotebookStudentHomeWorksOnSubject";
import NotebookStudentHomeWorkExecute from "../../Notebook/NotebookStudentHomeWorkExecute";
import NotebookAddEdit from "../../Notebook/NotebookAddEdit";
import {NB_ACT} from "../../Notebook/nb_utils";
import "./Dashboard.scss";
import "../Aside/Aside.scss";
import { ContentWrap } from "../ContentParts";

const DashboardNotebook = (props) => {
    const opt = Number(props.match.params.opt);
    const id = props.match.params.id; //ADD ID when we call from notebook table

    const getSuperAdminNotebookInfo = () => {
        if (opt === NB_ACT.SHOW_ALL_NBS) {  //open all items on all subjects
            //КАТАЛОГ ПО ВСЕМ ТЕМАМ
            return (
                <ContentWrap>
                    <Notebooks {...props} />
                </ContentWrap>
                );
        } else if (opt === NB_ACT.SHOW_ALL_SUBJECT_NBS || opt === NB_ACT.SHOW_ALL_SUBJECT_WORKS) { 
                //КАТАЛОГ ПО ВЫБРАННОЙ ТЕМЕ
            return (
                <ContentWrap>
                    <NotebooksOnSubject {...props} subjectId={Number(id)} />
                </ContentWrap>
            )
        } else { //if (opt === NB_ACT.ADD_EDIT_NB) { // open a new notebook or the selected notebook by id
            return (<NotebookAddEdit {...props} id={id} />);
        }
    };

    const getTeacherNotebookInfo = () => {
        if (opt === NB_ACT.SHOW_ALL_NBS) {  //open all items on all subjects
            //КАТАЛОГ ПО ВСЕМ ТЕМАМ
            return (
                <ContentWrap>
                    <Notebooks {...props} />
                    <Footer {...props} opt={props.match.params.opt} />
                </ContentWrap>
                );
        } else if (opt === NB_ACT.SHOW_ALL_SUBJECT_NBS || opt === NB_ACT.SHOW_ALL_SUBJECT_WORKS) { 
            //open all notebooks on selected subject (math, physics, ..)
            //КАТАЛОГ ПО ВЫБРАННОЙ ТЕМЕ //isNbCatalog
    
            return (
                <ContentWrap>
                    <NotebooksOnSubject {...props} subjectId={Number(id)} />
                    <Footer {...props} opt={props.match.params.opt} />
                </ContentWrap>
            ) 
        } else if (opt === NB_ACT.ADD_EDIT_NB) { // open a new notebook or the selected notebook by id
            return (<NotebookAddEdit {...props} id={id} />);
        } else if (opt === NB_ACT.SET_RUN_TIME) { // open the selected notebook by id and go to 3rd tab to set running time
            return (<NotebookAddEdit {...props} id={id} isSetRunTime={true} />);
        } else if (opt === NB_ACT.RUN_TEST) { //предпросмотр
            return (<NotebookStudentHomeWorkExecute {...props} ids={id} />);
        } else if (opt === NB_ACT.REVIEW_NB_RUN) {
            return (
                <ContentWrap>
                    <NotebookReviewSelectedRun {...props} notebookId={id} />
                    <Footer  {...props} opt={props.match.params.opt} />
                </ContentWrap>
                )
        } else {
            <>НЕ НАЙДЕН!!!!!</>
        }
};

    const getStudentNotebookInfo = () => {
        if (opt === NB_ACT.SHOW_ALL_NBS) { //open all home works on all subjects
            return (
                <ContentWrap>
                    <NotebookStudentHomeWorks {...props} />
                    <Footer  {...props} opt={props.match.params.opt} />
                </ContentWrap>
            ); //
        } else if (opt === NB_ACT.SHOW_ALL_SUBJECT_NBS) { //open all home works on selected subject (math, physics, ..)
            return (
                <ContentWrap>
                    <NotebookStudentHomeWorksOnSubject {...props} subjectId={Number(id)} />
                    <Footer  {...props} opt={props.match.params.opt} />
                </ContentWrap>
            );
        } else { // opt === NB_ACT.RUN_TEST - open the selected home work
            return (
                <NotebookStudentHomeWorkExecute {...props} ids={id} />
            );
        }
    };

    if (isAuth().role === 3) { //see --- history.push('/notebooks
        return getSuperAdminNotebookInfo();
    } else if (isAuth().role > 0) { //see --- history.push('/notebooks
        return getTeacherNotebookInfo();
    } else {
        return getStudentNotebookInfo();
    }
}

export default DashboardNotebook;
