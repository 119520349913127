//1-state.devices.all в редаксе  есть.
//2- /src/reducer/sensors.js вот тут есть sensors.list в него после подключения записывается весь подключенный датчик
//с данными  строка выглядит примерно так - accelero3 сh0 0.3 ch1 1.55 ch2 0
//6 - src/webWorkers/connectWorker.js вот тут у меня весь парсинг строки описан

import React, {useState, useEffect, useCallback} from "react";
import {Back, Content, ContentBody, ContentFooter, ContentHead, ContentHeader, ContentTitle} from "../template/ContentParts";
import {Icon, Tabs, Input} from "../ui";
import socket from "../../socket";
import {DB_REFRESH, base64ToArrayBuffer, getDropdown} from "../ui/utils/gen_utils";
import {Button} from 'rlabui';
import {useSelector} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {toast} from "react-toastify";
import {isAuth} from "../../helpers/auth";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import {ATTACH_TYPE, SC_STATE, RL_SCENARIO_PREFIX, RL_SCENARIO_SLASH, 
  getSensorCount, getSensorIdName, uploadRlsFile} from './sc_utils';
import Table from "../ui/Table/Table";
import {rlsApi, rlsiApi, storageApi} from "../../api/api";
import {printOnlyDate} from "../../helpers/text";
import {fullName} from "../../helpers/auth";

const scenarioTypeList = [
  {label: 'Все', value: 'all'},
  {label: 'Мои сценарии', value: 'room'},
  {label: 'Шаблоны', value: 'undefined'},
];

const ScenarioList = (props) => {
  const {history, devices, sensors} = props;
  const [scenariosData, setScenariosData] = useState([]);
  const [filteredScenariosData, setFilteredScenariosData] = useState([]);
  const [scenariosType, setScenariosType] = useState('all');
  const [idBeDeleted, setIdBeDeleted] = useState(undefined);
  const [showConfirmDlg, setShowConfirmDlg] = useState(false);
  const [activeSensorId, setActiveSensorId] = useState('');
  const [deleteQuestion, setDeleteQuestion] = useState('');
  const [hasScenarioTests, setHasScenarioTests] = useState(false);
	const [testFilter, setTestFilter] = useState('');
  //
	const [filesBeCopied, setFilesBeCopied] = useState([]);
	const [scenarioBeCopied, setScenarioBeCopied] = useState(undefined);
  const [loadedFileName, setLoadedFileName] = useState('');
	const [tableTabInd, setTableTabInd] = useState(isAuth().role < 3 ? SC_STATE.CREATED.status : SC_STATE.TEMPLATE.status);

  const documentClass = useSelector(storageClass);
	
  useEffect(() => {
    const count = getSensorCount(sensors);

    if (count === 1) {
      const [id] = getSensorIdName(devices, sensors);
      if (id) setActiveSensorId(id);
    }
  }, [devices, sensors]);

  const loadScenariosData = (cl, type, tabInd) => {
    rlsApi.getScenarios(cl).then(data => {
      if (type === 'room')
        data = data.filter(item => item.room);
      else if (type === 'undefined')
        data = data.filter(item => !item.room);

      const list = [];
      
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        const include = (tabInd === SC_STATE.ALL.status || 
           (tabInd === SC_STATE.TEMPLATE.status && !item.owner) ||
           (tabInd === SC_STATE.CREATED.status && item.owner));

        if (include) {
          list.push({
            linkName: item.name,
            date: printOnlyDate(item.updatedAt),
            author: fullName(item.owner, 'Шаблон'),
            sensor: 'Датчик ' + item.sensorName,
            sensorId: item.sensorId,
            actions: '',
            id: item._id
          });
        }
      }

      setScenariosData(list);
    });
  };

  useEffect(() => {
    loadScenariosData(documentClass._id, scenariosType, tableTabInd);
  }, [documentClass._id, scenariosType, tableTabInd]);

  useEffect(() => {
    const filter = testFilter.trim().toLowerCase();
    const scenarios = filter.length > 0 ? scenariosData.filter(item => 
        item.linkName.toLowerCase().includes(filter) || item.sensor.toLowerCase().includes(filter)) :
      scenariosData;      

    setFilteredScenariosData(scenarios);
  }, [testFilter, scenariosData]);
  
  const deleteScenario = async (id) => {
    const data = await rlsApi.getScenarioById(id);
    const isTemplate = !data.owner;

    if (!isTemplate) {
      //1. delete attachments
      await Promise.all(data.items.filter(item => item.attachmentName).map(async item => {
        await storageApi.delete(RL_SCENARIO_PREFIX + item.attachmentName);
      }));

      const c = data.composition;
      if (c && c.attachmentType === ATTACH_TYPE.IMAGE && c.attachmentName)
      {
        await storageApi.delete(RL_SCENARIO_PREFIX + data.composition.attachmentName);
      }

      //2. delete scenario tests
      if (hasScenarioTests) {
        await rlsiApi.deleteAllRlScenarioInfoByScenarioId(id);
        socket.emit('BE-refresh-db', {type: DB_REFRESH.SCI, roomId: documentClass._id});
      }
  }

    //3. delete this scenario
    if (!isTemplate || isAuth().role === 3)
      await rlsApi.deleteScenarioById(id);
  };

  const handleRun = id => {
    props.setScenarioId(id); 
    history.push('/rlab');
  };

  const handleEdit = id => {
    history.push('/rlsc/one/' + id);
  };

  const handleCopy = async (id) => {
    const scenario = await rlsApi.getScenarioById(id);
    scenario.name = 'Копия - ' + scenario.name;
    const isTemplate = !scenario.owner;

    if (!isTemplate)
      copyScenario(scenario);
    else if (isTemplate && isAuth().role === 3)
      copyTemplate(scenario);
    else //copy template to scenario
      copyTemplateToScenario(scenario);
  };

  const copyScenario = async (scenario) => {
    await Promise.all(scenario.items.filter(item => item.attachmentName)
    .map(async item => {
      const name = RL_SCENARIO_SLASH + item.attachmentName;
      const {newKey} = await storageApi.copy(name, name);
      item.attachmentName = newKey.split('/').pop();
    }));

    if (scenario.composition && scenario.composition.attachmentType === ATTACH_TYPE.IMAGE && 
        scenario.composition.attachmentName) {
      const name = RL_SCENARIO_SLASH + scenario.composition.attachmentName;
      const {newKey} = await storageApi.copy(name, name);
      scenario.composition.attachmentName = newKey.split('/').pop();
    }

    addScenario(scenario);
  };

  const copyTemplate = (scenario) => {
    addScenario(scenario);
  };

  const addScenario = useCallback(async (scenario) => {
    await rlsApi.addScenario(scenario).then(() => {
      toast.success("Сценарий '" + scenario.name + "' сохранен.");
      loadScenariosData(documentClass._id, scenariosType, tableTabInd);
    }).catch((err) => {
        toast.error(err.response.data.errors);
    });
  }, [documentClass._id, scenariosType, tableTabInd]);

  //копирование templates в сценарий (3 шага)
  //step 1 of 3: готовим инфррмацию о файлах в шаблоне
  const copyTemplateToScenario = async (scenario) => {
    scenario.room = documentClass._id;

    //готовим список с информацией о файлах, которые дорлжны быть помещены в хранилище 
    //на основе данных об этих файлах в шаблоне
    //1 - шаблон содержит инфу об items и composition - 
    //    a) items.attachmentName = [{name: f_name, content: content}], 
    //    b) composition.attachmentName = {name: f_name, content: content}
    //2 - внутренняя инфа для передачи в компоненте 
    //  a) для items - {type: 'item', ind: ind, attachmentName: item.attachmentName}
    //  b) для composition - {type: 'composion', attachmentName: scenario.composition.attachmentName}
    //3 - в итоге должны создать соответствующие файлы и поместить имена attachmentName - 
    //  items.attachmentName = f_name, composition.attachmentName = f_name

    const arr = scenario.items.filter(item => item.attachmentName).map((item, ind) => 
      ({type: 'item', ind: ind})
    );
    const comp = scenario.composition;
    if (comp && comp.attachmentType === ATTACH_TYPE.IMAGE && comp.attachmentName) {
      arr.push({type: 'composion'});
    }

    setFilesBeCopied(arr);
    setScenarioBeCopied(scenario);
  };

  //step 2 of 3: готовим очередной файл и посылаем запрос на загрузку ИЛИ сохраняем сценарий
  useEffect(() => {
    //templates contain the images inside. When copying the templates it's necessary to create 
    //image file, save them and add references to the files as attachmentName:
    if (!scenarioBeCopied || loadedFileName) return;
    if (filesBeCopied.length > 0) {
      //попадаем сюда. если список файлов еще не пуст. создаем соответствующий файл и затем удаляем ссылку на него из списка
      const f = filesBeCopied[0];
      const fileOpts = JSON.parse(f.type === 'item' ? 
        scenarioBeCopied.items[f.ind].attachmentName : scenarioBeCopied.composition.attachmentName);
      const ext = fileOpts.name.split('.')[1];
      const type = 'image/' + ext;
      const content = fileOpts.content.split('base64,')[1];

      const file = new File(
        [base64ToArrayBuffer(content)],
        fileOpts.name, 
        { type: type }
      );

      uploadRlsFile(file, setLoadedFileName);
    } else {
      //попадаем сюда, когда все файлы созданы. осталось создать сам сценарий
      addScenario(scenarioBeCopied);
      setScenarioBeCopied(undefined);
    }
  }, [addScenario, filesBeCopied, loadedFileName, scenarioBeCopied]);

  //step 3 of 3: сохраняем инфу об очередном загруженном файле в данные сценария (в attachmentName), 
  //уменьшаем список filesBeCopied. Затем снова возвращаемся в шаг 2.
  useEffect(() => {
    if (!loadedFileName || filesBeCopied.length === 0) return;
    const _filesBeCopied = [...filesBeCopied];
    const f = _filesBeCopied[0];
    const _scenario = {...scenarioBeCopied};
    //console.log('f.type=', f.type)
    if (f.type === 'item') {
      _scenario.items[f.ind].attachmentName = loadedFileName;
    } else {
      _scenario.composition.attachmentName = loadedFileName;
    }
    _filesBeCopied.shift(); //удаляем инфу о созданенном файле из списка
    setFilesBeCopied(_filesBeCopied); //сохраняем обновленный список
    setScenarioBeCopied(_scenario);
    setLoadedFileName('');
  }, [loadedFileName, filesBeCopied, scenarioBeCopied]);

  const handleLink = id => {
    handleEdit(id);
  };

  const handleDelete = (id) => {
    rlsiApi.getAllStudentsRlScenarioInfoByScenarioId(id).then(result => {
      let question = result.data.length > 0 ? 'Существуют тесты, выполненные учениками на основе этого сценария. ' +
        'В случае удаления сценария эти тесты будут также удалены из базы данных. ' : '';
      question += 'Вы действительно хотите удалить этот сценарий?';
      setHasScenarioTests(result.data.length > 0);
      setDeleteQuestion(question);
      setIdBeDeleted(id);
      setShowConfirmDlg(true);
    });
  };

  const handleDeleteNo = id => {
    setIdBeDeleted(undefined);
    setShowConfirmDlg(false);
  };
  const handleDeleteYes = () => {
    deleteScenario(idBeDeleted).then(() => {
      setIdBeDeleted(undefined);
      setShowConfirmDlg(false);
      loadScenariosData(documentClass._id, scenariosType, tableTabInd);
    });
  };
  const canDeleteIDs = () => filteredScenariosData.filter(item => isAuth().role === 3 || item.author !== 'Шаблон').map(item => item.id);
  const canRunIDs = () => filteredScenariosData.filter(item => item.sensorId === activeSensorId && activeSensorId !== '').map(item => item.id);
  const canEditIDs = () => filteredScenariosData.filter(item => isAuth().role === 3 || item.author !== 'Шаблон').map(item => item.id);

  const handleActionSyle = (row, id) => {
    return {justifyContent: 'flex-end'};
  };

  const handleSelectTab = (ind) => setTableTabInd(ind);

  const getTable = () => {
    const headerRunning = [
      {column: 'Название сценария', name: 'linkName', style: {width: '25%'}},
      {column: 'Дата', name: 'date', style: {width: '15%'}},
      {column: 'Автор', name: 'author', style: {width: '15%'}},
      {column: 'Название датчика', name: 'sensor', style: {width: '35%'}},
      {column: 'Действия', name: 'actions', style: {width: '10%'}},
      {column: 'id', name: 'id', style: {width: '0%'}},
    ];

    return (
      <Table
        table={{
          header: headerRunning,
          data: filteredScenariosData,
        }}
        link={{
          handleLink: id => handleLink(id),
        }}
        sort={{
          hasSorting: true,
          initSortInd: -2,
        }}
        actions={{
          handleRun: id => handleRun(id),
          canRunIDs: canRunIDs(),
          handleEdit: id => handleEdit(id),
          canEditIDs: canEditIDs(),
          handleCopy: id => handleCopy(id),
          handleDelete: id => handleDelete(id),
          canDeleteIDs: canDeleteIDs(),
          handleActionSyle: (row, id) => handleActionSyle(row, id),
        }}
      />
    );
  };

  return (
    <>
      <ContentHead flex={false}>
        {isAuth().role < 3 &&
          <Back onClick={() => history.goBack()} icon="back" margin="bottom"/>
        }
        <ContentTitle>Мои сценарии</ContentTitle>
      </ContentHead>
  
      <Content>
        <ContentHeader>
          <div className="cor-net__row">

            <div className="cor-net__col col-3">
							<Tabs>
									{Object.values(isAuth().role < 3 ? SC_STATE: {0 : SC_STATE.TEMPLATE}).map((tab) => (
										<Tabs.Item 
											active={tableTabInd === tab.status}
											onClick={() => handleSelectTab(tab.status)}
											key={tab.status}
										>
											{tab.name}
										</Tabs.Item>)
									)}
							</Tabs>
						</div>


            <div className="cor-net__col col-3 col-icon">
              <Input placeholder='Введите название сценария или датчика' onInput={e => setTestFilter(e.target.value)} />
              <Icon name={'search'}/>
            </div>

            {isAuth().role < 3 &&
            <div className="cor-net__col col-3">
              {getDropdown(scenarioTypeList, scenariosType, value => setScenariosType(value), '', '', 'sc01')}
            </div>
            }
          </div>
        </ContentHeader>

        <ContentBody>
          {getTable()}
        </ContentBody>

        <ContentFooter className="jc-end">
          <Button color="primary" onClick={() => history.push('/rlsc/one')}>
            Создать
          </Button>
        </ContentFooter>
      </Content>

      {showConfirmDlg &&
      <ModalConfirmDialog
        showConfirmDlg={showConfirmDlg}
        handleNo={handleDeleteNo}
        handleYes={handleDeleteYes}
        question={deleteQuestion}
				btnTextYes={'Точно удалить'}
				btnTextNo={'Оставить'}
				redWarning={true}
      />
      }
    </>
  );
}

export default ScenarioList;