import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    subjectId: '01-physics',
    sectionId: '01-mechanics',
    labId: '',
    audioContextData: null,
    isRefreshNBarCounters: false,
    nbView: [
      {ind: -1, isCatalog: false}, //all
      {ind: 0, isCatalog: true}, //math
      {ind: 1, isCatalog: true}, //physics
    ],
    isOtView: true,
    isLwView: true,
    otSubtab: 0,
    rtSubtab: 0
};

const tutorialSlice = createSlice({
  name: "tutorials",
  initialState,
  reducers: {
    setTutorialSubjectId(state, action) {
      state.subjectId = action.payload;
    },
    setTutorialSectionId(state, action) {
      state.sectionId = action.payload;
    },
    setTutorialLabId(state, action) {
      state.labId = action.payload;
    },
    setAudioContextData(state, action) {
      state.audioContextData = action.payload;
    },
    setIsRefreshNBarCounters(state, action) {
      state.isRefreshNBarCounters = action.payload;
    },
    setNbView(state, action) {
      state.nbView = action.payload;
    },
    setIsOtView(state, action) {
      state.isOtView = action.payload;
    },
    setIsLwView(state, action) {
      state.isLwView = action.payload;
    },
    setOtSubtab(state, action) {
      state.otSubtab = action.payload;
    },
    setRtSubtab(state, action) {
      state.rtSubtab = action.payload;
    },
  },
});

export const getTutorialSubjectId = (state) => state.tutorialSlice.subjectId;
export const getTutorialSectionId = (state) => state.tutorialSlice.sectionId;
export const getTutorialLabId = (state) => state.tutorialSlice.labId;
export const getAudioContextData = (state) => state.tutorialSlice.audioContextData;
export const getIsRefreshNBarCounters = (state) => state.tutorialSlice.isRefreshNBarCounters;
export const getNbView = (state) => state.tutorialSlice.nbView;
export const getIsOtView = (state) => state.tutorialSlice.isOtView;
export const getIsLwView = (state) => state.tutorialSlice.isLwView;
export const getOtSubtab = (state) => state.tutorialSlice.otSubtab;
export const getRtSubtab = (state) => state.tutorialSlice.rtSubtab;

export const { setTutorialSubjectId, setTutorialSectionId, setTutorialLabId, 
  setAudioContextData, setIsRefreshNBarCounters, 
  setNbView, setIsOtView, setIsLwView, setOtSubtab, setRtSubtab  } = tutorialSlice.actions;
export default tutorialSlice.reducer;
