import React from 'react';
import Notify from '../Notify';

const TabsItem = ({children, className = "", active = false, onClick = null, notify = false}) => {
    const activeClass = active ? " active" : "";
    const customClass = className ? " " + className : "";

    let notifyClass = "tabs__notify";
    if (notify > 9 && notify <= 99) {
        notifyClass += ' lg';
    } else if (notify > 99) {
        notifyClass += ' xl';
    }
 
    return <div className={"tabs__item" + activeClass + customClass} onClick={onClick}>
        <Notify className={notifyClass} value={notify} />
        {children}
    </div>
};

export default TabsItem;
