export const connectWebWorker = () => {
    let counters = {},
        currentTime = {},
        currentVal = [],
        channelsList = {},
        savedData = {},
        asideData = {},
        numsCheck = {},
        battery = {},
        time = 0,
        data,
        aside,
        channel,
        writedList,
        writedData,
        oscilloSettings,
        skip = false,
        batterySkip = false;

    /* eslint-disable-next-line no-restricted-globals */
    self.addEventListener('message', (ev) => {
        if (!ev && !ev.data.sensor) return;
        const { current, sensor, frequency, timer, reading, graduate, isOscilloscope, isHiSpeed, played, reset, paused, getSaved, idle, sensorsUnits, gradOffset, abscissa, sensorList, devicesInf } =
            ev.data;

        const stream = played && !paused;
        time = timer;
        // console.log(sensor);

        // Получаем сохраненные данные
        if (getSaved) {
            sendData();
        }

        // Сброс значений (пока не знаю зачем, но вдруг пригодится)
        if (reset) {
            postMessage({ channel: [], data: [] });
            return;
        }

        try {
            if (!sensor?.length) return;

            // Разбор данных о подключенных устройствах
            const devices = JSON.parse(current);
            let devicesKeys = [...Object.keys(devices)];
            // console.log(devices);

            // Получаем ключ первоко устройства (нужно для чтения сохраненного эксперимента, в дальнейшем нужно переделать под возможность чтения из нескольких устройств)
            const firstKeys = devicesKeys[0];

            // Формируем массив из фходящей от устройства строки с обрезкой лишнего если такое пришло
            // console.log(sensor);
            // console.log(isHiSpeed);
            let sensorSplited;

            if (isHiSpeed) {
                // Высокочастотные датчики отправляющие сразу несколько строк данных в пакете
                const multyString = sensor.split('\r\n');
                multyString.pop();
                sensorSplited = multyString?.map((str) => +str.replace('\r', '').replace('\n', '').split(' ')[1]);
                // console.log(sensorSplited);
            } else {
                // Низкочастотные датчики отправляющие одну строку данных в пакете
                sensorSplited = sensor.split('\r\n')[0].split(' ');
            }

            // Сохранем в уникальныое имя датчика
            // console.log(devices[firstKeys].attributes.key);
            const sensorUnicName = reading ? sensorSplited[0] + 'readed' : isHiSpeed ? devices[firstKeys].attributes.key : sensorSplited[0];
            // console.log(sensorUnicName);
            // played && console.log(sensorSplited);
            // console.log(sensorSplited);
            // sensorSplited.forEach((element) => {
            //     console.log(element);
            // });

            // Создаем дубль первого устроиства для формирования прочтенных данных
            if (reading) {
                devices['readed'] = { ...devices[firstKeys] };
                devices['readed'].unicName = devices[firstKeys].unicName + 'readed';
                devicesKeys = !devicesKeys['readed'] && ['readed', ...devicesKeys];
            }

            // Парсинг осциллографа
            let oscilloArr = [];
            if (isOscilloscope) {
                // Сохраняем массив для осциллографа
                oscilloArr = sensor.split('ch').filter((c, i) => i !== 0);
                // Сохраняем настройки осциллографа
                oscilloSettings = {};
                let params = sensor
                    .split('ch')[0]
                    .split(' ')
                    .filter((item) => item);

                for (var item of params) {
                    let fLetter = item.charAt(0);
                    let value = +item.replace(fLetter, '');
                    switch (fLetter) {
                        case 'a':
                            oscilloSettings['rawMin'] = +value;
                            break;
                        case 'b':
                            oscilloSettings['rawMax'] = +value;
                            break;
                        case 'c':
                            oscilloSettings['ch'] = value;
                            break;
                        case 'f':
                            oscilloSettings['freq'] = value / 1000;
                            break;
                        case 'n':
                            oscilloSettings['cnum'] = value;
                            break;
                        case 'v':
                            oscilloSettings['min'] = +value;
                            break;
                        case 'w':
                            oscilloSettings['max'] = +value;
                            break;
                        default:
                    }
                }
            }

            // Обрезаем уникальное имя датчика для дальнейшей работы с массивом (у осциллографа обрезаются настройки)
            if (!isHiSpeed) {
                sensorSplited.shift();
            }

            // убираем счетчик входящих данных. сделан для изменения стэта когда данные приходят одинаковые
            if (typeof +sensorSplited[sensorSplited.length - 1] === 'number') {
                !isHiSpeed && sensorSplited.pop();
            }

            // Ищем заряд батареи
            if (sensorSplited[sensorSplited.length - 2] === 'btl') {
                devicesKeys.forEach((key) => {
                    if (devices[key].unicName === sensorUnicName) {
                        const currCharge = sensorSplited[sensorSplited.length - 1];
                        const isCharge = currCharge.includes('+') ? true : false;
                        if (!battery[key]) {
                            battery[key] = {};
                        }

                        if (battery[key]?.value !== +currCharge || battery[key]?.chardged !== isCharge) {
                            // batterySkip = false;
                        } else {
                            // batterySkip = true;
                        }

                        battery[key].chardged = isCharge;

                        // if (battery[key]?.value !== +currCharge) {
                        battery[key].value = +currCharge;
                        // batterySkip = false;
                        // }
                        // } else {
                        //     batterySkip = true;
                        //     battery[key] = null;
                    }
                });
                // Удаляем лишнее
                sensorSplited.pop();
                sensorSplited.pop();
            }
            // console.log(battery);

            // Если работает режим чтения записаного эксперимента
            if (reading && sensorSplited[0].charAt('t')) {
                time = +sensorSplited[0].replace('t', '') * 1000;
                sensorSplited.shift();
            }

            // Формируем массив из входящей строки от устройства
            let sensorArray = [];
            // console.log(devices[firstKeys]);
            if (devices[firstKeys]?.single) {
                if (isHiSpeed) {
                    let vals = '';
                    sensorSplited.forEach((e, i) => {
                        vals += e + (i === sensorSplited.length - 1 ? '' : ' ');
                    });
                    sensorArray = [`${vals}`];
                } else {
                    // console.log(sensorSplited);
                    if (sensorSplited[0]?.includes('ch')) {
                        sensorArray = sensorSplited
                            .join(' ')
                            .split('ch')
                            .filter((i) => i);
                    } else {
                        sensorArray = [`0 ${sensorSplited[0]}`];
                    }
                }
            } else {
                if (sensorSplited[0]?.includes('ch')) {
                    sensorArray = sensorSplited
                        .join(' ')
                        .split('ch')
                        .filter((i) => i);
                } else {
                    sensorArray = sensorSplited.map((e, i) => i + ' ' + e);
                }
            }
            // console.log(sensorArray);
            // console.log(Number(sensorSplited[1].replace('!', '')));
            // console.log(parseFloat(sensorSplited[1].replace('!', '')));

            // Чистим от дублей массив
            let cleanedArr = [];
            if (isOscilloscope) {
                cleanedArr = oscilloArr;
            } else {
                if (isHiSpeed) {
                    cleanedArr = sensorArray.map((e) => e?.replace(' NaN', ''));
                    // played && console.log(cleanedArr);
                } else {
                    sensorArray.forEach((el) => {
                        const val = +el.split(' ')[1]?.replace('!', '');
                        // console.log(val);
                        if (!isNaN(val)) {
                            cleanedArr.push(el);
                        }
                    });
                }
            }
            // console.log(cleanedArr);

            // Если отключился датчик - убираем из сохраненного (возможно придется доработать)
            if (devicesKeys.length < Object.keys(channelsList).length) {
                Object.keys(channelsList).forEach((key) => {
                    if (key === 'readed') return;
                    const check = devicesKeys.filter((it) => it === key)[0];
                    if (!check) {
                        delete channelsList[key];
                    }
                });
            }

            // Если нет данных в сохраненных создаем пустые массивы
            for (const key of devicesKeys) {
                if (!channelsList[key]) {
                    channelsList[key] = [];
                }
                if (!counters[key]) {
                    counters[key] = [];
                }
                if (!currentVal[key]) {
                    currentVal[key] = [];
                }
                if (!currentTime[key]) {
                    currentTime[key] = [];
                }
                if (!savedData[key]) {
                    savedData[key] = [];
                }
                if (!asideData[key]) {
                    asideData[key] = [];
                }
                if (!numsCheck[key]) {
                    numsCheck[key] = [];
                }
            }

            devicesKeys.forEach((deviceKey, d) => {
                const {
                    // graduable,
                    single,
                    children,
                    unicName,
                    attributes: { roundDigit: rounded, graduable },
                } = devices[deviceKey];
                // console.log(unicName, sensorUnicName);

                if (unicName === sensorUnicName) {
                    let deviceChannels = children?.filter((ch) => ch.name === 'channels')[0]?.children;

                    if (!channelsList[deviceKey] || channelsList[deviceKey].length !== cleanedArr.length) {
                        channelsList[deviceKey] = [];
                    }
                    if (!savedData[deviceKey]) {
                        savedData[deviceKey] = [];
                    }

                    // played && console.log(cleanedArr);
                    cleanedArr?.forEach((sens, i) => {
                        if (!idle && time === currentTime[deviceKey][i]) return;
                        const value = [];

                        // Пересчет отрицательного roundDigit
                        let roundDigit = +rounded;

                        let parse = sens.split(' ').filter((v) => v);
                        let index = +parse[0];
                        if (isHiSpeed) {
                            index = 0;
                        }
                        let modesArr = [];
                        if (isOscilloscope) {
                            parse.shift();
                        }
                        // index === 2 && console.log(sens);
                        let num = !isOscilloscope && !isHiSpeed ? +parse[0] : i;
                        let val = !isOscilloscope && !isHiSpeed ? +parse[1]?.replace('!', '') : parse;

                        if (deviceChannels && deviceChannels[i]) {
                            roundDigit = deviceChannels[i].attributes.roundDigit;
                        }

                        if (roundDigit < 0) {
                            const posRound = Math.abs(roundDigit);
                            let currValue = parseFloat(parse[1].replace('!', ''));
                            let power10 = currValue !== 0 ? Math.floor(Math.log10(Math.abs(currValue))) + 1 : posRound;
                            roundDigit = Math.max(posRound - power10, 0);
                        }
                        // console.log(+val);
                        // console.log(roundDigit);

                        let isGraded = graduable === 'true';
                        let pseudoNum = d === 0 ? num : d + 20 + num;

                        // Меняем единицы измерения
                        if (sensorsUnits[pseudoNum] && !isOscilloscope) {
                            if (isHiSpeed) {
                                val = val.map((e) => e * sensorsUnits[pseudoNum].koeff + sensorsUnits[pseudoNum].koeffB);
                            } else {
                                val = val * sensorsUnits[pseudoNum].koeff + sensorsUnits[pseudoNum].koeffB;
                            }
                        }

                        // Формулы перерасчета для канала датчика
                        if (abscissa[pseudoNum] && !isHiSpeed && !isOscilloscope) {
                            const { value, c, d } = abscissa[pseudoNum];
                            if (abscissa[pseudoNum].value.includes('d')) {
                                if (isHiSpeed) {
                                    val = val.map((e) => e * c + d);
                                } else {
                                    val = val * c + d;
                                }
                            } else {
                                const calc = val + value.replace('c', c);
                                switch (calc) {
                                    case '^2':
                                        if (isHiSpeed) {
                                            val = val.map((e) => e.pow(2));
                                        } else {
                                            val = val.pow(2);
                                        }
                                        break;

                                    case val + 'sqrt(x)':
                                        if (isHiSpeed) {
                                            val = val.map((e) => Math.sqrt(e));
                                        } else {
                                            val = Math.sqrt(val);
                                        }
                                        break;

                                    case val + 'e^':
                                        if (isHiSpeed) {
                                            val = val.map((e) => Math.exp(e));
                                        } else {
                                            val = Math.exp(val);
                                        }
                                        break;

                                    case val + '10^':
                                        if (isHiSpeed) {
                                            val = val.map((e) => 10 ^ e);
                                        } else {
                                            val = 10 ^ val;
                                        }
                                        break;

                                    case val + 'ln(x)':
                                        if (isHiSpeed) {
                                            val = val.map((e) => (Math.log(e) ? Math.log(e) : 0));
                                        } else {
                                            val = Math.log(val) ? Math.log(val) : 0;
                                        }
                                        break;

                                    case val + 'log10(x)':
                                        if (isHiSpeed) {
                                            val = val.map((e) => (Math.log10(e) ? Math.log10(e) : 0));
                                        } else {
                                            val = Math.log10(val) ? Math.log10(val) : 0;
                                        }
                                        break;

                                    default:
                                        if (isHiSpeed) {
                                            val = val.map((e) => e);
                                        } else {
                                            // fix eslint message - eval can be harmful
                                            // eslint-disable-next-line
                                            val = eval(calc);
                                        }
                                        break;
                                }
                            }
                        }

                        // Меняем значения при быстрой калибровке
                        if (gradOffset[pseudoNum] && isGraded && !isOscilloscope) {
                            val = val - gradOffset[pseudoNum];
                        }

                        if (single || deviceChannels[index]) {
                            const globalModes = devices[deviceKey]?.children[0]?.name === 'modes';

                            const {
                                attributes: { name, quantity, unit },
                                children,
                            } = single && !isOscilloscope ? devices[deviceKey] : deviceChannels[index];

                            if (isOscilloscope) {
                                // Сброс сохраненных значений
                                savedData[deviceKey][i] = [];
                                counters[deviceKey][i] = 1;
                                currentTime[deviceKey][i] = 0;

                                if (!currentVal[deviceKey][i]) {
                                    currentVal[deviceKey][i] = +val.at(-1);
                                }

                                // Доп опции для осциллографа
                                devices[deviceKey].children[0].children.forEach((item) => {
                                    const modeData = [];
                                    const { name: modeName } = item.attributes;
                                    item.children.forEach((mod, i) => modeData.push(mod.attributes.name));
                                    modesArr.push({
                                        modeName,
                                        modeData,
                                    });
                                });

                                let cropped,
                                    range = 10;
                                const { rawMin, rawMax, min, max, freq } = oscilloSettings;
                                const numParse = parse.map(Number);
                                const coeffK = (max - min) / (rawMax - rawMin);
                                const coeffB = min - ((max - min) / (rawMax - rawMin)) * rawMin;
                                const maxVal = Math.max.apply(null, numParse) - range;

                                // Подрезка массива по верхнему значению для того чтобы убрать визуальный эффект накопления данных
                                const findex = numParse.findIndex((e) => e < maxVal + range && e > maxVal - range);
                                cropped = numParse.slice(findex);

                                // пропускаем отправку если данных слишком мало
                                if (cropped.length < numParse.length / 4) {
                                    skip = true;
                                    return;
                                }

                                for (let k in cropped) {
                                    if (cropped[k]) {
                                        const rawVal = cropped[k] * coeffK + coeffB;
                                        value.push({ y: rawVal, x: k / freq, count: counters[i]++, num });
                                    }
                                }
                            } else {
                                // console.log(val);

                                if (!counters[deviceKey][i]) {
                                    counters[deviceKey][i] = 1;
                                }
                                if (!savedData[deviceKey][i]) {
                                    savedData[deviceKey][i] = [];
                                }
                                if (!currentVal[deviceKey][i]) {
                                    currentVal[deviceKey][i] = val.toFixed(roundDigit);
                                }
                                // Сброс сохраненных значений
                                if (time === 0) {
                                    savedData[deviceKey][i] = [];
                                    counters[deviceKey][i] = 1;
                                    currentVal[deviceKey][i] = val.toFixed(roundDigit);
                                }

                                // Доп опции для отдельного канала
                                if (children.length || globalModes) {
                                    const chModes = globalModes ? devices[deviceKey]?.children[0].children : children[0].children;
                                    chModes.forEach((item, i) => {
                                        const {
                                            attributes: { name, minValue, maxValue },
                                        } = item;
                                        modesArr.push({
                                            modeName: name,
                                            modeMin: minValue || i,
                                            modeMax: maxValue || i,
                                            global: globalModes,
                                        });
                                    });
                                }

                                // console.log(devices[deviceKey]);
                                // console.log(deviceChannels[index]);

                                if (isHiSpeed) {
                                    // console.log(currentTime[deviceKey][i]);
                                    const oldTime = currentTime[deviceKey][i];
                                    const timeOfset = Math.round((time - oldTime) / val.length);
                                    // console.log(val.length);
                                    if (!val.length) skip = true;
                                    // console.log(+val[0], time, oldTime);
                                    // played && console.log(oldTime, time, timeOfset, val.length);

                                    if (val.length > 1 && time > 0) {
                                        val.forEach((v, ind) => {
                                            if (played) {
                                                // console.log(oldTime + timeOfset * (ind + 1));
                                            }
                                            // console.log(+v, time, oldTime);
                                            !isNaN(+v) &&
                                                value.push({
                                                    x: oldTime + timeOfset * (ind + 1),
                                                    y: +v.toFixed(roundDigit),
                                                    num: reading ? 'loc' + num : pseudoNum,
                                                    count: counters[deviceKey][i]++,
                                                });
                                        });
                                    } else {
                                        !isNaN(+val[0]) &&
                                            value.push({
                                                x: time,
                                                y: +val[0].toFixed(roundDigit),
                                                num: reading ? 'loc' + num : pseudoNum,
                                                count: counters[deviceKey][i]++,
                                            });
                                    }
                                } else {
                                    // console.log(val);
                                    // val = round(val, roundDigit);

                                    const diffTime = time - currentTime[deviceKey][i];
                                    const offset = +(diffTime / (1000 / frequency[pseudoNum])).toFixed();

                                    if (time !== 0 && offset > 1 && !reading) {
                                        const diffVal = val > currentVal[deviceKey][i] ? val - currentVal[deviceKey][i] : currentVal[deviceKey][i] - val;
                                        const offsetTime = diffTime / offset;
                                        const offsetVal = +(diffVal / offset).toFixed(roundDigit);

                                        for (let ind = 0; ind < offset; ind++) {
                                            const newTime = +(currentTime[deviceKey][i] += offsetTime).toFixed();
                                            if (newTime < timer) {
                                                let newVal = +currentVal[deviceKey][i];

                                                if (val !== newVal) {
                                                    if (newVal > val) {
                                                        newVal = newVal - (ind ? offsetVal * ind : offsetVal);
                                                    }
                                                    if (newVal < val) {
                                                        newVal = newVal + (ind ? offsetVal * ind : offsetVal);
                                                    }
                                                }
                                                // console.log(newVal);

                                                value.push({
                                                    x: +newTime,
                                                    y: +newVal?.toFixed(roundDigit),
                                                    num: reading ? 'loc' + num : pseudoNum,
                                                    count: counters[deviceKey][i]++,
                                                });
                                            } else {
                                                value.push({
                                                    x: time,
                                                    y: +val.toFixed(roundDigit),
                                                    num: reading ? 'loc' + num : pseudoNum,
                                                    count: counters[deviceKey][i]++,
                                                });
                                            }
                                        }
                                    } else {
                                        value.push({
                                            x: time,
                                            y: +val.toFixed(roundDigit),
                                            num: reading ? 'loc' + num : pseudoNum,
                                            count: counters[deviceKey][i]++,
                                        });
                                    }
                                    // console.log(value);
                                }
                            }

                            // Формируем элемент sensorList
                            let markers = {};
                            let sensValue = [];
                            let sensInf = devicesInf.find((device) => device.key === unicName) || null;
                            // console.log(sensInf);
                            // console.log(sensorList);

                            sensorList?.forEach((sensor) => {
                                if (sensor.num === num) {
                                    markers = sensor.markers || {};
                                    sensValue = sensor.value || [];
                                    // sensInf = sensor.inf || null;
                                }
                            });
                            const obj = {
                                name,
                                quantity,
                                unit: sensorsUnits[num]?.name ? sensorsUnits[num].name : unit,
                                defaultUnit: unit,
                                roundDigit,
                                num: reading ? 'loc' + num : pseudoNum,
                                value: sensValue,
                                sensNum: num,
                                modes: modesArr,
                                // graduable,
                                frequency: frequency[pseudoNum],
                                unicName,
                                deviceKey,
                                single,
                                markers,
                                inf: sensInf,
                            };

                            // console.log(obj);

                            // Сщхранем полученное для отправки
                            // console.log(currentTime[deviceKey][i], time);
                            channelsList[deviceKey][i] = obj;
                            currentTime[deviceKey][i] = time;

                            // console.log(value);

                            if (isOscilloscope) {
                                // console.log(value);
                                savedData[deviceKey][i] = value;
                                asideData[deviceKey][i] = [value.at(-1)];
                            } else {
                                if (reading) {
                                    savedData[deviceKey][i] = [...savedData[deviceKey][i], ...value];
                                }
                                if (stream) {
                                    savedData[deviceKey][i] = [...savedData[deviceKey][i], ...value];
                                }
                                currentVal[deviceKey][i] = val;
                                asideData[deviceKey][i] = value;

                                // played && console.log(savedData[deviceKey]);
                            }
                        }
                    });
                }
            });

            sendData();
        } catch (e) {
            console.log('Connect worker message == ', e);
        }

        function sendData() {
            channel = [];
            aside = [];
            data = [];
            writedList = [];
            writedData = [];
            let saved = false;
            if (getSaved) {
                saved = true;
            }
            Object.values(channelsList).forEach((device) => {
                for (const i in device) {
                    const { deviceKey, frequency } = device[i];

                    if (deviceKey.includes('readed')) {
                        writedList = [...writedList, channelsList[deviceKey][i]];
                        writedData = [...writedData, savedData[deviceKey][i]];
                    } else {
                        channel = [...channel, channelsList[deviceKey][i]];
                        if (isOscilloscope) {
                            data = [...data, savedData[deviceKey][i]];
                        } else {
                            data = [...data, savedData[deviceKey][i]];
                            // data = stream ? [...data, savedData[deviceKey][i].slice(-(50 + frequency))] : [...data, savedData[deviceKey][i]];
                        }
                    }
                    aside = [...aside, asideData[deviceKey][i]];

                    if (!played && !reading) {
                        counters[deviceKey][i] = 1;
                        currentTime[deviceKey][i] = 0;
                    }
                }
            });

            // console.log(channel);

            !skip &&
                postMessage({
                    channel: compareFunc(channel, sensorList) ? null : channel,
                    data,
                    aside,
                    writedList,
                    writedData,
                    reading,
                    graduate,
                    played,
                    saved,
                    oscilloSettings,
                    battery: batterySkip ? null : battery,
                    sensorList,
                });
            skip = false;
            return;
        }
    });

    const isObject = (object) => {
        return object && typeof object === 'object';
    };

    const objectEqual = (object1, object2) => {
        const objKeys1 = Object.keys(object1).sort();
        const objKeys2 = Object.keys(object2).sort();

        if (objKeys1.length !== objKeys2.length) return false;

        for (let currKey of objKeys1) {
            const value1 = object1[currKey];
            const value2 = object2[currKey];

            const isObjects = isObject(value1) && isObject(value2);

            if (isObjects) {
                if (!objectEqual(value1, value2)) {
                    // console.log(2);
                    return false;
                }
            } else {
                if (value1 !== value2) {
                    // console.log(3);
                    return false;
                }
            }
        }
        return true;
    };

    const compareFunc = (a, b) => {
        // console.log(a, b);
        if (a?.length !== b?.length) {
            return false;
        } else {
            for (let i = 0; i < a.length; i++) {
                if (!objectEqual(a[i], b[i])) {
                    return false;
                }
            }
            return true;
        }
    };

    const round = (value, decimals = 2) => {
        return Number(Math.round(Number(`${value}e${decimals}`)) + `e-${decimals}`);
    };
};
