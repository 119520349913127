import React, {useState, useEffect} from "react";
import { Icon, Warning } from '../ui';
import { isAuth } from '../../helpers/auth';
import {useSelector} from "react-redux";
import { getInitiatedTasks, prepareNbiData } from './task_utils';
import {toast} from "react-toastify";
import {storageClass} from "../../redux/slices/storage";
import socket from "../../socket";
import {DB_REFRESH} from "../ui/utils/gen_utils";
import {notebookApi, notebookInfoApi} from "../../api/api";
import {NB_HOME_WORK_STATUS, NB_STATES, NB_ACTION, NB_ACT, getSubjectById, 
    getInitialSettings, getInitialRun, getInitialUsers, 
    getFinalWarning, getNotebookById, addNotebook, getNotifMsg} from './nb_utils';
import NotebookAddPage from './NotebookAddPage';
import NotebookAddSettings from './NotebookAddSettings';
import NotebookAddRun from './NotebookAddRun';
import NotebookSelectHomeWorkDlg from './NotebookSelectHomeWorkDlg';
import {getNbStorage, setNbStorage} from "./NotebookStorage";
import ProgressBar from './ProgressBar';
import { Back, ContentDesc, ContentHead, ContentTitle } from "../template/ContentParts";
import ss from "./Notebooks.module.scss";

const NotebookPages = ({history, homeWorkId, setHomeWorkId, 
        homeWorkTaskSamples, setHomeWorkTaskSamples, currTaskInd, handleActions, 
        isDisabledTemplate, setIsDisabledTemplate, isSetRunTime,
        nbState, setNbState, isSaveData, setIsSaveData, isEditable, setIsEditable,
        isTaskChanged, setIsTaskChanged, forceSaveTask, setForceSaveTask, 
        isSettingChanged, setIsSettingChanged, forceSaveSettings, setForceSaveSettings, 
        isRunChanged, setIsRunChanged, forceSaveRun, setForceSaveRun, 
        actionType, subjectId, setSubjectId
    }) => {
    const [homeWorkStatus, setHomeWorkStatus] = useState(NB_HOME_WORK_STATUS.CATALOG);
    const [homeWorkTitles, setHomeWorkTitles] = useState(null);
    const [homeWorkSettings, setHomeWorkSettings] = useState(null);
    const [homeWorkRun, setHomeWorkRun] = useState(null);
    const [homeWorkUsers, setHomeWorkUsers] = useState(null);
    const [forceComplete, setForceComplete] = useState(false);
    const [moveToStep, setMoveToStep] = useState(-1);
    const [isAutoPrompt, setIsAutoPrompt] = useState(true);
    const [isNbLoaded, setIsNbLoaded] = useState(false);
    const [objVersion, setObjVersion] = useState(0);
    const [showEditNameDlg, setShowEditNameDlg] = useState(false);

    const documentClass = useSelector(storageClass);

    useEffect(() => {
        if (homeWorkId) return;
        const data = getNbStorage();
        setHomeWorkTitles(data);
        setHomeWorkTaskSamples([]);
        setHomeWorkSettings(getInitialSettings());
        setHomeWorkRun(getInitialRun());
        setHomeWorkUsers(getInitialUsers());
        setSubjectId(data?.subjectId);
    },[homeWorkId, setHomeWorkTaskSamples, setSubjectId]);

	useEffect(() => {
		const fetchDbData = async (nbId) => {
			const data = await getNotebookById(nbId);
            setIsNbLoaded(true);
            setHomeWorkStatus(data.homeWorkStatus);
            setHomeWorkTitles(data.titles);
            setSubjectId(data.titles.subjectId);
            setNbStorage({...data.titles, homeWorkId: nbId});
            setHomeWorkTaskSamples(data.taskSamples);
            setHomeWorkSettings(data.settings);
            setHomeWorkUsers(getInitialUsers(data.users));
            const _isDisabledTemplate = !data.owner && isAuth().role < 3;
            setIsDisabledTemplate(_isDisabledTemplate);
            setIsEditable(isAuth().role === 3 || 
                (data.homeWorkStatus === NB_HOME_WORK_STATUS.CATALOG && !_isDisabledTemplate));
            setObjVersion(data.__v);

            let runOpts = getInitialRun(data.run);
            if (isSetRunTime) {  //если хотим запустить старое задание, установим начало на сегодня
                const startDate = data.run?.runFullDate ? new Date(data.run.runFullDate) : null;
                const todayDate = new Date();
                const today = todayDate.valueOf();
                const start = startDate ? startDate.valueOf() : null;
                if (!start || start < today) {
                    runOpts = getInitialRun();
                }
            }
            setHomeWorkRun(runOpts);

            if (actionType === NB_ACTION.UNDEFINED) { //after initial loading the selected nb
                const taskInd = currTaskInd === -1 && data.taskSamples.length > 0 ? 0 : currTaskInd;
                const act = getActionTypeBySampleNumber(actionType, data.taskSamples);
                handleActions({type: act, payload: taskInd});
            }
        };

        if (isNbLoaded || !homeWorkId) return;
        fetchDbData(homeWorkId);
	},[currTaskInd, homeWorkId, isNbLoaded, setSubjectId, isSetRunTime, handleActions, actionType,
       setHomeWorkTaskSamples, setIsDisabledTemplate, setIsEditable]);

    const getActionTypeBySampleNumber = (type, taskSamples) => 
        taskSamples.length === 0 || type === NB_ACTION.TASK_SAVE_ADD ? NB_ACTION.TASK_ADD : NB_ACTION.TASK_EDIT;

    useEffect(() => {
        const getHomeWork = (_homeWorkStatus, _homeWorkTitles, _homeWorkTaskSamples, 
                    _homeWorkSettings, _homeWorkRun, _homeWorkUsers, room) => {
            const homework = {
                homeWorkStatus: _homeWorkStatus,
                titles: _homeWorkTitles,
                taskSamples: _homeWorkTaskSamples,
                settings: _homeWorkSettings,
            };

            if (isAuth().role < 3) {
                homework.room = room;
                homework.run = _homeWorkRun;
                homework.users = _homeWorkUsers;
            }
    
            return homework;
        };
    
        const createStudentTasks = async (_homeWorkId, homeWorkData) => {
            const owners = homeWorkData.users.isAll ? 
                documentClass.classUsers.filter(item => item.role === 0).map(item => item._id) : 
                homeWorkData.users.selectedUsers;
            const commonTasks = getInitiatedTasks(homeWorkData);

            for (let i = 0; i < owners.length; i ++) {
                const nbiData = prepareNbiData(_homeWorkId, homeWorkData, commonTasks, 
                    documentClass._id, owners[i]);
                await notebookInfoApi.addNotebookInfoByUser(nbiData);
            }
            socket.emit('BE-refresh-db', {type: DB_REFRESH.NBI, roomId: documentClass._id});
        };
    
        const doAfterSave = (result) => {
            //1. модифицируем задание
            setIsNbLoaded(false); //to reload the object
            const act = getActionTypeBySampleNumber(actionType, result.taskSamples);
            handleActions({type: act, payload: currTaskInd});
        };

        const doAddHomeWork = async (obj, msg) => {
            await addNotebook(obj)
                .then((result) => {
                    setHomeWorkId(result._id);
                    if (msg) toast.success(msg); 
                    doAfterSave(result);
            }).catch((err) => {
                toast.error(err.response.data.errors);
            });
        };

		const doUpdateHomeWorkById = async (_homeWorkId, obj, msg) => {
            //1 - сохраняем текущее NB со статусом NB_HOME_WORK_STATUS.CATALOG
            const saveData = {...obj, homeWorkStatus: NB_HOME_WORK_STATUS.CATALOG, __v: objVersion};
            await notebookApi.updateNotebookById(_homeWorkId, saveData)
            .then((result) => {
                if (msg) toast.success(msg); 
                if (!forceComplete) doAfterSave(result);
            }).catch((err) => {
                toast.error(err.response.data.errors);
            });

            if (forceComplete) {
                //2 - если надо запустить задание, создаем новое задание со статусом NB_HOME_WORK_STATUS.PROGRESS
                //    и создаем задачи для студентов
                if (homeWorkStatus === NB_HOME_WORK_STATUS.PROGRESS && isAuth().role < 3) {
                    const saveData = {...obj, homeWorkStatus: homeWorkStatus};
                    const result = await addNotebook(saveData); // создаем новый NB
                    createStudentTasks(result._id, result, documentClass); //создаем задачи для нового NB
                }

                //3 - завершаем
                history.push('/notebooks/' + NB_ACT.SHOW_ALL_SUBJECT_NBS + '/' + subjectId);
            }
        };

        if (isSaveData) {
            const homeWorkData = getHomeWork(homeWorkStatus, homeWorkTitles, homeWorkTaskSamples, 
                homeWorkSettings, homeWorkRun, homeWorkUsers, documentClass?._id);
            homeWorkData.homeWorkStatus = NB_HOME_WORK_STATUS.CATALOG;
            const msg = getNotifMsg(forceComplete, homeWorkStatus);
            setIsSaveData(false);

            if (homeWorkId === null) { //add the home work:
                doAddHomeWork(homeWorkData, msg);
            } else { //update the home work:
                doUpdateHomeWorkById(homeWorkId, homeWorkData, msg);
            }
        }
	},[handleActions, history, homeWorkId, setHomeWorkId, currTaskInd, forceComplete, 
        homeWorkStatus, homeWorkTitles, homeWorkTaskSamples, homeWorkSettings, homeWorkRun, homeWorkUsers, 
        isSaveData, setIsSaveData, documentClass, actionType, objVersion, subjectId]);

    const handleBack = () => {
        if (nbState === NB_STATES.PAGE) 
            history.push('/notebooks/' + NB_ACT.SHOW_ALL_SUBJECT_NBS + '/' + homeWorkTitles.subjectId);
        else if (nbState === NB_STATES.SETTINGS)
            setNbState(NB_STATES.PAGE);
        else if (nbState === NB_STATES.RUN)
            setNbState(NB_STATES.SETTINGS);
    };

    const doSetHomeWorkSettings = (settings) => {
        setHomeWorkSettings(settings);
        setIsSaveData(true);
    };

    const doSetHomeWorkRun = (run) => {
        setHomeWorkRun(run);
        setIsSaveData(true);
    };
    
    const doSetHomeWorkUsers = (users) => {
        setHomeWorkUsers(users);
        setIsSaveData(true);
    };

    const getCurrTask = () => {
        if (!homeWorkId || actionType !== NB_ACTION.TASK_EDIT || currTaskInd === -1)
            return null;
        return homeWorkTaskSamples[currTaskInd];
    };

    const handleProgress = (step) => {
        if (nbState === step) return;

        if ((nbState === NB_STATES.PAGE && !isTaskChanged) ||  
            (nbState === NB_STATES.SETTINGS && !isSettingChanged) || 
            (nbState === NB_STATES.RUN && !isRunChanged)) {
            setNbState(step);
            return;
        }

        if (isTaskChanged) setForceSaveTask(true);
        if (isSettingChanged) setForceSaveSettings(true);
        if (isRunChanged) setForceSaveRun(true);
        setMoveToStep(step); // it will force to jump to the page as soon as all changes are saved
    };

    useEffect(() => { //after saving all changes, jump to selected page
        if (moveToStep === -1 || isTaskChanged || isSettingChanged || isRunChanged) return;
        setNbState(moveToStep);
        setMoveToStep(-1);
    },[moveToStep, isTaskChanged, isSettingChanged, isRunChanged, setNbState]);

    const handleEditNameItem = (name, item) => {
        setShowEditNameDlg(false);
        const titles = {...homeWorkTitles, homeWorkName: name, homeWorkItem: item};
        setHomeWorkTitles(titles);
        setIsSaveData(true);
    };

    return  (
        <>
            <ContentHead column={true} className={ss.nb__head}>
                <Back onClick={handleBack} icon="back"/>
                <ContentTitle className={ss.nb__title}>
                    <button onClick={() => setShowEditNameDlg(true)}>
                        {homeWorkTitles && <>
                            <Icon name="edit" />
                            {homeWorkTitles.homeWorkName}
                            <p>
                                <b>{getSubjectById(homeWorkTitles.subjectId)} / </b>{homeWorkTitles.homeWorkItem}
                            </p> 
                        </>}
                    </button>
                </ContentTitle>
            </ContentHead>

            <ContentDesc desc={nbState === NB_STATES.PAGE ? 
                (isAuth().role < 3 ? 'Соберите задание. Можно использовать готовые наборы вопросов или создать свои.' : "Создайте шаблон.") : 
                nbState === NB_STATES.SETTINGS ? 
                ("Настройте параметры " + (isAuth().role < 3 ? 'задания' : 'шаблона задания')) : 
                'Настройте параметры запуска задания'} 
            />

            <ProgressBar 
                progress={nbState} 
                handleProgress={handleProgress} 
                isDisabledTemplate={isDisabledTemplate}
            />

            {homeWorkStatus === NB_HOME_WORK_STATUS.PROGRESS && 
                <Warning>{getFinalWarning(homeWorkRun)}</Warning>
            }

            {nbState === NB_STATES.PAGE &&
                <NotebookAddPage 
                    currTaskInd={currTaskInd}
                    currTask={getCurrTask()}
                    setNbState={setNbState}
                    actionType={actionType}
                    isEditable={isEditable}
                    handleActions={handleActions}
                    subjectId={homeWorkTitles?.subjectId}

                    isTaskChanged={isTaskChanged}
                    setIsTaskChanged={setIsTaskChanged}
                    forceSaveTask={forceSaveTask}
                    setForceSaveTask={setForceSaveTask} 

                    isAutoPrompt={isAutoPrompt}
                    setIsAutoPrompt={setIsAutoPrompt}
                    isDisabledTemplate={isDisabledTemplate}
                />
            }

            {nbState === NB_STATES.SETTINGS &&
                <NotebookAddSettings 
                    setNbState={setNbState}
                    homeWorkSettings={homeWorkSettings}
                    setHomeWorkSettings={doSetHomeWorkSettings}
                    isSettingChanged={isSettingChanged}
                    setIsSettingChanged={setIsSettingChanged}
                    forceSaveSettings={forceSaveSettings}
                    setForceSaveSettings={setForceSaveSettings}
                    isEditable={isEditable}
                    setForceComplete={setForceComplete}
                    isDisabledTemplate={isDisabledTemplate}
                />
            }

            {nbState === NB_STATES.RUN &&
                <NotebookAddRun 
                    setNbState={setNbState}
                    setHomeWorkStatus={setHomeWorkStatus}
                    homeWorkRun={homeWorkRun}
                    setHomeWorkRun={doSetHomeWorkRun}
                    homeWorkUsers={homeWorkUsers}
                    setHomeWorkUsers={doSetHomeWorkUsers}
                    isRunChanged={isRunChanged}
                    setIsRunChanged={setIsRunChanged}
                    forceSaveRun={forceSaveRun}
                    setForceSaveRun={setForceSaveRun}
                    setForceComplete={setForceComplete}
                    isEditable={isEditable}
                />
            }

            {showEditNameDlg && <NotebookSelectHomeWorkDlg 
                dlgType={'edit'}
				showModal={showEditNameDlg}
                subjectId={subjectId}
                initName={homeWorkTitles.homeWorkName} 
                initItem={homeWorkTitles.homeWorkItem}
                doSetShowSelectHomeWorkDlg={handleEditNameItem}
                doCancelDlg={() => setShowEditNameDlg(false)}
			/>
            }
        </>
    );
}

export default NotebookPages;
