import React, {useState, useEffect} from "react";
import NotebookSelectHomeWorkDlg from './NotebookSelectHomeWorkDlg';
import {storageClass} from "../../redux/slices/storage";
import {fullName, isAuth} from "../../helpers/auth";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import socket from "../../socket";
import {CHECK_TIME_TYPE, DB_REFRESH, checkTimeRange, chkItemText, chkItemDate} from "../ui/utils/gen_utils";
import {toast} from "react-toastify";
import {SCORE_MANUAL_CHECK} from '../ui/utils/score_utils';
import {notebookApi, notebookInfoApi} from "../../api/api";
import {loadCountersNotebook, loadCountersNotebookOnSubject} from "../../redux/slices/lists";
import NotebooksStudentInitDlg from './NotebooksStudentInitDlg';
import {Tabs} from '../ui';
import {Button} from 'rlabui';
import Table from "../ui/Table/Table";
import {printOnlyDate} from "../../helpers/text";
import {NB_WORK_TAB, NB_REVIEW_TAB, NB_TABS, RESULT_TABS, NB_HOME_WORK_STATUS, NB_MODE_TYPE,
    NB_ACT, TAB_STATE, getSubjectById, addNotebook, 
    getTaskNumberByTaskSamples, getNotebooks, getAllOwnersNotebookInfoByNotebookId, subjName, 
    getNbDeleteMessage, getNbCopy } from "./nb_utils";
import SelectNotebookFilterOptions  from "./SelectNotebookFilterOptions";
import {useSelector, useDispatch} from "react-redux";
import {getNbView, setNbView}  from "../../redux/slices/tutorialslice";
import {setNbStorage} from "./NotebookStorage";
import { Back, ContentTitle, Content, ContentBody, ContentFooter, ContentHead, ContentHeader } from "../template/ContentParts";
import nb from "./Notebooks.module.scss";

const TXT_CHECK = {
    AUTO: 'Автоматическая',
    MANUAL: 'Ручная',
};
const TXT_STATUS = {
    FUTURE: 'Отложено',
	RUNNING: 'Запущено',
	BE_CHECKED: 'На проверке',
	COMPLETE: 'Проверено',
}; 

const NotebooksOnSubject = ({history, subjectId}) => {
	const [notebookTableData, setNotebookTableData] = useState([]);
	const [notebookFilteredTableData, setNotebookFilteredTableData] = useState([]);
	const [isUpdate, setIsUpdate] = useState(false);
    //
    const [nbType, setNbType] = useState(NB_WORK_TAB.ALL);
    const [resultType, setResultType] = useState(NB_REVIEW_TAB.ALL);
	const [isShowNotebookCatalog, setIsShowNotebookCatalog] = useState(true);
    //
	const [idBeDeleted, setIdBeDeleted] = useState(undefined);
    const [confirmQuestion, setConfirmQuestion] = useState('');
	const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [checkTestInUse, setCheckTestInUse] = useState(false);
    const [isTestinUse, setIsTestinUse] = useState(false);
	const [showConfirmDlg, setShowConfirmDlg] = useState(false);
    const [showSelectHomeWorkDlg, setShowSelectHomeWorkDlg] = useState(false);
    const [showCopiedNameDlg, setShowCopiedNameDlg] = useState(false);
    const [copiedNb, setCopiedNb] = useState(null);
    const [counter, setCounter] = useState(0);
    //
	const [testFilter, setTestFilter] = useState('');
	const [startDate, setStartDate] = useState(null);
	const [finishDate, setFinishDate] = useState(null);
    //
	const [showInitDlg, setShowInitDlg] = useState(false);
	const [selectedIds, setSelectedIds] = useState(undefined);
    const [subjectCounter, setSubjectCounter] = useState(0);
    
    const documentClass = useSelector(storageClass);
	const {lists: {counters}} = useSelector(state => state);
	const nbView = useSelector(getNbView);
    const dispatch = useDispatch();

	useEffect(() => {
        socket.on("FE-refresh-db", ({type}) => {
          if (type === DB_REFRESH.NBI) setCounter(counter => counter + 1);
        });
        return () => {socket.off("FE-refresh-db");};
    }, []);

	useEffect(() => {
        dispatch(loadCountersNotebookOnSubject(documentClass._id)); 
	}, [dispatch, documentClass._id]);
    
	useEffect(() => {
		const sc = counters.notebookOnSubject.find(item => item._id === subjectId);
        setSubjectCounter(sc ? sc.count : 0);
        //notify={tab.status === OT_MODE_TYPE.HISTORY ? subjectCounter : 0}
        //debugger
	}, [counters, subjectId]);

    useEffect(() => {
		const isCatalog = nbView.find(item => item.ind === subjectId).isCatalog;
		setIsShowNotebookCatalog(isCatalog);
    }, [nbView, subjectId]);

    useEffect(() => {
        const getUncheckedResults = async (notebookId) => {
            const result = await getAllOwnersNotebookInfoByNotebookId(notebookId);
            return result.data.filter(item => item.score === SCORE_MANUAL_CHECK).length;
        };

        const fetchData = async () => {
			const id = isAuth()._id;
            let data = await getNotebooks(id, documentClass._id);
            if (isShowNotebookCatalog) {
				//оставляем только новые задания
                data = data.filter(item => item.homeWorkStatus === NB_HOME_WORK_STATUS.CATALOG &&
                    item.titles.subjectId === subjectId);
            } else {
				//оставляем только запущенные или завершенные задания
                data = data.filter(item => item.homeWorkStatus !== NB_HOME_WORK_STATUS.CATALOG &&
                    item.titles.subjectId === subjectId);
            }

            const list = [];
            for (let i = 0; i < data.length; i ++) {
                const nb = data[i];

                let uncheckedNum = '';
                let beManuallyScored = false;
                if (!nb.settings.isAutoScore) {
                    uncheckedNum = await getUncheckedResults(nb._id);
                    beManuallyScored = uncheckedNum !== 0;
                }
    
                let status = ''; 
                if (!isShowNotebookCatalog) {
                    status = checkTimeRange(nb.run.runFullDate, nb.run.completeFullDate, 
                        CHECK_TIME_TYPE.BEFORE) ? TXT_STATUS.FUTURE : 
                        checkTimeRange(nb.run.runFullDate, nb.run.completeFullDate, 
                        CHECK_TIME_TYPE.INSIDE) ? TXT_STATUS.RUNNING :
                        uncheckedNum > 0 ? TXT_STATUS.BE_CHECKED : TXT_STATUS.COMPLETE;
                }

                const record = {
                        nameLink: nb.titles.homeWorkName,
                        item: nb.titles.homeWorkItem ? nb.titles.homeWorkItem : '',
                        author: fullName(nb.owner, 'Шаблон'),
                        dateStart: nb.run ? printOnlyDate(nb.run.runFullDate) : '',
                        dateFinish: nb.run ? printOnlyDate(nb.run.completeFullDate) : '',
                        checkType: nb.settings.isAutoScore ? TXT_CHECK.AUTO : TXT_CHECK.MANUAL,
                        tasks: getTaskNumberByTaskSamples(nb.taskSamples)  + ' шт',
                        uncheckedNumLink: uncheckedNum > 0 ? uncheckedNum : '',
                        status: status,
                        actions: '',
                        id: nb._id,
                        owner: nb.owner,
                        //
                        beManuallyScored: beManuallyScored,
                        filter_dateStart: nb.run ? nb.run.runFullDate : '',
                        filter_dateFinish: nb.run ? nb.run.completeFullDate : '',
                };
    
                list.push(record);
            }
    
			setNotebookTableData(list);
			setIsUpdate(false);
        }

		fetchData();
	},[documentClass, isShowNotebookCatalog, subjectId, isUpdate, counter]);

    const handleSelectTab = tab => {
        if (isShowNotebookCatalog) 
            setNbType(tab);
        else 
            setResultType(tab);
    };

    useEffect(() => {
        const checkRowDates = (row, nbSelect, filterStartDate, filterFinishDate, beManuallyScored) => {
            const todayDate = new Date();
            const today = todayDate.valueOf();
            const nbStartDate = new Date(row.filter_dateStart);
            const start = nbStartDate.valueOf();
            let nbFinishDate = new Date(row.filter_dateFinish);
            const finish = nbFinishDate.valueOf();
    
            const isFuture = today <= start;
            const isManuallyScored = beManuallyScored && !isFuture;
    
            //1 - check criteria for the result tabs:
            let found = nbSelect === NB_REVIEW_TAB.ALL;
            if (nbSelect === NB_REVIEW_TAB.RUNNING && today < finish) {
                found = true;
            } else if (nbSelect === NB_REVIEW_TAB.HISTORY && today >= finish) {
                found = true;
            } else if (nbSelect === NB_REVIEW_TAB.BE_CHECKED && isManuallyScored) {
                found = true;
            }
        
            //2 - check filtering settings for start date
            if (found && filterStartDate && !chkItemDate(row.filter_dateStart, filterStartDate, true)) {
                found = false;
            } else if (found && filterFinishDate && !chkItemDate(row.filter_dateFinish, filterFinishDate, false)) {
                found = false;
            }
            
            return found;
        };
    
        let list = [];
        if (isShowNotebookCatalog) {
            if (nbType === NB_WORK_TAB.TEMPLATES) {
                list = notebookTableData.filter(item => !item.owner);
            } else if (nbType === NB_WORK_TAB.WORKS) {
                list = notebookTableData.filter(item => item.owner);
            } else {
                list = notebookTableData;
            }
        } else {
            for (let i = 0; i < notebookTableData.length; i ++) {
                const record = notebookTableData[i];
                const beManuallyScored = record.beManuallyScored;
                let found = checkRowDates(record, resultType, startDate, finishDate, beManuallyScored);

                if (found && (chkItemText(record, 'nameLink', testFilter) || chkItemText(record, 'item', testFilter))) {
                    list.push(record);
                }
            }
        }

        setNotebookFilteredTableData(list);
    }, [notebookTableData, testFilter, startDate, finishDate, 
        resultType, isShowNotebookCatalog, nbType]);

	useEffect(() => {
        const deleteNotebookAndAllDataByNbId = async () => {
            await await notebookInfoApi.deleteNotebookInfoByNotebookId(idBeDeleted);
            await notebookApi.deleteNotebookById(idBeDeleted);
            setIsUpdate(true);
            socket.emit('BE-refresh-db', {type: DB_REFRESH.NBI, roomId: documentClass._id});
            dispatch(loadCountersNotebook(documentClass._id)); 
            dispatch(loadCountersNotebookOnSubject(documentClass._id)); 
    
            setDeletionConfirmed(false);
            setIdBeDeleted(undefined);
            setIsTestinUse(false);
            setCheckTestInUse(false);
        };

		if (!deletionConfirmed) return;
        deleteNotebookAndAllDataByNbId(); 
	}, [deletionConfirmed, idBeDeleted, isTestinUse, dispatch, documentClass]);

    useEffect(() => {
		if (!checkTestInUse) return;
        setShowConfirmDlg(true);
    }, [checkTestInUse, idBeDeleted]);
    
	const handleEditTest = id => {
		setNbStorage({});
        history.push('/notebooks/' + NB_ACT.ADD_EDIT_NB + '/' + id);
	};

	const handleSetTestStartTime = id => { //переход к последнему табу
        history.push('/notebooks/' + NB_ACT.SET_RUN_TIME + '/' + id);
	};

    const canEditIDs = () => notebookFilteredTableData.filter(item => isAuth().role === 3 || item.author !== 'Шаблон').map(item => item.id);

    //copying: step 1 - create a new nb
	const handleStartCopyNb = id => {
        getNbCopy(id, documentClass._id, setCopiedNb);
	};
    //copying: step 2 - open the dlg to update name and item
	useEffect(() => {
		if (!copiedNb) return;
        setShowCopiedNameDlg(true);
	}, [copiedNb]);
    //copying: step 3 - save the updated name and item
    const handleCompleteCopyNb = (name, item) => {
        const doCopy = async () => {
            setShowCopiedNameDlg(false);
            copiedNb.titles.homeWorkName = name;
            copiedNb.titles.homeWorkItem = item;
            //save the nb copy
            await addNotebook(copiedNb);
            setIsUpdate(true);
            setCopiedNb(null);
            toast.success("Рабочая тетрадь скопирована."); 
        };
        doCopy();
    };
    //copying: step 4 - close the dlg if cancel
    const doCloseCopiedNameDlg = () => {
        setCopiedNb(null);
        setShowCopiedNameDlg(false);
    };

    //create a new nb: step 1 - create name and item
    const doCreateNewNotebook = (homeWorkName, homeWorkItem) => {
        const data = {
            subjectId: subjectId,
            homeWorkName: homeWorkName,
            homeWorkItem: homeWorkItem
        };
        setNbStorage(data);
        setShowSelectHomeWorkDlg(false);
        history.push('/notebooks/' + NB_ACT.ADD_EDIT_NB);
    };
    //create a new nb: step 2 - close the dlg if cancel
    const doCloseHomeWorkDlg = () => {
        setShowSelectHomeWorkDlg(false);
    };

	const handleRequestDeleteTest = id => {
		const getMsg = async () => {
			const msg = await getNbDeleteMessage(id);
            setIdBeDeleted(id);
            setConfirmQuestion(msg);
            setCheckTestInUse(true);
            };
		getMsg();
	};

	const handleDeleteTestNo = id => {
		setShowConfirmDlg(false);
		setDeletionConfirmed(false);
		setIdBeDeleted(undefined);
		setCheckTestInUse(false);
	};

	const handleDeleteTestYes = () => {
		setShowConfirmDlg(false);
		setDeletionConfirmed(true);
	};

    const handleLink = id => {
        if (!isShowNotebookCatalog)
            history.push('/notebooks/' + NB_ACT.REVIEW_NB_RUN + '/' + id);
        else 
            handleEditTest(id);
    };

	const handleOpenInitDlg = nbId => { //teacher preview
		setSelectedIds('' + nbId + '|'); //2й параметр (nbiId) должен быть пустым, если предпросмотр
		setShowInitDlg(true);
	};

	const handleExecutePreview = nbId => {
	    history.push('/notebooks/' + NB_ACT.RUN_TEST + '/' + selectedIds);
	};	

	const handleTab = (isCatalog) => {
		setIsShowNotebookCatalog(isCatalog);
		const view = nbView.map(item => item.ind !== subjectId ? item : 
            {ind: subjectId, isCatalog: isCatalog});
		dispatch(setNbView(view));
	};

	const specialCellStyle = (item, rowInd, colInd, row) => {
		const NON_CHECKED_RESULT_COL = 7;
        if (!isShowNotebookCatalog) {
            if (colInd === NON_CHECKED_RESULT_COL) return {color: 'red'};
            if (item.includes(TXT_CHECK.AUTO)) return {color: 'green'};
            if (item.includes(TXT_CHECK.MANUAL)) return {color: '#C96203'};
            if (item.includes(TXT_STATUS.BE_CHECKED)) return {color: '#C96203'};
            if (item.includes(TXT_STATUS.COMPLETE)) return {color: 'green'};
        }

		return {};
	};

    const getHomeWorkTable = () => {
        const headerRunning = [
            {column: 'Название', name: 'nameLink', style: { width: '15%'} }, 
            {column: 'Тема', name: 'item', style: { width: '15%'} }, 
            {column: 'Автор', name: 'author', style: { width: '10%'} }, 
            {column: 'Дата начала', name: 'dateStart', style: { width: '8%'} }, 
            {column: 'Дата окончания', name: 'dateFinish', style: { width: '8%'} }, 
            //{column: 'Количество заданий', name: 'tasks', style: { width: '10%'} }, 
            {column: 'Вид проверки', name: 'checkType', style: { width: '10%'} }, 
            {column: 'Статус', name: 'status', style: { width: '10%'} }, 
			{column: 'Непроверенные результаты', name: 'uncheckedNumLink', style: { width: '10%'} }, 
            {column: 'Действия  ', name: 'actions', style: { width: '14%'} }, 
            {column: 'id', name: 'id', style: { width: '0%'} }
        ];
    
        const headerCatalog = [
            {column: 'Название', name: 'nameLink', style: { width: '25%'} }, 
            {column: 'Тема', name: 'item', style: { width: '20%'} }, 
            {column: 'Автор', name: 'author', style: { width: '10%'} }, 
            {column: 'Дата начала', name: 'dateStart', style: { width: '15%'} }, 
            {column: 'Количество заданий', name: 'tasks', style: { width: '20%'} }, 
            {column: 'Действия', name: 'actions', style: { width: '10%'} }, 
            {column: 'id', name: 'id', style: { width: '0%'} }
        ];
    
		const actionRunning = {
            handleEdit: id => handleEditTest(id),
            canEditIDs: canEditIDs(),
            handleCopy: id => handleStartCopyNb(id),
            handleDelete: id => handleRequestDeleteTest(id),
            canDeleteIDs: canEditIDs(),
        };

        let actionCatalog = actionRunning;
        if (isAuth().role < 3) {
    		actionCatalog =  {
                ...actionRunning,
                handleRun: id => handleSetTestStartTime(id),
                tooltipRun: 'Установите время задания и запустите его',
                canRunIDs: canEditIDs(),
				handlePreview: id => handleOpenInitDlg(id),
            };
        }

		return (
		<div className={nb.nb__table}>
			<Table 
				table={{
                    header: !isShowNotebookCatalog ? headerRunning : headerCatalog, 
					data: notebookFilteredTableData,
                    specialCellStyle: specialCellStyle
				}}
				sort={{
                    hasSorting: true, 
                    initSortInd: -4
                }}
                link={{handleLink: id => handleLink(id)}}
                actions={!isShowNotebookCatalog ? actionRunning : actionCatalog}
			/>
		</div>
		);
    };

    let noTasks = false;
    if (notebookTableData.length === 0) {
        noTasks = true;
    }

    return  (
        <>
            <ContentHead column={true}>
                <Back onClick={() => history.push('/notebooks/' + NB_ACT.SHOW_ALL_NBS)} icon="back" />
                {!noTasks && <>
                    <ContentTitle>{'Предмет ' + getSubjectById(subjectId)}</ContentTitle>
                    {isAuth().role < 3 &&
                        <Tabs>
                            {TAB_STATE.map(tab => (
                                <Tabs.Item 
                                    active={Number(isShowNotebookCatalog) === tab.status}
                                    onClick={() => handleTab(!!tab.status)}
                                    key={tab.status}
                                    notify={tab.status === NB_MODE_TYPE.EXECUTED ? subjectCounter : 0}
                                >
                                    {tab.name}
                                </Tabs.Item>
                            ))}
                        </Tabs>
                    }
                </>}
            </ContentHead>

            <Content background={!noTasks}>
                {notebookTableData.length !== 0 && 
                <ContentHeader>
                    <SelectNotebookFilterOptions
                            isShowNotebookCatalog={isShowNotebookCatalog}
                            textPrompt={'Введите название или тему задания'}
                            testFilter={testFilter}
                            setTestFilter={setTestFilter}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            finishDate={finishDate}
                            setFinishDate={setFinishDate}
                            tabs={isShowNotebookCatalog ? NB_TABS : RESULT_TABS}
                            tabId={isShowNotebookCatalog ? nbType : resultType}
                            handleSelectTab={handleSelectTab}
                            subjectCounter={subjectCounter}
                    />
                </ContentHeader>
                }

                <ContentBody scrolled={!noTasks} padding={!noTasks} >
                    <div className={nb.nb__wrap}>
                        <div className={nb.nb__body}>
                            {noTasks ? 
                                <div className={nb.center}>
                                    <div className={nb.info}>
                                        <div className={nb.info_title}>
                                            {"У вас пока нет " + (isShowNotebookCatalog ? 'созданных ' : 'выполненных  ') + 
                                            (isAuth().role < 3 ? "заданий" : "шаблонов") + 
                                            " по предмету " + getSubjectById(subjectId)}.
                                        </div>

                                        {isShowNotebookCatalog &&
                                        <div className={nb.info_description}>
                                            {"Создайте " + subjName() + " по выбранному предмету."}
                                            <br></br>
                                            Вы в любой момент сможете изменить его.
                                        </div>
                                        }
                                        {isShowNotebookCatalog &&
                                        <div className={nb.info_button}>
                                            <Button onClick={() => setShowSelectHomeWorkDlg(true)}>
                                                {"Создать " + subjName()}
                                            </Button>
                                        </div>
                                        }
                                    </div>
                                </div>
                                : 
                                <>
                                    {getHomeWorkTable()}
                                </>
                            }
                        </div>
                    </div>
                </ContentBody>

                {!noTasks && 
                    <ContentFooter>
                        <Button onClick={() => setShowSelectHomeWorkDlg(true)}>
                            Создать {subjName()}
                        </Button>
                    </ContentFooter>
                }
            </Content>
      
            {showSelectHomeWorkDlg && 
			<NotebookSelectHomeWorkDlg 
                dlgType={'new'}
				showModal={showSelectHomeWorkDlg}
                subjectId={subjectId}
                initName={''} 
                initItem={''}
                doSetShowSelectHomeWorkDlg={doCreateNewNotebook}
                doCancelDlg={doCloseHomeWorkDlg}
			/>
            }

            {showCopiedNameDlg && 
			<NotebookSelectHomeWorkDlg 
                dlgType={'copy'}
				showModal={showCopiedNameDlg}
                subjectId={subjectId}
                initName={copiedNb?.titles?.homeWorkName} 
                initItem={copiedNb?.titles?.homeWorkItem}
                doSetShowSelectHomeWorkDlg={handleCompleteCopyNb}
                doCancelDlg={doCloseCopiedNameDlg}
			/>
            }

            {showConfirmDlg &&
			<ModalConfirmDialog
				showConfirmDlg={showConfirmDlg} 
				handleNo={handleDeleteTestNo}
				handleYes={handleDeleteTestYes}
				question={confirmQuestion}
				btnTextYes={'Точно удалить'}
				btnTextNo={'Оставить'}
				redWarning={true}
			/>
            }

            {showInitDlg && 
                <NotebooksStudentInitDlg
                    showDlg={showInitDlg}
                    setShowDlg={setShowInitDlg}
                    selectedIds={selectedIds}
                    handleExecuteTest={handleExecutePreview}
                />
            }
        </>
    );
}

export default NotebooksOnSubject;
//Dima - 2 Nob 2024 16-01 - New counters