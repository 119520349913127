import React, { useState, useEffect } from "react";
import Table from "../ui/Table/Table";
import {getLabWorkArraysAndNames} from "../practicum/lw_utils";
import {useSelector} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {isAuth} from "../../helpers/auth";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import labsList from '../practicum/subjectList';
import {printOnlyDate} from "../../helpers/text";
import {vrLabInfoApi} from "../../api/api";
import socket from "../../socket";
import {DB_REFRESH} from "../ui/utils/gen_utils";

const ProfileVirtLabTable = ({profile}) => {
	const [vrLabData, setVrLabData] = useState([]);
    const [showConfirmDlg, setShowConfirmDlg] = useState(false);
	const [idBeDeleted, setIdBeDeleted] = useState(undefined);
	const [deletionConfirmed, setDeletionConfirmed] = useState(false);
	const [counter, setCounter] = useState(0);

	const documentClass = useSelector(storageClass);

	useEffect(() => {
		socket.on("FE-refresh-db", ({type}) => {
			if (type === DB_REFRESH.VRL) setCounter(counter => counter + 1);
		});
		return () => {socket.off("FE-refresh-db");};
	}, []);
	
	useEffect(() => {
		const loadData = async () => {
            const result = await vrLabInfoApi.getVrLabInfoByOwnerId(profile._id);
			if (!result) return;
            const [,sect,] = getLabWorkArraysAndNames(labsList.subjectList, '01-physics', '04-optics', undefined);
            const sectionLabList = sect.labList;
            
            const list = [];
            for (let i = 0; i < result.data.length; i ++) {
                const item = result.data[i];
                list.push({
                    gameInd: item.vrLabInd, 
                    name: sectionLabList[item.vrLabInd-1].labName,
					result: item.step + ' из ' + item.stepAmount,
					progress: item.step === 0 ? 'Не начата' : item.isComplete ? 'Готово' : 'Начата',
					date: printOnlyDate(item.updatedAt), 
                    actions: '',
                    id: item._id
                });
            }
			setVrLabData(list);
    	};

		loadData();
    }, [profile._id, counter]);

	const handleDelete = id => {
		setIdBeDeleted(id);
		setShowConfirmDlg(true);
	};
	const handleDeleteNo = () => {
		setShowConfirmDlg(false);
		setIdBeDeleted(undefined);
	};
	const handleDeleteYes = () => {
		setShowConfirmDlg(false);
		setDeletionConfirmed(true);
	};

	const canDeleteIDs = () => {
		if (isAuth().role === 0) return [];
		return vrLabData.map(item=> item.id);
	};

    useEffect(() => {
		if (!deletionConfirmed) return;
		vrLabInfoApi.deleteVrLabInfoById(idBeDeleted)
		.then(res => {
			setDeletionConfirmed(false);
			setIdBeDeleted(undefined);
			socket.emit('BE-refresh-db', {type: DB_REFRESH.VRL, roomId: documentClass._id});
		});
	}, [deletionConfirmed, idBeDeleted, documentClass]);

    const getTableHeader = () => {
		if (isAuth().role === 0) {
			return [
				{column: 'Игра N', name: 'gameInd', style: { width: '10%'} }, 
				{column: 'Название', name: 'name', style: { width: '35%'} }, 
				{column: 'Результаты', name: 'result', style: { width: '20%'}}, 
				{column: 'Прогресс выполнения', name: 'progress', style: { width: '20%'}},
				{column: 'Дата', name: 'date', style: { width: '15%'}}, 
				{column: 'id', name: 'id', style: { width: '0%'} }, 
			];
		} else {
			return [
				{column: 'Игра N', name: 'gameInd', style: { width: '10%'} }, 
				{column: 'Название', name: 'name', style: { width: '35%'} }, 
				{column: 'Результаты', name: 'result', style: { width: '15%'}}, 
				{column: 'Прогресс выполнения', name: 'progress', style: { width: '15%'}},
				{column: 'Дата', name: 'date', style: { width: '15%'}}, 
				{column: 'Действия', name: 'actions', style: { width: '10%'} }, 
				{column: 'id', name: 'id', style: { width: '0%'} }, 
			];
		}
    };

    return ( 
		<div>
            <Table
                table={{
                    header: getTableHeader(), 
                    data: vrLabData,
                }}
                sort={{
                    hasSorting: true, 
                    initSortInd: 1,
                }}
				actions={{
					handleDelete: ids => handleDelete(ids),
					canDeleteIDs: canDeleteIDs(),
				}}
            />

            {showConfirmDlg && <ModalConfirmDialog
				showConfirmDlg={showConfirmDlg} 
				handleNo={handleDeleteNo}
				handleYes={handleDeleteYes}
				question={'Вы действительно хотите удалить эту работу?'}
			/>
			}
		</div>
    )
}

export default ProfileVirtLabTable;
