import { NB_TEMPLATE_TYPE, NB_COMPLEXITY, NB_SAMPLE_TYPE} from './nb_utils';

/*
СПЕЦИАЛЬНРЫЕ СИМВОЛЫ:
@0, @1, ...  - параметры
@+0, ...    параметры, которые писать обязательно
$0, $1, ... - знаки
$-0, $-1, ... - знаки, которые игнорируются, если число после него положительное

^+   - поднять текст наполовину до ^. или до конца строки
^-   - опустить текст наполовину до ^. или до конца строки

Пример:
formulas: ['@0x0^+2^+ $1@1x1 $2@2 = 0'], //$1 means sign + or -
2x2 + 7x + 3 = 0  => @0x^+2^+ $1  @1x   @2 = 0 (например @0 = +2, @1 = +7, @2 = +3")
task: Найти корни квадратного уравнения.
find: x0, x1;
params: 3
complexity: 0       x = a 'simple'        a = [-10, 10]
complexity: 1       x = a / b             a = [-10, 10]       b = [-4, 4]
complexity: 2       x = 'ratio' (a / b)   a = [-20, 20]       b = [-20, 20]
*/

const A0 = [0, 0], A1 = [0, 1], A2 = [0, 2];
const B0 = [1, 0], B1 = [1, 1], B2 = [1, 2];

//============================================================================
const text_mechan_01 = {
    templateTypeId: NB_TEMPLATE_TYPE.PHYSICS_MECHANICS,
    sampleTypeId: NB_SAMPLE_TYPE.CALC_PHYSICS_TASK, //TEXT_TASK,
    templateId: 'sdnn46mc8d',
    name: 'Лаб. работа №2. Измерение объема твердого тела',
    task: 'Решите задачу.',
    isAnySign: false, //значения коээфициентов могут быть только положительными
    showDecimalNumber: 2, //кол-во знаков после запятой при выводе
    coefNumber: 2, //кол-во используемых коэффициентов a0/b0
    coefRanges: [
        {id: NB_COMPLEXITY.LOW,    topRange: [2, 7], bottomRange: [1, 1]}, 
        {id: NB_COMPLEXITY.MEDIUM, topRange: [4, 14], bottomRange: [1, 1]},
        {id: NB_COMPLEXITY.HIGH,   topRange: [5, 80], bottomRange: [10, 10]},
    ],
    formulas: [ //по формуле V = πR^+2^+h
        {
            formula: 'Найдите объем цилиндра, имеющего r = @0 см и h = @1 см.', 
            units: 'см^+3^+',
            answerName: 'V =',
            calcSolutionRules: [
                { 
                    factorMethod: 'values',
                    factor: {
                        topValue: { action: '*', values: [A0, A0, A1], multiply: 314},
                        bottomValue: { action: '*', values: [B0, B0, B1], multiply: 100},
                    },
                }
            ],
        },

        { 
            formula: 'Найдите объем шарика, диаметр которого равен @0 см.', 
            units: 'см^+3^+', //по формуле V = #4/3#πR^+3^+
            answerName: 'V =',
            calcSolutionRules: [ 
                { 
                    factorMethod: 'values',
                    factor: {
                        topValue: { action: '*', values: [A0, A0, A0], multiply: 4 * 314},
                        bottomValue: { action: '*', values: [B0, B0, B0], multiply: 3 * 100},
                    },
                }
            ],
        },

        {
            formula: 'Чему будет равен объем цилиндрического тела, если его r = @0 см, а h = @1 см.', 
            units: 'см^+3^+', // по формуле V = πR^+2^+h
            answerName: 'V =',
            calcSolutionRules: [
                { 
                    factorMethod: 'values',  //special case: we use not coeffs a0/b0,... but a0, b0, b1, ...
                    factor: {
                        topValue: { action: '*', values: [A0, A0, A1], multiply: 314},
                        bottomValue: { action: '*', values: [B0, B0, B1], multiply: 100},
                    },
                }
            ],
        },

        {
            formula: 'Найдите объем кубика, ребро которого равно @0 см.', 
            units: 'см^+3^+', //по формуле V = R^+3^+
            answerName: 'V =',
            calcSolutionRules: [ 
                { 
                    factorMethod: 'values',
                    factor: {
                        topValue: { action: '*', values: [A0, A0, A0]},
                        bottomValue: { action: '*', values: [B0, B0, B0]},
                    },
                }
            ],
        },
    ],
};


//============================================================================
const text_molphys_termodyn_01 = {
    templateTypeId: NB_TEMPLATE_TYPE.PHYSICS_MOLECULAR_TERMODYNAMICS,
    sampleTypeId: NB_SAMPLE_TYPE.CALC_PHYSICS_TASK, //TEXT_TASK,
    templateId: 'cl9/v034ch',
    name: 'Лаб. работа №2. Измерение плотности вещества',
    task: 'Решите задачу.',
    isAnySign: false, //значения коээфициентов могут быть только положительными
    showDecimalNumber: 2, //кол-во знаков после запятой при выводе
    coefNumber: 2, //кол-во используемых коэффициентов a0/b0
    coefRanges: [
        {id: NB_COMPLEXITY.LOW,    topRange: [2, 7], bottomRange: [1, 1]}, 
        {id: NB_COMPLEXITY.MEDIUM, topRange: [4, 14], bottomRange: [1, 1]},
        {id: NB_COMPLEXITY.HIGH,   topRange: [5, 80], bottomRange: [10, 10]},
    ],
    formulas: [
        {
            formula: 'Найдите плотность жидкости, если её m = @0 г, а V = @1 см^+3^+.', 
            units: 'г/см^+3^+', //по формуле ρ=m/V
            answerName: 'ρ =',
            calcSolutionRules: [
                { 
                    factorMethod: 'values',
                    factor: {
                        topValue: { action: '*', values: [A0, B1]},
                        bottomValue: { action: '*', values: [B0, A1]},
                    },
                }
            ],
        },

        {
            formula: 'Найдите плотность жидкости, если её V = @1 л, а m = @0кг', 
            units: 'кг/м^+3^+', //по формуле ρ=m/V
            answerName: 'ρ =',
            calcSolutionRules: [
                { 
                    factorMethod: 'values',
                    factor: {
                        topValue: { action: '*', values: [A0, B1]}, //, multiply: 18
                        bottomValue: { action: '*', values: [B0, A1]}, //, multiply: 10
                    },
                }
            ],
        },

        {
            formula: 'Найдите объем оловянного бруска массой @0г.', 
            units: 'cм^+3^+', //по формуле V=m/ρ // Плотность олова 7,3 г/cм^+3^+
            answerName: 'V =',
            calcSolutionRules: [
                { 
                    factorMethod: 'values',
                    factor: {
                        topValue: { action: '*', values: [A0], multiply: 10},
                        bottomValue: { action: '*', values: [B0], multiply: 73}, 
                    },
                }
            ],
        },

        {
            formula: 'Масса детали m = @0г, а ее плотность @1г/cм^+3^+. Найдите объем детали.', 
            units: 'cм^+3^+', // по формуле V=m/ρ
            answerName: 'V =',
            calcSolutionRules: [
                { 
                    factorMethod: 'values',
                    factor: {
                        topValue: { action: '*', values: [A0, B1]},
                        bottomValue: { action: '*', values: [B0, A1]}, 
                    },
                }
            ],
        },

        {
            formula: 'Найдите массу латунного цилиндра, V которого @0cм^+3^+.', 
            units: 'г', //по формуле m=Vρ // Плотность латуни 8,73г/cм^+3^
            answerName: 'm =',
            calcSolutionRules: [
                { 
                    factorMethod: 'values',
                    factor: {
                        topValue: { action: '*', values: [A0], multiply: 87},
                        bottomValue: { action: '*', values: [B0], multiply: 10}, 
                    },
                }
            ],
        },

    ],
};


//============================================================================
const text_molphys_termodyn_02 = {
    templateTypeId: NB_TEMPLATE_TYPE.PHYSICS_MOLECULAR_TERMODYNAMICS,
    sampleTypeId: NB_SAMPLE_TYPE.CALC_PHYSICS_TASK,
    templateId: 'sd3wwxmcfd',
    name: 'Лаб. работа №11. Исследование уравнения теплового баланса',
    task: 'Решите задачу.',
    isAnySign: false, //значения коээфициентов могут быть только положительными
    showDecimalNumber: 2, //кол-во знаков после запятой при выводе
    coefNumber: 3, //кол-во используемых коэффициентов a0/b0
    coefRanges: [
        {id: NB_COMPLEXITY.LOW,    topRange: [2, 20], bottomRange: [1, 1]}, 
        {id: NB_COMPLEXITY.MEDIUM, topRange: [4, 40], bottomRange: [1, 1]},
        {id: NB_COMPLEXITY.HIGH,   topRange: [5, 80], bottomRange: [10, 10]},
    ],
    formulas: [
        {
            formula: 'Найдите Q, отданное водой стакану, если температура воды понизилась на @0°С, а m воды равна @1г. ',
            units: 'Дж', //Воспользуйтесь формулой Q=c·m·∆Т. Удельная теплоемкость воды c равна 4.2 Дж/(г·°C)
            answerName: 'Q =',
            calcSolutionRules: [
                { 
                    factorMethod: 'values',
                    factor: {
                        topValue: { action: '*', values: [A0, A1], multiply: 42},
                        bottomValue: { action: '*', values: [B0, B1], multiply: 10},
                    },
                }
            ],
        },

        {
            formula: 'Найдите Q, необходимое для нагревания @0кг воды на @1°С. ', 
            units: 'Дж', //Воспользуйтесь формулой Q=c·m·∆Т. Удельная теплоемкость воды c равна 4200 Дж/(кг·°C)
            answerName: 'Q =',
            calcSolutionRules: [
                { 
                    factorMethod: 'values',
                    factor: {
                        topValue: { action: '*', values: [A0, A1], multiply: 4200},
                        bottomValue: { action: '*', values: [B0, B1]},
                    },
                }
            ],
        },

        {
            formula: 'На сколько изменится температура воды, объем которой @1cм^+3^+, если ей передать @0 Дж теплоты? ', 
            units: '°C', //Воспользуйтесь формулой ∆Т=Q/(c·m). Удельная теплоемкость воды c равна 4.2 Дж/(г·°C)
            answerName: '∆Т =',
            calcSolutionRules: [
                { 
                    factorMethod: 'values',
                    factor: {
                        topValue: { action: '*', values: [A0, B1], multiply: 10},
                        bottomValue: { action: '*', values: [B0, A1], multiply: 42},
                    },
                }
            ],
        },

        {
            formula: 'Найдите удельную теплоемкость предмета, если нагретый предмет, охлаждаясь в воде на @2°С передает ей @0 кДж. Масса предмета @1кг. ',
            units: 'кДж/кг°C', //'Воспользуйтесь формулой c=Q/(∆Т·m).'
            answerName: '∆Т =',
            calcSolutionRules: [
                { 
                    factorMethod: 'values',
                    factor: {
                        topValue: { action: '*', values: [A0, B1, B2]},
                        bottomValue: { action: '*', values: [B0, A1, A2]},
                    },
                }
            ],
        },

    ],
};

//============================================================================
const text_electrodyn_01 = {
    templateTypeId: NB_TEMPLATE_TYPE.PHYSICS_ELECTRODYNAMICS,
    sampleTypeId: NB_SAMPLE_TYPE.CALC_PHYSICS_TASK,
    templateId: 'ks8x0fm5sj',
    name: 'Электродинамика',
    task: 'Решите задачу.',
    isAnySign: false, //значения коээфициентов могут быть только положительными
    showDecimalNumber: 2, //кол-во знаков после запятой при выводе
    coefNumber: 3, //кол-во используемых коэффициентов a0/b0
    coefRanges: [
        {id: NB_COMPLEXITY.LOW,    topRange: [10, 20], bottomRange: [1, 1]}, 
        {id: NB_COMPLEXITY.MEDIUM, topRange: [10, 40], bottomRange: [1, 1]},
        {id: NB_COMPLEXITY.HIGH,   topRange: [10, 80], bottomRange: [10, 10]},
    ],
    formulas: [
        {
            formula: 'Определите I кипятильника, если он подключен к розетке с U = 220 В, а R спирали кипятильника равно @0 Ом. ', 
            units: 'А', //'Воспользуйтесь формулой I=U/R.'
            answerName: 'I =',
            calcSolutionRules: [
                { 
                    factorMethod: 'values',
                    factor: {
                        topValue: { action: '*', values: [B0], multiply: 220},
                        bottomValue: { action: '*', values: [A0]},
                    },
                }
            ],
        },

        {
            formula: 'Какое U нужно подать на концы проводника с R @1 Ом, чтобы пошел ток с силой @0 А. ', 
            units: 'В', //'Воспользуйтесь формулой U=I·R.'
            answerName: 'U =',
            calcSolutionRules: [
                { 
                    factorMethod: 'values',
                    factor: {
                        topValue: { action: '*', values: [A0, A1]},
                        bottomValue: { action: '*', values: [B0, B1]},
                    },
                }
            ],
        },

        {
            formula: 'Определите мощность тока в электрической лампе, если при U = @0 В сила тока в ней @1А. ', 
            units: 'Вт', //'Воспользуйтесь формулой P=U·I.'
            answerName: 'P =',
            calcSolutionRules: [
                { 
                    factorMethod: 'values',
                    factor: {
                        topValue: { action: '*', values: [A0, A1]},
                        bottomValue: { action: '*', values: [B0, B1]},
                    },
                }
            ],
        },

        {
            formula: 'Найдите R спирали, сила тока в которой @1А, а U на ее концах @0В. ', 
            units: 'Ом', //'Воспользуйтесь формулой R=U/I.'
            answerName: 'R =',
            calcSolutionRules: [
                { 
                    factorMethod: 'values',
                    factor: {
                        topValue: { action: '*', values: [A0, B1]},
                        bottomValue: { action: '*', values: [B0, A1]},
                    },
                }
            ],
        },

    ],
};


export const physicsTemplateList = { 
    templates: [
        text_mechan_01,
        text_molphys_termodyn_01, text_molphys_termodyn_02,
        text_electrodyn_01,
    ],
    templateTypes: [
        {templateTypeId: NB_TEMPLATE_TYPE.PHYSICS_MECHANICS, templateTypeName: 'Механика'},
        {templateTypeId: NB_TEMPLATE_TYPE.PHYSICS_MOLECULAR_TERMODYNAMICS, templateTypeName: 'Молекулярная физика и термодинамика'},
        {templateTypeId: NB_TEMPLATE_TYPE.PHYSICS_ELECTRODYNAMICS, templateTypeName: 'Электродинамика'},
    ]
}; 

//http://mateshanov.ru/generator.php - Матешанов
//http://www.itmathrepetitor.ru/7-klass-lineynye-uravneniya-i-tekstovye/
