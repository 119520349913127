import React, {useState, useEffect} from "react";
import {toast} from "react-toastify";
import { Content, ContentBody, ContentWrap } from "../template/ContentParts";
import Footer from "../template/Footer/Footer";
import NotebookPages from "./NotebookPages";
import NotebookAside from "./NotebookAside2";
import {NB_STATES, NB_ACTION} from "./nb_utils";
import {getNbStorage, setNbStorage} from "./NotebookStorage";
import ss from './Notebooks.module.scss';

const NotebookAddEdit = (props) => {
    const [homeWorkId, setHomeWorkId] = useState(null);
    const [actionType, setActionType] = useState(NB_ACTION.UNDEFINED);
    const [subjectId, setSubjectId] = useState(undefined); //used in NotebookPages
    const [isSaveData, setIsSaveData] = useState(false);
    const [nbState, setNbState] = useState(!props.isSetRunTime ? NB_STATES.PAGE : NB_STATES.RUN);
    const [isDisabledTemplate, setIsDisabledTemplate] = useState(false);
    const [isShowRunMsg, setIsShowRunMsg] = useState(true);

    const [homeWorkTaskSamples, setHomeWorkTaskSamples] = useState([]);
    const [currTaskInd, setCurrTaskInd] = useState(-1);
    const [isTaskChanged, setIsTaskChanged] = useState(false);
    const [forceSaveTask, setForceSaveTask] = useState(false);

    const [isSettingChanged, setIsSettingChanged] = useState(false);
    const [forceSaveSettings, setForceSaveSettings] = useState(false);

    const [isRunChanged, setIsRunChanged] = useState(false);
    const [forceSaveRun, setForceSaveRun] = useState(false);
    const [isEditable, setIsEditable] = useState(true);
    
    useEffect(() => {
        if (props.id) {
            setHomeWorkId(props.id); //ADD ID when we call from notebook table
            setNbStorage({homeWorkId: props.id});
        } else {
            const data = getNbStorage();
            if (data.homeWorkId)
                setHomeWorkId(data.homeWorkId);
        }
    }, [props.id]);

    useEffect(() => {
        if (!props.isSetRunTime || !isShowRunMsg) return;
        toast.info('Установите даты начала и завершения задания и запустите его.');
        setIsShowRunMsg(false);
    }, [props.isSetRunTime, isShowRunMsg]);

    const handleActions = (action) => {
        switch (action.type) {
            case NB_ACTION.TASK_ADD:
                if (isTaskChanged) {
                    toast.warn("Сохраните изменения."); 
                } else {
                    if (homeWorkTaskSamples.length === 0) {
                        setCurrTaskInd(-1);
                    } else if (currTaskInd >= homeWorkTaskSamples.length) {
                        setCurrTaskInd(homeWorkTaskSamples.length-1);
                    }
                    setActionType(NB_ACTION.TASK_ADD);
                }
                return;
            
            case NB_ACTION.TASK_EDIT:
                const newInd = currTaskInd >= homeWorkTaskSamples.length ? 
                    homeWorkTaskSamples.length - 1 : action.payload;
                setCurrTaskInd(newInd);
                setActionType(NB_ACTION.TASK_EDIT);
                return;
            
            case NB_ACTION.TASK_COPY:
                const taskInd = action.payload;
                const copy = {...homeWorkTaskSamples[taskInd]};
                let samples = [...homeWorkTaskSamples];
                samples.splice(taskInd+1, 0, copy);
                setHomeWorkTaskSamples(samples);
                setIsSaveData(true);
                toast.info("Страница N" + (taskInd+1) + ' скопирована.'); 
                return;
            
            case NB_ACTION.TASK_DELETE:
                if (homeWorkTaskSamples.length > 0) {
                    const remTaskSamples = homeWorkTaskSamples.filter((item, ind) => ind !== action.payload);
                    setHomeWorkTaskSamples(remTaskSamples);
                    setIsSaveData(true);
                    toast.success("Страница N" + (action.payload+1) + ' удалена.'); 
                } 
                return;

            case NB_ACTION.TASK_SAVE_ADD: 
            case NB_ACTION.TASK_SAVE_EDIT:
                //come here from NotebookAddPage: see handleSaveChanges
                //add task to task list or update the task in the task list or delete task from task list

                const sample = action.payload;
                let taskSamples = [...homeWorkTaskSamples];
                
                if (actionType === NB_ACTION.TASK_ADD) {
                    if (currTaskInd === -1) {
                        taskSamples.push(sample);
                        setCurrTaskInd(0);
                    } else {
                        taskSamples.splice(currTaskInd, 0, sample);
                    }
                } else if (actionType === NB_ACTION.TASK_EDIT) {
                    taskSamples = homeWorkTaskSamples
                        .map((item, ind) => ind !== currTaskInd ? item : sample);
                }

                setHomeWorkTaskSamples(taskSamples);
                setIsSaveData(true);
                setActionType(action.type); //the value handles in NotebookPages: see getActionTypeBySampleNumber()
                return;

            default: 
                return;
        };
    };

    return (
            <>
                <ContentWrap>
                    <Content background={false}>
                        <ContentBody padding={false}>
                            <div className={ss.nb__wrap}>
                                <NotebookPages {...props} 
                                    homeWorkId={homeWorkId} 
                                    setHomeWorkId={setHomeWorkId}
                                    isDisabledTemplate={isDisabledTemplate}
                                    setIsDisabledTemplate={setIsDisabledTemplate}
                                    isSetRunTime={props.isSetRunTime}

                                    nbState={nbState}
                                    setNbState={setNbState}

                                    homeWorkTaskSamples={homeWorkTaskSamples}
                                    setHomeWorkTaskSamples={setHomeWorkTaskSamples}
                                    actionType={actionType}
                                    currTaskInd={currTaskInd}
                                    handleActions={handleActions}

                                    isEditable={isEditable}
                                    setIsEditable={setIsEditable}
                                    subjectId={subjectId}
                                    setSubjectId={setSubjectId}
                                    isSaveData={isSaveData}
                                    setIsSaveData={setIsSaveData}

                                    isTaskChanged={isTaskChanged}
                                    setIsTaskChanged={setIsTaskChanged}
                                    forceSaveTask={forceSaveTask}
                                    setForceSaveTask={setForceSaveTask}

                                    isSettingChanged={isSettingChanged}
                                    setIsSettingChanged={setIsSettingChanged}
                                    forceSaveSettings={forceSaveSettings}
                                    setForceSaveSettings={setForceSaveSettings}

                                    isRunChanged={isRunChanged}
                                    setIsRunChanged={setIsRunChanged}
                                    forceSaveRun={forceSaveRun}
                                    setForceSaveRun={setForceSaveRun}
                                />
                            </div>
                        </ContentBody>
                    </Content>
                    <Footer {...props} opt={props.opt} />
                </ContentWrap>
     
                <div className={ss.nb__aside}>
                    <NotebookAside {...props} 
                        homeWorkId={homeWorkId}
                        nbState={nbState}
                        actionType={actionType}
                        isDisabledTemplate={isDisabledTemplate}
                        homeWorkTaskSamples={homeWorkTaskSamples}
                        setHomeWorkTaskSamples={setHomeWorkTaskSamples}
                        isTaskChanged={isTaskChanged}
                        isSettingChanged={isSettingChanged}
                        isRunChanged={isRunChanged}
                        handleActions={handleActions}
                        currTaskInd={currTaskInd}
                        isEditable={isEditable}
                        subjectId={subjectId}
                    />
                </div>
            </>
    );
}

export default NotebookAddEdit;
