import React, { useState, useEffect } from "react";
import {videoEditorApi} from "../../api/api";
import Modal from "../../Components/Modal";
import {fullName} from "../../helpers/auth";
import {printOnlyDate} from "../../helpers/text";
import {TABLE_HEADER, getTableByMeasures, getViewTableContent} from "../PhysicalInvest/pi_utils";
import {recalcMeasures} from '../../rlab/src/components/VideoEditor/utils';
import {userApi} from "../../api/api";
import {getDropdown} from "../ui/utils/gen_utils";
import {Button} from '../ui';
import {getVEUrl} from '../template/Dashboard/db_utils';
import '../../rlab/src/components/VideoEditor/VideoEditor.scss';

const ProfileVideoEditorDetailsDlg = ({showDlg, setShowDlg, selectedVEId}) => {
	const [veData, setVEData] = useState(undefined);
	const [frames, setFrames] = useState([]);
	const [frameInd, setFrameInd] = useState(0);
    const [profile, setProfile] = useState(undefined);
    const [fileName, setFileName] = useState('');
    const [currFileUrl, setCurrFileUrl] = useState(undefined);
    const [tableRows, setTableRows] = useState([]);

	useEffect(() => {
		const getData = async () => {
			const result = await videoEditorApi.getVideoEditorById(selectedVEId);
			setVEData(result.data[0]);
		};
		if (!showDlg || !selectedVEId) return;
		getData();
	},[showDlg, selectedVEId]);

	useEffect(() => {
		const findVEUrl = async () => {
			const loadedFile = await getVEUrl(fileName);
			if (!loadedFile) return;
			setCurrFileUrl(loadedFile); //.Url
		};

		findVEUrl();
	},[fileName]);

	useEffect(() => {
		if (!veData) return;
		//1 - images
		const _frames = veData.frames.map((item, ind) => ({label: item.frameName.split('- ')[0], value: ind}));
		setFrames(_frames);

        userApi.get(veData.owner).then((data) => setProfile(data));
	},[veData]);

	useEffect(() => {
		if (frameInd === -1 || !veData) return;
		const frame = veData.frames[frameInd];
		//1 - file name
		setFileName(frame.frameName);

		//2 - table rows
		let measures = frame.measures.map(measure => ({
			...measure,
			rectangle: {
				x: measure.x, 
            	y: measure.y, 
            	width: measure.width, 
            	height: measure.height,
			}
		}));
		measures = recalcMeasures(measures, [veData.videoSizeX, veData.videoSizeX], veData.videoScale);
		let rows = getTableByMeasures(veData.videoUnitId, measures);
		rows = rows.map(row => row.filter((col, ind) => ind !== row.length - 1));
		setTableRows(rows);
	},[veData, frameInd]);

    const setModalContent = () => {
        return (
			<>
				<div className="modal__close" onClick={() => setShowDlg(false)}></div>
				<div className="modal__message">Видео анализ. Детали.</div>
				<div className="cor-net__row">
					<div className="cor-net__col col-2">
						<div className="cor-net__label">Имя</div>
						{fullName(profile)}
					</div>
					<div className="cor-net__col col-2">
						<div className="cor-net__label">Дата</div>
						{printOnlyDate(veData?.updatedAt)}
					</div>
				</div>
				<div className="cor-net__row">
					<div className="cor-net__col">
						<div className="cor-net__label mb_0">Кол-во кадров:</div>
					</div>
					<div className="cor-net__col">
						{frames.length}
					</div>
					<div className="cor-net__col col-grow"></div>
					<div className="cor-net__col col-2">
						{getDropdown(frames, frameInd, value => setFrameInd(value), '', '', 'fr01')}
					</div>
				</div>

				{!!currFileUrl && 
				<div className="cor-net__row">
					<div className="cor-net__col col-2">
						<div className="modal__body m_0">
							<div className="modal__img">
								<img src={currFileUrl} alt='' />
							</div>
						</div>
					</div>

					{tableRows.length > 0 && 
					<div className="cor-net__col col-2">
						<div className="modal__body has_scrolled m_0">
							<div className="modal__scrolled">
								{getViewTableContent(
									[], 
									TABLE_HEADER.filter((col, ind) => ind < TABLE_HEADER.length - 1), 
									tableRows, 
									0, 
									() => {},
									undefined			
								)}
							</div>
						</div>
					</div>
					}
				</div>
				}		
				
				<div className="modal__action modal__action_end">
					<Button color="primary" size="medium" onClick={() => setShowDlg(false)}>Закрыть</Button>
				</div>
			</>
        )
    }

    if (!showDlg) {
        return false;
    }
    
    return (
        <Modal visible={showDlg} content={setModalContent()} size="xxl" />
    )

}

export default ProfileVideoEditorDetailsDlg;
