import React from "react";
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "./redux";
import DashboardMl from "./DashboardMl";
import VrLab from "../../VrLab";
import Footer from "../Footer";
import {Content, ContentBody, ContentWrap} from "../ContentParts";
import SectionTabs from "../../ui/SectionTabs";
import "./Dashboard.scss";

const tabs = [
  {name: "Оптика >", icon: "microscope", tooltip: 'Виртуальная лаборатория: прохождение лабораторных работ в игровом формате.', to: '/vrlab/0' },
  {name: "3D игра самолетик с датчиком на акселерометре Х, Y, Z >", icon: "play", tooltip: '3Д игра с самолетиком: управление самолетиком с помощью датчика ускорения.', to: '/vrlab/sensor' },
  {name: "Ai 3D игра самолетик по веб-камере >", icon: "play-round", tooltip: '3Д игра с самолетиком с камеры: обучите ИИ жестам через ВЕБ-камеру и с их помощью управляйте самолетиком.', to: '/vrlab/plane' },
  {name: "Ai распознавание предметов через веб-камеру >", icon: "lamp", tooltip: 'Распознавание предметов: обучите искусственный интеллект распознавать предметы с помощью ВЕБ-камеры.', to: '/vrlab/recognition' },
  {name: "Механика >", icon: "gear", tooltip: 'Механика.', to: '/vrlab/mechanics' },
];

export const DashboardVrLab = (props) => {
  const indexes = ['0', '1','3','5']; // 0 - means a game without index, 1 - lab.work 1, ...
  const id = props.match.params.id;
  
  return (
    <>
      <ContentWrap>
        <Content background={false}>
          <ContentBody padding={false} scrolled={false}>
            {indexes.includes(id) ? 
              <VrLab {...props}/> 
             :
            ['sensor', 'plane', 'recognition', 'mechanics'].includes(id) ?
              <DashboardMl {...props}/> 
              :
              <SectionTabs {...props} tabs={tabs}/>
            }
          </ContentBody>
        </Content>
        <Footer {...props}/>
      </ContentWrap>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardVrLab);