import React, {useState, useEffect} from 'react';
import Modal from "../../Components/Modal";
import {isAuth} from '../../helpers/auth';
import {Button} from '../ui';
import {fullName} from "../../helpers/auth";
import {printOnlyDate} from "../../helpers/text";
import {getSubjectById, getNotebookById, getTaskNumber} from "./nb_utils";
import {showNameValue} from "../ui/utils/gen_utils";
import "../ui/ModalDialogs/PracticumTests.scss";

const NotebooksStudentInitDlg = ({showDlg, setShowDlg, selectedIds, handleExecuteTest}) => {
	const [nbData, setNbData] = useState(undefined);

    //1. get selectedSudentId and notebookInfoId:
    useEffect(() => {
        const fetchData = async () => {
            const _notebookId = selectedIds.split('|')[0];
			let result = await getNotebookById(_notebookId);
            setNbData(result);
        };

        if (!selectedIds) return;
        fetchData();
    }, [selectedIds]);

    const setModalContent = () => {

    return (
            <div>
                <div className="modal__close" onClick={() => setShowDlg(false)}></div>
                <div className="modal__message">{isAuth().role === 0 ? 'Задание' : 'Предпросмотр'}</div>

                <div className="modal__body">
                    <div className="cor-net">
                        <div className="cor-net__section">
                            {showNameValue('Предмет', nbData ? getSubjectById(nbData.titles.subjectId) : '')}
                            {showNameValue('Название задания', nbData?.titles ? nbData.titles.homeWorkName : '')}
                            {showNameValue('Тема', nbData?.titles ? nbData.titles.homeWorkItem : '')}
                            {nbData?.owner && showNameValue('Преподаватель', fullName(nbData.owner))}
                            {showNameValue('Дата выдачи задания', nbData?.run ? printOnlyDate(nbData.run.runFullDate) : '' )}
                            {showNameValue('Дата сдачи задания', nbData?.run ? printOnlyDate(nbData.run.completeFullDate) : '' )}
                            {showNameValue('Кол-во вопросов', nbData ? getTaskNumber(nbData) : 0)}
                            {showNameValue('Лимит ошибок на одно задание', nbData?.settings.isErrorNumberLimit ? nbData.settings.taskErrorLimit : 'не ограничен')}
                            {showNameValue('Лимит ошибок на все задания', nbData?.settings.isErrorNumberLimit ? nbData.settings.workErrorLimit : 'не ограничен')}
                            {showNameValue('Лимит времени', nbData?.settings.isTimeLimited ? '' + nbData.settings.timeLimitMinutes + ' минут' : 'не ограничен')}
                        </div>
                    </div>
                </div>

                <div className="modal__action">
                    <Button 
                        color="primary" 
                        onClick={handleExecuteTest}>
                        Начать {isAuth().role === 0 ? 'задание' : 'предпросмотр'}
                    </Button>
                    <Button 
                        color="primary" 
                        border={true} 
                        onClick={() => setShowDlg(false)}>
                            Закрыть
                    </Button>
                </div>
            </div>
        )
    }

    if (!showDlg || !selectedIds) {
        return false;
    }
    
    return (
        <Modal visible={showDlg} content={setModalContent()} size={'md'} />
    )
};

export default NotebooksStudentInitDlg;
