import React, {useEffect, useState} from "react";
import {fetchclass, fetchUsersByOrg, fetchOrgs, selectorgs} from "../../../redux/slices/classsline";
import {connect, useDispatch, useSelector} from "react-redux";
import {Button, Icon} from '../../ui';
import "./Dashboard.scss";
import {ClassGrid, FeaturesGrid, OrgGrid} from "../../Grids";
import {storageOrg, storageUser} from "../../../redux/slices/storage";
import {mapOrgDispatchToProps, mapOrgStateToProps} from "./redux";
import Profile from "../../Profile";
import {generatePassword, isAuth} from "../../../helpers/auth";
import {Back, Content, ContentBody, ContentHead, ContentHeader, ContentSection, ContentWrap} from "../ContentParts";
import Checkbox3 from "../../ui/Checkbox3";
import {nextYear} from "../../../helpers/text";

export const DashboardOrg = (props) => {
    const dispatch = useDispatch();
    const documentOrg = useSelector(storageOrg);
    const documentUser = useSelector(storageUser);
    const [orgList] = useSelector(selectorgs);
    const [active, setActive] = useState(0);
    const [searchOrg, setSearchOrg] = useState('');
    const [searchClass, setSearchClass] = useState('');
    const [searchLic, setSearchLic] = useState('');
    const [isEdit, setIsEdit] = useState(false);

    const selectedOrg = props.location.state?.org;

    useEffect(() => {
        dispatch(fetchOrgs());
        if (selectedOrg){
            dispatch(fetchUsersByOrg(documentOrg._id))
            dispatch(fetchclass(documentOrg._id));
        }
        // eslint-disable-next-line
    }, [dispatch]);

    const getUser = (uid) => {
        const suid = uid.toString().padStart(3, "0");
        return {
            uid,
            login: "school-" + suid,
            name: suid,
            email: "school@" + suid + ".ru",
            city: "Санкт-Петербург",
            password: generatePassword(8),
            features: {storage: nextYear},
        }
    }

    return (

        <ContentWrap>
                {selectedOrg ? 
                    <>
                        <ContentHead title={"Школа № " + documentOrg.name} />
                        <Back onClick={() => props.history.push("/")} icon="back" margin="top"/>
                        <ContentSection className="mb_0">
                            <Profile profile={documentOrg} isEdit={isEdit} setIsEdit={setIsEdit}/>
                        </ContentSection>
                    </>
                    :
                    <ContentHead title="Школы" />
                }
        
                <div className="cor_content__row">
                    <div className="cor_content__col w73">
                        <Content>
                            <ContentHeader>
                                <div className="cor-net__row">
                                    {selectedOrg && <div className="cor-net__col mb_0 cor-net__title">Классы</div>}
                                    <div className="cor-net__col col-icon col-6">
                                        {selectedOrg ?
                                          <input className="filter" value={searchClass} placeholder="Поиск"
                                                 onChange={(e) => setSearchClass(e.target.value)}/> :
                                          <input className="filter" value={searchOrg} placeholder="Поиск"
                                                 onChange={(e) => setSearchOrg(e.target.value)}/>}
                                        <Icon name="search"/>
                                    </div>
                                    {!selectedOrg && <div className="cor-net__col col-icon col-12">
                                            <label className="filter">
                                        <Checkbox3 value={active} onChange={(e)=>setActive(e.target.value)}/>
                                            <span>Активные</span>
                                        </label>
                                    </div>}
                                    {isAuth().role === 3 &&
                                      <div className="cor-net__col col-6">
                                          {selectedOrg ? <Button color="primary" border={true} onClick={() =>
                                              props.showClassSettings({showModal: true, isAdd: true, owner: documentOrg._id, isAdmin: isAuth().role === 3})
                                            }>Добавить класс</Button> :
                                            <Button color="primary" border={true} onClick={() =>
                                              props.showUserSettings({showModal: true, role: 2, isAdd: true, user: getUser(Math.max(...orgList.map(o => o.uid ?? 0)) + 1), owner: documentUser._id})
                                            }>Добавить школу</Button>}
                                      </div>}
                                </div>
                            </ContentHeader>
                            <ContentBody>
                                {selectedOrg ? <ClassGrid search={searchClass} {...props}/> :
                                  <OrgGrid search={searchOrg} active={active} {...props}/>}
                            </ContentBody>
                        </Content>
                    </div>
                    {selectedOrg && 
                    <div className="cor_content__col org">
                        <Content>
                            <ContentHeader>
                                <div className="cor-net__row">
                                    <div className="cor-net__col mb_0 cor-net__title">
                                        Лицензии
                                    </div>
                                    <div className="cor-net__col col-icon col-grow">
                                        <input className="filter" value={searchLic} placeholder="Введите название"
                                                onChange={(e) => setSearchLic(e.target.value)}/>
                                        <Icon name="search"/>
                                    </div>
                                    <div className="cor-net__col">
                                        <Icon 
                                            className="cor-net__hover"
                                            name="gear"
                                            onClick={() => props.showUserSettings({showModal: true, role: 2, user: documentOrg, allowDelete: false})}
                                        />
                                    </div>
                                </div>
                            </ContentHeader>
                            <ContentBody>
                                <FeaturesGrid search={searchLic}  {...props}/>
                            </ContentBody>
                        </Content>
                    </div>
                    }

                </div>
        </ContentWrap>
    );
};

export default connect(mapOrgStateToProps, mapOrgDispatchToProps)(DashboardOrg)

