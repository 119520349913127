import React, {useState, useEffect} from "react";
import { Content, ContentBody, ContentFooter, ContentHeader } from "../template/ContentParts";
import {toast} from "react-toastify";
import { Icon, Tabs } from '../ui';
import {Button, Input} from 'rlabui';
import {fullName, isAuth} from "../../helpers/auth";
import {printOnlyDate} from "../../helpers/text";
import Table from "../ui/Table/Table";
import {setIsRefreshNBarCounters}  from "../../redux/slices/tutorialslice";
import {WORK_STATE, BLANK_FILE, WS_TYPE, doDownloadResearch, getWorkStateByStatus, 
	uploadPIFile, sendChangePi} from "./pi_utils";
import {base64ToArrayBuffer} from "../ui/utils/gen_utils";
import {piApi} from "../../api/api";
import {useDispatch, useSelector} from "react-redux";
import {loadPIResearches, loadCountersPi} from "../../redux/slices/lists";
import {storageClass} from "../../redux/slices/storage";
import ModalConfirmDeleteDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import {FEATURES} from "../../api/config";

const PInvestigationsList = (props) => {
	const [researches, setResearches] = useState([]);
	const [testFilter, setTestFilter] = useState('');
	const [filteredResearches, setFilteredResearches] = useState([]);
    const [confirmQuestion, setConfirmQuestion] = useState('');
	const [showConfirmDeleteDlg, setShowConfirmDeleteDlg] = useState(false);
	const [actionId, setActionId] = useState('');
	const [filesBeCopied, setFilesBeCopied] = useState([]);
	const [researchBeCopied, setResearchBeCopied] = useState(undefined);
	const [loadedFileName, setLoadedFileName] = useState('');
	const [isLockUE, setIsLockUE] = useState(false);

	const tableTabInd = props.location.state?.status ?? (isAuth().role === 3 ? WORK_STATE.TEMPLATE.status : WORK_STATE.ALL.status);
	const {lists: {pi}} = useSelector(state => state);
	const {lists: {counters}} = useSelector(state => state);
	const documentClass = useSelector(storageClass);
    const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadPIResearches(documentClass._id, tableTabInd));
		dispatch(setIsRefreshNBarCounters(true));
		dispatch(loadCountersPi(documentClass._id));
	}, [dispatch, documentClass._id, tableTabInd]);

	useEffect(() => {
		const isStudentWork = (id) => {
			const user = documentClass.classUsers.find(item => item._id === id);
			return user ? user.role === 0 : false;
		};
		if (!pi) return;
		const _researches = [];

		for (let i = 0; i < pi.researches.length; i++) {
			const res = pi.researches[i];
			let found = false;

			if ((tableTabInd === WORK_STATE.ALL.status || 
				tableTabInd === WORK_STATE.TEMPLATE.status) && !res.status) {
				found = true;
			} else if ((tableTabInd === WORK_STATE.ALL.status || 
					   tableTabInd === WORK_STATE.WORK.status) && 
					   ((isAuth().role > 0 && isStudentWork(res.owner?._id)) ||
					   (isAuth()._id === res.owner?._id))) {
				found = true;
			} else if ((tableTabInd === WORK_STATE.ALL.status || 
					   tableTabInd === WORK_STATE.CHECK_IN_PROGRESS.status || 
					   tableTabInd === WORK_STATE.CHECKED.status) && 
					   ((isAuth().role > 0 && isAuth()._id === res.reviewer) ||
					   (isAuth().role === 0 && isAuth()._id === res.owner?._id))) {
				found = true;
			} else if ((tableTabInd === WORK_STATE.ALL.status || 
					   tableTabInd === WORK_STATE.PUBLISHED.status) && 
					   (res.status === WORK_STATE.PUBLISHED.status)) {
				found = true;
			}

			if (found) {
				const obj = {
					theme: res.theme,
					classLevel: '' + (res.level ? '' + res.level + ' класс' : res.theme.split(', ')[1]),
					nameLink: res.name,
					author: fullName(res.owner, 'Шаблон'),
					date: printOnlyDate(res.updatedAt),
					status: getWorkStateByStatus(res.status),
					actions: '',
					id: res._id,
				};

				_researches.push(obj);
			}
		}

		setResearches(_researches);
	}, [pi, documentClass, tableTabInd]);

	useEffect(() => {
		const filteredResearches = researches.filter((row)=> (
			Object.values(row)
				.slice(0, -1))
				.some(v => v?.toLowerCase().includes(testFilter.toLowerCase())
			));
		setFilteredResearches(filteredResearches);
	}, [researches, testFilter]);

	const handleSelectWork = (id = -1) => {
		props.history.push(FEATURES.pi.to + "/" + id);
	};

	const handleCopy = async (id) => {
		const research = await piApi.getResearchById(id);
		research.name = 'Копия - ' + research.name;
		const isTemplate = !research.owner;

		if (isTemplate && isAuth().role === 3) {
			copyTemplate(research);
		} else if (!isTemplate) {
			//copyInvestigation(research); //no need to implement yet
		} else {
			copyTemplateToInvestigation(research);
		}
	};

	const copyTemplate = async (_research) => {
		const research ={..._research};
		research.status = WORK_STATE.TEMPLATE.status;

		piApi.addResearch(research)
		.then((result) => {
			const workName = research.title ? research.title : research.name;
			toast.success("Работа '" + workName.trim() + "' скопирована.");
			dispatch(loadPIResearches(documentClass._id, tableTabInd));
			sendChangePi(documentClass._id, dispatch);
		}).catch((err) => {
			toast.error(err.response.data.errors);
		});
	};

	//копирование templates в работу (3 шага)
  	//step 1 of 3: готовим информацию о файлах в шаблоне
  	const copyTemplateToInvestigation = async (_research) => {
		const research ={..._research};
		research.status = WORK_STATE.WORK.status;
		research.room = documentClass._id;
		
		let dtImages = research.paintTools.map((item, dtInd) => ({...item, dtInd: dtInd}))
			.filter(item => item.image.frameData !== BLANK_FILE);

		const arr = [];
		for (let i = 0; i < dtImages.length; i ++) {
			arr.push({type: 'dtimage', dtInd: dtImages[i].dtInd});
		}
	
		setFilesBeCopied(arr);
		setResearchBeCopied(research);
	};

  	//step 2 of 3: готовим очередной файл и посылаем запрос на загрузку ИЛИ сохраняем сценарий
  	useEffect(() => {
		//templates contain the images inside. When copying the templates it's necessary to create 
		//image file, save them and add references to the files as attachmentName:
		if (!researchBeCopied || loadedFileName || isLockUE) return;
		setIsLockUE(true);

		if (filesBeCopied.length > 0) {
			//попадаем сюда. если список файлов еще не пуст. создаем соответствующий файл и затем удаляем ссылку на него из списка
			const f = filesBeCopied[0];
			const drawTool = researchBeCopied.paintTools[f.dtInd];

			const fileName = drawTool.image.name;
			const frameData = drawTool.image.frameData;
			
			const fileData = frameData.split(';base64,');
			const type = fileData[0].split(':')[1]; //keep type like 'image/jpeg'
			const content = fileData[1];

			const file = new File(
				[base64ToArrayBuffer(content)],
				fileName,
				{type: type}
			);

			uploadPIFile(file, setLoadedFileName);
		} else {
			//попадаем сюда, когда все файлы созданы. осталось создать сам тест
			piApi.addResearch(researchBeCopied)
			.then((result) => {
				const workName = result.title ? result.title : result.name;
				toast.success("Работа '" + workName.trim() + "' скопирована в раздел '" +
					WORK_STATE.WORK.name + "'.");
				dispatch(loadPIResearches(documentClass._id, tableTabInd));
				setResearchBeCopied(undefined);
				setIsLockUE(false);
				sendChangePi(documentClass._id, dispatch);
			}).catch((err) => {
				toast.error(err.response.data.errors);
			});
		}
	}, [dispatch, documentClass._id, filesBeCopied, isLockUE, loadedFileName, researchBeCopied, tableTabInd]);

	//step 3 of 3: сохраняем инфу об очередном загруженном файле в данные сценария (в attachmentName), 
	//уменьшаем список filesBeCopied. Затем снова возвращаемся в шаг 2.
	useEffect(() => {
		if (!loadedFileName || filesBeCopied.length === 0) return;
		//console.log('3/3')
		const _research = {...researchBeCopied};
		const _filesBeCopied = [...filesBeCopied];
		const f = _filesBeCopied[0];

		const img = _research.paintTools[f.dtInd].image;
		img.name = loadedFileName;
		img.frameData = ''; //just clean up. it will be filled when opening the research

		_filesBeCopied.shift(); //удаляем инфу о созданном файле из списка
		setFilesBeCopied(_filesBeCopied); //сохраняем обновленный список
		setResearchBeCopied(_research);
		setLoadedFileName('');
		setIsLockUE(false);
	}, [loadedFileName, filesBeCopied, researchBeCopied]);

	const handleSaveToComputerItem = async (id) => {
		const research = await piApi.getResearchById(id);
		doDownloadResearch(research);
	};

	const handleDeleteItem = (id) => {
		setConfirmQuestion('Вы действительно хотите удалить эту работу?');
		setShowConfirmDeleteDlg(true);
		setActionId(id);
	};
	const doDeleteItem = () => {
		setShowConfirmDeleteDlg(false);
		setConfirmQuestion('');
		piApi.deleteResearchById(actionId).then(() => {
			toast.success("Работа удалена.");
			setActionId('');
			dispatch(loadPIResearches(documentClass._id, tableTabInd));
			sendChangePi(documentClass._id, dispatch);
		}).catch((err) => {
			toast.error(err.response.data.errors);
		});
	};

	const getDeleteIDs = () => {
		if (filteredResearches.length === 0) return [];
		if (isAuth().role === 3) return filteredResearches.map(item => item.id);
		return filteredResearches.filter(item => item.author !== 'Шаблон').map(item => item.id);
	};
	
	const getTable = () => {
		const header = [
			{column: 'Тема работы', name: 'theme', style: { width: '18%'} }, 
			{column: 'Уровень', name: 'classLevel', style: { width: '12%'} }, 
			{column: 'Название работы', name: 'nameLink', style: { width: '38%'} }, 
			{column: 'Автор', name: 'author', style: { width: '12%'} }, 
			{column: 'Дата', name: 'date', style: { width: '10%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '10%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];

		const headerAll = [
			{column: 'Тема работы', name: 'theme', style: { width: '18%'} }, 
			{column: 'Уровень', name: 'classLevel', style: { width: '12%'} }, 
			{column: 'Название работы', name: 'nameLink', style: { width: '26%'} }, 
			{column: 'Автор', name: 'author', style: { width: '12%'} }, 
			{column: 'Статус', name: 'status', style: { width: '12%'} }, 
			{column: 'Дата', name: 'date', style: { width: '10%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '10%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];

		return (
			<Table 
				table={{
					header: tableTabInd === WORK_STATE.ALL.status ? headerAll : header, 
					data: filteredResearches,
				}}
				link={{
					handleLink: id => props.history.push(FEATURES.pi.to + "/" + id),
				}}
				sort={{
					hasSorting: true,
					initSortInd: tableTabInd === WORK_STATE.ALL.status ? -6 : -5,
				}}
				actions={{
					handleCopy: tableTabInd === WORK_STATE.TEMPLATE.status || tableTabInd === WORK_STATE.PUBLISHED.status ? 
						id => handleCopy(id) : undefined,
					tooltipCopy: "Скопировать работу в разделе '" + (isAuth().role === 3 ? WORK_STATE.TEMPLATE.name : WORK_STATE.WORK.name) + "'.",
					handleSave: id => handleSaveToComputerItem(id),
					tooltipSave: 'Сохранить работу в формате .corinv',
					canDeleteIDs: getDeleteIDs(),
					handleDelete: id => handleDeleteItem(id),
				}}
			/>			
		);
	};

	const handleUploadFile = async (e) => { 
		e.preventDefault();
		const reader = new FileReader();
		reader.onload = async (e) => {
			sendChangePi(documentClass._id, dispatch);
			props.history.push({pathname: FEATURES.pi.to + "/-1", state: {content: e.target.result}});
		};
		reader.readAsText(e.target.files[0]);
	} 

	const getNotify = (tabStatus) => {
		if ((isAuth().role === 1 && tabStatus === WS_TYPE.CHECK_IN_PROGRESS) || 
			(isAuth().role === 0 && tabStatus === WS_TYPE.WORK))
			return counters.pi;
		return 0;
	};

	const handleSelectTab = (ind) => props.history.push({pathname: FEATURES.pi.to, state:{status: ind}});

    return (
		<Content>
			<ContentHeader>
				<div className="cor-net__row">
					{isAuth().role < 3 && 
						<>
							<div className="cor-net__col">
									<Tabs>
										{Object.values(WORK_STATE).map((tab) => (
											<Tabs.Item 
												active={tableTabInd === tab.status}
												onClick={() => handleSelectTab(tab.status)}
												key={tab.status}
												notify={getNotify(tab.status)}
											>
												{tab.name}
											</Tabs.Item>)
										)}
									</Tabs>
							</div>
							<div className="cor-net__col col-grow"></div>
						</>
					}
					
					<div className="cor-net__col col-3 col-icon">
						<Input value={testFilter} onInput={e => setTestFilter(e.target.value)} placeholder={'Введите название '} />
						<Icon name={'search'} />
					</div>
				</div>
			</ContentHeader>

			<ContentBody>
				{getTable()}
			</ContentBody>

			<ContentFooter className="gap jc-start">
				<Button onClick={() => handleSelectWork()}>{isAuth().role < 3 ? "Создать" : "Создать шаблон" }</Button>
				<Button border={true} label={true}>
					{isAuth().role < 3 ? "Загрузить" : "Загрузить шаблон" }
					<input id="upload" 
						type="file" 
						accept={'.corinv'} 
						name="myFile" 
						onChange={e => handleUploadFile(e)}
					/>
				</Button>
			</ContentFooter>

			{showConfirmDeleteDlg &&
			<ModalConfirmDeleteDialog
				showConfirmDlg={showConfirmDeleteDlg} 
				handleNo={() => setShowConfirmDeleteDlg(false)}
				handleYes={doDeleteItem}
				question={confirmQuestion}
			/>
			}
		</Content>
    )
}

export default PInvestigationsList;