import {toast} from "react-toastify";
import {isAuth} from "../../../helpers/auth";

//Local Storage
export const getLSInfo = id => {
    const info = localStorage.getItem(id);
    return !info ? undefined : JSON.parse(info);
};
export const hasLSInfo = id => !!localStorage.getItem(id);
export const setLSInfo = (id, info) => {
    localStorage.setItem(id, JSON.stringify(info));
};
export const removeLSInfo = (id) => {
    localStorage.removeItem(id);
};

//============================

//Practicum Test Info Local Storage
export const TEST_TYPE = {
    ONLINE_TEST : 0, 
    LAB_WORK: 1, 
    LW_OPTIC_GAME: 2,
};

export const TEST_OPTION = {
    OT : 'ot', 
    LW: 'lw', 
    VRLAB: 'vrlab',
};

const TEST_INFO = 'TEST_INFO';

export const getRunningTestInfo = () => getLSInfo(TEST_INFO);
export const hasRunningTestInfo = () => hasLSInfo(TEST_INFO);
export const setRunningTestInfo = (info) => {
    setLSInfo(TEST_INFO, info);
};
export const clearRunningTestInfo = () => {
    removeLSInfo(TEST_INFO);
    //if (isAuth().role > 0) toast.info('Запущенный тест завершен.');
};
export const isActiveTestView = (opt) => {
    const info = getRunningTestInfo();
    if (!info) return false;
    const tt = info.testType;
    if (tt === TEST_TYPE.ONLINE_TEST && opt === TEST_OPTION.OT) return true;
    if (tt === TEST_TYPE.LAB_WORK && opt === TEST_OPTION.LW) return true;
    if (tt === TEST_TYPE.LW_OPTIC_GAME && opt === TEST_OPTION.VRLAB) return true;
    return false;
};
