import React, {useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {mapStorageStateToProps, mapStorageDispatchToProps} from "./redux";
import {StorageGrid} from "../../Grids";
import {Icon, FilterByText} from "../../ui";
import { Button, Modal } from 'rlabui';

import {FileSettings} from "../../Settings";
import Footer from "../Footer";
import DropdownList from "../../ui/Dropdown/DropdownList";
import {storageOptions} from "../../../redux/slices/storage";
import "./Dashboard.scss";
import {storageApi} from "../../../api/api";
import {loadFiles} from "../../../redux/slices/lists";
import axios from "axios";
import { Content, ContentBody, ContentFooter, ContentHead, ContentHeader, ContentWrap } from "../ContentParts";
import {toast} from "react-toastify";

const sizeList = {
    '' : ['Список', 'table' ],
    'grid_md' : [ 'Плитка', 'grid_md' ],
    'grid_lg' : [ 'Крупная плитка', 'grid_lg' ],
}

export const DashboardStorage = (props) => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [uploadModal, setUploadModal] = useState(false);
    const [uploadFile, setUploadFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const {gridOptions} = useSelector(storageOptions);
    const prefix = props.match?.params.key ?? '%2F';

    const upload = async ()=> {
        const uploadUrl = await storageApi.getUploadUrl((props.match?.params.key ?? '') + uploadFile.name);
        axios.put(uploadUrl, uploadFile, {
            headers: {"Content-Type": uploadFile.type},
            onUploadProgress: (e) => setUploadProgress(Math.min(Math.round((e.loaded * 100) / e.total), 100))
        }).then(()=>{
            dispatch(loadFiles(prefix));
            setUploadFile(null);
        }).catch((err) => {
            toast.error(err);
        });
    };

    const createDir = (data) => {
        storageApi.createDirectory(prefix + data.name).then(()=>{
            dispatch(loadFiles(prefix));
            props.showNameSettings({showModal: false})
        }).catch((err) => {
            toast.error(err);
        });
    };

    return (<>
        <ContentWrap className="cor-storage">
            <ContentHead title="Файлы" />
            <Content>
                <ContentHeader>
                    <div className="cor-net__row">
                        <div className="cor-net__col col-icon col-6">
                            <FilterByText 
                                value={search}
                                placeholder={'Введите название'}
                                setSearch={setSearch}
                            />
                        </div>
                        <div className="cor-net__col col-grow"></div>
                        <div className="cor-net__col col-flex col-6">
                            <div className="cor-net__label">Тип</div>
                            <DropdownList control="storage" sizeList={sizeList} />
                        </div>
                    </div>
                </ContentHeader>

                <ContentBody className={(gridOptions?.storage?.gridSize?" " + gridOptions.storage.gridSize:" tb")}>
                    <StorageGrid {...props} search={search}/>
                </ContentBody>

                <ContentFooter className="jc-end gap">
                    <Button onClick={() => props.showNameSettings({
                        showModal: true, showDesc: false,
                        action: "Создать", title: 'Создать папку', onSubmit: createDir
                    })} border={true} icon={true}>
                        <Icon name="plus_bold"/>
                        Создать папку
                    </Button>
                    <Button onClick={()=>setUploadModal(true)} border={true} icon={true}>
                        <Icon name="plus_bold" />
                        Загрузить файл
                    </Button>
                </ContentFooter>
            </Content>
            
            <Modal visible={uploadModal} size="xs">
                <Modal.Head modalClose={() => setUploadModal(false)} title='Загрузка нового файла' />
                <Modal.Body>
                    <div className="cor-net__section">
                        <div className="cor-net__row">
                            <div className="cor-net__col col-grow">
                                <div className="cor-net__label">Имя файла</div>
                                <input type="text" readOnly value={uploadFile?.name || ""}/>
                            </div>
                            <div className="cor-net__col col-3">
                                <div className="cor-net__label">&nbsp;</div>
                                <Button size="small" color="primary" border={true} className="corGrid__name_upload">
                                    <label htmlFor="upload">
                                        Выбрать файл
                                    </label>
                                    <input id="upload" type="file" onChange={e=>{
                                        setUploadFile(e.target.files[0]);
                                        setUploadProgress(0);
                                        e.target.value = ''
                                    }}/>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="cor-net__section">
                        { uploadProgress > 0 && <div className="cor-uploading__content">
                            <div className="cor-uploading__progress">
                                <div className="cor-uploading__progress_current" style={{width: uploadProgress + '%'}}></div>
                            </div>
                        </div>}
                        { uploadProgress === 100 && <div className="cor-uploading__status">Загрузка завершена</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {uploadFile &&  uploadProgress === 0 && <Button onClick={upload}>Добавить файл</Button>}
                    <Button onClick={() => setUploadModal(false)} border={true}>Закрыть</Button>
                </Modal.Footer>
            </Modal>

            <Footer {...props}/>
        </ContentWrap>
        <FileSettings/>
    </>);
};

export default connect(mapStorageStateToProps, mapStorageDispatchToProps)(DashboardStorage)

