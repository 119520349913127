import React from "react";
import Footer from '../Footer';
import OnlineTestExecute from '../../OnlineTest/OnlineTestExecute';
import { ContentWrap } from "../ContentParts";

export const DashboardTestView = (props) => { //for testing online tests by teacher
  const otSettings = {
    onlineTestId: props.match?.params.id
  };

  return (
    <ContentWrap>
      <OnlineTestExecute otSettings={otSettings} {...props} />
      <Footer {...props}/>
    </ContentWrap>
  );
}

export default DashboardTestView;
