import labsList from './subjectList';

export const LW_MODE_TYPE = {
  LABWORKS: 1,
  HISTORY: 2
};

export const LW_TAB_STATE = [
    {status: LW_MODE_TYPE.LABWORKS, name: 'Работы', path: '/practicum/lw'},
    {status: LW_MODE_TYPE.HISTORY, name: 'История тестирования', path: '/reviewtest/lw'},
];

//=====================================================================
// --- Session storage Teacher Work Info --- 
//=====================================================================
//It is necessary to provide access to lab work after reloading that page
const SS_TEACHER_WORK_INFO = 'ssTeacherWorkInfo';
export const getTeacherWorkInfo = () => {
    const ssInfo = sessionStorage.getItem(SS_TEACHER_WORK_INFO);
    if (!ssInfo) return undefined;
    return JSON.parse(ssInfo);
};
export const setTeacherWorkInfo = (_subjectId, _sectionId, _labId) => {
    const teachLabInfo = {
        subjectId: _subjectId,
        sectionId: _sectionId,
        labId: _labId,
    };
    sessionStorage.setItem(SS_TEACHER_WORK_INFO, JSON.stringify(teachLabInfo));
};
export const hasTeacherWorkInfo = () => {
    const info = sessionStorage.getItem(SS_TEACHER_WORK_INFO);
    if (!info) return false;
    return true;
};
export const removeTeacherWorkInfo = () => {
    sessionStorage.removeItem(SS_TEACHER_WORK_INFO);
};

export const getNamesByLabWorkId = (lwId) => {
	if (!lwId) return '';
	const ids = lwId.split('|');

	const subjects = labsList.subjectList;

	const subj = subjects.find(item => item.subjectId === ids[0]);
  const subjectName = subj ? subj.subjectName : '';

  if (!subj || !subj.sectionList) {
    return subjectName + '||';  
  }

	const sect = subj.sectionList.find(item => item.sectionId === ids[1]);
  const sectionName = sect ? sect.sectionName : "";

	let labWorkName = '';
	if (ids[2] && sect && sect.labList && sect.labList.length > 0) {
		const lab = sect.labList.find(item => item.labId === ids[2]);
		if (lab) {
			labWorkName = lab.labName;
		} 
	}
	
	return subjectName + '|' + sectionName + '|' + labWorkName;
};

export const formLabWorkId = (subjId, sectId, labId) => {
	return subjId + '|' + sectId + '|' + labId;
};


export const getLabWorkArraysAndNames = (laboratoryList, subjectId, sectionId, labId) => {
  let subj = undefined;
  let sect = undefined;
  let lab = undefined;

  if (laboratoryList.length > 0) {
    subj = !!subjectId && laboratoryList.find(item => item.subjectId === subjectId);
    sect = !!sectionId && !!subj && subj.sectionList.find(item => item.sectionId === sectionId);
    lab = !!sect && !!labId && sect.labList.find(item => item.labId === labId);
  }
  
  return [subj, sect, lab];
};

export const getSubjectName = (laboratoryList, subjectId, sectionId, labId) => {
  const [subj, ,] = getLabWorkArraysAndNames (laboratoryList, subjectId, sectionId, labId);
  return subj ? subj.subjectName : '';
};

export const getSectionName = (laboratoryList, subjectId, sectionId, labId) => {
  const [,sect,] = getLabWorkArraysAndNames (laboratoryList, subjectId, sectionId, labId);
  return sect ? sect.sectionName : '';
};

export const getLabName = (laboratoryList, subjectId, sectionId, labId) => {
  const [,,lab] = getLabWorkArraysAndNames (laboratoryList, subjectId, sectionId, labId);
  return lab ? lab.labName : '';
};

export const enabledEditScore = (auth, users) => {
  return auth.role > 0 && users.find(item => item.role > 0 && item._id === auth._id);
};

//HOW TO ADD SOUND:
//import useSound from 'use-sound';
    //const [isSoundChecked, setIsSoundChecked] = useState(false);
    //const [playOn] = useSound('/practicum/beep.mp3',{ volume: 0.25 });
	// const soundStart = () => {
    //     if (isSoundChecked) return;
    //     setIsSoundChecked(true);
    //     playOn();
	// };
	// const handleMouseOver = e => soundStart();const handleMouseOut = e => soundStop(); const soundStop = () => setIsSoundChecked(false);
