import React, {useEffect, useState} from 'react';
import "./Navbar.scss";
import Icon from '../../ui/Icon';
import {hasFeature, isAuth, profilePicture, signout} from "../../../helpers/auth";
import Modal from '../../Modal';
import socket from "../../../socket";
import {DB_REFRESH} from "../../ui/utils/gen_utils";
import {storageOrg, storageUser, storageClass} from "../../../redux/slices/storage";
import {useSelector, useDispatch} from "react-redux";
import {FEATURES} from "../../../api/config";
import {loadCountersNotebook, loadCountersOt, loadCountersPi} from "../../../redux/slices/lists";
import {toast} from "react-toastify";
import Notify from '../../ui/Notify';

const Navbar = ({match, history}) => {
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);
  const [buttons, setButtons] = useState([]);

  const documentUser = useSelector(storageUser);
  const documentOrg = useSelector(storageOrg);
  const documentClass = useSelector(storageClass);
  const {lists: {counters}} = useSelector(state => state);

  useEffect(() => {
    socket.on("FE-new-login", () => {
      signout(() => {
        history.push("/login");
        toast.info("Вход с другого устройства");
      });
    });
    socket.on("FE-new-tab", () => {
      toast.error("Вход с другого окна - перезагрузите страницу", {autoClose:false, closeButton:false});
    });

    return () => {
      socket.off("FE-new-login");
      socket.off("FE-new-tab");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuth().role > 1) return;

    dispatch(loadCountersPi(documentClass._id));
    dispatch(loadCountersOt(documentClass._id));
    dispatch(loadCountersNotebook(documentClass._id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentClass._id]);

  useEffect(() => {
    if (isAuth().role > 1) return;

    socket.on("FE-refresh-db", ({type}) => {
      if (type === DB_REFRESH.PI) dispatch(loadCountersPi(documentClass._id));
      if (type === DB_REFRESH.OTI) dispatch(loadCountersOt(documentClass._id));
      if (type === DB_REFRESH.NBI) dispatch(loadCountersNotebook(documentClass._id));
    });
    return () => {
      socket.off("FE-refresh-db");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExit = () => {
    if (isAuth()) {
      socket.emit("BE-leave-room", {leaver: isAuth()});
    }

    signout(() => {
      history.push("/login");
      toast.info("Выход");
    });
  };

  const handleItemClick = (to) => {
    if (to === "/login")
      setShowConfirm(true);
    else
      history.push(to);
  };

  useEffect(() => {
    const isVisible = (f) => (f.role.includes(isAuth().role) && (f.name ||
      (f.sub ? f.sub.some(s => hasFeature(documentOrg, s)) : true)));
    const isActive = (f) => [f.to, ...(f.alt ?? [])].includes(match.path) ||
      (f.sub && f.sub.some(s => [FEATURES[s].to, ...(FEATURES[s].alt ?? [])].includes(match.path)));

    const _buttons = Object.entries(FEATURES)
    .filter(([k, f]) => (f.name && isAuth().role < 3) ? hasFeature(documentOrg, k) : true)
    .map(([k, f]) => ({
      name: f.icon,
      to: f.to,
      title: f.title,
      isActive: isActive(f),
      visible: isVisible(f),
      notify: counters[k]
    }));
    _buttons.splice(-1, 0, {grow: true, visible: true});
    _buttons.push({name: 'log-out', to: '/login', alt: [], visible: true, title: "Выйти"});

    if (!_buttons[0].visible && !isAuth()) {
      signout(() => history.push("/login"));
    } else {
      setButtons(_buttons);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentClass._id, documentOrg, match, counters]);

  const exitCancel = () => {
    setShowConfirm(false);
  };

  useEffect(() => {
    if (!showConfirm) return ;
    const close = (e) => {
      if (e.keyCode === 27) exitCancel();
    };
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConfirm]);

  return (
    <div className="cor_navbar">
      {buttons.filter(b => b.visible).map((b, i) => (
        b.grow ? <div key={i} className="cor_navbar_grow"/> : (b.to === '/profile') ?
          <div className="profile__wrap" key={i}>
            <i className={"profile" + (b.isActive ? " active" : "")}
               onClick={() => handleItemClick(b.to)}>
              <div className="profile__img">
                {b.to === '/profile' && isAuth().picture ?
                  <img src={profilePicture(documentUser.picture)} alt={documentUser.name}/>
                  :
                  <Icon name={b.name} className={b.isActive ? "active" : ""}/>
                }
              </div>
              <div className="profile__content">
                <span className="profile__name">{documentUser.name}</span>
                <span className="profile__link">Перейти в профиль</span>
              </div>
            </i>
            <Notify value={b.notify} className="cor_navbar__notify"/>
          </div>
          :
          <span key={i} className="tp tp_center_right" data-tp={b.title}>
            <Icon name={b.name} className={b.isActive ? "active" : ""}
                  onClick={() => handleItemClick(b.to)}/>
            <Notify value={b.notify} className="cor_navbar__notify"/>
          </span>
      ))}
      <Modal visible={showConfirm} content={<>
        <div className="modal__message">Вы действительно хотите выйти?</div>
        <div className="modal__action">
          <button className="cor_btn cor_btn_primary" onClick={handleExit}>Выход</button>
          <button className="cor_btn" onClick={exitCancel}>Отмена</button>
        </div>
      </>} size={'xs'}/>
    </div>
  );
};
export default Navbar;
