import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import labsList from '../practicum/subjectList';
import {printOnlyDate} from "../../helpers/text";
import {storageClass} from "../../redux/slices/storage";
import socket from "../../socket";
import {DB_REFRESH} from "../ui/utils/gen_utils";
import {getLabWorkArraysAndNames, enabledEditScore} from "../practicum/lw_utils";
import {isAuth} from "../../helpers/auth";
import Table from "../ui/Table/Table";
import {getTableScoreList} from '../ui/utils/score_utils';
import {labWorkInfoApi} from "../../api/api";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";

const ProfileLabWorksTable = ({profile, setScores}) => {
	const [tableData, setTableData] = useState([]);
	const [isUpdated, setIsUpdated] = useState(false);
	const [canEditScore, setCanEditScore] = useState(false);
    const [counter, setCounter] = useState(0);
    const [showConfirmDlg, setShowConfirmDlg] = useState(false);
	const [idBeDeleted, setIdBeDeleted] = useState(undefined);

	const documentClass = useSelector(storageClass);

	useEffect(() => {
        socket.on("FE-refresh-db", ({type}) => {
            if (type === DB_REFRESH.LWI) setCounter(counter => counter + 1);
        });
        return () => {socket.off("FE-refresh-db");};
    }, []);

	useEffect(() => {
		setCanEditScore(enabledEditScore(isAuth(), documentClass.classUsers));
	},[documentClass.classUsers]);

    useEffect(() => {
        const fetchData = async () => {
			const result = await labWorkInfoApi.getLabWorkInfosByOwner(profile._id, documentClass._id);
			const labWorks = result.data;

			const list = [];
			let scores = [0, 0, 0, 0, 0];

			for (let i = 0; i < labWorks.length; i ++) {
				const lw = labWorks[i];
				const [subj, sect, lab] = getLabWorkArraysAndNames(labsList.subjectList, lw.subjectId, lw.sectionId, lw.labId);

				list.push({
					subject: !!subj && subj.subjectName,
					section: !!sect && sect.sectionName,
					lab: '' + (!!sect && (sect.labList.indexOf(lab) + 1)) + '. ' + (!!lab && lab.labName),
					date: printOnlyDate(lw.createdAt),
					result: '' + (lw.pageInd + 1) + ' из ' + (!!lab && lab.labPages.length),
					score: lw.score,
					actions: '',
					id: lw._id
				});

				scores = getTableScoreList(scores, lw.score);
			}

			setScores(scores);
			setTableData(list);

			if (isUpdated) setIsUpdated(false);
        }

		fetchData();
    },[documentClass._id, profile._id, isUpdated, setScores, canEditScore, counter]);

	const handleStudentTableDataUpdate = async (score, id) => {
		const scoreVal = score !== '' ? Number(score) : null;
		await labWorkInfoApi.updateLabWorkInfoScoreById(id, {score: scoreVal});
		socket.emit('BE-refresh-db', {type: DB_REFRESH.LWI, roomId: documentClass._id});
		setIsUpdated(true);
	};

	const handleDelete = id => {
		setIdBeDeleted(id);
		setShowConfirmDlg(true);
	};
	const handleDeleteNo = () => {
		setShowConfirmDlg(false);
		setIdBeDeleted(undefined);
	};

	const handleDeleteYes = () => {
		setShowConfirmDlg(false);
		labWorkInfoApi.deleteLabWorkInfoById(idBeDeleted)
		.then(res => {
			setIdBeDeleted(undefined);
			setIsUpdated(true);
			socket.emit('BE-refresh-db', {type: DB_REFRESH.LWI, roomId: documentClass._id});
		});
	};

	const getTableHeader = () => {
		if (isAuth().role === 0) {
			return [
				{column: 'Предмет', name: 'subject', style: { width: '10%'} }, 
				{column: 'Раздел', name: 'section', style: { width: '15%'} }, 
				{column: 'Название теста', name: 'lab', style: { width: '30%'} }, 
				{column: 'Дата', name: 'date', style: { width: '15%'} }, 
				{column: 'Прогресс выполнения', name: 'result', style: { width: '10%'} }, 
				{column: 'Оценка учителя', name: 'score', style: { width: '20%'} }, 
				{column: 'id', name: 'id', style: { width: '0%'} }, 
			];
		} else {
			return [
				{column: 'Предмет', name: 'subject', style: { width: '10%'} }, 
				{column: 'Раздел', name: 'section', style: { width: '15%'} }, 
				{column: 'Название теста', name: 'lab', style: { width: '20%'} }, 
				{column: 'Дата', name: 'date', style: { width: '15%'} }, 
				{column: 'Прогресс выполнения', name: 'result', style: { width: '10%'} }, 
				{column: 'Оценка учителя', name: 'score', style: { width: '20%'} }, 
				{column: 'Действия', name: 'actions', style: { width: '10%'} }, 
				{column: 'id', name: 'id', style: { width: '0%'} }, 
			];
		}
	};

    return ( 
		<div>
			<Table
				table={{
					header: getTableHeader(), 
					data: tableData, 
					handleTableDataUpdate: handleStudentTableDataUpdate, 
				}}
				sort={{
					hasSorting: true, 
					initSortInd: -4,
				}}
				actions={{
					handleDelete: id => handleDelete(id),
				}}
				canEditScore={canEditScore}
			/>

			{showConfirmDlg &&
			<ModalConfirmDialog
				showConfirmDlg={showConfirmDlg} 
				handleNo={handleDeleteNo}
				handleYes={handleDeleteYes}
				question={'Вы действительно хотите удалить этот тест?'}
			/>
			}
		</div>
    )
}

export default ProfileLabWorksTable;
