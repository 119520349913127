import React, { useState, useEffect, useRef } from "react";
import {Warning, AddFile, Trash} from '../ui';
import {toast} from "react-toastify";
import {QUESTION_TYPES, STATUS, getTextEditorOptionFieldList, getAnswerUrl,
    getMaxOfArray, getOneCorrectAnswer, getOptionFieldList, getMultipleCorrectAnswers, 
    uploadOTFile, deleteOTFileByName, getC2MinAnswerNum, checkC2Error } from "./ot_utils";
import {useDispatch} from "react-redux";
import "./OnlineTestCreate.scss";
import ss from './OnlineTestCreateQuestions.module.scss';

const Constructor2OneAnswer = ({
        isNewQuestion, currQuestionType, questionList, isTemplate, isEditable, currQuestionNum,
        //reinitQuestion, setReinitQuestion,
        setChangedData, setDataError, setCurrAnswerOptions, setCurrCorrectOptions}) => {
    const [correctOption, setCorrectOption] = useState('0');
    const [answerList, setAnswerList] = useState([]);
    const [currQuestion, setCurrQuestion] = useState(undefined);
    const [video, setVideo] = useState('');
    const [status, setStatus] = useState(0);
    const [loadedFileName, setLoadedFileName] = useState('');
    const [videoUrl, setVideoUrl] = useState(undefined);

    const dispatch = useDispatch();
	const startedRef = useRef(false);
    const minAnswerNumRef = useRef(-1);
    const answerListRef = useRef([]);

    const isOneAnswer = qType => qType === QUESTION_TYPES.CORRECT_ANSWER_ONE;
    const isMultipleAnswers = qType => qType === QUESTION_TYPES.CORRECT_ANSWER_MANY;
    const isCompleteSentence = qType => qType === QUESTION_TYPES.COMPLETE_SENTENCE;
    const isFreeWriting = qType => qType === QUESTION_TYPES.FREE_WRITING;    
    const isVideo = qType => qType === QUESTION_TYPES.VIDEO;

    useEffect(() => {
        startedRef.current = false;
        minAnswerNumRef.current = getC2MinAnswerNum(currQuestionType);
        setCurrQuestion(isNewQuestion ? undefined : {...questionList[currQuestionNum]});
        setStatus(STATUS.INIT);
    }, [questionList, currQuestionNum, isNewQuestion, currQuestionType]);

    // === 1 of 2 useEffects:
    useEffect(() => {
        if (status !== STATUS.INIT || minAnswerNumRef.current === -1) return;
        startedRef.current = true;

        if (isFreeWriting(currQuestionType)) {
            setStatus(STATUS.USE);
            return;
        }

        //Init new question:
        if (isNewQuestion) {
            //find initial answer list
            const answers = [];
            for (let i = 0; i < minAnswerNumRef.current; i ++) 
                answers.push({name: '', id: i, correct: false}); 
            answerListRef.current = answers;
            setAnswerList(answers);

            //find initial correct opts
            setCorrectOption('0');
            setStatus(STATUS.USE);
        } else if (currQuestion) { //check if loaded
            //Init existing question:
            const answerOptions = [...currQuestion.answerOptions];
            if (answerOptions.length < minAnswerNumRef.current)
                for (let i = 0; i < minAnswerNumRef.current - answerOptions.length; i++) 
                    answerOptions.push('');
    
            const answers = answerOptions.map((item, ind) => ({name: item, id: ind, correct: false}));
    
            if (isOneAnswer(currQuestionType) || isCompleteSentence(currQuestionType)) {
                setCorrectOption(currQuestion.correctOptions);
            } else if (isVideo(currQuestionType)) {
                const [opt, videoName] = currQuestion.correctOptions.split('|');
                setCorrectOption(opt); //contains correct option
                setVideo(videoName); //contains video name
            } else if (isMultipleAnswers(currQuestionType)) {
                //toast.info('correctOptions=A' + currQuestion.correctOptions + 'X')
                if (currQuestion.correctOptions) {
                    const options = currQuestion.correctOptions.split('|'); //contains the list of correct options
                    for (let i = 0; i < options.length; i ++) {
                        answers[Number(options[i])].correct = true;
                    }
                }
            }
    
            answerListRef.current = answers;
            setAnswerList(answers);
            setStatus(STATUS.USE);
        }
    }, [status, currQuestion, currQuestionType, isNewQuestion]);

    // === 2 of 2 useEffects:
    useEffect(() => {
        if (status !== STATUS.USE || !startedRef.current) return;

        if (isFreeWriting(currQuestionType)) {
            setDataError('');
            return;
        }

        //======== 1. check results: =============
        const errorMsg = checkC2Error(answerList, currQuestionType, video, minAnswerNumRef.current);
        setDataError(errorMsg);
        if (!!errorMsg) return;

        //======== 2. save results: =============
        //2.1 - correct answers:
        let optionsToSave = '';
        if (!isMultipleAnswers(currQuestionType)) {
            if (isVideo(currQuestionType)) { //видео держит инфу о файле в correctOption
                optionsToSave = correctOption + '|' + video; //merge correctOption and video name
            } else {
                optionsToSave = correctOption;
            }
        } else {
            optionsToSave = answerList.map((item, ind) => item.correct ? ind : -1)
                .filter(item => item >= 0)
                .map(item => item).join('|');
        }

        let changed = false;
        if (!currQuestion || currQuestion.correctOptions !== optionsToSave) {
            setCurrCorrectOptions(optionsToSave);
            changed = true;
        }

        //2.2. - answer list:
        const aOpts = answerList.map(item => item.name);
        if (!currQuestion || aOpts.length !== currQuestion.answerOptions.length || 
            aOpts.filter((item, ind) => item !== currQuestion.answerOptions[ind]).length > 0) {
                setCurrAnswerOptions(aOpts);
                changed = true;
        }

        if (changed && !isNewQuestion) { //исключаем новые вопросы, они сохраняются по кнопке save
            setChangedData(true);
        }
    }, [answerList, correctOption, currQuestion, status,
        setCurrAnswerOptions, setCurrCorrectOptions, isNewQuestion,
        setChangedData, setDataError, currQuestionType, video]);

    useEffect(() => {
        const findVideoUrl = async () => {
            if (!video)
                setVideoUrl(undefined);
            else if (isTemplate) {
                setVideoUrl(JSON.parse(video).content);
            } else {
                await getAnswerUrl(video, setVideoUrl);
            }
        };
        findVideoUrl();
    }, [video, answerList, isTemplate]);
    
    const handleChangeAnswer = (value, index) => {
        const answers = [...answerListRef.current];
        answers[index].name = value;
        answerListRef.current = answers;
        setAnswerList([...answers]);
    };

    const handleDeleteAnswerOption = (ind) => {
        let answers = [...answerListRef.current];
        answers = answers.filter((item, index) => index !== ind);
        answerListRef.current = answers;
        setAnswerList([...answers]);
    };

    const handleAddAnswerOption = () => {
        const answers = [...answerListRef.current];
        const maxId = getMaxOfArray(answers.map(item => item.id));
        answers.push({name: '', id: maxId + 1, correct: false});
        answerListRef.current = answers;
        setAnswerList([...answers]);
    };

    const handleToggleCorrectAnswers = (ind) => {
        const answers = [...answerListRef.current];
        answers[ind].correct = !answers[ind].correct;
        answerListRef.current = answers;
        setAnswerList([...answers]);
    };

    //step 1 of 2: get file
    const handleAddVideo = (event, _setVideo) => {
        if (!isTemplate) {
            uploadOTFile(event.target.files[0], _setVideo);
        } else {
            setLoadedFileName(event.target.files[0]); //keep the file name and content
        }

        event.target.value = '';
    };
    //step 2 of 2: save file (SA) name or file (teacher)
    useEffect(() => {
        if (!loadedFileName || !isTemplate) return;

        const reader = new FileReader();

        reader.onload = (event) => {
            //DB will keep the file data: name and content
            const fileOpts = {name: loadedFileName.name, content: event.target.result};
            setLoadedFileName('');
            const fileData = JSON.stringify(fileOpts);
            setCorrectOption(correctOption.split('|')[0] + '|' + fileData);
            setVideo(fileData);
        };

        reader.readAsDataURL(loadedFileName);
    }, [loadedFileName, correctOption, isTemplate]);

    const handleDeleteVideo = async () => {
        if (!isTemplate)
            deleteOTFileByName(dispatch, video);
        setVideo('');
    };

    const getFreeWriting = () => {
        return (
            <div className="cor-net__section border">
                <div className="cor-net__title">Варианты ответа</div>
                <div className="cor-net__row row-content">Пользователь должен написать текст в поле для текста.</div>
            </div>
        );
    };

    const getVideoQuestion = (currQuestion, videoUrl, video, setVideo, 
        handleAddVideo, handleDeleteVideo) => {
        if (!!video) {
            return (
                <div className={ss.video} key='v01'>
                    <video src={videoUrl} controls={true} key={"v03"}></video>
                    <Trash className={ss.video__trash} onClick={handleDeleteVideo} bg={true}/>
                </div>
            );
        };

        return (
            <div className='cor-net__section border'>
                {currQuestion && 
                <div className="cor-net__row">
                    <div className="cor-net__col col-3">
                        <Warning>Добавьте видео</Warning>
                    </div>
                </div>}

                <div className="cor-net__row">
                    <AddFile key='lbl03' text='Добавить видео' accept={'video/*'}
                        onChange={(e) => handleAddVideo(e, setVideo)}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            {isVideo(currQuestionType) && 
                getVideoQuestion(currQuestion, videoUrl, video, 
                    setVideo, handleAddVideo, handleDeleteVideo)}

            {/* Top part: answer options */}
            {(isOneAnswer(currQuestionType) || isMultipleAnswers(currQuestionType) || 
              isVideo(currQuestionType)) && status === STATUS.USE &&
                getTextEditorOptionFieldList(answerList, handleChangeAnswer, handleDeleteAnswerOption, 
                    handleAddAnswerOption, isEditable)
            }
            {isCompleteSentence(currQuestionType) &&
                getOptionFieldList(answerList, handleChangeAnswer, handleDeleteAnswerOption, 
                    handleAddAnswerOption, isEditable)
            }

            {/* Bottom part: select correct answer(s) */}
            {(isOneAnswer(currQuestionType) || isCompleteSentence(currQuestionType) || (isVideo(currQuestionType))) && 
                getOneCorrectAnswer(answerList, 
                    isVideo(currQuestionType) && isTemplate ? correctOption.split('|')[0] : correctOption, 
                    setCorrectOption, isEditable)}

            {isMultipleAnswers(currQuestionType) && 
                getMultipleCorrectAnswers(answerList, handleToggleCorrectAnswers)}
           
            {isFreeWriting(currQuestionType) && getFreeWriting()}
        </>
    )
}

export default Constructor2OneAnswer;
