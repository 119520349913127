import React, {useState, useEffect} from "react";
import { Icon, Button, Trash } from '../ui';
import { NB_STATES, NB_HOME_WORK_STATUS, isDisabled, getRunDates, prepareRun } from './nb_utils';
import { updateRunDate, updateCompleteDate, getSwitcher } from "../ui/utils/gen_utils";
import {useSelector} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {fullName} from "../../helpers/auth";
import DateTimePicker  from "../ui/DatePicker/DateTimePicker";
import ModalSelectStudentsDialog  from "../ui/ModalDialogs/ModalSelectStudentsDialog";
import "../template/Dashboard/Dashboard.scss";
import nb from "./Notebooks.module.scss";

const NotebookAddRun = ({ setNbState, setHomeWorkStatus, isEditable,
        homeWorkRun, setHomeWorkRun, homeWorkUsers, setHomeWorkUsers, 
        isRunChanged, setIsRunChanged, forceSaveRun, setForceSaveRun, setForceComplete }) => {
    const [runDate, setRunDate] = useState(null);
    const [completeDate, setCompleteDate] = useState(null);
    const [isAllUsers, setIsAllUsers] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [showSelectStudentsDlg, setShowSelectStudentsDlg] = useState(false);

    const documentClass = useSelector(storageClass);

	useEffect(() => {
        if (!homeWorkRun) return;

        const rDate = getRunDates(true, homeWorkRun.runFullDate);
        setRunDate(rDate); 

        const cDate = getRunDates(false, homeWorkRun.completeFullDate);
        setCompleteDate(cDate); 

        setIsAllUsers(homeWorkUsers.isAll ? true : false);
        setSelectedUsers([...homeWorkUsers.selectedUsers]);
	},[homeWorkRun, homeWorkUsers]);

    const handleBack = () => {
         if (isRunChanged) 
            setHomeWorkRun(prepareRun(runDate, completeDate));
        setNbState(NB_STATES.SETTINGS);
    };

    useEffect(() => {
        if (!forceSaveRun) return;
        setHomeWorkRun(prepareRun(runDate, completeDate));
        setHomeWorkUsers({isAll: isAllUsers, selectedUsers: isAllUsers ? [] : selectedUsers});
        setForceSaveRun(false);
        setIsRunChanged(false);
    },[runDate, completeDate, isAllUsers, selectedUsers, forceSaveRun, 
        setHomeWorkUsers, setHomeWorkRun, setForceSaveRun, setIsRunChanged]);
    
    const doHandleRun = (status) => {
        setHomeWorkStatus(status);
        setHomeWorkRun(prepareRun(runDate, completeDate));
        setHomeWorkUsers({isAll: isAllUsers, selectedUsers: isAllUsers ? [] : selectedUsers});
        setIsRunChanged(false);
        setForceComplete(true); //сохранить изменения и закрыть
    };

    const handleSaveInStore = () => {
        doHandleRun(NB_HOME_WORK_STATUS.CATALOG);
    };

    const handleRunNow = () => {
        doHandleRun(NB_HOME_WORK_STATUS.PROGRESS);
    };

    const doSetRunDate = value => {
        updateRunDate(value, completeDate, setRunDate, setCompleteDate, setIsRunChanged);
    };
    const doSetCompleteDate = value => {
        updateCompleteDate(value, runDate, setCompleteDate, setIsRunChanged);
    };

    const doSetIsAllUsers = isAll => {
        setIsAllUsers(isAll);
        setSelectedUsers(isAll ? [] : getUserList());
        setIsRunChanged(true);
    };

    const handleSelectedUsers = (ids) => {
        setSelectedUsers(ids);
        setShowSelectStudentsDlg(false);
    };

    const handleDeleteUser = id => {
        const userIds = selectedUsers.length > 0 ? 
            selectedUsers.filter(item => item !== id) : 
            documentClass.classUsers.filter(user => user.role === 0 && user._id !== id)
                .map(item => item._id);
        setSelectedUsers(userIds);
    };

    const showStudentNames = () => {
        const users = documentClass.classUsers.filter(user => user.role === 0)
            .filter(item => getUserList().includes(item._id));
        
        return users.map((item, index) => 
            <div className={nb.selectedStudents__item} key={'ss'+index}>
                <span className={nb.selectedStudents__name}>{fullName(item)}</span>
                <Trash 
                    className={nb.selectedStudents__trash} 
                    icon="close"
                    onClick={() => handleDeleteUser(item._id)}
                    disabled={false}
                />
            </div>
        );
    };

    const getUserList = () => {
        return selectedUsers.length > 0 ? 
            selectedUsers : 
            documentClass.classUsers.filter(user => user.role === 0).map(item => item._id);
    };

    return  (
        <>
            <div className={nb.nb__body}>
                <div className="cor-net">
                    <div className="cor-net__title">Запуск</div>

                    <div className="cor-net__section border">

                        <div className="cor-net__row">
                            <div className="cor-net__subtitle">Дата запуска</div>
                        </div>

                        <div className={"cor-net__row " + isDisabled(isEditable)}>
                            <div className="cor-net__col col-4">
                                <div className="cor-net__label">Задание будет запущено в указанную дату</div>
                                <DateTimePicker
                                    startDate={runDate} 
                                    setStartDate={doSetRunDate} 
                                    id={'RUN_DATE_PICKER_ID'}
                                />
                            </div>
                        </div>

                        <div className="cor-net__row">
                            <div className="cor-net__subtitle">Дата завершения</div>
                        </div>

                        <div className={"cor-net__row " + isDisabled(isEditable)}>
                            <div className="cor-net__col col-4">
                                <div className="cor-net__label">Крайний срок сдачи</div>
                                <div>
                                    <DateTimePicker
                                        startDate={completeDate} 
                                        setStartDate={doSetCompleteDate} 
                                        id={'START_DATE_PICKER_ID'}
                                    />
                                </div>
                            </div>
                        </div> 

                    </div>

                    <div className="cor-net__section border">

                        <div className="cor-net__subtitle">Выбор учеников</div>

                        <div className={"cor-net__row " + isDisabled(isEditable)}>
                            {getSwitcher('Отправить задание всем ученикам|Отправить задание выбранным ученикам', isAllUsers, () => doSetIsAllUsers(!isAllUsers))}
                        </div>

                        {!isAllUsers && <>
                        <div className="cor-net__row">
                            <div className="cor-net__label">Выбранные ученики</div>
                        </div>
                        
                        <div className="cor-net__row">
                            <div className="cor-net__col col-2">
                                <div className={nb.selectedStudents}>
                                    {showStudentNames()}
                                </div>
                            </div>
                        </div>
                        
                        <div className={"cor-net__row " + isDisabled(isEditable)}>
                            <div className="cor-net__reload"  
                                    onClick={() => setShowSelectStudentsDlg(true)}>
                                <Icon name={'plus'}/>
                                <span>Выбрать учеников</span>
                            </div>
                        </div>
                        </>}

                    </div>
                    
                </div>
            </div>
            
            <div className={nb.nb__footer}>
                <div className={nb.nb__footer_row}>
                    <div className={nb.nb__footer_col}>
                        <Button color="secondary"  size="medium" border={true} onClick={handleBack}>
                            Вернуться к настройкам
                        </Button>
                    </div>

                    <div className={nb.nb__footer_col}>
                        <Button className="cor_btn_icon_left" color="primary" size="medium" 
                            border={true} onClick={handleSaveInStore} disabled={!isEditable}>
                            <Icon name="save"/>
                            {' Сохранить задание в каталоге'}
                        </Button>
                        <Button color="primary" size = "medium" onClick={handleRunNow} disabled={!isEditable}>
                            Запустить задание
                        </Button>
                    </div>
                </div>
            </div>

            {showSelectStudentsDlg  &&
                <ModalSelectStudentsDialog 
                    showConfirmDlg={showSelectStudentsDlg}
                    studentList={documentClass.classUsers.filter(user => user.role === 0)}
                    selectedStudentIds={getUserList()}
                    handleNo={() => setShowSelectStudentsDlg(false)}
                    handleYes={ids => handleSelectedUsers(ids)}
                />
            }
        </>
    );
}

export default NotebookAddRun;
