import React, {useState, useEffect} from 'react';
import {isAuth, fullName} from "../../helpers/auth";
import {printOnlyDate} from "../../helpers/text";
import {NB_SAMPLE_TYPE, getNotebookById, getNotebookInfoById, getIsNbiIsNotStarted} from "./nb_utils";
import {getTaskAnswerOptions, getTaskAnswer, drawFormula, getTemplateWithVarNames} from "./task_utils";
import {Textarea} from 'rlabui';
import {userApi, notebookInfoApi} from "../../api/api";
import {useSelector, useDispatch} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {loadCountersNotebook, loadCountersNotebookOnSubject} from "../../redux/slices/lists";
import socket from "../../socket";
import {DB_REFRESH, getDropdown} from "../ui/utils/gen_utils";
import {getTemplatesBySubjectId} from "./templates";
import {SCORE_TYPE, getScoreCbList, getScoreName, getScoreValue, calcScoreValue} from '../ui/utils/score_utils';
import {Icon, Warning} from '../ui';
import {Button, Modal} from 'rlabui';
import resStyles from '../ReviewTest/Results.module.scss';

const NotebooksViewtResultByTeacherDlg = ({ showDlg, setShowDlg, selectedIds, setScoreUpdated }) => {
    const [studentName, setStudentName] = useState(undefined);
	const [templates, setTemplates] = useState([]);
    const [nbiId, setNbiId] = useState(undefined);
	const [nbiData, setNbiData] = useState(undefined);
	const [nbData, setNbData] = useState(undefined);
    const [isUpdated, setIsUpdated] = useState(false);
    const [showAnswers, setShowAnswers] = useState(true);

	const documentClass = useSelector(storageClass);
    const dispatch = useDispatch();

    //1. get studentId and nbiId:
    useEffect(() => {
        const [_studentId, _nbiId] = selectedIds.split('|');
        setNbiId(_nbiId);
        userApi.get(_studentId).then((data) => setStudentName(fullName(data)));
    }, [selectedIds]);

    //2. get nbi data nbiId:
    useEffect(() => {
		const fetchData = async (_nbiId) => {
			const _nbi = await getNotebookInfoById(_nbiId);
			setNbiData(_nbi);

			const _nb = await getNotebookById(_nbi.notebook);
            setNbData(_nb);
            const subjectId = _nb.titles.subjectId;
            setTemplates(getTemplatesBySubjectId(subjectId).templates);

            if (isAuth().role === 0 && _nb.settings.isAutoScore && 
                (!_nbi.score || _nbi.score < 0 || !_nbi.isComplete)) {
                setShowAnswers(false);
            }

            if (isUpdated) setIsUpdated(false);
		};

        if (!nbiId) return;
        fetchData(nbiId);
    }, [nbiId, isUpdated]);

    const handleClose = () => {
        setScoreUpdated(true);
        setShowDlg(false);
    };

    const handleChangedTask = (ind, isCorrect) => {
        const doChangeTask = async () => {
            const tasks = [...nbiData.tasks];
            tasks[ind].isCorrect = isCorrect;
            await notebookInfoApi.updateNotebookInfoById(nbiId, {tasks: tasks});

            const correctNum = tasks.filter(item => item.isCorrect === SCORE_TYPE.CORRECT).length;
            const scoreValue = calcScoreValue(correctNum, tasks.length);
            await doUpdateScore(scoreValue);
        };
        doChangeTask();
    };

    const doUpdateScore = async (value) => {
        const score = value !== '' ? Number(value) : null;
        await notebookInfoApi.updateNotebookInfoById(nbiId, {score: score});
        socket.emit('BE-refresh-db', {type: DB_REFRESH.NBI, roomId: documentClass._id});
        dispatch(loadCountersNotebook(documentClass._id)); 
        dispatch(loadCountersNotebookOnSubject(documentClass._id)); 
        setScoreUpdated(true);
        setIsUpdated(true);
    };

    const handleScore = value => {
        doUpdateScore(value);
    };

    const getScoreCell = () => {
        if (!nbiData) return <></>;
        const value = nbiData.score >= 0 ? nbiData.score : '';
        const score = getScoreValue(value);

		return getDropdown(getScoreCbList(), score, val => handleScore(val), 
				'', '', 'score01', "top");
	};

    const getTopPart = () => {
        if (!nbData || !nbiData || templates.length === 0) return <></>;
        const tasks = nbiData.tasks;
        const answerNum = nbData.settings.isAutoScore ?
            nbiData.tasks.filter(item => item.isCorrect !== SCORE_TYPE.UNDEFINED).length
            :
            nbiData.tasks.filter(item => item.userAnswerHistory).length;
        const correctAnswerNum = tasks.filter(item => item.isCorrect === SCORE_TYPE.CORRECT).length; 

        return (
            <div className={resStyles.info} key='res01'>
                <div className={resStyles.row} key='res02'>
                    <span className={resStyles.col} key='res03'>Имя ученика</span>
                    <span className={resStyles.col} key='res04'>{studentName}</span>    
                </div>
                <div className={resStyles.row} key='res05'>
                    <span className={resStyles.col} key='res06'>Название задания</span>
                    <span className={resStyles.col} key='res07'>{nbData?.titles.homeWorkName}</span>    
                </div>
                <div className={resStyles.row} key='res08'>
                    <span className={resStyles.col} key='res09'>Тема</span>
                    <span className={resStyles.col} key='res10'>{nbData.titles.homeWorkItem}</span>    
                </div>
                <div className={resStyles.row} key='res11'>
                    <span className={resStyles.col} key='res12'>Дата начала выполнения задания</span>
                    <span className={resStyles.col} key='res13'>{printOnlyDate(nbData.run.runFullDate)}</span>    
                </div>
                <div className={resStyles.row} key='res14'>
                    <span className={resStyles.col} key='res15'>Дата завершения выполнения задания</span>
                    <span className={resStyles.col} key='res16'>{printOnlyDate(nbData.run.completeFullDate)}</span>    
                </div>
                <div className={resStyles.row} key='res17'>
                    <span className={resStyles.col} key='res18'>Количество ответов</span>
                    <span className={resStyles.col} key='res19'>{answerNum} из {tasks.length}</span>    
                </div>
                {showAnswers &&
                <div className={resStyles.row} key='res20'>
                    <span className={resStyles.col} key='res21'>Количество правильных ответов</span>
                    <span className={resStyles.col} key='res22'>{correctAnswerNum}  из {tasks.length}</span>
                </div>
                }
                <div className={resStyles.row} key='res23'>
                    <span className={resStyles.col} key='res24'>Оценка</span>
                    <span className={resStyles.col} key='res25'>
                        {answerNum === 0 ? 'не сдано' : nbiData.score ? getScoreName(nbiData.score) : 'не выставлена'}
                    </span>
                </div>    
            </div>
        );
    };

    const getTheAnswer = (_templates, nb, task, ind, handleChangedTask) => {
        const taskSample = nb.taskSamples.find(item => item._id === task.sampleId);
        const isAutoScore = nb.settings.isAutoScore;
        const selectedTemplate = getTemplateWithVarNames(_templates, taskSample.templateId);
        if (!selectedTemplate) return <></>;
    
        const taskText = selectedTemplate.sampleTypeId === NB_SAMPLE_TYPE.EQUATION ? 'Решить уравнение': 
                         selectedTemplate.sampleTypeId === NB_SAMPLE_TYPE.GROUPING ? 'Группировать' : 'Решить задачу';
        const formula = drawFormula(selectedTemplate, task.formula);
        const answerHistory = task.isCorrect !== SCORE_TYPE.UNDEFINED || !isAutoScore ? 
            getAnswerHistory(_templates, isAutoScore, selectedTemplate, task, ind, handleChangedTask) : '';
            
        const answerOptions = getTaskAnswerOptions(task);
        const answer = getTaskAnswer(selectedTemplate, task.formulaInd, answerOptions[0].option);

        return (
        <>
            <div className={resStyles.answer__number} key={'num01'+ind}>
                Задача № <span key={'sp01'+ind}></span>
            </div>
            <div className={resStyles.answer__content} key={'cont01'+ind}>
                <div className={resStyles.answer__row} key={'row02'+ind}>
                    <div className={resStyles.answer__label} key={'qtext01'+ind}>
                        {taskText}
                    </div>
                    <div className={resStyles.answer__text} key={'qtext02'+ind}>
                        <b>{formula}</b>
                    </div>
                </div>

                {isAuth().role > 0 && !nb.settings.isAutoScore && 
                <div className={resStyles.answer__row} key={'row100'+ind}>
                    <div className={resStyles.answer__label} key={'qtext11'+ind}>
                        Правильный ответ
                    </div>
                    <div className={resStyles.answer__text} key={"key300"+ind} >
                        <span className={'success'}>{answer}</span>
                    </div>
                </div>
                }

                {taskSample.teacherDescription &&
                <div className={resStyles.answer__row} key={'row22'+ind}>
                    <div className={resStyles.answer__label} key={'qtext11'+ind}>
                        Комментарий преподавателя
                    </div>
                    <div className={resStyles.answer__textarea} key={'sp02'+ind}>
                        <Textarea 
                            autoComplete="off" 
                            value={taskSample.teacherDescription} 
                            name="description" 
                            rows="4" 
                            disabled={true}
                            key={'qanswer03'+ind}
                        />
                    </div>
                </div>
                }
                
                <div className={resStyles.answer__row} key={'row03'+ind}>
                    <div className={resStyles.answer__label} key={'row03a1'+ind}>
                        {nb.settings.isAutoScore ? 'История ответов' : 'Ответ'}
                    </div>
    
                    {answerHistory ? 
                        <div className={resStyles.answer__text} key={'qtext42'+ind} >
                            {answerHistory}
                        </div>
                    : 
                        <div className={resStyles.answer__text} key={'qtext43'+ind} >
                            <span className='error'>Нет ответа</span>
                        </div>
                    }
                </div>
            </div>
        </>
        );
    };
    
    const getAnswerHistory = (templates, isAutoScore, selectedTemplate, task, ind, handleChangedTask) => {
        if (templates.length === 0) return <div  key={'ah'}></div>;
    
        if (isAutoScore) {
            const answerOptions = getTaskAnswerOptions(task);
            const userAnswerHistoryList = task.userAnswerHistory.split('|');
            const usedAnswerOptions = [];
            
            for (let i = 0; i < userAnswerHistoryList.length; i ++) {
                const ind = Number(userAnswerHistoryList[i]);
                usedAnswerOptions.push(answerOptions[ind]);
            }

            const answerOptionsList = usedAnswerOptions.map((item, ind) => 
                <div key={"key0"+ind}>
                    {<span key={"key1"+ind}>{ind+1}){' '}</span>}
                    <span key={"key2"+ind}>
                        {getTaskAnswer(selectedTemplate, task.formulaInd, item.option, item.index === 0 ? 'success' : 'error')}
                    </span>
                    <span key={"key3"+ind} className={item.index === 0 ? 'success' : 'error'}>
                        {item.index === 0 ? 'верно' : 'неверно'}
                    </span>
                </div>
            );
    
            return answerOptionsList.map((item, ind) => item);
        } else { //add for student!!!!!
            const res = (
                <div key={"ah0"+ind}>
                    {!task.userAnswerHistory ?
                        <div className={resStyles.answer__text} key={'ah10'+ind} >
                            <span className='error'>Нет ответа</span>
                        </div>
                    :
                    <div key={"ah11"+ind}>
                        <div key={"ah1"}>
                            {task.userAnswerHistory}
                        </div>
    
                        <div key={"ah3"}>
                            {isAuth().role > 0 ?
                            <div className={resStyles.answer__options}>
                                <label className={resStyles.answer__option}>
                                    <input type="radio" name={"test"+ind}
                                        checked={task.isCorrect === SCORE_TYPE.CORRECT ? 'checked' : ''}
                                        onChange={() => handleChangedTask(ind, 1)}>
                                    </input>
                                    <span>Верно</span>
                                </label>
                                <label className={resStyles.answer__option}>
                                    <input type="radio" name={"test"+ind}
                                        checked={task.isCorrect === SCORE_TYPE.INCORRECT ? 'checked' : ''}
                                        onChange={() => handleChangedTask(ind, 0)}>
                                    </input>
                                    <span>Неверно</span>
                                </label>
                            </div>
                            :
                            <span key={"key3"+ind} className={task.isCorrect === SCORE_TYPE.CORRECT ? 'success' : 'error'}>
                                {task.isCorrect === SCORE_TYPE.CORRECT ? 'верно' : 
                                task.isCorrect === SCORE_TYPE.INCORRECT ? 'неверно' : 'не проверено'}
                            </span>
                            }
                        </div>
                    </div>
                    }
                </div>
                );
            return ([res]);
        }
    };
    
    if (!showDlg) {
        return null;
    }

    return (
        <Modal className={resStyles.root} visible={showDlg} size={'lg'}>
            <Modal.Head 
                className={resStyles.head} modalClose={handleClose} 
                title="Результаты выполнения задания"
            />

            {nbiData?.score === null &&
                <div className={resStyles.top} key={'t12'}>
                    <div className={resStyles.status} key={'t15'}>
                        <Icon name="warning"/>
                        <span key={'t16'}>Необходима проверка!</span>
                    </div>
                </div>    
            }
            {getTopPart()}

            {showAnswers &&
            <Modal.Body>
                <div className={resStyles.label} key={'t17'}>Задачи</div>
                {nbData && nbiData && 
                    <div className={resStyles.main} key={'t18'}>
                        <div className={resStyles.answer} key={'t22'}>
                            <div className={resStyles.answer__list} key='keyanswers'>
                                {nbiData.tasks.map((task, ind) => 
                                    <div className={resStyles.answer__item} key={'tsk'+ind}>
                                        {getTheAnswer(templates, nbData, task, ind, handleChangedTask)}
                                    </div> 
                                )}
                            </div>
                        </div>
                    </div>
                }
            </Modal.Body>
            }

            <Modal.Footer className={resStyles.footer}>
                {isAuth().role > 0 && <>
                    {!getIsNbiIsNotStarted(nbData, nbiData) ? 
                        <div className={resStyles.footer__dropdown}>
                            {getScoreCell()}
                        </div>
                    : 
                        <Warning className={resStyles.footer__warning}>Задание не выполнено</Warning> 
                    }
                </>}
                <Button onClick={handleClose}>Завершить</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default NotebooksViewtResultByTeacherDlg;
