import React, {useEffect} from 'react';
// import Modal from '../Modal';
import {Modal, Button} from 'rlabui';
import {rlabApi} from "../../api/api";
import {useFormik} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {isAuth} from "../../helpers/auth";
import "./Settings.scss";
import {useDispatch} from "react-redux";
import {loadRlabs} from "../../redux/slices/lists";
import {printShortDate} from "../../helpers/text";
import socket from "../../socket";

const RlabSettings = (props) => {
    const dispatch = useDispatch();

    const handleClose = () => {
        formik.resetForm();
        props.showRlabSettings({showModal: false})
    };

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) handleClose();
        };
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteRlab = async () => {
        try {
            await rlabApi.delete(props.rlab._id);
            socket.emit('BE-refresh-db', {roomId:props.rlab.room, type:'rlab', id:props.rlab._id});
            dispatch(loadRlabs(props.rlab.room, isAuth()._id))
            handleClose();
        } catch (error) {
            console.log(error);
        }
    };

    let error = {visible: false, message: ""};
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: props.rlab.name,
            desc: props.rlab.desc,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Введите название").max(60, 'Максимум 60 букв'),
            desc: Yup.string().max(100, 'Максимум 100 букв')
        }),
        onSubmit: async (formData) => {
            try {
                if (props.isAdd) {
                    const data = {
                        name: formData.name,
                        desc: formData.desc,
                        owner: isAuth()._id,
                        room: props.rlab.room,
                        startedAt: props.rlab.startedAt,
                        stoppedAt: props.rlab.stoppedAt,
                        data: props.rlab.data,
                    };

                    await rlabApi.add(data)
                    .then(() => {
                        handleClose();
                        toast.info("Эксперимент добавлен");
                    })
                    .catch((err) => {
                        toast.error(err.response.data.errors);
                    });
                } else {
                    const data = {
                        name: formData.name,
                        desc: formData.desc,
                    };
                    await rlabApi.update(props.rlab._id, data)
                    .then(() => {
                        handleClose();
                        dispatch(loadRlabs(props.rlab.room, isAuth()._id))
                        socket.emit('BE-refresh-db', {roomId:props.rlab.room, type:'rlab', id:props.rlab._id});
                    })
                    .catch((err) => {
                        toast.error(err.response.data.errors);
                    });
                }
            } catch (err) {
                error = {
                    visible: true,
                    message: JSON.stringify(err.errors, null, 2),
                };
                toast.error(error);
            }
        },
    });

    return (
        <Modal visible={props.showModal} size="sm">
            <form onSubmit={formik.handleSubmit}>
                <Modal.Head modalClose={handleClose} title={props.isAdd ? "Добавить эксперимент" : "Редактировать эксперимент"} />

                <Modal.Body>
                    <div key="name" className="cor_settings_vert">
                        <label htmlFor="name">Название эксперимента</label>
                        <div className="form_error">{formik.errors.name}</div>
                        <input
                            autoComplete="off"
                            placeholder="Название эксперимента"
                            className={formik.errors.name?"form_error":""}
                            name="name"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                    </div>
                    <div key="date" className="cor_settings_vert2">
                        <label htmlFor="startedAt">Начало эксперимента</label>
                        <input name="startedAt" value={printShortDate(props.rlab.startedAt)} disabled/>
                        <label htmlFor="stoppedAt">Окончание эксперимента</label>
                        <input name="stoppedAt" value={printShortDate(props.rlab.stoppedAt)} disabled/>
                    </div>
                    <div key="desc" className="cor_settings_vert">
                        <label htmlFor="desc">Описание эксперимента</label>
                        <div className="form_error">{formik.errors.desc}</div>
                        <textarea
                            autoComplete="off"
                            placeholder="Описание эксперимента"
                            name="desc"
                            rows="4"
                            className={formik.errors.desc?"form_error":""}
                            onChange={formik.handleChange}
                            value={formik.values.desc}
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer align = {!props.allowDelete ? 'right' : '' } >
                    {props.allowDelete && <Button color = 'danger' onClick={deleteRlab}>Удалить</Button>}
                    <Button type="submit">{props.isAdd ? "Добавить" : "Сохранить"} эксперимент</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
};

export default RlabSettings;