import React, {useEffect} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "./redux";
import socket from "../../../socket";
import {isAuth} from "../../../helpers/auth";
import {loadRlabs} from "../../../redux/slices/lists";
import {storageClass} from "../../../redux/slices/storage";
import RlabTable from "../../RlabTable";
import Footer from "../Footer";
import "./Dashboard.scss";
import { Back, Content, ContentBody, ContentHead, ContentTitle, ContentWrap } from "../ContentParts";

export const DashboardRlabExport = (props) => {
    const dispatch = useDispatch();
    const documentClass = useSelector(storageClass);
    const {lists: {rlabs}, cactions:{changeClass}} = useSelector(state => state);

    const getRlabs = () => dispatch(loadRlabs(documentClass._id, isAuth()._id));

    useEffect(() => {
        if (changeClass.to) {
            dispatch(loadRlabs(changeClass.to, isAuth()._id))
        }
    }, [dispatch, changeClass]);

    useEffect(() => {
        socket.on("FE-refresh-db", ({type}) => {
            if (type === 'rlab') getRlabs()
        });
        return () => {
            socket.off("FE-refresh-db");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentWrap>
            <ContentHead flex={false}>
                {props.match?.path==="/rlabExport" &&
                    <Back onClick={()=>props.history.push("/rlab")} icon="back" margin="bottom" />
                }
                <ContentTitle>Сохраненные эксперименты</ContentTitle>
            </ContentHead>
            <Content>
                <ContentBody>
                    <RlabTable {...props} rlabs={rlabs} refresh={getRlabs}/>
                </ContentBody>
            </Content>
            <Footer {...props}/>
        </ContentWrap>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardRlabExport)