import React, {forwardRef, useImperativeHandle, useState} from 'react';
import Icon from "../Icon";
import ss from './Password.module.scss';

const Password = forwardRef((props, ref) => {
  const [eye, setEye] = useState(false);

  useImperativeHandle(ref, () => ({setEye}));

  return (
    <div className={ss.password}>
      <input type={eye ? "text" : "password"}
             autoComplete="new-password"
             placeholder={props.placeholder}
             id={props.name}
             name={props.name}
             className={props.className}
             onChange={props.onChange}
             value={props.value}
      ></input>
      <Icon className={ss.eye} name={eye ? "eye" : "eye-hide"} onClick={() => setEye(!eye)}/>
    </div>
  )
});

export default Password;
