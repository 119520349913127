import React, { useState, useEffect, useRef } from "react";
import Table from "../ui/Table/Table";
import labsList from '../practicum/subjectList';
import {printOnlyDate} from "../../helpers/text";
import {vrLabInfoApi} from "../../api/api";
import {getSubjectName, getSectionName, getLabName} from '../practicum/lw_utils';
import {FEATURES} from "../../api/config";
import {getSelectedRunTopHeader, getHeader, getExceptionCellStyle} from "./rt_utils";
import {PROFILE_TAB}  from "../template/Dashboard/DashboardProfile";
import { Content, ContentBody } from "../template/ContentParts";
import {isAuth} from "../../helpers/auth";
import {useSelector, useDispatch} from "react-redux";
import {storageClass, storageOrg} from "../../redux/slices/storage";
import socket from "../../socket";
import {DB_REFRESH, getClassStudents} from "../ui/utils/gen_utils";
import {fetchclass} from "../../redux/slices/classsline";
import "./ReviewActiveLabWork.scss";

const ViewSelectedVrLabWorkRun = ({ history, selectedVrLabInd }) => {
	const [testRunStudentResults, setTestRunStudentResults] = useState([]);
	const [studentTableData, setStudentTableData] = useState([]);
	const [scoreUpdated, setScoreUpdated] = useState(false);
	const [counter, setCounter] = useState(0);
	
	const labWorkId = useRef('');
	const subjectName = useRef('');
	const sectionName = useRef('');
	const labName = useRef('');

    const documentOrg = useSelector(storageOrg);
	const documentClass = useSelector(storageClass);
    const dispatch = useDispatch();

	useEffect(() => {
		socket.on("FE-refresh-db", ({type}) => {
			if (type === DB_REFRESH.VRL) setCounter(counter => counter + 1);
		});
		return () => {socket.off("FE-refresh-db");};
   }, []);

    useEffect(() => {
        dispatch(fetchclass(isAuth()._id));
    }, [dispatch, documentOrg._id]);
	
	useEffect(() => {
		const labList = labsList.subjectList;
		const labId = 'lab' + (selectedVrLabInd <= 9 ? '0' : '') + selectedVrLabInd;
		subjectName.current = getSubjectName(labList, '01-physics', '04-optics', labId);
		sectionName.current = getSectionName(labList, '01-physics', '04-optics', labId);
		labName.current = getLabName(labList, '01-physics', '04-optics', labId);
		labWorkId.current = '01-physics|04-optics' + labId;
	},[selectedVrLabInd]);

	useEffect(() => { //2 ues be joined
		const getStudentResults = async () => {
			const result = await vrLabInfoApi.getAllVrLabInfosByClassStudentsByVrLabInd(documentClass._id, selectedVrLabInd);
			setTestRunStudentResults(result.data); 
			if (scoreUpdated)
				setScoreUpdated(false);
		}

		getStudentResults();
	},[documentClass._id, selectedVrLabInd, scoreUpdated, counter]);

	useEffect(() => {
		const getTestRunList = async () => {
			const students = getClassStudents(documentClass.classUsers);

			const list = [];
			for (let i = 0; i < students.length; i ++) {
				const student = students[i];
				const result = testRunStudentResults.find(item => item.owner === student.owner);

				if (result) {
					list.push({
						name: student.name, 
						result: result.step + ' из ' + result.stepAmount,
						progress: result.step <= 0 ? 'Не начата' : result.isComplete ? 'Готово' : 'Начата',
						date: printOnlyDate(result.updatedAt), 
						score: result && result.score !== undefined ? result.score.toString() : '',
						id: student.owner + '|' + result._id, 
						personPict: student.personPict
					});
				}
			}

			setStudentTableData(list);
		}

		if (testRunStudentResults.length === 0) return;
		getTestRunList();
	},[documentClass, testRunStudentResults]);

	const specialCellStyle = (item, rowInd, colInd, row) => getExceptionCellStyle(item);

	const handlePerson = (ids) => {
		const userId = ids.split('|')[0];
		history.push(FEATURES['profile'].to + '/' + userId + '/' + PROFILE_TAB.VIRT_LAB);
	}; 

	const handleScoreUpdate = async (score, ids) => {
		const testInfoId = ids.split('|')[1];
		const scoreVal = score !== '' ? Number(score) : null;
		await vrLabInfoApi.updateVrLabInfoScoreById(testInfoId, {score: scoreVal});
		setScoreUpdated(true);
		socket.emit('BE-refresh-db', {type: DB_REFRESH.VRL, roomId: documentClass._id});
	};

	const getTableHeader = () => {
		return [
			{column: 'Имя ученика', name: 'name', style: { width: '30%'}}, 
			{column: 'Прогресс выполнения', name: 'progress', style: { width: '15%'}},
			{column: 'Результаты', name: 'result', style: { width: '20%'}}, 
			{column: 'Дата', name: 'date', style: { width: '15%'}}, 
			{column: 'Оценка учителя', name: 'score', style: { width: '20%'} },
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];
	};

	const handleHistory = () => {
		history.push('/vrlab');
	};

    return (
		<>
			{getSelectedRunTopHeader(true, subjectName.current, undefined, handleHistory, 'Перейти к заданиям')}
			<Content>
				{getHeader(labName.current, sectionName.current, false, 0, undefined, undefined)}
				<ContentBody>
					<Table 
						table={{header: getTableHeader(), 
							data: studentTableData, 
							handleTableDataUpdate: handleScoreUpdate, 
							specialCellStyle: specialCellStyle
						}}
						sort={{hasSorting: true, 
							   initSortInd: 1
						}}
						person={{handlePerson: handlePerson}}
						canEditScore={true}
					/>	
				</ContentBody>
			</Content>
		</>					
    );
};

export default ViewSelectedVrLabWorkRun;
