import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {shouldReorderDates} from './rt_utils';
import {Tabs} from '../ui';
import {setIsLwView}  from "../../redux/slices/tutorialslice";
import socket from "../../socket";
import {DB_REFRESH, chkItemText, chkItemDate} from "../ui/utils/gen_utils";
import labsList from '../practicum/subjectList';
import Table from "../ui/Table/Table";
import SelectFilterOptions  from "./SelectFilterOptions";
import {LW_TAB_STATE, LW_MODE_TYPE, getSectionName, getLabName, 
	getLabWorkArraysAndNames} from "../practicum/lw_utils";
import {TEST_OPTION} from "../ui/utils/ServerInfo";
import {printOnlyDate} from "../../helpers/text";
import {labWorkRunApi, labWorkInfoApi, vrLabInfoApi} from "../../api/api";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import {isAuth} from "../../helpers/auth";
import {Content, ContentTitle, ContentBody, ContentHead, ContentHeader, Back} from "../template/ContentParts";
import "../template/Dashboard/Dashboard.scss";
import "./ViewLabWorks.scss";
import "./ReviewActiveLabWork.scss";

// таблица выаолненных лаб.работ
const ViewAllLabWorkRuns = ({ history }) => {
	const [joinedTable, setJoinedTable] = useState([]);
	const [filteredTable, setFilteredTable] = useState([]);
	const [testFilter, setTestFilter] = useState('');
	const [startDate, setStartDate] = useState(null);
	const [finishDate, setFinishDate] = useState(null);
    const [showConfirmDelete, setShowConfirmDelete] = useState('');
    const [idBeDeleted, setIdBeDeleted] = useState('');
    const [tableUpdated, setTableUpdated] = useState(false);
	const [counter, setCounter] = useState(0);

    const documentClass = useSelector(storageClass);
	const dispatch = useDispatch();

	useEffect(() => {
		socket.on("FE-refresh-db", ({type}) => {
			if (type === DB_REFRESH.LWI || type === DB_REFRESH.VRL) setCounter(counter => counter + 1);
		});
		return () => {socket.off("FE-refresh-db");};
   }, []);
	
    useEffect(() => {
		const getLabWorkRuns = async () => {
			const owners = [isAuth()._id];
			const laboratoryList = labsList.subjectList;
			const result = await labWorkRunApi.getLabWorkRunByClassOwners(owners, documentClass._id);
			const list = [];
		
			for (let i = 0; i < result.data.length; i++) {
				const test = result.data[i];
	
				const row = {
					lab: getLabName(laboratoryList, test.subjectId, test.sectionId, test.labId),
					section: getSectionName(laboratoryList, test.subjectId, test.sectionId, test.labId),
					type: 'Тест',
					date: test.createdAt,
					actions: '',
					id: 'lw|' + test._id
				};
	
				list.push(row);
			}

			return list;
		};

		const getVrLabWorkRuns = async () => {
			const owners = documentClass.classUsers.filter(item => item.role === 0)
				.map(item => item._id).join('|');
	
			const result = await vrLabInfoApi.getAllVrLabInfosByAllClassStudents(owners);
			const data = result.data;

			const laboratoryList = labsList.subjectList;
			const [,sect,] = getLabWorkArraysAndNames(labsList.subjectList, '01-physics', '04-optics', undefined);	
			const list = [];
		
			for (let i = 0; i < sect.labList.length; i++) {
				const vrLabInd = i + 1;
				const found = data.find(item => item.vrLabInd === vrLabInd);

				if (found) {
					const labId = 'lab' + (vrLabInd <= 9 ? '0' : '') + vrLabInd;
					const row = {
						lab: getLabName(laboratoryList, '01-physics', '04-optics', labId),
						section: getSectionName(laboratoryList, '01-physics', '04-optics', labId),
						type: 'Игра',
						date: found.createdAt,
						actions: '',
						id: 'vrlab|' + vrLabInd
					};
					list.push(row);
				}
			}
			return list;
		};
	
		const getAllRuns = async () => {
			const lwRuns = await getLabWorkRuns();
			const vrRuns = await getVrLabWorkRuns();
			setJoinedTable(lwRuns.concat(vrRuns));
			if (tableUpdated)
				setTableUpdated(false);
		};

		getAllRuns();
    }, [documentClass._id, documentClass.classUsers, tableUpdated, counter]);

	useEffect(() => {
		if (shouldReorderDates(startDate, finishDate)) { //check startDate <= finishDate
			setStartDate(finishDate);
			setFinishDate(startDate);
		}
    }, [startDate, finishDate]);
	
	useEffect(() => {
		//filtering:
		const list = [];
		for (let i = 0; i < joinedTable.length; i ++) {
			const row = joinedTable[i];

			if ((chkItemText(row, 'lab', testFilter) || chkItemText(row, 'section', testFilter)) && 
				chkItemDate(row.date, startDate, true) && chkItemDate(row.date, finishDate, false)) {
				const newRow = {...row};
				newRow.date = printOnlyDate(row.date);
				list.push(newRow);
			}
		}

		setFilteredTable(list);
    }, [joinedTable, testFilter, startDate, finishDate]);

	const handleRunningTestDetails = id => {
		history.push('/reviewtest/' + id.replace('|', '/'));
	};
	const handleConfirmDeleteRunningTest = id => {
		setIdBeDeleted(id);
		setShowConfirmDelete(true);
	};
	const handleDeleteRunningTest = async () => {
		setShowConfirmDelete(false);
		const [type, id] = idBeDeleted.split('|');
		if (type === TEST_OPTION.LW) {
			await labWorkInfoApi.deleteLabWorkInfosByRunId(id);
			await labWorkRunApi.deleteLabWorkRunById(id);
			socket.emit('BE-refresh-db', {type: DB_REFRESH.LWI, roomId: documentClass._id});
		} else {
			await vrLabInfoApi.deleteVrLabInfoByRoomByInd(documentClass._id, id);
			socket.emit('BE-refresh-db', {type: DB_REFRESH.VRL, roomId: documentClass._id});
		}

		setIdBeDeleted('');
		setTableUpdated(true);
	};

    const handleLwMode = (tab) => {
		if (tab.status === LW_MODE_TYPE.HISTORY) return;
		dispatch(setIsLwView(true));
		history.push(tab.path);
	};

	const getTableHeader = () => {
		const header = [
			{column: 'Название эксперимента', name: 'lab', style: { width: '25%'} }, 
			{column: 'Раздел', name: 'section', style: { width: '20%'} }, 
			{column: 'Тип эксперимента', name: 'type', style: { width: '15%'} }, 
			{column: 'Дата', name: 'date', style: { width: '18%'} }, 
			{column: 'Действия с тестом', name: 'actions', style: { width: '22%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];

		return header;
	};

	return (
		<>
			<ContentHead column={true}>
				<Back onClick={() => history.push("/practicum")} icon="back" />
				<ContentTitle>Лабораторные работы</ContentTitle>
				<Tabs>
					{LW_TAB_STATE.map(tab => (
						<Tabs.Item 
							active={tab.status === LW_MODE_TYPE.HISTORY}
							onClick={() => handleLwMode(tab)}
							key={tab.status}
						>
							{tab.name}
						</Tabs.Item>
					))}
				</Tabs>
	        </ContentHead>
			
			<Content>
				<ContentHeader>
					<SelectFilterOptions
						textPrompt={'Введите название предмета, раздела или лабораторной работы'}
						testFilter={testFilter}
						setTestFilter={setTestFilter}
						startDate={startDate}
						setStartDate={setStartDate}
						finishDate={finishDate}
						setFinishDate={setFinishDate}
					/>
				</ContentHeader>
				<ContentBody>
					<Table 
						table={{
							header: getTableHeader(), 
							data: filteredTable,
							}}
						sort={{
							hasSorting: true, 
							initSortInd: -4, 
						}}
						person={undefined}
						actions={{
							handleShowDetails: id => handleRunningTestDetails(id),
							textShowDetails: 'Смотреть результаты',
							handleDelete: id => handleConfirmDeleteRunningTest(id),
						}}								
					/>
				</ContentBody>
			</Content>

			{showConfirmDelete &&
		   	<ModalConfirmDialog
				showConfirmDlg={showConfirmDelete} 
				handleNo={() => setShowConfirmDelete(false)}
				handleYes={handleDeleteRunningTest}
				question={'Вы действительно хотите удалить результаты этого теста?'}
			/>
			}
		</>
	);
}

export default ViewAllLabWorkRuns;
