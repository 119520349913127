const initialState = {
    showChat: true,
    silence: false,
    raiseHand: false,
    video: false,
    audio: false,
    screen: false,
    sensor: false,
    isMobile: false,
};

const toggleReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_CHAT':
            return {
                ...state,
                showChat: action.payload,
            };

        case 'TOGGLE_RAISE':
            return {
                ...state,
                raiseHand: action.payload,
            };

        case 'TOGGLE_SILENCE':
            return {
                ...state,
                silence: action.payload,
            };

        case 'TOGGLE_VIDEO':
            return {
                ...state,
                video: action.payload,
            };

        case 'TOGGLE_AUDIO':
            return {
                ...state,
                audio: action.payload,
            };

        case 'TOGGLE_SCREEN':
            return {
                ...state,
                screen: action.payload,
            };

        case 'TOGGLE_SENSOR':
            return {
                ...state,
                sensor: action.payload,
            };

        case 'TOGGLE_MOBILE':
            return {
                ...state,
                isMobile: action.payload,
            };

        default:
            return state;
    }
};

export default toggleReducer;

