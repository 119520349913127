import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {isAuth} from "../../../helpers/auth";
import {fetchclass, fetchUsersByOrg, selectclass, selectusers,} from "../../../redux/slices/classsline";
import {reloadClass, storageClass, storageOrg} from "../../../redux/slices/storage";
import {MembersGrid} from "../../Grids";
import Footer from "../Footer";
import "./Dashboard.scss";
import { classApi} from "../../../api/api";
import { Button } from 'rlabui';
import {mapMembersStateToProps, mapMembersDispatchToProps} from "./redux";
import {UserSelect} from "../../Settings";
import { Back, Content, ContentBody, ContentHead, ContentWrap } from "../ContentParts";

export const DashboardClassMembers = (props) => {
    const dispatch = useDispatch();
    const documentClass = useSelector(storageClass);
    const documentOrg = useSelector(storageOrg);
    const [classs] = useSelector(selectclass);
    const [usersList] = useSelector(selectusers);
    const [searchTeach, setSearchTeach] = useState('');
    const [searchStud, setSearchStud] = useState('');

    const allowEditTeach = isAuth().role > 1;
    const allowEditStud = isAuth().role > 0;

    useEffect(() => {
        if (props.match.params.id) {
            classApi.getclassById(props.match.params.id).then((cl) => {
                dispatch(reloadClass(cl));
            });
        }
    }, [dispatch, props.match.params.id]);

    useEffect(() => {
        dispatch(fetchclass(documentOrg._id));
        dispatch(fetchUsersByOrg(documentOrg._id))
    }, [dispatch, documentOrg._id]);

    const getUserCount = role => usersList.filter(u => u.role === role && u.status !== -1 &&
      classs.filter(c => c._id === documentClass._id)[0]?.classUsers.includes(u._id)).length;

    //   console.log(documentClass.className);

    return (
        <>
            <ContentWrap>
                <ContentHead title={"Класс " + documentClass.className} />
                <Back onClick={() => props.history.goBack()} margin="top"/>
                <Content>
                    <ContentBody>
                        <div className="cor-net__section">
                            <div className="cor-net__row">
                                <div className="cor-net__col col-8">
                                    <div className="cor-net__title mb_0">Учителя ({getUserCount(1)})</div>
                                </div>
                                {/* в макете нет фильтрации */}
                                {/* <div className="cor-net__col col-icon col-6">
                                    <input className="filter" value={searchTeach} placeholder="Введите имя"
                                        onChange={(e) => setSearchTeach(e.target.value)}/>
                                    <Icon name="search"/>
                                </div> */}
                                {allowEditTeach && <>
                                <div className="cor-net__col col-8">
                                    <Button 
                                        onClick={() => props.showUserSelect({showModal: true, role: 1})}
                                        border={true}
                                        // color="muted"
                                    >
                                        Добавить учителей из базы
                                    </Button>
                                </div>
                                <div className="cor-net__col col-8">
                                    <Button 
                                        onClick={() => props.showUserSettings({showModal: true, role: 1, isAdd: true, user: {city: documentOrg.city}, owner: documentOrg._id})}
                                        border={true}
                                    >
                                        Создать Учителя
                                    </Button>
                                </div>
                                <div className="cor-net__col col-grow"></div>
                                <div className="cor-net__col col-8">
                                    <Button 
                                        onClick={() => {}}
                                        border={true}
                                        disabled={true}
                                    >
                                        Выделить всех
                                    </Button>
                                </div></>}
                            </div>
                        </div>

                        <div className="cor-net__section">
                            <MembersGrid disabled={!allowEditTeach} usersList={
                                usersList.filter(u => u.role === 1 && u.status !== -1 &&
                                ([u.name, u.name2, u.name3, u.login, u.email]).some(s => s?.toLowerCase().includes(searchTeach.toLowerCase())) &&
                                classs.filter(c => c._id === documentClass._id)[0]?.classUsers.includes(u._id))}/>
                        </div>
                            
                        <div className="cor-net__section">
                            <div className="cor-net__row">
                                <div className="cor-net__col col-8">
                                    <div className="cor-net__title mb_0">Ученики ({getUserCount(0)})</div>
                                </div>
                                {/* в макете нет фильтрации */}
                                {/* <div className="cor-net__col col-icon col-6">
                                    <input className="filter" value={searchStud} placeholder="Введите имя"
                                        onChange={(e) => setSearchStud(e.target.value)}/>
                                    <Icon name="search"/>
                                </div> */}
                                {allowEditStud && <>
                                <div className="cor-net__col col-8">
                                    <Button
                                        onClick={() => props.showUserSelect({showModal: true, role: 0})}
                                        border={true}
                                    >
                                        Добавить учеников из базы
                                    </Button>
                                </div>
                                <div className="cor-net__col col-8">
                                    <Button
                                        onClick={() => props.showUserSettings({showModal: true, role: 0, isAdd: true, user: {city: documentOrg.city}, owner: documentOrg._id})}
                                        border={true}
                                    >
                                        Создать Ученика
                                    </Button>
                                </div>
                                <div className="cor-net__col col-grow"></div>
                                <div className="cor-net__col col-8">
                                    <Button 
                                        onClick={() => {}}
                                        border={true}
                                        disabled={true}
                                    >
                                        Выделить всех
                                    </Button>
                                </div></>}
                            </div>
                        </div>

                        <div className="cor-net__section">
                            <MembersGrid disabled={!allowEditStud} usersList={
                                usersList.filter(u => u.role === 0 && u.status !== -1 &&
                                ([u.name, u.name2, u.name3, u.login, u.email]).some(s => s?.toLowerCase().includes(searchStud.toLowerCase())) &&
                                classs.filter(c => c._id === documentClass._id)[0]?.classUsers.includes(u._id))}/>
                        </div>

                    </ContentBody>
                </Content>
                <Footer {...props}/>
                <UserSelect/>
            </ContentWrap>
   
        </>
    );
}
export default connect(mapMembersStateToProps, mapMembersDispatchToProps) (DashboardClassMembers)