import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import { getTutorialSubjectId, getTutorialSectionId, setTutorialSubjectId, 
        setTutorialSectionId, setTutorialLabId }  from "../../redux/slices/tutorialslice";
import ModalInitTestByTeacherDlg from "../ui/ModalDialogs/ModalInitTestByTeacherDlg";
import {setIsLwView}  from "../../redux/slices/tutorialslice";
import {Icon, Switcher} from '../ui';
import labsList from './subjectList';
import {Tabs} from '../ui';
import {TEST_TYPE} from "../ui/utils/ServerInfo";
import {FEATURES} from "../../api/config";
import {LW_TAB_STATE, LW_MODE_TYPE, removeTeacherWorkInfo, formLabWorkId,
    getLabWorkArraysAndNames} from './lw_utils';
import {ContentHead, Content, ContentTitle, ContentBody, Back} from '../template/ContentParts';
import "./TutorialSelectLabs.scss";
import "./TutorialSelectTab.scss";

const ACTION = {
    DEMO: 1,
    TEST: 2,
};

const isGameEnabled = ind => {
    const ENABLED_GAMES = [1, 3, 5];
    for (let i = 0; i < ENABLED_GAMES.length; i ++)
        if (ENABLED_GAMES[i] === Number(ind) + 1) return true;
    return false;
};

const LabWorks = ({ history }) => {
    const [subjectId, setSubjectId] = useState('01-physics');
    const [sectionId, setSectionId] = useState('01-mechanics');
    const [labId, setLabId] = useState('');
    const [sectionIndex, setSectionIndex] = useState(0);
    const [sectionName, setSectionName] = useState('');
    const [sectionLabList, setSectionLabList] = useState([]);
    const [actionType, setActionType] = useState(ACTION.DEMO);
    const [testType, setTestType] = useState(TEST_TYPE.LAB_WORK);
    const [laboratoryList, setLaboratoryList] = useState([]);
    const [selectedSectionIndex, setSelectedSectionIndex] = useState(-1);
    const [showInitialTestModal, setShowInitialTestModal] = useState(false);
    const dispatch = useDispatch();

	const _subjectId = useSelector(getTutorialSubjectId);
	const _sectionId = useSelector(getTutorialSectionId);

    useEffect(() => {
        setLaboratoryList(labsList.subjectList);
        setSubjectId(_subjectId);
        setSectionId(_sectionId);
        removeTeacherWorkInfo();
    }, [_subjectId, _sectionId]);

    useEffect(() => {
        if (laboratoryList.length === 0) return;
        
		const [, sect,] = getLabWorkArraysAndNames(laboratoryList, subjectId, sectionId, undefined);
        setSectionName(sect.sectionName);
		setSectionLabList(sect.labList);
    }, [subjectId, sectionId, laboratoryList]);

    const handleClickSection = (subjId,  sectId, sectInd) => {
        setSubjectId(subjId);
        setSectionId(sectId);
        setSectionIndex(sectInd);
    };

    const handleOpenLabWork = (_actionType, _testType) => {
        dispatch(setTutorialSubjectId(subjectId));
        dispatch(setTutorialSectionId(sectionId));
        dispatch(setTutorialLabId(labId));

        if (_actionType === ACTION.DEMO) {
            if (_testType === TEST_TYPE.LAB_WORK)
                history.push(FEATURES.lw.to);
            else {
                history.push(FEATURES.vrlab.to + '/' + Number(labId.substring(3)));
            }
        } else {
            setShowInitialTestModal(true);
            setTestType(_testType);
        }
    };

    const handleClickToggle = () => {
        setActionType(actionType === ACTION.DEMO ? ACTION.TEST : ACTION.DEMO);
    };

    const handleMouseOverSection = (ind, _labId) => {
        setSelectedSectionIndex(ind);
        setLabId(_labId);
    };

    const handleMouseOutSection = () => {
        setSelectedSectionIndex(-1);
    };

    const handleClickToggleByButton = () => {
        const toggle = document.getElementById('toggleTest');
        toggle.click();
    };

    const handleLwMode = (tab) => {
		if (tab.status === LW_MODE_TYPE.LABWORKS) return;
		dispatch(setIsLwView(false));
		history.push(tab.path);
	};

    const getSubjectSections = () => {
        const getSubject = (item, itemInd) => {
            //Физика ...
            const iconName = itemInd === 0 ? 'phisic' : itemInd === 1 ? 'chemistry' : 'biology';
            const subject = (
                <div className='cor_practicumLab__title'>
                    <span className='cor_practicumLab__subjectIcon'><Icon name={iconName} />&nbsp;</span>
                    <span>{item.subjectName}</span>
                </div>
            );
            return subject;
        };

        const isSelected = (item, lab) => {return (subjectId === item.subjectId) && (sectionId === lab.sectionId);};

        const getSection = (item, lab, ind) => {
            //1. Механика ...
            const getToggleText = (isDemo) => {
                const text = isDemo ? 'Демонстрация' : 'Тестирование';
                const active = (actionType === ACTION.DEMO && isDemo) || (actionType !== ACTION.DEMO && !isDemo);
                return <label htmlFor="toggleTest" className={active ? 'active' : 'cor_practicumLab__rowToggleNotactve'}>{text}</label>;
            };

            let section;
            if (!isSelected(item, lab)) {
                section = (
                    <div className="cor_practicumLab__row" key={ind*1000}>
                        <div className="cor_practicumLab__rowName" id={item.subjectId + '/' + lab.sectionId} key={ind}
                            onClick={() => handleClickSection(item.subjectId, lab.sectionId, ind)}
                            onMouseOut={handleMouseOutSection}
                            >
                            {(ind + 1)}. {lab.sectionName}
                        </div>
                    </div>
                );

            } else {

                section = (
                    <div key={ind*1000} className='cor_practicumLab__row cor_practicumLab__row_selected'>
                        <span className='cor_practicumLab__rowName'>
                            <button onClick={handleClickToggleByButton} className='cor_practicumLab__rowBtn'>
                            {(ind + 1)}. {lab.sectionName}
                            </button>
                        </span>
                        <span className='cor_practicumLab__rowToggle'>
                            {getToggleText(true)}
                            <Switcher
                                onChange={handleClickToggle}
                                checked={actionType === ACTION.DEMO ? false : true}
                                id="toggleTest"
                                className="contrast"
                                />
                            {getToggleText(false)}
                        </span>
                    </div>
                );
            }

            return section;
        };

        const sections = laboratoryList.map((item, itemInd) =>
            <div className='cor_practicumLab__item' key={item.subjectId}>
                {getSubject(item, itemInd)}
                <div className="cor_practicumLab__list">
                    {item.sectionList.map((lab, ind) => getSection(item, lab, ind))}
                </div>
            </div>);

        return sections;
    };

    const getChoice = (_handleRun, _handleGame, isPersonalGame) => {
        return (
            <div className="cor_practicumLabList__drop">
                <Icon 
                    className='cor_practicumLabList__toggle'
                    name='play-trigon'
                />
                <div className='cor_practicumLabList__dropList'>
                    <div className='cor_practicumLabList__dropItem' onClick={_handleRun}>
                        <Icon name='checkmark-list' />
                        <p>Запустить {isPersonalGame ? 'демонстрацию' : 'тестирование'}</p>
                    </div>
                    <div className='cor_practicumLabList__dropItem' onClick={_handleGame}>
                        <Icon name='user' />
                        <p>Запустить игру {isPersonalGame ? ' для себя' : ''}</p>
                    </div>
                </div>
            </div>
        );		
    };    

    const getSectionLabs = () => {
        const getSectionLab = (item, ind) => {
            const isSectionSelected = (selectedSectionIndex === ind);

            let section;
            if (!isSectionSelected) {
                section = (
                    <div key={item.labId} className='cor_practicumLabList__item'>
                        <div className='cor_practicumLabList__row'
                            onMouseOver={() => handleMouseOverSection(ind, item.labId)}>
                               <div className="cor_practicumLabList__name">{(ind+1)}. {item.labName}</div>
                        </div>
                </div>);
            } else {
                section = (
                    <div key={ind*1000} className='cor_practicumLabList__item cor_practicumLabList__item_selected'>
                        <div className='cor_practicumLabList__row'>
                            <span className='cor_practicumLabList__name'>{(ind+1)}. {item.labName}</span>

                            {(sectionId !== "04-optics" || !isGameEnabled(ind)) ?
                                <Icon 
                                    className='cor_practicumLabList__toggle'
                                    name='play-trigon'
                                    tp={actionType === 1 ? 'Демонстрация' : 'Тестирование'}
                                    position='tp_center_left'
                                    onClick={() => handleOpenLabWork(actionType, TEST_TYPE.LAB_WORK)}
                                />
                      
                            :
                                <>
                                {getChoice(
                                    () => handleOpenLabWork(actionType, TEST_TYPE.LAB_WORK), 
                                    () => handleOpenLabWork(actionType, TEST_TYPE.LW_OPTIC_GAME), 
                                    actionType === ACTION.DEMO)}
                                </>
                            }
                        </div>
                    </div>);
            }

            return section;
        };

        let labs = <></>;
        if (sectionLabList && sectionLabList.length > 0) {
            labs = (
                <div id='labs' className='cor_practicumLabList'>
                    <div className='cor_practicumLabList__title'>{(sectionIndex + 1)}. {sectionName}</div>
                    <div className='cor_practicumLabList__box'></div>
                    {sectionLabList.map((item, ind) => getSectionLab(item, ind))}
                </div>
            );
        }
        return labs;
    };

    //getSubjectSections - левая часть, getSectionLabs - правая часть
    return (
        <>
            <ContentHead column={true}>
                <Back onClick={() => history.push("/practicum")} icon="back" />
				<ContentTitle>Лабораторные работы</ContentTitle>
                <Tabs>
                    {LW_TAB_STATE.map(tab => (
                        <Tabs.Item 
                            active={tab.status === LW_MODE_TYPE.LABWORKS}
                            onClick={() => handleLwMode(tab)}
                            key={tab.status}
                        >
                            {tab.name}
                        </Tabs.Item>
                    ))}
                </Tabs>
	        </ContentHead>

            <Content>
                <ContentBody scrolled={false}>
                    <div className='cor_practicum'>
                        <div className='cor_practicum__col'>
                            <div className="cor_practicum__scroll">
                                {getSubjectSections()}
                            </div>
                        </div>
                        <div className='cor_practicum__col'>
                            <div className="cor_practicum__scroll">
                                {getSectionLabs()}
                            </div>
                        </div>
                    </div>
                </ContentBody>
            </Content>
            {showInitialTestModal &&
            <ModalInitTestByTeacherDlg
                showModal={showInitialTestModal} 
                setShowModal={setShowInitialTestModal} 
                workId={formLabWorkId(subjectId, sectionId, labId)}
                testType={testType}
                history={history}
            />
            }
        </>
    );
};

export default LabWorks;
