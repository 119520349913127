import React, {useState, useEffect} from "react";
import {storageOrg} from "../../redux/slices/storage";
import {fullName, isAuth} from "../../helpers/auth";
import {tutorialApi} from "../../api/api";
import {toast} from "react-toastify";
import Table from "../ui/Table/Table";
import {printOnlyDate} from "../../helpers/text";
import {FilterByText} from "../ui";
import {useSelector} from "react-redux";
import {Back, Content, ContentBody, ContentHead, ContentHeader} from "../template/ContentParts";
import nb from "../Notebook/Notebooks.module.scss";
import {Button} from 'rlabui';
import {TUTORIAL_SUBJECTS} from "./TutorialConfig";
import {chkItemText} from "../ui/utils/gen_utils";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import {FEATURES} from "../../api/config";

const TutorialSections = (props) => {
  const {history} = props;
  const subject = Number(props.match.params.opt);
  const documentOrg = useSelector(storageOrg);
  const [tableData, setTableData] = useState([]);
  const [canEditIds, setCanEditIds] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [idBeDeleted, setIdBeDeleted] = useState(undefined);
  const [showConfirmDlg, setShowConfirmDlg] = useState(false);
  const [testFilter, setTestFilter] = useState('');

  const loadSections = (subject, id) => {
    tutorialApi.getSections(subject, id).then(data => {
      setCanEditIds(data.filter(s => ((isAuth().role === 3) || (s.owner?._id === isAuth()._id))).map(s => s._id));
      setTableData(data.map(s => ({
        id: s._id,
        contentLink: s.name,
        desc: s.desc,
        count: s.count,
        date: printOnlyDate(s.updatedAt),
        author: fullName(s.owner, 'Шаблон'),
        actions: '',
      })));
    });
  };

  useEffect(() => {
    loadSections(subject, documentOrg._id);
  }, [subject, documentOrg._id]);

  useEffect(() => {
    const list = tableData.filter(record=> (chkItemText(record, 'contentLink', testFilter)));
    setFilteredTableData(list);
  }, [tableData, testFilter]);

  const handleCopy = id => {
    tutorialApi.getSectionById(id)
    .then((result) => {
      const tld = {...result, name: 'Копия - ' + result.name, room: documentOrg._id};
      tutorialApi.addSection(tld)
      .then((res) => {
        toast.success("Раздел скопирован с именем '" + res.name + "'");
        loadSections(subject, documentOrg._id);
      }).catch((err) => {
        toast.error(err.response.data.errors);
      });
    }).catch((err) => {
      toast.error(err.response.data.errors);
    });
  };

  const handleRequestDeleteTest = id => {
    setIdBeDeleted(id);
    setShowConfirmDlg(true);
  };

  const handleDeleteTestNo = () => {
    setShowConfirmDlg(false);
    setIdBeDeleted(undefined);
  };

  const handleDeleteTestYes = () => {
    tutorialApi.deleteSection(idBeDeleted)
    .then(() => {
      setShowConfirmDlg(false);
      setIdBeDeleted(undefined);
      loadSections(subject, documentOrg._id);

      toast.success("Раздел удален.");
    }).catch((err) => {
      toast.error(err.response.data.errors);
    });
  };

  const handleLink = id => {
    history.push({pathname: FEATURES.tutorial.to + '/' + subject + '/' + id,
      state: tableData.filter(table => table.id === id)?.[0]?.contentLink});
  };

  const handleEditTldName = id => {
    tutorialApi.getSectionById(id)
    .then((result) => {
      props.showNameSettings({showModal: true, action: "Сохранить", title: 'Редактировать раздел', data: result, onSubmit})
    }).catch((err) => {
      toast.error(err.response.data.errors);
    });
  };

  const onSubmit = (data) => {
    if (data._id) {
      tutorialApi.updateSection(data._id, data)
      .then((res) => {
        loadSections(subject, documentOrg._id);
        props.showNameSettings({showModal: false});
        toast.success("Раздел '" + res.name + "' сохранен.");
      }).catch((err) => {
        toast.error(err.response.data.errors);
      });
    } else {
      tutorialApi.addSection({...data, subject, room: documentOrg._id})
      .then((res) => {
        loadSections(subject, documentOrg._id);
        props.showNameSettings({showModal: false});
        toast.success("Раздел '" + res.name + "' создан.");
      }).catch((err) => {
        toast.error(err.response.data.errors);
      });
    }
  };

  const header = [
    {column: 'Название', name: 'contentLink', style: {width: '20%'}},
    {column: 'Описание', name: 'desc', style: {width: '20%'}},
    {column: 'Количество пособий', name: 'count', style: {width: '10%'}},
    {column: 'Автор', name: 'author', style: {width: '20%'}},
    {column: 'Дата создания', name: 'date', style: {width: '20%'}},
    {column: 'Действия', name: 'actions', style: {width: '10%'}},
    {column: 'id', name: 'id', style: {width: '0%'}}
  ];

  return (
    <>
      <ContentHead flex={false}>
        <Back onClick={() => history.push(FEATURES.tutorial.to)} icon="back" margin="bottom"/>
        <div className="cor_content__title">Каталог разделов по предмету {TUTORIAL_SUBJECTS[subject]?.name}</div>
      </ContentHead>
      <Content>
        <ContentHeader>
          <div className="cor-net__row">
            <div className="cor-net__col col-4">
              <FilterByText
                placeholder={'Введите название раздела'}
                value={testFilter}
                setSearch={setTestFilter}
              />
            </div>
            <div className="cor-net__col col-grow"></div>
            <div className="cor-net__col">
              <Button size="small"
                      onClick={() => props.showNameSettings({showModal: true,
                        action: "Создать", title: 'Создать раздел', onSubmit})}>Создать
                раздел</Button>
            </div>
          </div>
        </ContentHeader>
        <ContentBody scrolled={true} padding={true}>
          <div className={nb.nb__table}>
            <Table
              table={{header: header, data: filteredTableData}}
              sort={{hasSorting: true, initSortInd: -4}}
              link={{handleLink: id => handleLink(id)}}
              actions={{
                handleEdit: id => handleEditTldName(id),
                handleCopy: id => handleCopy(id),
                handleDelete: id => handleRequestDeleteTest(id),
                canEditIDs: canEditIds,
                canDeleteIDs: canEditIds
              }}/>
          </div>
        </ContentBody>
      </Content>

      {showConfirmDlg && <ModalConfirmDialog
          showConfirmDlg={showConfirmDlg}
          handleNo={handleDeleteTestNo}
          handleYes={handleDeleteTestYes}
          question={'Вы действительно хотите удалить этот раздел?'}
        />
      }
    </>
  );
}

export default TutorialSections;
