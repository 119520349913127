import React from 'react';
import Icon from '../Icon';
import ss from './ActionFile.module.scss';

const ActionFile = (props) => {
    const {
        className = '',
        label = 'Работа с файлами',
        isInnerCM = false,
        profile = null,
        experiment = null,
        print = null,
    } = props;


    let ActionFileClass = ss.root;
    if (className) ActionFileClass += ' ' + className;



    return (
        <div className={ActionFileClass}>

            {label && <span className={ss.label}>{label}</span>}

            <div className={ss.dropdown}>
                <div className={ss.dropdown__toggle}>
                    <Icon name="log-out-circle" />
                    <p>Экспорт</p>
                </div>
                <div className={ss.dropdown__list}>
                    {isInnerCM && 
                    <button className={ss.dropdown__item} onClick={profile}>
                        <Icon name="dock" />
                        <p>В профиль</p>
                    </button>
                    }
                    <button className={ss.dropdown__item} onClick={experiment}>
                        <Icon name="file-excel" />
                        <p>XLS</p>
                    </button>
                </div>
            </div>

            {print &&
            <div className={ss.print} onClick={print}>
                <Icon name="print"/>
            </div>}

        </div>
    )
};
export default ActionFile;
