import React, {useState, useEffect} from 'react';
import Modal from "../../Components/Modal";
import { Button, Icon } from '../ui';
import PIOneDragAndDropList from "./PIOneDragAndDropList";
import {INV_ELEM_TYPE} from "./pi_utils";
import "./PInvest.scss";

const PIChangeCompositionDlg = ({showDlg, setShowDlg, workComposition, setWorkComposition, 
                workMeasurements, workComments, workObjects, workPlots, workDrawTools, 
                setWorkMeasurements, setWorkComments, setWorkObjects, setWorkPlots, setWorkDrawTools}) => {
	const [composition, setComposition] = useState([]);
	const [sortedList, setSortedList] = useState([]);

    useEffect(() => {
        if (!showDlg) return;
        const list = [];

		for (let i = 0; i < workComposition.length; i ++) {
			const content = workComposition[i];
			const type = content.type;
			const uid = content.uid; 
            let obj = '';
            if (type === INV_ELEM_TYPE.MEASUREMENT_ID) obj = workMeasurements.find((item) => item.uid === uid);
            else if (type === INV_ELEM_TYPE.DRAW_TOOL_ID) obj = workDrawTools.find((item) => item.uid === uid);
            else if (type === INV_ELEM_TYPE.COMMENT_ID) obj = workComments.find((item) => item.uid === uid);
            else if (type === INV_ELEM_TYPE.OBJECT_ID)  obj = workObjects.find((item) => item.uid === uid);
            else if (type === INV_ELEM_TYPE.PLOT_ID)  obj = workPlots.find((item) => item.uid === uid);
            let title = obj.title;

            const elem = {
                ...content,
                title: title
            };
            list.push(elem);
       }

        setComposition(list);
    }, [showDlg, workComposition, workComments, workObjects, workPlots, workDrawTools, workMeasurements]);

    useEffect(() => {
        const handleToggleVisibility = (ind, _composition) => {
            const items = [..._composition];
            const elem = items[ind];
            elem.isShown = !elem.isShown;
            setComposition(items);
        };
    
        const handleDelete = (ind, _composition) => {
            const items = [..._composition];
            const elem = items[ind];
            elem.beDeleted = true;
            setComposition(items);
        };

        if (composition.length === 0) return;

        const list = [];
        for (let ind = 0; ind < composition.length; ind ++) {
            const item = composition[ind];

            if (!item.beDeleted) {
                const element = (
                    <div className="selectedSections" key={'pg01'+ind} >
                        <input type="checkbox" key={'pg02'+ind} value={item.isShown}
                            checked={item.isShown} onChange={() => handleToggleVisibility(ind, composition)} 
                        />

                        <div className="selectedSections__title" key={'pg03'+ind}>
                            {item.title}
                        </div>

                        <div className="selectedSections__action">
                            <span key={'pg05'+ind}>
                                <Icon name="trash" onClick={() => handleDelete(ind, composition)} />
                            </span>
                            <span key={'pg06'+ind}>
                                <Icon name="drag" />
                            </span>
                        </div>
                    </div>
                );

                const elem = {
                    content: (<span key={'item'+ind}>{element}</span>), 
                    item: item,
                    ind: ind
                };
                list.push(elem);
            }
        }

        setSortedList(list);
    }, [composition]);

    const makeAction = () => {
        const list = [];

        for (let i = 0; i < composition.length; i ++) {
            const content = composition[i];
            if (!content.beDeleted) { //save items with current hide/show state
                const elem = {
                    type: content.type,
                    uid: content.uid,
                    isShown: content.isShown
                };
                list.push(elem);
            } else { //delete selected items and save
                if (content.type === INV_ELEM_TYPE.MEASUREMENT_ID) setWorkMeasurements(workMeasurements.filter(item => item.uid !== content.uid));
                else if (content.type === INV_ELEM_TYPE.DRAW_TOOL_ID) setWorkDrawTools(workDrawTools.filter((item) => item.uid !== content.uid));
                else if (content.type === INV_ELEM_TYPE.COMMENT_ID) setWorkComments(workComments.filter((item) => item.uid !== content.uid));
                else if (content.type === INV_ELEM_TYPE.OBJECT_ID) setWorkObjects(workObjects.filter((item) => item.uid !== content.uid));
                else if (content.type === INV_ELEM_TYPE.PLOT_ID)  setWorkPlots(workPlots.filter((item) => item.uid !== content.uid));
            }
        }

        setWorkComposition(list);
        setShowDlg(false);
    };

    const setModalContent = () => {
        return (
            <>
                <div className="modal__close" onClick={() => setShowDlg(false)}></div>
                <div className="modal__message">Измените порядок</div>
                <div className="modal__body has_scrolled">
                    <div className="modal__scrolled">
                        <PIOneDragAndDropList
							elems={composition}
							setElems={setComposition}
							keyVal={'compose'}
							list={sortedList.map(v => v.content)}
					    />
                    </div>
                </div>
                <div className="modal__action">
                    <Button color="primary" border={false} onClick={makeAction}>Подтвердить</Button>
                    <Button color="primary" border={true} onClick={() => setShowDlg(false)}>Отменить</Button>
                </div>
            </>
        )
    }

    if (!showDlg) {
        return false;
    }
    
    return (
        <Modal visible={showDlg} content={setModalContent()} size={'md'} />
    )
};

export default PIChangeCompositionDlg;
