import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {storageClass, storageOptions} from "../../redux/slices/storage";
import {Tldraw} from "tldraw";
import {useSync} from "@tldraw/sync";
import {assets, unfurlBookmarkUrl} from "../../helpers/assets";
import {fullName, isAuth} from "../../helpers/auth";

const WhiteBoard = () => {
  const options = useSelector(storageOptions);
  const documentClass = useSelector(storageClass);
  const [editor, setEditor] = useState();

  const store = useSync({
    uri: `${process.env.REACT_APP_TLD_URL}/connect/${documentClass._id}`,
    assets,
  });

  useEffect(() => {
    editor?.user.updateUserPreferences({colorScheme: options.theme})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.theme]);

  return (<Tldraw
      store={store}
      onMount={(editor) => {
        setEditor(editor);
        editor.user.updateUserPreferences({name: fullName(isAuth())})
        editor.user.updateUserPreferences({colorScheme: options.theme})
        editor.registerExternalAssetHandler('url', unfurlBookmarkUrl);
      }}
    />
  );
};
export default WhiteBoard;
