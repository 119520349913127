import React from "react";
import {useSelector} from "react-redux";
import {storageOptions} from "../../../redux/slices/storage";
import Footer from '../Footer';
import {Back, Content, ContentBody, ContentHead, ContentTitle, ContentWrap} from "../ContentParts";
import {isAuth} from "../../../helpers/auth";
import {ELDYN_EXP_TYPE} from "../../CalcModels/cm_utils";
import CoilMagneticField from "../../CalcModels/CalcModel_CoilMagneticField";
import Lense from "../../CalcModels/CalcModel_Lense";
import Diffraction from "../../CalcModels/CalcModel_Diffraction";
import Interference from "../../CalcModels/CalcModel_Interference";
import Electrodynamics from "../../CalcModels/CalcModel_Electrodynamics";
import MechanicMove from "../../CalcModels/CalcModel_MechanicMove";
import ss from './DashboardCalcModels.module.scss';

const models = {
  1: {image: '01_Magnetic_field', name: 'Магнитное поле катушки'},
  2: {image: '02_Lense', name: 'Линза'},
  3: {image: '03_Diffraction', name: 'Дифракция'},
  4: {image: '04_Interference', name: 'Интерференция'},
  5: {image: '05_ElDyn_Capacitor', name: 'Электродинамика > Конденсатор'},
  6: {image: '06_ElDyn_Inductance', name: 'Электродинамика > Индуктивность'},
  7: {image: '07_ElDyn_OscCircuit', name: 'Электродинамика > Колебательный контур'},
  8: {image: '08_ElDyn_Resonance', name: 'Резонанс'},
  9: {image: '09_Mechanic_Move', name: 'Механические явления'},
};

export const DashboardCalcModels = (props) => {
  const {theme} = useSelector(storageOptions);

  const isLightMode = theme !== "dark";
  const cmProps = {
    ...props,
    isLightMode,
    isInnerCM: isAuth(),
  };

  return (
    <ContentWrap>
      {{
        '1': <CoilMagneticField  {...cmProps} />,
        '2': <Lense {...cmProps} />,
        '3': <Diffraction {...cmProps} />,
        '4': <Interference {...cmProps} />,
        '5': <Electrodynamics initExperimentType={ELDYN_EXP_TYPE.CAPACITOR} {...cmProps} />,
        '6': <Electrodynamics initExperimentType={ELDYN_EXP_TYPE.INDUCTANCE} {...cmProps} />,
        '7': <Electrodynamics initExperimentType={ELDYN_EXP_TYPE.OSC_CIRCUIT} {...cmProps} />,
        '8': <Electrodynamics initExperimentType={ELDYN_EXP_TYPE.RESONANCE} {...cmProps} />,
        '9': <MechanicMove {...cmProps} />,
        undefined: 
        <>
          <ContentHead flex={false}>
            <Back onClick={props.history.goBack} icon="back" margin="bottom" />
            <ContentTitle>Расчетные модели</ContentTitle>
          </ContentHead>
          <Content>
            <ContentBody className={ss.root}>
              <div className={ss.list}>
                {Object.entries(models).map(([id, m]) => (
                  <div className={ss.elem} key={id} onClick={() => props.history.push('/cm/' + id)}>
                    <div className={ss.image}>
                      <img src={"/CalcModels/SplashScreens/" + m.image + (isLightMode ? "_light" : "_black") + ".jpg"}
                           alt=''/>
                    </div>
                    <div className={ss.bottom}>
                      <div className={ss.title}>
                        {id}. {m.name}
                      </div>
                      <button className={ss.link}>
                      </button>
                    </div>
                  </div>))
                }
              </div>
            </ContentBody>
          </Content>
        </>
      }[props.match.params.id]}
      {isAuth() && <Footer {...props}/>}
    </ContentWrap>
  );
}

export default DashboardCalcModels;
