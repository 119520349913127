import React from 'react';
import {Icon, Notify, Tooltip} from "../index";
import ss from "./SectionTabs.module.scss";

const SectionTabs = ({history, tabs}) => {
   const handleClick = (tab) => {
    if (tab.disabled) return;
    history.push(tab.to);
   };

  return (
    <div className={ss.sections}>
      {tabs.map((tab, key) =>
        <div 
            key={key} 
            className={tab.disabled?ss.disabled:ss.tab} 
            onClick={() => handleClick(tab)}>
          <div>
            <div className={ss.icon}>
              <Icon name={tab.icon}/>
            </div>
            <div className={ss.name}>{tab.name}</div>
          </div>
          {tab.tooltip && 
            <Tooltip 
              className={ss.tooltip} 
              position='custom'>
                {tab.tooltip}
            </Tooltip>
          }
          <Notify 
            value={tab.notify?.value} 
            className={ss.notify} 
            history={history}
            path={tab.notify?.path}
          />
        </div>
      )}
    </div>
  )
};

export default SectionTabs;
