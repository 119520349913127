import React, {useState, useEffect}  from 'react';
import {Icon} from '../ui/';
import {storageClass} from "../../redux/slices/storage";
import {useSelector} from "react-redux";
import {fullName, profilePicture} from "../../helpers/auth";
import {Modal, Button, Checkbox} from 'rlabui';
import ss from '../ui/ModalDialogs/ModalSelectStudentsDialog.module.scss';

const PISelectReviewerDlg = ({ showModal, setShowModal, reviewer, handleSelectReviewer }) => {
	const [teachers, setTeachers] = useState([]);
	const [selTeacherId, setSelTeacherId] = useState(undefined);

	const documentClass = useSelector(storageClass);

	useEffect(() => {
        setSelTeacherId(reviewer);
	 	const _teachers = documentClass.classUsers.filter(item => item.role > 0);
        setTeachers(_teachers);
    }, [documentClass, reviewer]);

    const confirmYes = () => {
        if (selTeacherId) {
            handleSelectReviewer(selTeacherId);
            setShowModal(false);
        }
    };

    if (!showModal) {
        return false;
    }

    return (
        <Modal className={ss.root} visible={showModal} size="sm">
            <Modal.Head modalClose={() => setShowModal(false)} title='Выберите учителя для проверки работы' />
            <Modal.Body >
                <div className={ss.main}>
                    {teachers.map(item => 
                        <div className={ss.item} key={"rbkey"+item._id}>
                            <Checkbox 
                                className={ss.item__checkbox}
                                value={item._id}
                                checked={item._id === selTeacherId}
                                onChange={e => setSelTeacherId(e.target.value)}
                                label={
                                    <div className={ss.item__content}>
                                        {!item.picture ? 
                                            <Icon name="user" className={ss.item__icon} key={'icon'+item._id} />
                                            :
                                            <span className={ss.item__picture} key={'sp'+item._id} >
                                                <img src={profilePicture(item.picture)} alt='' key={'img'+item._id} />
                                            </span>
                                        }
                                        <span className={ss.item__name}>
                                            {fullName(item)}
                                        </span>
                                    </div>
                                }
                            />
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={confirmYes} disabled={!selTeacherId}>Подтвердить</Button>
                <Button onClick={() => setShowModal(false)} border={true}>Отменить</Button>
            </Modal.Footer>
        </Modal>
    )

};

export default PISelectReviewerDlg;