import React from "react";
import {NB_STATES} from './nb_utils';
import { isAuth } from '../../helpers/auth';
import ss from './ProgressBar.module.scss';

const ProgressBar = ({progress, handleProgress, isDisabledTemplate}) => {
    const getStepState = (step, progress) => {
        if (progress > step) return ' ' + ss.done;
        if (progress === step) return ' ' + ss.current;
        return '';
    };

    return  (
        <div className={ss.wrap}>
            <div className={ss.root}>
                <div className={ss.item + getStepState(NB_STATES.PAGE, progress)}>
                    <div className={ss.number}  onClick={() => handleProgress(NB_STATES.PAGE)}>
                        <span className={ss.item__counter}></span>
                    </div>
                    <div className={ss.label}>
                        <span>Шаг </span>
                        <span className={ss.item__counter}></span>
                        <span>. {"Создание " + (isAuth().role < 3 ? "задания" : "шаблона")}</span>
                    </div>
                </div>
                <div className={ss.item +  getStepState(NB_STATES.SETTINGS, progress)}>
                    <div className={ss.number} onClick={() => handleProgress(NB_STATES.SETTINGS)}>
                        <span className={ss.item__counter}></span>
                    </div>
                    <div className={ss.label}>
                        <span>Шаг </span>
                        <span className={ss.item__counter}></span>
                        <span>. Настройка</span>
                    </div>
                </div>
                {isAuth().role < 3 && !isDisabledTemplate && 
                <div className={ss.item + getStepState(NB_STATES.RUN, progress)}>
                    <div className={ss.number} onClick={() => handleProgress(NB_STATES.RUN)}>
                        <span className={ss.item__counter}></span>
                    </div>
                    <div className={ss.label}>
                        <span>Шаг </span>
                        <span className={ss.item__counter}></span>
                        <span>. Запуск</span>
                    </div>
                </div>
                }
            </div>
        </div>
    );
}

export default ProgressBar;
