import { Dropdown, Icon } from '../';
import DropdownItem from '../Dropdown/DropdownItem';
import {storageApi} from "../../../api/api";
import axios from "axios";
import {loadFiles} from "../../../redux/slices/lists";
import {Switcher} from '../../ui';
import {toast} from "react-toastify";
import {fullName, profilePicture} from "../../../helpers/auth";
import TextEditor, {BUTTON_LIST} from '../TextEditor/TextEditor';
import htmlParser from "html-react-parser";
import {uniqueId} from "tldraw";

export const DB_REFRESH = {
    OTI: 'onlinetestinfo',  //S: OnlineTestExecute => T: ReviewActiveOnlineTest
                            //T: ViewSelectedOnlineTestRun => S: ProfileOnlineTestsTable
                            //T: ProfileOnlineTestsTable => S: ProfileOnlineTestsTable
                            //T: ViewStudentOnlineTestResultDlg => S: ProfileOnlineTestsTable
    LWI: 'labworkinfo',     //S: LabWorkShow => T: ReviewActiveLabWork
                            //T: ViewSelectedLabWorkRun => S: ProfileLabWorksTable
                            //T: ProfileLabWorksTable => S: ProfileLabWorksTable
    NBI: 'notebookinfo',    //T: NotebookReviewSelectedRun => S: ProfileNotebooksTable
                            //T: NotebooksViewtResultByTeacherDlg => S: ProfileNotebooksTable
                            //T: ProfileNotebooksTable => S: ProfileNotebooksTable
    CMI: 'calcmodelinfo',   //S: cm_utils (addCalcModelInfo) => T: ProfileCalcModelInfoTable
                            //T: ProfileCalcModelInfoTable => S: ProfileCalcModelInfoTable
    SCI: 'rlscenarioinfo',  //T: ScenarioList => S: ProfileScenarioTable
                            //T: ScenarioResultDetailDlg => S: ProfileScenarioTable
                            //T: ProfileScenarioTable => S: ProfileScenarioTable
    VE: 'videoeditor',      //S: DashboardInstrumentSelect => T, S: ProfileVideoEditorTable
    VRL: 'vrlabinfo',       //S: VrLab => T: ProfileVirtLabTable
                            //S: VrLab => T: ReviewActiveVrLabWork
                            //T, S: ProfileVirtLabTable => T, S: ProfileVirtLabTable
                            //T: ViewAllVrLabWorkRuns => S: ProfileVirtLabTable
                            //T: ViewSelectedVrLabWorkRun => S: ProfileVirtLabTable
    PI: 'research'          //T, S: PInvestigationsOne => T, S: practimum
};

const getIsSelected = (list, id, value) => {
    const elem = list?.find(item => item.value === id);
    const foundValue = elem?.value;

    if (foundValue === '' && value === '') 
        return 'selected';
    else 
        return foundValue === value ? 'selected' : '';
};

export const getDropdown = (list, selectedId, handleClick, dropdownClassName, dropdownItemClassName, 
    key, dropPosition="bottom", disabled=false, additionalClickOpt=undefined) => {
	const label = list.find(item => item.value.toString() === selectedId?.toString())?.label;
	const getClassName = v => dropdownItemClassName + ' ' + getIsSelected(list, selectedId, v);

    return (
        <Dropdown 
            value={label} 
            dropPosition={dropPosition}
            className={dropdownClassName}
            disabled={disabled}
        >
            {list.map((item, ind) => 
                <DropdownItem 
                    key={key+ind} onClick={() => handleClick(item.value, additionalClickOpt)} 
                    className={getClassName(item.value)} >
                    {item.label} 
                </DropdownItem>
             )}
        </Dropdown>
    );
};

//=============
export const getUrl = async (prefix, name) => {
    const url = await storageApi.getUrl(prefix + name);
    return url;
};

export const getUrls = async (prefix, list) => {
    const urls = [];
    for (let i = 0; i < list.length; i ++) {
        const url = await getUrl(prefix, list[i]);
        urls.push(url);
    }

    return urls;
};

export const uploadFile = async (prefix, file, setLoadedFileName) => {
    const nameArr = file.name.split('.');
    //const objectName = encodeURIComponent(uniqueId() + '.' + file.name.split('.').pop());
    const objectName = encodeURIComponent(nameArr[0] + '_' + uniqueId() + '.' + file.name.split('.').pop());
    const uploadUrl = await storageApi.getUploadUrl(prefix + objectName);

    await axios.put(uploadUrl, file, {
      headers: {"Content-Type": file.type},
    }).catch((err) => {
      toast.error(err)
      return ''
    });
    setLoadedFileName(objectName);
    const url = await storageApi.getUrl(prefix + objectName);
    return url;
}

export const doDeleteFileByName = async (prefix, dispatch, fileName) => {
    const fName = prefix + fileName;
    try {
        await storageApi.delete(fName);
        dispatch(loadFiles(prefix));
    } catch (err) {
        console.log(err);
    }
};

export const printFile = (name) => name.split('/').pop();

const getExtension =(name)=> name.lastIndexOf('.') > -1 ? name.split('.').pop().toLowerCase() : '';

export const fileTypeIcon =(name)=> {
    switch (getExtension(name)) {
        case "csv":
        case "xls":
        case "xlsx":
            return "file-excel";
        case "doc":
        case "docx":
            return "file-word";
        case "ppt":
        case "pptx":
            return "file-pp";
        default:
            return "file";
    }
};
export const isImageFile =(name)=> ['gif', 'jpg', 'jpeg', 'png', 'svg', 'tiff'].includes(getExtension(name));

// export const getFiles = (savedFiles, fileNameList) => {
//     //we need to find all files from fileNameList that are not removed from the collection on any reason
//     if (savedFiles.length === 0 || fileNameList.length === 0) return [];
//     const checkedFiles = [];

//     for (let k = 0; k < fileNameList.length; k ++) {
//         const fn = fileNameList[k].name;

//         for (let i = 0; i < savedFiles.length; i ++) {
//             const file = savedFiles[i];
//             const fileName = file.Key.split('/')[1];    
//             if (fileName === fn) 
//                 checkedFiles.push(file); 
//         }
//     }

//     return checkedFiles; //the files exist in the collection
// };

export const registerAddNewFile = (fileName, objFiles, setObjFiles) => {
    const f = {name: fileName, isNew: true, isAdded: true, isDeleted: false};
    const arr = [...objFiles];
    arr.push(f);
    setObjFiles(arr);
};

export const registerDeleteFile = (shortFileName, objFiles, setObjFiles, dispatch, deleteFileByName) => {
    let f = objFiles.find(item => item.name === shortFileName);
    let oFiles = [...objFiles];
    if (!f.isNew) {
        //только помечаем, что файл следует удалить в случае сохранения
        f = {...f, isDeleted: true};
        oFiles = oFiles.map(item => item.name !== shortFileName ? item : f);
    } else {
        //это новый файл, его удаляем сразу
        deleteFileByName(dispatch, f.name);
        oFiles = oFiles.filter(item => item.name !== shortFileName);
    }

    setObjFiles(oFiles);
};

export const base64ToArrayBuffer = (cleanedBase64) => {
	//https://stackoverflow.com/questions/64923029/javascript-how-to-save-base64-data-as-png
	const binary_string = window.atob(cleanedBase64);
	const len = binary_string.length;
	const bytes = new Uint8Array(len);
	for (let i = 0; i < len; i++) 
		bytes[i] = binary_string.charCodeAt(i);
	return bytes.buffer;
}

export const downloadImage = (fileName, imageUrl) => {
		let link = document.createElement('a');
		link.download = fileName;
		link.href = imageUrl;
		link.click();		
};

//https://stackoverflow.com/questions/3665115/how-to-create-a-file-in-memory-for-user-to-download-but-not-through-server
export const downloadTextFile = (filename, text) => {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
//downloadTextFile('test.txt', 'Hello world!');
//option 2 - 
//const blob = new Blob(["some text"], {    type: "text/plain;charset=utf-8;",});
//saveAs(blob, "thing.txt");

//it allow getting storage files in File format
export const urlToFileObject = async(fileUrl, fileName)=> {
    const response = await fetch(fileUrl);
    // here fileUrl is url/location of the file
    const blob = await response.blob();
    const file = new File([blob], fileName, {type: blob.type});
    return file;
};

export const fileToDataUri = (file) => new Promise((resolve, reject) => {
    //see also doDownloadResearch in pi_utils
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result)
    };
    reader.readAsDataURL(file);
});

//================

const getDigitFromNumber = num => num - Math.floor(num / 10) * 10;
export const getDayTitle = (i) => {
    if (i > 10 && i <= 20) return 'дней';
    const digit = getDigitFromNumber(i);
    return digit === 1 ? 'день' : digit >= 2 && digit <= 4 ? 'дня' : 'дней';
}
export const getHourTitle = (i) => {
    if (i > 10 && i <= 20) return 'часов';
    const digit = getDigitFromNumber(i);
    return digit === 1 ? 'час' : digit >= 2 && digit <= 4 ? 'часа' : 'часов';
}
export const getMinuteTitle = (i) => {
    if (i > 10 && i <= 20) return 'минут';
    const digit = getDigitFromNumber(i);
    return digit === 1 ? 'минута' : digit >= 2 && digit <= 4 ? 'минуты' : 'минут';
}
export const getSecondTitle = (i) => {
    if (i > 10 && i <= 20) return 'секунд';
    const digit = getDigitFromNumber(i);
    return digit === 1 ? 'секунда' : digit >= 2 && digit <= 4 ? 'секунды' : 'секунд';
}

export const getRemainingTimeInMSec = (currTime, initTime, durInSec) => {
	const durInMSec = durInSec * 1000; 
	const remInMSec = durInMSec - (currTime - initTime);
	return remInMSec;
  }
  
  export const showRemainingTime = (currTime, initTime, durationInSec, isHrMinSec = false) => {
	const getRemTimeInMinSecs = remInMSec => {
	  const mins = Math.floor(remInMSec / 60000);
	  let secs = Math.floor((remInMSec - mins * 60000) / 1000);
	  let strSecs = secs.toString();
	
	  //if (strSecs.length === 1) strSecs = "0" + strSecs;
	  return (<span>Осталось: <b>{mins}</b> мин <b>{strSecs}</b> сек </span>);
	};
  
	const getRemTimeInHrMinSecs = (remInMSec) => showTopBarTime(false, remInMSec);
	
	  if (!currTime || !initTime || !durationInSec) return <span></span>;
  
	const remInMSec = getRemainingTimeInMSec(currTime, initTime, durationInSec);
	if (remInMSec < 0) return (<span>Работа завершена</span>);
	return isHrMinSec ? getRemTimeInHrMinSecs(remInMSec) : getRemTimeInMinSecs(remInMSec);
  };
  
  export const showCurrTime = (currTime, initTime) => {
	  return !currTime || !initTime ? <span></span> : showTopBarTime(true, currTime - initTime);
  };
  
  const showTopBarTime = (isCurrentTime, remInMSec) => {
	const getTimeStr = val => (val.toString().length === 1 ? "0" : '') + val.toString();
	// const getTimeStr = val => val.toString();
  
	let remInSec = Math.floor(remInMSec / 1000);
	const hrs = Math.floor(remInSec / 60 / 60);
	remInSec -= hrs * 60 * 60;
	const mins = Math.floor(remInSec / 60);
	remInSec -= mins * 60;
	const secs = remInSec;
  
	return (
	  <div className='cor-timer'>
		<span className="cor-timer__title">
		    {isCurrentTime ? <b>Прошло</b> : <b>Осталось</b>}
            <Icon name="alarm"/>
		</span>
	
        <span className='cor-timer__list'>
            {hrs > 0 &&
                <>
                    <span className='cor-timer__item'>
                        <span className="cor-timer__number">{getTimeStr(hrs)}</span>
                        <span className="cor-timer__label">{getHourTitle(hrs)}</span>
                    </span>
                    <span className='cor-timer__separator'>:</span>
                </>
            }
            <span className='cor-timer__item'>
                <span className="cor-timer__number">{getTimeStr(mins)}</span>
                <span className="cor-timer__label">{getMinuteTitle(mins)}</span>
            </span> 
            <span className='cor-timer__separator'>:</span>
            <span className='cor-timer__item'>
                <span className="cor-timer__number">{getTimeStr(secs)}</span>
                <span className="cor-timer__label">{getSecondTitle(secs)}</span>
            </span> 
        </span>
	  </div>
	);
};
  
//=============

export const initializeTimeCounter = (sec, setTime) => {
	const workProgress = () => { setTime(Date.now()); }
	return setInterval(workProgress, sec * 1000);
};

//=============

export const getClassStudents = (_classUsers) => {
	return _classUsers.filter(user => user.role === 0).map((item, ind) => ({
		owner: item._id, 
		name: fullName(item),
        personPict: item.picture
	}));
};
  
//=============

export const chkItemText = (row, itemName, filter) => {
    const f = filter.trim();
    if (!f) return true;
    if (itemName) {
        const value = row[itemName];
        return value.toLowerCase().includes(f.toLowerCase());
    } else {
        return Object.values(row).some(col => col?.toLowerCase()?.includes(f.toLowerCase()));
    }
};
export const chkItemDate = (filter_date, date, isStart) => {
    if (!date) return true;
    const rowDate = new Date(filter_date);
    const rowValueOf = rowDate.valueOf();

    let checkDate = new Date(date);
    if (!isStart) //add 1 day to the finish date
        checkDate.setDate(checkDate.getDate() + 1);

    const valueOf = checkDate.valueOf();
	return isStart ? rowValueOf >= valueOf : rowValueOf <= valueOf;
}

//===============

export const getRoundValue = (_value, roundNum) => {
	if (_value === undefined) return 0;
	const value = Number(_value);
	if (Math.abs(value) >= 1) {
	  const val0 = Number.parseFloat(value).toFixed(roundNum);
	  return parseFloat(val0);
	}
  
	if (value === 0) return value;
	if (Math.abs(value) < 0.0000001) return '0';
  
	let isReady = false;
	let val = value;
	let max = 1;
	let cnt = 0;
	for (let i = 0; i < roundNum - 1; i++) max *= 10;
	while (!isReady) {
	  val *= 10;
	  cnt ++;
	  if (Math.abs(val) >= max) {
		isReady = true;
		val = Number(Math.round(val).toFixed(0));
	  }
	}
	let zeros = '0.';
	let hasSign = false;
	if (val < 0) {
	  val = -val;
	  hasSign = true;
	}
	for (let i = 0; i < cnt - roundNum; i++) zeros += '0';
	let res = zeros + val;
	if (hasSign) res = "-" + res;
	return res;
  };
  
//=================

export const getNbCheckBox = (value, handle, id, text) => {
    return (
        <div className='cor-net__checkbox' key={'nbc1'+id}>
            <input type="checkbox"
                key={'nbin1'+id}
                id={id} 
                value={value}
                checked={value} 
                onChange={() => handle(!value)}
            >
            </input>
            {!!text && 
            <label htmlFor={id} key={'nbl1'+id}>{text}</label>
            }
        </div>
    );
};    

//==================

export const getLimitedString = (wordLimit, lineLimit, value) => {
  let vl = value;
  const count = lineLimit / wordLimit + 1;
  for (let i = 0; i < count; i ++) vl = chkTextWordSize(wordLimit, vl);
  vl = vl.substring(0, lineLimit);
  return vl;
};

export const chkTextWordSize = (maxLength, v) => {
  const arr = v.split(' ');
  const resArr = [];
  for (let i = 0; i < arr.length; i ++) {
      if (arr[i].length < 2 * maxLength) resArr.push(arr[i]);
      else {
          resArr.push(arr[i].substring(0, maxLength));
          resArr.push(arr[i].substring(maxLength));
      }
  }
  return resArr.join(' ');
};

//==================

export const renderPicture = (u) => u.picture ? <img src={profilePicture(u.picture)} alt={fullName(u)}/> : <Icon name='user'/>;

//==================

export const getDescriptionEditor = (value, setValue, isDisabledTemplate, ind = 0, maxCharCount) => {
  if (!isDisabledTemplate) {
      return (
          <TextEditor 
              className="example"
              value={value} 
              setValue={setValue} 
              maxHeight={'100%'} 
              btListType={BUTTON_LIST.CUSTOM_SCENARIO}
              maxCharCount={maxCharCount}
          />
      );
  }

  return (
      <div className='cor_te__preview' key={'te'+ind}> 
          {value ? htmlParser(value) : ''}
      </div>
  );
};

export const calcDecriptionSize = v => {
  const parent = htmlParser(v);
  return calcPropsSizes(parent);
};

const calcPropsSizes = parent => {
  if (!parent) return 0;
  let sum = 0;

  if (!Array.isArray(parent)) {
      const children = parent.props.children;
      if (typeof children === 'string') {
          const len = children.length;
          return len;
      } else {
          sum += calcPropsSizes(children);
      }
  } else { //array:
      for (let i = 0; i < parent.length; i ++) {
          const p = parent[i];
          if (typeof p === 'string') {
              sum += p.length;
          } else {
              const pp = p.props;
              const children = pp.children;
  
              if (!Array.isArray(children)) {
                  if (children && typeof children === 'string') {
                      sum += children.length;
                  } else if (children?.props?.children) {
                      sum += calcPropsSizes(children);
                  }
              } else {
                  for (let k = 0; k < children.length; k ++) {
                      const child = children[k];
                      if (typeof child === 'string') {
                          sum += child.length;
                      } else {
                          sum += calcPropsSizes(child);
                      }
                  }
              }
          }
      }
  }

  return sum;
};

//==================

export const getSwitcher = (options, isChecked, _handleClickToggle) => {
    const optList = options.split('|');
    const getToggleOptionName = (isChecked, isUnique) => {
        const active = (isChecked && isUnique) || (!isChecked && !isUnique);
        return (<label htmlFor="toggleTest" className={active ? 'active' : 'cor_practicumLab__rowToggleNotactve'}>
            {isUnique ? optList[0] : optList[1]}
        </label>);
    };

    return (
        <span className='cor_practicumLab__rowToggle'>
            {getToggleOptionName(isChecked, true)}
            <Switcher
                onChange={_handleClickToggle}
                checked={isChecked ? false : true}
                id="toggleTest"
                className="contrast"
            />
            {getToggleOptionName(isChecked, false)}
        </span>
    );
};

//==================

export const updateRunDate = (start, complete, setStartDate, setCompleteDate, setIsRunChanged = undefined) => {
    const todayDay = new Date();
    const startDay = new Date(start);
    const completeDay = new Date(complete);

    const todayDate = todayDay.valueOf();
    const startDate = startDay.valueOf();
    const completeDate = completeDay.valueOf();

    const startValue = startDate > todayDate ? startDay : todayDay;
    startValue.setHours(0, 0, 0);
    setStartDate(startValue); 

    const completeValue = completeDate >= Math.max(startDate, completeDate) ? completeDate : startValue;
    setCompleteDate(completeValue); 

    if (setIsRunChanged) setIsRunChanged(true);
};

export const updateCompleteDate = (end, runDate, setCompleteDate, setIsRunChanged = undefined) => {
    const runDay = new Date(runDate);
    const endDay = new Date(end);
    const _runDate = runDay.valueOf();
    const endDate = endDay.valueOf();
    const endValue = endDate > _runDate ? endDay : runDay;
    endValue.setHours(23, 55, 0);
    setCompleteDate(endValue); 
    if (setIsRunChanged) setIsRunChanged(true);
};

//==================

export const getSwitchedList = (inputList, isToOn) => {
    const list = [];

    for (let i = 0; i < inputList.length; i ++) {
        const row = inputList[i];
        const ids = row.id;
        const arr = ids.split('|');
        const newIds = arr[0] + '|' + (isToOn ? '1' : '0');
        const newRow = {...row};
        newRow.id = newIds;
        list.push(newRow);
    }
    
    return list;
};

export const getListWithSwitchedRow = (inputList, ids) => {
    const list = [];
    for (let i = 0; i < inputList.length; i ++) {
        let row = inputList[i];
        if (ids === row.id) {
            const arr = ids.split('|');
            row.id = arr[0] + '|' + (arr[1] !== '0' ? '0' : '1');
        }
        list.push(row);
    }
    return list;
};

//==================

export const CHECK_TIME_TYPE = {
    BEFORE: 1,
    INSIDE: 2,
    AFTER: 3
};

export const checkTimeRange = (startDay, finishDay, criteria) => {
    const startDate = new Date(startDay);
    const finishDate = new Date(finishDay);
    const todayDate = new Date();

    const today = todayDate.valueOf();
    const start = startDate.valueOf();
    const finish = finishDate.valueOf();
    
    if (criteria === CHECK_TIME_TYPE.BEFORE) {
        return today < start;
    } else if (criteria === CHECK_TIME_TYPE.INSIDE) {
        return today >= start && today <= finish;
    } else { //if (criteria === CHECK_TIME_TYPE.AFTER)
        return today > finish;
    }
};

//==================

export const cleanupDivs = str => {
    return str.replaceAll('<div>', '')
    .replaceAll('</div>', '')
    .replaceAll('<br>', '')
    .replaceAll(' ', '')
    .replaceAll("\u200B",""); //remove ZeroWidthSpace Unicode character from a string: 8203
};

//==================

export const showNameValue = (item, value) => {
    return (
        <div className="cor-net__row">
            <div className="cor-net__col col-2 mark">{item}</div>
            <div className="cor-net__col col-2 info">{value}</div>
        </div>
    );
};


//==================

export const shuffle = array => {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
  
    return array;
};
