import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Content, ContentBody, ContentHead, ContentHeader, Back, ContentTitle} from "../template/ContentParts";
import {storageClass} from "../../redux/slices/storage";
import {setIsOtView, getRtSubtab, setRtSubtab}  from "../../redux/slices/tutorialslice";
import socket from "../../socket";
import {shouldReorderDates} from './rt_utils';
import {DB_REFRESH, CHECK_TIME_TYPE, checkTimeRange, chkItemText, chkItemDate} from "../ui/utils/gen_utils";
import {loadCountersOt} from "../../redux/slices/lists";
import Table from "../ui/Table/Table";
import {Tabs} from '../ui';
import SelectFilterOptions  from "./SelectFilterOptions";
import {printOnlyDate} from "../../helpers/text";
import {onlineTestApi, onlineTestInfoApi, onlineTestRunApi} from "../../api/api";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import {isAuth} from "../../helpers/auth";
import {OT_MODE_TYPE, OT_MODE_STATE, RT_TAB_STATE, OT_TYPE} from "../OnlineTest/ot_utils";
import "../template/Dashboard/Dashboard.scss";
import "./ViewLabWorks.scss";
import "./ReviewActiveLabWork.scss";

const TXT_STATUS = {
	RUNNING: 'Запущено',
	BE_CHECKED: 'На проверке',
	COMPLETE: 'Проверено',
}; 
const TXT_CHECK = {
    AUTO: 'Автоматическая',
    MANUAL: 'Ручная',
};

// таблица выаолненных онлайн тестов
const ViewAllOnlineTestRuns = ({ history }) => {
	const [runTesFullTableList, setRunTesFullTableList] = useState([]);
	const [filteredTableData, setFilteredTableData] = useState([]);
	const [testFilter, setTestFilter] = useState('');
	const [startDate, setStartDate] = useState(null);
	const [finishDate, setFinishDate] = useState(null);
    const [showConfirmDelete, setShowConfirmDelete] = useState('');
    const [idBeDeleted, setIdBeDeleted] = useState('');
    const [isUpdated, setIsUpdated] = useState(false);
	const [tableTabInd, setTableTabInd] = useState(OT_TYPE.ALL);

	const documentClass = useSelector(storageClass);
	const subtab = useSelector(getRtSubtab);
	const {lists: {counters}} = useSelector(state => state);
  	const dispatch = useDispatch();
	  
	useEffect(() => {
		setTableTabInd(subtab);
    }, [subtab]);

	useEffect(() => {
		dispatch(loadCountersOt(documentClass._id));
	}, [dispatch, documentClass._id]);

    useEffect(() => {
		const getAllUncheckedTests = async (room) => {
			const oti = await onlineTestInfoApi.getAllTeacherNonCheckedOtTasksByRoom(room);
			return oti.data.filter(item => item.answers.length > 0);
		};
		
		const getUncheckedTests = (_allUncheckedTests, runId) => {
			return _allUncheckedTests.filter(item => item.otrun === runId);
		};

		const getOtRunsByTabInd = async (_allUncheckedTests, runList, tabInd) => {
			if (tabInd === OT_TYPE.ALL) return runList;
			const list = [];

			if (tabInd === OT_TYPE.COMPLETED) {
				for (let i = 0; i < runList.length; i ++) {
					const runItem = runList[i];
					if (!runItem.delayDates || checkTimeRange(runItem.delayDates.runDate, 
						runItem.delayDates.completeDate, CHECK_TIME_TYPE.AFTER))
						list.push(runItem);
				}
			} else if (tabInd === OT_TYPE.RUNNING) {
				for (let i = 0; i < runList.length; i ++) {
					const runItem = runList[i];
					if (runItem.delayDates && 
						!checkTimeRange(runItem.delayDates.runDate, runItem.delayDates.completeDate, CHECK_TIME_TYPE.AFTER)) 
						list.push(runItem);
				}
			} else if (tabInd === OT_TYPE.BE_CHECKED) {
				for (let i = 0; i < runList.length; i ++) {
					const runItem = runList[i];
					const hasUncheckedTests = getUncheckedTests(_allUncheckedTests, runItem._id).length > 0;
					if (hasUncheckedTests) 
						list.push(runItem);
				}
			}

			return list;
		};
	
		const prepareTable = async () => {
			//0 - get all unchecked tests:
			const allUncheckedTests = await getAllUncheckedTests(documentClass._id);

			//1 - get test runs
			let result = await onlineTestRunApi
				.getOnlineTestRunsByClassOwners([isAuth()._id], documentClass._id);
				
			//2 - select test runs by tabInd
			const runTestList = await getOtRunsByTabInd(allUncheckedTests, result.data, tableTabInd);

			//3 - get OTs by run ids
			const otIDs = runTestList.map(item => item.onlineTest).join('|');
			result = await onlineTestApi.getAllOnlineTestsByIDs(otIDs);
			const selectedOnlineTests = result.data;
			
			//3 - get info for each run id
			const list = [];
	
			for (let i = 0; i < runTestList.length; i++) {
				const runTest = runTestList[i];
				const ot = selectedOnlineTests.find(item => item._id === runTest.onlineTest);
				
				if (ot) {
					//4 - get info how many non-checked tests in each run:
					const uncheckedTests = getUncheckedTests(allUncheckedTests, runTest._id);
					let dateStart = runTest.createdAt;
					let dateFinish = '';

					let status = (!runTest.delayDates || 
							checkTimeRange(runTest.delayDates.runDate, 
								runTest.delayDates.completeDate, CHECK_TIME_TYPE.AFTER)) ?
							(uncheckedTests.length > 0 ? TXT_STATUS.BE_CHECKED : TXT_STATUS.COMPLETE) : 
							TXT_STATUS.RUNNING;

					if (runTest.delayDates) {
						dateStart = runTest.delayDates.runDate;
						dateFinish = runTest.delayDates.completeDate;
					} 

					const checkSource = runTest.runOpts ? // 1 - new way , 2 - obsolete way
						runTest.runOpts.runIsAutoEstimateType : ot.isAutoEstimateType;

					const item = {
						name: ot.title, 
						description: ot.description,
						dateCreate: printOnlyDate(ot.createdAt),
						dateStart: dateStart, 
						dateFinish: printOnlyDate(dateFinish),
                        checkType: checkSource ? TXT_CHECK.AUTO : TXT_CHECK.MANUAL,
						status: status,
						uncheckedNumLink: uncheckedTests.length > 0 ? uncheckedTests.length : '',
						actions: '', 
						id: runTest._id
					};
					list.push(item);
				}
			}
	
			setRunTesFullTableList(list);
		};

		prepareTable();
		if (isUpdated) setIsUpdated(false);
    }, [documentClass._id, isUpdated, tableTabInd]);

	useEffect(() => {
		if (shouldReorderDates(startDate, finishDate)) { //check startDate <= finishDate
			setStartDate(finishDate);
			setFinishDate(startDate);
		}
    }, [startDate, finishDate]);
	
	useEffect(() => {
		//filtering:
		const list = [];
		for (let i = 0; i < runTesFullTableList.length; i ++) {
			const row = runTesFullTableList[i];

			if ((chkItemText(row, 'name', testFilter) || chkItemText(row, 'description', testFilter)) && 
				chkItemDate(row.dateStart, startDate, true) && chkItemDate(row.dateStart, finishDate, false)) {
				const newRow = {...row};
				newRow.dateStart = printOnlyDate(row.dateStart);
				list.push(newRow);
			}
		}
		setFilteredTableData(list);
    }, [runTesFullTableList, testFilter, startDate, finishDate]);

	const handleRunningTestDetails = id => {
		history.push('/reviewtest/ot/' + id);
	};

	const handleConfirmDeleteRunningTest = id => {
		setIdBeDeleted(id);
		setShowConfirmDelete(true);
	};

	const handleDeleteRunningTest = () => {
		const deleteOnlineTestInfoByOtRunId = async (otRunId) => {
			setShowConfirmDelete(false);
			await onlineTestInfoApi.deleteAllOnlineTestInfosByRunId(otRunId); //delete ot results:
			await onlineTestRunApi.deleteOnlineTestRunById(otRunId); //delete ot runs:
			setIdBeDeleted('');
			setIsUpdated(true);
			socket.emit('BE-refresh-db', {type: DB_REFRESH.OTI, roomId: documentClass._id});
			dispatch(loadCountersOt(documentClass._id));
		};

		deleteOnlineTestInfoByOtRunId(idBeDeleted);
	};

	const specialCellStyle = (item, rowInd, colInd, row) => {
		const NON_CHECKED_RESULT_COL = tableTabInd !== OT_TYPE.RUNNING ? 5 : 7;  //4 : 5;
		if (colInd === NON_CHECKED_RESULT_COL) return {color: 'red'};
		if (item === TXT_STATUS.BE_CHECKED) return {color: '#C96203'};
		if (item === TXT_STATUS.COMPLETE) return {color: 'green'};
        if (item.includes(TXT_CHECK.AUTO)) return {color: 'green'};
        if (item.includes(TXT_CHECK.MANUAL)) return {color: '#C96203'};
		return {};
	};

	const getTableHeader = () => {
		if (tableTabInd !== OT_TYPE.RUNNING)
			return [
				{column: 'Название', name: 'name', style: { width: '15%'} }, 
				{column: 'Описание', name: 'description', style: { width: '18%'} }, 
				{column: 'Дата', name: 'dateStart', style: { width: '13%'} }, 
				{column: 'Вид проверки', name: 'checkType', style: { width: '12%'} }, 
				{column: 'Статус', name: 'status', style: { width: '10%'} }, 
				{column: 'Непроверенные результаты', name: 'uncheckedNumLink', style: { width: '12%'} }, 
				{column: 'Действия с тестом', name: 'actions', style: { width: '20%'} }, 
				{column: 'id', name: 'id', style: { width: '0%'} }, 
			];
		else 
			return [
				{column: 'Название', name: 'name', style: { width: '10%'} }, 
				{column: 'Описание', name: 'description', style: { width: '14%'} }, 
				{column: 'Дата создания', name: 'dateCreate', style: { width: '10%'} }, 
				{column: 'Дата начала', name: 'dateStart', style: { width: '9%'} }, 
				{column: 'Дата окончания', name: 'dateFinish', style: { width: '9%'} }, 
				{column: 'Вид проверки', name: 'checkType', style: { width: '10%'} }, 
				{column: 'Статус', name: 'status', style: { width: '10%'} }, 
				{column: 'Непроверенные результаты', name: 'uncheckedNumLink', style: { width: '10%'} }, 
				{column: 'Действия с тестом', name: 'actions', style: { width: '18%'} }, 
				{column: 'id', name: 'id', style: { width: '0%'} }, 
			];
	};

	const handleOtMode = (tab) => {
		if (tab.status === OT_MODE_TYPE.HISTORY) return;
		dispatch(setIsOtView(true));
		history.push(tab.path);
	};

	const handleTab = (tab) => {
		setTableTabInd(tab.status);
		dispatch(setRtSubtab(tab.status));
	};

	return (
		<>
			<ContentHead column={true}>
				{isAuth().role < 3 && <Back onClick={() => history.push("/practicum")} icon="back" />}
				<ContentTitle>Онлайн тесты</ContentTitle>		
				{isAuth().role < 3 &&
					<Tabs>
						{OT_MODE_STATE.map(tab => (
							<Tabs.Item 
								active={tab.status === OT_MODE_TYPE.HISTORY}
								onClick={() => handleOtMode(tab)}
								key={tab.status}
								notify={tab.status === OT_MODE_TYPE.HISTORY ? counters.ot : 0}
							>
								{tab.name}
							</Tabs.Item>
						))}
					</Tabs>
				}
	        </ContentHead>

			<Content>
				<ContentHeader>
					<div className="cor-net__row">
						<div className="cor-net__col">
							<Tabs>
								{RT_TAB_STATE.map(tab => (
									<Tabs.Item 
										active={tableTabInd === tab.status}
										onClick={() => handleTab(tab)}
										key={'rt'+tab.status}
										notify={tab.status === OT_TYPE.BE_CHECKED ? counters.ot : 0}
									>
										{tab.name}
									</Tabs.Item>
								))}
							</Tabs>
						</div>
						<div className="cor-net__col col-10">
						</div>
						<div className="cor-net__col col-grow">
							<SelectFilterOptions
								textPrompt={'Введите название или описание онлайн теста'}
								testFilter={testFilter}
								setTestFilter={setTestFilter}
								startDate={startDate}
								setStartDate={setStartDate}
								finishDate={finishDate}
								setFinishDate={setFinishDate}
							/>
						</div>
					</div>
				</ContentHeader>

				<ContentBody>
					<Table
						table={{
							header: getTableHeader(), 
							data: filteredTableData,
							specialCellStyle: specialCellStyle
						}}
						sort={{
							hasSorting: true, 
							initSortInd: -3, 
						}}
						person={undefined}
						link={{handleLink: id => handleRunningTestDetails(id)}}
						actions={{
							handleShowDetails: id => handleRunningTestDetails(id),
							textShowDetails: 'Смотреть результаты',
							handleDelete: id => handleConfirmDeleteRunningTest(id),
						}}								
					/>
				</ContentBody>
			</Content>

			{showConfirmDelete &&
		   	<ModalConfirmDialog
				showConfirmDlg={showConfirmDelete} 
				handleNo={() => setShowConfirmDelete(false)}
				handleYes={handleDeleteRunningTest}
				question={'Вы действительно хотите удалить результаты этого теста?'}
			/>
			}
		</>
	);
}

export default ViewAllOnlineTestRuns;
