import React from 'react';
import Icon from "../Icon";
import ss from './AddFile.module.scss';

const AddFile = (props) => {
    const {
        className = '',
        icon = 'plus_bold',
        text = 'Добавьте файл',
        accept = false,
        onChange = null,
        progress = false,
    } = props;

    let disabled = progress > 0 && progress < 100;
    let addFileClass = ss.root;
    if (className) addFileClass += ' ' + className;

    return (
        <>
            <div className={disabled ? ss.disabled  : ss.wrap}>
                <label className={addFileClass}>
                    <div className={ss.content}>
                        <Icon className={ss.icon} name={icon}/>
                        <p className={ss.text} >{text}</p>
                    </div>

                    <input 
                        className={ss.input} 
                        type="file" 
                        accept={accept}
                        onChange={onChange}
                        disabled={disabled}
                    />

                    {disabled &&
                    <div className={ss.progress}>
                        <div 
                            className={ss.progress__current}  
                            style={{width: progress + '%'}}>
                        </div>
                    </div>}
                </label>
            </div>
        </>
    )
};
export default AddFile;
