import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {printOnlyDate} from "../../helpers/text";
import socket from "../../socket"; 
import {DB_REFRESH, CHECK_TIME_TYPE, checkTimeRange} from "../ui/utils/gen_utils";
import {loadCountersOt} from "../../redux/slices/lists";
import {enabledEditScore} from "../practicum/lw_utils";
import {isAuth} from "../../helpers/auth";
import {SCORE_TYPE, getTableScoreList} from '../ui/utils/score_utils';
import ViewStudentOnlineTestResultDlg from "../ReviewTest/ViewStudentOnlineTestResultDlg";
import Table from "../ui/Table/Table";
import {onlineTestInfoApi} from "../../api/api";
import {getOnlineTestById, updateOnlineTestInfoScoreById} from "../OnlineTest/ot_utils";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";

const ProfileOnlineTestsTable = ({profile, setScores}) => {
	const [tableData, setTableData] = useState([]);
	const [isUpdated, setIsUpdated] = useState(false);
	const [canEditScore, setCanEditScore] = useState(false);
	const [showOnlineTestResultDlg, setShowOnlineTestResultDlg] = useState(false);
	const [onlineTestId, setOnlineTestId] = useState(undefined);
	const [selectedTestRunId, setSelectedTestRunId] = useState(undefined);
    const [counter, setCounter] = useState(0);
    const [showConfirmDlg, setShowConfirmDlg] = useState(false);
	const [idBeDeleted, setIdBeDeleted] = useState(undefined);

	const documentClass = useSelector(storageClass);
	const dispatch = useDispatch();
	
	useEffect(() => {
        socket.on("FE-refresh-db", ({type}) => {
            if (type === DB_REFRESH.OTI) setCounter(counter => counter + 1);
        });
        return () => {socket.off("FE-refresh-db");};
    }, []);

	useEffect(() => {
		setCanEditScore(enabledEditScore(isAuth(), documentClass.classUsers));
	},[documentClass.classUsers]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await onlineTestInfoApi.getAllOnlineTestInfosByOwner(documentClass._id, profile._id);
			const otis = result.data;

			const list = [];
			let scores = [0, 0, 0, 0, 0];

			for (let i = 0; i < otis.length; i ++) {
				const oti = otis[i];
				const onlineTestId = oti.onlineTest;
				const ot = await getOnlineTestById(onlineTestId);
				const answerNumber = oti.answers.filter(item => item.isCorrect !== SCORE_TYPE.UNDEFINED).length;
				const correctResultNumber = oti.answers.filter(item => item.isCorrect === SCORE_TYPE.CORRECT).length;
				const totalNumber = oti.answers.length;
				const isComplete = oti.isComplete;
				const execution = answerNumber === 0 ? 'Не приступал' : !isComplete ? 'Не завершено' : 'Завершено';
				const run = oti.delayOpts;
				let show = true;

				if (run && !checkTimeRange(run.runDate, run.completeDate, CHECK_TIME_TYPE.AFTER) && !oti.isComplete) 
					show = false;

				if (ot && show) { //avoid orphan records
					list.push({
						titleLink: ot.title, 
						date: printOnlyDate(oti.createdAt), 
						answers: answerNumber +  ' из ' + totalNumber, 
						result: correctResultNumber + ' из ' + totalNumber, 
						execution: execution,
						score: oti.score,
						actions: '',
						id: oti.otrun + '|' + oti._id + '|' + onlineTestId
					});
	
					scores = getTableScoreList(scores, oti.score);
				}
			}

			setScores(scores);
			setTableData(list);
			if (isUpdated) setIsUpdated(false);
        }

		fetchData();
    },[documentClass._id, profile._id, isUpdated, setScores, canEditScore, counter]);

	const getOtRunId = row_id => row_id.split('|')[0];
	const getOtInfoId = row_id => row_id.split('|')[1];
	const getOtId = row_id => row_id.split('|')[2];

	const handleStudentTableDataUpdate = async (score, row_id) => {
		const scoreVal = score !== '' ? Number(score) : null;
		const onlineTestInfoId = getOtInfoId(row_id);
		await updateOnlineTestInfoScoreById(onlineTestInfoId, scoreVal);
		doRefreshDb();
		setIsUpdated(true);
	};

	const handleOnlineTestLink = row_id => {
		setShowOnlineTestResultDlg(true);
		setOnlineTestId(getOtId(row_id));
		setSelectedTestRunId(getOtRunId(row_id));
	};

	const handleDelete = id => {
		setIdBeDeleted(id);
		setShowConfirmDlg(true);
	};
	const handleDeleteNo = () => {
		setShowConfirmDlg(false);
		setIdBeDeleted(undefined);
	};

	const handleDeleteYes = () => {
		setShowConfirmDlg(false);
		onlineTestInfoApi.deleteOnlineTestInfoById(getOtInfoId(idBeDeleted))
		.then(res => {
			setIdBeDeleted(undefined);
			setIsUpdated(true);
			doRefreshDb();
		});
	};

	const doRefreshDb = () => {
		socket.emit('BE-refresh-db', {type: DB_REFRESH.OTI, roomId: documentClass._id});
		dispatch(loadCountersOt(documentClass._id));
	};

	const getTableHeader = () => {
		if (isAuth().role === 0) {
			return [ 
				{column: 'Название теста', name: 'titleLink', style: { width: '25%'} }, 
				{column: 'Дата', name: 'date', style: { width: '15%'} }, 
				{column: 'Ответы', name: 'answers', style: { width: '15%'} }, 
				{column: 'Правильные ответы', name: 'result', style: { width: '15%'} }, 
				{column: 'Выполнение', name: 'execution', style: { width: '15%'} }, 
				{column: 'Оценка учителя', name: 'score', style: { width: '15%'} }, 
				{column: 'id', name: 'id', style: { width: '0%'} }, 
			];
	} else {
			return [ 
				{column: 'Название теста', name: 'titleLink', style: { width: '15%'} }, 
				{column: 'Дата', name: 'date', style: { width: '15%'} }, 
				{column: 'Ответы', name: 'answers', style: { width: '15%'} }, 
				{column: 'Правильные ответы', name: 'result', style: { width: '15%'} }, 
				{column: 'Выполнение', name: 'execution', style: { width: '15%'} }, 
				{column: 'Оценка учителя', name: 'score', style: { width: '15%'} }, 
				{column: 'Действия', name: 'actions', style: { width: '10%'} }, 
				{column: 'id', name: 'id', style: { width: '0%'} }, 
			];
		}
	};

    return ( 
		<div>
			<Table 
				table={{
					header: getTableHeader(), 
					data: tableData, 
					handleTableDataUpdate: handleStudentTableDataUpdate, 
				}}
				link={{
					handleLink: id => handleOnlineTestLink(id),
				}}
				sort={{
					hasSorting: true, 
					initSortInd: -2,
				}}
				actions={{
					handleDelete: ids => handleDelete(ids),
				}}
				canEditScore={canEditScore}
			/>

			{showOnlineTestResultDlg &&
			<ViewStudentOnlineTestResultDlg 
				showDlg={showOnlineTestResultDlg}
				setShowDlg={setShowOnlineTestResultDlg}
				selectedStudentId={profile._id}
				onlineTestId={onlineTestId}
				selectedTestRunId={selectedTestRunId}
				setIsUpdated={setIsUpdated}
			/>
			}

			{showConfirmDlg &&
			<ModalConfirmDialog
				showConfirmDlg={showConfirmDlg} 
				handleNo={handleDeleteNo}
				handleYes={handleDeleteYes}
				question={'Вы действительно хотите удалить этот тест?'}
			/>
			}
		</div>
    )
}

export default ProfileOnlineTestsTable;
