import React, {useRef, useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {COL_TYPE, uploadPIFile, deletePIFileByName, getPiUrl} from "./pi_utils";
import { Icon, Tooltip } from "../ui";
import "./PInvest.scss";

const PIOneImageCell = ({cellFile, width, height, uniqueInd, saveFileName, 
            setShowImageDlg, setShownFile}) => {
    const [loadedImageName, setLoadedImageName] = useState('');
    const [loadedImage, setLoadedImage] = useState(undefined);
    const [isUploadFile, setIsUploadFile] = useState(false);

    const canvasRef = useRef(null);
	const dispatch = useDispatch();

    useEffect(() => { //загрузить картинку
        const loadFile = async () => {
            const url = await getPiUrl(cellFile);

            if (url) {
                setLoadedImageName(cellFile);
                setLoadedImage(url);
                setIsUploadFile(true);
            }
        };

        if (isUploadFile || !cellFile) return;
        loadFile();
    }, [cellFile, isUploadFile]);

    useEffect(() => {
        const loadImage = async () => {
            const url = await getPiUrl(loadedImageName);
            if (!url) return;
            setLoadedImage(url);

    
            if (cellFile !== loadedImageName) {
               //сохрянем имя файла
               saveFileName(COL_TYPE.IMAGE, loadedImageName, uniqueInd);
            }
    
            setLoadedImageName('');
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
    
            const image = new Image();
            image.src = url;
    
            image.onload = () => {
                context.drawImage(image, 0, 0, width, height);
            };
        };
        if (!loadedImageName) return;
        loadImage();
    },[loadedImageName, cellFile, width, height, saveFileName, uniqueInd]);

    const handleAddFile = (event) => {
        uploadPIFile(event.target.files[0], setLoadedImageName);
        event.target.value = '';
    };

    const handleShowFile = () => {
        if (!loadedImage) return;
        setShowImageDlg(true);
        setShownFile({Url: loadedImage});
    };

    const handleClearImage = async () => {
        //1 - delete loadedImageName
        await deletePIFileByName(dispatch, loadedImageName);
        //2 - save changes
        saveFileName(COL_TYPE.IMAGE, '', uniqueInd);
        //3 - change state
        setIsUploadFile(false);
    };

    return (
        <>  
            {!loadedImageName && !isUploadFile ? 
            <label onChange={(e) => handleAddFile(e)} key='lbl01'>
                <Tooltip position="top" name="picture">Выберите файл</Tooltip>
                <input type="file" accept={'image/*'} key='lbl01' />
            </label>
            :
            <>
                <div className="pi-table__img_wrap" onClick={handleShowFile}>
                    <canvas
                        ref={canvasRef}
                        width={width}
                        height={height}
                    />
                </div>
                <div className="pi-table__img_trash" onClick={() => {handleClearImage()}}>
                    <Icon name="trash" />
                </div>
            </>
            }
        </>
        
    );
}

export default PIOneImageCell; 
