import {printOnlyDate} from "../../helpers/text";
import { notebookApi, notebookInfoApi} from "../../api/api";
import {isAuth} from "../../helpers/auth";
import {CHECK_TIME_TYPE, checkTimeRange, getDayTitle, getHourTitle} from '../ui/utils/gen_utils';
import {SCORE_TYPE, SCORE_MANUAL_CHECK} from '../ui/utils/score_utils';

export const NB_STATES = {
    PAGE: 0, 
    SETTINGS: 1,
    RUN: 2
}

export const NB_COMPLEXITY = {
    LOW: 0, 
    MEDIUM: 1,
    HIGH: 2
}

export const NB_ACT = {
    SHOW_ALL_NBS: 0, 
    SHOW_ALL_SUBJECT_NBS: 1, 
    SHOW_ALL_SUBJECT_WORKS: 2, 
    ADD_EDIT_NB: 3,
    REVIEW_NB_RUN: 4, 
    SET_RUN_TIME: 5,
    RUN_TEST: 6,
}

export const NB_HOME_WORK_STATUS = {
    CATALOG: 0, 
    PROGRESS: 1, 
    COMPLETE: 2
}

export const NB_ACTION = {
    UNDEFINED: 0,
    TASK_ADD: 1, 
    TASK_EDIT: 2,
    TASK_COPY: 3, 
    TASK_DELETE: 4, 
    TASK_SAVE_ADD: 5, 
    TASK_SAVE_EDIT: 6,
}

export const NB_TEMPLATE_TYPE = {
    MATH_NUMBER_PLUS: 10,
    MATH_NUMBER_MINUS: 11,
    MATH_NUMBER_MULTIPLY: 12,
    MATH_NUMBER_DIVIDE: 13,
    MATH_NODNOK: 20,
    MATH_EQUATION_LINEAR: 50,
    MATH_EQUATION_SQUARE: 51,
    MATH_GROUPING: 60,
    //
    PHYSICS_MECHANICS: 100, 
    PHYSICS_MOLECULAR_TERMODYNAMICS: 110, 
    PHYSICS_ELECTRODYNAMICS: 120, 
};

export const NB_SAMPLE_TYPE = {
    EQUATION: 0,
    TEXT_TASK: 1,
    CALC_NUMBERS: 2,
    NOD: 3,
    NOK: 4,
    GROUPING: 5,
    //
    CALC_PHYSICS_TASK: 20,
}

export const NB_WORK_TAB = { //подготовленные задания
    ALL: 1,
    TEMPLATES: 2,
    WORKS: 3, 
};

export const NB_TABS = [
    {tabId: NB_WORK_TAB.ALL, tabName: 'Все'},
    {tabId: NB_WORK_TAB.TEMPLATES, tabName: 'Шаблоны'},
    {tabId: NB_WORK_TAB.WORKS, tabName: 'Созданные'},
];

export const NB_REVIEW_TAB = { //результаты тестирования
    ALL: 0,
    RUNNING: 1,
    HISTORY: 2,
    BE_CHECKED: 3, 
};

export const RESULT_TABS = [
    {tabId: NB_REVIEW_TAB.ALL, tabName: 'Все'},
    {tabId: NB_REVIEW_TAB.RUNNING, tabName: 'Запущенные'},
    {tabId: NB_REVIEW_TAB.HISTORY, tabName: 'История'},
    {tabId: NB_REVIEW_TAB.BE_CHECKED, tabName: 'На проверке'},
];

export const NB_NAME_CHG = {
    TYPE1: 0,
    TYPE234: 1,
    TYPE_MISC: 2, 
};

export const NB_SUBJECT = {
    MATH: 0,
    PHYSICS: 1,
    CHEMISTRY: 2,
    GEOGRAPHY: 3,
    NATURE: 4,
    ECOLOGY: 5,
};

export const NB_ANSWER_TYPE = {
    AUTO: 0,
    MANUAL: 1,
};

export const NB_MODE_TYPE = {
    EXECUTED: 0,
    CATALOG: 1
};

export const TAB_STATE = [
    {status: NB_MODE_TYPE.CATALOG, name: 'Каталог заданий'},
    {status: NB_MODE_TYPE.EXECUTED, name: 'История выполнения заданий'},
];

export const STUDY_SUBJECTS = [
    {name: 'Математика', relName: 'математике', icon: 'mathematics', tooltip: 'Математика', to:"/notebooks/1/" + NB_SUBJECT.MATH},
    {name: 'Физика', relName: 'физике', icon: 'phisic', tooltip: 'Физика', to:"/notebooks/1/" + NB_SUBJECT.PHYSICS},
    {name: 'Химия', relName: 'химии', icon: 'chemistry', tooltip: 'Химия', to:"/notebooks/1/" + NB_SUBJECT.CHEMISTRY, disabled: true},
    {name: 'География', relName: 'географии', icon: 'geography', tooltip: 'География', to:"/notebooks/1/" + NB_SUBJECT.GEOGRAPHY, disabled: true},
    {name: 'Естествознание', relName: 'естествознанию', icon: 'natural', tooltip: 'Естествознание', to:"/notebooks/1/" + NB_SUBJECT.NATURE, disabled: true},
    {name: 'Экология', relName: 'экологии', icon: 'ecology', tooltip: 'Экология', to:"/notebooks/1/" + NB_SUBJECT.ECOLOGY, disabled: true},
];

export const NB_SPEC_TEXT = {
    NEW: 'Новое',
    IN_PROGRESS: 'Начато',
    INFO_NO_DATA: 'Нет данных',
    MANUAL_EVAL: 'Требуется ручная проверка',
    NOT_EXECUTED: 'Не выполнено',
    EXECUTED: 'Сдано',
    TIME_COMPLETED: 'Время закончилось, тест не завершен',
    BAD_MARK: '2 балла',
    GOOD_MARK: 'из 5',
    DAY: 'день',
    DAYS: 'дня',
};

export const getSubjectById = (id) => id !== undefined ? STUDY_SUBJECTS[id].name : 'empty';

export const getSubjectRPNameById = (id) => STUDY_SUBJECTS[id].relName;

export const getTemplateTypes = (_templateTypes) => { //read the info from my JSON
    if (!_templateTypes) return [];
    //выберите тип задачи
    const list = [];
    for (let i = 0; i < _templateTypes.length; i ++)
        list.push({label: _templateTypes[i].templateTypeName, value: _templateTypes[i].templateTypeId});
    return list;
};

export const getSubTasks = (templates, templateTypeId) => { //read the info from my JSON
    const list = [];
    for (let i = 0; i < templates.length; i ++)
        if (templates[i].templateTypeId === templateTypeId)
            list.push({label: templates[i].name, value: templates[i].templateId});
    return list;
};

export const getFirstTemplateId = (_templates, _templateTypeId) => {
    const selTemplates = _templates.filter(item => item.templateTypeId === _templateTypeId);
    return selTemplates[0].templateId;
};

export const getPageNames = () => { 
    const list = [];
    list.push({label: 'Вводная часть', value: 0});
    return list;
};

export const getPagePlacements = () => {
    const list = [];
    list.push({label: 'В начало задания', value: 0});
    return list;
};

export const getTaskComplexities = () => {
    const list = [];
    list.push({label: 'Низкая', value: NB_COMPLEXITY.LOW});
    list.push({label: 'Средняя', value: NB_COMPLEXITY.MEDIUM});
    list.push({label: 'Высокая', value: NB_COMPLEXITY.HIGH});
    return list;
};

export const getTaskSummaries = () => {
    const list = [];
    for (let i = 1; i <= 10; i ++)
        list.push({label: i.toString(), value: i});
    return list;
};

export const getDifferenceInDays = (timeComplete) => {
    const timeNow = (new Date()).getTime();
    const difference_In_Time = timeComplete > timeNow ? timeComplete - timeNow : 0;
    const difference_In_Days = Math.round(difference_In_Time / (1000 * 3600 * 24));
    return '' + difference_In_Days + ' ' + getDayTitle(difference_In_Days);
}

export const getHourList = () => {
    const list = [];
    for (let i = 0; i < 24; i ++) {
        const label = i.toString() + ' ';
        const nm = getHourTitle(i);
        list.push({label: label + nm, value: i});
    }
    return list;
};

export const getMinuteList = () => {
    const list = [];
    for (let i = 0; i < 12; i ++) {
        const label = (5*i).toString() + ' минут';
        list.push({label: label, value: 5*i});
    }
    return list;
};

export const getEstimateThresholdList = () => {
    const list = [];
    for (let i = 1; i < 99; i ++)
        list.push({label: i.toString(), value: i});
    return list;
};

export const getSelectedDate = (date, hours, minutes) => { //use in DB when analysis of start and complete date
    const dt = new Date(date);
    const currTime = dt.getTime();
    const timeInMSecs = (hours * 3600 + minutes * 60) * 1000;
    const selectedTime = currTime + timeInMSecs;
    return selectedTime;
};

export const getErrorLimits = (isLong) => {
    const list = [];
    const num = isLong ? 20 : 3;
    for (let i = 1; i <= num; i ++) {
        const res = i % 10;
        const units = (res === 0 || res >= 5) ? 'ошибок' : res === 1 ? 'ошибка' : 'ошибки';
        const label = i.toString() + ' ' + units;

        list.push({label: label, value: i});
    }
    return list;
};

export const getNbRbBoxes = (selectedIndex, handle, options) => {
    return (
        <div className='cor-net__checkbox'>
        {options.split('|').map((item, ind) => 
            <div key={"rbkey"+ind} className={''}>
                <input type="checkbox" 
                    id={ind} 
                    value={ind} 
                    name={item}
                    checked={ind === selectedIndex} 
                    onClick={(e) => handle(Number(e.target.value))} 
                    onChange={() => {}}
                >
                </input>
                &nbsp;<label htmlFor={ind}>{item}</label>
            </div>
        )}
        </div>
    );
};


export const getInitialSample = () => {
    const sample = {
        templateId: '',
        complexity: NB_COMPLEXITY.LOW,
        groupAmount: 1,
        teacherDescription: '',
        taskSample: '',
        taskAnswer: '',
    };
    return sample;
};

export const getInitialSettings = () => {
    const settings = {
        isGenerateUniqueTasks: true, 
        isTimeLimited: true,
        timeLimitMinutes: 180,
        isRandomOrder: false, //true,
        isErrorNumberLimit: false, //true,
        taskErrorLimit: 3,
        workErrorLimit: 10,
        isAutoScore: true,
    };
    return settings;
};

export const getInitialRun = (run = null) => {
    const runFullDate = getRunDates(true, run ? run.runFullDate : null);
    const completeFullDate = getRunDates(false, run ? run.completeFullDate : null);

    const newRun = {
        runFullDate: runFullDate,
        completeFullDate: completeFullDate,
    };

    return newRun;
};

export const getInitialUsers = (users = null) => !users ? {isAll: true, selectedUsers: []} : users;

export const getRunDates = (isRun, date) => {
    let currDate;
    if (date !== null) { //time is already set
        currDate = new Date(date);
    } else { //find time
        currDate = new Date();
        currDate.setHours(isRun ? 0 : 24 + 23, isRun ? 0 : 55, 0);
    }

    return currDate;
};

export const prepareRun = (runDate, completeDate) => {
    const getFullDate = (date, hours, minutes) => {
        if (date === null) return null;
        const fullDate = new Date(date);
        fullDate.setHours(hours, minutes, 0);
        return fullDate;
    };        
    const run = {
        runFullDate: getFullDate(runDate, 0, 0),
        completeFullDate: getFullDate(completeDate, 23, 55),
    };
    return run;
};


export const getFinalWarning = (_homeWorkRun) => {
    if (!_homeWorkRun) return <></>;
    const isNowLater = isNowLaterThanDate(_homeWorkRun.runDate, _homeWorkRun.runHours, _homeWorkRun.runMinutes);
    const timeStart = printOnlyDate(_homeWorkRun.runDate);
    const timeFinish = printOnlyDate(_homeWorkRun.completeDate);
    return (
        !isNowLater ? 
        <>Задание будет запущено {timeStart}. Задание не может быть отредактировано. </>
        : 
        <>Задание начато {timeStart} {_homeWorkRun.completeDate && (<> и будет завершено &nbsp; {timeFinish}</>)}. Задание не может быть отредактировано.</>
    );
};

export const isDisabled = isEditable => !isEditable ? 'disabled' : '';

export const isNowLaterThanDate = (_date, _hours = 23, _minutes = 55) => {
    const runTime = getSelectedDate(_date, _hours, _minutes);
    const nowTime = (new Date()).getTime();
    return nowTime >= runTime;
};

export const getDateText = (date, hours, minutes, isMinutes = false) => {
    let hr = hours.toString();
    hr = hr.length === 1 ? '0' + hr : hr;
    let min = isMinutes ? minutes : minutes.toString();
    min = min < 10 ? '0' + min : min;
    const text = printOnlyDate(date) + ' в ' + hr + ':' + min;
    return text;
};

export const getTaskNumberByTaskSamples = taskSamples => {
    let num = 0;
    for (let i = 0; i < taskSamples.length; i ++)
        num += taskSamples[i].groupAmount;
    return num;
};

export const getNbCopy = async (_id, room, saveCopiedNb) => {
    const updateRunDates = (_notebook) => {
        const notebook = {..._notebook};
        if (isAuth().role === 3) return notebook;
    
        const nowDate = new Date();
        const runFullDate = notebook.run ? new Date(notebook.run.runFullDate) : undefined;
        const completeFullDate = notebook.run ? new Date(notebook.run.completeFullDate) : undefined;
        
        if (!runFullDate || !completeFullDate || runFullDate < nowDate || completeFullDate < nowDate) {
            const newRunFullDate = new Date();
            newRunFullDate.setHours(0);
            newRunFullDate.setMinutes(0);
            const newCompleteFullDate = new Date();
            newCompleteFullDate.setDate(newCompleteFullDate.getDate() + 1);
            newCompleteFullDate.setHours(23);
            newCompleteFullDate.setMinutes(55);
            const run = {
                runFullDate: newRunFullDate,
                completeFullDate: newCompleteFullDate,
            };
            notebook.run = run;
        }
    
        return notebook;
    };

    let notebook = await getNotebookById(_id);

    notebook.titles.homeWorkName = 'Копия - ' + notebook.titles.homeWorkName;
    notebook.homeWorkStatus = NB_HOME_WORK_STATUS.CATALOG;
    if (isAuth().role < 3) {
        notebook.room = room;
        notebook = updateRunDates(notebook);
    }

    saveCopiedNb(notebook);
};

export const updateWords = form => {
    const arr = form.split('|');
    for (let i = 1; i < arr.length; i += 2) {
        const elem = arr[i];
        const newWord = getChangedWords(elem);
        arr[i] = newWord;
    }
    return arr.join('');
};

export const getChangedWords = (str) => {
    const WORD_MALE_TYPE = 0;
    const WORD_MALE_SOFT_TYPE = 1;
    const WORD_FEMALE_TYPE = 2;
    const WORD_MIDDLE_TYPE = 3;

    const dictionary = [
        {word: 'час', type: WORD_MALE_TYPE},
        {word: 'балл', type: WORD_MALE_TYPE},
        {word: 'кубометр', type: WORD_MALE_TYPE},
        {word: 'килограмм', type: WORD_MALE_TYPE},
        {word: 'километр', type: WORD_MALE_TYPE},
        {word: 'ряд', type: WORD_MALE_TYPE},
        {word: 'лист', type: WORD_MALE_TYPE},
        {word: 'ученик', type: WORD_MALE_TYPE},
        //        
        {word: 'рубль', type: WORD_MALE_SOFT_TYPE, short: 'рубл'},
        //        
        {word: 'минута', type: WORD_FEMALE_TYPE, short: 'минут'},
        {word: 'конфета', type: WORD_FEMALE_TYPE, short: 'конфет'},
        {word: 'штука', type: WORD_FEMALE_TYPE, short: 'штук'},
        {word: 'парта', type: WORD_FEMALE_TYPE, short: 'парт'},
        //
        {word: 'яблоко', type: WORD_MIDDLE_TYPE, short: 'яблок'},
        {word: 'кресло', type: WORD_MIDDLE_TYPE, short: 'кресл', multiple: 'кресел'},
        //
    ];

    let [word, value] = str.split(',');

    if (value.includes('/')) {
        value = value.replaceAll('#', '');
        const arr = value.split('/');
        const res = Number(arr[0]) / Number(arr[1]);
        if (res === 1) value = 1;
        else if (res > 0 && res < 1) value = 2; //to get word 'часа'
        else value = Math.floor(res);
    }

    for (let i = 0; i < dictionary.length; i ++) {
        const content = dictionary[i];
        const type = content.type;
        const changeType = getNameChangeType(value);
        
        if (word.toLowerCase() === content.word) {
            if (type === WORD_MALE_TYPE) {
                const updatedWord = getMaleChangeTypeEnding(changeType, content);
                return updatedWord;
            } else if (type === WORD_FEMALE_TYPE) {
                const updatedWord = getFemaleChangeTypeEnding(changeType, content);
                return updatedWord;
            } else if (type === WORD_MALE_SOFT_TYPE) {
                const updatedWord = getMaleSoftChangeTypeEnding(changeType, content);
                return updatedWord;
            } else if (type === WORD_MIDDLE_TYPE) {
                const updatedWord = getMiddleChangeTypeEnding(changeType, content);
                return updatedWord;
            }
        }
    }
    return word;
};

//const isANumber = (str) => !/\D/.test(str);

const getMaleChangeTypeEnding = (changeType, content) => {
    return content.word + (changeType === NB_NAME_CHG.TYPE1 ? '' : changeType === NB_NAME_CHG.TYPE234 ? 'а': 'ов');
};
const getFemaleChangeTypeEnding = (changeType, content) => {
    return content.short + (changeType === NB_NAME_CHG.TYPE1 ? 'а' : changeType === NB_NAME_CHG.TYPE234 ? 'ы': '');
};
const getMaleSoftChangeTypeEnding = (changeType, content) => {
    return content.short + (changeType === NB_NAME_CHG.TYPE1 ? 'ь' : changeType === NB_NAME_CHG.TYPE234 ? 'я': 'ей');
};
const getMiddleChangeTypeEnding = (changeType, content) => {
    if (changeType === NB_NAME_CHG.TYPE1) return content.short + 'о';
    if (changeType === NB_NAME_CHG.TYPE234) return content.short + 'а';
    return (!content.multiple ? content.short : content.multiple);
};

export const getNameChangeType = value => {
    if (value >= 5 && value <= 20)
        return NB_NAME_CHG.TYPE_MISC;

    const n = Math.floor(value / 10);
    const val = value - 10 * n;

    if (val === 1) return NB_NAME_CHG.TYPE1;
    if (val >= 2 && val <= 4) return NB_NAME_CHG.TYPE234;
    return NB_NAME_CHG.TYPE_MISC;
};

export const getBallChangeType = value => {
    const changeType = getNameChangeType(value);
    return getMaleChangeTypeEnding(changeType, {word: 'балл'});
};

///// --- notebookApi ---------------------------------------------------------------------------
export const addNotebook = async (nb) => {
    const result = await notebookApi.addNotebook(nb)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
};

export const getNotebookById = async (nbId) => {
    const result = await notebookApi.getNotebookById(nbId)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
}

export const getNotebooks = async (owner, room) => {
    const result = await notebookApi.getNotebooks(owner, room)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
}

export const getNotebooksByIds = async (nbIds) => {
    const result = await notebookApi.getNotebooksByIds(nbIds)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
}

///// --- notebookInfoApi ---------------------------------------------------------------------------
export const getNotebookInfoById = async (nbiId) => {
    const result = await notebookInfoApi.getNotebookInfoById(nbiId)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
};

export const getAllNotebookInfoByOwner = async (room, owner) => { //owner is student id
    const result = await notebookInfoApi.getAllNotebookInfoByOwner(room, owner)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
};

export const getAllOwnersNotebookInfoByNotebookId = async (notebookId) => { //notebook is notebook id
    const result = await notebookInfoApi.getAllOwnersNotebookInfoByNotebookId(notebookId)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
};

export const updateNotebookInfoScoreById = async (id, score) => { //id is NotebookInfo id
    const result = await notebookInfoApi.updateNotebookInfoScoreById(id, score)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
};

export const getNbDeleteMessage = async (id) => {
    const result = await getAllOwnersNotebookInfoByNotebookId(id);
    const found = result.data.length > 0;
    const msg = !found ? 'Вы действительно хотите удалить это задание?' : 
    'Удаление этого задания приведет к удалению ранее проведенных тестирований учеников и сотрет их оценки из базы. Не рекомендуется удалять.';
    return msg;
};

export const getNotifMsg = (forceComplete, homeWorkStatus) => {
    let msg = "Изменения сохранены. ";

    if (forceComplete) {
        if (isAuth().role < 3)   
            msg += homeWorkStatus === NB_HOME_WORK_STATUS.PROGRESS ? 
                "Задание запущено." : "Задание сохранено в хранилище.";
        else 
            msg += "Шаблон сохранен в хранилище.";
    }
    return msg;
};

export const subjName = () => isAuth().role < 3 ? "задание" : "шаблон";

const getNbNumber = (data) => {
    let num = 0;
    for (let i = 0; i < data.length; i ++) {
        const run = data[i].run;
        if (checkTimeRange(run.runFullDate, run.completeFullDate, CHECK_TIME_TYPE.INSIDE))
            num ++;
    }
    return num;
};

export const getNbInfoMessage = async (room, owner) => { //use in Aside 
    let result = await getAllNotebookInfoByOwner(room, owner);
    let data = result.data.filter(item => !item.isComplete);
    if (data.length === 0) return '';
    const nbIds = data.map(item => item.notebook).join('|');
    result = await getNotebooksByIds(nbIds);
    const num = getNbNumber(result.data);
    if (num > 0) 
        return 'Вы должны выполнить ' +  (num === 1 ? '1 новое задание ' : 'новые задания') + ' в Рабочей тетради.';
    return '';
};

export const getTaskNumber = (_nbData) => {
    let tasks = 0;
    for (let i = 0; i < _nbData.taskSamples.length; i ++) 
        tasks += _nbData.taskSamples[i].groupAmount;
    return tasks;
};

export const getNbSpecialStudentWorkCellStyle = (item, rowInd, colInd, row) => {
    if (item === NB_SPEC_TEXT.NEW || item === NB_SPEC_TEXT.IN_PROGRESS) return {
        color: 'white', backgroundColor: '#00B5FF', padding: '0.5em 1em', borderRadius: '0.5em'};
    if (item === NB_SPEC_TEXT.INFO_NO_DATA) return {color: 'grey'};
    if (item === NB_SPEC_TEXT.MANUAL_EVAL) return {color: '#C96203'};
    if (item === NB_SPEC_TEXT.NOT_EXECUTED) return {color: 'red'};
    if (item === NB_SPEC_TEXT.TIME_COMPLETED) return {color: 'red'};
    if (item === NB_SPEC_TEXT.BAD_MARK) return {color: 'red'};
    if (item === NB_SPEC_TEXT.EXECUTED) return {color: 'green'};
    if (item === NB_SPEC_TEXT.GOOD_MARK) return {color: 'green'};
    if (item === NB_SPEC_TEXT.DAY) return {color: 'red'};
    if (item === NB_SPEC_TEXT.DAYS && item.split(' ')[0] === '2') return {color: '#C96203'};
    return {};
};

export const getNbStudentWorkStatus = (score, isCompleteTest, isTimeCompleted, 
    isExecutionOutOfLimit, inProgress, minValue) => {
    let status = '';

    if (isCompleteTest) {
        if (score === SCORE_MANUAL_CHECK) status = NB_SPEC_TEXT.MANUAL_EVAL;
        else status = NB_SPEC_TEXT.EXECUTED;
    } else if (isTimeCompleted || isExecutionOutOfLimit) {
        status = NB_SPEC_TEXT.TIME_COMPLETED;
    } else {
        if (!inProgress) status = NB_SPEC_TEXT.NEW;
        else status = NB_SPEC_TEXT.IN_PROGRESS;
    }
    
    return status;
};

export const getIsNbiInProgressCount = (nb, nbi) => {
    if (!nb || !nbi) return 0;
    return nb.settings.isAutoScore ?
        nbi.tasks.filter(item => item.isCorrect !== SCORE_TYPE.UNDEFINED).length
        :
        nbi.tasks.filter(item => item.userAnswerHistory).length;
};

export const getIsNbiInProgressByInfo = (nb, nbi) => getIsNbiInProgressCount(nb, nbi) > 0;
export const getIsNbiIsNotStarted = (nb, nbi) => getIsNbiInProgressCount(nb, nbi) === 0;

export const getIsNbiInProgress = (nbs, nbis, ids) => {
    const [_nbId, _nbiId] = ids.split('|');
    const nb = nbs?.find(item => item._id === _nbId); //nb.settings.isAutoScore
    const nbi = nbis?.find(item => item._id === _nbiId);

    return getIsNbiInProgressByInfo(nb, nbi);
};

export const getIsExecutionOutOfLimit = (nb, nbi) => {
    const isTimeLimited = nb.settings.isTimeLimited;
    if (!isTimeLimited) return false;
    if (!nbi.startDate) return false;
    const timeLimitSecs = nb.settings.timeLimitMinutes * 60;
    const startTimeInSecs = (new Date(nbi.startDate)).valueOf() / 1000;
    const currTimeInSecs = (new Date()).valueOf() / 1000;
    const isExecutionOutOfLimit = currTimeInSecs - startTimeInSecs >= timeLimitSecs;
    return isExecutionOutOfLimit;
};

export const getIsTestTimeStarted = (timeRun) => {
    const timeNow = (new Date()).getTime();
    const isTimeStarted = timeNow > timeRun;
    return isTimeStarted;
};
export const getIsTestTimeCompleted = (timeComplete) => {
    const timeNow = (new Date()).getTime();
    const isTimeStarted = timeNow > timeComplete;
    return isTimeStarted;
};

export const isNbTaskStarted = (nb, nbi) => {
    const rfd = nb.run.runFullDate;
    const cfd = nb.run.completeFullDate;
    const timeRun = (new Date(rfd)).getTime();
    const timeComplete = (new Date(cfd)).getTime();

    const isTimeCompleted = getIsTestTimeCompleted(timeComplete);
    const isExecutionOutOfLimit = getIsExecutionOutOfLimit(nb, nbi);
    const isCompleteTest = nbi.isComplete; 

    const isStarted = !isCompleteTest && !isTimeCompleted && !isExecutionOutOfLimit;
    return [isStarted, timeRun, timeComplete];
};

export const getSelectedIndex = _reorderedOptions => {
    for (let i = 0; i < _reorderedOptions.length; i ++) 
        if (_reorderedOptions[i].isSelected) return i;
    return -1;
};
