import React, { useState, useEffect, useRef } from "react";
//https://socket.dev/npm/package/react-audio-visualize
//https://github.com/samhirtarif/react-audio-visualize/blob/master/src/AudioVisualizer/AudioVisualizer.tsx
//import { AudioVisualizer } from 'react-audio-visualize'; 
import { Icon } from '../ui'; //, Sound
import {clearRunningTestInfo} from "../ui/utils/ServerInfo";
import {FEATURES} from "../../api/config";
import {PROFILE_TAB}  from "../template/Dashboard/DashboardProfile";
import {SCORE_TYPE, SCORE_MANUAL_CHECK, calcScoreValue, getScoreName, getMinGoodScore} from '../ui/utils/score_utils';
import {toast} from "react-toastify";
import ModalPrint from '../ui/ModalDialogs/ModalPrint';
import ImageDlg from "../ui/ModalDialogs/ImageDlg";
import {printOnlyDate} from "../../helpers/text";
import {onlineTestInfoApi} from "../../api/api"; 
import ScoreCalcTableDlg from "../Notebook/ScoreCalcTableDlg";
import socket from "../../socket";
import {storageClass} from "../../redux/slices/storage";
import {fullName} from "../../helpers/auth";
import htmlParser from "html-react-parser";
import {isAuth} from "../../helpers/auth";
import {useDispatch, useSelector} from "react-redux";
import {DB_REFRESH, showCurrTime, showRemainingTime, getRemainingTimeInMSec, shuffle,
	initializeTimeCounter, cleanupDivs, showNameValue} from "../ui/utils/gen_utils";
import {QUESTION_TYPES, FORMAT_TYPE, getContentByFile, getOneOption, getOTUrl, getOTUrls, 
	getOnlineTestById, updateOnlineTestInfoById, getTestScoreOpts} from "./ot_utils";
import {Button, Checkbox} from 'rlabui';
import {loadCountersOt} from "../../redux/slices/lists";
import { Content, ContentBody, ContentDesc, ContentFooter, ContentHead, ContentHeader } from "../template/ContentParts";
import ss from './OnlineTestExecute.module.scss';

const MAX_DESC_LENGTH = 1000;

const OnlineTestExecute = ({ otSettings, clearOtSettings, history }) => {
	const [onlineTestInfoId, setOnlineTestInfoId] = useState(undefined);
	const [onlineTestData, setOnlineTestData] = useState(undefined);
	const [otiData, setOtiData] = useState(undefined);
	const [isTemplate, setIsTemplate] = useState(false);
	const [isAutoEstimateType, setIsAutoEstimateType] = useState(false);
    const [questionList, setQuestionList] = useState([]);
    const [answerList, setAnswerList] = useState([]);
    const [sequenceRemList, setSequenceRemList] = useState([]);
    const [sequenceMovedList, setSequenceMovedList] = useState([]);
    const [showPictureDlg, setShowPictureDlg] = useState(false);
    const [fileUrl, setFileUrl] = useState(false);
	const [currPage, setCurrPage] = useState(0);
    const [timeLimitInMinutes, setTimeLimitInMinutes] = useState(0);
    const [timeLimitEnabled, setTimeLimitEnabled] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [currTestTime, setCurrTestTime] = useState();
    const [urls, setUrls] = useState([]);
	const [showPrint, setShowPrint] = useState(false);
    const [showHowToCalcDlg, setShowHowToCalcDlg] = useState(false);
	const [isTestCompleted, setIsTestCompleted] = useState(false);
	//const [soundVolume, setSoundVolume] = useState(20);
    
	const timerIdRef = useRef();
	const currPageRef = useRef(0); //use only for free writing because it uses context dependant OneOption
	const answerListRef = useRef([]); //use only for free writing because it uses context dependant OneOption
    const documentClass = useSelector(storageClass);
    const dispatch = useDispatch();
	const isTeacher = () => isAuth().role > 0;

	useEffect(() => {
		timerIdRef.current = initializeTimeCounter(1, setCurrTestTime); //refresh each sec
		return () => {clearInterval(timerIdRef.current);};
    }, []);

  	useEffect(() => {
		const initSettings = (_otSettings) => {
			setOnlineTestInfoId(_otSettings.onlineTestInfoId);
			setIsAutoEstimateType(_otSettings.isAutoEstimateType); 
			setTimeLimitInMinutes(_otSettings.timeLimitInMinutes);
			setTimeLimitEnabled(_otSettings.timeLimitEnabled); 
		};

		const doReorder = (list, orderList) => {
			const reorderedList = [];
			for (let i = 0; i < orderList.length; i ++) reorderedList.push(list[orderList[i]]);
			return reorderedList;
		};
	
		const fetchData = async (_otSettings) => {
			//1 - get ot data
			const _otData = await getOnlineTestById(_otSettings.onlineTestId);
			_otData.questions = _otData.questions.map((item, ind) => ({...item, orderInd: ind}));
			setIsTemplate(!_otData.owner);
			let answers = _otData.questions.map(item => "");

			//2 - get oti data
			let _сurrPage = 0;
			let _otiData = [];
			const otiId = otSettings.onlineTestInfoId;
			if (isAuth().role === 0) { //для ученика
				_otiData = await onlineTestInfoApi.getOnlineTestInfoById(otiId);
				if (_otiData.isComplete) {
					setIsTestCompleted(true); //тест завершен
					return;
				}
				setOtiData(_otiData);
				_сurrPage = _otiData.answers.filter(item => item.answer).length;

				//3 - update oti data
				if (!_otiData.startDate) {
					//3.1 - set start date
					const start = new Date();
					setStartDate((new Date(start)).valueOf());

					//3.2 - set random order if needed
					if (_otSettings.isRandomOrder) {
						_otData.questions = shuffle(_otData.questions);
					}
					const orderList = _otData.questions.map(item => item.orderInd);

					//3.3 - update oti data
					const updateData = {startDate: start, orderList: orderList, __v: _otiData.__v};
					const newOtiData = await onlineTestInfoApi.updateOnlineTestInfoById(otiId, updateData);
					setOtiData(newOtiData);
				} else {
					//3.1 - get random order
					_otData.questions = doReorder(_otData.questions, _otiData.orderList);

					//3.2 - get start date
					setStartDate((new Date(_otiData.startDate)).valueOf());

					//3.3 - get answers
					if (isAuth().role === 0) { //для ученика
						answers = _otiData.answers.map(item => item.answer);
						answers = doReorder(answers, _otiData.orderList);
					}
				}
			}

	
			setQuestionList(_otData.questions);
			setOnlineTestData(_otData); //сохраняем после изменений
			setAnswerList(answers);	
			setCurrPage(_сurrPage);
		};

		if (!otSettings) return;
		if (!isTeacher()) initSettings(otSettings);
		fetchData(otSettings);
	}, [otSettings]);

	const handleStudentIsReadyToComplete = () => {
		if (isTeacher()) {
			history.push('/practicum/ot');	
		} else {
			handleNextQuestion(currPage);
		}
	};

	useEffect(() => {
		const completeTest = async () => {
			const updateOtiData = {
				isComplete: true,
				__v: otiData.__v
			};
			await updateOnlineTestInfoById(onlineTestInfoId, updateOtiData);
			setCurrPage(questionList.length);
		};
	
		if (!timeLimitEnabled) return;
		const remTime = getRemainingTimeInMSec(currTestTime, startDate, 60 * timeLimitInMinutes);
		if (remTime <= 0 && currPage < questionList.length) {
			toast.info('Тест завершен из-за окончания времени.');
			completeTest();
		}
    }, [currPage, onlineTestInfoId, otiData?.__v, startDate, currTestTime, questionList,
		timeLimitEnabled, timeLimitInMinutes]);

	useEffect(() => {
		const getUrls = async () => {
            const list = [];

            for (let i = 0; i < questionList.length; i ++) {
                const question = questionList[i];
                const qType = Number(question.questionType);

				const ils = question.illustration; 
				if (ils) {
					const illustration = JSON.parse(ils);
                    const url = await getOTUrl(illustration.name);
					list.push({type: 'illustr', qNum: i, illFileType: illustration.illFileType, answerNum: 0, url: url});
				}

                if (qType === QUESTION_TYPES.AUDIO) {
                    const urls = await getOTUrls(question.answerOptions);
					for (let k = 0; k < urls.length; k ++) 
                    	list.push({type: 'audio', qNum: i, answerNum: k, url: urls[k]});
                }

                if (qType === QUESTION_TYPES.NON_VERBAL) {
                    const urls = await getOTUrls(question.answerOptions);
					for (let k = 0; k < urls.length; k ++) 
						list.push({type: 'nonverb', qNum: i, answerNum: k, url: urls[k]});
                }

                if (qType === QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE) {
                    const urls = await getOTUrls(question.answerOptions);
					for (let k = 0; k < urls.length; k ++) 
						list.push({type: 'sequence', qNum: i, answerNum: k, url: urls[k], name: question.answerOptions[k]});
                }

				if (qType === QUESTION_TYPES.VIDEO) {
					const fileName = question.correctOptions.split('|')[1];
                    const url = await getOTUrl(fileName);
					list.push({type: 'video', qNum: i, answerNum: 0, url: url});
				}
			}
			setUrls(list);
		};

		getUrls();
	}, [questionList]);

	const handlePrevQuestion = pageNum => {
		if (pageNum === 0) return;
		setCurrPage(pageNum - 1);
	};

	const handleNextQuestion = pageNum => { //set next page after saving data
		const getScore = (_results) => {
			const correctAnswerNumber = _results.filter(item => item.isCorrect === SCORE_TYPE.CORRECT).length;
			const uncheckedAnswerNumber = _results.filter(item => item.isCorrect === SCORE_TYPE.UNDEFINED).length;
			const score = isAutoEstimateType && uncheckedAnswerNumber === 0 ? 
				calcScoreValue(correctAnswerNumber, _results.length) : SCORE_MANUAL_CHECK;
			return score;
		};

		const checkCorrectAnswer = (item, ind) => {
			const qType =  Number(item.questionType);

			const studentAnswer = answerList[ind].toString();
			const correctAnswer = item.correctOptions;

			//for Writing question, it's enough to have any text written (-1 means that the answer should be checked by teacher later):
			if (qType === QUESTION_TYPES.FREE_WRITING) return !!studentAnswer.trim() ? -1 : 0;

			//video: correctOptions contain the correct answer and the video file name
			if (qType === QUESTION_TYPES.VIDEO) {
				const correctVideoAnswer = correctAnswer.split('|')[0];
				return studentAnswer === correctVideoAnswer ? 1 : 0;
			}

			//for others: compare the receved answer and the correct answer:
			return studentAnswer === correctAnswer ? SCORE_TYPE.CORRECT : SCORE_TYPE.INCORRECT; //if no answer yet (studentAnswer is '') isCorrect will be false
		};

		const saveData = async () => {
			//prepare results of the step:
			const results = questionList.map((item, ind) => ({
				answer: answerList[ind].toString(),
				orderInd: item.orderInd,
				isCorrect: checkCorrectAnswer(item, ind),
			}));

			const resultList = results.sort((a,b) => a.orderInd - b.orderInd);

			if (!isTeacher()) {
				const updateOtiData = {
					answers: resultList.map(item => ({answer: item.answer, isCorrect: item.isCorrect})),
					isComplete: currPage === questionList.length - 1,
					score: getScore(resultList),
					__v: otiData.__v
				};
				
				const _otiData = await updateOnlineTestInfoById(onlineTestInfoId, updateOtiData);
				setOtiData(_otiData);
				socket.emit('BE-refresh-db', {type: DB_REFRESH.OTI, roomId: documentClass._id});
			}

			setCurrPage(currPage + 1); //go to next step
		};

		if (pageNum < questionList.length) 
			saveData();
	};

	const getSequenceLists = (pageNum, question, _answerList) => {
		let rems = [];
		const moves = [];
		let allOptions = [...question.answerOptions];
			
		const answerIds = !!_answerList[pageNum] ? _answerList[pageNum].split('|') : [];
	
		for (let i = 0; i < answerIds.length; i ++) {
			const ind = Number(answerIds[i]);
			const move = { name: allOptions[ind], id: ind }
			moves.push(move);
			allOptions[ind] = undefined;
		}
	
		for (let i = 0; i < allOptions.length; i ++) { //do not use filter and map
			if (allOptions[i]) {
				rems.push({name: allOptions[i], id: i});
			}
		}
		return [rems, moves];
	};

	useEffect(() => {
		//only for 2 sequence pages:
		if (!questionList || questionList.length === 0 || currPage >= questionList.length) return;
		const question = questionList[currPage];
		const qType = Number(question.questionType);
		if (qType !== QUESTION_TYPES.CORRECT_WORD_SEQUENCE && qType !== QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE) return;

		const [rems, moves] = getSequenceLists(currPage, question, answerList);

		setSequenceRemList(rems);
		setSequenceMovedList(moves);
	}, [answerList, currPage, questionList]);

	const getIsStudentAnswered = (pageNum) => {
		if (isTeacher()) return true;
		if (pageNum === questionList.length) return true;
		if (answerList[pageNum] === "") return false;

		const qType = Number(questionList[pageNum].questionType);
		if (qType === QUESTION_TYPES.CORRECT_WORD_SEQUENCE || qType  === QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE)
			return sequenceRemList.length === 0;

		return true;
	};

	const handlePrint = () => {
		setShowPrint(true);
	};

	const getPrintData = () => {
		if (!showPrint) return [];

		let [rems, moves] = [[], []];

		const title = 
			<div className="cor-net__section ">
				{showNameValue('Название онлайн теста', onlineTestData.title)}
				{showNameValue('Описание онлайн теста', onlineTestData.description)}
				{onlineTestData?.owner && showNameValue('Преподаватель', fullName(onlineTestData.owner))}
				{showNameValue('Дата выдачи задания', printOnlyDate(new Date()))}
				{showNameValue('Кол-во вопросов', questionList.length)}
			</div>
		const printData = [title];

		for (let ind = 0; ind < questionList.length; ind ++) {
			const question = questionList[ind]; 
			const qType = Number(question.questionType); 
			if (qType === QUESTION_TYPES.CORRECT_WORD_SEQUENCE || 
				qType === QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE) {
					[rems, moves] = getSequenceLists(ind, question, answerList);
				}

				if (qType !== QUESTION_TYPES.AUDIO && qType !== QUESTION_TYPES.NON_VERBAL &&
					qType !== QUESTION_TYPES.VIDEO) {
					const page = getQuestionPage(ind, rems, moves);
					printData.push(page);
				}
			}

		return printData;
	};

	const getNextQuestion = pageNum => {
		const isStudentAnswered = getIsStudentAnswered(pageNum);
		if (!isStudentAnswered || showPrint) return (<></>);

		return (
			<>
				{pageNum < questionList.length - 1 ? 
					<Button onClick={() => handleNextQuestion(pageNum)}>
						Следующий вопрос
					</Button>
				:
					<Button onClick={handleStudentIsReadyToComplete}>
						Завершить {!isTeacher() ? 'тест' : 'предпросмотр'}
					</Button>
				}
			</>
		);		
	};

	const getActionButtons = (pageNum) => {
		//lock Next button if the student is not answered yet:
		const isLastPage = pageNum === questionList.length - 1;
		const isStudentAnswered = getIsStudentAnswered(pageNum);
		
		const buttons = (
			<>
				<div></div>
				<div>
					<button
						type="button"
						className={ss.btn}
						onClick={() => handlePrevQuestion(pageNum)}
						disabled={!(pageNum > 0 && pageNum < questionList.length)}
					>
						<Icon name="arrow-round-prev" />
						Предыдущий вопрос
					</button>
				</div>
				<div>
					<button 
						type="button"
						className={ss.btn}
						onClick={() => handleNextQuestion(pageNum)}
						disabled={isLastPage || !isStudentAnswered } 
					>
						{pageNum < questionList.length-1 ? 'Следующий вопрос' : 'Результаты теста'}
						<Icon name="arrow-round-next" />
					</button>
				</div>

				<div>
					{((isLastPage && isStudentAnswered) || (isAuth().role > 0 && pageNum < questionList.length)) && 
					<button 
						type="button"
						className={ss.btn}
						onClick={handleStudentIsReadyToComplete}>
						<Icon name="success"/>
						Завершить
					</button>}
				</div>
 			</>
		);
		return buttons; 
	};

	const getCBAnswerByInd = (pageNum, ind) => {
		if (!answerList[pageNum]) return "";

		const answers = answerList[pageNum].split('|');
		for (let i = 0; i < answers.length; i ++) {
			if (Number(answers[i]) === ind)
				return ind;
			}

		return "";
	};

	const savePreparedAnswer = (pageNum, answer) => {
		if (pageNum < answerList.length) {
			const answers = answerList.map((item, ind) => ind !== pageNum ? item : answer);
			setAnswerList(answers);
		}
	};

    const handleChangeСB = (e, pageNum) => {
        const cbAnswers = [];
        const checkboxes = document.querySelectorAll('input[type=checkbox]:checked');
        for (let i = 0; i < checkboxes.length; i++) {
			const val = checkboxes[i].value;
			if (val !== 'on') {
            	cbAnswers.push(val);
			}
        }
		const answers = cbAnswers.join('|');
		savePreparedAnswer(pageNum, answers);
    };

	const getRbAnswerByInd = (pageNum, _answerList) => pageNum < _answerList.length && _answerList[pageNum] !== '' ? 
		Number(_answerList[pageNum]) : '';

	const getRbBox = (pageNum, answerOptions, isTextEditorView, adding = '') => {
		const selectedInd = getRbAnswerByInd(pageNum, answerList);

		return (
			answerOptions.map((item, ind) => 
				<div key={"rbkey"+ind} className={ss.row}>
					<Checkbox
						id={ind} 
						value={ind} 
						name={item}
						checked={ind === selectedInd} 
						onChange={(e) => savePreparedAnswer(pageNum, Number(e.target.value))}
						label={isTextEditorView ? htmlParser(item) : adding + item}
						square={true}
					/>
				</div>
			)			
		);
	};

	const handleItemMove = (pageNum, item, isShiftToMoved) => {
		let rems = [...sequenceRemList];
		let moved = [...sequenceMovedList];
		
		if (isShiftToMoved) {
				const elem = rems.find((obj, i) => obj.id === item.id);
				rems = rems.filter((obj, i) => obj.id !== item.id);
				moved.push(elem);
		} else {
			const elem = moved.find((obj, i) => obj.id === item.id);
			moved = moved.filter((obj, i) => obj.id !== item.id);
			rems.push(elem);
		}

		setSequenceRemList(rems);
		setSequenceMovedList(moved);
		const answer = moved.map(obj => obj.id).join('|');
		savePreparedAnswer(pageNum, answer);
	};

	// useEffect(() => {
	// 	if (questionList.length === 0) return;
	// 	const question = questionList[currPage]; 
	// 	const qType = Number(question.questionType); 
	// 	if (qType !== QUESTION_TYPES.AUDIO) return;

	// 	const answerOptions = question.answerOptions;
	// 	for (let i = 0; i < answerOptions.length; i ++) {
	// 		const audioEl = document.getElementById("isAudio"+i);
	// 		const audioDivEl = document.getElementById("isAudioDiv"+i);
	// 		const d = audioDivEl.disabled;

	// 		if (!audioEl.paused) {
	// 		}
	// 	}
    // }, [currPage, questionList, currTestTime]);

	const getRbAudio = (pageNum, answerOptions, filesList) => {
		const selectedInd = getRbAnswerByInd(pageNum, answerList);

		const options = (
			answerOptions.map((item, ind) => 
				<div key={"rbkey"+ind} className={ss.row}>
					<Checkbox 
						id={ind} 
						value={ind} 
						name={item}
						checked={ind === selectedInd} 
						onChange={(e) => savePreparedAnswer(pageNum, Number(e.target.value))}
					/>
					
					{/* <Sound id={"isAudio"+ind} volume={0} src={filesList[ind] ? filesList[ind].Url : ''} key={"audio"+ind} /> */}
					<div key={'mid01'+(ind+1)}>
						<audio id={"isAudio"+ind} volume={0} src={filesList[ind] ? filesList[ind].Url : ''} controls key={"audio"+ind} />
					</div>
				</div>
			)			
		);

		return options;
	};

	const getIllustration = (pageNum) => {
		const url = urls.find(item => item.qNum === pageNum && item.type === 'illustr');
		if (!url)  return <></>;
		const content = url.url;
		const ifType = url.illFileType;

		return (
		<div className={ss.section}>

			{ifType === FORMAT_TYPE.IMAGE && 
				<div className={ss.questionFile}>
					<img src={content} alt='' />
				</div>
			}

			{ifType === FORMAT_TYPE.VIDEO && 
				<div className={ss.questionFile}>
					<video src={content} controls={true}></video>
				</div>
			}
			{ifType === FORMAT_TYPE.AUDIO && 
				<div className={ss.questionFile_audio}>
					<audio src={content} volume={0} controls={true} width="100" height="30" />
				</div>
			}
		</div>
		);
	};

	const getAudioQuestion = pageNum => {
		const question = questionList[pageNum]; 
		const answerOptions = question.answerOptions;
		const filesList = urls.filter(item => item.qNum === pageNum && item.type !== 'illustr')
			.map(item => ({Url: item.url}));

		const options = filesList.length > 0 ? getRbAudio(pageNum, answerOptions, filesList) : <></>;

		return (
			<div className={ss.section}>
				<div className={ss.row}>
					<div className={ss.info}>
						<Icon name="info" />
						Прослушайте все ответы и выберите один из них: 
					</div>
				</div>

				{options}

				<div className={ss.row}>
					{getNextQuestion(pageNum)}
				</div>
			</div>
		);
	};

	const getCorrectWordSequence = (pageNum, _remList, _movedList) => {
		const getSequence = (pageNum, list, isRem) => {
			if (list.length === 0) return (
				<div className={ss.sequence__wrap}></div>
			);

			return (
				<div className={ss.sequence__wrap}>
					<div className={ss.sequence__list}>
						{list.map((item, ind) => 
							<button 
								type="button"
								className={ss.sequence__btn}
								key={(item.id + 1) * (isRem ? 1 : -1)}
								onClick={() => handleItemMove(pageNum, item, isRem)}>
								{item.name}
							</button>
						)}
					</div>
					
				</div>
			);
		};

		return (
		<div className={ss.section}>
			<div className={ss.row}>
				<div className={ss.info}>
					<Icon name="info" />
					Поставьте слова в правильном порядке: 
				</div>
			</div>
			<div className={ss.row}>
				<div className={ss.sequence}>
					{getSequence(pageNum, _remList, true)}
					{getSequence(pageNum, _movedList, false)}
				</div>
			</div>
			<div className={ss.row}>
				{getNextQuestion(pageNum)}
			</div>
		</div>
		);
	};

	const getChooseOneAnswer = pageNum => {
		return (
			<div className={ss.section}>
				<div className={ss.row}>
					<div className={ss.info}>
						<Icon name="info" />
						Выберите один из ответов: 
					</div>
				</div>
				
				{getRbBox(pageNum, questionList[pageNum].answerOptions, true)}
				
				<div className={ss.row}>
					{getNextQuestion(pageNum)}
				</div>
			</div>			
		);
	};

	const getChooseManyAnswers = pageNum => {
		return (
			<div className={ss.section}>
				<div className={ss.row}>
					<div className={ss.info}>
						<Icon name="info" />
						Выберите все правильные ответы среди перечисленных:
					</div>
				</div>
				
				{questionList[pageNum].answerOptions.map((item, ind) => 
				<div className={ss.row} key={'cbelem'+ind}>
					<Checkbox 
						value={ind}  
						name={item}
						checked={ind === getCBAnswerByInd(pageNum, ind)} 
						onChange={ e=> handleChangeСB(e, pageNum)}
						label={htmlParser(item)}
						square={true}
					/>
				</div>
				)}
			
				<div className={ss.row}>
					{getNextQuestion(pageNum)}
				</div>
			</div>
		);
	};

	const handleShowPicture = (_fileUrl) => {
		setFileUrl(_fileUrl);
		setShowPictureDlg(true);
	};

	const getCorrectImageSequence = (pageNum, _remList, _movedList) => {
		const getImage = (pageNum, i, file, fileItem, vRem) => {
			return (
				<div className={ss.grid__img} key={'dir'+i}>
					<img 
						src={getContentByFile(isTemplate, file)} 
						onClick={() => handleItemMove(pageNum, fileItem, vRem === 1)}  
						alt='' 
						key={'mid03'+(i+1)*vRem} 
					/>
					<div className={ss.grid__fullScreen} 
						onClick={() => handleShowPicture(!isTemplate ? file : file.name)}
					>
						<Icon name="full-screen" />
					</div>
				</div>
			);
		};
	
		const getImageGridList = (pageNum, fileList, nameList, vRem) => {
			if (fileList.length === 0) return (
				<div className={ss.grid__empty}></div>
			);
	
			return (
				<div className={ss.grid__full}>
					<div className={ss.grid}  key='grid01'>
						{fileList.map((f, i) => 
							<button className={ss.grid__btn} key={'btn'+(i+1)*vRem} >
								{getImage(pageNum, i, f, nameList[i], vRem)}
							</button>
						)}
					</div>									
				</div>
			);
		};
	
		let remFiles, movedFiles;
		if (!isTemplate) {
			remFiles = urls.filter(item => item.qNum === pageNum && 
				item.type !== 'illustr' && _remList.map(item => item.name).includes(item.name))
			.map(item => ({Url: item.url}));
			movedFiles = urls.filter(item => item.qNum === pageNum && 
				item.type !== 'illustr' && _movedList.map(item => item.name).includes(item.name))
			.map(item => ({Url: item.url}));
		} else {
			remFiles = _remList;
			movedFiles = _movedList;
		}

		return (
			<div className={ss.section}>
				<div className={ss.row}>
					<div className={ss.info}>
						<Icon name="info" />
						Расположите картинки в правильном порядке: 
					</div>
				</div>
	
				<div className={ss.row}>
					<div className={ss.grid__wrap}>
						{getImageGridList(pageNum, remFiles, _remList, 1)}
						{getImageGridList(pageNum, movedFiles, _movedList, -1)}
					</div>
				</div>
	
				<div className={ss.row}>
					{getNextQuestion(pageNum)}
				</div>
			</div>
		);
	};

	const getNonVerbalQuestion = pageNum => {
		const getRbImages = (pageNum, answerOptions, filesList) => {
			const selectedInd = getRbAnswerByInd(pageNum, answerList);
	
			const options = (
				answerOptions.map((item, ind) => 
					<div key={"rbkey"+ind} className={ss.grid__checkbox}>
						<Checkbox
							value={ind} 
							name={item}
							checked={ind === selectedInd} 
							onChange={(e) => savePreparedAnswer(pageNum, Number(e.target.value))}
							square={true}
							label={
							<>
								<div className={ss.grid__img}>
									<img 
										src={getContentByFile(isTemplate, filesList[ind])} 
										alt='' 
										key={'mid03'+(ind+1)} 
									/>
								</div>
							</>}
						></Checkbox>
	
						<div className={ss.grid__fullScreen}  
							 onClick={() => handleShowPicture(!isTemplate ? filesList[ind] : filesList[ind].name)}
						>
							<Icon name="full-screen" />
						</div>
					</div>
				)
			);
	
			return options;
		};

		const question = questionList[pageNum]; 
		const answerOptions = question.answerOptions;
		let filesList = answerOptions.map(item => ({name: item}));
		if (!isTemplate) {
			filesList = urls.filter(item => item.qNum === pageNum && item.type !== 'illustr')
				.map(item => ({Url: item.url}));
		}

		const options = filesList.length > 0 ? getRbImages(pageNum, answerOptions, filesList) : <></>;

		return (
			<div className={ss.section}>
				<div className={ss.row}>
					<div className={ss.info}>
						<Icon name="info" />
						Выберите один из ответов:
					</div>
				</div>

				<div className={ss.row}>
					<div className={ss.grid}>
						{options}
					</div>
				</div>

				<div className={ss.row}>
					{getNextQuestion(pageNum)}
				</div>

			</div>);
	};

	const getCompleteSentence = pageNum => {
		const question = questionList[pageNum]; 
		return (
			<div className={ss.section}>
				<div className={ss.row}>
					<div className={ss.info}>
						<Icon name="info" />
						Завершите предложение:
					</div>
				</div>

				{getRbBox(pageNum, question.answerOptions, false, '...')}

				<div className={ss.row}>
					{getNextQuestion(pageNum)}
				</div>
			</div>
		);	
	};

	const getFreeWriting = () => {
		const updateAnswer = answer => {
			const cleanedAnswer = cleanupDivs(answer);
			const currAnswer = answerListRef.current[currPageRef.current];
			let newAnswer = answer;

			if (!cleanedAnswer) { //очищенная строка пустая
				if (!currAnswer) return; //была пустая строка и ничего не введено
				else newAnswer = ''; //пришел пустой ответ
			}

			//сохраняем
			const answers = answerListRef.current.map((item, ind) => ind !== currPageRef.current ? item : newAnswer);
			setAnswerList(answers);
		};

		return (
			<div className={ss.section}>
				<div className={ss.row}>
					<div className={ss.info}>
						<Icon name="info" />
						Напишите текст:
					</div>
				</div>
				<div className={ss.row}>
					{getOneOption(0, '', answerListRef.current[currPageRef.current], updateAnswer, 
						null, MAX_DESC_LENGTH)}
				</div>
				<div className={ss.row}>
					{getNextQuestion(currPageRef.current)}
				</div>
			</div>
		);	
	};

	const getVideoQuestion = pageNum => {
		const question = questionList[pageNum];

		const foundFiles = urls.filter(item => item.qNum === pageNum && item.type !== 'illustr')
			.map(item => ({Url: item.url}));

		if (foundFiles.length === 0) return <></>;
		const video = foundFiles[0];
  
		return (
			<div className={ss.section}>
				<div className={ss.row}>
					<div className={ss.info}>
						<Icon name="info" />
						Посмотрите видео и выберите один из ответов:
					</div>
				</div>

				{!!video && (
				<div className={ss.row + " "+ ss.row_center}>
					<div className={ss.col}>
						<div className={ss.video}>
							<video src={video.Url} controls={true} width="600" height="400" key={"v03"} ></video>
						</div>
					</div>
					<div className={ss.col}>
						{getRbBox(pageNum, question.answerOptions, true)}
					</div>
				</div>
				)}
				<div className={ss.row}>
					{getNextQuestion(pageNum)}
				</div>
			</div>			
		);
	};

    const getQuestionPage = (pageNum, _remList, _movedList) => {
		const getQuestionName = pageNum => {
			const question = questionList[pageNum]; 
			const qType = Number(question.questionType); 
			let qName = question.questionName;
			let qAnswer = '';
			if (qType === QUESTION_TYPES.COMPLETE_SENTENCE) {
				qName += '... ';
				const answerId = answerList[pageNum];
				if (answerId !== '') {
					qAnswer = question.answerOptions[Number(answerId)];
				}
			}
			return [qName, qAnswer];
		};
	
		const qType = Number(questionList[pageNum].questionType); 
		const [qName, qAnswer] = getQuestionName(pageNum);
		currPageRef.current = currPage; //only for free writing
		answerListRef.current = answerList;

		return (
			<>
				<div className={ss.question}>
					<div className={ss.question__name}>
						{htmlParser(qName)} <span>{qAnswer}</span>
					</div>
				</div>

				{!showPrint && getIllustration(pageNum)}

				{(qType === QUESTION_TYPES.AUDIO) && getAudioQuestion(pageNum)}
				{(qType === QUESTION_TYPES.CORRECT_WORD_SEQUENCE) && getCorrectWordSequence(pageNum, _remList, _movedList)}
				{(qType === QUESTION_TYPES.CORRECT_ANSWER_ONE) && getChooseOneAnswer(pageNum)}
				{(qType === QUESTION_TYPES.CORRECT_ANSWER_MANY) && getChooseManyAnswers(pageNum)}
				{(qType === QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE) && getCorrectImageSequence(pageNum, _remList, _movedList)}
				{(qType === QUESTION_TYPES.NON_VERBAL) && getNonVerbalQuestion(pageNum)}
				{(qType === QUESTION_TYPES.COMPLETE_SENTENCE) && getCompleteSentence(pageNum)}
				{(qType === QUESTION_TYPES.FREE_WRITING) && getFreeWriting()}
				{(qType === QUESTION_TYPES.VIDEO) && getVideoQuestion(pageNum)}
			</>
		);
    }

	const getResultPage = () => {
		const handleHowToCalc = () => {
			setShowHowToCalcDlg(true);
		};
	
		const clearAllOpts = () => {
			dispatch(loadCountersOt(documentClass._id));
			clearRunningTestInfo();
			clearInterval(timerIdRef.current);
			clearOtSettings(undefined);
		};

		const handleGoMain = () => {
			clearAllOpts();
			history.push('/ot');
		};
		const handleGoProfile = () => {
			clearAllOpts();
			history.push(FEATURES.profile.to + '/' + otiData.owner + '/' + PROFILE_TAB.ONLINE_TESTS);
		};

		if (!otiData) return <></>;

		const questionNumber = onlineTestData.questions.length;
		const correctAnswerNumber = otiData.answers.filter(item => item.isCorrect).length;

		let iconName, status, iconText, score, scoreName, info, isResultOk;

		if (isAutoEstimateType) {
			score = calcScoreValue(correctAnswerNumber, questionNumber);
			scoreName = getScoreName(score);
			isResultOk = score >= getMinGoodScore();
		}
		[iconName, iconText, status, info] = getTestScoreOpts(isAutoEstimateType, isResultOk, 'ot');

		return (
			<div className="cor-work__result">
				<div className={"cor-work__result_caption " + status}>
					<div className="cor-work__result_icon">
						<Icon name={iconName} />
					</div>
					<div className="cor-work__result_title">{iconText}</div>
				</div>

				{isAutoEstimateType && 
					<div className="cor-work__result_rating">
						Оценка: {scoreName}
					</div>
				}

				<div className="cor-work__result_info">{info}</div>

				{isAutoEstimateType && 
					<div className="cor-work__result_methodology" onClick={handleHowToCalc}>
						Методика расчета оценки
					</div>
				}

				<div className="cor-work__result_buttons">
					<Button onClick={handleGoMain}>
						Вернуться в онлайн тесты
					</Button>
					<Button border={true} onClick={handleGoProfile}>
						Перейти в профиль
					</Button>
				</div>
			</div> 
		);
	};

	const getInitState = () => {
		if (isTeacher()) return <></>;
		return (<div className="labWorkShow__first">
				<div className="labWorkShow__firstName"><span>Преподаватель не определил задание.</span></div>
			</div>);};
	if (questionList.length === 0 || isTestCompleted) {return getInitState();}

    return (
		<>
		<ContentHead title={"Название теста: " + onlineTestData?.title} />
		<ContentDesc>Описание теста: {onlineTestData?.description}</ContentDesc>
		<Content>
			<ContentHeader flex={true} className={ss.header}>
				<div className={ss.header__nav}>
					{currPage < questionList.length ? 
						<>Вопрос { currPage+1 } / {questionList.length}</>
					: 
					<>Результаты онлайн теста</>
					}
				</div>
				<div className={ss.header__time}>
					{timeLimitEnabled ? showRemainingTime(currTestTime, startDate, 60 * timeLimitInMinutes, true) : 
										showCurrTime(currTestTime, startDate)}
				</div>

				{isAuth().role > 0 && 
				<div>
					<Button icon={true}
							onClick={handlePrint}>
						<Icon name="print" />Печать
					</Button> 
				</div>
				}
			</ContentHeader>

			<ContentBody>
				{currPage < questionList.length ? 
					getQuestionPage(currPage, sequenceRemList, sequenceMovedList) 
					: 
					getResultPage()
				}
			</ContentBody>

			<ContentFooter className={ss.footer}>
				{getActionButtons(currPage)}
			</ContentFooter>
		
			{showPictureDlg &&
			<ImageDlg
				showModal={showPictureDlg}
				setShowModal={setShowPictureDlg}
				file={fileUrl}
			/>
			}

			{showHowToCalcDlg && <ScoreCalcTableDlg
				showHowToCalcDlg={showHowToCalcDlg} 
				setShowHowToCalcDlg={setShowHowToCalcDlg}
			/>
			}

			{showPrint && <ModalPrint
                showPrint={showPrint} 
				closePrint={() => setShowPrint(false)}
                printData={getPrintData()}
            />}
		</Content>
		</>
    )
}

export default OnlineTestExecute;
