import React, {useState, useEffect} from "react";
import {FEATURES} from "../../../api/config";
import LabWorks from '../../practicum/LabWorks';
import OnlineTests from '../../OnlineTest/OnlineTests';
import {getIsOtView, getIsLwView} from "../../../redux/slices/tutorialslice";
import {ContentWrap, ContentHead, Content, ContentBody} from '../ContentParts';
import {storageClass} from "../../../redux/slices/storage";
import SectionTabs from "../../ui/SectionTabs";
import {useSelector, useDispatch} from "react-redux";
import socket from "../../../socket";
import {DB_REFRESH} from "../../ui/utils/gen_utils";
import Footer from '../Footer';
import {loadCountersOt, loadCountersPi} from "../../../redux/slices/lists";
import {hasFeature, isAuth} from "../../../helpers/auth";
import {TEST_OPTION, isActiveTestView} from "../../ui/utils/ServerInfo";
import {storageOrg} from "../../../redux/slices/storage";
import DashboardLicense from "./DashboardLicense";
import DashboardReviewTest from "./DashboardReviewTest";
import "./Dashboard.scss";

export const DashboardPracticum = (props) => {
  const [tabs, setTabs] = useState(null);

  const documentOrg = useSelector(storageOrg);
  const documentClass = useSelector(storageClass);
  const isOtView = useSelector(getIsOtView);
  const isLwView = useSelector(getIsLwView);
  const {lists: {counters}} = useSelector(state => state);
  const dispatch = useDispatch();
  
	useEffect(() => {
		dispatch(loadCountersOt(documentClass._id));
		dispatch(loadCountersPi(documentClass._id));
	}, [dispatch, documentClass._id]);
	
	useEffect(() => {
		socket.on("FE-refresh-db", ({type}) => {
		  if (type === DB_REFRESH.OTI) dispatch(loadCountersOt(documentClass._id));
		  if (type === DB_REFRESH.PI) dispatch(loadCountersPi(documentClass._id));
		});
		return () => {
		  socket.off("FE-refresh-db");
		};
  }, [dispatch, documentClass._id]);

  useEffect(() => {
    const notify = {
      ot: {value: counters.ot, path: isAuth().role > 0 ? '/reviewtest/ot' : FEATURES.pi.to},
      pi: {value: counters.pi, path: {pathname: FEATURES.pi.to, state: {status: isAuth().role > 0 ? 2 : 1}}},
    };

    const _tabs = FEATURES.practicum.sub.map((s) => ({
      name: FEATURES[s].title + " >",
      icon: FEATURES[s].icon,
      tooltip: FEATURES[s].tooltip,
      to: (([TEST_OPTION.OT,TEST_OPTION.LW].includes(s) && isAuth().role === 1) ?
        '/practicum/' + s : FEATURES[s].to), disabled: !hasFeature(documentOrg, s),
      notify: notify[s]
    }));

    setTabs(_tabs);
  }, [documentOrg, counters]);

  const opt = props.match.params.opt;
  if (opt && !hasFeature(documentOrg, opt)) {
    return <DashboardLicense {...props}/>;
  }

  if ((isActiveTestView(opt) && opt) || (opt === 'ot' && !isOtView) || (opt === 'lw' && !isLwView)) {
    return <DashboardReviewTest {...props}/>;
  }

  return (
    <ContentWrap>
      {{
        'ot': <OnlineTests {...props} />,
        'lw': <LabWorks {...props} />,
        undefined: <>
          <ContentHead title="Задания"/>
          <Content background={false}>
            <ContentBody padding={false}>
              {tabs && <SectionTabs {...props} tabs={tabs}/>}
            </ContentBody>
          </Content>
        </>
      }[opt]}
      <Footer  {...props}/>
    </ContentWrap>
  );
}

export default DashboardPracticum;
