import React, {useEffect, useState} from 'react';
import ConnectSettings from '../../ConnectSettings';
import ClassEventList from '../../ClassEventList';
import ClassChatList from '../../ClassChatList';
import ClassVoice from '../../ClassVoice';
import socket from "../../../socket";
import {isAuth} from "../../../helpers/auth";
import {chatApi} from "../../../api/api";
import {storageClass, storageOptions} from "../../../redux/slices/storage";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import ModalRunStudentTestDlg from "../../ui/ModalDialogs/ModalRunStudentTestDlg";
import ModalFinishTestByTeacherDlg from "../../ui/ModalDialogs/ModalFinishTestByTeacherDlg";
import {getActiveTests, stopActiveTest, checkRunStudentTest, testInProgress, 
    canOpenStudentDlg} from "../../ReviewTest/rt_utils";
import {getNbInfoMessage} from "../../Notebook/nb_utils";
import {getOtInfoMessage} from "../../OnlineTest/ot_utils";
import {setRunningTestInfo, clearRunningTestInfo} from "../../ui/utils/ServerInfo";
import {FEATURES} from "../../../api/config";
import {Icon} from "../../ui";
import {toast} from "react-toastify";
import "./Aside.scss";

const Aside = (props) => {
    const history = useHistory();
    const documentClass = useSelector(storageClass);
    const {toggle} = useSelector(state => state);

    const [msg, setMsg] = useState([]);
    const [ntf, setNtf] = useState([]);
    const {aside} = useSelector(storageOptions);
    const [isVisible, setIsVisibe] = useState(aside);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showStudentTest, setShowStudentTest] = useState(false);
    const [showFinishTest, setShowFinishTest] = useState(false);
    const [takePartInTest, setTakePartInTest] = useState(false);
    const [activeTests, setActiveTests] = useState([]);
    const [isTestInProgress, setIsTestInProgress] = useState(false);
    const [runStudentTest, setRunStudentTest] = useState(null);
    const [timerId, setTimerId] = useState(null);
    const [durTimeInSecs, setDurTimeInSecs] = useState(null);
    const [showInfoMsg, setShowInfoMsg] = useState(true);
    const [startTestTime, setStartTestTime] = useState(undefined);
    const [testDuration, setTestDuration] = useState(undefined);

    const toggleAside = (isAside) => {
        if (!isDisabled) {
            setIsVisibe(isAside);
            props.setAside(isAside);
        }
    }

    useEffect(() => {
        if (documentClass._id && isAuth().role < 2) {
            props.changeClass({to:documentClass._id});
            socket.emit("BE-join-room", {
                roomId: documentClass._id, user: isAuth(),
                options: {video: toggle.video, audio: toggle.audio, screen: toggle.screen}
            });
        }
        // eslint-disable-next-line
    }, [documentClass._id]);

    useEffect(() => {
        const infoMsg = async () => {
            let msg = await getNbInfoMessage(documentClass._id, isAuth()._id);
            if (msg) toast.info(msg);
            msg = await getOtInfoMessage(documentClass._id, isAuth()._id);
            if (msg) toast.info(msg);
        };

        if (isAuth().role > 0 || !showInfoMsg) return;
        setShowInfoMsg(false);
        infoMsg();
    }, [showInfoMsg, documentClass._id]);

    useEffect(() => {
        const hidden = FEATURES.rlab.alt.includes(props.match.path) ||
          FEATURES.notebook.alt.includes(props.match.path) ||
          FEATURES.pi.alt.includes(props.match.path) ||
          (FEATURES.instruments.alt.includes(props.match.path) && 
                ['video', 'videofile', 'videocamera', 'videolib'].includes(props.match.params.id));
        setIsVisibe(!hidden && !isDisabled && aside);
        // eslint-disable-next-line
    },[props.match]);

    useEffect(() => {
        //ученик только что залогинился: если активный тест запущен, достаем данные
        if (!documentClass?.classUsers || isAuth().role > 0) return;
        const teacherIds = documentClass.classUsers.filter(item => item.role > 0)?.map(item => item._id).join('|');
        getActiveTests(teacherIds, documentClass._id, setActiveTests);
    },[documentClass]);

    useEffect(() => { //for student
        if (activeTests.length === 0) return;
        const at = activeTests[0];
        if (!at) return;

        if (testInProgress(at)) {
            if (isAuth().role === 0) { //для ученика
                //Тест в процессе. Если ученик был offline, получить данные для запуска его теста
                checkRunStudentTest(at, setRunStudentTest);
            } else { //для учителя
                //todo later
                //startTestTime = at.createdAt
                //set: setStartTestTime, setTestDuration 
            }
        }
    },[activeTests, documentClass]);
    //useEffect(() => {
        //todo later
        //stopActiveTest(documentClass._id);
    //},[startTestTime, testDuration]);

    useEffect(() => {
        const prepareStudentDlg = async () => {
            const show = await canOpenStudentDlg(runStudentTest);
            if (!show) return;
            setIsTestInProgress(true);
            setRunningTestInfo(runStudentTest);  //сохраняем детали теста у студента
            setDurTimeInSecs((runStudentTest.timeLimitEnabled ? runStudentTest.timeLimitInMinutes : 2 * 60) * 60 * 1000);
            setShowStudentTest(true); //открываем диалог для студента с информацией о тесте
        };
        if (!runStudentTest) return; //данные о тесте для диалогов
        prepareStudentDlg(); //срабатывает только для студента:
    }, [runStudentTest]);

    const renderType = (type) => {
        switch (type) {
            case 1: return "Ушел";
            case 2: return "Поднял руку";
            case 3: return "Режим тишины включен";
            case 4: return "Режим тишины выключен";
            default: return "Присоединился";
        }
    }

    useEffect(() => {
        if (documentClass?._id) {
            setMsg([]);
            chatApi.getChat(documentClass._id, 20).then(res => {
                  const msgs = res.map(m => ({
                      author: m.owner ?? {name: "Удален"},
                      text: m.message,
                      timestamp: m.updatedAt,
                      type: 'text',
                  }));
                  setMsg(msgs);
              }
            );
            setNtf([]);
            chatApi.getNotification(documentClass._id, 20).then(res => {
                    const ntfs = res.map(m => ({
                        author: m.owner ?? {name: "Удален"},
                        text: renderType(m.type),
                        timestamp: m.updatedAt,
                        type: 'text',
                    }));
                    setNtf(ntfs);
                }
            );
        }
    }, [documentClass])

    useEffect(() => {
        const completeTest = () => {
            setIsTestInProgress(false);
            stopActiveTest(documentClass._id);
        };

        setIsDisabled(isTestInProgress);
        if (isTestInProgress) setIsVisibe(false);
        if (!isTestInProgress || !durTimeInSecs) return;

        const _timerId = setTimeout(completeTest, durTimeInSecs); 
        setTimerId(_timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTestInProgress, durTimeInSecs]);

    useEffect(() => {
        //завершим тест у студента:
        if (!isTestInProgress && timerId) {
            clearTimeout(timerId);
            setTimerId(null);
            clearRunningTestInfo();
        
            setShowStudentTest(false);
            if (takePartInTest) { //if the student started to take part in the test
                setShowFinishTest(true); //this student should complete the test
                setTakePartInTest(false);
            }
            setDurTimeInSecs(null);
        }
    }, [takePartInTest, isTestInProgress, timerId]);

    useEffect(() => {
        socket.on("FE-receive-message", ({msg}) => {
            setMsg((msgs) => [...msgs, msg]);
        });
        socket.on("FE-user-joined", ({user}) => {
            const ntf = {
                author: user,
                text: renderType(0),
                timestamp: +new Date(),
                type: "notification"
            };
            setNtf((ntfs) => [...ntfs, ntf]);
        });
        socket.on("FE-user-leave", ({user}) => {
            const ntf = {
                author: user,
                text: renderType(1),
                timestamp: +new Date(),
                type: "notification"
            };
            setNtf((ntfs) => [...ntfs, ntf]);
        });
        socket.on('FE-raise-hand', (user) => {
            const ntf = {
                author: user,
                text: renderType(2),
                timestamp: +new Date(),
                type: "notification"
            };
            setNtf((ntfs) => [...ntfs, ntf]);
        });
        socket.on("FE-receive-test", ({test}) => {
            if (isAuth().role > 0) return; 
            console.log('2-testRunId=', test.testRunId)
            checkRunStudentTest(test, setRunStudentTest);
        });
        socket.on("FE-stop-test", ({test}) => {
            if (test.testStop && isAuth().role === 0) {
                setIsTestInProgress(false);
            }
        });
        
        return () => {
            socket.off('FE-receive-message');
            socket.off('FE-receive-test');
            socket.off('FE-user-joined');
            socket.off('FE-raise-hand');
            socket.off('FE-stop-test');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendMessage = (m) => {
        if (documentClass?._id && m.trim().length > 0) {
            const msg = {
                author: isAuth(),
                text: m,
                timestamp: +new Date(),
                type: "text"
            };
            socket.emit('BE-send-message', {roomId: documentClass._id, msg});
            return true;
        }
        return false;
    };

    return ( isAuth().role < 2?
        <div className={"cor_aside" + (isVisible ? "" : " hidden")}>
            {!isVisible && <div className="cor_aside__title">
                <div className="cor_aside__toggle icon_toggler" onClick={() => toggleAside(true)}/>
            </div>}
            <div className={isVisible?"cor_aside__title":"cor_aside__box horizontal"}>
                {isVisible && <div className={"cor_aside__toggle icon_toggler active"} onClick={() => toggleAside(false)}/>}
                <div className={isVisible?"cor_aside__title__content":""}>
                    <ConnectSettings aside={isVisible} room={documentClass}/>
                </div>
            </div>
            {!isVisible && <div className="cor_aside__box cor_aside__box_grow hidden split1" onClick={() =>{props.toggleChat(true);toggleAside(true)}}>
                <Icon name="messages"/>
            </div>}
            {!isVisible && <div className="cor_aside__box cor_aside__box_grow hidden split2" onClick={() => {props.toggleChat(false);toggleAside(true);}}>
                <Icon name="bell"/>
            </div>}
            {isVisible && <div className={"cor_aside__box cor_aside__box_grow" + (isTestInProgress ? " invisible" : "")}>
                {props.showChat ?
                        <ClassChatList messages={msg} onSendMessage={sendMessage}/> :
                        <ClassEventList messages={ntf}/>
                }
            </div>}
            <div className={"cor_aside__box" + (isVisible ? "" : " hidden")} onClick={()=>{if (!isVisible) toggleAside(true);}}>
                <ClassVoice aside={isVisible}/>
            </div>

            {showStudentTest &&
            <ModalRunStudentTestDlg
                showModal={showStudentTest}
                setShowModal={setShowStudentTest}
                testType={runStudentTest.testType}
                testId={runStudentTest.labWorkId}
                testRunId={runStudentTest.testRunId}
                history={history}
                setTakePartInTest={setTakePartInTest}
            />
            }
            {showFinishTest &&
            <ModalFinishTestByTeacherDlg
                showFinishTest={showFinishTest}
                setShowFinishTest={setShowFinishTest}
                testType={runStudentTest.testType}
                history={history}
            />
            }
        </div> : <></>
    )
};
export default Aside;
