import cookie from "js-cookie";
import {apiSetToken} from "../api/api";
import peer from "../peer";
import {FEATURES} from "../api/config";

const setToken = (value) => {
  apiSetToken(value);
  cookie.set('cor', value, {expires: 7});
};

const getToken = ()=> cookie.get('cor');
const removeToken = () => cookie.remove('cor');

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const authenticate = (response, next) => {
  if (!response.token) {
    throw Object({response: {data: {errors: "Ошибка сервера"}}});
  }
  setToken(response.token);
  setLocalStorage("user", response.user);
  setLocalStorage("org", response.user.role < 2 ? response.user.owner : response.user);
  let selected = JSON.parse(localStorage.getItem("class"));
  if (selected) {
    selected = response.classes.filter(c => c._id === selected._id)[0];
  }

  if (selected) {
    setLocalStorage("class", selected);
  } else {
    if (response.classes[0])
      setLocalStorage("class", response.classes[0]);
    else
      removeLocalStorage("class");
  }

  next();
};

export const isAuth = () => {
  if (!getToken()) return false
  const auth = localStorage.getItem("user");
  return auth ? JSON.parse(auth) : false;
};

export const hasFeature = (org, feature) => {
  return !FEATURES[feature].name || (org.features?.[feature] && new Date(org.features[feature]) > new Date());
};

export const signout = async (next) => {
  for (const [, value] of peer._connections) if (value.length) await value[0].close();
  removeToken();
  removeLocalStorage("user");
  removeLocalStorage("org");
  next();
};

export const profilePicture = picture => picture ? process.env.REACT_APP_API_URL + '/files/profile/' + picture
  : "/person.svg";
export const fullName = (user, def = 'Удален') =>
  user ? (user.name + " " + ((user.role === 0 ? user.name2 : user.name3) ?? "")) : def;

export const generatePassword = () => window.crypto.getRandomValues(new Uint32Array(4)).reduce(
  (p, c, i) =>
    (!i ? p : p.toString(36)) + (i % 2 ? c.toString(36).toUpperCase() : c.toString(36))
).split('').sort(() => 128 - window.crypto.getRandomValues(new Uint8Array(1))[0]).slice(0,8).join('');