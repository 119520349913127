import React from "react";
import Footer from '../Footer';
import ReviewActiveOnlineTest from '../../ReviewTest/ReviewActiveOnlineTest';
import ReviewActiveLabWork from '../../ReviewTest/ReviewActiveLabWork';
import ReviewActiveVrLabWork from '../../ReviewTest/ReviewActiveVrLabWork';
import ViewAllLabWorkRuns from '../../ReviewTest/ViewAllLabWorkRuns';
import ViewAllOnlineTestRuns from '../../ReviewTest/ViewAllOnlineTestRuns';
import ViewSelectedLabWorkRun from '../../ReviewTest/ViewSelectedLabWorkRun';
import ViewSelectedVrLabWorkRun from '../../ReviewTest/ViewSelectedVrLabWorkRun';
import ViewSelectedOnlineTestRun from '../../ReviewTest/ViewSelectedOnlineTestRun';
import {ContentWrap} from "../ContentParts";
import {useSelector} from "react-redux";
import {storageOrg} from "../../../redux/slices/storage";
import {TEST_OPTION, isActiveTestView} from "../../ui/utils/ServerInfo";
import {hasFeature} from "../../../helpers/auth";
import DashboardLicense from "./DashboardLicense";
import "./Dashboard.scss";

export const DashboardReviewTest = (props) => {
  const documentOrg = useSelector(storageOrg);
  const opt = props.match.params.opt;
  const showActiveTestView = isActiveTestView(opt);
  const selectedTestRunId = !!props.match.params.id ? props.match.params.id : undefined;

  if (!hasFeature(documentOrg, opt)) { //opt !== 'otdelay' ? opt : 'ot'
    return <DashboardLicense {...props}/>;
  }

  return (
    <ContentWrap>
      {/* Show all test results: */}
      {!showActiveTestView ? 
      <>
        {/* Show all class online test results: add  || opt === 'otdelay' ? */}
        {(opt === TEST_OPTION.OT) && (
          <>
            {!selectedTestRunId ?
              <ViewAllOnlineTestRuns {...props} opt={opt} />
              :
              <ViewSelectedOnlineTestRun {...props} selectedTestRunId={selectedTestRunId}/>
            }
          </>
        )}

        {/* Show all class lab work results: */}
        {!selectedTestRunId && (opt === TEST_OPTION.LW || opt === TEST_OPTION.VRLAB) && 
          <ViewAllLabWorkRuns {...props} />
        }

        {selectedTestRunId && (opt === TEST_OPTION.LW || opt === TEST_OPTION.VRLAB) && <>
          {opt === TEST_OPTION.LW && <ViewSelectedLabWorkRun {...props} selectedTestRunId={selectedTestRunId}/>}
          {opt === TEST_OPTION.VRLAB && <ViewSelectedVrLabWorkRun {...props} selectedVrLabInd={selectedTestRunId}/>}
        </> 
        }
      </>
      :
      <>
        {/* Show current active online test: */}
        {opt === TEST_OPTION.OT && (
          <ReviewActiveOnlineTest {...props} />
        )}

        {/* Show current active lab work: */}
        {opt === TEST_OPTION.LW &&  (
          <ReviewActiveLabWork {...props} />
        )}

        {/* Show current active vr lab work: */}
        {opt === TEST_OPTION.VRLAB &&  (
          <ReviewActiveVrLabWork {...props} />
        )}
      </>
      }

      <Footer {...props}/>
    </ContentWrap>
  ); 
}

export default DashboardReviewTest;
