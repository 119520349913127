//import {loadFiles} from "../../redux/slices/lists";
import {uploadFile, doDeleteFileByName, getUrl, getUrls} from '../ui/utils/gen_utils'; //getFiles, 
import {rlsApi} from "../../api/api";
import {isAuth} from "../../helpers/auth";
import {getDescriptionEditor} from "../ui/utils/gen_utils";

export const SCENARIO_STEP = {
	DESC: 1,
	ITEMS: 2,
	QUESTIONS: 3,
	GRAPHS: 4,
	COMPOSITION: 5,
};

export const ATTACH_TYPE = {
    EMPTY: 0,
    SCHEMA: 1, 
    IMAGE: 2,
};

export const SCHEMA_TYPE = {
    ONE: 'One', 
    BIOLOGY: 'Biology',
    CHEMISTRY: 'Chemistry',
    PHYSICS: 'Physics',
    UNIVERSAL: 'Universal',
};

export const SCHEMAS = [
    {value: SCHEMA_TYPE.ONE, label: 'Одиночный датчик'},
    {value: SCHEMA_TYPE.BIOLOGY, label: 'Мультидатчик по биологии'},
    {value: SCHEMA_TYPE.CHEMISTRY, label: 'Мультидатчик по  химии'},
    {value: SCHEMA_TYPE.PHYSICS, label: 'Мультидатчик по  физике'},
    {value: SCHEMA_TYPE.UNIVERSAL, label: 'Мультидатчик универсальный'},
];

export const scTabs = [
    {tabId: SCENARIO_STEP.DESC, tabName: 'Описание'},
    {tabId: SCENARIO_STEP.ITEMS, tabName: 'Шаги проведения'},
    {tabId: SCENARIO_STEP.QUESTIONS, tabName: 'Контрольные вопросы'},
    {tabId: SCENARIO_STEP.GRAPHS, tabName: 'Графики и таблицы'},
    {tabId: SCENARIO_STEP.COMPOSITION, tabName: 'Схема сборки'},
];

export const SCHEMA_FOLDER = process.env.PUBLIC_URL + '/Scenarios/';
export const NAME_LIMIT = 100;
export const DESC_LIMIT = 1000;

export const SC_TYPE = {
    ALL: -2,  
    TEMPLATE: 0,
    CREATED: 1,
};

export const SC_STATE = {
    ALL: {status: SC_TYPE.ALL, name: 'Все'},
    TEMPLATE: {status: SC_TYPE.TEMPLATE, name: 'Шаблоны'},
    CREATED: {status: SC_TYPE.CREATED, name: 'Созданные'},
};

export const getScenarioTabs = () => {
    return scTabs;
};

export const getTabNameById = id => {
    return getScenarioTabs().find(item => item.tabId === id).tabName;
};

export const fillScenarioData = (documentClass, scName, scDescription, scSensorId, scSensorName,
    scQuestions, scItems, scGraph, scComposition) => {

    let scenario = {
        sensorId: scSensorId,
        name: scName,
        description: scDescription,
        sensorName: scSensorName,
        questions: scQuestions.filter(item => item !== ''),
        items: scItems,
        graph: scGraph,
        composition: scComposition,
    };

    if (isAuth().role < 3) {
        scenario.room = documentClass._id;
    }

    return scenario;
};

export const getDevices = (deviceData) => {
    const _devices = [];

    for (let i = 0; i < deviceData.length; i ++) {
        const device = deviceData[i];
        const name = device.attributes.name;
        const key = device.attributes.key;
        const label = name; // + ' (' + key + ')'; 
        _devices.push({value: key, label: label});
    }
    return _devices;
};

export const getSensorCount = _sensors => {
    if (_sensors.length === 0) return 0;
    const uniqueName = _sensors[0].unicName;
    const remSensors = _sensors.filter(item => item.unicName !== uniqueName && !!item.unicName);
    if (remSensors.length === 0) return 1;
    return 2;
};
export const getSensorIdName = (devices, _sensors) => {
    if (_sensors.length === 0) return ['', ''];
    const sensor = _sensors[0];
    const fullKey = sensor.unicName;
    const id = fullKey?.split('-')[0];
    const device = devices.find(item => item.value === id);
    const data = [id, device ? device.label : ''];
    return data;
}

export const getSensorNameBySensor = (devices, sensor) => {
    const id = sensor.split(' ')[0];
    const id2 = id.split('-')[0];
    const device = devices.find(item => item.value === id2);
    console.log('id=', id, ' id2=', id2, ' found device=', device);
    return [id2, device ? device.label : ''];
}

const getDevicesWithSensors = (deviceData) => {
    const _devices = [];

    for (let i = 0; i < deviceData.length; i ++) {
        const device = deviceData[i];
        const name = device.attributes.name;
        const key = device.attributes.key;
        const label = 'Устройство: ' + name + ' (' + key + ')'; 
        const quantity = device.attributes.quantity;
        const unit = device.attributes.unit;
        const id = quantity + '_' + unit;

        if (device.children.length === 0) {
            //_devices.push({id: id, quantity: quantity, unit: unit}); //, value: key, label: label
            _devices.push(id);
        } else {
            for (let k = 0; k < device.children.length; k ++) {
                const bulk = device.children[k];
                if (bulk.name === 'modes') {
                    //_devices.push({id: id, quantity: quantity, unit: unit}); //, value: key, label: label
                    _devices.push(id);
                } else {
                    for (let m = 0; m < bulk.children.length; m ++) {
                        //const sensor = bulk.children[m];
                        //const chLabel = label + '. Датчик: ' + sensor.attributes.name;
                        //const chKey = key + ' ch' + m;
                        const quantity = device.attributes.quantity;
                        const unit = device.attributes.unit;
                        const id = quantity + '_' + unit;
                        //_devices.push({id: id, quantity: quantity, unit: unit}); //, value: chKey, label: chLabel
                        _devices.push(id);
                    }
                }
            }
        }
    }
    const ud = new Set();
    for (let i = 0; i < _devices.length; i ++)
        ud.add(_devices[i]);

    const uniqueDevices =  [];
    for (let value of ud) {
        let [quantity, unit] = value.split('_');
        quantity = quantity.length > 0 ?  quantity[0].toUpperCase() + quantity.slice(1) : '';
        uniqueDevices.push({quantity: quantity, unit: unit});
    }
    return uniqueDevices;
};

export const RL_SCENARIO_PREFIX = 'RlScenarios%2F';
export const RL_SCENARIO_SLASH = 'RlScenarios/';

export const uploadRlsFile = async (file, setLoadedFileName) => {
    await uploadFile(RL_SCENARIO_PREFIX, file, setLoadedFileName);
}
export const deleteRlsFileByName = async (dispatch, fileName) => {
    doDeleteFileByName(RL_SCENARIO_PREFIX, dispatch, fileName);
}; 
// export const loadRlsFiles = dispatch => {
//     dispatch(loadFiles(RL_SCENARIO_PREFIX));
// }
// export const getFileByName = (_files, fileName) => {
//     const fArr = getFiles(_files, [{name: fileName}]);
//     if (fArr.length === 0) return '';
//     return fArr[0].Url;
// };
// const getFileDataByName = (_files, fileName) => {
//     const fArr = getFiles(_files, [{name: fileName}]);
//     if (fArr.length === 0) return '';
//     return fArr[0];
// };

export const getScUrlByName = async (fileName, setUrl) => {
    const url = await getRlsUrl(fileName);
    setUrl(url);
};

export const getScUrls = async (items, setUrls) => {
    const _urls = await getRlsUrls(items);
    setUrls(_urls);
};

export const getRlsUrl = async (name) => {
    return await getUrl(RL_SCENARIO_PREFIX, name); 
};

export const getRlsUrls = async (list) => {
    return await getUrls(RL_SCENARIO_PREFIX, list); 
};

export const loadScenarios = async (sensor, room, scenarios, setScenarioList) => {
    const data = await rlsApi.getScenarios(room);
    const sensorId = sensor.unicName.split('-')[0];
    const list = [];

    for (let i = 0; i < data.length; i ++) {
        const item = data[i];
        if (item.sensorId === sensorId) {
            list.push({scenario: item.name, id: item._id});
        }
    }
    
    let changed = scenarios.length !== list.length;
    if (!changed) {
        changed = list.filter((item, ind) => item.id !== scenarios[ind].id).length > 0;
    }
    if (changed) {
        setScenarioList(list);
    }
};

export const loadScenario = async (scenarioId, setScenario) => {
    const getObjWithUrl = async (isTemplate, obj) => {
        if (obj.attachmentName) {
            let url;
            if (!isTemplate) {
                url = await getRlsUrl(obj.attachmentName);
            } else {
                url = JSON.parse(obj.attachmentName).content; //attachmentName contains obj {name:.., content:..}
            }
            return {...obj, fileUrl: url};
        }
        return obj;
    };

    const _scenario = await rlsApi.getScenarioById(scenarioId);
    const isTemplate = !_scenario.owner;

    const list = [];
    for (let i = 0; i < _scenario.items.length; i ++) {
        const elem = await getObjWithUrl(isTemplate, _scenario.items[i]);
        list.push(elem);
    }
    _scenario.items = list;

    if (_scenario.composition.attachmentType === ATTACH_TYPE.IMAGE) {
        _scenario.composition = await getObjWithUrl(isTemplate, _scenario.composition);
    }
    setScenario(_scenario);
};

export const getDescription = (counter, maxCharCount, itemTitle, description, handleDescription, isDisabledTemplate, index) => {
    return (
        <div className="cor-net__row">
            <div className="cor-net__col col-1">
                <div className="cor-net__label flex jc-between">
                    <span>{itemTitle}</span>    
                </div>
                {getDescriptionEditor(description, handleDescription, isDisabledTemplate, index, maxCharCount)}
            </div>
        </div>
    );
};
  