import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Modal, Button } from 'rlabui';
import {SCALEPOS, SAVE_DATA_TYPE, GraphicOpts, getSvgWithHeightWidth, getCurve,  getB, getMinMax, 
    getBackground, getStrokes, getScaleTitle, getScaleData, getScaleValues, getAxis, getGrid, 
    printDiv, getScreenAreaPictureById, getModeColorOpts, canStartAction, 
    completeAction } from "./cm_utils";
import { saveExcel } from './excel_utils';
import { ActionFile, Icon } from '../ui';
import {useDispatch} from "react-redux";
import {reloadOption} from "../../redux/slices/storage";
import SaveXlsFileConfirmDialog from "../ui/ModalDialogs/SaveXlsFileConfirmDialog";
import "./CalcModel.scss";
import ss from './CalcModel_CoilMagneticField_Graphs.module.scss';

const CALC_ARRAY_SIZE = 100;

const CoilMagneticFieldGraphs = (props) => {
    const { showModal, setShowModal, saveProfile, graphicOptions, isLightMode, isInnerCM } = props;
    const [bzhSvg, setBzhSvg] = useState(undefined);
    const [brvSvg, setBrvSvg] = useState(undefined);
    const [svg_WIDTH, setSvgWidth] = useState(300);
    const [svg_HEIGHT, setSvgHeight] = useState(svg_WIDTH);
    const [showConfirmSaveXlsDlg, setShowConfirmSaveXlsDlg] = useState(false);
	const [makePrint, setMakePrint] = useState(false);
	const [makeXls, setMakeXls] = useState(false);
	const [isLightModeChanged, setIsLightModeChanged] = useState(false);
	const [saveDataType, setSaveDataType] = useState(SAVE_DATA_TYPE.XLS);

	const dispatch = useDispatch();
    const colRef = useRef();

    useLayoutEffect(() => {
        if (colRef?.current) {
            setSvgWidth(colRef.current.offsetWidth);
            setSvgHeight(colRef.current.offsetWidth - 60);
        }
    },[colRef, showModal]);

    const getHorizExperimentArray = (graphOpts) => {
        const arrHoriz = [];
        const arrBzh = [];
        const step = (graphOpts.svgXRange[1] - graphOpts.svgXRange[0]) / CALC_ARRAY_SIZE;

        for (let i = 0; i < CALC_ARRAY_SIZE + 1; i ++) {  // note: +1 to include the last point
            const pos = graphOpts.svgXRange[0] + i * step;
            const Bx = getB(pos, graphOpts.yPos, graphOpts.coilLength, graphOpts.coilRadius, graphOpts.windingNumber, graphOpts.currentPower)[0];
            arrHoriz.push(pos);
            arrBzh.push(Bx);
        }
        return [arrHoriz, arrBzh];
    };
    const getVertExperimentArray = (graphOpts) => {
        const arrVert = [];
        const arrBrv = [];
        const step = (graphOpts.svgYRange[1] - graphOpts.svgYRange[0]) / CALC_ARRAY_SIZE;

        for (let i = 0; i < CALC_ARRAY_SIZE + 1; i ++) { // note: +1 to include the last point
            const pos = graphOpts.svgYRange[0] + i * step;
            const By = getB(graphOpts.xPos, pos, graphOpts.coilLength, graphOpts.coilRadius, graphOpts.windingNumber, graphOpts.currentPower)[1];
            arrVert.push(pos);
            arrBrv.push(By);
        }

        return [arrVert, arrBrv];
    };

    const getSvgCurveData = (scaleArr, valueArr, curveColor) => {
        const gapTop = 10;
        const gapHoriz = 52
        const gapBottom = 40;
        const gapRight = 10;        
		const width = svg_WIDTH-gapHoriz - gapRight;
		const height = svg_HEIGHT-gapTop-gapBottom;
        const scaleLarge = 6;
        const scaleSmall = 3;
        const timeStrokeNUM = 5;
        const vertStrokeNUM = 4;
        
        const svgData = [];

		const [min, max] = getMinMax(scaleArr);
		let [vMin, vMax] = getMinMax(valueArr);
        if (vMin === 0 && vMax === 0) vMax = 1;

		//1 - get full rectangle with backround color:
		svgData.push(getBackground(svg_WIDTH, svg_HEIGHT, GraphicOpts.backgroundOpacity, getModeColorOpts(isLightMode).calcModelBknd));

		//4 - axis titles:
		const borderTitleLEFT = gapHoriz * 0.14;
        const bottomAxisTitle = getScaleTitle(SCALEPOS.horiz, svg_WIDTH * 0.45, svg_HEIGHT-8, 
            "Координата, мм", "#7C8195", "bottomkey");
        const leftAxisTitle = getScaleTitle(SCALEPOS.left, borderTitleLEFT + 2, svg_HEIGHT * 0.6, 
            "Величина поля, нТл", "#7C8195", "leftkey");

        svgData.push(leftAxisTitle);
        svgData.push(bottomAxisTitle);

		//5 - scales:
		const horizScaleArr = getScaleData(SCALEPOS.horiz, SCALEPOS.left, timeStrokeNUM, min, max, gapHoriz, width, svg_HEIGHT - gapBottom, scaleLarge, scaleSmall, 0);
		const horizStrokes = getStrokes(horizScaleArr, GraphicOpts.timeColor, 0, 0.1, "hkey");
		const horizScaleValues = getScaleValues(horizScaleArr, SCALEPOS.horiz, SCALEPOS.left, GraphicOpts.timeColor, "horiz1");

        const xAxis = getAxis(gapHoriz, (svg_HEIGHT-gapBottom)/2, width, 1, GraphicOpts.scaleLineColor, GraphicOpts.scaleLineWidth, GraphicOpts.scaleLineOpacity);

        const vScaleArr = getScaleData(SCALEPOS.vert, SCALEPOS.left, vertStrokeNUM, vMin, vMax, gapTop, height, gapHoriz, scaleLarge, scaleSmall, 0);
		const vStrokes = getStrokes(vScaleArr, GraphicOpts.timeColor, 0, 0.1, "vskey");
		const vScaleValues = getScaleValues(vScaleArr, SCALEPOS.vert, SCALEPOS.left, GraphicOpts.timeColor, "vert1");
        const yAxis = getAxis(gapHoriz + width/2, gapTop, 1, height, GraphicOpts.scaleLineColor, GraphicOpts.scaleLineWidth, GraphicOpts.scaleLineOpacity);

        svgData.push(horizStrokes);
        svgData.push(horizScaleValues);
        svgData.push(vStrokes);
        svgData.push(vScaleValues);
        svgData.push(xAxis);
		svgData.push(yAxis);
        
		//6 - grid:
		const hGrid = getGrid(horizScaleArr, SCALEPOS.horiz, gapHoriz, gapTop, width, height, getModeColorOpts(isLightMode).gridColor, GraphicOpts.gridWidth, GraphicOpts.gridOpacity);
		const vGrid = getGrid(vScaleArr, SCALEPOS.vert, gapHoriz, gapTop, width, height, getModeColorOpts(isLightMode).gridColor, GraphicOpts.gridWidth, GraphicOpts.gridOpacity);

		svgData.push(hGrid);
		svgData.push(vGrid);

		//8 - get border: removed - 
		//svgData.push(getBorder(svg_WIDTH, svg_HEIGHT, "red"));

        //get curve:
		const hList = scaleArr.map(item => gapHoriz + width * (Number(item) - Number(min)) / (max - min));
		const vList = valueArr.map(item => gapTop + height * (Number(vMax) - Number(item)) / (vMax - vMin));
		const vLine = getCurve(hList, vList, curveColor, "2", "curve02");
		svgData.push(vLine);

        return svgData;
    };

    const getBrvSvg = () => {
        const [vertList, brVertList] = getVertExperimentArray(graphicOptions);
        const svgBvrData = getSvgCurveData(vertList, brVertList, "#9647FB");
        return getSvg(svgBvrData);
    };

    const getBzhSvg = () => {
        const [horizList, bzHorizList] = getHorizExperimentArray(graphicOptions);
        const svgBzhData = getSvgCurveData(horizList, bzHorizList, "#00DA98");
        return getSvg(svgBzhData);
    };

    const getSvg = (obj) => {
        return getSvgWithHeightWidth(svg_HEIGHT, svg_WIDTH, obj);
    };

    const saveXLSX = (workBookName, description) => {
        const grOpts = graphicOptions;

		const tableColumns = [
			{ header: 'Описание эксперимента', key: 'description' },
			{ header: 'Радиус, мм', key: 'coilRadius' }, 
			{ header: 'Длина, мм', key: 'coilLength' },
			{ header: 'Обмотка, витки/мм', key: 'windingNumber' },
			{ header: 'Ток, мА', key: 'currentPower' },
			{ header: 'Координата X, мм', key: 'xPos' },
			{ header: 'Координата Y, мм', key: 'yPos' },
		];

		const tableData = [];
		const row = {
            description: description,
			coilRadius: grOpts.coilRadius,
			coilLength: grOpts.coilLength,
			windingNumber: grOpts.windingNumber,
			currentPower : grOpts.currentPower,
			xPos: grOpts.xPos,
			yPos: grOpts.yPos,
		};
    	tableData.push(row);

		const graphicData = [{
				title: '',
				image: bzhSvg,
				rowNum: 18
			},
            {
				title: '',
				image: brvSvg,
				rowNum: 18
			}
		];

		const tableSheet = {
			workSheetName: 'Таблица',
			columns: tableColumns,
			tableData: tableData,
			graphicData: graphicData
		};

		saveExcel(workBookName, [tableSheet], []);
        setBzhSvg(undefined);
        setBrvSvg(undefined);
    };

    const handleCloseDlg = () => {
        setShowModal(false);
    };  

    const handleCloseConfirmSaveXlsDlg = () => {
        setBzhSvg(undefined);
        setBrvSvg(undefined);
        setShowConfirmSaveXlsDlg(false);
    };

    const handleProfile = () => {
        setSaveDataType(SAVE_DATA_TYPE.PROFILE);
        setShowConfirmSaveXlsDlg(true);
    };

    const handleSaveGraphs = () => {
        setSaveDataType(SAVE_DATA_TYPE.XLS);
        //step 1 of 3: change light mode if needed and request to save svg
        canStartAction(isLightMode, dispatch, reloadOption, setIsLightModeChanged, setMakeXls);
    };

	useEffect(() => {
		if (!makeXls) return;
		//step 2 of 3: save svg and request to calc expResults
		getScreenAreaPictureById("MainSvg1", img => setBzhSvg(img));
		getScreenAreaPictureById("MainSvg2", img => setBrvSvg(img));
		setTimeout(() => completeAction(dispatch, setMakeXls, isLightModeChanged, setIsLightModeChanged, reloadOption), 100);
	}, [dispatch, isLightModeChanged, makeXls]);

    useEffect(() => {
		//step 3 of 3: run the export dlg
        if (!!bzhSvg && !!brvSvg) {
            setShowConfirmSaveXlsDlg(true);
        }
    }, [bzhSvg, brvSvg]);

    const saveExport = (name, description) => {
        if (saveDataType === SAVE_DATA_TYPE.PROFILE) {
            saveProfile(name, description);
        } else {
            saveXLSX(name, description);
        }
    };

	const handlePrint = () => {
        canStartAction(isLightMode, dispatch, reloadOption, setIsLightModeChanged, setMakePrint);
    };

    useEffect(() => {
		if (!makePrint) return;

        const modelParams = [
			['Радиус соленоида: ', graphicOptions.coilRadius + ' нм'],
			['Длина соленоида: ', graphicOptions.coilLength + ' мм'],
			['Плотность обмотки: ', graphicOptions.windingNumber + ' витков/мм'],
			['Сила тока: ', graphicOptions.currentPower + ' mA'],
			['Координата X: ', graphicOptions.xPos + ' мм'],
			['Координата Y: ', graphicOptions.yPos + ' мм'],
		];        
		printDiv(["MainSvg1", "MainSvg2"], 'Магнитная катушка', modelParams, ["./CalcModel.scss"]);
		setTimeout(() => completeAction(dispatch, setMakePrint, isLightModeChanged, setIsLightModeChanged, reloadOption), 100);
	}, [dispatch, graphicOptions.coilLength, graphicOptions.coilRadius, graphicOptions.currentPower, 
        graphicOptions.windingNumber, graphicOptions.xPos, graphicOptions.yPos, isLightModeChanged, 
        makePrint]);

	const getButtons = () => {
		return (
            <>
                <Button onClick={handleCloseDlg}>
                    Закрыть
                </Button>
                <ActionFile 
                    profile={handleProfile}
                    experiment={handleSaveGraphs}
                    isInnerCM={isInnerCM}
                    print={handlePrint}
                />
            </>
		
		);
	};

    if (!showModal) {
        return false;
    }

    return (
        <>
            <Modal className={ss.root} visible={showModal} size="xl">
                <Modal.Head modalClose={handleCloseDlg} title='Графики' />
                
                <Modal.Body>
                    <div className="calcModel__modalLabels">
                        <div className="calcModel__modalLabels__item">
                            <span className="calcModel__modalLabels_circle calcModel__modalLabels_circleBZH">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <span className="calcModel__circleText">Проекция B на горизонтальную ось</span>
                        </div>
                        <div className="calcModel__modalLabels__item">
                                <span className="calcModel__modalLabels_circle calcModel__modalLabels_circleBRV">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <span className="calcModel__circleText">Проекция B на вертикальную ось</span>
                        </div>
                    </div>

                    <div className="calcModel__stretch">
                        <div ref={colRef} className="calcModel__flexColumn calcModel__flexColumnGraphs">
                            <div className="calcModel__graphTitle">Горизонтальная ось</div>
                            <div id="MainSvg1" className="calcModel__graph" key="mainSvg01" 
                                style={{width: svg_WIDTH, height: svg_HEIGHT}}>
                                {getBzhSvg()}
                            </div> 
                            <div></div>
                        </div>
                        <div className="calcModel__flexColumn calcModel__flexColumnGraphs">
                            <div className="calcModel__graphTitle">Вертикальная ось</div>
                            <div id="MainSvg2" className="calcModel__graph" key="mainSvg02" 
                                style={{width: svg_WIDTH, height: svg_HEIGHT}}>
                                {getBrvSvg()}
                            </div>
                            <div></div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className={ss.footer}>
                    {getButtons()}
                </Modal.Footer>

            </Modal>

            {showConfirmSaveXlsDlg &&
                <SaveXlsFileConfirmDialog
                    showConfirmSaveXlsDlg={showConfirmSaveXlsDlg}
                    setShowConfirmSaveXlsDlg={handleCloseConfirmSaveXlsDlg}
                    saveExport={saveExport}
                    fileName={'Магнитная Катушка'}
                />
            }
        </>
    )
};

export default CoilMagneticFieldGraphs;
