import React, { useState, useEffect, useRef } from "react";
import { QUESTION_TYPES, STATUS, MAX_ANSWER_LENGTH, getMaxOfArray, getOptionFieldList, getComboboxes,
    getNonVerbalOptionFieldList, uploadOTFile, deleteOTFileByName, getAnswerUrls} from "./ot_utils";
import ImageDlg from "../ui/ModalDialogs/ImageDlg";
import {getDropdown} from '../ui/utils/gen_utils';
import {useDispatch} from "react-redux";
import { isAuth } from "../../helpers/auth";
import "./OnlineTestCreate.scss";
import "../ReviewTest/LabWorkSelect.scss";

const ANSWER_NUM = 2;
//перетаскивание элементов или рисунков

const Constructor1RestoreSequence = ({isNewQuestion, currQuestionType, questionList, currQuestionNum,
        isEditable, isTemplate, setDataError, 
        setCurrAnswerOptions, setCurrCorrectOptions, setChangedData, }) => {
    const [currQuestion, setCurrQuestion] = useState(undefined);
    const [answerList, setAnswerList] = useState([]);
    const [comboboxesList, setComboboxesList] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [showPictureDlg, setShowPictureDlg] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [loadedFileName, setLoadedFileName] = useState('');
    const [status, setStatus] = useState(0);
    const [urls, setUrls] = useState([]);

	const startedRef = useRef(false);
    const dispatch = useDispatch();

    const isCorrectWordSequence = _currQuestionType => _currQuestionType === QUESTION_TYPES.CORRECT_WORD_SEQUENCE;
    const isCorrectImageSequence = _currQuestionType => _currQuestionType === QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE;

    useEffect(() => {
        startedRef.current = false;
        const question = isNewQuestion ? undefined : {...questionList[currQuestionNum]};
        setCurrQuestion(question);
        setStatus(STATUS.INIT);
    }, [questionList, currQuestionNum, isNewQuestion]);

    // === 1 of 3 useEffects
    useEffect(() => {
        if (status !== STATUS.INIT) return;
        startedRef.current = true;

        if (isNewQuestion) {
            const answers = [];
            const orders = [];
            if (isCorrectWordSequence(currQuestionType)) {
                for (let i = 0; i < ANSWER_NUM; i ++) {
                    answers.push({name: '', id: i, order: -1}); 
                    orders.push(-1);
                }
            }

            setOrderList(orders);
            setAnswerList(answers);
            setStatus(STATUS.USE);
        } else if (currQuestion) {
            let answerOptions = currQuestion.answerOptions;
            let _orderOptions = currQuestion.correctOptions;

            if (answerOptions.length === 0) {
                if (_orderOptions === '0')
                    _orderOptions = '-1';
                for (let i = 0; i < ANSWER_NUM - 1; i++) {
                    answerOptions.push('');
                    _orderOptions += '|-1';
                }
            }

            const answers = answerOptions.map((item, ind) => ({name: item, id: ind}));
            setAnswerList(answers);
            const orderOptions = _orderOptions.split('|');
            setOrderList(orderOptions);
            setStatus(STATUS.USE);
        }
    }, [currQuestion, setDataError, currQuestionType, isNewQuestion, status]);

    // === 2 of 3 useEffects:
    useEffect(() => {
        if (status !== STATUS.USE || !startedRef.current) return;
        const list = getComboboxes(orderList, setOrderList);
        setComboboxesList(list);
    }, [orderList, status]);

    // === 3 of 3 useEffects:
    useEffect(() => {
        if (status !== STATUS.USE || !startedRef.current) return;
        const errorMsg = checkError(answerList, orderList);
        setDataError(errorMsg);
        if (errorMsg) return;

        //console.log('1ue3/3')
        let changed = false;
        const aOpts = answerList.map(item => item.name);
        if (!currQuestion || aOpts.length !== currQuestion.answerOptions.length || 
            aOpts.filter((item, ind) => item !== currQuestion.answerOptions[ind]).length > 0) {
                setCurrAnswerOptions(aOpts);
                changed = true;
        }

        const ol = orderList.join('|');
        if (!currQuestion || ol !== currQuestion.correctOptions) {
            setCurrCorrectOptions(ol);
            changed = true;
        }
        if (changed) {
            setChangedData(true);
        }
    }, [answerList, currQuestionType, currQuestion, setCurrAnswerOptions, status,
        setCurrCorrectOptions, setDataError, orderList, setChangedData]);

    useEffect(() => {
        getAnswerUrls(isTemplate, answerList, setUrls);
    }, [answerList, isTemplate]);
    
    const checkError = (_answerList, _orderList) => {
        const hasEmptyAnswers = _answerList.filter(item => !item.name.trim()).length > 0;
        const isNotEnoughAnswers = _answerList.length < ANSWER_NUM; 
        const isNotEnoughCorrectOptions = _orderList.filter(item => Number(item) === -1).length > 0;

        if (hasEmptyAnswers) {
            return 'Заполните все варианты ответов.';
        } else if (isNotEnoughAnswers) {
            return 'Количество предлагаемых ответов должно быть не меньше ' + ANSWER_NUM + '.';
        } else if (isNotEnoughCorrectOptions) {
            return 'Определите порядок ответов.';
        }
        return '';
    };

    const handleChangeAnswer = (value, index) => {
        if (value.length > MAX_ANSWER_LENGTH) return;
        const answers = answerList.map((item, ind) => ind === index ? 
            {name: value, id: index, order: item.order} : item);
        setAnswerList(answers);
    };

    const addAnswerOption = (name, _answerList, _setAnswerList, _orderList) => {
        const answers = [..._answerList];
        const maxId = _answerList.length > 0 ? getMaxOfArray(_answerList.map(item => item.id)) : 0;
        answers.push({name: name, id: maxId + 1});
        _setAnswerList(answers);
        const orders = [..._orderList];
        orders.push(-1);
        setOrderList(orders);
    };
        
    const handleAddWordAnswerOption = () => {
        addAnswerOption('', answerList, setAnswerList, orderList);
    };

    //step 1 of 2: get file
    const handleAddImgAnswerOption = (event) => {
        //перетаскивание элементов или рисунков
        if (!isTemplate) {
            uploadOTFile(event.target.files[0], setLoadedFileName);
        } else {
            setLoadedFileName(event.target.files[0]); //keep the file name and content
        }
        event.target.value = '';
    };

    //step 2 of 2: save file name (SA) or file (teacher)
    useEffect(() => {
        if (!loadedFileName) return;
        if (!isTemplate) {
            addAnswerOption(loadedFileName, answerList, setAnswerList, orderList);
            setLoadedFileName('');
        } else {
            const reader = new FileReader();
            reader.onload = (event) => {
                //DB will keep the file data: name and content
                const fileOpts = {name: loadedFileName.name, content: event.target.result};
                setLoadedFileName('');
                const fileData = JSON.stringify(fileOpts);
                addAnswerOption(fileData, answerList, setAnswerList, orderList);
            };
            reader.readAsDataURL(loadedFileName); //if isTemplate the param contains the file (not just its name)
        }
    }, [answerList, loadedFileName, orderList, isTemplate]);
    
    const doDeleteAnswerOption = (ind) => {
        const answers = answerList.filter((item, index) => index !== ind);
        setAnswerList(answers);
        const orders = [...orderList];
        orders.pop();
        setOrderList(orders);
    };

    const handleDeleteWordAnswerOption = (ind) => {
        doDeleteAnswerOption(ind);
    };

    const handleDeleteImageAnswerOption = async (_answerList, _setAnswerList, _dispatch, ind) => {
        if (isAuth().role < 3) {
            const fileName = _answerList[ind].name;
            deleteOTFileByName(_dispatch, fileName);
        }
        doDeleteAnswerOption(ind);
    };

	const handleShowPicture = (_file) => {
		setFileUrl({Url: _file});
		setShowPictureDlg(true);
	};

    const getCorrectAnswers = () => {
        const getViewOptionByIndex = (cbList, boxInd) => {
            const cb = cbList[1];
            return <div key={'vo'+boxInd}>{cb.label}</div>;
        };

        const getEditBoxByIndex = (boxItemList, boxInd, order) => {
            const list = boxItemList.map(item => ({value: item.value, label: item.label}));
            return getDropdown(list, order, v => handleOptionBox(v, boxInd), 
                "", '', 'opts01', 'top');
        };
    
        const handleOptionBox = (value, boxInd) => {
            const orders = [];
            for (let i = 0; i < orderList.length; i ++) {
                const val = i < Number(boxInd) ? orderList[i] : i === Number(boxInd) ? value : -1;
                orders.push(val);
            }
            setOrderList(orders);
        };

        return (
            <div className="cor-net__section border">
                <div className="cor-net__title">Правильная последовательность</div>
        		<div className="cor-net__row">
                    {comboboxesList.map((boxItemList, boxInd) => (
                        <div key={'sb'+boxInd} className="cor-net__col col-10"> 
                            <div key={'nm'+boxInd} className="cor-net__label">N{boxInd+1}</div>
                            <div key={'rt'+boxInd}>
                                {isEditable ? 
                                    getEditBoxByIndex(boxItemList, boxInd, orderList[boxInd])
                                : 
                                    getViewOptionByIndex(boxItemList, boxInd)
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <>
            {/* Top part: options */}
            {isCorrectWordSequence(currQuestionType) && 
                getOptionFieldList(answerList, handleChangeAnswer, handleDeleteWordAnswerOption, 
                    handleAddWordAnswerOption, isEditable)
            }

            {isCorrectImageSequence(currQuestionType) && 
                getNonVerbalOptionFieldList(isTemplate, urls, 
                    !currQuestion ? 0 : ANSWER_NUM - answerList.length, 
                    answerList, setAnswerList, ANSWER_NUM, 
                    dispatch, "image/*", 
                    handleAddImgAnswerOption, handleDeleteImageAnswerOption, handleShowPicture)
            }

            {/* Bottom part: correct answers */}
            {getCorrectAnswers()}

            {showPictureDlg && 
			<ImageDlg
				showModal={showPictureDlg}
				setShowModal={setShowPictureDlg}
				file={fileUrl}
			/>
            }
        </>
    )
}

export default Constructor1RestoreSequence;
