import Icon from '../Icon';
import { Dropdown } from 'rlabui';
import {toast} from "react-toastify";
import './SectionTabs.scss';
import { saveExcel } from './excel_utils';

export const IMAGE_SHIFT_X = 50;
export const IMAGE_SHIFT_Y = 30;
export const SELECTED_FRAME_ID = 'selectedFrameId';

export const SCREEN_MODE = {
    DO_LOAD_VIDEO: 1,
    //DO_CHECK_DB: 2,
    SHOW_VIDEO: 3,
    SHOW_FRAME: 4,
};

export const VIDEO_SOURCE = {
    UNDEFINED: 0,
    VIDEOFILE: 1,
    CAMERA: 2,
    LIBRARY: 3,
    DB: 4,
};

export const STREAM_INPUT = {
    VIDEO: 1,
    CAMERA: 2,
};

export const MEASURE_TYPE = {
    RECT: 1,
    LINE: 2,
    CIRCLE: 3,
};

export const DRAG_ACTION = {
    TOP_LEFT: 1,
    TOP_RIGHT: 2,
    BOTTOM_LEFT: 3,
    BOTTOM_RIGHT: 4,
    INSIDE: 5,
};

export const DELETE_ALL_MEASURES_MARKER = 1000;

export const ICON_ACTION = {
    EDIT: 1,
    DELETE: 2,
};

const CIRCLE_RADIUS = 5;
const MIN_DISTANCE = 5;
const COLOR_STANDARD = '#9dfe00';
const COLOR_SELECTED = COLOR_STANDARD;
const COLOR_TEXT = 'black';
const FIGURE_BKND_COLOR = 'rgba(137, 239, 0, 0.15)';
const FIGURE_LEGEND_COLOR = COLOR_STANDARD;

export const saveNewFrame = (videoInd, video, addNewFrame, measures = []) => {
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const frameData = canvas.toDataURL('image/img'); // Always save frame as PNG format

    const image = new Image();
    image.src = frameData;

    image.onload = () => {
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = image.width;
        tempCanvas.height = image.height;
        const tempContext = tempCanvas.getContext('2d');
        tempContext.drawImage(image, 0, 0);

        const newFrame = {
            videoInd: videoInd,
            frameTime: video.currentTime,
            frameData: frameData,
            frameExportData: frameData,
            title: '',
            measureList: measures,
        };

        addNewFrame(newFrame);
    };
};

//save frames based on DB info:
export const addSavedFrame = (frame, videoRef, _videoSize, currVideoInd, currScale, addNewFrame) => {
    videoRef.current.currentTime = frame.frameTime;
    const measureList = [];

    for (let j = 0; j < frame.measures.length; j++) {
        const measure = frame.measures[j];
        const rectangle = {
            x: measure.x,
            y: measure.y,
            width: measure.width,
            height: measure.height,
            comment: '',
        };
        const newMeasure = getMeasure(measure.type, rectangle, _videoSize, currScale);
        measureList.push(newMeasure);
    }

    saveNewFrame(currVideoInd, videoRef.current, addNewFrame, measureList);
};

export const doUploadNewVideo = (videoStreamInput, file, url, scale, unitId, 
        _videoList, _currVideoInd, setVideoList, setCurrVideoInd) => {
    const isVideoStream = videoStreamInput === STREAM_INPUT.VIDEO;
    const newVideo = {
        videoStreamInput: videoStreamInput,
        videoName: file.name,
        videoURL: isVideoStream ? url : file.cameraId,
        size: isVideoStream ? file.size : 0, //used for comparizon of video files
        videoScale: scale,
        videoUnitId: unitId,
        videoUnitName: getScaleUnitById(unitId).label,
        dbId: null, //measures are found, db id is known  //do we need all 3 variables???
        isMeasuresLoaded: false,
    };

    const list = [..._videoList];
    list.push(newVideo);
    setVideoList(list);
    setCurrVideoInd(list.length - 1); //_currVideoInd + 1);
};

export const findActiveFigure = (offsetX, offsetY, imageData, _selectedActionType) => {
    let action = '';
    const imageLen = imageData?.measureList?.length;

    if (!!imageLen) {
        for (let i = 0; i < imageData.measureList.length; i++) {
            const measure = imageData.measureList[i];
            const r = measure.rectangle;
            if (r) {
                if (measure.type === MEASURE_TYPE.RECT) action = findActiveRectangle(offsetX, offsetY, r); //check rectangle
                else if (measure.type === MEASURE_TYPE.LINE) action = findActiveLine(offsetX, offsetY, r); //check line
                else if (measure.type === MEASURE_TYPE.CIRCLE) action = findActiveCircle(offsetX, offsetY, r); //check circle
            }

            if (action !== '') {
                return {
                    figureInd: i,
                    figureActionType: measure.type,
                    dragAction: action,
                    offsetX: offsetX,
                    offsetY: offsetY,
                    rectX: measure.rectangle.x,
                    rectY: measure.rectangle.y,
                };
            }
        }
    }

    return { figureInd: -1, figureActionType: _selectedActionType, dragAction: '' };
};

const findActiveRectangle = (offsetX, offsetY, rectangle) => {
    const { x, y, width, height } = rectangle;

    const left = x;
    const right = x + width;
    const top = y;
    const bottom = y + height;

    const distTopLeft = Math.sqrt((offsetX - left) ** 2 + (offsetY - top) ** 2);
    const distTopRight = Math.sqrt((offsetX - right) ** 2 + (offsetY - top) ** 2);
    const distBottomLeft = Math.sqrt((offsetX - left) ** 2 + (offsetY - bottom) ** 2);
    const distBottomRight = Math.sqrt((offsetX - right) ** 2 + (offsetY - bottom) ** 2);

    const minDistance = Math.min(distTopLeft, distTopRight, distBottomLeft, distBottomRight);

    if (minDistance <= MIN_DISTANCE) {
        if (minDistance === distTopLeft) {
            return DRAG_ACTION.TOP_LEFT;
        } else if (minDistance === distTopRight) {
            return DRAG_ACTION.TOP_RIGHT;
        } else if (minDistance === distBottomLeft) {
            return DRAG_ACTION.BOTTOM_LEFT;
        } else if (minDistance === distBottomRight) {
            return DRAG_ACTION.BOTTOM_RIGHT;
        }
    }

    if (
        offsetX - Math.min(left, right) > MIN_DISTANCE &&
        Math.max(left, right) - offsetX > MIN_DISTANCE &&
        offsetY - Math.min(top, bottom) > MIN_DISTANCE &&
        Math.max(top, bottom) - offsetY > MIN_DISTANCE
    ) {
        return DRAG_ACTION.INSIDE;
    }

    return '';
};

const findActiveLine = (offsetX, offsetY, rectangle) => {
    const { x, y, width, height } = rectangle;

    const left = x;
    const right = x + width;
    const top = y;
    const bottom = y + height;

    if (
        offsetX < Math.min(left, right) - MIN_DISTANCE ||
        offsetX > Math.max(left, right) + MIN_DISTANCE ||
        offsetY < Math.min(top, bottom) - MIN_DISTANCE ||
        offsetY > Math.max(top, bottom) + MIN_DISTANCE
    )
        return '';

    const distTopLeft = Math.sqrt((offsetX - left) ** 2 + (offsetY - top) ** 2);
    const distBottomRight = Math.sqrt((offsetX - right) ** 2 + (offsetY - bottom) ** 2);

    const minDistance = Math.min(distTopLeft, distBottomRight);

    if (minDistance <= MIN_DISTANCE) {
        if (minDistance === distTopLeft) {
            return DRAG_ACTION.TOP_LEFT;
        } else if (minDistance === distBottomRight) {
            return DRAG_ACTION.BOTTOM_RIGHT;
        }
    }

    if (Math.abs(right - left) > MIN_DISTANCE) {
        //left !== right
        const k = (bottom - top) / (right - left);
        const b = top - k * left;

        const middleX = offsetX;
        const middleY = k * middleX + b;
        const distY = Math.abs(offsetY - middleY);
        if (distY <= MIN_DISTANCE) {
            return DRAG_ACTION.INSIDE;
        }
    } else {
        if (Math.abs(offsetX - left) <= MIN_DISTANCE && offsetY > Math.min(top, bottom) && offsetY < Math.max(top, bottom)) {
            return DRAG_ACTION.INSIDE;
        }
    }

    return '';
};

const findActiveCircle = (offsetX, offsetY, rect) => {
    //center of the circle: rect.x, rect.y; radius = rect.width
    const [circleCenterX, circleCenterY] = [rect.x, rect.y];
    const circleRadius = rect.width;
    const dist = Math.sqrt((offsetX - circleCenterX) ** 2 + (offsetY - circleCenterY) ** 2);

    if (Math.abs(dist - circleRadius) <= MIN_DISTANCE) return DRAG_ACTION.TOP_LEFT; // on the border of the circle

    if (Math.abs(dist) < circleRadius - MIN_DISTANCE) return DRAG_ACTION.INSIDE; //inside of the circle

    return '';
};

export const findFigureIconAction = (offsetX, offsetY, signatureListRef) => {
    const list = signatureListRef.current;
    if (!list || list.length === 0) return null;

    for (let i = 0; i < list.length; i++) {
        const elem = list[i];
        if (offsetX > elem.x && offsetX < elem.x + elem.width && 
            offsetY > elem.y && offsetY < elem.y + elem.height) 
            return elem;
    }

    return null;
};

export const dragFigure = (offsetX, offsetY, currFigureRect, setCurrFigureRect, offset0, selectedFigureOpts, imageSize) => {
    const type = selectedFigureOpts.figureActionType;
    const dragAction = selectedFigureOpts.dragAction;

    if (type === MEASURE_TYPE.RECT) dragRectangle(offsetX, offsetY, currFigureRect, dragAction, setCurrFigureRect, offset0, selectedFigureOpts, imageSize);
    else if (type === MEASURE_TYPE.LINE) dragLine(offsetX, offsetY, currFigureRect, dragAction, setCurrFigureRect, offset0, selectedFigureOpts, imageSize);
    else dragCircle(offsetX, offsetY, currFigureRect, dragAction, setCurrFigureRect, offset0, selectedFigureOpts, imageSize);
};

const getSmallCircleShift = () => Math.floor(CIRCLE_RADIUS / 2) + 1 + 1;

const getAllowedOffset = (IMAGE_SHIFT, IMAGE_SIZE, offset) => {
    const smallCircleShift = getSmallCircleShift(); //Math.floor(CIRCLE_RADIUS / 2)+1 + 1;
    let _offset = offset > IMAGE_SHIFT + smallCircleShift ? offset : IMAGE_SHIFT + smallCircleShift; //drag out of the scale
    _offset = _offset < IMAGE_SIZE - smallCircleShift ? _offset : IMAGE_SIZE - smallCircleShift; //drag out of right and bottom area
    return _offset;
};

const dragRectangle = (offsetX, offsetY, currFigureRect, dragAction, setRectangle, offset0, selectedFigureOpts, imageSize) => {
    const { x: startX, y: startY, width, height } = currFigureRect;
    const _offsetX = getAllowedOffset(IMAGE_SHIFT_X, imageSize[0], offsetX); //drag out of the scale
    const _offsetY = getAllowedOffset(IMAGE_SHIFT_Y, imageSize[1], offsetY); //drag out of the scale

    let newX = startX;
    let newY = startY;
    let newWidth = width;
    let newHeight = height;

    if (dragAction === DRAG_ACTION.TOP_LEFT) {
        newX = _offsetX;
        newY = _offsetY;
        newWidth = startX + width - _offsetX;
        newHeight = startY + height - _offsetY;
    } else if (dragAction === DRAG_ACTION.TOP_RIGHT) {
        newY = _offsetY;
        newWidth = _offsetX - startX;
        newHeight = startY + height - _offsetY;
    } else if (dragAction === DRAG_ACTION.BOTTOM_LEFT) {
        newX = _offsetX;
        newWidth = startX + width - _offsetX;
        newHeight = _offsetY - startY;
    } else if (dragAction === DRAG_ACTION.BOTTOM_RIGHT) {
        newWidth = _offsetX - startX;
        newHeight = _offsetY - startY;
    } else if (dragAction === DRAG_ACTION.INSIDE) {
        [newX, newY] = getDragXYRect(currFigureRect, offset0, selectedFigureOpts, imageSize, offsetX, offsetY);
    }

    const r = {
        x: newX,
        y: newY,
        width: newWidth,
        height: newHeight,
    };

    setRectangle(r);
};

const dragLine = (offsetX, offsetY, currFigureRect, dragAction, setRectangle, offset0, selectedFigureOpts, imageSize) => {
    const { x: startX, y: startY, width, height } = currFigureRect;
    const _offsetX = getAllowedOffset(IMAGE_SHIFT_X, imageSize[0], offsetX); //drag out of the scale
    const _offsetY = getAllowedOffset(IMAGE_SHIFT_Y, imageSize[1], offsetY); //drag out of the scale

    let newX = startX;
    let newY = startY;
    let newWidth = width;
    let newHeight = height;

    if (dragAction === DRAG_ACTION.TOP_LEFT) {
        newX = _offsetX;
        newY = _offsetY;
        newWidth = startX + width - _offsetX;
        newHeight = startY + height - _offsetY;
    } else if (dragAction === DRAG_ACTION.BOTTOM_RIGHT) {
        newWidth = _offsetX - startX;
        newHeight = _offsetY - startY;
    } else if (dragAction === DRAG_ACTION.INSIDE) {
        [newX, newY] = getDragXYRect(currFigureRect, offset0, selectedFigureOpts, imageSize, offsetX, offsetY);
    }

    const r = {
        x: newX,
        y: newY,
        width: newWidth,
        height: newHeight,
    };
    setRectangle(r);
};

const dragCircle = (offsetX, offsetY, currFigureRect, dragAction, setRectangle, offset0, selectedFigureOpts, imageSize) => {
    const getNewCoord = (coord, _currRadius, IMAGE_SHIFT, IMAGE_SIZE) => {
        let newCoord = coord;
        if (coord < _currRadius + IMAGE_SHIFT + 1) newCoord = _currRadius + IMAGE_SHIFT + 1;
        else if (newCoord > IMAGE_SIZE - _currRadius - 1) newCoord = IMAGE_SIZE - _currRadius - 1;
        return newCoord;
    };
    const { x, y } = currFigureRect;
    let r = null;

    if (dragAction === DRAG_ACTION.TOP_LEFT) {
        let newRadius = Math.sqrt((offsetX - x) ** 2 + (offsetY - y) ** 2);
        if (x < newRadius + IMAGE_SHIFT_X || y < newRadius + IMAGE_SHIFT_Y) newRadius = Math.min(x - IMAGE_SHIFT_X, y - IMAGE_SHIFT_Y);
        else if (x > imageSize[0] - newRadius - 1 || y > imageSize[1] - newRadius - 1) newRadius = Math.min(imageSize[0] - x, imageSize[1] - y);

        r = { x: x, y: y, width: newRadius, height: 0 };
    } else if (dragAction === DRAG_ACTION.INSIDE) {
        const [offsetX0, offsetY0] = offset0;
        const [rX, rY] = [selectedFigureOpts.rectX, selectedFigureOpts.rectY];

        let newX = offsetX - (offsetX0 - rX);
        let newY = offsetY - (offsetY0 - rY);

        const currRadius = currFigureRect.width;

        newX = getNewCoord(newX, currRadius, IMAGE_SHIFT_X, imageSize[0]);
        newY = getNewCoord(newY, currRadius, IMAGE_SHIFT_Y, imageSize[1]);

        r = { x: newX, y: newY, width: currFigureRect.width, height: 0 };
    }

    if (!!r) setRectangle(r);
};

const getDragXYRect = (currFigureRect, offset0, selectedFigureOpts, imageSize, offsetX, offsetY) => {
    const getNewCoord = (currOffset, initOffset, initXYCoord, IMAGE_SHIFT, IMAGE_SIZE, figureRectSize) => {
        const initLeftShift = initOffset - initXYCoord; // сдвиг курсора относительно левого (верхнего) угла

        let newXYCoord = currOffset - initLeftShift; // новая левая (верхняя) координата при сдвиге
        const circleSize = getSmallCircleShift();
        const fullShift = IMAGE_SHIFT + circleSize;

        if (figureRectSize >= 0) {
            if (newXYCoord < fullShift) newXYCoord = fullShift;
            if (newXYCoord > IMAGE_SIZE - figureRectSize - circleSize) newXYCoord = IMAGE_SIZE - figureRectSize - circleSize;
        } else {
            if (newXYCoord < fullShift - figureRectSize) newXYCoord = fullShift - figureRectSize;
            if (newXYCoord > IMAGE_SIZE - circleSize) newXYCoord = IMAGE_SIZE - circleSize;
        }

        return newXYCoord;
    };

    const [offsetX0, offsetY0] = offset0;
    const [rectX, rectY] = [selectedFigureOpts.rectX, selectedFigureOpts.rectY];

    const newX = getNewCoord(offsetX, offsetX0, rectX, IMAGE_SHIFT_X, imageSize[0], currFigureRect.width);
    const newY = getNewCoord(offsetY, offsetY0, rectY, IMAGE_SHIFT_Y, imageSize[1], currFigureRect.height);

    return [newX, newY];
};

export const drawFigures = (_ctx, imageData, unitName, _currFigure, isDraggingMode, 
        _selectedFigureOpts, isAlreadyDrawn, signatureListRef, imageSize, canAddComment) => {
    if (!_ctx) return;
    const signatureList = [];
    //console.log('imageData.measureList.length=', imageData?.measureList?.length);
    
    for (let i = 0; i < imageData?.measureList?.length; i++) {
        const measure = { ...imageData.measureList[i], unitName: unitName };
        const isFigureSelected = i === _selectedFigureOpts.figureInd;

        if (isFigureSelected && isDraggingMode) {
            //dragging the selected figure
            drawFigure(
                _ctx, isFigureSelected, _selectedFigureOpts.figureActionType, null,
                _selectedFigureOpts, measure,
                _currFigure, i, isDraggingMode, false, signatureList, imageSize, canAddComment);
            //console.log('OPTION 1. index', i, ' signatureList=', signatureList);
        } else if (isDraggingMode) {
            //show the rest of non-dragged figures when dragging mode
            drawFigure(_ctx, isFigureSelected, measure.type, null, _selectedFigureOpts, measure, 
                measure.rectangle, i, isDraggingMode, false, signatureList, imageSize, canAddComment);
            //console.log('OPTION 2. index', i, ' signatureList=', signatureList);
        } else if (isFigureSelected) {
            //cursor is inside the figure, no dragging
            const offset0 = [_selectedFigureOpts.offsetX, _selectedFigureOpts.offsetY];
            drawFigure(_ctx, isFigureSelected, measure.type, offset0, _selectedFigureOpts, measure, 
                measure.rectangle, i, false, true, signatureList, imageSize, canAddComment);
            //console.log('OPTION 3. index', i, ' signatureList=', signatureList);
        } else if (!isAlreadyDrawn) {
            //no dragging, any figure
            drawFigure(_ctx, isFigureSelected, measure.type, null, _selectedFigureOpts, measure, 
                measure.rectangle, i, false, false, signatureList, imageSize, canAddComment);
            //console.log('OPTION 4. index', i, ' signatureList=', signatureList);
        }
    }

    //draw a new figure:
    if (!!_currFigure && isDraggingMode && _selectedFigureOpts.figureInd === -1) {
        drawFigure(_ctx, false, _selectedFigureOpts.figureActionType, null, _selectedFigureOpts, null, 
            _currFigure, -1, isDraggingMode, false, signatureList, imageSize, canAddComment);
    }

    //add common signature to for deleting all signatures;
    if (signatureList.length > 0) {
        drawDeleteAllFigures(_ctx, IMAGE_SHIFT_X + 20, imageSize[1] - 30, signatureList);
    }

    //save signature list:
    if (!isAlreadyDrawn) {
        signatureListRef.current = signatureList;
        //console.log('FINAL signatureList=', signatureList);
    }
};

const drawFigure = (_ctx, isFigureSelected, type, offset0, selectedFigureOpts, measure, rectangle, figureIndex, isDraggingMode, isShowBknd, signatureList, imageSize, canAddComment) => {
    if (type === MEASURE_TYPE.RECT) {
        drawRectangle(_ctx, isFigureSelected, measure, selectedFigureOpts, rectangle, figureIndex, isDraggingMode, isShowBknd, signatureList, imageSize, canAddComment);
    } else if (type === MEASURE_TYPE.LINE) {
        drawLine(_ctx, isFigureSelected, measure, selectedFigureOpts, rectangle, figureIndex, isDraggingMode, isShowBknd, signatureList, imageSize, canAddComment);
    } else {
        drawCircle(_ctx, isFigureSelected, measure, offset0, selectedFigureOpts, rectangle, figureIndex, isDraggingMode, isShowBknd, signatureList, imageSize, canAddComment);
    }
};

const drawRectangle = (ctx, isFigureSelected, measure, selectedFigureOpts, rectangle, figureIndex, isDraggingMode, isShowBknd, signatureList, imageSize, canAddComment) => {
    if (!ctx || !rectangle) return;

    const dragAction = selectedFigureOpts.dragAction;

    const { x, y, width, height } = rectangle;
    ctx.strokeStyle = isFigureSelected && dragAction === DRAG_ACTION.INSIDE ? COLOR_SELECTED : COLOR_STANDARD;
    ctx.lineWidth = getLineWidth(isFigureSelected, dragAction);

    ctx.beginPath();
    ctx.rect(x, y, width, height);
    ctx.stroke();

    if (isShowBknd) {
        ctx.fillStyle = FIGURE_BKND_COLOR;
        ctx.fill();
    }
    ctx.closePath();

    // Draw circles in the corners
    drawSmallCircle(ctx, x, y, getSmallCircleColor(isShowBknd, dragAction, DRAG_ACTION.TOP_LEFT));
    drawSmallCircle(ctx, x + width, y, getSmallCircleColor(isShowBknd, dragAction, DRAG_ACTION.TOP_RIGHT));
    drawSmallCircle(ctx, x, y + height, getSmallCircleColor(isShowBknd, dragAction, DRAG_ACTION.BOTTOM_LEFT));
    drawSmallCircle(ctx, x + width, y + height, getSmallCircleColor(isShowBknd, dragAction, DRAG_ACTION.BOTTOM_RIGHT));

    let numY = Math.max(y, y + height) + 23;
    let isCommentBelow = true;
    if (imageSize[1] - numY < 30) {
        numY = Math.min(y, y + height) - 30;
        isCommentBelow = false;
    }
    drawFigureNumberAndComment(ctx, measure, isDraggingMode, figureIndex, isCommentBelow, Math.min(x, x + width), numY, signatureList, canAddComment);
    drawFigureLegend(ctx, measure, Math.min(x, x + width) + 15, Math.min(y, y + height) + 23, isFigureSelected);
};

const drawLine = (ctx, isFigureSelected, measure, selectedFigureOpts, rectangle, figureIndex, isDraggingMode, isShowBknd, signatureList, imageSize, canAddComment) => {
    if (!ctx || !rectangle) return;
    const dragAction = selectedFigureOpts.dragAction;

    const { x, y, width, height } = rectangle;
    ctx.fillStyle = COLOR_STANDARD;
    ctx.strokeStyle = isFigureSelected && dragAction === DRAG_ACTION.INSIDE ? COLOR_SELECTED : COLOR_STANDARD;
    ctx.lineWidth = getLineWidth(isFigureSelected, dragAction);

    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + width, y + height);
    ctx.stroke();
    ctx.closePath();

    // Draw circles in the corners
    drawSmallCircle(ctx, x, y, getSmallCircleColor(isShowBknd, dragAction, DRAG_ACTION.TOP_LEFT));
    drawSmallCircle(ctx, x + width, y + height, getSmallCircleColor(isShowBknd, dragAction, DRAG_ACTION.BOTTOM_RIGHT));

    let numX, numY;
    if (height >= 0) {
        numX = x + width;
        numY = y + height;
    } else {
        numX = x;
        numY = y;
    }
    let isCommentBelow = true;
    if (imageSize[1] - Math.max(y, y + height) < 50) {
        numY -= 50;
        isCommentBelow = false;
    }

    drawFigureNumberAndComment(ctx, measure, isDraggingMode, figureIndex, isCommentBelow, numX, numY + 23, signatureList, canAddComment);

    let stX0 = x + width / 2;
    let stY0 = y + height / 2;
    const angle = width === 0 ? Math.PI : Math.atan(height / width);
    let stX = stX0;
    let stY = stY0;
    if (Math.abs(angle) < 0.8) stY += 20;
    if (angle >= 0.8) stX -= 20;
    if (angle <= -0.8) stX -= 50;

    drawFigureLegend(ctx, measure, stX, stY, isFigureSelected, angle); //
};

const drawCircle = (ctx, isFigureSelected, measure, offset0, selectedFigureOpts, rectangle, figureIndex, isDraggingMode, isShowBknd, signatureList, imageSize, canAddComment) => {
    if (!ctx || !rectangle || !rectangle.width) return;
    const { x: centerX, y: centerY, width: radius } = rectangle;
    const dragAction = selectedFigureOpts.dragAction;

    ctx.beginPath();
    ctx.strokeStyle = isFigureSelected && dragAction === DRAG_ACTION.INSIDE ? COLOR_SELECTED : COLOR_STANDARD;
    ctx.lineWidth = getLineWidth(isFigureSelected, dragAction);

    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
    ctx.stroke();

    if (isShowBknd) {
        ctx.fillStyle = FIGURE_BKND_COLOR;
        ctx.fill();
    }
    ctx.closePath();

    let xLegend = centerX - 5;
    let yLegend = centerY + 10;

    if (isFigureSelected && !isDraggingMode) {
        const [offsetX0, offsetY0] = offset0;
        //show radius:
        const [circleX, circleY] = getPointOnCircle(centerX, centerY, offsetX0, offsetY0, radius);
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.lineTo(circleX, circleY); //ctx.lineTo(offsetX, offsetY);
        ctx.stroke();
        ctx.closePath();

        //show small round in the center
        drawSmallCircle(ctx, centerX, centerY, getSmallCircleColor(isShowBknd, DRAG_ACTION.TOP_LEFT, DRAG_ACTION.TOP_LEFT));

        if (dragAction === DRAG_ACTION.TOP_LEFT) {
            //show small round on the circle
            drawSmallCircle(ctx, offsetX0, offsetY0, getSmallCircleColor(isShowBknd, DRAG_ACTION.TOP_LEFT, DRAG_ACTION.TOP_LEFT));
        }
        yLegend = offsetY0 > centerY ? yLegend - 62 : yLegend + 25;
    }

    let numY = centerY + radius + 20;
    let isCommentBelow = true;
    if (imageSize[1] - numY < 25) {
        numY = centerY - radius - 30;
        isCommentBelow = false;
    }
    drawFigureNumberAndComment(ctx, measure, isDraggingMode, figureIndex, isCommentBelow, centerX, numY, signatureList, canAddComment);
    drawFigureLegend(ctx, measure, xLegend, yLegend, isFigureSelected);
};

//it's common for all figures:
const drawFigureNumberAndComment = (ctx, measure, isDraggingMode, figureIndex, isCommentBelow, xCoord, yCoord, signatureList, canAddComment) => {
    if (figureIndex === -1 || figureIndex === '' || figureIndex === undefined) return;

    const rectX = xCoord - 15;
    const rectY = yCoord - 12;
    const rectWidth = 60 + (canAddComment ? 25 : 0); //contains index and 2 icons
    const rectHeight = 30;

    const textX = rectX + 14;
    const textY = rectY + 21;

    const ICON_SIZE = 22; //rectWidth/2; //
    const editX = textX + 18;
    const editY = textY - 15;

    const trashX = textX + 20 + (canAddComment ? 20 : 0);
    const trashY = editY;

    const commentX = textX;
    const commentY = isCommentBelow ? textY + rectHeight : rectY - 10;

    ctx.beginPath();
    ctx.fillStyle = COLOR_STANDARD;
    ctx.roundRect(rectX, rectY, rectWidth, rectHeight, [15]);
    ctx.fill();

    //index:
    ctx.font = '20px serif';
    ctx.fillStyle = COLOR_TEXT;
    ctx.fillText('' + (figureIndex + 1).toString(), figureIndex < 9 ? textX : textX - 6, textY);

    //comment:
    if (canAddComment && measure.comment) {
        ctx.font = '24px serif';
        ctx.fillStyle = COLOR_STANDARD;
        ctx.fillText(measure.comment, commentX, commentY);
    }

    ctx.closePath();

    //edit and delete icons
    if (canAddComment) drawIcon(ctx, editX, editY, '/edit.svg');
    drawIcon(ctx, trashX, trashY, '/trash.svg');

    //add signature rectangles:
    if (!isDraggingMode) {
        if (canAddComment) {
            const signaturEditRect = { x: editX, y: rectY, width: ICON_SIZE, height: rectHeight, figureIndex: figureIndex, iconAction: ICON_ACTION.EDIT };
            signatureList.push(signaturEditRect);
            const signaturDeleteRect = { x: trashX, y: rectY, width: ICON_SIZE, height: rectHeight, figureIndex: figureIndex, iconAction: ICON_ACTION.DELETE };
            signatureList.push(signaturDeleteRect);
        } else {
            const signatureRect = { x: rectX, y: rectY, width: rectWidth, height: rectHeight, figureIndex: figureIndex, iconAction: ICON_ACTION.DELETE };
            signatureList.push(signatureRect);
        }
    }
};

const drawDeleteAllFigures = (ctx, xCoord, yCoord, signatureList) => {
    const rectX = xCoord - 5;
    const rectY = yCoord - 16;
    const rectWidth = 220;
    const rectHeight = 30;

    const trashX = rectX + 10;
    const trashY = rectY + 5;

    const textX = rectX + 35;
    const textY = rectY + 21;

    ctx.beginPath();
    ctx.fillStyle = COLOR_STANDARD;
    ctx.roundRect(rectX, rectY, rectWidth, rectHeight, [5]);
    ctx.fill();

    drawIcon(ctx, trashX, trashY, '/trash.svg');

    ctx.font = '18px serif';
    ctx.fillStyle = COLOR_TEXT;
    ctx.fillText('Удалить все объекты', textX, textY);
    ctx.closePath();

    //add signature rectangle:
    const signatureRect = { x: rectX, y: rectY, width: rectWidth, height: rectHeight, figureIndex: DELETE_ALL_MEASURES_MARKER };
    signatureList.push(signatureRect);
};

const drawFigureLegend = (ctx, measure, xCoord, yCoord, isFigureSelected, angle = undefined) => {
    if (!isFigureSelected) return;

    const yShift = 18;
    const yCoordText1 = yCoord + 3;
    const yCoordText2 = yCoordText1 + yShift;
    const yCoordText3 = yCoordText2 + yShift;
    const xLegend = xCoord - 8;
    const yLegend = yCoord - 15;

    const width = 130;
    const height = measure.type === MEASURE_TYPE.LINE ? 25 : measure.type === MEASURE_TYPE.CIRCLE ? 43 : 59;

    ctx.beginPath();

    //legend rectangle:
    ctx.fillStyle = FIGURE_LEGEND_COLOR;
    ctx.font = '18px serif';

    //legend text:
    const txt1 = (measure.type !== MEASURE_TYPE.CIRCLE ? 'L = ' : 'R = ') + getRoundValue(measure.length, 3) + ' ' + measure.unitName;
    if (measure.type !== MEASURE_TYPE.LINE) {
        ctx.roundRect(xLegend, yLegend, width, height, [3]);
        ctx.fill();
        ctx.fillStyle = COLOR_TEXT;
        ctx.fillText(txt1, xCoord, yCoordText1);
    } else {
        ctx.save();
        ctx.translate(xCoord, yCoordText1);
        ctx.rotate(angle);

        const currX = -60;
        ctx.fillRect(currX, 0, 120, 30);
        //ctx.roundRect(-60, 0, 120, 30, [3]);

        ctx.fillStyle = COLOR_TEXT;
        ctx.fillText(txt1, currX + 10, 20);
        ctx.restore();
    }

    if (measure.type === MEASURE_TYPE.CIRCLE) {
        const txt2 = 'S = ' + getRoundValue(measure.square, 3) + ' ' + measure.unitName + '2';
        ctx.fillText(txt2, xCoord, yCoordText2);
    }

    if (measure.type === MEASURE_TYPE.RECT) {
        const txt2 = 'W = ' + getRoundValue(measure.width, 3) + ' ' + measure.unitName;
        ctx.fillText(txt2, xCoord, yCoordText2);
        const txt3 = 'S = ' + getRoundValue(measure.square, 3) + ' ' + measure.unitName + '2';
        ctx.fillText(txt3, xCoord, yCoordText3);
    }

    ctx.closePath();
};

const drawIcon = async (ctx, textX, textY, iconSource) => {
    const img = new Image();
    img.src = iconSource;

    img.onload = () => {
        ctx.drawImage(img, textX, textY);
    };
};

const drawSmallCircle = (ctx, xCoord, yCoord, fillColor, circleRadius = CIRCLE_RADIUS) => {
    ctx.fillStyle = fillColor;
    ctx.beginPath();
    ctx.arc(xCoord, yCoord, circleRadius, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();
};

const getPointOnCircle = (centerX, centerY, offsetX, offsetY, radius) => {
    //1. y - y0 = k (x - x0) => y = kx + y0 - kx0
    //2. (x1 - x0)**2 + (y1 - y0)**2 = R**2 => (x1 - x0)**2 * (k ** 2 + 1) = R**2 => x1 = +/-  R / Sqrt(k**2 + 1) + x0
    // => y1 = k * x1 + y0 - kx0

    if (centerX === offsetX) {
        if (offsetY > centerY) return [centerX, centerY + radius];
        else return [centerX, centerY - radius];
    }

    const kCoef = Math.abs((offsetY - centerY) / (offsetX - centerX));
    let sqrt = radius / Math.sqrt(kCoef * kCoef + 1);

    let diffX = offsetX >= centerX ? +sqrt : -sqrt; // > > ; > <
    const newX = diffX + centerX;

    const sign = offsetX >= centerX ? 1 : -1;

    const diffY = (offsetY >= centerY ? +kCoef : -kCoef) * sign;
    const newY = centerY + diffY * (newX - centerX);
    return [newX, newY];
};

export const getEmptyFigureOpts = (_selectedActionType) => {
    return { figureInd: -1, figureActionType: _selectedActionType, dragAction: '' };
};

export const areEqualFigureOpts = (figureOpts1, figureOpts2) => {
    const areEqual = figureOpts1.figureInd === figureOpts2.figureInd && figureOpts1.figureActionType === figureOpts2.figureActionType && figureOpts1.dragAction === figureOpts2.dragAction;
    return areEqual;
};

export const areEqualEmptyFigureOpts = (figureOpts1, figureOpts2) => {
    const areEqualEmpty = areEqualFigureOpts(figureOpts1, figureOpts2) && figureOpts1.figureInd === -1 && figureOpts1.figureActionType && figureOpts1.dragAction === '';
    return areEqualEmpty;
};

const getLineWidth = (isFigureSelected, dragAction) => (isFigureSelected && dragAction !== '' ? 4 : 2);

const getSmallCircleColor = (isShowSelection, corner, selectedCorner) => {
    return isShowSelection && (corner === DRAG_ACTION.INSIDE || corner === selectedCorner) ? COLOR_SELECTED : COLOR_STANDARD;
};

export const getImageNumber = (index) => {
    return (index < 9 ? '00' : index < 99 ? '0' : '') + (parseInt(index) + 1).toString();
};

export const getMeasureTypeName = (type) => {
    return type === MEASURE_TYPE.RECT ? 'Прямоугольник' : type === MEASURE_TYPE.LINE ? 'Линейка' : 'Круг';
};

export const getMeasure = (figureActionType, _rect, innerImageSize, scale) => {
    const getRectLength = (_rect, _videoSize, _scale) => {
        if (!_rect) return 0;
        const rectSize = Math.max(Math.abs(_rect.width), Math.abs(_rect.height));
        const videoWidth = _videoSize[0];
        const length = (rectSize / videoWidth) * _scale;
        return length;
    };
    const getLineLength = (_rect, _videoSize, _scale) => {
        if (!_rect) return 0;
        const rectSize = Math.sqrt(_rect.width * _rect.width + _rect.height * _rect.height);
        const videoWidth = _videoSize[0];
        const length = (rectSize / videoWidth) * _scale;
        return length;
    };
    const getCircleRadius = (_rect, _videoSize, _scale) => {
        if (!_rect) return 0;
        const rectSize = Math.abs(_rect.width);
        const videoWidth = _videoSize[0];
        const radius = (rectSize / videoWidth) * _scale;
        return radius;
    };
    const getRectWidth = (_rect, _videoSize, _scale) => {
        if (!_rect) return 0;
        const rectSize = Math.min(Math.abs(_rect.width), Math.abs(_rect.height));
        const videoWidth = _videoSize[0];
        const _width = (rectSize / videoWidth) * _scale;
        return _width;
    };
    const getRectSquare = (_width, _length) => _width * _length;
    const getCircleSquare = (_radius) => Math.PI * _radius * _radius;

    const length =
        figureActionType === MEASURE_TYPE.RECT
            ? getRectLength(_rect, innerImageSize, scale)
            : figureActionType === MEASURE_TYPE.LINE
            ? getLineLength(_rect, innerImageSize, scale)
            : getCircleRadius(_rect, innerImageSize, scale);
    const width = getRectWidth(_rect, innerImageSize, scale);
    const radius = length;

    const measure = {
        type: figureActionType,
        comment: '', //xxx
        rectangle: !!_rect ? _rect : null, // for RECT, LINE, CIRCLE (for CIRCLE: center= {x, y}; radius = width)
        length: Number(getRoundValue(length, 3)), // for RECT, LINE, CIRCLE (radius for CIRCLE)
        width: figureActionType === MEASURE_TYPE.RECT ? Number(getRoundValue(width, 3)) : 0, //for RECT
        square:
            figureActionType === MEASURE_TYPE.RECT
                ? Number(getRoundValue(getRectSquare(width, length), 3))
                : figureActionType === MEASURE_TYPE.CIRCLE
                ? Number(getRoundValue(getCircleSquare(radius)), 3)
                : 0, //for CIRCLE
    };

    return measure;
};

export const recalcAllImageMeasures = (_pngFrames, currVideoInd, innerImageSize, videoScale) => {
    //reaclc in all frames for the same video
    const updatedFrames = [];

    for (let i = 0; i < _pngFrames.length; i++) {
        const frame = _pngFrames[i];

        if (frame.videoInd === currVideoInd) {
            const newMeasureList = recalcMeasures(frame.measureList, innerImageSize, videoScale);
            const updatedImageData = { ...frame, measureList: newMeasureList };
            updatedFrames.push(updatedImageData);
        } else updatedFrames.push(frame);
    }
    return updatedFrames;
};

export const recalcMeasures = (measureList, innerImageSize, videoScale) => {
    const newMeasureList = [];
    for (let j = 0; j < measureList.length; j++) {
        const measure = measureList[j];
        const newMeasure = getMeasure(measure.type, measure.rectangle, innerImageSize, videoScale);
        newMeasureList.push(newMeasure);
    }
    return newMeasureList;
};

export const getFrameDataList = (frame, frameInd, handleDeleteMeasure, videoObj) => {
    //it is used in VideoAnalysis and VideoAnalysisConfirmDlg
    const measureList = [...frame.measureList];
    const unitName = videoObj.videoUnitName;

    return (
        <>
            <div className="videoEditorList__row" key={'v01' + frameInd}>
                <span className="videoEditorList__label" key={'v02' + frameInd}>
                    Видео
                </span>
                <span className="videoEditorList__val" key={'v03' + frameInd}>
                    {videoObj.videoName}
                </span>
            </div>

            <div className="videoEditorList__row" key={'m01' + frameInd}>
                <span className="videoEditorList__label" key={'m02' + frameInd}>
                    <div>Масштаб</div>
                    <div>видео</div>
                </span>
                <span className="videoEditorList__val" key={'m03' + frameInd}>
                    {videoObj.videoScale} {unitName}
                </span>
            </div>

            {measureList.map((item, ind) => getFrameDataFigure(frame, item, unitName, frameInd, ind, handleDeleteMeasure))}
        </>
    );
};

const getFrameDataFigure = (frame, measure, unitName, selectedFrameInd, ind, handleDeleteMeasure) => {
    const key = '' + selectedFrameInd + ind;
    return (
        <div
            className="videoEditorList__box"
            key={'i01' + key}
        >
            <Icon
                name="close"
                key={'ic0101' + key}
                onClick={() => {
                    handleDeleteMeasure(selectedFrameInd, ind);
                }}
            />

            <div
                className="videoEditorList__row"
                key={'d02' + key}
            >
                <span
                    className="videoEditorList__label"
                    key={'sp0201' + key}
                >
                    {' '}
                    Измерение{' '}
                </span>
                <span
                    className="videoEditorList__val"
                    key={'sp0202' + key}
                >
                    {ind + 1}
                </span>
            </div>

            <div
                className="videoEditorList__row"
                key={'d03' + key}
            >
                <span
                    className="videoEditorList__label"
                    key={'sp0301' + key}
                >
                    Тип измерения
                </span>
                <span
                    className="videoEditorList__val"
                    key={'sp0302' + key}
                >
                    {getMeasureTypeName(measure.type)}
                </span>
            </div>
            <div
                className="videoEditorList__row"
                key={'d04' + key}
            >
                <span
                    className="videoEditorList__label"
                    key={'sp0401' + key}
                >
                    {measure.type !== MEASURE_TYPE.CIRCLE ? 'Длина' : 'Радиус'}
                </span>
                <span
                    className="videoEditorList__val"
                    key={'sp0402' + key}
                >
                    {getRoundValue(measure.length, 3)} {!!measure.length && unitName}
                </span>
            </div>

            {measure.type === MEASURE_TYPE.RECT && (
                <>
                    <div
                        className="videoEditorList__row"
                        key={'d10' + key}
                    >
                        <span
                            className="videoEditorList__label"
                            key={'sp1001' + key}
                        >
                            Ширина
                        </span>
                        <span
                            className="videoEditorList__val"
                            key={'sp1002' + key}
                        >
                            {getRoundValue(measure.width, 3)} {!!measure.width && unitName}
                        </span>
                    </div>
                </>
            )}

            {(measure.type === MEASURE_TYPE.RECT || measure.type === MEASURE_TYPE.CIRCLE) && (
                <>
                    <div
                        className="videoEditorList__row"
                        key={'d20' + key}
                    >
                        <span
                            className="videoEditorList__label"
                            key={'sp2001' + key}
                        >
                            Площадь
                        </span>
                        <span
                            className="videoEditorList__val"
                            key={'sp2002' + key}
                        >
                            {getRoundValue(measure.square, 3)} {!!measure.square && unitName}
                            <span>{!!measure.square && '2'}</span>
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};

export const getRoundValue = (_value, roundNum) => {
    if (_value === undefined) return 0;
    const value = Number(_value);
    if (Math.abs(value) >= 1) {
        const val0 = Number.parseFloat(value).toFixed(roundNum);
        return parseFloat(val0);
    }

    if (value === 0) return value;

    let isReady = false;
    let val = value;
    let max = 1;
    let cnt = 0;
    for (let i = 0; i < roundNum - 1; i++) max *= 10;
    while (!isReady) {
        val *= 10;
        cnt++;
        if (Math.abs(val) >= max) {
            isReady = true;
            val = Number(Math.round(val).toFixed(0));
        }
    }
    let zeros = '0.';
    let hasSign = false;
    if (val < 0) {
        val = -val;
        hasSign = true;
    }
    for (let i = 0; i < cnt - roundNum; i++) zeros += '0';
    let res = zeros + val;
    if (hasSign) res = '-' + res;
    return res;
};

export const getExportCB = (_handleProfile, _handleExportExperiment, isCor) => {
    //const isCor1 = false; //isCor;
    return (
        <div className="exportCB">
            <div
                className="exportCB__label"
                onClick={isCor ? () => null : _handleExportExperiment}
            >
                <Icon name="download" />
                <span>Экспорт измерений</span>
            </div>
            {isCor && (
                <div className="exportCB__drop">
                    <div
                        className="exportCB__item"
                        onClick={_handleProfile}
                    >
                        <Icon name="export" />
                        <span>В профиль</span>
                    </div>
                    <div
                        className="exportCB__item"
                        onClick={_handleExportExperiment}
                    >
                        <Icon name="xls" />
                        <span>XLS</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export const getFormattedDate = (date, dateType = 'ymd') => {
    //https://stackoverflow.com/questions/3552461/how-do-i-format-a-date-in-javascript
    let dateOptions = {
        year: 'numeric',
        month: '2-digit', // 'long',
        day: '2-digit', // 'numeric',
    };

    if (dateType === 'ymd_hm') {
        dateOptions = {
            year: 'numeric',
            month: '2-digit', // 'long',
            day: '2-digit', // 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };
    }

    return date ? date.toLocaleString('ru', dateOptions) : '';
};

export const DEFAULT_SCALE_SIZE = 0; //2;
export const DEFAULT_SCALE_UNIT_ID = 'mm';
export const DEFAULT_SCALE_UNIT_NAME = 'мм';

export const sizeUnitList = [
    { value: 'mkm', label: 'мкм', miltiplier: 0.0001 },
    { value: 'mm', label: 'мм', miltiplier: 1 },
    { value: 'sm', label: 'см', miltiplier: 10 },
    { value: 'm', label: 'м', miltiplier: 1000 },
    { value: 'km', label: 'км', miltiplier: 1000 * 1000 },
];

export const getSizeUnitList = () => {
    return sizeUnitList;
};

export const getScaleUnitById = (id) => {
    return sizeUnitList.find((item) => item.value === id);
};

export const getFramesBeExported = (videoList, videoSelectList, pngFrames, owner) => {
    const frames = [];
    for (let i = 0; i < videoList.length; i++) {
        const video = videoList[i];
        if (videoSelectList[i]) {
            for (let j = 0; j < pngFrames.length; j++) {
                const frame = pngFrames[j];
                if (frame.videoInd === i) {
                    const frameInfo = {
                        videoInd: i,
                        frameInd: j,
                        frameName: video.videoName + ', Кадр ' + (j + 1) + '- ' + owner + ' - ' + new Date().getTime(),
                        frameExportData: pngFrames[j].frameExportData,
                    };

                    frames.push(frameInfo);
                }
            }
        }
    }

    return frames;
};

export const saveVideosInDb = (videoList, videoSelectList, options, videoSize, pngFrames, 
    fileNamesExported, videoDbRecord, setVideoDbRecord) => {
    //save video data in db:
    for (let i = 0; i < videoList.length; i++) {
        const video = videoList[i];
        if (videoSelectList[i]) {
            //Save data in DB:
            const frames = [];
            let curInd = 0;

            for (let j = 0; j < pngFrames.length; j++) {
                const frame = pngFrames[j];

                if (frame.videoInd === i) {
                    const frameInfo = {
                        frameTime: pngFrames[j].frameTime,
                        frameName: fileNamesExported[curInd].frameName,
                        measures: getMeasures(frame),
                    };

                    frames.push(frameInfo);
                    curInd++;
                }
            }

            const videoDbData = {
                owner: options.owner,
                videoName: video.videoName,
                userVideoName: video.videoName, //maybe will be changed later
                size: video.size,
                videoScale: video.videoScale,
                videoUnitId: video.videoUnitId,
                videoSizeX: videoSize[0],
                videoSizeY: videoSize[1],
                frames: frames,
            };

            //save info about the video in DB: //
            options.addUpdateVideoDataToDb(videoDbRecord, videoDbData);
        }
    }
};

const getMeasures = (frame) => {
    const measures = [];

    for (let k = 0; k < frame.measureList.length; k++) {
        const measure = frame.measureList[k];
        const measureData = {
            type: measure.type, //1 - rectangle, 2 - line, 3 - circle
            x: measure.rectangle.x, //1, 2 - (x,y)-> (x+width, y+height), 3 - (x.y) - center, widht - radius
            y: measure.rectangle.y,
            width: measure.rectangle.width,
            height: measure.rectangle.height,
        };
        measures.push(measureData);
    }
    return measures;
};

export const getListElementById = (list, id) => {
    return list.find((item) => item.value?.toString() === id?.toString());
};

export const getDropdown = (list, selectedValue, handleClick, dropdownClassName, dropdownItemClassName, key, dropPosition = 'bottom', disabled = false, additionalClickOpt = undefined) => {
    const elem = getListElementById(list, selectedValue);

    return (
        <Dropdown
            value={elem?.label}
            dropPosition={dropPosition}
            className={dropdownClassName}
            disabled={disabled}
        >
            {list.map((item, ind) => (
                <Dropdown.Item
                    key={key + ind}
                    onClick={() => handleClick(item.value, additionalClickOpt)}
                    className={''}
                >
                    {item.label}
                </Dropdown.Item>
            ))}
        </Dropdown>
    );
};

export const getCheckBox = (value, handle, id, text) => {
    return (
        <div className="cor-net__checkbox">
            <input
                type="checkbox"
                key={id + '01'}
                id={id}
                value={value}
                checked={value}
                onChange={() => handle(!value)}
            ></input>
            <label htmlFor={id}>
                {'  '}
                {text}
            </label>
        </div>
    );
};

//camera methods:
export const getCameras = (setCameraList) => {
    if (!navigator.mediaDevices) return [];
    const cameras = [];
    navigator.mediaDevices.enumerateDevices().then(devices => {
        for (let i in devices) {
            let device = devices[i];
            // console.log(device);
            if (device.kind === 'videoinput') {
                const cameraOpts = {
                    value: i,
                    id: device.deviceId,
                    label: device.label.split('(')[0],
                };
                cameras.push(cameraOpts);
            }
        }
        setCameraList(cameras);
    });
};

export const startRecording = (localStream, setRecorder) => {
    const options = { mimeType: 'video/webm;codecs=vp9' };
    let rec = new MediaRecorder(localStream, options);
    rec.start();
    setRecorder(rec);
};

export const stopAndClearRecording = (video, _localStream, _recorder, _setRecorder, fileName, _setSavedCameraFileData) => {
    stopRecording(_localStream, _recorder, _setRecorder, fileName, _setSavedCameraFileData);

    if (video?.srcObject) {
        video.srcObject.getTracks().forEach((track) => {
            track.stop();
        });
    }
};

export const stopRecording = (localStream, recorder, setRecorder, fileName, setSavedCameraFileData) => {
    const downloadFile = async (url, fName) => {
        const a = document.createElement('a');
        a.download = fName;
        a.href = url;
        a.click();
    };
    
    const stopBothVideoAndAudio = (stream) => {
        stream.getTracks().forEach(function (track) {
            if (track.readyState === 'live') {
                track.stop();
            }
        });
    };

    if (recorder && recorder.state === 'recording') {
        recorder.stop();
    }

    if (recorder && recorder.state === 'inactive') {
        const data = [];

        recorder.ondataavailable = (e) => {
            if (e.data) {
                data.push(e.data);
            }
        };

        recorder.onstop = () => {
            if (!!fileName) {
                let blob = new Blob(data, { type: 'video/mp4' });
                let href = URL.createObjectURL(blob);
                const fullFileName = fileName + '.mp4';
                downloadFile(href, fullFileName);

                const fileData = {
                    videoName: fullFileName,
                    videoURL: href,
                    size: blob.size,
                };
                setSavedCameraFileData(fileData);
            }

            setRecorder(null);
            stopBothVideoAndAudio(localStream);
        };
    }
};

export const updateLocalStream = (video, selectedCameraId, isCameraRecord, setLocalStream) => {
    if (!video || !navigator.mediaDevices) return;
    if (!selectedCameraId || !isCameraRecord) return;

    const options = {
        video: { deviceId: selectedCameraId },
        audio: false,
    };

    navigator.mediaDevices.getUserMedia(options)
        .then((stream) => {
            setLocalStream(stream);
        })
        .catch((err) => {
            toast.warn('Произошла ошибка при выборе камеры для записи. Выберите другую камеру из списка, проверьте подключение и/или обновите страницу');
    });
};
export const getMicroscopeInstruction = () => {
    return [
        'Установка размера кадра',
        'Подключите микроскоп к устройству через USB – порт. ',
        'Нажмите кнопку «Начать запись» для записи видео с микроскопа. ',
        'Снимите с камеры микроскопа защитную крышку, установите микроскоп камерой вертикально вплотную на калибровочный круг карточки из комплекта поставки. ',
        'Добейтесь четкого изображения шкалы микроскопа, изменяя кратность фокусировки колесиком на нем.',
        'Установите микроскоп в начале шкалы для расчета размера кадра (количество делений шкалы, находящееся в кадре).',
        'Впишите полученное значения размера кадра. Для смены единицы измерения размера кадра необходимо выбрать её во всплывающем списке. ',
        'Учтите, что для последующих измерений нельзя менять фокусировку микроскопа, это приведет к сбою калибровки, что впоследствии скажется на точности показаний. ',
        'Если вы выполнили все требования и не видите запись с микроскопа, поток видео захвачен другой программой (возможно, другим браузером). Завершите работу с этой программой и повторите подсоединение с к микроскопу.',
    ];
};

export const getVideoFileInstruction = () => {
    return [
        'Выберите видео для анализа.  ',
        'Перед началом анализа необходимо установить размер кадра. ',
        'Видео для анализа должно быть записано с заведомо известным размером кадра.',
        '',
        'Технические требования к видео:',
        'Допустимые форматы видео файлов: .MP4, .WebM, .3GP, .MPEG, .OGG, .MOV, .MOVIE, .QT',
        'Кодеки (алгоритмы сжатия): для воспроизведения встроенным плеером видео должно использовать один из следующих кодеков: h264, MPEG4, h265, HEVC. Для WEBM используются кодеки VP8 и VP9.',
        'Соотношение сторон: Самое распространенное соотношение — 16:9. При квадратном экране оптимально подходит — 4:3.',
        'Пример видео: video_1920x1080_h265.mp4 Демонстрационное видео можно скачать здесь:',
    ];
};
export const getVideoFileExampleRef = () => {
    return 'https://disk.yandex.ru/i/B9wh08CeimuXIg';
};

export const getVideoName = (videoSource, currVideoInd, videoList) => {
    let name;
    if (videoSource === VIDEO_SOURCE.CAMERA) {
        name = ': Микроскоп';
    } else { //if (videoSource === VIDEO_SOURCE.VIDEOFILE || videoSource === VIDEO_SOURCE.LIBRARY)
        name = currVideoInd >= 0 && videoList.length > 0 ? videoList[currVideoInd].videoName : '';
        name = ' ' + name.replace('.mp4', '');
        // let list = name.split('.');
        // name = ' ' + list.filter((item, ind) => ind < list.length - 1).join('');
    }
    return name;
};

export const saveXLSX = (workBookName, description, videoList, pngFrames) => {
    const getTableColumns = (unitName) => {
        const cols = [
            { header: '         Видео        ', key: 'videoName' },
            { header: '             Описание          ', key: 'description' },
            { header: 'Масштаб, ' + unitName, key: 'videoScale' },
            { header: 'Номер измерения', key: 'measureNumber' },
            { header: 'Тип измерения', key: 'measureType' },
            { header: 'Длина, ' + unitName, key: 'length' },
            { header: 'Ширина, ' + unitName, key: 'width' },
            { header: 'Площадь, ' + unitName + '2', key: 'square' },
        ];

        return cols;
    };

    const getTableData = (frame, videoObj) => {
        const tableData = [];

        for (let i = 0; i < frame.measureList.length; i++) {
            const measure = frame.measureList[i];

            const measureRecord = {
                videoName: i === 0 ? videoObj.videoName : '',
                description: i === 0 ? description : '',
                videoScale: i === 0 ? videoObj.videoScale : '',
                measureNumber: i + 1,
                measureType: getMeasureTypeName(measure.type),
                length: measure.length,
                width: measure.type === MEASURE_TYPE.RECT ? measure.width : '',
                square: measure.type === MEASURE_TYPE.RECT || measure.type === MEASURE_TYPE.CIRCLE ? measure.square : '',
            };

            tableData.push(measureRecord);
        }

        return tableData;
    };

    const getGraphicData = (frame) => {
        return [
            {
                image: frame.frameExportData,
                rowNum: 32,
                colNum: 12,
            },
        ];
    };

    const tableSheets = [];
    for (let i = 0; i < pngFrames.length; i++) {
        const frame = pngFrames[i];
        const videoInd = frame.videoInd;
        const unitName = videoList[videoInd].videoUnitName;

        const tableSheet = {
            workSheetName: 'Таблица ' + (i + 1),
            columns: getTableColumns(unitName),
            tableData: getTableData(frame, videoList[videoInd]),
            graphicData: getGraphicData(frame),
        };
        tableSheets.push(tableSheet);
    }

    saveExcel(workBookName, tableSheets, []);
};



/*
//версия, если мы вы экспортируем видео. Тогда нужно переходить в состояние DO_CHECK_DBюсм. упрощенный вариант в VE
const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const videoBlob = URL.createObjectURL(file);

    //1. let's check if the video has been already uploaded:
    const videoName = file.name;
    for (let i = 0; i < videoList.length; i ++)
        if (videoList[i].videoName === videoName) {
            setCurrVideoInd(i); //this video has been already loaded. no need to load again, just need to select it.
            doSetCurrMode(isCor, corOptions, SCREEN_MODE.DO_CHECK_DB);
            return;
        }

    //2. let's upload a new video:
    doUploadNewVideo(STREAM_INPUT.VIDEO, file, videoBlob, commonScale, currUnitId, 
        videoList, currVideoInd, setVideoList, setCurrVideoInd);
    doSetCurrMode(isCor, corOptions, SCREEN_MODE.SHOW_VIDEO); //DO_CHECK_DB
};
    //реализует проверку, если currMode === SCREEN_MODE.DO_CHECK_DB
    useEffect(() => {
        const isFoundDbVideoIndex = () => {
            let found = false;
            for (let i = 0; i < corOptions.savedVideoDataList.length; i ++) {
                const video = videoList[currVideoInd];
                const dbVideoData = corOptions.savedVideoDataList[i];
                if (dbVideoData.videoName === video.videoName && dbVideoData.size === video.size) {
                        setFoundDbVideoInd(i);
                        setShowConfirmUploadVideoDbDataDlg(true);
                        found = true;
                }
            }
            return found;
        };
        if (currMode !== SCREEN_MODE.DO_CHECK_DB) return;
        let found = false;
        if (isCor) {
            if (!corOptions) return;
            else found = isFoundDbVideoIndex();
        }
        if (!found) doSetCurrMode(isCor, corOptions, SCREEN_MODE.SHOW_VIDEO);
    }, [isCor, currMode, corOptions, videoList, currVideoInd]);
        <ConfirmUploadVideoDbDataDlg
                showConfirmDlg={showConfirmUploadVideoDbDataDlg}
                question={'Для загруженного вами видео сохранены сделанные ранее измерения. Вы хотите загрузить их?'}
                handleYes={() => saveUploadVideoDbDataInfo(true)}
                handleNo={() => saveUploadVideoDbDataInfo(false)}
            />
    useEffect(() => {
        const addNewFrame = (newFrame) => {
            setPngFrames(pngFrames => [...pngFrames, newFrame]);
        };
        
        if (isCor && corOptions && isDbVideoDataAdded && currMode === SCREEN_MODE.SHOW_VIDEO && 
                videoRef.current.videoWidth > 0) {
            setIsDbVideoDataAdded(false);
            const videoData = corOptions?.savedVideoDataList[foundDbVideoInd];
            const vSize = [videoData.videoSizeX, videoData.videoSizeY];

            for (let i = 0; i < videoData.frames.length; i ++)
                addSavedFrame(videoData.frames[i], videoRef, vSize, currVideoInd, currScale, addNewFrame);
        }
    }, [corOptions, currMode, currScale, currVideoInd, foundDbVideoInd, isCor, isDbVideoDataAdded]);

    import ConfirmUploadVideoDbDataDlg from './ConfirmDlg';
    const [showConfirmUploadVideoDbDataDlg, setShowConfirmUploadVideoDbDataDlg] = useState(false);
    const [foundDbVideoInd, setFoundDbVideoInd] = useState(undefined);
    const [isDbVideoDataAdded, setIsDbVideoDataAdded] = useState(false);
    const [isCheckedSavedVideoDataList, setIsCheckedSavedVideoDataList] = useState(false);

    useEffect(() => { ///
        //works only for COR: selection of saved video (file and db data)
        if (!isCor || !corOptions || !corOptions.selectedVideoInfo || currMode !== SCREEN_MODE.DO_LOAD_VIDEO) return;
        const [file, dbVideoData] = corOptions.selectedVideoInfo;
        corOptions.setSelectedVideoInfo(undefined);

        doUploadNewVideo(STREAM_INPUT.VIDEO, file, file.Url, dbVideoData.videoScale, dbVideoData.videoUnitId, 
            videoList, currVideoInd, setVideoList, setCurrVideoInd);
        doSetCurrMode(isCor, corOptions, SCREEN_MODE.SHOW_VIDEO); //DO_CHECK_DB
    }, [isCor, corOptions, videoList, currVideoInd, currMode]);

    useEffect(() => {
        const getAllVideoDbData = async (_options) => {
            const videoData = await _options.getAllOwnerVideoData(_options.owner);

            if (videoData && videoData.length > 0 && corOptions) {
                corOptions.setSavedVideoDataList(videoData);
                setIsCheckedSavedVideoDataList(true);
            }
        };

        if (!isCor || !corOptions || isCheckedSavedVideoDataList) return;
		getAllVideoDbData(corOptions);
    }, [isCor, corOptions, isCheckedSavedVideoDataList]);


    const saveUploadVideoDbDataInfo = isMeasuresLoaded => {
        setShowConfirmUploadVideoDbDataDlg(false);

        const newVideoList = [...videoList];
        const video = newVideoList[currVideoInd];

        const videoData = corOptions.savedVideoDataList[foundDbVideoInd];
        video.dbId = videoData._id;
        video.isMeasuresLoaded = isMeasuresLoaded;
        setVideoList(newVideoList);
        setCurrScale(videoData.videoScale);
        setCurrUnitId(videoData.videoUnitId);
        
        if (isMeasuresLoaded) 
            setIsDbVideoDataAdded(true);
        else 
            toast.warn("При сохранении новых измерений в этом видео старые измерения будут удалены."); 

        doSetCurrMode(isCor, corOptions, SCREEN_MODE.SHOW_VIDEO);
    };
    
export const getCheckedVideoData = (videoDataList, storageFiles) => {
    const videoData = [];
    for (let i = 0; i < videoDataList.length; i ++) {
        const dbFileName = videoDataList[i].videoName;
        let found = false;
        for (let j = 0; j < storageFiles.length && !found; j ++) {
            const storageFileName = storageFiles[j].Key.split('/')[1];
            if (storageFileName === dbFileName) {
                videoData.push(videoDataList[i]);
                found = true;
            }
        }
    }
    return videoData;
};
*/

/*
    useEffect(() => {
        if (!mouseMoveCoords || dragging) return;
        const [offsetX, offsetY] = mouseMoveCoords;
        if (offsetX <= IMAGE_SHIFT_X || offsetY <= IMAGE_SHIFT_Y) {
            // it is the scale area
            if (selectedFigureOpts.figureInd !== -1)
                //clean up the options
                setSelectedFigureOpts(getEmptyFigureOpts(selectedActionType));
        } else { //try to find a figure
            const opts = findActiveFigure(offsetX, offsetY, pngFrames[selectedFrameInd], selectedActionType);
            if (!areEqualEmptyFigureOpts(selectedFigureOpts, opts)) {
                //found a new figure
                setSelectedFigureOpts(opts);
            }
        }
        setMouseMoveCoords(undefined);
    }, [mouseMoveCoords, pngFrames, selectedActionType, selectedFigureOpts, selectedFrameInd, dragging]);

    const handleMouseMove = (e) => {
        const DEBOUNCE_PERIOD = 50;
        const { offsetX, offsetY } = e.nativeEvent;
        if (selectedFrameInd === -1) return;
        if (!dragging) {
            if (debounceMouseRef.current) clearTimeout(debounceMouseRef.current);
            debounceMouseRef.current = setTimeout(() => {
                setMouseMoveCoords([offsetX, offsetY]);
            }, DEBOUNCE_PERIOD);
            //see handking in useEffect above
        } else {
            //dragging the figure. offset0 - start coords, offsetX - current coords
            const offset0 = [selectedFigureOpts.offsetX, selectedFigureOpts.offsetY];
            dragFigure(offsetX, offsetY, currFigureRect, setCurrFigureRect, offset0, selectedFigureOpts, imageSize);
        }
    };
*/
