import React, { useState, useEffect } from "react";
import {storageClass} from "../../redux/slices/storage";
import {isAuth} from "../../helpers/auth";
import {onlineTestApi, onlineTestInfoApi} from "../../api/api";
import {getOnlineTestById, getQuestionTypes} from "./ot_utils";
import {useSelector} from "react-redux";
import {DB_REFRESH} from "../ui/utils/gen_utils";
import socket from "../../socket";
import OnlineTestCreateQuestions from "./OnlineTestCreateQuestions";
import ModalCommentDlg  from "../ui/ModalDialogs/ModalCommentDlg";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import OnlineTestCreateGeneral from "./OnlineTestCreateGeneral";
import {toast} from "react-toastify";
import { Back, Content, ContentBody, ContentHead } from "../template/ContentParts";

const OnlineTestAddEdit = (props) => {
    const [testTitle, setTestTitle] = useState('');
    const [description, setDescription] = useState('');
    const [timeLimitInMinutes, setTimeLimitInMinutes] = useState(5);
    const [timeLimitEnabled, setTimeLimitEnabled] = useState(false);
    const [isRandomOrder, setIsRandomOrder] = useState(true);
    const [isAutoEstimateType, setIsAutoEstimateType] = useState(false);
    const [questionTypeList, setQuestionTypeList] = useState([]);
    const [onlineTestId, setOnlineTestId] = useState('');
    const [showInfoComment, setShowInfoComment] = useState(false);
    const [infoComment, setInfoComment] = useState('');
    const [shouldSave, setShouldSave] = useState(false);
    const [showCompleteDlg, setShowCompleteDlg] = useState(false);
    const [showGeneral, setShowGeneral] = useState(true);
    const [questionList, setQuestionList] = useState([]);
    const [isRunningEditQuestions, setIsRunningEditQuestions] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isTemplate, setIsTemplate] = useState(false);
    const [objVersion, setObjVersion] = useState(undefined);
	const [isSaved, setIsSaved] = useState(false);
	//
	const [enabledQuestionTypes, setEnabledQuestionTypes] = useState([]);
	const [firstQuestionType, setFirstQuestionType] = useState(undefined);

	const isSA = isAuth().role === 3; //superadmin
    const documentClass = useSelector(storageClass);

	const checkTestUsage = async (_onlineTestId) => {
		const result = await onlineTestInfoApi.getAllOnlineTestInfosByTest(_onlineTestId);
		if (result && result.data.length > 0) {
			setInfoComment("Этот тест уже использовался в тестировании учеников. Добавление или удаление вопросов может привести к искажениям результов тестирования. Рекомендуем создать новый тест.");
			setShowInfoComment(true);
		}
	};

	useEffect(() => {
		const fetchData = async (_onlineTestId) => {
			const data = await getOnlineTestById(_onlineTestId);
			//1. get general info
			setTestTitle(data.title);
			setDescription(data.description);
			setTimeLimitInMinutes(data.timeLimitInMinutes !== 0 ? data.timeLimitInMinutes : 5);
			setTimeLimitEnabled(data.timeLimitEnabled);
			setIsRandomOrder(data.isRandomOrder);
			setIsAutoEstimateType(!!data.isAutoEstimateType ? data.isAutoEstimateType : false);
			setQuestionTypeList(getQuestionTypes(data.questionTypes));
			setQuestionList(data.questions);
			setIsEditable(isAuth().role === 3 || !!data.owner);
			setIsTemplate(isAuth().role === 3 || !data.owner);
            setObjVersion(data.__v);
		};

		if (!props.onlineTestId) return;
		setOnlineTestId(props.onlineTestId);
		if (props.onlineTestId === "0") {
			setQuestionTypeList(getQuestionTypes(''));
			setIsEditable(true);
			setIsTemplate(isAuth().role === 3);
		} else {
			fetchData(props.onlineTestId);
			if (isAuth().role < 3)
				checkTestUsage(props.onlineTestId); //check if students already answered the test:
		} 
    }, [props.onlineTestId]);

	useEffect(() => {
		const enabledQTypes = questionTypeList.filter(item => item.value); ////найдем доступные типы вопросов
		if (enabledQTypes.length === 0) return;
        const sorted = enabledQTypes.sort((a,b) => a.order - b.order);
        const firstId = sorted[0].id;
		setEnabledQuestionTypes(enabledQTypes);
        setFirstQuestionType(firstId); //найдем первый из вопросов
    }, [questionTypeList]);

	const doLeave = () => {
		props.history.push(isAuth().role < 3 ? '/practicum/ot' : '/ot');
	};
    const handleBack = () => { //< Выйти
		if (shouldSave) {
			setShowCompleteDlg(true); //there are changes. Cancel them?
		} else {
			doLeave();
		}
	};

    const handleConfirmExitWithoutSave = () => {
		setShowCompleteDlg(false);
		doLeave();
	};
	
	const handleSaveAndExit = () => {
		const doComplete = async () => {
			await doSave();
			setShouldSave(false);
			doLeave();
		};
		doComplete();
	};

    const doSave = async (qList = null) => {
		const getSaveData = (qlist) => {
			const onlineTestData = {
				title: testTitle,
				description: description,
				timeLimitInMinutes: timeLimitInMinutes,
				timeLimitEnabled: timeLimitEnabled,
				isRandomOrder: isRandomOrder,
				isAutoEstimateType: isAutoEstimateType,
				questionTypes: questionTypeList.map(item => item.value ? 1 : 0).join(''),
				questions: qlist,
			};
			if (isAuth().role < 3) onlineTestData.room = documentClass._id;
			if (onlineTestId !== "0") {
				onlineTestData.__v = objVersion;
			}
	
			return onlineTestData;
		};

		const reinitOpts = (result) => {
			setObjVersion(result.__v);
			setQuestionList(result.questions);
		};
		const saveData = async () => {
			const onlineTestData = getSaveData(qList ? qList : questionList);

			if (onlineTestId === "0") {
				await onlineTestApi.addOnlineTest(onlineTestData)
				.then((result) => {
					setOnlineTestId(result._id);
					reinitOpts(result);
				}).catch((err) => {
					toast.error(err.response.data.errors);
				});
			} else {	
				await onlineTestApi.updateOnlineTestById(onlineTestId, onlineTestData)
				.then((result) => {
					reinitOpts(result);
					socket.emit('BE-refresh-db', {type: DB_REFRESH.OTI, roomId: documentClass._id});
				}).catch((err) => {
					toast.error(err.response.data.errors);
				});
			}
		};
	
		saveData();
    };

	useEffect(() => {
		setIsSaved(true);
    }, [questionList]);

	//=========================================================
	if (!onlineTestId) {
		return <></>;
	}

    return (
	    <>
			{showGeneral && (
				<>
					<ContentHead flex={false}>
						<Back onClick={handleBack} icon="back" margin="bottom" />
						<div className="cor_content__title">Добавление задания</div>
					</ContentHead>
					<Content>
						<ContentBody>
							<OnlineTestCreateGeneral 
								testTitle={testTitle}
								setTestTitle={setTestTitle}
								description={description}
								setDescription={setDescription}
								timeLimitInMinutes={timeLimitInMinutes}
								setTimeLimitInMinutes={setTimeLimitInMinutes}
								timeLimitEnabled={timeLimitEnabled}
								setTimeLimitEnabled={setTimeLimitEnabled}
								isRandomOrder={isRandomOrder}
								setIsRandomOrder={setIsRandomOrder}
								isAutoEstimateType={isAutoEstimateType}
								setIsAutoEstimateType={setIsAutoEstimateType}
								questionList={questionList}
								questionTypeList={questionTypeList}
								setQuestionTypeList={setQuestionTypeList}
								showGeneral={showGeneral}
								setShowGeneral={setShowGeneral}
								handleSaveAndExit={handleSaveAndExit}
								onlineTestId={onlineTestId}
								isRunningEditQuestions={isRunningEditQuestions}
								setIsRunningEditQuestions={setIsRunningEditQuestions}
								shouldSave={shouldSave}
								setShouldSave={setShouldSave}
								isEditable={isEditable}
							/>
						</ContentBody>
					</Content>
				</>
			)}

			{!showGeneral && (
				<OnlineTestCreateQuestions 
					onlineTestId={onlineTestId}
					setShowGeneral={setShowGeneral}
					questionList={questionList}
					doSave={doSave}
					enabledQuestionTypes={enabledQuestionTypes}
					firstQuestionType={firstQuestionType}
					isSaved={isSaved} 
					setIsSaved={setIsSaved}
					isEditable={isEditable}
					isSA={isSA}
					isTemplate={isTemplate}
					doLeave={doLeave}
				/>
			)}

			{showInfoComment &&
    	    <ModalCommentDlg
        	    showComment={showInfoComment}
				setShowComment={setShowInfoComment}
				comment={infoComment}
	        />
			}

			{showCompleteDlg &&
			<ModalConfirmDialog
				showConfirmDlg={showCompleteDlg} 
				handleNo={() => setShowCompleteDlg(false)}
				question={'Имеются несохраненные данные. Вы действительно хотите завершить тест и выйти?'}
				handleYes={handleConfirmExitWithoutSave}
			/>
		}
        </>
    )
}

export default OnlineTestAddEdit;
