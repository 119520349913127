import {useEffect, useState} from 'react';

const TIME_ZONE = new Date().getTimezoneOffset() / -60;
const PAD_Z2 = n => ('0' + n).slice(-2);

const DateTime = () => {
  const [ts, setTs] = useState(Date.now());

  useEffect(() => {
    const timer = setInterval(() => setTs(Date.now()), 1000);
    return () => clearInterval(timer);
  }, []);

  return PAD_Z2((~~(ts / 3600000) + TIME_ZONE) % 24) + ':' +
    PAD_Z2(((~~(ts / 60000)) % 60)) + ':' +
    PAD_Z2(((~~(ts / 1000)) % 60));
};

export default DateTime