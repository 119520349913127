import React, {useEffect, useState} from 'react';
import {Unity, useUnityContext} from "react-unity-webgl";
import {isAuth} from "../../helpers/auth";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import socket from "../../socket";
import {DB_REFRESH} from "../ui/utils/gen_utils";
import {vrLabInfoApi} from "../../api/api";
import ss from "./VrLab.module.css";

const VR_DIR = "/vr-lab/StandBuild/";
const VR_BUILD = VR_DIR + "Build/StandBuild";

const VrLab = (props) => {
    const vrLabInd = Number(props.match.params.id);
    const [recId, setRecId] = useState(undefined);
    const [vrLabStepAmount, setVrLabStepAmount] = useState(0);
    const documentClass = useSelector(storageClass);

    const {unityProvider, loadingProgression, isLoaded,
        addEventListener, removeEventListener, sendMessage,
        UNSAFE__detachAndUnloadImmediate: detachAndUnloadImmediate
    } = useUnityContext({
        loaderUrl: VR_BUILD + ".loader.js",
        dataUrl: VR_BUILD + ".data",
        frameworkUrl: VR_BUILD + ".framework.js",
        codeUrl: VR_BUILD + ".wasm",
        streamingAssetsUrl: VR_DIR + "StreamingAssets",
    });

    const getVrLabData = async (_vrLabInd, documentClassId, _sendMessage) => {
        const result = await vrLabInfoApi.getVrLabInfoByOwnerIdByVrLabInd(isAuth()._id, _vrLabInd)
        .catch((err) => {
            toast.error(err.response.data.errors);
        });

        let stepState = '';

        if (result) {
            if (result.data.length > 0) {
                const data = result.data[0];
                setRecId(data._id);
                setVrLabStepAmount(data.stepAmount ? Number(data.stepAmount) : 0);
                stepState = data.stepState;
            }
        } else {
            const data = {
                room: documentClassId,
                vrLabInd: _vrLabInd,
                stepAmount: 0,
                step: 0,
                stepState: '',
                isComplete: false,
            }

            const result = await vrLabInfoApi.addVrLabInfo(data);
            //toast.success('id=' + result._id);
            setRecId(result._id);
			socket.emit('BE-refresh-db', {type: DB_REFRESH.VRL, roomId: documentClass._id});
        }

        _sendMessage('BridgeMediator', 'OpenLabWork', '' + _vrLabInd + ' @#$' + stepState);
    };

    useEffect(() => {
        const doUpdate = async (obj, changedField) => {
            //toast.info('START doUpdate. field =' + changedField + ' recId=' + recId);
            if (changedField === 'stepComplete') { //step and complete
                await vrLabInfoApi.updateVrLabInfoById(recId, obj)
                .catch((err) => {
                    toast.error(err.response.data.errors);
                });
            } else if (changedField === 'stepAmount') {
                await vrLabInfoApi.updateVrLabInfoStepAmountById(recId, obj)
                .catch((err) => {
                    toast.error(err.response.data.errors);
                });
            } else if (changedField === 'stepState') {
                await vrLabInfoApi.updateVrLabInfoStateById(recId, obj)
                .catch((err) => {
                    toast.error(err.response.data.errors);
                });
            }
			socket.emit('BE-refresh-db', {type: DB_REFRESH.VRL, roomId: documentClass._id});
       };
    
        const handleGoBack = () => props.history.goBack();
        const handleButtonQuitLabWorkPressed = () => props.history.push('/vrlab');
    
        const handleReload = async () => {
            await getVrLabData(vrLabInd, documentClass._id, sendMessage);
        };
        const handleStepAmount = async (amount) => {
            await doUpdate({stepAmount: amount}, 'stepAmount');
            setVrLabStepAmount(amount);
        };
        const handleStepCompleted = async (step) => {
            const data = {step: Number(step), isComplete: false};
            await doUpdate(data, 'stepComplete');
        };
        const handleOnDataSent = async (stepState) => {
            await doUpdate({stepState: stepState}, 'stepState');
        };
        const handleLabWorkCompleted = async () => {
            await doUpdate({stepState: ''}, 'stepState');
            await doUpdate({step: vrLabStepAmount, isComplete: true}, 'stepComplete');
        };
    
        addEventListener("OnReloadLabWork", handleReload);
        addEventListener("OnStepAmount", handleStepAmount);
        addEventListener("OnStepCompleted", handleStepCompleted);
        addEventListener("OnDataSent", handleOnDataSent);
        addEventListener("OnLabWorkCompleted", handleLabWorkCompleted);
        addEventListener("OnButtonBackToCorePressed", handleGoBack);
        addEventListener("OnButtonQuitLabWorkPressed", handleButtonQuitLabWorkPressed);


        return () => {
            removeEventListener("OnReloadLabWork", handleReload);
            removeEventListener("OnStepAmount", handleStepAmount);
            removeEventListener("OnStepCompleted", handleStepCompleted);
            removeEventListener("OnDataSent", handleOnDataSent);
            removeEventListener("OnLabWorkCompleted", handleLabWorkCompleted);
            removeEventListener("OnButtonBackToCorePressed", handleGoBack);
            removeEventListener("OnButtonQuitLabWorkPressed", handleButtonQuitLabWorkPressed);
        };
    }, [addEventListener, vrLabInd, documentClass._id, props.history, recId, 
        removeEventListener, sendMessage, vrLabStepAmount]);

    useEffect(() => {
        if (!isLoaded) return;
        if (vrLabInd) {
            //1. запускается у ученика при запуске тестирования учителем
            //2. запускает учитель в Демо при выборе конкретной работы
            getVrLabData(vrLabInd, documentClass._id, sendMessage);
        } else {
            //запускает учитель или ученик по нажатию иконки Ракета (конкретная работа не выбрана)
        }
    }, [isLoaded, sendMessage, vrLabInd, documentClass._id]);

    useEffect(() => {
        return () => {
            detachAndUnloadImmediate().catch();
        }
    }, [detachAndUnloadImmediate]);

    return (<>
        {!isLoaded &&
          <div className="cor_dashboardBox__top">
              Загрузка... {Math.round(loadingProgression * 100)}%
          </div>}
        <div className={ss.vrlab}>
            <Unity unityProvider={unityProvider}/>
        </div>
    </>);
};

export default VrLab;

/*
1. запуск конкретной работы у класса - в лаб.работах, оптика в режиме тестирования 
  в этом случае для каждого ученмка запускается - 
  передается sendMessage('CustomBridge', 'OpenLabWork', Number(_vrLabInd), stepState);
2. запуск конкретной работы у учителя - то же что в пункте 1.
3. учитель и ученик могут запустить игру по нажатию иконки Ракета. В этом случае ничего не передается.
*/
