import React, {useState, useEffect} from "react";
import ScenarioOneItem from './ScenarioOneItem';
import {deleteRlsFileByName, getScUrls} from './sc_utils';
import {useDispatch} from "react-redux";
import { Button } from 'rlabui';
import {isAuth} from "../../helpers/auth";
import ss from './Scenarios.module.scss';
import preview from '../../rlab/src/components/Scenario/ScenarioRunDlg.module.scss';
import htmlParser from "html-react-parser";

const ScenarioPage2Items = ({isTemplate, scItems, setScItems, checkItemName, images, setImages}) => {
    const [itemUrls, setItemUrls] = useState([]);

    const dispatch = useDispatch();
    const isDisabledTemplate = isTemplate && isAuth().role < 3;

    useEffect(() => {
        getScUrls(scItems.map(item => item.attachmentName), setItemUrls);
    }, [scItems]);

    const handleItemChange = (itemInd, item) => {
        const items = scItems.map((obj, ind) => ind !== itemInd ? obj : item);
        setScItems(items);
    };

    const handleAddItem = () => {
        if (!checkItemName()) return;

        const item = {
            name: '',
            description: '',
            attachmentName: '',
        };
        const items = [...scItems];
        items.push(item);
        setScItems(items);
    };

    const handleDeleteItem = (itemIndex) => {
        if (scItems[itemIndex].attachmentName && !isTemplate) //удаляем из хранилища, если это не шаблон
            deleteRlsFileByName(dispatch, scItems[itemIndex].attachmentName);
        const items = scItems.filter((item, ind) => ind !== itemIndex);
        setScItems(items);
    };

    return (
        <div className={ss.root}>

            <div className={"cor-net grow " + ss.steps } key='si01'>
                {scItems.map((item, ind) => 
                    <ScenarioOneItem 
                        key={'si02'+ind}
                        itemIndex={ind}
                        isTemplate={isTemplate}
                        scItem={item}
                        handleItemChange={handleItemChange}
                        handleDeleteItem={handleDeleteItem}
                        images={images} 
                        setImages={setImages}
                    />
                )}

                {!isDisabledTemplate && 
                <div className="cor-net__add" key='si03'>
                    <Button
                        onClick={handleAddItem}
                        border={true} 
                        disabled={isDisabledTemplate}
                    >
                        Добавить шаг
                    </Button>
                </div>
                }
            </div>

            <div className={ss.preview__sticky}>
                <div className={ss.preview__title}>Предварительный просмотр</div>
                <div className={ss.preview}>
                    <div className={preview.open}>
                        <div className={preview.toggle}>
                            <b>Шаги</b>
                        </div>

                        <div className={preview.body}>

                            <div className={preview.content}>
                                <div className={preview.content__scrolled}>
                                    <div className={preview.questions}>
                                    {
                                    scItems.map((item, ind) => 
                                        <div className={preview.questions__item}  key={'it1'+ind}>
                                            <div className={preview.questions__title}  key={'it2'+ind}>
                                                Шаг {ind+1}. {item.name}
                                            </div>
                                            <div className={preview.description} key={'it4'+ind}>
                                                {item.description ? htmlParser(item.description) : ''}
                                            </div>
                                            {item.attachmentName &&
                                            <div className={preview.img} key={'it5'+ind}>
                                                <img src={!isTemplate ? 
                                                    itemUrls[ind] :
                                                    JSON.parse(item.attachmentName).content}
                                                    alt='' key={'pr1'}  />
                                            </div>
                                            }
                                        </div>
                                    )}
                                    </div>
                                </div>
                            </div> 
                            
                        </div>
                        
                    </div>
                </div>
            </div>

        </div> 
    );
}

export default ScenarioPage2Items; 
