import React, { useState, useEffect, useRef } from "react";
import Table from "../ui/Table/Table";
import labsList from '../practicum/subjectList';
import {printOnlyDate} from "../../helpers/text";
import {labWorkRunApi, labWorkInfoApi} from "../../api/api";
import socket from "../../socket";
import {DB_REFRESH, getClassStudents} from "../ui/utils/gen_utils";
import {getSubjectName, getSectionName, getLabName, getLabWorkArraysAndNames} from "../practicum/lw_utils";
import {FEATURES} from "../../api/config";
import {PROFILE_TAB}  from "../template/Dashboard/DashboardProfile";
import {getSelectedRunTopHeader, getHeader, getExceptionCellStyle} from "./rt_utils";
import { Content, ContentBody } from "../template/ContentParts";
import {useSelector, useDispatch} from "react-redux";
import {isAuth} from "../../helpers/auth";
import {storageClass, storageOrg} from "../../redux/slices/storage";
import {fetchclass} from "../../redux/slices/classsline";
import "./ReviewActiveLabWork.scss";

const ViewSelectedLabWorkRun = ({ history, selectedTestRunId }) => {
    const [labWork, setLabWork] = useState(undefined);
	const [testRunDetails, setTestRunDetails] = useState(null);
	const [studentResults, setStudentResults] = useState([]);
	const [studentTableData, setStudentTableData] = useState([]);
	const [scoreUpdated, setScoreUpdated] = useState(false);
	
	const labWorkId = useRef('');
	const subjectName = useRef('');
	const sectionName = useRef('');
	const labName = useRef('');

    const documentOrg = useSelector(storageOrg);
	const documentClass = useSelector(storageClass);
    const dispatch = useDispatch();
	const laboratoryList = labsList.subjectList;

    useEffect(() => {
        dispatch(fetchclass(isAuth()._id));
    }, [dispatch, documentOrg._id]);
	
	useEffect(() => {
		const getLWRunDetails = async () => {
			const result = await labWorkRunApi.getLabWorkRunById(selectedTestRunId);
			setTestRunDetails(result);
		}
		const getLWRunStudentResults = async () => {
			const result = await labWorkInfoApi.getClassUserLabWorkInfosByRunId(selectedTestRunId);
			setStudentResults(result.data); 
		}

		getLWRunDetails(); //info about running test
		getLWRunStudentResults(); //info about student results
		if (scoreUpdated) setScoreUpdated(false);
	},[selectedTestRunId, scoreUpdated]);

	useEffect(() => {
		if (!testRunDetails) return;

		subjectName.current = getSubjectName(laboratoryList, testRunDetails.subjectId, testRunDetails.sectionId, testRunDetails.labId);
		sectionName.current = getSectionName(laboratoryList, testRunDetails.subjectId, testRunDetails.sectionId, testRunDetails.labId);
		labName.current = getLabName(laboratoryList, testRunDetails.subjectId, testRunDetails.sectionId, testRunDetails.labId);
		labWorkId.current = testRunDetails.subjectId + '|' + testRunDetails.sectionId  + '|' + testRunDetails.labId;

		const [, , lab] = getLabWorkArraysAndNames(laboratoryList, testRunDetails.subjectId, testRunDetails.sectionId, testRunDetails.labId);
		setLabWork(lab);
	},[laboratoryList, testRunDetails]);

	useEffect(() => {
		const getTestRunList = async () => {
			const students = getClassStudents(documentClass.classUsers);

			const list = [];
			for (let i = 0; i < students.length; i ++) {
				const student = students[i];
				const result = studentResults.find(item => item.owner === student.owner);

				if (result) {
					const item = {
						name: student.name, 
						progress: result.isComplete ? 'Готово' : 
							((result.pageInd+1) + ' из ' + labWork?.labPages?.length),
						score: result.score !== null ? result.score.toString() : '',
						id: student.owner + '|' + result._id, 
						personPict: student.personPict
					};

					list.push(item);
				}
			}

			setStudentTableData(list);
		}

		if (!testRunDetails) return;
		if (!studentResults || studentResults.length === 0) return;
		
		getTestRunList();
	},[documentClass._id, documentClass.classUsers, labWork?.labPages?.length, 
	   testRunDetails, studentResults]);

	const specialCellStyle = (item, rowInd, colInd, row) => getExceptionCellStyle(item);

	const handlePerson = (ids) => {
		const userId = ids.split('|')[0];
		history.push(FEATURES['profile'].to + '/' + userId + '/' + PROFILE_TAB.LAB_WORKS);
	}; 

	const handleScoreUpdate = async (score, ids) => {
		const testInfoId = ids.split('|')[1];
		const scoreVal = score !== '' ? Number(score) : null;
		await labWorkInfoApi.updateLabWorkInfoScoreById(testInfoId, {score: scoreVal});
		socket.emit('BE-refresh-db', {type: DB_REFRESH.LWI, roomId: documentClass._id});
		setScoreUpdated(true);
	};

	const getTableHeader = () => {
		return [
			{column: 'Имя ученика', name: 'name', style: { width: '40%'} }, 
			{column: 'Прогресс выполнения', name: 'progress', style: { width: '40%'} }, 
			{column: 'Оценка учителя', name: 'score', style: { width: '20%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];
	};

	const handleHistory = () => {
		history.push('/reviewtest/lw');
	};

    return (
		<>
			{getSelectedRunTopHeader(true, subjectName.current, 
				!!testRunDetails ? printOnlyDate(testRunDetails.createdAt) : undefined, 
				handleHistory, "Перейти ко всем результатам")
			}
			<Content>
				{getHeader(labName.current, sectionName.current, false, 0, undefined, undefined)}
				<ContentBody>
					<Table 
						table={{header: getTableHeader(), 
							data: studentTableData, 
							handleTableDataUpdate: handleScoreUpdate, 
							specialCellStyle: specialCellStyle
						}}
						sort={{hasSorting: true, 
							   initSortInd: 1
						}}
						person={{handlePerson: handlePerson}}
						canEditScore={true}
					/>	
				</ContentBody>
			</Content>
		</>					
    );
};

export default ViewSelectedLabWorkRun;
