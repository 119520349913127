import React from "react";
import { FilterByDate, FilterByText } from '../ui';
import "./LabWorkSelect.scss";

//Выбираем текстовый фильтр и временной диапазон 
const SelectFilterOptions = ({textPrompt, testFilter, setTestFilter, startDate, setStartDate, finishDate, 	setFinishDate}) => {
	return (
		<div className="cor-net">
			<div className="cor-net__section">
				<div className="cor-net__row">
					<div className="cor-net__col col-grow">
						<FilterByText 
							placeholder={textPrompt}
							value={testFilter}
							setSearch={setTestFilter}
						/>
					</div>
					<div className="cor-net__col col-grow"></div>
					<div className="cor-net__col">
						<FilterByDate 
							startDate={startDate}
							setStartDate={setStartDate}
							finishDate={finishDate}
							setFinishDate={setFinishDate}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SelectFilterOptions;
