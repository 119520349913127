import Grid from '../../ui/Grid';
import { Icon, Tooltip } from '../../ui';
import {base64ToArrayBuffer, uploadFile, 
	doDeleteFileByName, getUrl} from '../../ui/utils/gen_utils';

const VIDEOEDITOR_PREFIX = 'VideoEditor%2F';

export const uploadVEFile = async (fileData, setLoadedFileName) => {
	const cleanedBase64 = fileData.base64.substring(22);

	const file = new File(
		[base64ToArrayBuffer(cleanedBase64)],
		fileData.name, 
		{ type: 'image/jpg' }
	);
	
	await uploadFile(VIDEOEDITOR_PREFIX, file, setLoadedFileName);
}
export const deleteVEFileByName = async (dispatch, fileName) => {
	await doDeleteFileByName(VIDEOEDITOR_PREFIX, dispatch, fileName);
};

export const getVEUrl = async (name) => {
    return await getUrl(VIDEOEDITOR_PREFIX, name); 
};

export const getSavedVideoDataByStorageFile = (file, _savedVideoDataList) => {
	if (!_savedVideoDataList || _savedVideoDataList.length === 0) return null;
	const storageVideoName = file.Key.split('/')[1];
	const vd = _savedVideoDataList.find(item => item.storageVideoName === storageVideoName);
	return vd;
};

export const getStorageVideoName = file => {
	const storageVideoName = file.Key.split('/')[1];
	return storageVideoName;
};

export const getFrameNumber =  (file, _savedVideoDataList) => {
	const vd = getSavedVideoDataByStorageFile(file, _savedVideoDataList);
	return vd ? vd.frames.length : 0;
}

export const getMeasureNumber =  (file, _savedVideoDataList) => {
	const vd = getSavedVideoDataByStorageFile(file, _savedVideoDataList);
	if (!vd) return 0;
	let measureNum = 0;
	for (let i = 0; i < vd.frames.length; i ++)
		measureNum += vd.frames[i].measures.length;
	return measureNum;
}

const doGetVideos = (foundFiles, savedVideoDataList, hadleSelectVideo, handleDeleteVideo) => {
	return (
		<Grid className="corGrid"  key='grid01'>
			{foundFiles.map((f, i) => (
				<div key={'dir01'+i}>
					 <Grid.Col key={'dir02'+i}>
						<div className="corGrid__body otCreate__imageBody otCreate__imageBodyBknd" key={'mid01'+i}>
							<div key={"v02"+i} className='otCreate__videoExecVideo'>
								<video src={f.Url} controls={true} width="300" height="200" key={"v03"+i} ></video>
							</div>
						</div>
		
						<div className="otCreate__imageTitle" key={'bot01'+i}>
							<span className='otCreate__imageVariant otCreate__imagePointer' key={'bot04'+i}>
								{getStorageVideoName(f)}
							</span>
							<span className='otCreate__imageVariant otCreate__imagePointer' key={'bot05'+i}>
								&nbsp;&nbsp;
								<Icon name="download" onClick={() => hadleSelectVideo(f)} key={'bot06'+i}/>
							</span>
							<span className='otCreate__imageVariant otCreate__imagePointer' key={'bot15'+i}>
								&nbsp;&nbsp; 
								<Icon name="trash" onClick={() => handleDeleteVideo(f)} key={'bot16'+i}/>
							</span>
						</div>

						<div className="otCreate__imageVariant otCreate__imagePointer" key={'bot07'+i}>
							Количество кадров {getFrameNumber(f, savedVideoDataList)}
						</div>

						<div className="otCreate__imageVariant otCreate__imagePointer" key={'bot08'+i}>
							Количество измерений {getMeasureNumber(f, savedVideoDataList)}
						</div>
					</Grid.Col>
				</div>
			))}
		</Grid> 
	);
};

export const getSectionTab = (index, name, iconName, tooltip, _handleSelectTab, isUsed = true) => {
	let classNames = 'InitSectionTabs__tab';
	if (!isUsed) {
	  classNames += ' disabled';
	}
  
	return (
	  	<div className={classNames} 
			onClick={() => isUsed ? _handleSelectTab(index) : {}} 
			key={'st01'+index}
		>
			<div className="InitSectionTabs__content" key={'st02'+index}>
				<div className="InitSectionTabs__iconBox" key={'st03'+index}>
					<Icon name={iconName} className="InitSectionTabs__icon" key={'st04'+index}/>
				</div>
				<div className="InitSectionTabs__name" key={'st05'+index}>{name}</div>
		  	</div>
  
		  	<Tooltip className="InitSectionTabs__tooltip" position='custom'>{tooltip}</Tooltip>
	  </div>
	);
};
