import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getRelativePosition, getValueForPixel, isNumber } from 'chart.js/helpers';
import parse from 'html-react-parser';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
// import tableSS from '../Table/tableStyles';
import chartColors from '../../styles/charts';
// https://github.com/DuartBreedt/chartjs-plugin-draggable
import ChartjsDraggablePlugin from 'chartjs-plugin-draggable';
// import worker from './chartWorker.js';
// import WebWorker from '../../WebWorker';
import Icon from '../Icon';
import { Switcher, Modal, Range } from 'rlabui';
import ChartComment from '../ChartComment';
import ChartMini from './ChartMini';
import { showCursorLine, setChartType, showBoxOnHoverChart, setLineAnnotation, deleteBox, resetViewArea } from './functions';
import ss from './Chart.module.scss';

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, zoomPlugin, annotationPlugin, ChartjsDraggablePlugin);

let isDrag = false;
let cursorYAxisId = 'yAxis';

const ChartAreaMulty = (props) => {
    // console.log(props.props);
    const {
        data,
        writedData,
        sensors,
        bundles,
        bundlesTitle,
        port,
        played,
        paused,
        view,
        legendMode,
        markerMode,
        markerActions,
        rangeActions,
        oscilloChartSettings: { channels: oscilloChannels },
        // oscilloDefaultChartSettings: { channels: oscilloDefaultChannels },
        chartDisabled,
        chartMarkers,
        setChartMarkerAdd,
        setChartMarkerDelete,
        setChartMarkers,
        // delChartMarkers,
        chartStyle,
        defaultColors,
        theme,
        isOscilloscope,
        isHiSpeed,
        deleteAllMarkers,
        // isMobile,
        toggleChartParametric,
        // toggleChartLissague,
        // parametricMode,
        abscissa,
    } = props;

    const {
        xMin: oscXMin,
        xMax: oscXMax,
        xBias: oscXBias,
        yBias: oscYBias,
        yMin: oscYMin,
        yMax: oscYMax,
    } = isOscilloscope ? oscilloChannels[0] : { xMin: 0, xMax: 25, xBias: 0, yBias: 0, yMin: -100, yMax: 100 };

    const {
        xMin: oscXMin1,
        xMax: oscXMax1,
        xBias: oscXBias1,
        yBias: oscYBias1,
        yMin: oscYMin1,
        yMax: oscYMax1,
    } = isOscilloscope ? oscilloChannels[1] : { xMin: 0, xMax: 25, xBias: 0, yBias: 0, yMin: -100, yMax: 100 };

    const [series, setSeries] = useState([]);
    const [allAxes, setAllAxes] = useState({});
    const [firstAxis, setFirstAxis] = useState('');
    const [chart, setChart] = useState();
    const [hoveredMarker, setHoveredMarker] = useState();
    const [dragging, setDragging] = useState(false);
    const [draggingMarker, setDraggingMarker] = useState(null);
    const [xMax, setXMax] = useState(10000);
    const [xMin, setXMin] = useState(0);
    const [labelX, setLabelX] = useState(0);
    const [labelY, setLabelY] = useState(0);
    const [panned, setPanned] = useState(0);
    const [isChartArea, togleChartArea] = useState(false);
    const [chanelPrefix, setChannelPrefix] = useState();
    const [sliceLen, setSliceLen] = useState(-50);
    const [roundDigit, setRoundDigit] = useState(3);
    const [xState, setXstate] = useState(played && !paused ? false : true);
    const [yState, setYstate] = useState(played && !paused ? false : true);
    const [showMiniChart, toggleMiniChart] = useState(false);
    const [isZoom, setZoom] = useState(false);
    const [zoomMode, setZoomMode] = useState('xy');
    const [hoverTime, setHoverTime] = useState(0);
    const [hoverValue, setHoverValue] = useState(0);
    const [handlePointMode, setHandlePointMode] = useState(false);
    const [handlePoint, setHandlePoint] = useState(false);

    const chartRef = useRef();

    const styles = { ...chartStyle };

    // Android
    const { isAndroid } = window;
    const [editMarker, setEditMarker] = useState(false);

    const setAxes = () => {
        const axes = {};
        let line = true;

        axes['xAxis'] = {
            type: 'linear',
            min: isOscilloscope ? (oscXMin || oscXMin === 0 ? oscXMin : null) : xMin,
            max: isOscilloscope ? (oscXMax ? oscXMax : null) : xMax,
            display: true,
            ticks: {
                display: true,
                count: 10,
                color: chartColors.tick,
                font: {
                    size: isAndroid ? 6 : 10,
                },
                major: {
                    enabled: true,
                },
                callback: (value) => {
                    return +(value / 1000).toFixed(2);
                },
            },
            grid: {
                display: true,
                color: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                borderColor: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                tickColor: 'transparent',
                tickLength: 2,
            },
            title: {
                display: true,
                text: 'время, сек',
                color: isOscilloscope ? 'transparent' : styles[0]?.color ? styles[0].color : defaultColors[0]?.val,
                font: {
                    size: isAndroid ? 8 : 11,
                },
            },
        };

        let axesName;

        for (var i = 0; i < sensors.length; i++) {
            const { num, name, unit, roundDigit, unicName } = sensors[i];
            const abciss = abscissa[num]?.label.split('(')[1].replace(')', '') || '';

            let preffix = '';

            if (typeof num === 'string') {
                if (num.includes('loc')) {
                    preffix = ' (Лог)';
                } else {
                    preffix = ' (Имп)';
                }
            }

            let hidden = false;
            const disabled = !bundles && chartDisabled.filter((ch) => ch === num);

            if (disabled.length) {
                hidden = true;
            }

            // bundles && console.log(disabled);

            // console.log(chartStyle[i]);

            // console.log(name);

            if (view === 'mono') {
                if (!axesName) {
                    axesName = 'yAxis';
                }
            } else {
                // let count = num;
                axesName = 'y' + num;
            }
            if (i === 0) {
                setFirstAxis(axesName);
                cursorYAxisId = axesName;
            }
            // console.log(styles[num]);
            // if (!axes[axesName]) {
            axes[axesName] = {
                type: 'linear',
                min: isOscilloscope ? oscYMin : null,
                max: isOscilloscope ? oscYMax : null,
                id: axesName,
                display: !hidden,
                position: 'left',
                unicName,
                grid: {
                    display: line ? true : false,
                    borderColor: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    color: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    tickColor: 'transparent',
                    tickLength: 0,
                },
                ticks: {
                    maxTicksLimit: 10,
                    color: chartColors.tick,
                    font: {
                        size: isAndroid ? 6 : 10,
                    },
                    enabled: true,
                    callback: (value) => {
                        return +value.toFixed(roundDigit);
                    },
                },
                title: {
                    display: view === 'multy' || bundles,
                    text: `${name} ${unit} ${preffix} ${abciss}`,
                    color: styles[num] ? styles[num].color : defaultColors[i].val,
                    point: {
                        pointRadius: styles[num]?.point?.width ? styles[num].point.width : 0,
                        pointBackgroundColor: styles[num]?.point?.color ? styles[num].point.color : defaultColors[i].val,
                    },
                    font: {
                        size: isAndroid ? 8 : 11,
                    },
                    fontWeight: 'bold',
                    padding: {
                        top: 9,
                        bottom: 4,
                    },
                },
            };
            // }
            line = false;
        }

        // console.log(axes);
        // chart.options.scales = axes;
        setAllAxes(axes);
    };

    const setChartSeries = () => {
        if (!chart) {
            return;
        }
        const rec = played && !paused;
        const arr = [];
        let obj;
        let yKey = '';
        const allData = [...writedData, ...data];
        // console.log(rec);
        sensors?.forEach((item, i) => {
            const { num, name, unit, imported } = item;
            let value = item.value;
            let hidden = false;

            if (typeof num === 'number') {
                value = allData.filter((d) => d[0]?.num === num)[0] ? [...allData.filter((d) => d[0]?.num === num)[0]] : item.value;
            }

            if (typeof num !== 'number' && num.includes('loc')) {
                value = allData[i] ? [...allData[i]] : item.value;
            }

            if (typeof num !== 'number' && (num.includes('imp') || imported)) {
                value = item.value;
            }

            const disabled = !bundles && chartDisabled.filter((ch) => ch === num);

            if (disabled.length) {
                hidden = true;
            }

            if (view === 'mono') {
                if (!yKey.length) {
                    yKey = 'yAxis';
                }
            }
            if (view === 'multy' || bundles) {
                yKey = 'y' + num;
            }
            if (!arr[i]) {
                let stepped;
                let cubicInterpolationMode;
                let tension;
                switch (styles[num]?.interpolation) {
                    case 'linear':
                        stepped = false;
                        cubicInterpolationMode = 'default';
                        tension = 0;
                        break;

                    case 'natural':
                        stepped = false;
                        cubicInterpolationMode = 'default';
                        tension = 0.1;
                        break;

                    case 'basis':
                        stepped = false;
                        cubicInterpolationMode = 'default';
                        tension = 0.3;
                        break;

                    case 'bundle':
                        stepped = false;
                        cubicInterpolationMode = 'default';
                        tension = 0.4;
                        break;

                    case 'cardinal':
                        stepped = false;
                        tension = 0.6;
                        cubicInterpolationMode = 'default';
                        break;

                    case 'catmull':
                        stepped = false;
                        tension = 0.8;
                        cubicInterpolationMode = 'default';
                        break;

                    case 'step':
                        stepped = 'middle';
                        break;

                    case 'stepbefore':
                        stepped = 'before';
                        break;

                    case 'stepafter':
                        stepped = 'after';
                        break;

                    case 'monotone':
                        stepped = false;
                        cubicInterpolationMode = 'monotone';
                        break;

                    default:
                        stepped = false;
                        cubicInterpolationMode = 'default';
                        tension = 0.3;
                        break;
                }
                obj = {
                    label: name + ' ' + unit,
                    data: rec ? value.slice(-100) : value,
                    borderColor: styles[num]?.color ? styles[num].color : defaultColors[i].val,
                    borderWidth: styles[i]?.weight ? styles[i].weight : 2,
                    pointRadius: styles[num]?.point?.width ? styles[num].point.width : 0,
                    pointBackgroundColor: styles[num]?.point?.color ? styles[num].point.color : defaultColors[i].val,
                    stepped,
                    tension,
                    cubicInterpolationMode,
                    yAxisID: yKey,
                    hidden,
                    num,
                    spanGaps: true,
                };
                arr[i] = obj;
            } else {
                // console.log(arr[i]);
                if (data.length > 0) {
                    arr[i].data = [...arr[i].data, ...value];
                } else {
                    arr[i].data = [];
                }
            }
            // saved = a
        });

        setSeries(arr);
    };

    const initialOptions = {
        maintainAspectRatio: false,
        spanGaps: false,
        responsive: true,
        animation: false,
        // animation: {
        //     duration: 0,
        //     easing: 'linear',
        // },
        interaction: {
            includeInvisible: false,
        },
        layout: {
            padding: {
                top: 0,
                bottom: 0,
                left: 5,
                right: 5,
            },
        },
        elements: {
            line: {
                // скругление линии
                tension: isOscilloscope || isHiSpeed ? 0 : 0.3,
            },
            point: {
                pointRadius: 0,
            },
        },
        tooltips: {
            enabled: false,
        },
        hover: {
            mode: 'xy',
            intersect: false,
        },
        plugins: {
            decimation: {
                enabled: true,
                samples: 100,
                threshold: 1000,
                algorithm: 'lttb',
            },
            datalabels: {
                display: false,
            },
            legend: {
                display: true,
                position: 'left',
                // labels: {},
            },
            annotation: {
                drawTime: 'afterDatasetsDraw',
                // clip: false,
                animations: {
                    numbers: {
                        properties: [],
                    },
                },
                annotations: {},
            },
            zoom: {
                pan: {
                    enabled: isOscilloscope ? false : true,
                    mode: played && !paused ? '' : 'xy',
                    onPanStart: (chart) => {
                        // console.log(chart);
                        setPanned(true);
                    },
                    onPanComplete: (chart) => {
                        setPanned(false);
                        setChartMarkerAdd(false);
                        setChartMarkerDelete(false);
                    },
                    // onPan: chart => {},
                },
                zoom: {
                    scaleMode: 'xy',
                    mode: played && !paused ? '' : 'xy',
                    // threshold: 1,
                    // sensitivity: 2,
                    // speed: 0.1,
                    wheel: {
                        enabled: isOscilloscope ? false : true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    drag: {
                        enabled: !played && isZoom ? true : false,
                        modifierKey: 'shift',
                        backgroundColor: theme === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                        borderColor: theme === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)',
                    },
                    rangeMin: {
                        x: null,
                        y: null,
                    },
                    rangeMax: {
                        x: null,
                        y: null,
                    },
                    onZoom: (e) => {
                        // console.log(e);
                        // const level = chart.getZoomLevel();
                        // console.log(chart);
                    },
                    onZoomComplete: function ({ chart }) {
                        // console.log(`I was zoomed!!!`);
                    },
                },
            },
            themeBackgroundColor: {
                color: theme === 'dark' ? '#282B33' : '#ffffff',
            },
        },
        scales: allAxes,
        onHover: (evt, elements, chart) => {
            setHoverEvent(evt, theme);
        },
    };

    const plugins = [
        {
            id: 'checked_in_area',
            beforeEvent(chart, args) {
                const { event, inChartArea } = args;
                if (event.type === 'mouseout') {
                    togleChartArea(false);
                    setDragging(false);
                    isDrag = false;
                }
                if (event.type === 'mousemove') {
                    togleChartArea(inChartArea);
                }
            },
        },
        {
            id: 'themeBackgroundColor',
            beforeDraw: (chart, args, options) => {
                const { ctx } = chart;
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = options.color || '#FFFFFF';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
            },
        },
    ];

    const [options] = useState(initialOptions);

    useEffect(() => {
        // Фиксим нулевую точку при первом запуске
        setChartSeries();
        const onKeyDown = (e) => {
            const { key, target } = e;
            const isTextField = target.nodeName === 'INPUT' || target.nodeName === 'TEXTAREA';
            if (!isTextField) {
                e.preventDefault();
                e.stopPropagation();
                if (key === 'Shift') setZoom(true);
                if (key === ' ') setHandlePoint(true);
            }
        };
        const onKeyUp = (e) => {
            const { key, target } = e;
            const isTextField = target.nodeName === 'INPUT' || target.nodeName === 'TEXTAREA';
            if (!isTextField) {
                e.preventDefault();
                e.stopPropagation();
                if (key === 'Shift') {
                    setZoom(false);
                }
                if (key === ' ') setHandlePoint(false);
            }
        };
        document.addEventListener('keydown', onKeyDown);
        document.addEventListener('keyup', onKeyUp);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
            document.removeEventListener('keyup', onKeyUp);
        };
    }, []);

    useEffect(() => {
        if (handlePointMode && handlePoint && played) {
            const fArr = series[0].data;
            let index = fArr[fArr.length - 2].count;
            setLineAnnotation({ chart, index, value: null, roundDigit, arr: series, theme, isDrag, setHoveredMarker, setChartMarkers, sensors, setEditMarker, bundles });
        }
    }, [handlePointMode, played, handlePoint]);

    useEffect(() => {
        // console.log(xRef.current);
        if (chart) {
            const zoom = chart.options.plugins.zoom;
            if (isZoom) {
                setZoomMode(zoom.zoom.mode);
                isDrag = false;
                zoom.pan.enabled = false;
                zoom.zoom.drag.enabled = true;
                zoom.zoom.mode = 'x';
                chart.update();
            } else {
                zoom.pan.enabled = true;
                zoom.zoom.drag.enabled = false;
                zoom.zoom.mode = zoomMode;
                chart.update();
            }
        }
    }, [isZoom]);

    useEffect(() => {
        if (!chart) return;
        let i;
        if (view === 'mono') {
            const axes = chart.data.datasets;
            i = 0;
            for (let index in axes) {
                const disabled = !bundles && chartDisabled.filter((item) => item === axes[index].num);
                if (disabled.length) {
                    chart.data.datasets[index].hidden = true;
                } else {
                    chart.data.datasets[index].hidden = false;
                }
            }
        }
        if (view === 'multy' || bundles) {
            i = 0;
            for (let axes in allAxes) {
                if (axes.charAt(0) === 'y') {
                    let index = +axes.replace('y', '');
                    if (isNaN(index)) {
                        index = axes.replace('y', '');
                    }
                    const disabled = !bundles && chartDisabled.filter((item) => item === index);
                    if (disabled.length) {
                        chart.options.scales[axes].display = false;
                        chart.options.scales[axes].display = false;
                        if (chart.data.datasets[i]) {
                            chart.data.datasets[i].hidden = true;
                        }
                    } else {
                        chart.options.scales[axes].display = true;
                        chart.options.scales[axes].display = true;
                        if (chart.data.datasets[i]) {
                            chart.data.datasets[i].hidden = false;
                        }
                    }
                    i++;
                }
            }
        }
        chart.update();
    }, [chartDisabled, chart, bundles]);

    useEffect(() => {
        if (isOscilloscope) {
            // const checkXAxis = Object.keys(allAxes).filter((key) => key === 'xAxis')[0];
            // console.log(oscilloChannels);
            if (chart && oscilloChannels[0]) {
                // console.log(oscilloChannels);
                // if (chart.config._config.options.scales.xAxis) {
                // console.log(chart.config._config.options.scales.xAxis);
                chart.options.scales.xAxis.min = oscXMin;
                chart.options.scales.xAxis.max = oscXMax;

                if (view === 'mono') {
                    chart.options.scales.yAxis.min = oscYMin;
                    chart.options.scales.yAxis.max = oscYMax;
                } else {
                    let i = 0;
                    Object.keys(allAxes).forEach((axes) => {
                        if (axes.charAt(0) === 'y') {
                            chart.options.scales[axes].min = oscilloChannels[i].yMin;
                            chart.options.scales[axes].max = oscilloChannels[i].yMax;
                            i++;
                        }
                    });
                }
                chart.update();
                // }
            }
        }
    }, [oscXMin, oscXMax, oscXBias, oscYBias, oscYMin, oscYMax, oscXMin1, oscXMax1, oscXBias1, oscYBias1, oscYMin1, oscYMax1]);

    useEffect(() => {
        if (chart) {
            setAllAxes({});
            setAxes();

            if (view === 'mono') {
                chart.options.plugins.legend.display = true;
            }
            if (view === 'multy' || bundles) {
                chart.options.plugins.legend.display = false;
            }

            chart.update();
        }
    }, [view, chart, bundles]);

    useEffect(() => {
        if (chart && Object.keys(chartMarkers)?.length && series.length) {
            const key = sensors[0].num;
            const marks = chart.config.options.plugins.annotation.annotations;
            const markers = chartMarkers[bundles ? bundles : key];
            if (markers) {
                for (let key in markers) {
                    const { type, value, id, resizer, backgroundColor, xMin, xMax } = markers[key];
                    if (!marks[id]) {
                        if (type === 'line' && value && !resizer) {
                            const index = +id.replace('line', '');
                            // console.log(series);
                            setLineAnnotation({ chart, index, value: null, roundDigit, arr: series, theme, isDrag, setHoveredMarker, setChartMarkers, sensors, setEditMarker, bundles });
                        }

                        if (type === 'box' && xMin && xMax) {
                            setBoxAnnotation(id, xMin, xMax, backgroundColor);
                        }
                    }
                }
            }
        }
    }, [chartMarkers, chart, series.length]);

    useEffect(() => {
        if (!chanelPrefix && chanelPrefix !== '') {
            const num = sensors[0].num;
            if (num) {
                if (!isNaN(num)) {
                    setChannelPrefix('');
                } else {
                    if (num.charAt(0) === 'l') {
                        setChannelPrefix('(Лог)');
                    } else {
                        setChannelPrefix('(Имп)');
                    }
                }
            }
        }
    }, [sensors]);

    useEffect(() => {
        if (chart) {
            setAxes();
        }
    }, [chanelPrefix, chart, sensors.length]);

    useEffect(() => {
        if (chart) {
            chart.options.scales.xAxis.grid.borderColor = theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light;
            chart.options.scales.xAxis.grid.color = theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light;

            for (var axes in allAxes) {
                if (axes.charAt(0) === 'y') {
                    // console.log(axes);
                    chart.options.scales[axes].grid.borderColor = theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light;
                    chart.options.scales[axes].grid.color = theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light;
                }
            }

            // Markers
            const markers = chart.config.options.plugins.annotation.annotations;
            for (let key in markers) {
                const mark = markers[key];

                if (mark && mark.type === 'line' && !mark.resizer) {
                    mark.borderColor = theme === 'dark' ? chartColors.marker.dark.line : chartColors.marker.line;
                    mark.label.backgroundColor = theme === 'dark' ? chartColors.marker.dark.background : chartColors.marker.background;
                    mark.label.borderColor = theme === 'dark' ? chartColors.marker.dark.border : chartColors.marker.border;
                    mark.label.color = theme === 'dark' ? chartColors.marker.dark.color : chartColors.marker.color;
                }
            }
            chart.options.plugins.themeBackgroundColor.color = theme === 'dark' && '#282B33';
            chart.update();
        }
    }, [theme]);

    useEffect(() => {
        if (chart && styles) {
            sensors.forEach((sens, i) => {
                const { num } = sens;
                try {
                    if (chart.data.datasets[i] && styles && styles[num]) {
                        if (styles[num].color) {
                            chart.options.scales.xAxis.title.color = styles[num].color;
                            chart.data.datasets[i].borderColor = styles[num].color;
                            for (var axes in allAxes) {
                                if (axes.charAt(0) === 'y') {
                                    chart.options.scales[axes].title.color = styles[num].color;
                                }
                            }
                        }
                        if (styles[num].point) {
                            chart.data.datasets[i].pointRadius = styles[num].point.weight;
                            chart.data.datasets[i].pointBackgroundColor = styles[num].point.color;
                        }
                        if (styles[num].weight) {
                            chart.data.datasets[i].borderWidth = styles[num].weight;
                        }
                        if (styles[num].type) {
                            setChartType(chart, styles[num], i);
                        }
                        if (styles[num].interpolation) {
                            // styles[num].interpolation
                            switch (styles[num].interpolation) {
                                case 'linear':
                                    chart.data.datasets[i].stepped = false;
                                    chart.data.datasets[i].cubicInterpolationMode = 'default';
                                    chart.data.datasets[i].tension = 0;
                                    break;

                                case 'natural':
                                    chart.data.datasets[i].stepped = false;
                                    chart.data.datasets[i].cubicInterpolationMode = 'default';
                                    chart.data.datasets[i].tension = 0.1;
                                    break;

                                case 'basis':
                                    chart.data.datasets[i].stepped = false;
                                    chart.data.datasets[i].cubicInterpolationMode = 'default';
                                    chart.data.datasets[i].tension = 0.3;
                                    break;

                                case 'bundle':
                                    chart.data.datasets[i].stepped = false;
                                    chart.data.datasets[i].cubicInterpolationMode = 'default';
                                    chart.data.datasets[i].tension = 0.4;
                                    break;

                                case 'cardinal':
                                    chart.data.datasets[i].stepped = false;
                                    chart.data.datasets[i].tension = 0.6;
                                    chart.data.datasets[i].cubicInterpolationMode = 'default';
                                    break;

                                case 'catmull':
                                    chart.data.datasets[i].stepped = false;
                                    chart.data.datasets[i].tension = 0.8;
                                    chart.data.datasets[i].cubicInterpolationMode = 'default';
                                    break;

                                case 'step':
                                    chart.data.datasets[i].stepped = 'middle';
                                    break;

                                case 'stepbefore':
                                    chart.data.datasets[i].stepped = 'before';
                                    break;

                                case 'stepafter':
                                    chart.data.datasets[i].stepped = 'after';
                                    break;

                                case 'monotone':
                                    chart.data.datasets[i].stepped = false;
                                    chart.data.datasets[i].cubicInterpolationMode = 'monotone';
                                    break;

                                default:
                                    chart.data.datasets[i].stepped = false;
                                    chart.data.datasets[i].cubicInterpolationMode = 'default';
                                    chart.data.datasets[i].tension = 0.3;
                                    break;
                            }
                        }

                        chart.update();
                    }
                } catch (e) {
                    console.log(e);
                }
            });
            setAxes();
            setChartSeries();
        }
    }, [chartStyle]);

    useEffect(() => {
        if (!port) {
            removeAllMarker();
        }
    }, [port]);

    useEffect(() => {
        if (chartRef.current) {
            setChart(chartRef.current);
        }
    }, [chartRef]);

    useEffect(() => {
        if (chart) {
            if (!Object.keys(allAxes).length) {
                setAxes();
            }
            chart.options.scales = allAxes;
            setChartSeries();
            chart?.update();
        }
    }, [allAxes, chart]);

    useEffect(() => {
        chart && setChartSeries();
    }, [data, chart]);

    useEffect(() => {
        if (deleteAllMarkers) {
            removeAllMarker();
        }
    }, [deleteAllMarkers]);

    useEffect(() => {
        if (chart && !isOscilloscope) {
            if (!isChartArea) {
                chart.options.plugins.zoom.pan.scaleMode = 'xy';
                chart.options.plugins.zoom.zoom.scaleMode = 'xy';
            } else {
                chart.options.plugins.zoom.pan.scaleMode = null;
                chart.options.plugins.zoom.zoom.scaleMode = null;
            }
            chart.options.onHover = (evt) => setHoverEvent(evt, theme);
        }
    }, [isChartArea, chart, theme]);

    useEffect(() => {
        if (chart) {
            chart.update();
        }
    }, [options, chart]);

    useEffect(() => {
        if (chart) {
            if (dragging && draggingMarker && !hoveredMarker) {
                chart.options.plugins.zoom.pan.enabled = false;
                setChartMarkerAdd(false);
            } else {
                chart.options.plugins.zoom.pan.enabled = true;
            }
            chart.update();
        }
    }, [dragging, draggingMarker]);

    useEffect(() => {
        if (isOscilloscope) {
            setSliceLen(0);
        } else {
            setSliceLen(-50);
        }
    }, [isOscilloscope]);

    useEffect(() => {
        if (chart && series?.length && series[0]?.data?.length) {
            if (isOscilloscope) {
                chart.options.scales.xAxis.min = oscXMin;
                chart.options.scales.xAxis.max = oscXMax;

                if (view === 'mono') {
                    chart.options.scales.yAxis.min = oscYMin;
                    chart.options.scales.yAxis.max = oscYMax;
                } else {
                    let i = 0;
                    Object.keys(allAxes).forEach((axes) => {
                        if (axes.charAt(0) === 'y') {
                            const { yMin, yMax } = oscilloChannels[i];
                            chart.options.scales[axes].min = yMin;
                            chart.options.scales[axes].max = yMax;
                            i++;
                        }
                    });
                }
            } else {
                const elData = series[0].data;
                const min = elData[0].x;
                const max = elData[elData.length - 1].x;
                if (chart.scales.xAxis) {
                    if (played && !paused) {
                        chart.options.scales.xAxis.min = min;
                        chart.options.scales.xAxis.max = max + 300;
                    } else {
                        if (elData.length && elData.length > 5) {
                            chart.options.scales.xAxis.min = elData.length && elData.length > 50 ? elData.slice(sliceLen)[0].x : min;
                            chart.options.scales.xAxis.max = max + 300;
                        }
                    }
                }
            }
            chart.update();
        }
    }, [series, played, paused]);

    useEffect(() => {
        if (chart) {
            if (played && !paused) {
                chart.options.scales.xAxis.min = null;
                chart.options.scales.xAxis.max = null;
                setXstate(false);
                setYstate(false);
                chart.options.plugins.zoom.zoom.mode = '';
                chart.options.plugins.zoom.pan.mode = '';
            } else {
                chart.options.plugins.zoom.zoom.wheel.enabled = true;
                chart.options.plugins.zoom.pan.enabled = true;
                chart.options.plugins.zoom.zoom.mode = 'xy';
                chart.options.plugins.zoom.pan.mode = 'xy';
                setXstate(true);
                setYstate(true);
            }
            if (paused) {
                setAxes();
                setChartSeries();
            }
            if (played) {
                toggleMiniChart(false);
            }
            chart.update();
        }
    }, [played, paused]);

    useEffect(() => {
        if (chart && played) {
            setSeries([]);
            setXMin(0);
            chart.data.datasets = [];
            chart.options.plugins.annotation.annotations = {};
            chart.options.plugins.zoom.zoom.wheel.enabled = false;
            chart.options.plugins.zoom.pan.enabled = false;
            updateMarkers({});
            chart.update();
        }
    }, [played]);

    const updateMarkers = (markers) => {
        if (bundles) {
            setChartMarkers({ num: bundles, data: markers });
        } else {
            for (const item of sensors) {
                const { num } = item;
                // console.log(num);
                setChartMarkers({ num: bundles ? bundles : num, data: markers });
            }
        }

        chart?.update();
    };

    const setBoxAnnotation = (key, min, max, color) => {
        // if (data[0].value.length <= 1) return;
        const markers = chart.config.options.plugins.annotation.annotations;
        const start = key + 'start';
        const end = key + 'end';
        markers[start] = {
            type: 'line',
            resizer: true,
            borderWidth: 5,
            borderColor: 'transparent',
            scaleID: 'xAxis',
            value: min,
            draggable: true,
            drawTime: 'afterDraw',
            enter: function (context, event) {
                document.body.style.cursor = 'col-resize';
                const { id } = context;
                setHoveredMarker(id);
            },
            leave: function (context, event) {
                document.body.style.cursor = '';
                setHoveredMarker();
            },
            onDragStart: function (event, value) {
                chart.options.plugins.zoom.pan.enabled = false;
            },
            onDrag: function (event, value) {
                const min = series[0].data[0].x;
                const boxMax = chart.options.plugins.annotation.annotations[key].xMax - 500;
                const {
                    subject: {
                        config: { id },
                    },
                } = event;
                chart.options.plugins.zoom.pan.enabled = false;
                if (value < min) {
                    chart.options.plugins.annotation.annotations[id].value = min;
                } else if (value > boxMax) {
                    chart.options.plugins.annotation.annotations[id].value = boxMax;
                } else {
                    chart.options.plugins.annotation.annotations[key].xMin = value < min ? min : value;
                }
            },
            onDragEnd: function (event, value) {
                chart.options.plugins.zoom.pan.enabled = true;
                updateMarkers(chart.config.options.plugins.annotation.annotations);
            },
        };
        markers[key] = {
            type: 'box',
            id: key,
            xScaleID: 'xAxis',
            yScaleID: firstAxis,
            backgroundColor: color,
            borderWidth: 0,
            xMax: max ? max : data[0][data[0].length - 1],
            xMin: min ? min : 0,
            enter: (context, event) => {
                if (!isDrag) {
                    setEditMarker(key);
                    if (isAndroid) return;
                    setDraggingMarker(key);
                }
            },
            leave: (context, event) => {
                if (!isDrag) {
                    setDraggingMarker();
                }
            },
        };
        markers[end] = {
            type: 'line',
            borderWidth: 5,
            resizer: true,
            borderColor: 'transparent',
            scaleID: 'xAxis',
            value: max,
            draggable: true,
            drawTime: 'afterDraw',
            enter: function (context, event) {
                document.body.style.cursor = 'col-resize';
                const { id } = context;
                setHoveredMarker(id);
            },
            leave: function (context, event) {
                document.body.style.cursor = 'default';
                setHoveredMarker();
            },
            onDragStart: function (event, value) {
                // const { subject: {config: {id}} } = event;
                chart.options.plugins.zoom.pan.enabled = false;
            },
            onDrag: function (event, value) {
                const { data } = series[0];
                const max = data[data.length - 1].x;
                const boxMin = chart.options.plugins.annotation.annotations[key].xMin + 0.5;
                const {
                    subject: {
                        config: { id },
                    },
                } = event;
                chart.options.plugins.zoom.pan.enabled = false;
                if (value > max) {
                    chart.options.plugins.annotation.annotations[id].value = max;
                } else if (value < boxMin) {
                    chart.options.plugins.annotation.annotations[id].value = boxMin;
                } else {
                    chart.options.plugins.annotation.annotations[key].xMax = value > max ? max : value;
                }
            },
            onDragEnd: function (event, value) {
                chart.options.plugins.zoom.pan.enabled = true;
                updateMarkers(chart.config.options.plugins.annotation.annotations);
            },
        };
        chart.options.plugins.annotation.annotations = markers;
        updateMarkers(chart.config.options.plugins.annotation.annotations);
    };

    const addRemoveMarker = (event, key) => {
        // console.log(series);
        if (series[0]?.data[data.length - 1].x === 0) {
            return;
        }
        if (panned) {
            setPanned(false);
            return;
        }
        // console.log(key);
        const markers = chart.config.options.plugins.annotation.annotations;
        if (markerActions.del && key) {
            if (markers[key] && markers[key].type === 'line' && !markers[key].resizer) {
                delete markers[key];
                setHoveredMarker();
            }
        }
        if (markerActions.add) {
            const points = chart.getElementsAtEventForMode(event, 'index', { intersect: false }, true);
            const i = points[0].index;
            if (markerMode === 'arbitrary' || markerMode === 'cursor') {
                setLineAnnotation({ chart, index: i, value: null, roundDigit, arr: series, theme, isDrag, setHoveredMarker, setChartMarkers, sensors, setEditMarker, bundles });
            }
        }
        chart.options.plugins.annotation.annotations = markers;
        updateMarkers(chart.config.options.plugins.annotation.annotations);
    };

    const removeAllMarker = () => {
        if (chart) {
            setDraggingMarker();
            setDragging();
            chart.options.plugins.annotation.annotations = {};
            updateMarkers({});
        }
    };

    const setHoverEvent = (evt, theme) => {
        if (!chart) return;
        if (played && !paused) return;
        // console.log(series);
        if (!series.length) return;
        // console.log(evt);

        const markers = chart.config.options.plugins.annotation.annotations;

        const canvasPosition = getRelativePosition(evt, chart);
        const dataX = chart.scales.xAxis.getValueForPixel(canvasPosition.x);
        const dataY = chart.scales[firstAxis].getValueForPixel(canvasPosition.y);

        setHoverTime(+dataX.toFixed());
        setHoverValue(dataY.toFixed(roundDigit));

        if (markerMode === 'cursor') {
            const xLine = markers['xLine'];
            const yLine = markers['yLine'];

            if (xLine) {
                xLine.display = true;
                xLine.value = dataX;
                xLine.label.content = dataX.toFixed(2);
            }
            if (yLine) {
                yLine.display = true;
                yLine.value = dataY;
                yLine.label.content = dataY.toFixed(3);
            }
            chart.update();
        }

        setLabelX((dataX / 1000).toFixed(3));
        setLabelY(dataY.toFixed(3));

        if (markers['hovered']) {
            markers['hovered'].xMin = dataX - 1000;
            markers['hovered'].xMax = dataX + 1000;
        }

        switch (markerMode) {
            case 'cursor':
                if (!markers.xLine) {
                    showCursorLine(chart, theme, cursorYAxisId);
                }
                break;
            case 'range1':
                if (!markers.box1 && !markerActions.del) {
                    showBoxOnHoverChart(chart, evt, 'box1', dataX, chartColors.boxes.chart.box1, series, setBoxAnnotation, true);
                }
                break;
            case 'range2':
                if (!markers.box2 && !markerActions.del) {
                    showBoxOnHoverChart(chart, evt, 'box2', dataX, chartColors.boxes.chart.box2, series, setBoxAnnotation, true);
                }
                break;
            case 'range3':
                if (!markers.box3 && !markerActions.del) {
                    showBoxOnHoverChart(chart, evt, 'box3', dataX, chartColors.boxes.chart.box3, series, setBoxAnnotation, true);
                }
                break;
            case 'range4':
                if (!markers.box4 && !markerActions.del) {
                    showBoxOnHoverChart(chart, evt, 'box4', dataX, chartColors.boxes.chart.box4, series, setBoxAnnotation, true);
                }
                break;
            default:
        }

        if (dragging) {
            draggingBox(evt, draggingMarker);
        }
    };

    const draggingBox = (e, key) => {
        if (dragging && key) {
            const { data } = series[0];
            // console.log(data[0]);
            const min = data[0].x;
            const max = data[data.length - 1].x;
            const markers = chart.config.options.plugins.annotation.annotations;
            const canvasPosition = getRelativePosition(e, chart);
            const dataX = chart.scales.xAxis.getValueForPixel(canvasPosition.x);

            if (markers[key].type === 'box') {
                const offset = (markers[key].xMax - markers[key].xMin) / 2;
                const start = key + 'start';
                const end = key + 'end';
                const minValue = dataX - offset < min ? min : dataX - offset;
                const maxValue = dataX + offset > max ? max : dataX + offset;

                markers[start].value = minValue;
                markers[key].xMin = minValue;
                markers[key].xMax = maxValue;
                markers[end].value = maxValue;

                chart.options.plugins.annotation.annotations = markers;
                chart.update();
                updateMarkers(chart.config.options.plugins.annotation.annotations);
            }
            // console.log(isChartArea);
            if (!isChartArea) {
                console.log(e, dataX);
                isDrag = false;
                setDragging(false);
            }
        } else {
        }
    };

    const setLeaveEvent = () => {
        if (played && !paused) return;
        const markers = chart.config.options.plugins.annotation.annotations;
        setTimeout(function () {
            try {
                delete markers['hovered'];
                delete markers['xLine'];
                delete markers['yLine'];
            } catch (e) {
                console.log(e.message);
            } finally {
                document.body.style.cursor = 'default';
                chart.options.plugins.annotation.annotations = markers;
                chart.update();
            }
        }, 100);
    };

    const changeCoursor = (key) => {
        if (played && !paused) return;
        const markers = chart.config.options.plugins.annotation.annotations;
        if (!dragging) {
            if (key && markers[key]) {
                if (markerActions.del && !dragging) {
                    if (markers[key].type === 'line') {
                        if (markers[key].resizer) {
                            document.body.style.cursor = 'col-resize';
                        } else {
                            document.body.style.cursor = 'no-drop';
                        }
                    }
                } else {
                    if (markers[key].type === 'line') document.body.style.cursor = 'col-resize';
                }
            } else {
                document.body.style.cursor = 'default';
            }
        }
        if (draggingMarker) {
            if (rangeActions.del) {
                document.body.style.cursor = 'no-drop';
            } else {
                document.body.style.cursor = 'grab';
            }
            if (dragging) {
                document.body.style.cursor = 'grabbing';
            }
        }
    };

    const setLabeles = useCallback(() => {
        if ((!played || paused) && series.length) {
            return (
                <div className={ss.data}>
                    <div>Время: {labelX} c</div>
                    {sensors.map((item, i) => (
                        <div key={'label' + i}>
                            {item.name}: {labelY} {item.unit} ${chanelPrefix}
                        </div>
                    ))}
                </div>
            );
        }
    }, [labelX, labelY, played, paused]);

    const setTitles = useCallback(() => {
        let titles = '';
        const preffix = chanelPrefix ? chanelPrefix : '';
        sensors.forEach((item, i) => {
            const { name, unit, num } = item;
            const abciss = abscissa[num]?.label.split('(')[1].replace(')', '') || '';
            const currentColor = styles[num] && styles[num].color ? styles[num].color : defaultColors[i].val;
            const disabled = !bundles && chartDisabled.filter((ch) => ch === item.num);
            if (!disabled.length) {
                titles += `<span style="color: ${currentColor}">${name} ${unit} ${preffix} ${abciss}</span>&nbsp;&nbsp;&nbsp;`;
            }
            // console.log(name);
        });
        return titles;
    }, [sensors.length, chartDisabled, chartStyle, bundles]);

    const clickMarkersEvents = (e) => {
        if (markerActions?.add || markerActions?.del) {
            addRemoveMarker(e, hoveredMarker);
        }
        if (draggingMarker && rangeActions?.del) {
            deleteBox(chart, draggingMarker);
            chart.update();
            setDraggingMarker(null);
            updateMarkers(chart.config.options.plugins.annotation.annotations);
        }
        isDrag = false;
    };

    const renderTitlesFromMonoAxe = () => {
        // console.log(sensors);
        if (bundlesTitle) {
            return (
                <div className={ss.legend}>
                    <span>{bundlesTitle}</span>
                </div>
            );
        } else {
            sensors.forEach((item) => {
                const { name, num, imported } = item;
                const abciss = abscissa[num]?.label.split('(')[1].replace(')', '') || '';
                const disabled = !bundles && chartDisabled.filter((ch) => ch === item.num)[0];
                let preffix = typeof num === 'string' ? (num.includes('loc') || num.includes('imp') || imported ? 'Лог.' : 'Имп.') : '';
                if (disabled) {
                    return false;
                } else {
                    return (
                        <div
                            key={num}
                            className={ss.legend}
                        >
                            <div
                                className={ss.color}
                                style={{ background: styles[num]?.color ? styles[num].color : item.color }}
                            ></div>
                            <span>
                                {name} {preffix} {abciss}
                            </span>
                        </div>
                    );
                }
            });
        }
    };

    const handleCheckX = (e) => {
        const { checked } = e.target;
        const zoom = chart.options.plugins.zoom;
        if (!checked) {
            if (zoom.zoom.mode === 'xy') {
                zoom.zoom.mode = 'y';
            } else {
                zoom.zoom.mode = '';
                played && chart?.resetZoom();
            }
            if (zoom.pan.mode === 'xy') {
                zoom.pan.mode = 'y';
            } else {
                zoom.pan.mode = '';
                played && chart?.resetZoom();
            }
        } else {
            if (zoom.zoom.mode === 'y') {
                zoom.zoom.mode = 'xy';
            } else {
                zoom.zoom.mode = 'x';
            }
            if (zoom.pan.mode === 'y') {
                zoom.pan.mode = 'xy';
            } else {
                zoom.pan.mode = 'x';
            }
        }
        chart?.update();
    };

    const handleCheckY = (e) => {
        const { checked } = e.target;
        const zoom = chart.options.plugins.zoom;
        if (!checked) {
            if (zoom.zoom.mode === 'xy') {
                zoom.zoom.mode = 'x';
            } else {
                zoom.zoom.mode = '';
                played && chart?.resetZoom();
            }
            if (zoom.pan.mode === 'xy') {
                zoom.pan.mode = 'x';
            } else {
                zoom.pan.mode = '';
                played && chart?.resetZoom();
            }
        } else {
            if (zoom.zoom.mode === 'x') {
                zoom.zoom.mode = 'xy';
            } else {
                zoom.zoom.mode = 'y';
            }
            if (zoom.pan.mode === 'x') {
                zoom.pan.mode = 'xy';
            } else {
                zoom.pan.mode = 'y';
            }
        }
        chart?.update();
    };

    const renderEditMarkerModalContent = (num) => {
        // console.log(series[0].data);
        return (
            <div className={ss.editMarker}>
                <Modal.Head
                    modalClose={() => setEditMarker(false)}
                    title={'Редактировать ' + editMarker?.includes('box') ? 'диапазон' : 'маркер'}
                ></Modal.Head>
                <Modal.Body>
                    {Object.values(chartMarkers[num]).map((marker) => {
                        // const keys = Object.keys(marker);
                        // if (keys !== undefined && keys.length && keys.includes(editMarker)) {
                        if (marker.type === 'box' && marker.id === editMarker) {
                            // console.log(marker);
                            const { xMin, xMax } = marker;
                            // console.log(marker);
                            return (
                                <div className={ss.editMarkerFields}>
                                    <div className={ss.editMarkerField}>
                                        <div className={ss.editMarkerFabel}>Мин. {Math.round(xMin)} мс.</div>
                                        <Range
                                            min={series[0]?.data[0]?.x}
                                            max={xMax}
                                            value={xMin}
                                            onChange={({ target }) => {
                                                const { value } = target;
                                                const updMarker = { ...chartMarkers[num] };
                                                const start = editMarker + 'start';
                                                updMarker[editMarker].xMin = value;
                                                updMarker[start].value = value;
                                                // console.log(e);
                                                if (bundles) {
                                                    setChartMarkers({ num: bundles, data: updMarker });
                                                } else {
                                                    series.forEach(({ num }) => {
                                                        setChartMarkers({ num, data: updMarker });
                                                    });
                                                }
                                                setChartMarkerAdd(false);
                                                setChartMarkerDelete(false);
                                                chart?.update();
                                            }}
                                        />
                                    </div>
                                    <div className={ss.editMarkerField}>
                                        <div className={ss.editMarkerLabel}>Макс. {Math.round(xMax)} мс.</div>
                                        <Range
                                            min={xMin}
                                            max={series[0]?.data[series[0]?.data?.length - 1]?.x}
                                            value={xMax}
                                            onChange={({ target }) => {
                                                const { value } = target;
                                                const updMarker = { ...chartMarkers[num] };
                                                const end = editMarker + 'end';
                                                updMarker[editMarker].xMax = value;
                                                updMarker[end].value = value;
                                                if (bundles) {
                                                    setChartMarkers({ num: bundles, data: updMarker });
                                                } else {
                                                    series.forEach(({ num }) => {
                                                        setChartMarkers({ num, data: updMarker });
                                                    });
                                                }
                                                setChartMarkerAdd(false);
                                                setChartMarkerDelete(false);
                                                chart?.update();
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        }
                        if (marker.type === 'line' && marker.id === editMarker) {
                            const { value } = marker;
                            const ser = series[0]?.data;
                            // console.log(ser);
                            if (!ser) return;
                            return (
                                <div className={ss.editMarkerFields}>
                                    <div className={ss.editMarkerField}>
                                        <div className={ss.editMarkerLabel}>Текущеее значение {Math.round(value)} мс.</div>
                                        <Range
                                            min={ser[0]?.x}
                                            max={ser[ser?.length - 1]?.x}
                                            value={value}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                const updMarker = { ...chartMarkers[num] };
                                                // console.log(value);
                                                const xArr = ser.map((item) => item.x);
                                                const closest = xArr.sort((a, b) => Math.abs(value - a) - Math.abs(value - b))[0];
                                                // let index;
                                                // ser.forEach((item, i) => {
                                                //     if (item.x === closest) {
                                                //         // console.log(item.x, i);
                                                //         index = i;
                                                //     }
                                                // });
                                                updMarker[editMarker].value = closest;
                                                updMarker[editMarker].label.content = (closest / 1000).toFixed(3);
                                                if (bundles) {
                                                    setChartMarkers({ num: bundles, data: updMarker });
                                                } else {
                                                    series.forEach(({ num }) => {
                                                        setChartMarkers({ num, data: updMarker });
                                                    });
                                                }
                                                setChartMarkerAdd(false);
                                                setChartMarkerDelete(false);
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        }
                        // }
                    })}
                </Modal.Body>
            </div>
        );
    };

    return (
        <>
            <div className={ss.root}>
                <div className={ss.head}>
                    <div className={ss.left}>{renderTitlesFromMonoAxe()}</div>
                    <div className={ss.action}>
                        {!isAndroid && (
                            <>
                                <Icon
                                    tag="button"
                                    name="point"
                                    className={handlePointMode && ss.iconActive}
                                    title="Режим ручного снятия параметров"
                                    disabled={played || paused}
                                    onClick={() => setHandlePointMode(!handlePointMode)}
                                />
                                {!bundles && (
                                    <Icon
                                        name="broken"
                                        title="Параметрические оси"
                                        onClick={() =>
                                            toggleChartParametric([
                                                { name: '', num: '' },
                                                { name: '', num: '' },
                                            ])
                                        }
                                    />
                                )}
                                <Icon
                                    tag="button"
                                    name="zoom"
                                    className={isZoom && ss.iconActive}
                                    title="Масштабирование фрагмента. Нажать Shift"
                                    disabled={played}
                                    // onClick={() => setZoom(!isZoom)}
                                />
                                <Icon
                                    tag="button"
                                    name="chart-mob"
                                    className={showMiniChart && ss.iconActive}
                                    title="Просмотр фрагмента"
                                    disabled={played}
                                    onClick={() => toggleMiniChart(!showMiniChart)}
                                />
                            </>
                        )}
                        {bundles && <ChartComment num={bundles} />}
                        <Icon
                            name="capture"
                            title="Полный масштаб"
                            onClick={() => resetViewArea(chart, series)}
                        />
                    </div>
                </div>
                <div className={ss.wrap}>
                    <div
                        className={ss.wrap_multy}
                        id={bundles}
                        data-learn="9"
                        data-learn-border="9"
                        data-learn-position="center"
                    >
                        {setLabeles()}
                        {view === 'mono' && <div className={ss.titles}>{parse(setTitles())}</div>}
                        <Line
                            ref={chartRef}
                            options={options}
                            data={{ datasets: series }}
                            plugins={plugins}
                            onMouseDown={(e) => {
                                if (markerActions && !markerActions.add) {
                                    setDragging(true);
                                    isDrag = true;
                                }
                            }}
                            onMouseUp={(e) => {
                                setDragging(false);
                                isDrag = false;
                            }}
                            onMouseEnter={(e) => {
                                // togleChartArea(true);
                            }}
                            onMouseOut={(e) => {
                                setLeaveEvent();
                                // togleChartArea(false);
                            }}
                            onMouseMove={(e) => {
                                changeCoursor(hoveredMarker);
                                if (dragging) {
                                    draggingBox(e, draggingMarker);
                                }
                            }}
                            onClick={(e) => clickMarkersEvents(e)}
                            onTouchEnd={(e) => clickMarkersEvents(e)}
                        />
                        {(!played || paused) && legendMode && isChartArea && (
                            <div className={ss.tootltip}>
                                <div className={ss.tootltipRow}>
                                    <label>Время</label> <span>{(hoverTime / 1000).toFixed(2)}c</span>
                                </div>
                                {sensors.map((s) => {
                                    const { name, unit, num } = s;
                                    const data = series.filter((ser) => ser.num === num)[0];
                                    const val = data.data.filter((d) => hoverTime + 100 >= d.x && hoverTime - 100 <= d.x)[0];
                                    // console.log(data);
                                    // console.log(val);
                                    return (
                                        <div className={ss.tootltipRow}>
                                            <label>{name}</label>{' '}
                                            <span>
                                                {val?.y || val?.y === 0 ? val.y : ''}
                                                {unit}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    {showMiniChart && (
                        <ChartMini
                            chart={chart}
                            played={played}
                            paused={paused}
                            series={series[0]?.data}
                            theme={theme}
                        />
                    )}
                </div>
                {!isOscilloscope && (
                    <div className={ss.foot}>
                        <label title="Масштабирование по оси Х">
                            <span>X</span>
                            <Switcher
                                checked={xState}
                                onChange={handleCheckX}
                            />
                        </label>
                        <label title="Масштабирование по оси Х">
                            <span>Y</span>
                            <Switcher
                                checked={yState}
                                onChange={handleCheckY}
                            />
                        </label>
                    </div>
                )}
            </div>
            {isAndroid && editMarker && !markerActions.add && !markerActions.del && (
                <Modal
                    visible={editMarker}
                    size={'xs'}
                >
                    {renderEditMarkerModalContent(series[0]?.num)}
                </Modal>
            )}
        </>
    );
};

export default ChartAreaMulty;
