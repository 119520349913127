import {
    showNameSettings,
    showUserSelect,
    showUserSettings
} from "../../../actions/actions";
import {addDevicesConnected, removeDevicesConnected, setDeviceConnectedCount} from "../../../rlab/src/actions/devices";
import {setSensor, setSensorImported, setSensorList, setSensorWritedList} from "../../../rlab/src/actions/sensors";
import {setScenarioId} from "../../../rlab/src/actions/scenario";
import {toggleSensor} from "../../../actions/toggle";
import {setSensors} from "../../../redux/slices/options";
import {getDevicesList, autoconnectReconnectSerialPort} from "../../../rlab/src/commands/device";
import {setScenarioList, setScenario, setScenarioInfo, setShowUnitInfo, setUnitInfo} from "../../../rlab/src/actions/scenario";
import {usbConnectReconnect} from "../../../rlab/src/commands/port";
import {btConnect} from "../../../rlab/src/commands/bluetooth";
import {setTheme, setMobile, setPlay, setCor} from '../../../rlab/src/actions/actions';
import {setChartMarkers, setChartView} from "../../../rlab/src/actions/chart";
import {setConnectionType, togglePort} from "../../../rlab/src/actions/port";
import {setSensorReading} from "../../../rlab/src/actions/reader";
import {resetReduxState} from "../../../rlab-neuro/src/actions/app";
import {autoConnectPort} from "../../../rlab-neuro/src/commands/port";

export const mapStateToProps = (state) => {
    return ({});
};
export const mapDispatchToProps = (dispatch) => ({});

export const mapRlabStateToProps = (state) => {
    return {
        port: state.port,
        portOpen: state.port.open,
        portData: state.port.data,
        connectionType: state.port.connectionType,
        devList: state.devices.list,
        isMobile: state.actions.isMobile,
        sensorWriter: state.writer.data,
        sensor: state.toggle.sensor,
        changeClass: state.cactions.changeClass,
        imported: state.sensors.imported,
        sensorList: state.sensors.list,
        scenarioList: state.scenario.list,
        scenarioId: state.scenario.scenarioId,
        loadScenario: state.scenario.load,
        scenarioInfo: state.scenario.scenarioInfo,
        showUnitInfo: state.scenario.showUnitInfo,
        unitInfo: state.scenario.unitInfo,
        storage: state.storage,
        options: state.storage.options,
        documentClass: state.storage.class,
        sensorStream: state.options.sensors,
        files: state.lists.files,
    };
};

export const mapRlabDispatchToProps = (dispatch) => ({
    toggleSensor: (bool) => dispatch(toggleSensor(bool)),
    setSensorStream: (bool) => dispatch(setSensors(bool)),
    setScenarioList: (obj) => dispatch(setScenarioList(obj)),
    setScenario: (obj) => dispatch(setScenario(obj)),
    setScenarioInfo: (info) => dispatch(setScenarioInfo(info)),
    setShowUnitInfo: (bool) => dispatch(setShowUnitInfo(bool)),
    setUnitInfo: (obj) => dispatch(setUnitInfo(obj)),
    addDevicesConnected: (devices) => dispatch(addDevicesConnected(devices)),
    removeDevicesConnected: (devices) => dispatch(removeDevicesConnected(devices)),
    setDeviceConnectedCount: (count) => dispatch(setDeviceConnectedCount(count)),
    setSensor: (obj) => dispatch(setSensor(obj)),
    setSensorImported: (obj) => dispatch(setSensorImported(obj)),
    setSensorWritedList: (obj) => dispatch(setSensorWritedList(obj)),
    setConnectionType: (type) => dispatch(setConnectionType(type)),
    togglePort: (bool) => dispatch(togglePort(bool)),
    setSensorReading: (reading) => dispatch(setSensorReading(reading)),
    setTheme: (theme) => dispatch(setTheme(theme)),
    setMobile: (bool) => dispatch(setMobile(bool)),
    setPlay: (bool) => dispatch(setPlay(bool)),
    setCor: (bool) => dispatch(setCor(bool)),
    setChartMarkers: (obj) => dispatch(setChartMarkers(obj)),
    setChartView: (str) => dispatch(setChartView(str)),
    setSensorList: (obj) => dispatch(setSensorList(obj)),
    autoconnectReconnectSerialPort: () => dispatch(autoconnectReconnectSerialPort()),
});

export const mapNeuroStateToProps = (nstate) => {
    return {
        devices: nstate.ndevice.all,
        port: nstate.nport.open,
    };
};

export const mapNeuroDispatchToProps = (dispatch) => ({
    resetReduxState: (item) => dispatch(resetReduxState(item)),
    autoConnectPort: () => dispatch(autoConnectPort()),
});

export const mapScenarioStateToProps = (state) => ({
    devicesAll: state.devices.all,
    sensors: state.sensors.records,
    sensor: state.sensors.item,
    connectionType: state.port.connectionType,
    played: state.actions.played,
    port: state.port.open,
});

export const mapScenarioDispatchToProps = (dispatch) => ({
    getDevicesList: () => dispatch(getDevicesList()),
    usbConnectReconnect: (fn) => dispatch(usbConnectReconnect(fn)),
    btConnect: (fn) => dispatch(btConnect(fn)),
    setSensorList: (fn) => dispatch(setSensorList(fn)),
    setScenarioId: (id) => dispatch(setScenarioId(id)),
});

export const mapStorageStateToProps = (state) => {
    return ({});
};
export const mapStorageDispatchToProps = (dispatch) => ({
    showNameSettings: (obj) => dispatch(showNameSettings(obj)),
});

export const mapOrgStateToProps = (state) => {
    return ({});
};
export const mapOrgDispatchToProps = (dispatch) => ({
    showUserSettings: (obj) => dispatch(showUserSettings(obj)),
});

export const mapMembersStateToProps = (state) => {
    return ({});
};
export const mapMembersDispatchToProps = (dispatch) => ({
    showUserSelect: (obj) => dispatch(showUserSelect(obj)),
    showUserSettings: (obj) => dispatch(showUserSettings(obj)),
});

export const mapProfileStateToProps = (state) => {
    return ({});
};
export const mapProfileDispatchToProps = (dispatch) => ({
    showUserSelect: (obj) => dispatch(showUserSelect(obj)),
    showUserSettings: (obj) => dispatch(showUserSettings(obj)),
});

