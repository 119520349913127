import React, {useState, useEffect} from "react";
import { ContentHead, Content, ContentBody, ContentFooter, ContentHeader } from "../template/ContentParts";
import {toast} from "react-toastify";
import {getDropdown, registerAddNewFile, registerDeleteFile} from "../ui/utils/gen_utils";
import PIOneObjectPropDlg from "./PIOneObjectPropDlg";
import { InputLimit, Tabs, Icon, Button, Input } from '../ui';
import {isAuth} from "../../helpers/auth";
import {METHOD, OBJECT_TAB, getUniqueTypes, getUniqueGroups, getObjTabs,
    uploadPIFile, deletePIFileByName, getSwitcherHasLabel, 
    loadObjectFileUrls, showObjProperty, getObjectView, getFileList} from "./pi_utils";
import {piApi} from "../../api/api";
import ImageDlg from "../ui/ModalDialogs/ImageDlg";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import {useDispatch, useSelector} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {loadPIObjects} from "../../redux/slices/lists";
import "../ui/ModalDialogs/PracticumTests.scss";
import "./PInvest.scss";

const NAME_LIMIT = 100;
const DESC_LIMIT = 200;

const PInvestObjectsOne = (props) => {
    const selectedObjectId = props.match.params.id ?? '-1';
	const [isTemplate, setIsTemplate] = useState(false);
	const [objName, setObjName] = useState("");
	const [objDescription, setObjDescription] = useState("");
	const [objProps, setObjProps] = useState([]);
    const [objFiles, setObjFiles] = useState([]);
    const [objVersion, setObjVersion] = useState([]);
    //
	const [objTypeName, setObjTypeName] = useState(""); //текущее значение типа
	const [objTypeMethod, setObjTypeMethod] = useState(METHOD.LIST);  //true - выбор type из списка, false - ввод нового type
	const [objTypeId, setObjTypeId] = useState(-1); //используется при выборе из списка
	const [uniqueObjectTypes, setUniqueObjectTypes] = useState([]); //список уникальных типов
    //
	const [objGroupName, setObjGroupName] = useState("");
	const [objGroupMethod, setObjGroupMethod] = useState(METHOD.LIST); 
	const [objGroupId, setObjGroupId] = useState(-1);  
	const [uniqueObjectGroups, setUniqueObjectGroups] = useState([]);
    //
	const [tabInd, setTabInd] = useState(OBJECT_TAB.PROPS);
	const [showObjectPropDlg, setShowObjectPropDlg] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isEditEnabled, setIsEditEnabled] = useState(false);
	const [showConfirmDlg, setShowConfirmDlg] = useState(false);
    const [loadedFileName, setLoadedFileName] = useState('');
    const [isFileRefresh, setIsFileRefresh] = useState(false); 
    const [uploadProgress, setUploadProgress] = useState(0);  //УДАЛИТЬ
	const [showImageDlg, setShowImageDlg] = useState(false);
	const [shownFile, setShownFile] = useState('');
	const [fileUrls, setFileUrls] = useState([]);
    //
	const documentClass = useSelector(storageClass);
	const {lists: {pi}} = useSelector(state => state);
	const dispatch = useDispatch();

    const saveSelectedObject = (obj, _isTemplate) => {
        setObjName(obj.name);
        setObjDescription(obj.desc);
        setObjGroupName(obj.group);
        setObjTypeName(obj.type);
        setObjProps(!obj.properties ? [] : obj.properties);
        setObjVersion(obj.__v);
        setIsEditEnabled(obj.owner || !_isTemplate || isAuth().role === 3);
        const _files = !_isTemplate ? 
            obj.files.map(item => ({name: item, isNew: false, isAdded: false, isDeleted: false})) : obj.files;
        setObjFiles(_files);
    };

	useEffect(() => {
        const readSelectedObject = async (_selectedObjectId) => {
            const obj = await piApi.getObjectById(_selectedObjectId);
            const _isTemplate = !obj.owner;
            setIsTemplate(_isTemplate);
            saveSelectedObject(obj, _isTemplate);
        };
        
        if (selectedObjectId === '-1') {
            setObjName('');
            setObjDescription('');
            setObjGroupName('');
            setObjTypeName('');
            setObjProps([]);
            setObjFiles([]);
            setIsEditEnabled(true);
            setIsEditMode(true);
        } else {
            readSelectedObject(selectedObjectId);
        }
    }, [selectedObjectId]);

    useEffect(() => {
        if (isTemplate) return;
        loadObjectFileUrls(objFiles, setFileUrls);
    }, [objFiles, isTemplate]);

    useEffect(() => {
        const readSelectedObject = async (_selectedObjectId) => {
            const obj = await piApi.getObjectById(_selectedObjectId);
            saveSelectedObject(obj, isTemplate);
        };
        
        if (!isFileRefresh) return;
        readSelectedObject(selectedObjectId);
        setIsFileRefresh(false);
    }, [dispatch, isFileRefresh, selectedObjectId, isTemplate]);

    useEffect(() => {
		if (!pi.objects) return;
        const types = getUniqueTypes(pi.objects);
        const groups = getUniqueGroups(pi.objects);
        setUniqueObjectTypes(types);
        setUniqueObjectGroups(groups);
    }, [pi.objects]);

    useEffect(() => {
        const objT = uniqueObjectTypes.find(item => item.label === objTypeName);
        if (objT) setObjTypeId(objT.value);

        const objG = uniqueObjectGroups.find(item => item.label === objGroupName);
        if (objG) setObjGroupId(objG.value);
    }, [uniqueObjectTypes, uniqueObjectGroups, objTypeName, objGroupName]);

    useEffect(() => {
		dispatch(loadPIObjects(documentClass._id));
	}, [dispatch, documentClass._id, objProps]);

    const handleShowImage = (file) => {
        if (!file) return;
        setShowImageDlg(true);
        setShownFile(file);
    };
    
    const handleDownloadFile = () => {
        toast.success("Файл загружен.");
    };
    
    const handleSaveObject = () => {
        const checkData = () => {
            let warning = undefined;
            if (!objName.trim()) warning = 'Введите название объекта.';
            else if (!objTypeName.trim()) warning = 'Выберите или введите раздел.';
            else if (!objGroupName.trim()) warning = 'Выберите или введите подраздел.';
            if (warning) toast.warn(warning);
            return !warning;
        };

        const addObject = async (object) => {
            piApi.addObject(object)
            .then((result) => {
                props.history.push("/pi/obj/" + result._id);
                toast.success("Объект '" + objName + "' добавлен.");
            }).catch((err) => {
                toast.error(err.response.data.errors);
            });
        };
        const updateObject = async (object, _selectedObjectId) => {
            piApi.updateObjectById(_selectedObjectId, object)
            .then((result) => {
                saveSelectedObject(result, isTemplate);
                toast.success("Объект '" + objName + "' сохранен.");
            })
            .catch((err) => {
                toast.error(err.response.data.errors);
            });
        };

        if (!checkData()) return;

        const oFiles = [];
        for (let i = 0; i < objFiles.length; i++) {
            const f = objFiles[i];

            if (f.isDeleted) {
                if (!isTemplate)
                    deletePIFileByName(dispatch, f.name);
            } else {
                if (!isTemplate)
                    oFiles.push(f.name);
                else
                    oFiles.push(f);
            }
        }
    
        const obj = {
            name: objName.trim(),
            desc: objDescription.trim(),
            type: objTypeName.trim(),
            group: objGroupName.trim(),
            properties: objProps,
            files: oFiles
        };

        if (isAuth().role < 3) {
            obj.room = documentClass._id;
        }

        if (selectedObjectId === '-1') {
            addObject(obj);
        } else {
            obj.__v = objVersion;
            updateObject(obj, selectedObjectId);
        }
    };

    const handleCancelObject = async (back = false) => {
        if (isEditMode && !isTemplate) {
            for (const item of objFiles) {
                if (item.isAdded) {
                    await deletePIFileByName(dispatch, item.name);
                }
            }
        }

        if (back || selectedObjectId === '-1') {
            props.history.push("/pi/obj");
        } else {
            setIsFileRefresh(true);
            setIsEditMode(false); //переключаем режим
        }
    };

    const handleObjectEdit = () => {
		if (isAuth().role === 0)
			toast.warn('Только преподаватель может редактировать объект.');
		else {
            if (isEditEnabled) 
                setIsEditMode(true);
        }
    };

    const handleDeleteObject = async () => {
        const deleteObject = async () => {
            piApi.deleteObjectById(selectedObjectId)
            .then((result) => {
            }).catch((err) => {
                toast.error(err.response.data.errors);
            });
        };

        setShowConfirmDlg(false);

        //Delete all attachments
        if (!isTemplate) {
            for (const item of objFiles) {
                await deletePIFileByName(dispatch, item.name);
            }
        }

        deleteObject();
        toast.success("Объект удален.");
        props.history.push("/pi/obj");
    };

    const handleAddProp = (propNameMethod, propNameId, propName, propValue) => {
        const addProperty = async (piProperty, newObjProp) => {
            piApi.addProperty(piProperty)
                .then((result)=>{
                    const op = [...objProps];
                    const _newObjProp = {...newObjProp, id: result._id};
                    op.push(_newObjProp);
                    setObjProps(op);
            }).catch((err) => {
                toast.error(err.response.data.errors);
            });
        };

        const found = objProps.find(item => item.id === propNameId || item.propName === propName);
        if (found) {
            toast.warn("Данное свойство уже было добавлено.");
            return;
        }

        const newObjProp = {
            id: propNameId,
            value: propValue,
        };

        if (propNameMethod === METHOD.MANUAL) {
            const piProperty = {
                name: propName,
                room: documentClass._id,
            };  
            addProperty(piProperty, newObjProp);
        } else {
            const op = [...objProps];
            op.push(newObjProp);
            setObjProps(op);
        }
    };

    const handleDeleteProp = (propInd) => {
        const propsBefore = [...objProps.slice(0, propInd)];
        const propsAfter =[...objProps.slice(propInd+1)];
        const newProps = [...propsBefore, ...propsAfter];
        setObjProps(newProps);
    };

    const handleChangeName = (value) => {
        if (value.length > NAME_LIMIT) return;
        setObjName(value);
    };
    const handleChangeDesc = (value) => {
        if (value.length > DESC_LIMIT) return;
        setObjDescription(value);
    };
    const handleTypeMethod = (value) => {
        setObjTypeMethod(value); //reset method
        setObjTypeName(''); //initialize
    };
    const handleObjTypeById = (id) => {
        setObjTypeId(id);
        setObjTypeName(uniqueObjectTypes.find(item => item.value === id).label);
    };
    const handleGroupMethod = (value) => {
        setObjGroupMethod(value); //reset method
        setObjGroupName(''); //initialize
    };
    const handleObjGroupById = (id) => {
        setObjGroupId(id);
        setObjGroupName(uniqueObjectGroups.find(item => item.value === id).label);
    };

    const handleOpenAddPropDlg = () => {
        if (selectedObjectId === '-1')
            toast.warn("Сохраните объект и затем добавьте его свойства.");
        else
            setShowObjectPropDlg(true);
    };

    //step 1 of 2: get file
    const handleAddFile = (event) => {
        if (!isTemplate) {
            uploadPIFile(event.target.files[0], setLoadedFileName);
        } else {
            setLoadedFileName(event.target.files[0]); //keep the file name and content
        }
        event.target.value = '';
    };
    //step 2 of 2: save file name (SA) or file (teacher)
    useEffect(() => {
        if (!loadedFileName) return;
        if (!isTemplate) {
            registerAddNewFile(loadedFileName, objFiles, setObjFiles);
            setLoadedFileName('');
            setUploadProgress(0);
        } else {
            const reader = new FileReader();
            reader.onload = (event) => {
                //DB will keep the file data: name and content
                const fileOpts = {
                    name: loadedFileName.name, 
                    isImage: tabInd === OBJECT_TAB.IMAGES, 
                    content: event.target.result
                };

                setLoadedFileName('');
                const fileData = JSON.stringify(fileOpts);
                const arr = [...objFiles];
                arr.push(fileData);
                setObjFiles(arr);
            };
            reader.readAsDataURL(loadedFileName); //if isTemplate the param contains the file (not just its name)
        }
    }, [objFiles, loadedFileName, tabInd, isTemplate]);

    const handleDeleteFile = (index, shortFileName) => {
        if (!isTemplate)
            registerDeleteFile(shortFileName, objFiles, setObjFiles, dispatch, deletePIFileByName);
        else {
            const arr = objFiles.filter((item, ind) => ind !== index);
            setObjFiles(arr);
        }
    };

    const getObjectEdit = () => {
        return (
            <div key={'nn01'} className="cor-net mt_lg">

                <div className="cor-net__section">
                    <div className="cor-net__row">
                        <div className="cor-net__col col-grow">
                            <div className="cor-net__label">Название объекта</div>
                            <InputLimit 
                                value={objName} 
                                max={NAME_LIMIT}
                                placeholder={'Введите название объекта'}
                                onInput={e => handleChangeName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="cor-net__row">
                        <div className="cor-net__col col-grow">
                            <div className="cor-net__label">Описание объекта</div>
                            <div className="cor-net__lenght">
                                <span>{objDescription.length}/{DESC_LIMIT}</span>
                                <textarea 
                                    key={'nn05'}
                                    value={objDescription} 
                                    placeholder={'Введите описание объекта'}
                                    onInput={e => handleChangeDesc(e.target.value)} 
                                />
                            </div>
                        </div>
                    </div>
                    <div className="cor-net__row">
                        <div className="cor-net__col col-2">
                            <div className="cor-net__label">
                                {getSwitcherHasLabel('Выбрать раздел из списка|Ввести новый', objTypeMethod, () => handleTypeMethod(!objTypeMethod))}
                            </div>

                            { objTypeMethod ? 
                                getDropdown(uniqueObjectTypes, objTypeId, value => handleObjTypeById(value), '', '', 'tp01')
                                :
                                <Input 
                                    key={'inp11'}
                                    value={objTypeName} placeholder={'Введите название раздела'}
                                    onInput={e => setObjTypeName(e.target.value)}
                                />
                            }
                        </div>
                        <div className="cor-net__col col-2">
                            <div className="cor-net__label">
                                {getSwitcherHasLabel('Выбрать подраздел из списка|Ввести новый', objGroupMethod, () => handleGroupMethod(!objGroupMethod))}
                            </div>
                            { objGroupMethod ?
                                getDropdown(uniqueObjectGroups, objGroupId, value => handleObjGroupById(value), '', '', 'gp01')
                                :
                                <Input 
                                    key={'inp12'}
                                    value={objGroupName} placeholder={'Введите название подраздела'}
                                    onInput={e => setObjGroupName(e.target.value)}
                                />
                            }
                        </div>
                    </div>
                </div>

                <div className="cor-net__section">
                    <div className="cor-net__row">
                        <div className="cor-net__col col-2">
                            <Tabs key={'nn30'}>
                                {getObjTabs().map(tab => (
                                    <Tabs.Item active={tabInd === tab.tabId} 
                                        onClick={() => setTabInd(tab.tabId)} 
                                        key={'tab'+ tab.tabId}>
                                        {tab.tabName}
                                    </Tabs.Item>)
                                )}
                            </Tabs>
                        
                        </div>
                    </div>
                </div>

                <div className="cor-net__section">
               
                    { tabInd === OBJECT_TAB.PROPS && 
                        <>
                            <div className="cor-net__title">Свойства</div>
                            <ul className="cor-net__list" key={'tb2'}>
                                {objProps.map((item, ind) => showObjProperty(pi.properties[item.id], item, ind, true, handleDeleteProp))}
                            </ul>
                            <div className="cor-net__add">
                                <Button className="cor_btn_add" onClick={handleOpenAddPropDlg}>
                                    <Icon name="plus_bold" />
                                    <span>Добавить свойство</span>
                                </Button>
                            </div>
                        </>
                    }

                    { tabInd === OBJECT_TAB.IMAGES && 
                        <>
                            <div className="cor-net__title">Изображения</div>
                            {getFileList(isTemplate, true, true, 
                                fileUrls, 
                                objFiles, handleAddFile, handleDeleteFile, uploadProgress, 
                                handleShowImage)}
                        </>
                    }

                    { tabInd === OBJECT_TAB.FILES && 
                        <>
                            <div className="cor-net__title">Файлы</div>
                            {getFileList(isTemplate, true, false, 
                                fileUrls, 
                                objFiles, handleAddFile, handleDeleteFile, uploadProgress, 
                                handleDownloadFile)}
                        </>
                    }
                </div>
            </div>
        );
    };
    
    return (
        <>  
            <ContentHead title={!isEditMode ? objName : 'Создание/редактирование объекта'} />

            <Content>
                <ContentHeader>
                    <Button back={true} onClick={()=>handleCancelObject(true)} />
                </ContentHeader>

                <ContentBody>
                    {isEditMode ? 
                    getObjectEdit() 
                    : 
                    getObjectView(isTemplate, true, undefined, objTypeName, objGroupName, objDescription, objProps, 
                        pi.properties, tabInd, setTabInd, handleDeleteProp, 
                        fileUrls, 
                        objFiles, handleAddFile, handleDeleteFile, uploadProgress, 
                        handleShowImage, handleDownloadFile)
                    }
                </ContentBody>
                                
                <ContentFooter className="gap jc-start">
                    {isEditMode &&
                    <>
                        <Button color="primary" size="medium" border={false} onClick={handleSaveObject}>Сохранить</Button>
                        <Button color="primary" size="medium" border={false} onClick={()=>handleCancelObject(false)}>Завершить редактирование</Button>
                    </>
                    } 

                    {!isEditMode && isEditEnabled &&
                        <Button color="primary" size="medium" border={false} onClick={handleObjectEdit}>Редактировать</Button>
                    }
                    {isEditEnabled && 
                        <Button color="primary" size="medium" onClick={() => setShowConfirmDlg(true)}>Удалить</Button>
                    }
                </ContentFooter>

                {showObjectPropDlg &&
                <PIOneObjectPropDlg
                    showDlg={showObjectPropDlg}
                    setShowDlg={setShowObjectPropDlg}
                    handleAddProp={handleAddProp}
                />
                }
                {showConfirmDlg &&
                <ModalConfirmDialog
                    showConfirmDlg={showConfirmDlg} 
                    handleNo={() => setShowConfirmDlg(false)}
                    handleYes={handleDeleteObject}
                    question={"Вы действительно хотите удалить этот объект?"}
                />
                }
            </Content>
            
            {showImageDlg &&
            <ImageDlg 
                showModal={showImageDlg}
                setShowModal={setShowImageDlg}
                file={shownFile}
            />
            }
        </>
    );
};

export default PInvestObjectsOne;
