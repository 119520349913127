import React, { useState, useEffect, useRef } from "react";
import {Icon} from '../ui';
import {Button} from 'rlabui';
import {toast} from "react-toastify";
import {FEATURES} from "../../api/config";
import ModalFinishStudentTestDlg from "../ui/ModalDialogs/ModalFinishStudentTestDlg";
import {DB_REFRESH, showCurrTime, showRemainingTime, getRemainingTimeInMSec, 
	showNameValue} from "../ui/utils/gen_utils";
import {drawFormula, getTaskAnswer, getRandomVariableNames, 
	getActualSelectedIndex, getLastAnswerIndex} from "./task_utils";
import {isAuth} from "../../helpers/auth";
import {storageClass} from "../../redux/slices/storage";
import {notebookInfoApi} from "../../api/api";
import socket from "../../socket";
import {getTestScoreOpts} from "../OnlineTest/ot_utils";
import {printOnlyDate} from "../../helpers/text";
import {loadCountersNotebook} from "../../redux/slices/lists";
import {fullName} from "../../helpers/auth";
import {NB_SAMPLE_TYPE, getTaskNumber, getSubjectById, getSelectedIndex} from "./nb_utils";
import ScoreCalcTableDlg from "./ScoreCalcTableDlg";
import ModalPrint from '../ui/ModalDialogs/ModalPrint';
import {PROFILE_TAB}  from "../template/Dashboard/DashboardProfile";
import {useSelector, useDispatch} from "react-redux";
import {SCORE_TYPE, SCORE_MANUAL_CHECK, calcScoreValue, getScoreName, 
	getMinGoodScore} from '../ui/utils/score_utils';
import {getTemplatesBySubjectId} from "./templates";
import {Content, ContentBody, ContentFooter, ContentHeader} from "../template/ContentParts";
import "./Notebooks.scss";
import nb from "./Notebooks.module.scss";

const NotebookStudentHomeWorkExecutePages = ({history, notebookInfoId, notebookData, 
		currPage, setCurrPage, taskPages, setTaskPages, startDate, isForceComplete,
		workErrorLimit, setWorkErrorLimit, isTeacherPreview, handleRefreshTaskData}) => {
	const [templates, setTemplates] = useState([]);
    const [showCompleteTestDlg, setShowCompleteTestDlg] = useState(false);
    const [showHowToCalcDlg, setShowHowToCalcDlg] = useState(false);
	const [varNames, setVarNames] = useState(['x', 'y']);
	
    const [currPageIndex, setCurrPageIndex] = useState(null); //пользователь кликнул опцию, но еще не сохранил ответ
    const [manualAnswer, setManualAnswer] = useState('');

    const [currTestTime, setCurrTestTime] = useState();
    const [isTestTimeFinished, setIsTestTimeFinished] = useState(false);
    const [secondCounter, setSecondCounter] = useState(0);
    const [canGoNextPage, setCanGoNextPage] = useState(false);
	const [showPrint, setShowPrint] = useState(false);
	const [isAutoScore, setIsAutoScore] = useState(false);
    const [timeLimitInMinutes, setTimeLimitInMinutes] = useState(0);
    const [timeLimitEnabled, setTimeLimitEnabled] = useState(false);

    const documentClass = useSelector(storageClass);
    const dispatch = useDispatch();
	const timerIdRef = useRef();

	useEffect(() => {
		const workProgress = () => { 
			setCurrTestTime(Date.now());
			setSecondCounter(secondCounter => secondCounter + 1);
		};
		timerIdRef.current = setInterval(workProgress, 1 * 1000); //refresh each sec
		return () => {clearInterval(timerIdRef.current);};
	},[]);

	useEffect(() => {
		if (!notebookData) return;
		const _subjectId = notebookData.titles.subjectId;
		setTemplates(getTemplatesBySubjectId(_subjectId).templates);
		setIsAutoScore(notebookData.settings.isAutoScore);
		setTimeLimitEnabled(notebookData.settings.isTimeLimited);
		setTimeLimitInMinutes(notebookData.settings.timeLimitMinutes);
	},[notebookData]);

	useEffect(() => {
		if (!timeLimitEnabled) return;
		const remTime = getRemainingTimeInMSec(currTestTime, startDate, 60 * timeLimitInMinutes);
		const finished = remTime <= 0;
		if (finished) {
			toast.info('Тест завершен из-за окончания времени.');
			setIsTestTimeFinished(true);
		}
    }, [currTestTime, startDate, timeLimitEnabled, timeLimitInMinutes]);

	useEffect(() => {
		if (notebookData?.run) {
			const finishedTime = (new Date(notebookData.run.completeFullDate)) - (new Date());
			const finished = finishedTime <= 0;
			if (finished)
				setIsTestTimeFinished(true);
		}
	}, [secondCounter, notebookData]);

	useEffect(() => {
		setVarNames(getRandomVariableNames(2));
	}, [currPage]);

	useEffect(() => {
		if (!isTestTimeFinished || !notebookInfoId) return;
		toast.warn('Время выполнения задания закончилось.');
		clearInterval(timerIdRef.current);
		setCurrPage(taskPages.length);
	}, [setCurrPage, taskPages, isTestTimeFinished, notebookInfoId]);

    //save tasks with updated results + score + completion:
    useEffect(() => { //update tasks
        const updateNotebookInfoById = async () => {
            if (isAuth().role === 0) { //only for student
                notebookInfoApi.updateNotebookInfoById(notebookInfoId, {isComplete: true})
                .then(() => {
                    socket.emit('BE-refresh-db', {type: DB_REFRESH.NBI, roomId: documentClass._id, id: notebookInfoId});
                    dispatch(loadCountersNotebook(documentClass._id));
                }).catch((err) => {
                });
            }

            setCurrPage(taskPages.length); //for both teacher and student
        };

        if (!isForceComplete) return;
        updateNotebookInfoById();
    }, [ notebookInfoId, taskPages, documentClass, dispatch, isForceComplete, setCurrPage]);

	const recalcTaskPages = async () => {
		const task = {...taskPages[currPage].task};
		const reorderedOptions = [...taskPages[currPage].reorderedOptions];
		let isChanged = false;
		let _canGoNextPage = false;

		if (currPageIndex !== null) {
			for (let i = 0; i < reorderedOptions.length; i ++)
				reorderedOptions[i].isSelected = false;
			reorderedOptions[currPageIndex].isSelected = true;
	
			const actualSelectedIndex = getActualSelectedIndex(reorderedOptions);
			const lastAnswerInd = getLastAnswerIndex(task.userAnswerHistory);
			let _workErrorLimit = workErrorLimit;

			if (actualSelectedIndex !== lastAnswerInd && actualSelectedIndex !== null) {
				if (!isAutoScore) { 
					//-------------------------------------
					//ответ: ручной ввод
					if (manualAnswer) {
						task.userAnswerHistory = manualAnswer.trim();
						task.isCorrect = SCORE_TYPE.UNDEFINED;
						isChanged = true;
						_canGoNextPage = true;
					}
				} else {
					//-------------------------------------
					//ответ: выбор из вариантов
					let saveResults = true;

					if (task.userTaskErrorLimit === -1) {
						_canGoNextPage = true;
					} else if (task.userTaskErrorLimit > 0) {
						if (actualSelectedIndex === 0) {
							toast.success('Ответ верный');
							_canGoNextPage = true;
						} else {
							toast.error('Ответ неверный');
							task.userTaskErrorLimit --; 
							_workErrorLimit --; 
							if (_workErrorLimit === 0) {
								task.userTaskErrorLimit = 0; //закончили работу
							} else if (task.userTaskErrorLimit === 0) {
								//закончили попытки для данной задачи
								if (_workErrorLimit > 0) //если остались еще попытки, переходим к следующей задаче
									_canGoNextPage = true;
							}
							setWorkErrorLimit(_workErrorLimit);
						}
					} else if (task.userTaskErrorLimit === 0){ //все попытки для данной задачи закончились - переходим дальше
						saveResults = false; //что бы пользователь не ввел, мы эти данные не будем учитывать
						if (_workErrorLimit > 0)
							_canGoNextPage = true;
					}

					if (saveResults) { //сохранять  результат только если пользователь не исчерпал попытки для данной задачи
						task.userAnswerHistory = (task.userAnswerHistory === '' ? '' : task.userAnswerHistory + '|') + actualSelectedIndex;
						task.isCorrect =  (actualSelectedIndex === 0) ? SCORE_TYPE.CORRECT : SCORE_TYPE.INCORRECT;
						isChanged = true;
					}
				}
			} else if (actualSelectedIndex === lastAnswerInd && actualSelectedIndex !== null) {
				_canGoNextPage = true;
			}
		}

		if (isChanged) {
			const _taskPages = taskPages.map((item, ind) => ind !== currPage ? item : {
				task: task,
				reorderedOptions: reorderedOptions
			});
			setTaskPages(_taskPages); //it will update db

			await saveData(_taskPages);
		}

		if (_canGoNextPage) setCanGoNextPage(true);
	};

	const saveData = async (_taskPages) => {
		let tasks = _taskPages.map(item => item.task);

		let score;
		if (isAutoScore) {
			const correctAnswerNumber = tasks.filter(item => item.isCorrect === SCORE_TYPE.CORRECT).length;
			score = calcScoreValue(correctAnswerNumber, tasks.length);
		} else {
			score = SCORE_MANUAL_CHECK;
		}

		const isComplete = currPage === _taskPages.length - 1;
		//const ah = tasks.map(task => task.userAnswerHistory);
		//console.log('info before save: ', ah)

		const newNbiData = {
			tasks: tasks,
			userWorkErrorLimit: workErrorLimit,
			score: score,
			isComplete: isComplete
		};

		notebookInfoApi.updateNotebookInfoById(notebookInfoId, newNbiData)
		.then(() => {
			socket.emit('BE-refresh-db', {type: DB_REFRESH.NBI, roomId: documentClass._id, id: notebookInfoId});

			if (isComplete) {
				dispatch(loadCountersNotebook(documentClass._id));
			}
		}).catch((err) => {
		});
	};

	const handleStudentIsReadyToComplete = () => {
		setShowCompleteTestDlg(true);
	};

	const handleConfirmCompleteTestYes = () => { 
		setShowCompleteTestDlg(false);
		clearInterval(timerIdRef.current);
		history.push("/");
	};	

	const isStudentAnswered = pageNum => {
		if (pageNum === taskPages.length) return false;
		const userAnswerInd = getLastAnswerIndex(taskPages[pageNum].task.userAnswerHistory);
		return userAnswerInd !== -1;
	}
	
	const isResultPage = pageNum => pageNum === taskPages.length;

	const handlePrevQuestion = pageNum => {
		if (pageNum === 0 || pageNum === taskPages.length) return;

		if (isAutoScore) {
			const ind = getSelectedIndex(taskPages[pageNum - 1].reorderedOptions);
			setCurrPageIndex(ind);
		} else {
			setManualAnswer(taskPages[pageNum - 1].task.userAnswerHistory);
			setCurrPageIndex(0);
		}
		
		//console.log('prev: pageNum=', pageNum, ' next=', pageNum - 1)
		setCurrPage(pageNum - 1);
	};

	const handleNextQuestion = (pageNum) => {
		prepareNextPageOpts(pageNum, setCurrPage, notebookData, taskPages, workErrorLimit);
	};

	const prepareNextPageOpts = (_currPage, _setCurrPage, _nbData, _taskPages, _workErrorLimit) => {
		let nextPage;
		if (_workErrorLimit === 0 || (_workErrorLimit === -1 && _currPage === _taskPages.length - 1)) {
			nextPage = _taskPages.length;
		} else if (_workErrorLimit > 0 && _currPage === _taskPages.length - 1) {
			nextPage = _taskPages.length - 1;
		} else {
			nextPage = _currPage + 1;
		}

		if (nextPage < _taskPages.length) {
			if (_nbData.settings.isAutoScore) {
				const ind = getSelectedIndex(_taskPages[nextPage].reorderedOptions);
				setCurrPageIndex(ind !== -1 ? ind : null);
			} else {
				const answer = _taskPages[nextPage].task.userAnswerHistory;
				setManualAnswer(answer ? answer : '');
				setCurrPageIndex(answer ? answer : null);
			}
		}

		//console.log('prep: _currPage=', _currPage, ' next=', nextPage)
		_setCurrPage(nextPage);
	};

	useEffect(() => {
		if (!canGoNextPage) return;
		setCanGoNextPage(false);
	
		prepareNextPageOpts(currPage, setCurrPage, notebookData, taskPages, workErrorLimit);
	},[canGoNextPage, workErrorLimit, currPage, notebookData, taskPages, setCurrPage]);

	const handleCurrPageIndex = (index) => { //1.1 - для выбора из предложенных вариантов
		setCurrPageIndex(index);
	};

	const handleManualAnswer = (val) => { //1.2 - для ручного ввода
		setManualAnswer(val);
		setCurrPageIndex(0); //just to be able to save changes
	};

	const handleAnswer = () => { //2 - open UE above to save data
		if (isTeacherPreview) {
			setCanGoNextPage(true);
			return;
		}

		let beChecked = false;
		let nextPage = false;

		if (isAutoScore) {
			//определим, был ли ранее сделан выбор:
			let selectedInd = -1;
			const opts = taskPages[currPage].reorderedOptions;
			for (let i = 0; i < opts.length; i ++) 
				if (opts[i].isSelected) selectedInd = i;

			if (selectedInd !== -1) { //выбор был
				if (currPageIndex !== null) {
					if (currPageIndex !== selectedInd) {//сделали новый выбор - идем к сохранению
						beChecked = true;
					} else {//нового выбора нет - идем к следующему шагу
						nextPage = true;
					}
				} else {
					//раньше выбор был, нового выбора нет, идем к следующему шагу
					nextPage = true;
				}
			} else { //выбора не было
				if (currPageIndex !== null) {//раньше выбора не было. теперь есть - идем к сохранению
					beChecked = true;
				} else
					return; //ничего не выбрано. остаемся на том же шаге
			}
		} else {
			const answer = taskPages[currPage].task.userAnswerHistory;
			const newAnswer = manualAnswer.trim();
			if (answer) { //ответ был
				if (currPageIndex !== null) {
					if (!newAnswer) { //стерли ответ
						return; //нет ответа. остаемся на том же шаге
					} else if (answer !== newAnswer) {//сделали новый ответ - идем к сохранению
						beChecked = true;
					} else { //ответ не изменился - идем к следующему шагу
						nextPage = true;
					}
				} else {
					//раньше ответ был, нового ответа нет, идем к следующему шагу
					nextPage = true;
				}
			} else { //ответа не было
				if (newAnswer) {//раньше ответа не было. теперь есть - идем к сохранению
					beChecked = true;
				} else
					return; //ничего не выбрано. остаемся на том же шаге
			}
		}

		if (beChecked) 
			recalcTaskPages();
		else if (nextPage)
			setCanGoNextPage(true);
	};

	const handlePrint = () => {
		setShowPrint(true);
	};

	const getPrintData = () => {
		if (!showPrint) return [];

		const title = 
			<div className="cor-net">
				<div className="cor-net__section">
					{showNameValue('Предмет', getSubjectById(notebookData.titles.subjectId))}
					{showNameValue('Название задания', notebookData.titles.homeWorkName)}
					{showNameValue('Тема', notebookData.titles.homeWorkItem)}
					{notebookData?.owner && showNameValue('Преподаватель', fullName(notebookData.owner))}
					{showNameValue('Дата выдачи задания', printOnlyDate(new Date()))}
					{showNameValue('Кол-во вопросов', getTaskNumber(notebookData))}
				</div>
			</div>;

		const printData = [title];

		for (let ind = 0; ind < taskPages.length; ind ++) {
			const page = getQuestionPage(ind);
			printData.push(page);
		}

		return printData;
	};

	const getAnswerRbBoxes = (pageNum, selectedTemplate, userTaskErrorLimit, workErrorLimit) => {
		const formulaInd = taskPages[pageNum].task.formulaInd;
		const reorderedOptions = taskPages[pageNum].reorderedOptions;
		const answerOptionsList = reorderedOptions.map(item => getTaskAnswer(selectedTemplate, formulaInd, item.option));
	
		return (
			answerOptionsList.map(
				(item, ind) => 
				<div key={"rbkey"+ind} className="cor-work__task">
					<input type="checkbox" 
						id={ind} 
						value={ind} 
						name={item}
						checked={ind === currPageIndex} 
						disabled={userTaskErrorLimit === 0 || workErrorLimit === 0}
						onClick={(e) => handleCurrPageIndex(Number(e.target.value))} 
						onChange={() => {}}
					>
					</input>
					<label htmlFor={ind}>{item}</label>
				</div>
			)
		);
	};

	const getNextQuestion = (pageNum) => {
		if (taskPages.length === 0 || isResultPage(pageNum) || showPrint)  return (<></>);

		return (
			<div className="otCreate__stretch1">
				{workErrorLimit === 0 ?
				<div className="nb__warning nb__warningSpace">
					Вы сделали максимальное количество допустимых ошибок. Результаты выполнения задания сохранены.
				</div>
				
				:

				<button onClick={handleAnswer} 
					className="otCreate__button otCreate__buttonNext otCreate__buttonSel"
				>
					{'Ответить'}
				</button>
				}

				{isStudentAnswered(pageNum) && pageNum === taskPages.length - 1 && 
				<button onClick={() => handleNextQuestion(pageNum)} 
					className="otCreate__button otCreate__buttonNext otCreate__buttonSel">
					Смотреть результаты
				</button>
				}
			</div>
		);		
	};

	const solveEquationTask = pageNum => {
		const getTemplateWithVariableNames = (templates, id) => {
			const _selectedTemplate = templates.find(item => item.templateId === id);
			if (!_selectedTemplate) return undefined;
			return {..._selectedTemplate, varNames: varNames};
		};
	
		if (!notebookData || !templates ||  pageNum >= taskPages.length) return <></>;

		const taskSample = notebookData.taskSamples.find(item => item._id === taskPages[pageNum].task.sampleId);
		const selectedTemplate = getTemplateWithVariableNames(templates, taskSample.templateId);
		if (!selectedTemplate) return <></>;

		const formulaInd = taskPages[pageNum].task.formulaInd;
		const formula = selectedTemplate.formulas[formulaInd];
		if (!formula) {
			debugger;
		}

		const task = taskPages[pageNum].task;
		const userTaskErrorLimit = task.userTaskErrorLimit;
		const teacherDescription = taskSample.teacherDescription;

		const taskText1 = selectedTemplate.sampleTypeId === NB_SAMPLE_TYPE.EQUATION ? 'Решите уравнение': 
						  selectedTemplate.sampleTypeId === NB_SAMPLE_TYPE.GROUPING ? 'Сгруппируйте' : 'Решите задачу';
		const taskText2 = selectedTemplate.sampleTypeId === NB_SAMPLE_TYPE.EQUATION ? 'Уравнение': 'Задача';

		return (
			<div className="cor-work__main">

				<div className="cor-work__label">
					<span>{taskText1}{' и '} 
						{isAutoScore ? 'выберите правильный ответ.' : 'введите ответ.'}
					</span>
				</div>
				<div className={nb.nb__commentBox}>
					{taskText2}: <b>{drawFormula(selectedTemplate, task.formula)}</b>
				</div>

				{isAutoScore ?
				<div className="cor-work__tasks">
					{getAnswerRbBoxes(pageNum, selectedTemplate, userTaskErrorLimit, workErrorLimit)}
				</div>
				: 
				<div className="cor-work__tasks">
					<textarea 
						className={nb.nb__taResizeDisable} autoComplete="off" 
						value={manualAnswer} 
						onChange={e => handleManualAnswer(e.target.value)}
						name="description" rows="4" 
					/>
				</div>
				}

				{teacherDescription &&
				<>
					<div className={nb.nb__commentBox}>Комментарий преподавателя</div>
					<textarea 
						className={nb.nb__commentText} 
						autoComplete="off" 
						value={teacherDescription} 
						name="description" 
						rows="4" 
						disabled 
					/>
				</>
				}

				<div className="otCreate__nextBtnQuestChooseOne">
					{getNextQuestion(pageNum)}
				</div>
				
				{workErrorLimit > 0 && userTaskErrorLimit !== -1 && (
					<div className="cor-work__tasks">Оставшиеся попытки для данного примера: {userTaskErrorLimit}</div>
				)}
			</div>
		);
	};

    const getQuestionPage = pageNum => {
		return (
			<>
				{notebookData?.titles?.homeWorkItem && (!showPrint || pageNum === 0) &&
					<div className="cor-work__subject">
						Тема: <b>{notebookData?.titles?.homeWorkItem}</b>
					</div>
				}

				{solveEquationTask(pageNum)}
			</>
		);
    }

	const getResultPage = () => {
		const handleGoMain = () => {
			history.push(FEATURES['notebook'].to);
		};
		const handleGoProfile = () => {
			if (notebookInfoId)
				history.push(FEATURES.profile.to + '/' + isAuth()._id + '/' + PROFILE_TAB.NOTEBOOK);
			else 
				history.push(FEATURES.profile.to);
		};
	
		if (!notebookData) return <></>;
		if (currPage === taskPages.length && taskPages.length > 0) 
			clearInterval(timerIdRef.current);

		const tasks = taskPages.map(item => item.task);
		const correctAnswerNumber = tasks.filter(item => item.isCorrect === SCORE_TYPE.CORRECT).length;
		let iconName, status, iconText, score, scoreName, info, isResultOk;

		if (isAutoScore) {
			score = calcScoreValue(correctAnswerNumber, tasks.length);
			scoreName = getScoreName(score);
			isResultOk = score >= getMinGoodScore();
		}
		[iconName, iconText, status, info] = getTestScoreOpts(isAutoScore, isResultOk, 'nb');

		return (
			<div className="cor-work__result">
				<div className={"cor-work__result_caption " + status}>
					<div className="cor-work__result_icon">
						<Icon name={iconName} />
					</div>
					<div className="cor-work__result_title">{iconText}</div>
				</div>

				{isAutoScore && 
					<div className="cor-work__result_rating">
						Оценка: {scoreName}
					</div>
				}

				<div className="cor-work__result_info">{info}</div>

				{isAutoScore && 
					<div className="cor-work__result_methodology" onClick={handleHowToCalc}>
						Методика расчета оценки
					</div>
				}

				<div className="cor-work__result_buttons">
					<Button onClick={handleGoMain}>
						Вернуться в рабочую тетрадь
					</Button>
					<Button border={true} onClick={handleGoProfile}>
						Перейти в профиль
					</Button>
				</div>
			</div> 
			);
	};

	const handleHowToCalc = () => {
		setShowHowToCalcDlg(true);
	};

	const getActionButtons = (pageNum) => {
		if (taskPages.length === 0)  return (<></>);

		let prevButton = <></>;
		if (pageNum > 0) {
			prevButton = <div className="cor-work__btn" onClick={() => handlePrevQuestion(pageNum)} >
				<Icon name={'arrow-round-prev'}/>Предыдущий вопрос&nbsp;&nbsp;&nbsp;
			</div>
		}

		//lock Next button if the student is not answered yet:
		const buttons = (
			<ContentFooter className="jc-center">
				{prevButton}

				{isStudentAnswered(pageNum) && 
					<div className="cor-work__btn" onClick={() =>handleNextQuestion(pageNum)}>
						{pageNum < taskPages.length - 1 && workErrorLimit !== 0 ? 'Следующий вопрос' : 'Смотреть результаты'}
						<Icon name={'arrow-round-next'}/>
					</div>
				}

				{(isResultPage(pageNum) || isAuth().role > 0) && 
					<div className="cor-work__btn" onClick={handleStudentIsReadyToComplete}>
						Завершить <Icon name={'check-round'}/>
					</div>
				}

			</ContentFooter>
		);
		return buttons;
	};

	if (taskPages.length === 0) {
		return <></>;
	}

    return (
		<>
		{currPage < taskPages.length ?
			<Content>
				<ContentHeader>
					<div className="cor-work__row col-w33">
						<div className="cor-work__col">
							Название задания: &nbsp;{notebookData?.titles?.homeWorkName}
						</div>
						<div className="cor-work__col">
							{ currPage < taskPages.length ? 
								<>Вопрос { currPage+1 } / {taskPages.length}</> 
								: 
								<>Результаты</>
							}
						</div>
						<div className="cor-work__col">
							<span>
								{isAuth().role > 0 ? 
									<div className={nb.printBtns}>
										<Button onClick={handleRefreshTaskData} border={true}>
											Обновить задачи
										</Button> 
										<Button onClick={handlePrint} icon={true} >
											<Icon name="print"/>
											Печать
										</Button> 
									</div>
									: 
									timeLimitEnabled ? showRemainingTime(currTestTime, startDate, 60 * timeLimitInMinutes, true) : 
										showCurrTime(currTestTime, startDate)
								}
							</span>
						</div>
					</div>
				</ContentHeader>
				<ContentBody>
					{getQuestionPage(currPage)}
				</ContentBody>

				{getActionButtons(currPage)}
			</Content>
		:
			<div className="cor-work__result_wrap">
				{getResultPage()}
			</div>
		}

			{showCompleteTestDlg &&
			<ModalFinishStudentTestDlg
				showConfirmDlg={showCompleteTestDlg} 
				handleNo={() => setShowCompleteTestDlg(false)}
				handleYes={handleConfirmCompleteTestYes}
			/>
			}
			
			{showHowToCalcDlg && 
			<ScoreCalcTableDlg
				showHowToCalcDlg={showHowToCalcDlg} 
				setShowHowToCalcDlg={setShowHowToCalcDlg}
			/>
			}

			{showPrint && <ModalPrint
                showPrint={showPrint} 
				closePrint={() => setShowPrint(false)}
                printData={getPrintData()}
            />}
		</>
    )
}

export default NotebookStudentHomeWorkExecutePages;
