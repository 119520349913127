import React, {useState, useEffect, useRef} from "react";
import { Button } from "../ui";
import {SCALEPOS, getSvgWithHeightWidth, getColoredInnerBox, getScaleData,
	getScaleStrokes, getScaleValues, getAxis, getGrid, getInnerBorder} from "../CalcModels/cm_utils";
import {UNIT_RELATION, getCategories, getSystemList, getTValue} from './pi_utils';
import { getDropdown, getSwitchedList, getListWithSwitchedRow } from "../ui/utils/gen_utils";
import Table from "../ui/Table/Table";
import {storageClass} from "../../redux/slices/storage";
import {loadPIUnits} from "../../redux/slices/lists";
import {useDispatch, useSelector} from "react-redux";
import { ContentBody, ContentSubHeader } from "../template/ContentParts";
import "./PInvest.scss";

const idMainSvg ='mainSvg';

const PIShowLinks = () => {
	const [selectedCategoryId, setSelectedCategoryId] = useState(-1);
	const [catUnits, setCatUnits] = useState([]);
	const [selectedUnitId, setSelectedUnitId] = useState(-1);
	const [unitTableList, setUnitTableList] = useState([]);
    const [isTCategory, setIsTCategory] = useState(false);
	//
    const [svgWidth, setSvgWidth] = useState(undefined);
    const [svgHeight, setSvgHeight] = useState(undefined);
    const [graphicUnitArray, setGraphicUnitArray] = useState([]);
    const [svgArr, setSvgArr] = useState([]);
	const [resize, setResize] = useState(0);
	const refMainBox = useRef();
	const refMainSvg = useRef();

	const {lists: {pi}} = useSelector(state => state);
	const documentClass = useSelector(storageClass);
	const dispatch = useDispatch();

    useEffect(() => {
		dispatch(loadPIUnits(documentClass._id));
	}, [dispatch, documentClass._id]);

	useEffect(() => {
		const handleResize = e => setResize(e[0].contentBoxSize[0].inlineSize);
		const observer = new ResizeObserver(handleResize);
		observer.observe(refMainBox.current);
		return () => {
			observer.disconnect();
		};
	}, []);

	useEffect(() => {
		const svgBox = document.getElementById(idMainSvg);
		const svgR = svgBox?.getBoundingClientRect();

		const innerMainR = document.getElementById('idInnerMainBox')?.getBoundingClientRect();
		const width = (innerMainR.width - (svgR.left - innerMainR.left));
		const height = unitTableList.length === 0 ? 100 : 20 * unitTableList.length + 100;
		setSvgWidth(width);
		setSvgHeight(height);
	}, [resize, unitTableList]);

	useEffect(() => {
		setSelectedUnitId(-1);
		if (selectedCategoryId === -1) {
			setCatUnits([]);
			return; 
		}
		const units = pi?.units.filter(item => item.quant === selectedCategoryId);
		setCatUnits(units);

		//подготовим табличную информацию
		const tableList = [];
		const systems = getSystemList(pi?.systems);

		for (let i = 0; i < units.length; i ++) {
			const unit = units[i]; 
			const systemName = systems.find(r => r.value === unit.system).label;

			const record = {
				name: unit.name, 
				unit: unit.sign,
				msystem: systemName, 
				actions: '', 
				id: '' + unit._id + '|0' //switch: id + switch-state: 1 - on, 0 - off
			};

			tableList.push(record);
		}

		setUnitTableList(tableList);
	}, [pi?.systems, pi?.units, selectedCategoryId]);

	useEffect(() => {
		if (catUnits.length === 0) return; 
		const unitId = catUnits?.find(item => item.relation === UNIT_RELATION.BASIC)?._id;
		setSelectedUnitId(unitId);
	}, [catUnits]);

	const handleSelectedCategoryId = value => {
		setSelectedCategoryId(value);
		setUnitTableList([]);
		const cat = getCategories(pi?.quants).find(item => item.value === value);
		setIsTCategory(cat.label === "Температура (t)");
	};

	const getUnits = () => {
		const list = []; 
		for (let i = 0; i < catUnits.length; i ++)
			list.push({label: catUnits[i].name, value: catUnits[i]._id});
		return list;
	};
  
	useEffect(() => {
		//1. prepare IDs to be shown in graphics
		const shownUnitIndexes = unitTableList.map(item => item.id)
								 .filter(item => item.split('|')[1] !== '0')
								 .map(item => item.split('|')[0]);
		if (shownUnitIndexes.length === 0) {
			setGraphicUnitArray([]);
			return;
		}
		//2. find the value of relative units (selectedUnitId) and its name:
		const selectedUnit = pi?.units.find(item => item._id === selectedUnitId);
		let dependency = selectedUnit ? pi?.dependencies.find(item => item.to === selectedUnit?._id) : undefined;
		const selecteValue = !selectedUnit ? 0 : 
							 !dependency ? 1 : 
							 selectedUnit?.relation === UNIT_RELATION.BASIC ? 1 : 
							 dependency?.formula ? dependency?.formula : 0;
		const selectedName = selectedUnit?.sign;
		//3. prepare the array of objects: names and relative values:
		const list = [];
		
		for (let i = 0; i < shownUnitIndexes.length; i ++) {
			const unit = pi?.units.find(item => item._id === shownUnitIndexes[i]);
			dependency = pi?.dependencies.find(item => item.to === unit._id);
			const value = !dependency ? 1 : dependency?.formula ? dependency?.formula : 0;
			const relValue = !!selecteValue ? value / selecteValue : 0;

			const rec = {
					name: !isTCategory ? unit.name : getTValue(selectedUnit, unit), 
					relValue: relValue, 
					unitName: selectedName, 
					ruUnit: unit.sign,
					graphWidth: relValue, 
					isSelectedMeasureUnit: selectedUnitId === shownUnitIndexes[i]
			};
			list.push(rec);
		}

		//4. sort the array
		list.sort((a, b) => b.relValue - a.relValue);

		//5. make normalization: max = 1
		let maxValue = list[0].relValue;
		if (maxValue === 0) maxValue = 1;
		for (let i = 0; i < list.length; i ++) {
			list[i].graphWidth = list[i].graphWidth / maxValue;
		}

		setGraphicUnitArray(list);
	}, [unitTableList, selectedUnitId, pi?.units, pi?.dependencies, isTCategory]);

	useEffect(() => {
		const RECT_TOP_POS = 40; //расстояние от верха одного прямоугольника до следующего

		const getGraphicData = (_svgWidth, _svgHeight, graphArr) => {
			if (graphArr.length === 0) return [];

			const rect1Arr = [];
			const rect2Arr = [];
			const textArr = [];
			const SCALE_SHIFT = 10; //опускаем шкалу
			const GRAPH_SHIFT = 40; //опускаем графики
			const RECT_HEIGHT = 30; //высота одного прямугольника
			const TEXT_LEFT_SHIFT = 10; //сдвиг текста слева
			const TEXT_TOP_SHIFT = 20; //сдвиг текста вниз
			const RECT_SEL_UNIT_BKND_COL = 'grey';
			const RECT_BKND_COL = 'lightgrey';
			const RECT_UPPER_FIRST_COL = '#f4b7b7'; // //розовый #f4b7b7   зел #a7e39d гол #00b5ff
			const RECT_SEL_UNIT_COL = '#00b5ff'; //цвет 
			const RECT_UPPER_COL = '#a7e39d'; //стандартный цвет 
			const RECT_BORDER_COL = 'grey';
			const TEXT_FIRST_COL = 'white';
			const TEXT_COL = 'black';

			for (let i = 0; i < graphArr.length; i ++ ) {
				const graph = graphArr[i];
				const bknd = graph.isSelectedMeasureUnit ? RECT_SEL_UNIT_BKND_COL : RECT_BKND_COL;
				const rect1 = getColoredInnerBox(0, GRAPH_SHIFT + i * RECT_TOP_POS, _svgWidth, RECT_HEIGHT, bknd, RECT_BORDER_COL, 1, "bknd01"+i);
				rect1Arr.push(rect1);
				const rectWidth = _svgWidth * graph.graphWidth;
				const topCol = i === 0 ? RECT_UPPER_FIRST_COL : 
							graph.isSelectedMeasureUnit ? RECT_SEL_UNIT_COL : RECT_UPPER_COL;
				const rect2 = getColoredInnerBox(0, GRAPH_SHIFT + i * RECT_TOP_POS, rectWidth, RECT_HEIGHT, topCol, RECT_BORDER_COL, 1, "bknd02"+i);
				rect2Arr.push(rect2);
				let text;
				if (!isTCategory)
					text = graph.isSelectedMeasureUnit ? '1 ' + graph.name : '1 ' + graph.name + 
						(!!graph.unitName ? ' = ' + graph.relValue + ' ' + graph.unitName : '');
				else 
					text = graph.name;
				//const xxx = getCellText(text, '', 'ab');
				const txt = text.replaceAll('<sup>', '').replaceAll('</sup>', ''); //parser(text);
				
				const textCol = graph.isSelectedMeasureUnit ? TEXT_FIRST_COL : TEXT_COL;
				const textInfo = <text x={TEXT_LEFT_SHIFT} y={GRAPH_SHIFT + TEXT_TOP_SHIFT + i * RECT_TOP_POS} 
					key={"text01"+i} className="pi__svgText" fill={textCol}>{txt}</text>;
				textArr.push(textInfo);
			}
	
			const strokeNUM = 11;
			const min = 0; 
			const max = 1;
			const height1 = 11 + SCALE_SHIFT; //черточки шкалы опустим на 11
			const scaleLarge = 10;
			const scaleSmall = 0;
			const scaleColor = 'grey';
			const vertShift = 0;
			const horizScaleArr1 = getScaleData(SCALEPOS.horiz, SCALEPOS.left, strokeNUM, min, max, 
				vertShift, _svgWidth, height1, scaleLarge, scaleSmall, 0);
			const horizStrokes = getScaleStrokes(horizScaleArr1, scaleColor, 1, 1, "hkey");

			const height2 = -11 + SCALE_SHIFT; //поднимем шкалу выше на 11
			const horizScaleArr2 = getScaleData(SCALEPOS.horiz, SCALEPOS.left, strokeNUM, min, max, 
				vertShift, _svgWidth-10, height2, scaleLarge, scaleSmall, 0);
			const horizScaleValues = getScaleValues(horizScaleArr2, SCALEPOS.horiz, SCALEPOS.left, 
				scaleColor, "horz1", false, 1, 'pi__svgTextScale');

			const scaleW = 1;
			const opacity= 0.5;
			const AXIS_SHIFT = 20;
    	    const xAxis = getAxis(0, AXIS_SHIFT + SCALE_SHIFT, _svgWidth, 1, 'grey', scaleW, opacity);
			const gridVShift = 20;
			const gridW = 1;
			const col = 'grey';
			const hGrid = getGrid(horizScaleArr2, SCALEPOS.horiz, 0, gridVShift, gridW, _svgHeight, 
				col, 1, 0.2);

			//1 - boxes:
			const innerBox = getInnerBorder(0, 0, _svgWidth, _svgHeight, 'lightgrey', "rect01");
		  
			return [
				hGrid,
				...rect1Arr, 
				...rect2Arr, 
				...textArr,
				horizStrokes,
				horizScaleValues,
				xAxis,
				innerBox,
			];
		};
	
		const height = (graphicUnitArray.length + 1) * RECT_TOP_POS;
		if (svgHeight < height) setSvgHeight(height);

		const _svgArr = getGraphicData(svgWidth, svgHeight, graphicUnitArray);
		setSvgArr(_svgArr);
	}, [svgWidth, svgHeight, graphicUnitArray, isTCategory]);

	//-----------------------

	const handleSwitchTable = (isToOn) => {
		setUnitTableList(getSwitchedList(unitTableList, isToOn));
	};

	const handleSwitchRow = (ids) => {
		setUnitTableList(getListWithSwitchedRow(unitTableList, ids));
	};

	const getSvg = (obj) => {
		return getSvgWithHeightWidth(svgHeight, svgWidth, obj, 'svg1');
	};

    const getTableHeader = () => {
		const header = [
			{column: 'Название', name: 'name', style: { width: '30%'} }, 
			{column: 'Обозначение', name: 'unit', style: { width: '30%'} }, 
			{column: 'Система единиц', name: 'msystem', style: { width: '30%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '10%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		]; //plus minus

		return header;
	};

    return ( 
		<>
			<ContentSubHeader>
				<div className="cor-net">
					<div className="cor-net__section">
						<div className="cor-net__title">Связь между единицами</div>
						<div className="cor-net__row">
							<div className="cor-net__col col-2">
								<div className="cor-net__label">Категория</div>
								{getDropdown(getCategories(pi?.quants), selectedCategoryId, value => handleSelectedCategoryId(value), '', '', 'cat01')}
							</div>
							<div className="cor-net__col col-2">
								<div className="cor-net__label">Единица измерения для сравнения</div>
								{getDropdown(getUnits(), selectedUnitId, value => setSelectedUnitId(value), '', '', 'unit01')}
							</div>
						</div>
						{unitTableList.length > 0 &&
							<div className="cor-net__row">
								<div className="cor-net__col">
									<Button color="primary" size="extraSmall" onClick={() => handleSwitchTable(true)}>Включить все</Button>
								</div>
								<div className="cor-net__col">
									<Button color="secondary" size="extraSmall" onClick={() => handleSwitchTable(false)}>Выключить все</Button>
								</div>
							</div>
						}
					</div>
				</div>
			</ContentSubHeader>
			<ContentBody>
				<div id="idInnerMainBox" ref={refMainBox} className="cor-net">
					<div className="cor-net__section">
						<div className="cor-net__row ai-start">
							<div className="cor-net__col col-2">
									<>
										<Table 
											className="pi__linkUnits"
											table={{
												header: getTableHeader(), 
												data: unitTableList
											}}
 											actions={{
												handleSwitch: id => handleSwitchRow(id),
											}}
											sort={{
												hasSorting: true, 
												initSortInd: 1,
											}}
										/>
									</>
							</div>
							<div className="cor-net__col col-2">
								<div id={idMainSvg} ref={refMainSvg} className="calcModel__coil1 pi__svg"
										style={{width: svgWidth, height: svgHeight}}>
									{getSvg(svgArr)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</ContentBody>
		</>
    )
}

export default PIShowLinks;