import React, { useState, useEffect } from 'react';
import {Modal} from 'rlabui';
import {useSelector} from "react-redux";
import {onlineTestRunApi} from "../../../api/api";
import {getNamesByLabWorkId } from "../../practicum/lw_utils";
import {getOnlineTestById} from "../../OnlineTest/ot_utils";
import {storageClass} from "../../../redux/slices/storage";
import {_isOnlineTest, _isLabWork, _isVirtLab, getLabInfo, getOnlineTestInfo, getButtons } from "./md_utils";
import ss from './ModalBegin.module.scss';

const ModalRunStudentTestDlg = ({ showModal, setShowModal, 
        testType, testId, testRunId,
        history, setTakePartInTest }) => {
    const [isLabWork, setIslabWork] = useState(false);
    const [isOnlineTest, setIsOnlineTest] = useState(false);
    const [isVirtLabGame, setIsVirtLabGame] = useState(false);
    const [virtLabGameNumber, setVirtLabGameNumber] = useState(undefined);
    const [contentList, setContentList] = useState(null);
    const [onlineTestTitle, setOnlineTestTitle] = useState('');
    const [onlineTestDescr, setOnlineTestDescr] = useState('');
    const [timeLimitEnabled, setTimeLimitEnabled] = useState(false);
    const [timeLimitInMinutes, setTimeLimitInMinutes] = useState(0);
    const documentClass = useSelector(storageClass);
    
    useEffect(() => {
		const getOTData = async (testId, testRunId) => {
   			const ot = await getOnlineTestById(testId);
            const otRun = await onlineTestRunApi.getOnlineTestRunById(testRunId);
            const runOpts = otRun.runOpts;
               
            setOnlineTestTitle(ot.title);
            setOnlineTestDescr(ot.description);
            setTimeLimitEnabled(runOpts.runTimeLimitEnabled);
            setTimeLimitInMinutes(runOpts.runTimeLimitInMinutes);
        };

        if (!testId) return;
       
        const isLW = _isLabWork(testType);
        const isOT = _isOnlineTest(testType);
        const isVG = _isVirtLab(testType);
        setIslabWork(isLW);
        setIsOnlineTest(isOT);
        setIsVirtLabGame(isVG);

        if (isLW) {
            setContentList(getNamesByLabWorkId(testId).split('|'));
        } else if (isOT) {
            getOTData(testId, testRunId);
        } else if (isVG) {
            //извлечем индекс лаб.работы по оптике:
            const gameInd = Number(testId.split('|')[2].replace('lab', ''));
            setVirtLabGameNumber(gameInd);
            setContentList(getNamesByLabWorkId(testId).split('|'));
        }
    }, [testType, testId, testRunId]);

    const handleStartTest = () => {
        setTakePartInTest(true); //the student started the test
        const path = isLabWork ? '/lw' : isOnlineTest ? '/ot' : ('/vrlab/' + virtLabGameNumber);
        history.push(path);
        setShowModal(false);
    };  

    return (
        <Modal className={ss.root} visible={showModal} size="md">
            <Modal.Head 
                // modalClose={() => {}} 
                title={isLabWork ? 'Новая лабораторная работа' : isOnlineTest ? 'Новый онлайн тест' : 'Новая игра'} 
            /> 
            <Modal.Body>
                <div className={ss.body}>
                    <div className={ss.row}>
                        <span>Класс</span>
                        <span>{documentClass.className}</span>
                    </div>

                    {(isLabWork || isVirtLabGame) && getLabInfo(contentList)}

                    {isOnlineTest && (
                        <>
                            {getOnlineTestInfo(onlineTestTitle, onlineTestDescr)}

                            <div className={ss.row}>
                                <span>Тест ограничен по времени</span>
                                <span>{timeLimitEnabled? 'Да' : 'Нет'}</span>    
                            </div>
                            {!!timeLimitEnabled && (
                                <div className={ss.row}>
                                    <span>Продолжительность теста</span>
                                    <span>{timeLimitInMinutes}<b>&nbsp;минут</b></span>    
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer align="left">
                {getButtons(handleStartTest, setShowModal, false, testType, true)}
            </Modal.Footer>
        </Modal>
    )
};

export default ModalRunStudentTestDlg;
