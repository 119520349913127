import React from 'react';
import ss from './Notify.module.scss';

const Notify = (props) => {
    const {
        className = '',
        value = null,
        path = null,
        history = undefined,
    } = props;

    const handle = (e) => {
        e.stopPropagation();
        if (history && path) {
            history.push(path);
        }
    };

    let notifyClass = ss.root;
    if (value <= 9) notifyClass += ' ' + ss.small;
    if (className) notifyClass += ' ' + className;

    return value ? (
        <div className={notifyClass}>
            <p className={ss.value} onClick={handle} >{value > 99 ? '99+' : value}</p>
        </div>
    )
    :
    ('');
};
export default Notify;
