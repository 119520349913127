import {showUserSettings} from "../../../actions/actions";
import {toggleIsMobile} from "../../../actions/toggle";

export const mapStateToProps = (state) => {
    return ({
    });
};

export const mapDispatchToProps = (dispatch) => ({
    showUserSettings: (obj) => dispatch(showUserSettings(obj)),
    toggleIsMobile: (obj) => dispatch(toggleIsMobile(obj)),
});
