import React, { useState, useEffect } from "react";
import Table, {TBL_ICON_NAME}  from "../ui/Table/Table";
import socket from "../../socket";
import {DB_REFRESH, getClassStudents} from "../ui/utils/gen_utils";
import {onlineTestInfoApi} from "../../api/api";
import {printOnlyDate} from "../../helpers/text";
import {loadCountersOt} from "../../redux/slices/lists";
import {FEATURES} from "../../api/config";
import {SCORE_TYPE} from '../ui/utils/score_utils';
import {QUESTION_TYPES, getOnlineTestById, updateOnlineTestInfoScoreById, 
	getNonCheckAnswerList} from "../OnlineTest/ot_utils";
import {getHeader, getExceptionCellStyle} from "./rt_utils";
import ViewStudentOnlineTestResultDlg from "./ViewStudentOnlineTestResultDlg";
import {PROFILE_TAB}  from "../template/Dashboard/DashboardProfile";
import {onlineTestRunApi} from "../../api/api";
import {ContentHead, Content, ContentBody, Back} from "../template/ContentParts";
import {storageClass, storageOrg} from "../../redux/slices/storage";
import {useSelector, useDispatch} from "react-redux";
import {isAuth} from "../../helpers/auth";
import {fetchclass} from "../../redux/slices/classsline";
import "./ReviewActiveLabWork.scss";

const TXT_CHECK = {
    AUTO: 'Автоматическая',
    MANUAL: 'Ручная',
};
const SPEC_TEXT = {
    NOT_EXECUTED: 'Не приступал',
	BE_CHECKED: 'Не проверено',
	BE_EVALUATED: 'Нет оценки',
    EXECUTED: 'Выполнено',
};

const ViewSelectedOnlineTestRun = ({ history, selectedTestRunId }) => {
	const [onlineTestId, setOnlineTestId] = useState(undefined);
	const [onlineTestData, setOnlineTestData] = useState(undefined);
	const [studentResults, setStudentResults] = useState([]);
	const [studentData, setStudentData] = useState([]);
	const [isUpdated, setIsUpdated] = useState(false);
	const [testRunDetails, setTestRunDetails] = useState(null);
	const [showDetailstDlg, setShowDetailstDlg] = useState(false);
	const [selectedStudentId, setSelectedStudentId] = useState(undefined);
	const [hasNonCheckedAnswers, setHasNonCheckedAnswers] = useState(false);
	const [rowComments, setRowComments] = useState([]);
	
    const documentOrg = useSelector(storageOrg);
	const documentClass = useSelector(storageClass);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchclass(isAuth()._id));
    }, [dispatch, documentOrg._id]);

	useEffect(() => {
		const fetchData = async () => {
			let result = await onlineTestRunApi.getOnlineTestRunById(selectedTestRunId);
			setOnlineTestId(result.onlineTest);
			setTestRunDetails(result);

			result = await onlineTestInfoApi.getAllClassUserOnlineTestInfosByRunId(selectedTestRunId);
			if (result.data) setStudentResults(result.data);
			if (isUpdated) setIsUpdated(false);
		};

		fetchData();
	},[selectedTestRunId, isUpdated]);

	useEffect(() => {
		if (!onlineTestId) return;

		const fetchData = async (_onlineTestId) => {
			const result = await getOnlineTestById(_onlineTestId);
			setOnlineTestData(result);
		};

		fetchData(onlineTestId);
    }, [onlineTestId]);

	useEffect(() => {
		if (!onlineTestData || studentResults.length === 0) return;
		const hasBeCheckedAnswers = onlineTestData.questions.filter(item => Number(item.questionType) === QUESTION_TYPES.FREE_WRITING);
		if (hasBeCheckedAnswers.length === 0) return;

		let isFoundNonCheckedAnswers = false;
		for (let i = 0; i < studentResults.length && !isFoundNonCheckedAnswers; i ++) {
			const testInfo = studentResults[i];
        	isFoundNonCheckedAnswers = getNonCheckAnswerList(onlineTestData, testInfo).length > 0;
		}

		if (isFoundNonCheckedAnswers)
			setHasNonCheckedAnswers(true);
    }, [onlineTestData, studentResults]);


	useEffect(() => {
		if (studentResults.length === 0 || !onlineTestData) return;
		const students = getClassStudents(documentClass.classUsers); //contain full name and id of each student

		const list = [];
		const _rowComments = [];

		for (let i = 0; i < students.length; i ++) {
			const student = students[i];
			const studentInfo = studentResults.find(item => item.owner === student.owner);

			if (studentInfo) {
				const answerNumber = studentInfo.answers.length;
				const correctAnswerNumber = studentInfo.answers.filter(item => item.isCorrect === SCORE_TYPE.CORRECT).length;
				const questionNumber = onlineTestData.questions.length;
				const isComplete = studentInfo.isComplete;
				const nonCheckedAnswersStatus = studentInfo.answers.length > 0 ? 
					(getNonCheckAnswerList(onlineTestData, studentInfo).length > 0 ? 1 : 0) : -1;
				const status = nonCheckedAnswersStatus === 1 ? SPEC_TEXT.BE_CHECKED : 
							   studentInfo.score === -1 ? SPEC_TEXT.BE_EVALUATED : 
							   nonCheckedAnswersStatus === 0 ? SPEC_TEXT.EXECUTED :
							   correctAnswerNumber === 0 ? SPEC_TEXT.NOT_EXECUTED : '';
							
				const checkSource = testRunDetails.runOpts ? // 1 - new way , 2 - obsolete way
					testRunDetails.runOpts.runIsAutoEstimateType : onlineTestData.isAutoEstimateType;
				const id = student.owner + '|' + (studentInfo._id);
	   
				const item = {
					name: student.name, 
					progress: isComplete ? 'Готово' : answerNumber + ' из ' + questionNumber,
					result: correctAnswerNumber + ' из ' + questionNumber,
					checkType: checkSource ? TXT_CHECK.AUTO : TXT_CHECK.MANUAL,
					status: status,
					score: studentInfo.score ? studentInfo.score.toString() : '',
					actions: '',
					id: id,
					personPict: student.personPict
				};
				list.push(item);
				_rowComments.push({id: id, text: 
					nonCheckedAnswersStatus === 1 ? 'Проверить' : 
					studentInfo.score === -1 ? 'Оценить' : 
					nonCheckedAnswersStatus === 0 ? 'Смотреть результат' : 'Тест не пройден'
				});
			}
		}

		setStudentData(list);
		setRowComments(_rowComments);
	},[documentClass.classUsers, studentResults, onlineTestData, testRunDetails]);

	const specialCellStyle = (item, rowInd, colInd, row) => {
        if (item.includes(TXT_CHECK.AUTO)) return {color: 'green'};
        if (item.includes(TXT_CHECK.MANUAL)) return {color: '#C96203'};
        if (item === SPEC_TEXT.NOT_EXECUTED) return {color: 'red'};
        if (item === SPEC_TEXT.BE_CHECKED) return {color: '#C96203'};
        if (item === SPEC_TEXT.BE_EVALUATED) return {color: '#C96203'};
        if (item === SPEC_TEXT.EXECUTED) return {color: 'green'};
		return getExceptionCellStyle(item);
    };

	const getPersonId = (idArr) => idArr.split('|')[0];
	const getOnlineTestInfoId = (idArr) => idArr.split('|')[1];

	const handlePerson = idArr => {
		history.push(FEATURES['profile'].to + '/' + getPersonId(idArr) + '/' + PROFILE_TAB.ONLINE_TESTS);
	};

	const handleRunningTestDetails = idArr => {  //click to open the test result of the student
		const userId = getPersonId(idArr);
		setSelectedStudentId(userId);
		setShowDetailstDlg(true);
	};

	const handleScoreUpdate = async (score, idArr) => {
		const onlineTestInfoId = getOnlineTestInfoId(idArr);
		const scoreVal = score !== '' ? Number(score) : null;
		await updateOnlineTestInfoScoreById(onlineTestInfoId, scoreVal);
		socket.emit('BE-refresh-db', {type: DB_REFRESH.OTI, roomId: documentClass._id});
		dispatch(loadCountersOt(documentClass._id));
		setIsUpdated(true);
	};

	const getTableHeader = () => {
		return [
				{column: 'Имя ученика', name: 'name', style: { width: '15%'}},
				{column: 'Ответы', name: 'progress', style: { width: '15%'}},
				{column: 'Правильные ответы', name: 'result', style: { width: '15%'}},
				{column: 'Вид проверки', name: 'checkType', style: { width: '13%'} }, 
				{column: 'Статус', name: 'status', style: { width: '12%'}},
				{column: 'Оценка учителя', name: 'score', style: { width: '15%'}},
				{column: 'Действия', name: 'actions', style: { width: '15%'}},
				{column: 'id', name: 'id', style: { width: '0%'}},
			];
	};

    return (
        <>
			<ContentHead flex={false}>
				<Back 
					onClick={()=> history.push('/reviewtest/ot')} 
					text="Перейти ко всем результатам" 
					icon="back" margin="bottom"
				/>
				<div className="cor-net__row mt_0">
					<div className="cor-net__col col-grow">
						<div className="cor_content__title">{'Результаты проведения онлайн теста ' + onlineTestData?.title}</div>
					</div>
					{testRunDetails &&
					<div className="cor-net__col">
						<div className="cor_content__title">Дата тестирования {printOnlyDate(testRunDetails.createdAt)}</div>
					</div>}
				</div>
	        </ContentHead>

			<Content>
				{getHeader('Описание: ' + onlineTestData?.description, '', false, 0, undefined, undefined)}
				<ContentBody>
					<Table 
						table={{header: getTableHeader(), 
							data: studentData, 
							handleTableDataUpdate: handleScoreUpdate, 
							specialCellStyle: specialCellStyle}}
						sort={{
							hasSorting: true, 
							initSortInd: 1
						}}
						person={{handlePerson: handlePerson}}
						actions={{
							handleShowDetails: idArr => handleRunningTestDetails(idArr),
							explanationOnIconType: TBL_ICON_NAME.SHOW_DETAILS,
							explanationDetailsById: rowComments,
						}}								
						canEditScore={true}
					/>
				</ContentBody>
			</Content>

			{showDetailstDlg &&
			<ViewStudentOnlineTestResultDlg 
				  showDlg={showDetailstDlg}
				  setShowDlg={setShowDetailstDlg}
				  selectedStudentId={selectedStudentId}
				  onlineTestId={onlineTestId}
				  selectedTestRunId={selectedTestRunId}
				  setIsUpdated={setIsUpdated}
			  />
			}
        </>
    );
};

export default ViewSelectedOnlineTestRun;
