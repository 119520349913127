import React from 'react';
import {Modal, Button} from 'rlabui';
import {TEST_TYPE} from "../utils/ServerInfo";
import {PROFILE_TAB}  from "../../template/Dashboard/DashboardProfile";
import {FEATURES} from "../../../api/config";
import {isAuth} from "../../../helpers/auth";
import "./PracticumTests.scss";

const ModalFinishTestByTeacherDlg = (props) => {
    const { showFinishTest, setShowFinishTest, testType, history } = props;

    const handleOpenProfile = () => {
        const profileTabIOnd = testType === TEST_TYPE.ONLINE_TEST ? PROFILE_TAB.ONLINE_TESTS : 
                               testType === TEST_TYPE.LAB_WORK ? PROFILE_TAB.LAB_WORKS : 
                               PROFILE_TAB.VIRT_LAB;
        history.push(FEATURES['profile'].to + '/' + isAuth()._id + '/' + profileTabIOnd);
        setShowFinishTest(false);
    };

    const doFinishTest = () => {
        const path = testType === TEST_TYPE.ONLINE_TEST ? '/practicum' : 
                     testType === TEST_TYPE.LAB_WORK ? '/practicum' : '/';
        history.push(path);
        setShowFinishTest(false);
    };

    if (!showFinishTest) {
        return null;
    }

    return (
        <Modal visible={showFinishTest} size="xs">
            <Modal.Head modalClose={doFinishTest} title='Работа оценена' />
            <Modal.Body>Учитель оценил ваши результаты, с ними можно ознакомиться в профиле ученика</Modal.Body>
            <Modal.Footer>
                <Button onClick={handleOpenProfile}>Перейти в профиль</Button>
                <Button onClick={doFinishTest} border={true}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default ModalFinishTestByTeacherDlg;
