import React, {useEffect, useRef} from 'react';
import * as Yup from "yup";
import Modal from '../Modal';
import {userApi} from "../../api/api";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import Password from "../ui/Password";
import "./Settings.scss";
import {fullName, generatePassword, isAuth} from "../../helpers/auth";
import {reloadUser} from "../../redux/slices/storage";
import {useDispatch} from "react-redux";

const UserPassword = (props) => {
  const dispatch = useDispatch();
  const passwordNewRef = useRef();
  const passwordNewRef2 = useRef();

  const handleClose = () => {
    formik.resetForm();
    props.showUserPassword({showModal: false});
  };

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) handleClose();
    };
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  Yup.addMethod(Yup.string, "checkPassword", function (errorMessage) {
    return this.test(`checkPassword`, errorMessage, function (value) {
      const {path, createError} = this;
      return (
        (path === "passwordNew" ? formik.values.passwordNew2 : formik.values.passwordNew) === value ||
        createError({path, message: errorMessage})
      );
    });
  });

  let error = {visible: false, message: ""};
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      passwordOld: '',
      passwordNew: '',
      passwordNew2: '',
    },
    validationSchema: Yup.object({
      passwordOld: Yup.string().required("Введите старый пароль"),
      passwordNew: Yup.string().required("Введите пароль").min(8, "Минимум 8 символов").matches(/\S/, "Только пробелы запрещены "),
      passwordNew2: Yup.string().required("Введите пароль").checkPassword("Пароли не совпадают"),
    }),
    onSubmit: async (formData) => {
      try {
        const data = {
          _id: props.user._id,
          password: formData.passwordOld,
          passwordNew: formData.passwordNew,
        };

        await userApi.password(data)
        .then((updated) => {
          if (props.user._id === isAuth()._id) {
            dispatch(reloadUser(updated));
          }
          handleClose();
        })
        .catch((err) => {
          toast.error(err.response.data.errors);
        });
      } catch (err) {
        error = {
          visible: true,
          message: JSON.stringify(err.errors, null, 2),
        };
        toast.error(error);
      }
    },
  });

  return (
    <>
      <Modal visible={props.showModal} content={
        <form onSubmit={formik.handleSubmit}>
          <div className="modal__close" onClick={handleClose}/>
          <div className="modal__message">Изменение пароля {fullName(props.user)}</div>
          <div className="modal__body">
            <div key="passwordOld" className="cor_settings_vert">
              <label htmlFor="passwordOld">Текущий пароль</label>
              <div className="form_error">{formik.errors.passwordOld}</div>
              <Password name="passwordOld"
                placeholder="Текущий пароль"
                className={formik.errors.passwordOld ? "form_error" : ""}
                onChange={formik.handleChange}
                value={formik.values.passwordOld}
              />
            </div>
            <div key="passwordNew" className="cor_settings_vert">
              <label htmlFor="passwordNew">Новый пароль</label>
              <div className="form_error">{formik.errors.passwordNew}</div>
              <Password name="passwordNew" ref={passwordNewRef}
                placeholder="Новый пароль"
                className={formik.errors.passwordNew ? "form_error" : ""}
                onChange={formik.handleChange}
                value={formik.values.passwordNew}
              />
            </div>
            <div key="passwordNew2" className="cor_settings_vert">
              <label htmlFor="passwordNew2">Повтор нового пароля</label>
              <div className="form_error">{formik.errors.passwordNew2}</div>
              <Password name="passwordNew2"  ref={passwordNewRef2}
                placeholder="Повтор нового пароля"
                className={formik.errors.passwordNew2 ? "form_error" : ""}
                onChange={formik.handleChange}
                value={formik.values.passwordNew2}
              />
            </div>
          </div>
          <div className="modal__action">
            <button
              className="cor_btn"
              type="button"
              onClick={()=>{
                const newPass = generatePassword(16);
                formik.setFieldValue("passwordNew", newPass).then(()=>formik.setFieldValue("passwordNew2", newPass));
                // noinspection JSUnresolvedReference
                passwordNewRef.current.setEye(true);
                // noinspection JSUnresolvedReference
                passwordNewRef2.current.setEye(true);
            }}>Сгенерировать пароль</button>
            <div style={{width: "50%"}}/>
            <button
              className="cor_btn cor_btn_primary"
              type="submit"
            >Сохранить
            </button>
          </div>
        </form>
      } size={props.role === 2 ? 'md' : ''}/>
    </>
  )
};

export default UserPassword;