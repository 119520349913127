import React, { useEffect, useState } from 'react';
import ss from './SensorDataModal.module.scss';
import { Modal, Button, Dropdown } from 'rlabui';

const params = ['Минимальное', 'Максимальное', 'Среднее', 'Стандартное отклонение', 'Площадь'];

const SensorDataModal = ({ modal, markers, data, setSensorsSummaryModal, sensors, imported, writed, writedData, played, paused }) => {
    const [current, setCurrent] = useState(modal);
    const [sensorName, setSensorName] = useState();
    const [sensorData, setSensorData] = useState([]);
    const [sensorMarkers, setSensorMarkers] = useState(markers[current]);
    const [tableData, setTableData] = useState([]);
    const [tableHead, setTableHead] = useState([]);
    const [round, setRound] = useState(3);

    useEffect(() => {
        setCurrent(modal);
    }, [modal, setCurrent]);

    useEffect(() => {
        if ((typeof current === 'string' || typeof current === 'number') && (!played || paused)) {
            const sensor = [...sensors, ...imported, ...writed]?.find((e) => e?.num === current);
            let postfix = '';
            let vals;

            if (typeof current === 'string') {
                if (current.includes('imp')) {
                    vals = sensor.value;
                    postfix = ' (Имп)';
                } else {
                    vals = writedData?.find((e) => e[0]?.num === current);
                    postfix = ' (Лог)';
                }
            } else {
                vals = data?.find((e) => e[0]?.num === current);
            }

            if (markers[current]) setSensorMarkers(markers[current]);
            if (sensor) {
                setRound(sensor.roundDigit);
                setSensorName(sensor.name + postfix);
            }
            if (vals?.length) setSensorData(vals);
        }
    }, [current, data, writedData, markers, sensors, imported, writed, , setSensorsSummaryModal, sensorData, setSensorName, setSensorMarkers, played]);

    useEffect(() => {
        const head = [];
        const table = [];
        if (sensorData?.length) {
            const arr = getTableColValues(sensorData);
            head.push('Данные');
            for (let index = 0; index < arr?.length; index++) {
                const val = +arr[index];
                table.push({ name: params[index], data: [+val?.toFixed(index === 4 ? 3 : round)] });
            }
        }
        if (sensorMarkers && Object.keys(sensorMarkers)?.length) {
            let rCount = 0;
            let markers = [];
            let ranges = [];
            for (const key in sensorMarkers) {
                const marker = sensorMarkers[key];
                if (!marker.resizer) {
                    if (marker.type === 'line') {
                        const { value: x, yValue: y } = marker;
                        markers.push({ x, y });
                    } else {
                        const { xMin, xMax } = marker;
                        ranges.push(sensorData.filter((e) => e.x >= xMin && e.x <= xMax));
                    }
                }
            }
            if (markers.length > 0) {
                head.push(`Маркеры`);
                const arr = getTableColValues(markers);
                for (let index = 0; index < arr.length; index++) {
                    const val = +arr[index];
                    table[index]?.data?.push(index === 4 ? '-' : +val?.toFixed(round));
                }
            }
            if (ranges.length > 0) {
                ranges.forEach((range) => {
                    const arr = getTableColValues(range);
                    head.push(`Диапазон ${++rCount}`);
                    for (let index = 0; index < arr.length; index++) {
                        const val = +arr[index];
                        table[index]?.data?.push(+val?.toFixed(index === 4 ? 3 : round));
                    }
                });
            }
        }
        setTableData(table);
        setTableHead(head);

        function getTableColValues(arr) {
            const x = arr.map((e) => e.x);
            const y = arr.map((e) => e.y);
            const min = Math.min(...y);
            const max = Math.max(...y);
            const mean = y.reduce((a, b) => a + b) / y.length;
            const std = getStandardDeviation(y);
            const area = getArea(x, y) / 1000;
            return [min, max, mean, std, area];
        }

        function getStandardDeviation(array) {
            const n = array.length;
            const mean = array.reduce((a, b) => a + b) / n;
            return Math.sqrt(array.map((e) => Math.pow(e - mean, 2)).reduce((a, b) => a + b) / n);
        }

        function getArea(x, y) {
            const area = getIntgral(x, y);
            return area.at(-1) || 0;
        }

        function getDerivative(t, y) {
            let N = t.length;
            let d = new Array(N);
            d[0] = (-3 * y[0] + 4 * y[1] - y[2]) / (t[2] - t[0]);

            for (let i = 1; i < N - 1; i++) {
                d[i] = (y[i + 1] - y[i - 1]) / (t[i + 1] - t[i - 1]);
            }

            d[N - 1] = (y[N - 3] - 4 * y[N - 2] + 3 * y[N - 1]) / (t[N - 1] - t[N - 3]);
            return d;
        }

        function getIntgral(t, y) {
            let N = t.length;
            let s = [];
            let Y = [];
            let dy = getDerivative(t, y);
            s[0] = ((t[0] - t[0 - 1]) * (y[0 - 1] + y[0])) / 2 + ((t[0] - t[0 - 1]) * (t[0] - t[0 - 1]) * (dy[0 - 1] - dy[0])) / 12;
            Y[0] = 0;

            for (var k = 1; k < N; k++) {
                s[k] = ((t[k] - t[k - 1]) * (y[k - 1] + y[k])) / 2 + ((t[k] - t[k - 1]) * (t[k] - t[k - 1]) * (dy[k - 1] - dy[k])) / 12;
                Y[k] = Y[k - 1] + s[k];
            }
            return Y;
        }
        // console.log(table);
    }, [sensorData, sensorMarkers, round, markers]);

    return (
        <Modal
            visible={(typeof current === 'string' || typeof current === 'number') && sensorData.length > 0}
            className={ss.root}
            size="lg"
        >
            <Modal.Head
                modalClose={() => setSensorsSummaryModal()}
                title={`Значения датчика "${sensorName}"`}
            ></Modal.Head>
            <Modal.Body>
                <table className={ss.table}>
                    <thead>
                        <tr>
                            <th>
                                {
                                    <Dropdown value={sensorName || 'Выберите датчик'}>
                                        {[...imported, ...writed, ...sensors].map(({ name, num }) => {
                                            let title = name;
                                            if (typeof num === 'string') {
                                                if (num.includes('imp')) {
                                                    title = name + ' (Имп)';
                                                } else {
                                                    title = name + ' (Лог)';
                                                }
                                            }
                                            return (
                                                <Dropdown.Item
                                                    key={num}
                                                    onClick={() => setCurrent(num)}
                                                >
                                                    {title}
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown>
                                }
                            </th>
                            {tableHead.map((e, i) => (
                                <th key={i}>{e}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map(({ name, data }, i) => (
                            <tr key={i}>
                                <td>{name}</td>
                                {data.map((e, k) => (
                                    <td key={k}>{e}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer align="left">
                <Button
                    onClick={() => setSensorsSummaryModal()}
                    border={true}
                >
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SensorDataModal;
