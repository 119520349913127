import React, {useState, useEffect} from "react";
import Footer from '../Footer';
import {onlineTestInfoApi, onlineTestRunApi} from "../../../api/api";
import LabWorkShow from '../../practicum/LabWorkShow';
import OnlineTestExecute from '../../OnlineTest/OnlineTestExecute';
import {TEST_OPTION, getRunningTestInfo, clearRunningTestInfo} from "../../ui/utils/ServerInfo";
import {isAuth} from "../../../helpers/auth";
import {ContentWrap} from "../ContentParts";
import {useSelector} from "react-redux";
import OnlineTestStudentDelayed from "../../OnlineTest/OnlineTestStudentDelayed";
import {getOnlineTestInfoByRunId} from "../../OnlineTest/ot_utils";
import {storageOrg} from "../../../redux/slices/storage";
import {hasFeature} from "../../../helpers/auth";
import DashboardLicense from "./DashboardLicense";
import "./Dashboard.scss";
import "../../practicum/TutorialSelectTab.scss";

export const DashboardTestExecute = (props) => {
	const [otSettings, setOtSettings] = useState(undefined);
	const [isSetup, setIsSetup] = useState(false);
  const documentOrg = useSelector(storageOrg);

  const isLW = props.location.pathname.includes('/lw');
  const isOT = props.location.pathname.includes('/ot');
  const otiId = props.match.params.id; //if student runs a test
  const ti = getRunningTestInfo();

  const prepareOtSettings = (isTi, onlineTestId, testRunId, onlineTestInfoId, runOpts) => {
      const _otSettings = {
        onlineTestId: onlineTestId,
        testRunId: testRunId,
        onlineTestInfoId: onlineTestInfoId,
        startTime: isTi ? new Date(runOpts.runStartTime).valueOf() : new Date().valueOf(),
        timeLimitInMinutes: runOpts.runTimeLimitInMinutes,
        timeLimitEnabled: runOpts.runTimeLimitEnabled,
        isAutoEstimateType: runOpts.runIsAutoEstimateType,
        isRandomOrder: runOpts.runIsRandomOrder,
    };
    setOtSettings(_otSettings);
    setIsSetup(true);
  };

	useEffect(() => {
		const setOtSettingsByTestInfo = async (_ti) => {
			const otis = await getOnlineTestInfoByRunId(isAuth()._id, _ti.testRunId);
      const oti = otis[0];
      const otRun = await onlineTestRunApi.getOnlineTestRunById(oti.otrun);
      prepareOtSettings(true, _ti.labWorkId, _ti.testRunId, oti._id, 
        otRun.runOpts);
		};

    const setOtSettingsByOtiId = async (_otiId) => {
			const oti = await onlineTestInfoApi.getOnlineTestInfoById(_otiId);
      const otRun = await onlineTestRunApi.getOnlineTestRunById(oti.otrun);
      prepareOtSettings(false, oti.onlineTest, oti.otrun, oti._id, 
        otRun.runOpts);
		};

    if (!isOT || otSettings) return;

    if (!isSetup) {
    if (otiId) {
      setOtSettingsByOtiId(otiId); //отложенный тест запущен учеником
      clearRunningTestInfo(); //гарантируем, что незаконченный ранее общий тест завершен
    } else if (!!ti) {
        setOtSettingsByTestInfo(ti);  //тест запущен учителем
      }
    } else {
      if (!ti && !otiId) {
        setIsSetup(false);
      }
    }
	}, [isOT, otiId, ti, isSetup, otSettings]);

  if (!(hasFeature(documentOrg, TEST_OPTION.OT) || hasFeature(documentOrg, TEST_OPTION.LW)))
    return <DashboardLicense {...props}/>;

  if (isLW) {
    return (
      <ContentWrap>
        <LabWorkShow {...props} />
        <Footer {...props}/>
      </ContentWrap>
    );
  }

  if (isOT) {
    return (
      <ContentWrap>
        {otSettings ? 
          <OnlineTestExecute {...props} otSettings={otSettings} clearOtSettings={setOtSettings} />
        :
          <OnlineTestStudentDelayed {...props} /> 
        }        
        <Footer {...props}/>
      </ContentWrap>
    );
  }

  return <></>;
}

export default DashboardTestExecute;
