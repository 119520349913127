import React, {useState, useEffect} from 'react';
import { Modal, Button, Checkbox } from 'rlabui';
import { toast } from 'react-toastify';
import {profilePicture} from "../../../helpers/auth";
import {Icon} from '../';
import { getSwitchedList, getListWithSwitchedRow } from "../utils/gen_utils";
import ss from './ModalSelectStudentsDialog.module.scss';

const ModalSelectStudentsDialog = ({ showConfirmDlg, studentList, selectedStudentIds, 
        handleNo, handleYes }) => {
    const [selectedStudents, setSelectedStudents] = useState([]);

	useEffect(() => {
        const list = [];
		for (let i = 0; i < studentList.length; i ++) {
			const student = studentList[i]; 
            const isSelected = selectedStudentIds.find(item => item === student._id);

			const record = {
				name: student.name +  (student.name2 ? ' ' + student.name2 : ''),
				id: '' + student._id + '|' + (!isSelected ? '0' : '1'),
				personPict: student.personPict
			};

			list.push(record);
		}
        setSelectedStudents(list);
	}, [studentList, selectedStudentIds]);

	const handleSwitchAll = (isToOn) => {
		setSelectedStudents(getSwitchedList(selectedStudents, isToOn));
	};

	const handleSwitchUser = (ids) => {
		setSelectedStudents(getListWithSwitchedRow(selectedStudents, ids));
	};

    const handleSaveIds = () => {
        const ids = selectedStudents.filter(item => item.id.split('|')[1] === '1')
            .map(item => item.id.split('|')[0]);
        if (ids.length === 0) {
            toast.warning('Выберите по крайней мере 1 ученика.');
        } else {
            handleYes(ids);
        }
    };

	const getUser = (user) => {
		const [id, value] = user.id.split('|');
		
		return (
			<div className={ss.item} key={user.id}>
				<Checkbox 
					className={ss.item__checkbox}
					value={value === '1'}
					checked={value === '1'}
					onChange={() => handleSwitchUser(user.id)}
					label={
						<div className={ss.item__content}>
							{!user.personPict ? 
								<Icon name="user" className={ss.item__icon} key={'icon'+user.id} />
							:
								<span className={ss.item__picture} key={'sp'+user.id} >
									<img src={profilePicture(user.personPict)} alt='' key={'img'+user.id} />
								</span>
							}
							<span className={ss.item__name} key={'name'+user.id} >
								{user.name}
							</span>
						</div>
					}
				/>
			</div>
		);		
	};

    if (!showConfirmDlg) {
        return false;
    }

    return (
        <Modal className={ss.root} visible={showConfirmDlg} size="sm">
            <Modal.Head modalClose={handleNo} title={'Выберите учеников для тестирования'} />

			<div className={ss.action}>
				<Button onClick={() => handleSwitchAll(true)}>Включить всех учеников</Button>
				<Button color="danger" onClick={() => handleSwitchAll(false)}>Исключить всех учеников</Button>
			</div>

            <Modal.Body>
				<div className={ss.main}>
					<>
						{selectedStudents.map(item => getUser(item))}
					</>
				</div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={handleSaveIds}>Подтвердить</Button>
                <Button onClick={handleNo} border={true}>Отменить</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default ModalSelectStudentsDialog;
