import React, { useState, useRef, useEffect } from 'react';
import Icon from '../Icon';
import {toast} from "react-toastify";
import { Dropdown, Button, Input } from 'rlabui';
import html2canvas from 'html2canvas';
import {
    SCREEN_MODE, MEASURE_TYPE, DELETE_ALL_MEASURES_MARKER, IMAGE_SHIFT_X, IMAGE_SHIFT_Y, SELECTED_FRAME_ID,
    DRAG_ACTION, ICON_ACTION, DEFAULT_SCALE_SIZE, DEFAULT_SCALE_UNIT_ID, STREAM_INPUT, VIDEO_SOURCE,
    saveNewFrame, getImageNumber, getMeasure, getExportCB,findActiveFigure,
    dragFigure, drawFigures, recalcAllImageMeasures, findFigureIconAction, sizeUnitList, getScaleUnitById,
    getEmptyFigureOpts, areEqualEmptyFigureOpts, doUploadNewVideo, saveVideosInDb, getFramesBeExported,
    getMicroscopeInstruction, getVideoFileInstruction, getVideoFileExampleRef, getCameras,
    startRecording, stopAndClearRecording, updateLocalStream, getVideoName, saveXLSX} from './utils';
import { VideoEditorItem, VideoEditorXlsxExport } from './';
import Moment from 'moment';
import DeleteFrameDlg from './ConfirmDlg';
import DeleteAllFramesDlg from './ConfirmDlg';
import CameraChoiceDlg from './CameraChoiceDlg';
import CameraFileNameDlg from './CameraFileNameDlg';
import ChangeVideoSourceDlg from './ChangeVideoSourceDlg';
import { drawImageScales } from './scale_utils';
import './VideoEditor.scss';

const CAN_ADD_COMMENT = false;
const DEBOUNCE_SCAN_PERIOD = 1500;

const VideoEditor = (props) => {
    const { isCor } = props;
    const useAdvanceFeatures = false;
    const [corOptions, setCorOptions] = useState(undefined);
    const [videoSource, setVideoSource] = useState(VIDEO_SOURCE.UNDEFINED);
    const [videoList, setVideoList] = useState([]);
    const [currVideoInd, setCurrVideoInd] = useState(-1);
    const [resize, setResize] = useState(0);
    const [isResized, setIsResized] = useState(false);
    const [counter, setCounter] = useState(0);
    const [currMode, setCurrMode] = useState(SCREEN_MODE.DO_LOAD_VIDEO);
    const [pngFrames, setPngFrames] = useState([]);
    const [selectedFrameInd, setSelectedFrameInd] = useState(-1);
    const [deleteFrameInd, setDeleteFrameInd] = useState(-1);
    const [videoSize, setVideoSize] = useState([1, 1]);
    const [imageNaturalSize, setImageNaturalSize] = useState([1, 1]);
    const [imageSize, setImageSize] = useState([100, 100]);
    const [isSelectedFrameIndChanged, setIsSelectedFrameIndChanged] = useState(false);
    const [currScale, setCurrScale] = useState(DEFAULT_SCALE_SIZE);
    const [saveScale, setSaveScale] = useState(null);
    const [isCurrScaleChanged, setIsCurrScaleChanged] = useState(false);
    const [currUnitId, setCurrUnitId] = useState(DEFAULT_SCALE_UNIT_ID);
    const [selectedActionType, setSelectedActionType] = useState(MEASURE_TYPE.RECT);
    const [showDeleteFrameDlg, setShowDeleteFrameDlg] = useState(false);
    const [showDeleteAllFramesDlg, setShowDeleteAllFramesDlg] = useState(false);
    const [showSaveXlsDlg, setShowSaveXlsDlg] = useState(false);
    const [ctx, setCtx] = useState(null);
    const [dragging, setDragging] = useState(false);
    const [isFrameChanged, setIsFrameChanged] = useState(false);
    const [shouldSaveFrame, setShouldSaveFrame] = useState(false);
    //current figure:
    const [selectedFigureOpts, setSelectedFigureOpts] = useState(getEmptyFigureOpts(selectedActionType));
    const [currFigureRect, setCurrFigureRect] = useState(null); //selected figure (rect/line/circle)
    const [updatedExportImage, setUpdatedExportImage] = useState(undefined);
    //camera options:
    const [showCameraChoiceDlg, setShowCameraChoiceDlg] = useState(false);
    const [cameraList, setCameraList] = useState([]);
    const [selectedCameraId, setSelectedCameraId] = useState(undefined);
    const [isCameraRecord, setIsCameraRecord] = useState(false);
    const [recorder, setRecorder] = useState(null);
    const [restartRecoringStep, setRestartRecoringStep] = useState(0);
    const [localStream, setLocalStream] = useState(null);
    const [isSaveCameraFiles, setIsSaveCameraFiles] = useState(false);
    const [showCameraFileNameDlg, setShowCameraFileNameDlg] = useState(false);
    const [initCameraFileName, setInitCameraFileName] = useState('');
    const [showChangeVideoStreamDlg, setShowChangeVideoStreamDlg] = useState(false);
    const [savedCameraFileData, setSavedCameraFileData] = useState(null);
    const [isCleanupLibVideo, setIsCleanupLibVideo] = useState(false);
    //
    const [videoDbRecord, setVideoDbRecord] = useState(undefined);
    const [videoSelectList, setVideoSelectList] = useState([]);
    const [framesBeExported, setFramesBeExported] = useState([]);
    const [fileNamesExported, setFileNamesExported] = useState([]);
    const [canExportToDb, setCanExportToDb] = useState(false);
    const [exportedFileData, setExportedFileData] = useState(undefined);
    const [mouseMoveCoords, setMouseMoveCoords] = useState(undefined);
    const debounceMouseRef = useRef();

    const mainBoxRef = useRef(null);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const signatureListRef = useRef(null);
    const debounceScanRef = useRef(null);

    useEffect(() => {
        if (!props.options) return;
        setCorOptions(props.options); //if isCor=false, contans info about Camera/Video
        return () => {
            setCorOptions(undefined);
        };
    }, [props.options]);

    useEffect(() => {
        if (!corOptions) return;
        getCameras(setCameraList);
        if (!useAdvanceFeatures) setVideoSource(corOptions.videoSource);
    }, [useAdvanceFeatures, corOptions]);

    const doSetCurrMode = (_isCor, _corOptions, mode) => {
        if (mode !== SCREEN_MODE.SHOW_FRAME) {
            setCtx(null);
        }
        setCurrMode(mode);
        if (_isCor && _corOptions) _corOptions.setCurrMode(mode);

        if (mode === SCREEN_MODE.SHOW_FRAME) {
            setIsCameraRecord(false);
        } else if (mode === SCREEN_MODE.SHOW_VIDEO) {
            setIsCameraRecord(true);
        }
    };

    useEffect(() => {
        //запускается при переходе в режим видео
        if (!isCor || !corOptions) return;
        if (corOptions.fireVideoMode) {
            //change mode from FRAME to VIDEO
            doSetCurrMode(isCor, corOptions, SCREEN_MODE.SHOW_VIDEO);
            corOptions.setFireVideoMode(false);
            //setIsCameraRecord(true); //вернулись к режиму видео. начинаем снова запись с камеры или показ выбранного видео.
        }
        corOptions.setSelectedFrameInd(selectedFrameInd); //change frame ind
    }, [isCor, corOptions, selectedFrameInd]);

    useEffect(() => {
        // определить имя видео
        if (!isCor || !corOptions || videoList.length === 0) return;
        const vName = getVideoName(videoSource, currVideoInd, videoList);
        corOptions.setVideoName(vName);
    }, [isCor, videoList, corOptions, currVideoInd, videoSource]);

    useEffect(() => {
        if (pngFrames.length === 0) {
            setSelectedFrameInd(-1);
            setIsSelectedFrameIndChanged(true);
        }
    }, [pngFrames]);

    useEffect(() => {
        const drawImageAndFigures = (_ctx, _canvas, img, unitName, _currFigure, _dragging, _selectedFigureOpts) => {
            if (!img) return;
            const image = new Image();
            image.src = img.frameData;
            const nWidth = image.naturalWidth;
            const nHeight = image.naturalHeight;
            const [insWidth, insHeight] = imageNaturalSize;

            if (insWidth !== nWidth || insHeight !== nHeight) setImageNaturalSize([nWidth, nHeight]);

            image.onload = () => {
                //_ctx.drawImage(image, IMAGE_SHIFT_X, IMAGE_SHIFT_Y, _canvas.width, _canvas.height);
                _ctx.drawImage(image, IMAGE_SHIFT_X, IMAGE_SHIFT_Y, _canvas.width - IMAGE_SHIFT_X, _canvas.height - IMAGE_SHIFT_Y);
                drawFigures(_ctx, img, unitName, _currFigure, _dragging, _selectedFigureOpts, false, signatureListRef, imageSize, CAN_ADD_COMMENT);
                drawImageScales(_ctx, IMAGE_SHIFT_X, IMAGE_SHIFT_Y, _canvas.width, _canvas.height, currScale);
            };
        };

        if (currMode !== SCREEN_MODE.SHOW_FRAME || !canvasRef.current || selectedFrameInd === -1) return;
        const canvas = canvasRef.current;
        let _ctx = ctx;
        if (!ctx) {
            _ctx = canvas.getContext('2d');
        }

        if (isCurrScaleChanged) {
            _ctx.clearRect(0, 0, canvas.width, canvas.height);
            setIsCurrScaleChanged(false);
        }

        const frame = pngFrames[selectedFrameInd];
        const unitName = videoList[frame.videoInd].videoUnitName;

        drawImageAndFigures(_ctx, canvas, frame, unitName, currFigureRect, dragging, selectedFigureOpts);

        if (!ctx) {
            setCtx(_ctx);
            doFrameChanged();
        }
    }, [ctx, currFigureRect, currMode, currScale, dragging, imageNaturalSize, imageSize, 
        isCurrScaleChanged, pngFrames, selectedFigureOpts, selectedFrameInd, videoList]);

    useEffect(() => {
        setSelectedFigureOpts(getEmptyFigureOpts(selectedActionType));
    }, [selectedFrameInd, selectedActionType]);

    const handleMouseDown = (e) => {
        const handleSaveMeasureComment = (_selectedFrameInd, _selectedMeasureInd, comment) => {
            const frame = pngFrames[selectedFrameInd];
            const measure = { ...frame.measureList[_selectedMeasureInd], comment: comment };
            const list = frame.measureList.map((item, ind) => (ind !== _selectedMeasureInd ? item : measure));
            const updatedImageData = { ...frame, measureList: list };
            doUpdateFrames(pngFrames, selectedFrameInd, updatedImageData);
        };
    
        const { offsetX, offsetY } = e.nativeEvent;

        if (!!selectedFigureOpts.dragAction) {
            // found when mouse move
            if (selectedFigureOpts.figureInd !== -1) {
                const frame = pngFrames[selectedFrameInd];
                const measure = frame.measureList[selectedFigureOpts.figureInd];
                setCurrFigureRect(measure.rectangle);
                setDragging(true);
            }
        } else {
            const figureActionOpts = findFigureIconAction(offsetX, offsetY, signatureListRef);
            if (!!figureActionOpts) {
                //console.log('2-figureActionOpts=', figureActionOpts);

                //can add/edit a comment or delete the figure
                const figureInd = figureActionOpts.figureIndex;
                const iconAction = figureActionOpts.iconAction;

                if (iconAction === ICON_ACTION.EDIT) {
                    //not implemented here
                    handleSaveMeasureComment(selectedFrameInd, figureInd, 'comment');
                    //console.log('3-EDIT=');
                } else {
                    //console.log('FOUND: figureActionOpts=', figureActionOpts);
                    // do deletion:
                    //console.log('4-DELETE. selectedFrameInd=', figureInd);
                    doDeleteMeasure(selectedFrameInd, figureInd);
                }
            } else {
                //console.log('5-MISC');
                setSelectedFigureOpts({
                    figureInd: -1,
                    figureActionType: selectedActionType,
                    dragAction: DRAG_ACTION.TOP_LEFT,
                });

                if (offsetX > IMAGE_SHIFT_X && offsetY > IMAGE_SHIFT_Y) {
                    // it is out of the scale place
                    const rect = { x: offsetX, y: offsetY, width: 0, height: 0 }; //new figure depends on ACTION!! change (for circle x,y is center, width is radius)
                    setCurrFigureRect(rect);
                    setDragging(true);
                }
            }
        }
    };

    const handleMouseMove = (e) => {
        const { offsetX, offsetY } = e.nativeEvent;
        if (selectedFrameInd === -1) return;

        if (!dragging) {
            if (offsetX <= IMAGE_SHIFT_X || offsetY <= IMAGE_SHIFT_Y) {
                // it is the scale area
                if (selectedFigureOpts.figureInd !== -1)
                    //clean up the options
                    setSelectedFigureOpts(getEmptyFigureOpts(selectedActionType));
            } else {
                //try to find a figure
                const opts = findActiveFigure(offsetX, offsetY, pngFrames[selectedFrameInd], selectedActionType);
                if (!areEqualEmptyFigureOpts(selectedFigureOpts, opts)) {
                    //found a new figure
                    setSelectedFigureOpts(opts);
                }
            }
        } else {
            //dragging the figure. offset0 - start coords, offsetX - current coords
            const offset0 = [selectedFigureOpts.offsetX, selectedFigureOpts.offsetY];
            dragFigure(offsetX, offsetY, currFigureRect, setCurrFigureRect, offset0, 
                selectedFigureOpts, imageSize);
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
        doFrameChanged();

        if (currFigureRect && Math.max(Math.abs(currFigureRect.width), Math.abs(currFigureRect.height)) >= 5) {
            const selectedFrame = pngFrames[selectedFrameInd];
            const imageData = getFrameWithSavedMeasure(selectedFrame, selectedFigureOpts.figureActionType, currFigureRect);
            doUpdateFrames(pngFrames, selectedFrameInd, imageData);
        }

        setCurrFigureRect(null);
    };

    const getFrameWithSavedMeasure = (frame, figureActionType, figureRect) => {
        if (!frame) return undefined;
        const updatedFrame = { ...frame };

        const innerImageSize = getInnerImageScale(imageSize);
        const measure = getMeasure(figureActionType, figureRect, innerImageSize, videoList[frame.videoInd].videoScale);

        if (selectedFigureOpts.figureInd === -1)
            //new figure
            updatedFrame.measureList.push(measure);
        //updated figure
        else updatedFrame.measureList = updatedFrame.measureList.map((item, ind) => 
            (ind !== selectedFigureOpts.figureInd ? item : measure));

        return updatedFrame;
    };

    const doUpdateFrames = (_pngFrames, _selectedFrameInd, updatedImageData) => {
        const updatedFrames = [..._pngFrames];

        updatedFrames[_selectedFrameInd] = {
            ...updatedImageData,
            measureList: [...updatedImageData.measureList],
        };
        setPngFrames(updatedFrames);
    };

    const doFrameChanged = () => {
        if (debounceScanRef.current) clearTimeout(debounceScanRef.current);
        debounceScanRef.current = setTimeout(() => setIsFrameChanged(true), DEBOUNCE_SCAN_PERIOD);
    };

    const scanNewExportFrame = async (setIsFrameChanged, setUpdatedExportImage) => {
        const screenshot = document.getElementById(SELECTED_FRAME_ID);
        const opts = {
            useCORS: true,
            allowTaint: true,
        };
        //const base64image = await html2canvas(screenshot, opts).then(canvas => canvas.toDataURL('image/img'));
        const canvas = await html2canvas(screenshot, opts);
        const base64image = canvas.toDataURL('image/img');

        if (base64image) {
            setUpdatedExportImage(base64image);
            setIsFrameChanged(false);
        }
    };

    useEffect(() => {
        if (!isCor || currMode !== SCREEN_MODE.SHOW_FRAME || !isFrameChanged) return;
        scanNewExportFrame(setIsFrameChanged, setUpdatedExportImage);
    }, [isCor, currMode, isFrameChanged]);

    useEffect(() => {
        if (!updatedExportImage || selectedFrameInd === -1) return;
        const selectedFrame = pngFrames[selectedFrameInd];
        //сохраняем заново отсканированный кадр для экспорта
        const updatedImageData = { ...selectedFrame, frameExportData: updatedExportImage };
        doUpdateFrames(pngFrames, selectedFrameInd, updatedImageData);
        setUpdatedExportImage(undefined);
    }, [selectedFrameInd, updatedExportImage, pngFrames]);

    useEffect(() => {
        const handleResize = (ev) => setResize([window.innerWidth]);
        window.addEventListener('resize', (ev) => handleResize(ev));
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const counterInProgress = () => setCounter((cnt) => cnt + 1);
        const cntId = setInterval(counterInProgress, 1000);
        return () => {
            clearInterval(cntId);
        };
    }, []);

    useEffect(() => {
        //step 1 of 4 - start
        if (localStream && !localStream.active && restartRecoringStep === 0) {
            setRestartRecoringStep(1);
        }
    }, [counter, localStream, restartRecoringStep]);
    useEffect(() => {
        //step 2 of 4 - clear localStream
        if (restartRecoringStep !== 1) return;
        stopAndClearRecording(videoRef.current, localStream, recorder, setRecorder, '', () => {});
        setLocalStream(null);
        setRestartRecoringStep(2);
    }, [localStream, recorder, restartRecoringStep]);
    useEffect(() => {
        //step 3 of 4 - check if the selected camera is on
        const checkCameras = (newList) => {
            const found = newList.find((item) => item.id === selectedCameraId);
            if (found) setRestartRecoringStep(3);
        };

        if (restartRecoringStep !== 2 || localStream) return;
        getCameras(checkCameras);
    }, [isCameraRecord, localStream, restartRecoringStep, selectedCameraId, counter]);
    useEffect(() => {
        //step 4 of 4 - camera is on, let's update the localStream
        const doSetLocalStream = async (v) => {
            setLocalStream(v);
            setRestartRecoringStep(0); //go to the last step: stert recording (see useEffect below)
        };

        if (restartRecoringStep !== 3) return;
        updateLocalStream(videoRef, selectedCameraId, isCameraRecord, doSetLocalStream);
    }, [isCameraRecord, restartRecoringStep, selectedCameraId]);

    useEffect(() => {
        const w = window.innerWidth;
        if (w !== resize && !isResized) {
            setResize(w);
            setIsResized(true);
        }
    }, [resize, isResized]);

    useEffect(() => {
        //change video size
        if (!mainBoxRef.current || currMode !== SCREEN_MODE.SHOW_VIDEO) return;
        const mainAreaR = mainBoxRef.current?.getBoundingClientRect();
        setVideoSize([mainAreaR.width - IMAGE_SHIFT_X, mainAreaR.height - IMAGE_SHIFT_Y]);
        if (isResized) setIsResized(false);
    }, [mainBoxRef, currMode, isResized]);

    useEffect(() => {
        //change image size
        if (!mainBoxRef.current || currMode !== SCREEN_MODE.SHOW_FRAME) return;
        const mainAreaR = mainBoxRef.current.getBoundingClientRect();
        const ratio = Math.min(mainAreaR.width / imageNaturalSize[0], mainAreaR.height / imageNaturalSize[1]);
        const padingImg = 0; // сделал отступы от обертки
        setImageSize([imageNaturalSize[0] * ratio - padingImg, imageNaturalSize[1] * ratio - padingImg]);
        if (isSelectedFrameIndChanged) setIsSelectedFrameIndChanged(false);
        if (isResized) setIsResized(false);
    }, [isResized, currMode, mainBoxRef, imageNaturalSize, isSelectedFrameIndChanged]);

    const getVideoIndByFrameInd = (frameInd) => pngFrames[frameInd].videoInd;
    //const getInnerImageScale = (_imageSize) => [_imageSize[0] - IMAGE_SHIFT_X, _imageSize[1] - IMAGE_SHIFT_Y];
    const getInnerImageScale = (_imageSize) => [_imageSize[0], _imageSize[1]];

    const doSetCameraRecordStateByVideoInd = (videoInd) => {
        const isCamera = videoInd >= 0 && videoList[videoInd].videoStreamInput === STREAM_INPUT.CAMERA;
        if ((isCamera && !isCameraRecord) || (!isCamera && isCameraRecord)) {
            setIsCameraRecord(!isCameraRecord); //если выделенный кадр был взят из видео, то возвращаемся снова к видео и начинаем запись.
        }
    };

    const handleSetVideoMode = () => {
        //переключение в режим видео, если isCor=false
        const videoInd = getVideoIndByFrameInd(selectedFrameInd);
        doSetCameraRecordStateByVideoInd(videoInd);

        doSetCurrMode(isCor, corOptions, SCREEN_MODE.SHOW_VIDEO);
        setSelectedFrameInd(-1);
        setIsSelectedFrameIndChanged(true);
    };

    const handleSelectedVideo = (value) => {
        //выбираем новое видео
        const videoInd = Number(value);
        if (videoInd >= 0) {
            setCurrVideoInd(videoInd);
            doSetCameraRecordStateByVideoInd(videoInd);
        }
    };

    const handleChangeVideoStream = () => {
        setRestartRecoringStep(0);
        setShowChangeVideoStreamDlg(true);
    };

    const handleChangeVideoSource = (videoSourceInd) => {
        doSetCurrMode(isCor, corOptions, SCREEN_MODE.DO_LOAD_VIDEO);
        setVideoSource(videoSourceInd);
        setShowChangeVideoStreamDlg(false);
        //
        setCurrVideoInd(-1);
        setSelectedFrameInd(-1);
        setPngFrames([]);
        setVideoList([]);
        setCurrScale(DEFAULT_SCALE_SIZE);
        //
        if (isCor && corOptions?.libVideo) {
            setIsCleanupLibVideo(true);
            corOptions.setLibVideo(undefined);
        }
    };
    useEffect(() => {
        if (!isCleanupLibVideo || !isCor || !corOptions) return;
        if (!corOptions.libVideo) 
            setIsCleanupLibVideo(false);
    }, [isCleanupLibVideo, isCor, corOptions]);

    //camera:
    const handleCameraChoice = (_selCameraId, _isSaveCameraFiles) => {
        if (!_selCameraId) return;
        setSelectedCameraId(_selCameraId);
        setIsSaveCameraFiles(_isSaveCameraFiles);
        setShowCameraChoiceDlg(false);

        //1. let's check if the video has been already uploaded:
        for (let i = 0; i < videoList.length; i++)
            if (videoList[i].videoStreamInput === STREAM_INPUT.CAMERA && 
                    videoList[i].videoName === _selCameraId) {
                setCurrVideoInd(i); //this video has been already loaded. no need to load again, just need to select it.
                doSetCurrMode(isCor, corOptions, SCREEN_MODE.SHOW_VIDEO);
                return;
            }

        //2. Подсчитаем сколько несохраненных видео записей было сделано:
        //const cameraRecords = videoList.filter((item) => item.videoName.includes('Камера')).length;

        //3. let's upload a new video:
        doUploadNewVideo(
            STREAM_INPUT.CAMERA,
            {
                name: 'Микроскоп',
                cameraId: _selCameraId,
            },
            null, currScale, currUnitId, videoList, currVideoInd, setVideoList, setCurrVideoInd
        );
        doSetCurrMode(isCor, corOptions, SCREEN_MODE.SHOW_VIDEO);
        //setIsCameraRecord(true); //НАЧИНАЕМ ЗАПИСЬ. При возвращении из режима кадров (если кадр был сделае в видео) в режим видое снова устанавливаем isCameraRecord = true
    };

    useEffect(() => {
        if (currVideoInd === -1 || !localStream || videoList.length === 0) return;
        if (videoList[currVideoInd].videoStreamInput !== STREAM_INPUT.CAMERA) return;
        if (restartRecoringStep !== 0) return;
        if (isCameraRecord) {
            startRecording(localStream, setRecorder);
            if (videoRef.current) {
                videoRef.current.srcObject = localStream;
            }
        } else {
            if (!isSaveCameraFiles) {
                doStopRecording(localStream, recorder, '');
            } else {
                const fName = 'Видео файл ' + Moment(new Date()).format('yyMMD-HHmmss'); //'Видео файл ' + getFormattedDate(new Date(), 'ymd')
                setInitCameraFileName(fName);
                setShowCameraFileNameDlg(true); // откроем диалог для ввода имени видео файла
            }
        }
    }, [currVideoInd, isCameraRecord, localStream, videoList, 
        isSaveCameraFiles, restartRecoringStep]); //don't add recorder

    const doStopRecording = (_localStream, _recorder, fileName) => {
        setShowCameraFileNameDlg(false); //закроем диалог ввода имени файла
        stopAndClearRecording(videoRef.current, _localStream, _recorder, setRecorder, 
            fileName, setSavedCameraFileData);
        setLocalStream(null);
        setInitCameraFileName('');
    };

    useEffect(() => {
        if (!savedCameraFileData) return;
        const selectedVideo = videoList[currVideoInd];
        selectedVideo.videoName = savedCameraFileData.videoName;
        selectedVideo.videoURL = savedCameraFileData.videoURL;
        selectedVideo.size = savedCameraFileData.size;
        setSavedCameraFileData(null);
    }, [savedCameraFileData, currVideoInd, videoList]);

    useEffect(() => {
        if (!selectedCameraId) return;
        updateLocalStream(videoRef, selectedCameraId, isCameraRecord, setLocalStream);
    }, [selectedCameraId, isCameraRecord]);

    const handleCancelCameraFileNameDlg = () => {
        doStopRecording(localStream, recorder, '');
    };

    const handleSaveCameraFile = (fileName) => {
        doStopRecording(localStream, recorder, fileName);
    };

    // misc:
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const videoBlob = URL.createObjectURL(file);
        //let's upload a new video:
        doUploadNewVideo(STREAM_INPUT.VIDEO, file, videoBlob, currScale, currUnitId, 
            videoList, currVideoInd, setVideoList, setCurrVideoInd);
        doSetCurrMode(isCor, corOptions, SCREEN_MODE.SHOW_VIDEO);
    };

    useEffect(() => {
        if (!isCor || isCleanupLibVideo) return;
        if (!corOptions?.libVideo) return;

        const file = corOptions.libVideo;
        if (videoList.length > 0 && videoList[0].videoName === file.name) {
            return;
        }

        const videoBlob = URL.createObjectURL(file);
        //let's upload a new video:
        doUploadNewVideo(STREAM_INPUT.VIDEO, file, videoBlob, currScale, currUnitId, 
            videoList, currVideoInd, setVideoList, setCurrVideoInd);
        doSetCurrMode(isCor, corOptions, SCREEN_MODE.SHOW_VIDEO);
    }, [isCor, corOptions, currScale, currUnitId, videoList, currVideoInd, isCleanupLibVideo]);

    const handleDeleteFrameYes = () => {
        setShowDeleteFrameDlg(false);
        const frames = pngFrames.filter((item, index) => deleteFrameInd !== index);
        setPngFrames(frames);
        if (selectedFrameInd >= deleteFrameInd && selectedFrameInd > 0) 
            setSelectedFrameInd(selectedFrameInd - 1);
        if (frames.length === 0) {
            setSelectedFrameInd(-1);
            doSetCurrMode(isCor, corOptions, SCREEN_MODE.SHOW_VIDEO);
            //setIsCameraRecord(true);
            if (isCor && corOptions) corOptions.setFireVideoMode(true);
        }
        setDeleteFrameInd(-1);
    };

    useEffect(() => {
        if (currMode !== SCREEN_MODE.SHOW_VIDEO && currMode !== SCREEN_MODE.SHOW_FRAME) return;
        const innerImageSize = getInnerImageScale(imageSize);
        const updatedFrames = recalcAllImageMeasures(pngFrames, currVideoInd, innerImageSize, currScale);
        setPngFrames(updatedFrames);
        const vd = { ...videoList[currVideoInd], videoScale: currScale };

        const list = videoList.map((item, ind) => (ind !== currVideoInd ? item : vd));
        setVideoList(list);
    }, [currMode, currScale, currVideoInd, imageSize]); //don't add 2 others

    const doDeleteMeasure = (_selectedFrameInd, _selectedMeasureInd) => {
        const frame = pngFrames[selectedFrameInd];
        const updatedImageData = { ...frame, ...frame.measureList };
        updatedImageData.measureList = _selectedMeasureInd !== DELETE_ALL_MEASURES_MARKER ? 
            updatedImageData.measureList.filter((item, ind) => ind !== _selectedMeasureInd) : [];
        doUpdateFrames(pngFrames, selectedFrameInd, updatedImageData);
    };

    const handleDeleteAllFrames = () => {
        setShowDeleteAllFramesDlg(true);
    };

    const handleDeleteAllFramesYes = () => {
        setShowDeleteAllFramesDlg(false);
        const frames = pngFrames.filter((item) => item.videoInd !== currVideoInd);

        if (currMode === SCREEN_MODE.SHOW_FRAME) {
            handleSetVideoMode();
            setPngFrames(frames);
            setSelectedFrameInd(-1);
        } else if (currMode === SCREEN_MODE.SHOW_VIDEO && currVideoInd !== -1) {
            setPngFrames(frames);
        }
    };

    const handleProfile = () => {
        //export: step 1 of 4
        if (!isCor) return;
        setFileNamesExported([]); //clean up before new export
        setExportedFileData(undefined);
        // 2 actions to avoid export dlg:
        const _videoSelectList = [true];

        corOptions.clearExportedImage(videoDbRecord);
        const _framesBeExported = getFramesBeExported(videoList, _videoSelectList, 
            pngFrames, corOptions.owner);
        setVideoSelectList(_videoSelectList);
        setFramesBeExported(_framesBeExported);
    };

    useEffect(() => {
        //export: step 2 of 4
        if (framesBeExported.length === 0) return;
        if (fileNamesExported.length < framesBeExported.length) {
            const currInd = fileNamesExported.length;
            const frame = framesBeExported[currInd];

            //готовим информацию для загрузки изображения в хранилище
            const fileData = {
                name: frame.frameName + '.jpg',
                base64: frame.frameExportData,
            };

            //записываем очередной кадр в хранилище и отправляем инфо о кадре дальше для записи в БД
            corOptions.uploadVEFile(fileData, (v) =>
                setExportedFileData({
                    frameName: v,
                    videoInd: frame.videoInd,
                    frameInd: frame.frameInd,
                })
            );
            //go to step 3 of 4 - следует добавить информацию об этом экспортированном кадре к списку
        } else {
            setCanExportToDb(true); //go to step 4 of 4 - все кадры экспортированы. Осталось загрузить данные в БД
            setFramesBeExported([]);
        }
    }, [framesBeExported, fileNamesExported, corOptions]);

    useEffect(() => {
        //export: step 3 of 4
        if (!exportedFileData) return;
        //добавляем информацию об изображении к списку уже экспортированных
        const fn = [...fileNamesExported];
        fn.push(exportedFileData);
        setFileNamesExported(fn);
        setExportedFileData(undefined);
        //go back to step 2 of 4 - возвращаемся, чтобы экспортироаать следующее изображение
    }, [fileNamesExported, exportedFileData]);
    useEffect(() => {
        //export: step 4 of 4
        if (!canExportToDb) return;
        //сохраняем всю информацию в базе данных
        saveVideosInDb(videoList, videoSelectList, corOptions, videoSize, pngFrames, 
            fileNamesExported, videoDbRecord, setVideoDbRecord);
        toast.success('Измерения сохранены.');
        setCanExportToDb(false);
    }, [canExportToDb, corOptions, fileNamesExported, framesBeExported.length, 
        pngFrames, videoList, videoSelectList, videoSize, videoDbRecord]);

    const handleSaveNewFrame = () => {
        if (currScale === 0 || isNaN(currScale)) {
            toast.warn('Установите размер кадра.');
        } else {
            //it causes to collapse all existing images, then catch shouldSaveFrame and add the new image
            setShouldSaveFrame(true);
        }
    };
    useEffect(() => {
        const addNewFrame = (newFrame) => {
            setPngFrames([...pngFrames, newFrame]);
        };

        if (!shouldSaveFrame) return;
        setShouldSaveFrame(false);
        saveNewFrame(currVideoInd, videoRef.current, addNewFrame);
    }, [shouldSaveFrame, currVideoInd, pngFrames]);

    const handleSelectedVideoUnitId = (id) => {
        if (selectedFrameInd === -1) {
            setCurrUnitId(id);
            return;
        }

        const videoInd = getVideoIndByFrameInd(selectedFrameInd);

        if (videoList[videoInd].videoUnitId !== id) {
            setCurrUnitId(id);
            const vd = { ...videoList[currVideoInd], videoUnitId: id, videoUnitName: getScaleUnitById(id).label };
            const list = videoList.map((item, ind) => (ind !== currVideoInd ? item : vd));
            setVideoList(list);
        }
    };

    const getVideoScale = (initScaleValue) => {
        const handleChangeScaleValue = (val) => {
            if (val.trim() === '') {
                setSaveScale('');
                return;
            }

            if (val.match(/^[.0-9]+$/) === null || val.toString().length > 5 || parseFloat(val) === 0) {
                setSaveScale(saveScale ? saveScale : initScaleValue);
                return;
            }

            setSaveScale(val);
            setIsCurrScaleChanged(true);
        };
        const handleBlurScale = (val) => {
            const float = parseFloat(val);

            let valueToSave;
            if (val.trim() === '') valueToSave = initScaleValue;
            else if (isNaN(float)) valueToSave = initScaleValue;
            else if (float < 0.01 && float !== currScale) valueToSave = 0.01;
            else if (float > 999) valueToSave = 999;
            else valueToSave = float;

            if (valueToSave !== videoList[currVideoInd].videoScale) {
                setCurrScale(valueToSave);
                setIsCurrScaleChanged(true);
            }
            setSaveScale(null);
        };

        return (
            <div className={'videoEditor__size'}>
                <span>Размер кадра</span>
                <Input
                    style={{ width: '8em' }}
                    value={saveScale === null ? currScale : saveScale}
                    onChange={(e) => handleChangeScaleValue(e.target.value)}
                    onBlur={(e) => handleBlurScale(e.target.value)}
                />
                <span>
                    <Dropdown
                        value={getScaleUnitById(currUnitId)?.label}
                        className={'videoEditor__select videoEditor__selectValues5'}
                    >
                        {sizeUnitList.map((item, ind) => (
                            <Dropdown.Item
                                key={'unit' + ind}
                                onClick={() => handleSelectedVideoUnitId(item.value)}
                                className={item.value === getScaleUnitById(currUnitId)?.value ? 'selected' : ''}
                            >
                                {item.label}
                            </Dropdown.Item>
                        ))}
                    </Dropdown>
                </span>
            </div>
        );
    };

    const getChangeVideoStream = () => {
        return (
            <div>
                <Button key="title08" onClick={handleChangeVideoStream} border={true} icon={true}>
                    <Icon name="reload" />
                    Заменить видео
                </Button>
            </div>
        );
    };

    const getUploadTopBar = () => {
        return (
            <>
                {getChangeVideoStream()}

                {videoSource === VIDEO_SOURCE.CAMERA && (
                    <div>
                        <Button label={true} icon={true} 
                            onClick={() => setShowCameraChoiceDlg(true)}
                        >
                            <Icon name="microscope" />
                            {' Микроскоп'}
                        </Button>
                    </div>
                )}

                {videoSource === VIDEO_SOURCE.VIDEOFILE && (
                    <div key="upload01">
                        <Button label={true} icon={true}
                        >
                            <Icon name="film" />
                            Выберите новое видео
                            <input type="file" value="" accept="video/*" onChange={handleFileUpload} />
                        </Button>
                    </div>
                )}

                {isCor && corOptions && videoSource === VIDEO_SOURCE.LIBRARY && (
                    <div>
                        <Button label={true} icon={true} 
                            onClick={corOptions.openVideoLibDlg}
                        >
                            <Icon name="folder" />
                            {' Картотека'}
                        </Button>
                    </div>
                )}
            </>
        );
    };

    const getVideoTopBar = () => {
        return (
            <>
                {getChangeVideoStream()}

                {useAdvanceFeatures && videoList.length > 0 && (
                    <span>
                        <select
                            value={-1}
                            className="calcModel__SelectElDynam"
                            onChange={(e) => handleSelectedVideo(e.target.value)}
                        >
                            <option key="m" value="-1">
                                Выберите видео
                            </option>
                            {videoList.map((item, ind) => (
                                <option key={'m' + ind} value={ind}>
                                    {item.videoName}
                                </option>
                            ))}
                        </select>
                    </span>
                )}

                <div>
                    {getVideoScale(DEFAULT_SCALE_SIZE)}
                </div>

                <div>
                    <Button onClick={handleSaveNewFrame} key="title07" icon={true}>
                        <Icon name="picture" />
                        Сохранить кадр
                    </Button>
                </div>
            </>
        );
    };

    const getImageTopBar = () => {
        const selectedMeasureClass = (action) => {
            return 'videoEditor__control ' + (action === selectedActionType ? 'active' : '');
        };
    
        const getMeasureActionCommands = () => {
            return (
                <>
                    <div
                        key="title01"
                        onClick={() => setSelectedActionType(MEASURE_TYPE.RECT)}
                        className={selectedMeasureClass(MEASURE_TYPE.RECT)}
                    >
                        <Icon name="square" />
                        Прямоугольник
                    </div>
                    <div
                        key="title02"
                        onClick={() => setSelectedActionType(MEASURE_TYPE.LINE)}
                        className={selectedMeasureClass(MEASURE_TYPE.LINE)}
                    >
                        <Icon name="ruler" />
                        Линейка
                    </div>
                    <div
                        key="title03"
                        onClick={() => setSelectedActionType(MEASURE_TYPE.CIRCLE)}
                        className={selectedMeasureClass(MEASURE_TYPE.CIRCLE)}
                    >
                        <Icon name="protractor" />
                        Радиус
                    </div>
                </>
            );
        };

        const getSwitchToVideo = () => {
            return (
                <div className="videoEditor__action">
                    <Button key="title11" onClick={handleSetVideoMode} icon={true} border={true}>
                        <Icon name="toggler" />
                        Видео
                    </Button>
                </div>
            );
        };

        return (
            <>
                <div className="videoEditor__controls">{getMeasureActionCommands()}</div>
                {!isCor && currMode === SCREEN_MODE.SHOW_FRAME && 
                    <div className="videoEditor__title">{'Кадр ' + getImageNumber(selectedFrameInd)}</div>
                }

                {getVideoScale(videoList[currVideoInd].videoScale, )}

                {!isCor && currMode === SCREEN_MODE.SHOW_FRAME && 
                    <div className="videoEditor__action">{getSwitchToVideo()}</div>
                }
            </>
        );
    };

    const getSelectedVideo = () => {
        if (currVideoInd === -1) return <></>;
        const currVideo = videoList[currVideoInd];
        const isVideoStream = currVideo.videoStreamInput === STREAM_INPUT.VIDEO;

        return (
            <video
                ref={videoRef}
                width={videoSize[0]}
                height={videoSize[1]}
                controls={true}
                src={isVideoStream ? currVideo.videoURL : localStream}
                autoPlay={true}
            ></video>
        );
    };

    const getSelectedFrame = () => {
        if (selectedFrameInd === -1 || !imageSize[0]) return <></>;
        const frame = pngFrames[selectedFrameInd];
        const videoUnitName = videoList[frame.videoInd].videoUnitName;

        return (
            <>
                <canvas
                    id={SELECTED_FRAME_ID}
                    ref={canvasRef}
                    width={imageSize[0]}
                    height={imageSize[1]}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                />
                {drawFigures(ctx, frame, videoUnitName, currFigureRect, dragging, 
                    selectedFigureOpts, true, signatureListRef, imageSize, CAN_ADD_COMMENT)}
            </>
        );
    };

    const getInstruction = () => {
        //it contains the instruction in the right side:
        const instructionList = videoSource === VIDEO_SOURCE.CAMERA ? 
            getMicroscopeInstruction() : getVideoFileInstruction();

        return (
            <>
                <div className="videoEditorAside__title">Инструкция</div>
                <div className="videoEditorAside__separator"></div>

                <div className="videoEditorAside__body">
                    <div className="videoEditorList">
                        <div className="videoEditorAside__instruction">
                            {instructionList.map((item, index) => {
                                return (
                                    <p key={'instr' + index}>
                                        {item}
                                    </p>
                                );
                            })}
                            {videoSource === VIDEO_SOURCE.VIDEOFILE && 
                                <a href={getVideoFileExampleRef()}>Скачать видео</a>
                            }
                        </div>

                    </div>
                </div>
            </>
        );
    };

    const getFrames = () => {
        const handleSelectImage = (index) => {
            if (currMode !== SCREEN_MODE.SHOW_VIDEO && currMode !== SCREEN_MODE.SHOW_FRAME) return;
    
            if (currMode === SCREEN_MODE.SHOW_VIDEO) {
                doSetCurrMode(isCor, corOptions, SCREEN_MODE.SHOW_FRAME);
                // if (isCameraRecord) setIsCameraRecord(false); //ЗАКАНЧИВАЕМ ЗАПИСЬ, если она была
            }
    
            if (index !== selectedFrameInd) {
                setSelectedFrameInd(index);
                setIsSelectedFrameIndChanged(true);
                setCurrVideoInd(pngFrames[index].videoInd);
            }
        };
    
        const handleDeleteImage = (delIndex) => {
            setShowDeleteFrameDlg(true);
            setDeleteFrameInd(delIndex);
            doFrameChanged();
        };
    
        const handleDeleteMeasure = (_selectedFrameInd, _selectedMeasureInd) => {
            doDeleteMeasure(_selectedFrameInd, _selectedMeasureInd);
            doFrameChanged();
        };
    
        const handleDeleteAllFrameMeasures = (_selectedFrameInd) => {
            const frame = pngFrames[_selectedFrameInd];
            const updatedImageInfo = { ...frame, ...frame.measureList };
            updatedImageInfo.measureList = [];
            doUpdateFrames(pngFrames, selectedFrameInd, updatedImageInfo);
            doFrameChanged();
        };
    
        const handleExportExperiment = () => {
            setShowSaveXlsDlg(true);
        };
    
            
        //it defines the right side:
        return (
            <>
                <div className="videoEditorAside__title">История захвата кадров</div>
                <div className="videoEditorAside__separator"></div>

                <div className="videoEditorAside__body">
                    <div className="videoEditorList">
                        {pngFrames.length > 0 &&
                            pngFrames.map((frame, index) => {
                                return (
                                    <VideoEditorItem
                                        key={'vi' + index}
                                        props={{videoList, frame, index, currMode, pngFrames, 
                                            selectedFrameInd, imageSize, shouldSaveFrame,
                                            handleSelectImage, handleDeleteImage, 
                                            handleDeleteMeasure, handleDeleteAllFrameMeasures,
                                        }}
                                    />
                                );
                            })}
                    </div>
                </div>

                <div className="videoEditorAside__bottom">
                    {pngFrames.length > 0 && getExportCB(handleProfile, handleExportExperiment, isCor)}
                    {pngFrames.length > 0 && (
                        <div className="videoEditorAside__bottom_item" onClick={() => handleDeleteAllFrames()}>
                            <Icon name="trash" />
                            <span>Удалить все кадры</span>
                        </div>
                    )}
                </div>
            </>
        );
    };

    return (
        <div className={isCor ? 'videoEditor videoEditor_cor' : 'videoEditor'}>
            <div className="videoEditor__content">
                {/* Top side */}
                <div className="videoEditor__head">
                    {currMode === SCREEN_MODE.DO_LOAD_VIDEO && getUploadTopBar()}
                    {currMode === SCREEN_MODE.SHOW_VIDEO && getVideoTopBar()}
                    {currMode === SCREEN_MODE.SHOW_FRAME && getImageTopBar()}
                </div>

                {/* Central side */}
                <div
                    ref={mainBoxRef}
                    className="videoEditor__media videoEditor__stretch"
                >
                    {currMode === SCREEN_MODE.DO_LOAD_VIDEO && <>Загрузите видео</>}
                    {currMode === SCREEN_MODE.SHOW_VIDEO && getSelectedVideo()}
                    {currMode === SCREEN_MODE.SHOW_FRAME && getSelectedFrame()}
                </div>
            </div>

            {/* Right side */}
            <div className="videoEditorAside">{currScale === 0 ? getInstruction() : getFrames()}</div>

            {showDeleteFrameDlg && (
                <DeleteFrameDlg
                    showConfirmDlg={showDeleteFrameDlg}
                    question={'Вы действительно хотите удалить этот кадр?'}
                    handleYes={handleDeleteFrameYes}
                    handleNo={() => setShowDeleteFrameDlg(false)}
                />
            )}

            {showDeleteAllFramesDlg && (
                <DeleteAllFramesDlg
                    showConfirmDlg={showDeleteAllFramesDlg}
                    question={'Вы действительно хотите удалить все кадры?'}
                    handleYes={handleDeleteAllFramesYes}
                    handleNo={() => setShowDeleteAllFramesDlg(false)}
                />
            )}

            {showSaveXlsDlg && (
                <VideoEditorXlsxExport
                    showModal={showSaveXlsDlg}
                    setShowModal={setShowSaveXlsDlg}
                    fileName={selectedFrameInd >= 0 ? videoList[pngFrames[selectedFrameInd].videoInd].videoName : ''}
                    saveXLSX={(v, n) => saveXLSX(v, n, videoList, pngFrames)}
                />
            )}

            {showCameraChoiceDlg && (
                <CameraChoiceDlg
                    showModal={showCameraChoiceDlg}
                    setShowModal={setShowCameraChoiceDlg}
                    isCor={isCor}
                    cameraList={cameraList}
                    useAdvanceFeatures={useAdvanceFeatures}
                    handleCameraChoice={handleCameraChoice}
                />
            )}

            {showCameraFileNameDlg && (
                <CameraFileNameDlg
                    showModal={showCameraFileNameDlg}
                    initCameraFileName={initCameraFileName}
                    doCancelDlg={handleCancelCameraFileNameDlg}
                    doSaveCameraFile={handleSaveCameraFile}
                />
            )}

            {showChangeVideoStreamDlg && (
                <ChangeVideoSourceDlg
                    showModal={showChangeVideoStreamDlg}
                    setShowModal={setShowChangeVideoStreamDlg}
                    handleChangeVideoSource={handleChangeVideoSource}
                    isCor={isCor}
                />
            )}
        </div>
    );
};

export default VideoEditor;
