import React from "react";
import {Route} from "react-router-dom";

import {isAuth} from "../../helpers/auth";
import {FEATURES} from "../../api/config";
import HeaderBar from "../template/Header";
import Aside from "../template/Aside";
import Navbar from "../template/Navbar";
import DashboardClass from "../template/Dashboard/DashboardClass";
import DashboardVideo from "../template/Dashboard/DashboardVideo";
import DashboardMembers from "../template/Dashboard/DashboardMembers";
import DashboardClassMembers from "../template/Dashboard/DashboardClassMembers";
import DashboardPracticum from "../template/Dashboard/DashboardPracticum";
import DashboardTestExecute from "../template/Dashboard/DashboardTestExecute";
import DashboardTestView from "../template/Dashboard/DashboardTestView";
import DashboardOnlineTest from "../template/Dashboard/DashboardOnlineTest";
import DashboardCalcModels from "../template/Dashboard/DashboardCalcModels";
import DashboardScenarios from "../template/Dashboard/DashboardScenarios";
import DashboardPInvestigations from "../template/Dashboard/DashboardPInvestigations";
import DashboardNotebook from "../template/Dashboard/DashboardNotebook";
import DashboardReviewTest from "../template/Dashboard/DashboardReviewTest";
import DashboardWhite from "../template/Dashboard/DashboardWhite";
import DashboardRlabSelect from "../template/Dashboard/DashboardRlabSelect";
import DashboardRlab from "../template/Dashboard";
import DashboardRlabNeuro from "../template/Dashboard/DashboardRlabNeuro";
import {DashboardRlabExport} from "../template/Dashboard/DashboardRlabExport";
import {DashboardInstruments} from "../template/Dashboard/DashboardInstruments";
import {DashboardInstrumentSelect} from "../template/Dashboard/DashboardInstrumentSelect";
import DashboardProfile from "../template/Dashboard/DashboardProfile";
import {VideoContextProvider} from "../Video/VideoContext";
import DashboardStorage from "../template/Dashboard/DashboardStorage";
import {DashboardVrLab} from "../template/Dashboard/DashboardVrLab";
import DashboardTracker from "../template/Dashboard/DashboardTracker";
import DashboardTutorial from "../template/Dashboard/DashboardTutorial";
import DashboardMl from "../template/Dashboard/DashboardMl";

function Home(props) {
    return (<VideoContextProvider>
            <HeaderBar {...props}/>
            <main className="cor_main">
                <Navbar {...props} />
                <Route exact path="/">
                    {FEATURES.video.role.includes(isAuth().role) ?
                    <DashboardVideo {...props}/>
                    :
                    <DashboardClass {...props}/>
                    }
                </Route>
                <Route exact path="/members/:id">
                    <DashboardClassMembers {...props}/>
                </Route>
                <Route exact path="/teachers">
                    <DashboardMembers {...props}/>
                </Route>
                <Route exact path="/students">
                    <DashboardMembers {...props}/>
                </Route>
                <Route exact path="/practicum/:opt?/:id?">
                    <DashboardPracticum {...props} />
                </Route>
                <Route exact path="/ot_create/:id">
                    <DashboardOnlineTest type="ot_create" {...props} />
                </Route>
                <Route exact path="/reviewtest/:opt/:id?">
                    <DashboardReviewTest {...props} />
                </Route>
                <Route exact path="/ot/:opt?/:id?">
                    <DashboardTestExecute {...props} />
                </Route>
                <Route exact path="/lw/:opt?">
                    <DashboardTestExecute {...props} />
                </Route>
                <Route exact path="/testview/:id">
                    <DashboardTestView {...props} />
                </Route>
                <Route exact path="/pi/:opt/:id?">
                    <DashboardPInvestigations {...props}/>
                </Route>
                <Route exact path="/cm/:id?/:opt?">
                    <DashboardCalcModels {...props} />
                </Route>
                <Route exact path="/notebooks/:opt/:id?">
                    <DashboardNotebook {...props} />
                </Route>
                <Route exact path="/instruments/:id?">
                    <DashboardInstruments {...props} />
                </Route>
                <Route exact path="/instrument/:id">
                    <DashboardInstrumentSelect {...props} />
                </Route>
                <Route exact path="/whiteboard">
                    <DashboardWhite {...props} />
                </Route>
                <Route exact path="/rlabSelect">
                    <DashboardRlabSelect {...props} />
                </Route>
                <Route exact path="/rlab">
                    <DashboardRlab {...props} />
                </Route>
                <Route exact path="/rlab/:id/:opt?">
                    <DashboardRlab {...props} />
                </Route>
                <Route exact path="/rlabNeuro">
                    <DashboardRlabNeuro {...props} />
                </Route>
                <Route exact path="/rlabExport">
                    <DashboardRlabExport {...props} />
                </Route>
                <Route exact path="/rlsc/:opt/:id?">
                    <DashboardScenarios {...props} />
                </Route>
                <Route exact path="/profile">
                    <DashboardProfile {...props} />
                </Route>
                <Route exact path="/profile/:id/:opt?">
                    <DashboardProfile {...props} />
                </Route>
                <Route exact path="/storage/:key?">
                    <DashboardStorage {...props} />
                </Route>
                <Route exact path="/vrlab/:id?">
                    <DashboardVrLab {...props} />
                </Route>
                <Route exact path="/ml">
                    <DashboardMl {...props} />
                </Route>
                <Route exact path="/trello">
                    <DashboardTracker {...props} />
                </Route>
                <Route exact path="/tutorial/:opt?/:sid?/:bid?">
                    <DashboardTutorial {...props} />
                </Route>
                <Aside {...props}/>
            </main>
        </VideoContextProvider>);
}

export default Home;
