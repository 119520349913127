import React, {useState, useEffect} from "react";
import {printOnlyDate} from "../../helpers/text";
import {getCalcModelNameByType} from "../CalcModels/cm_utils";
import {isAuth} from "../../helpers/auth";
import {useSelector} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import socket from "../../socket";
import {DB_REFRESH} from "../ui/utils/gen_utils";
import Table from "../ui/Table/Table";
import {calcModelInfoApi} from "../../api/api";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";

const ProfileCalcModelInfoTable = ({profile, history}) => {
  const [calcModelData, setCalcModelData] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [counter, setCounter] = useState(0);
  const [showConfirmDlg, setShowConfirmDlg] = useState(false);
	const [idBeDeleted, setIdBeDeleted] = useState(undefined);

	const documentClass = useSelector(storageClass);

	useEffect(() => {
    socket.on("FE-refresh-db", ({type}) => {
        if (type === DB_REFRESH.CMI) setCounter(counter => counter + 1);
    });
    return () => {socket.off("FE-refresh-db");};
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await calcModelInfoApi.getAllCalcModelInfosByOwner(profile._id);
			const list = [];
			if (result && result.data.length > 0) {
        const cmis = result.data;

        for (let i = 0; i < cmis.length; i++) {
          const cmi = cmis[i];
          const row = {
            calcModeName: getCalcModelNameByType(cmi.cmType),
            cmType: cmi.cmType,
            expName: cmi.name,
            expDescription: cmi.description,
            date: printOnlyDate(cmi.updatedAt),
            actions: '',
            id: cmi._id,
          };
          list.push(row);
        }
      }

      setCalcModelData(list);
      if (isUpdated) setIsUpdated(false);
    };
    fetchData();
  }, [profile._id, isUpdated, counter]);

  const handleOpenCalcModel = async (optId) => {
    const cmType = calcModelData.find((cm) => cm.id === optId).cmType;
    history.push('/cm/' + cmType + '/' + optId);
  };

	const handleDelete = id => {
		setIdBeDeleted(id);
		setShowConfirmDlg(true);
	};
	const handleDeleteNo = () => {
		setShowConfirmDlg(false);
		setIdBeDeleted(undefined);
	};

	const handleDeleteYes = () => {
		setShowConfirmDlg(false);
    calcModelInfoApi.deleteCalcModelInfoById(idBeDeleted)
    .then(res => {
      setIdBeDeleted(undefined);
      setIsUpdated(true);
      socket.emit('BE-refresh-db', {type: DB_REFRESH.CMI, roomId: documentClass._id});
    });
  };

  const getTableHeader = () => {
    if (isAuth().role === 0) {
      return [
        {column: 'Название расчетной модели', name: 'calcModeName', style: {width: '25%'}},
        {column: 'Название эксперимента', name: 'expName', style: {width: '25%'}},
        {column: 'Описание эксперимента', name: 'expDescription', style: {width: '35%'}},
        {column: 'Дата', name: 'date', style: {width: '15%'}},
        {column: 'id', name: 'id', style: {width: '0%'}},
      ];
    } else {
      return [
        {column: 'Название расчетной модели', name: 'calcModeName', style: {width: '25%'}},
        {column: 'Название эксперимента', name: 'expName', style: {width: '20%'}},
        {column: 'Описание эксперимента', name: 'expDescription', style: {width: '30%'}},
        {column: 'Дата', name: 'date', style: {width: '15%'}},
        {column: 'Действия', name: 'actions', style: {width: '10%'}},
        {column: 'id', name: 'id', style: {width: '0%'}},
      ];
    }
  };

  return (
    <div>
      <Table
        table={{
          header: getTableHeader(),
          data: calcModelData,
        }}
        person={{
          handlePerson: handleOpenCalcModel,
          isCellLink: true,
        }}
        sort={{
          hasSorting: true,
          initSortInd: -4,
        }}
        actions={{
          handleDelete: id => handleDelete(id),
        }}
      />

      {showConfirmDlg &&
        <ModalConfirmDialog
          showConfirmDlg={showConfirmDlg} 
          handleNo={handleDeleteNo}
          handleYes={handleDeleteYes}
          question={'Вы действительно хотите удалить эти данные?'}
        />
			}
    </div>
  )
}

export default ProfileCalcModelInfoTable;
