import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import socket from "../../../socket";
import {DB_REFRESH} from "../../ui/utils/gen_utils";
import {toast} from "react-toastify";
import {rlabApi, rlsiApi} from "../../../api/api";
import {isAuth} from "../../../helpers/auth";
import CorFooter from '../Footer';
import {loadScenarios, loadScenario} from '../../RlabScenarios/sc_utils';
import "./Dashboard.scss";
import ss from "./DashboardRlab.module.scss";

import Aside from "../../../rlab/src/components/Template/Aside";
import Dashboard from '../../../rlab/src/components/Template/Dashboard';
import Footer from '../../../rlab/src/components/Template/Footer';
import ChartStyles from '../../../rlab/src/components/ChartStyles';
import CompanyInfo from "../../../rlab/src/components/Template/CompanyInfo";
import {Connection} from '../../../rlab/src/components/Connection';
import SensorsSettings from '../../../rlab/src/components/SensorsSettings';
import {Graduation} from '../../../rlab/src/components/Graduation';
import ModalPrint from '../../../rlab/src/components/ModalPrint';
import ModalBattery from '../../../rlab/src/components/ModalBattery';
import MobileAreaSwicher from '../../../rlab/src/components/Template/MobileAreaSwicher';
import TimerModal from "../../../rlab/src/components/TimerModal";
import Help from '../../../rlab/src/components/Help';
import UnitDetailInfo from '../../RlabScenarios/UnitDetailInfo';
import {mapRlabDispatchToProps, mapRlabStateToProps} from "./redux";
import Firmware from "../../../rlab/src/components/Firmware";
import { Content, ContentWrap } from "../ContentParts";
import ConnectedSensorsModal from "../../../rlab/src/components/ConnectedSensorsModal";
import DemoLearn from "../../../rlab/src/components/Demo/DemoLearn";
import DemoModal from "../../../rlab/src/components/Demo/DemoModal";
import { BundleModalDel } from "../../../rlab/src/components/Bundles/AsideBundles";
import { autoconnectReconnectSerialPort } from "../../../rlab/src/commands/device";
import SensorDataModal from "../../../rlab/src/components/SensorDataModal";
import ChartCommentImageModal from "../../../rlab/src/components/ChartComment/ChartCommentImageModal";

export const DashboardRlab = (props) => {
    const dispatch = useDispatch();

    const {
        sensor,
        match,
        portOpen, 
        portData, 
        connectionType,
        isMobile, 
        devList,
        sensorWriter, 
        imported, 
        sensorList,
        setSensorStream, 
        toggleSensor,
        changeClass,
        scenarioList,
        scenarioId,
        setScenario,
        setScenarioList,
        scenarioInfo,
        setScenarioInfo,
        documentClass, 
        options,
        sensorStream,
        addDevicesConnected,
        removeDevicesConnected,
        setDeviceConnectedCount,
        setSensor,
        setSensorImported,
        setSensorList,
        setSensorWritedList,
        setTheme,
        setMobile,
        setPlay,
        setCor,
        setChartMarkers,
        setChartView,
        setConnectionType,
        togglePort,
        setSensorReading,
    } = props;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const beforeSelectClass = async (classBefore) => {
        if (sensorStream) {
            setSensorStream(false);
            socket.emit("BE-sensor-close", {roomId:classBefore});
            toggleSensor(false);
        } else if (connectionType === 'net') {
            closeNetSensor(false)
        }
    };

    const selectClass = async (classSelected) => {
        setSensorImported([]);
        socket.emit("BE-join-rlab", {roomId:classSelected});
    };

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    function closeNetSensor(notify) {
        setPlay(false);
        setConnectionType(null);
        removeDevicesConnected('fake');
        setDeviceConnectedCount(0);
        togglePort(false);
        if (notify) toast.info('Датчик отключен');
    }

    useEffect(() => {        
        if (changeClass.from) {
            beforeSelectClass(changeClass.from);
        }
        if (changeClass.to) {
            selectClass(changeClass.to);
        }
        // eslint-disable-next-line
    }, [changeClass]);


    useEffect(() => {
        toast.dismiss();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if (isMobile) {
            setChartView('one');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    useEffect(() => {
        if (width <= 768) {
            if (width > height) {
                setMobile('landscape');
            } else {
                setMobile('portrait');
            }
        } else {
            setMobile(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height]);

    useEffect(() => {
        setCor(true);
        if ( Object.keys(portData).length === 0) {
            dispatch(autoconnectReconnectSerialPort);
        }
        return () => {
            setPlay(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setScenario(undefined); //initial clean up
    }, [setScenario]);

    useEffect(() => {
        //load scenario list
        if (sensorList.length === 0) {
            if (scenarioList.length > 0) {
                setScenarioList([]); //датчиков нет, поэтому очищаем отобранные сценарии
                setScenario(undefined); //удаляем текущий сценарий, даже если он уже запущен
            }
        } else {
            loadScenarios(sensorList[0], documentClass._id, scenarioList, setScenarioList);
        }
    }, [documentClass._id, sensorList, scenarioList, setScenarioList, setScenario]);

    useEffect(() => {
        //load the selected scenario by id:
        if (scenarioId === '') return;
        loadScenario(scenarioId, setScenario);
    }, [scenarioId, setScenario]);

    useEffect(() => {
        //save scenario info
        const saveScenarioInfo = async (scenarioInfo) => {
            rlsiApi.addScenarioInfo(scenarioInfo)
            .then(() => {
                setScenario(undefined); //clean up
                toast.success('Результат сохранен в профиль.');
                socket.emit('BE-refresh-db', {type: DB_REFRESH.SCI, roomId: documentClass._id});
            }).catch((err) => {
                console.log(err.response.data.errors);
                toast.error('Ошибка при сохранении отчета.');
            });
        };
    
        if (!scenarioInfo) return;
        const _scenarioInfo = {...scenarioInfo, room: documentClass._id};
        saveScenarioInfo(_scenarioInfo);
        setScenarioInfo(undefined);
    }, [documentClass._id, scenarioInfo, setScenarioInfo, setScenario]);
    
    useEffect(() => {
        setTheme(options.theme??'light');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.theme]);

    useEffect(() => {
        if (match?.params.id && !match?.params.opt) {
            rlabApi.getById(match.params.id).then((rlab) => {
                try {
                    if (rlab.isShared > 0 || rlab.owner._id === isAuth()._id) {
                        const data = JSON.parse(rlab.data);
                        for (let rec in data.records) data.records[rec].num = 'imp' + data.records[rec].num;
                        setSensorReading(true);
                        setSensorImported(data.records);
                        for (let m in data.markers) {
                            setChartMarkers({num:'imp' + m, data: data.markers[m]});
                        }
                        setSensorReading(false);
                    }
                } catch (err) {
                    console.log(err);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match?.params.id]);

    useEffect(() => {
        socket.on("FE-sensor-start", ({sensorList, devicesList}) => {
            if (sensorList && devicesList) {
                setConnectionType('net');
                for (let key in devicesList) {
                    addDevicesConnected({key, data: devicesList[key]});
                }
                setDeviceConnectedCount(Object.keys(devicesList).length);
                setSensorList(sensorList);
                togglePort(true);
                toast.success('Соединение установлено');
            }
        });
        socket.on("FE-sensor-item", (item) => {
            setSensor(item);
        });
        socket.on("FE-sensor-close", () => {
            closeNetSensor(true);
        });

        return () => {
            socket.off("FE-sensor-start");
            socket.off("FE-sensor-item");
            socket.off("FE-sensor-close");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!portOpen) {
            setSensorList([]);
            if (sensorStream) {
                setSensorStream(false);
                socket.emit("BE-sensor-close", {roomId:documentClass._id});
            }
            toggleSensor(false);
        }

        setSensorWritedList([]);
        setSensorImported([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portOpen]);

    useEffect(() => {
        if (sensor && sensorWriter && connectionType !== "net") {
            setSensorStream(true);
            socket.emit("BE-sensor-start", {roomId:documentClass._id, sensorList, devicesList: devList});
        }

        if (!sensor) {
            if (sensorStream) {
                setSensorStream(false);
                socket.emit("BE-sensor-close", {roomId:documentClass._id});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[sensor]);


    return (
        <ContentWrap className={(options.theme==="dark"?" dark":"")}>
            <Content background={false}>
                <MobileAreaSwicher/>
                <div className={ss.main}>
                    {(portOpen || imported?.length > 0) && !isMobile && <Aside/>}
                    <Dashboard/>
                </div>
                <Footer />
                <ChartStyles />
                <SensorsSettings />
                <Graduation />
                <TimerModal />
                <ModalPrint />
                <ModalBattery />
                <CompanyInfo />
                <BundleModalDel />
                <Firmware />
                <Connection />
                <ConnectedSensorsModal />
                <DemoLearn />
                <DemoModal />
                <SensorDataModal />
                <ChartCommentImageModal />
                <UnitDetailInfo {...props}/>
                {!portOpen && !imported.length > 0 && <Help dashboard={false} />}
            </Content>
            <CorFooter {...props}/>
        </ContentWrap>
    );
}

export default connect(mapRlabStateToProps, mapRlabDispatchToProps)(DashboardRlab)
