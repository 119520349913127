import React, { useEffect, useRef, useState } from 'react';
import ss from './AnalogDevice.module.scss';
import Icon from '../Icon';
import { Button, InputNumber } from 'rlabui';
import Analog from './devises/Analog';
import DigigtalHorizontal from './devises/DigigtalHorizontal';
import Lamp from './devises/Lamp';
import scales_def from './scales_def';
import AnalogVertical from './devises/AnalogVertical';
import LinearVertical from './devises/LinearVertical';
import LinearHorizontal from './devises/LinearHorizontal';

const AnalogDevice = ({ theme, sensor, data, chartStyle, defaultColors, measunits, played }) => {
    const [value, setValue] = useState();
    const [coeffK, setCoeffK] = useState(1);
    const [coeffB, setCoeffB] = useState(0);
    const [ticks, setTicks] = useState(0);
    const [max, setMax] = useState(100);
    const [min, setMin] = useState(0);
    const [avg, setAvg] = useState(0);
    const [size, setSize] = useState(100);
    const [width, setWidth] = useState(100);
    const [height, setHeight] = useState(100);
    const [view, setView] = useState('AnalogHorizontal');
    const wrapRef = useRef();
    const dropRef = useRef();
    const { name, num, unit, defaultUnit, roundDigit } = sensor;
    const defaultColor = defaultColors[num];
    const { color } = chartStyle[num] ? chartStyle[num] : { color: defaultColor.val };
    const scales = [
        { key: 'AnalogHorizontal', title: 'Дуговая с углом 270°', icon: 'speed-dashboard' },
        { key: 'AnalogVertical', title: 'Дуговая с углом 180°', icon: 'a-pressure' },
        { key: 'LinearVertical', title: 'Линейная вертикальная', icon: 'temperature1' },
        { key: 'LinearHorizontal', title: 'Линейная горизонтальная', icon: 'horisontal' },
        { key: 'Lamp', title: 'Лампа°', icon: 'lighting' },
        { key: 'DigigtalHorizontal', title: 'Цифровая', icon: 'ab-testing' },
    ];

    useEffect(() => {
        const wrap = wrapRef?.current;
        if (!wrap) return;
        const observer = new ResizeObserver(updateSize);
        observer.observe(wrap);
        return () => observer.disconnect();
    }, [wrapRef]);

    useEffect(() => {
        if (name) {
            setDefaultMinMax();
        }
        // eslint-disable-next-line
    }, [name]);

    useEffect(() => {
        let currentUnit = measunits[defaultUnit]?.filter((e) => e.Name === unit)[0];
        if (currentUnit) {
            setCoeffK(currentUnit.CoeffK);
            setCoeffB(currentUnit.CoeffB);
            updateVals();
        }
        // eslint-disable-next-line
    }, [unit, defaultUnit, measunits]);

    useEffect(() => {
        // console.log(data);
        if (num >= 0) {
            if (data[0]?.length) {
                updateVals();
            } else {
                setValue();
                setAvg(0);
            }
        }
        // eslint-disable-next-line
    }, [data, num]);

    useEffect(() => {
        updateVals();
        setDefaultMinMax();
        // eslint-disable-next-line
    }, [coeffB, coeffK]);

    const setDefaultMinMax = () => {
        const filterName = Object.keys(scales_def).filter((key) => key === name)[0];
        if (filterName) {
            setMin(scales_def[filterName].MinValue * coeffK + coeffB);
            setMax(scales_def[filterName].MaxValue * coeffK + coeffB);
        }
    };

    const updateVals = () => {
        const vals = data?.filter((e) => e[0]?.num === num)[0];

        if (vals) {
            const y = vals.map((e) => e.y) || 0;
            const minVal = Math.min(...y) || 0;
            const maxVal = Math.max(...y) || 0;
            if (y.length) {
                setValue(+y.at(-1));
                if (played) {
                    setAvg((minVal + maxVal) / 2);
                } else {
                    // setValue(y.at(-1) * coeffK + coeffB);
                    setAvg(((minVal + maxVal) / 2) * coeffK + coeffB);
                }
            }
        }
    };

    const updateSize = ([entries]) => {
        const { width, height } = entries.contentRect;
        setWidth(width);
        setHeight(height);
        if (height < width) {
            setSize(height);
        } else {
            setSize(width);
        }
    };

    const renderView = () => {
        switch (view) {
            case 'LinearVertical':
                return <LinearVertical props={{ theme, color, width, height, value, max, min, avg, roundDigit, unit, ticks }} />;

            case 'LinearHorizontal':
                return <LinearHorizontal props={{ theme, color, width, height, value, max, min, avg, roundDigit, unit, ticks }} />;

            case 'AnalogVertical':
                return <AnalogVertical props={{ theme, color, size, value, max, min, avg, roundDigit, unit, ticks }} />;

            case 'Lamp':
                return <Lamp props={{ theme, color, size, value: played ? value : min, max, min, avg, roundDigit, unit, num }} />;

            case 'DigigtalHorizontal':
                return <DigigtalHorizontal props={{ theme, color, size, value, max, min, avg, roundDigit, unit }} />;

            default:
                // sensor.num === 0 && console.log(value);

                return <Analog props={{ theme, color, size, value, max, min, avg, roundDigit, unit, ticks }} />;
        }
    };

    return (
        <div
            className={ss.root}
            onMouseLeave={() => dropRef.current.classList.remove('open')}
        >
            <div className={ss.top}>
                <div className={ss.title}>
                    <div
                        className={ss.color}
                        style={{ background: color }}
                    ></div>
                    {name}
                </div>
                <div className={ss.action}>
                    <div className={ss.group}>
                        {scales.map((scale) => {
                            const { key, icon, title } = scale;
                            return (
                                <Icon
                                    tag="button"
                                    className={key === view ? 'active' : ''}
                                    key={key}
                                    name={icon}
                                    onClick={() => setView(key)}
                                    title={title}
                                />
                            );
                        })}
                    </div>
                    <div
                        className={ss.drop}
                        ref={dropRef}
                    >
                        <div
                            className={ss.dropToggle}
                            onClick={() => dropRef.current.classList.toggle('open')}
                            title="Настройка шкал"
                        >
                            <Icon
                                tag="button"
                                name="gear"
                            />
                        </div>
                        <div className={ss.dropBody}>
                            <div className={ss.dropRow}>
                                <div className={ss.dropLabel}>Метки шкалы</div>
                                <InputNumber
                                    min={0}
                                    max={15}
                                    value={ticks}
                                    onChange={(e) => {
                                        const value = e.target.value ? +e.target.value : '';
                                        if (typeof value === 'number') {
                                            if (value > 15) {
                                                setTicks(15);
                                            } else if (value < 0) {
                                                setTicks(0);
                                            } else {
                                                setTicks(value);
                                            }
                                        } else {
                                            setTicks();
                                        }
                                    }}
                                />
                            </div>
                            <div className={ss.dropRow}>
                                <div className={ss.dropLabel}>Мин.</div>
                                <InputNumber
                                    value={min}
                                    onChange={(e) => {
                                        const value = e.target.value ? +e.target.value : '';
                                        if (typeof value === 'number') {
                                            setMin(value);
                                        } else {
                                            setMin();
                                        }
                                    }}
                                />
                            </div>
                            <div className={ss.dropRow}>
                                <div className={ss.dropLabel}>Макс.</div>
                                <InputNumber
                                    type="number"
                                    value={max}
                                    onChange={(e) => {
                                        const value = e.target.value ? +e.target.value : '';
                                        if (typeof value === 'number') {
                                            setMax(value);
                                        } else {
                                            setMax();
                                        }
                                    }}
                                />
                            </div>
                            <div className={ss.dropRow}>
                                <Button
                                    className="btn btn_small"
                                    onClick={() => {
                                        setTicks(0);
                                        setDefaultMinMax();
                                    }}
                                >
                                    Сбросить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={ss.body}
                ref={wrapRef}
            >
                {renderView()}
            </div>
            <div className={ss.foot}></div>
        </div>
    );
};
export default AnalogDevice;
