import React, {useState, useEffect} from "react";
import { isAuth } from "../../helpers/auth";
import Table, {TBL_ICON_NAME}  from "../ui/Table/Table";
import {printOnlyDate} from "../../helpers/text";
import socket from "../../socket";
import {DB_REFRESH} from "../ui/utils/gen_utils";
import {NB_ACT, NB_SPEC_TEXT, getAllNotebookInfoByOwner, getNotebookById, getSubjectById, 
    getDifferenceInDays, getNbSpecialStudentWorkCellStyle, getNbStudentWorkStatus, 
    getIsNbiInProgress, getIsNbiInProgressByInfo, getIsExecutionOutOfLimit, getIsTestTimeStarted, 
    getIsTestTimeCompleted}  from './nb_utils'; 
import NotebooksViewtResultByTeacherDlg from './NotebooksViewtResultByTeacherDlg';
import NotebooksStudentInitDlg from './NotebooksStudentInitDlg';
import {storageClass} from "../../redux/slices/storage";
import {useSelector} from "react-redux";
import {getScoreName, getScoreValues} from '../ui/utils/score_utils';
import { Content, ContentBody, ContentHead, Back, ContentTitle } from "../template/ContentParts";
import "../template/Dashboard/Dashboard.scss";
import nb from "./Notebooks.module.scss";

const NotebookStudentHomeWorksOnSubject = ({isLightMode, history, subjectId}) => {
    const [nbList, setNbList] = useState([]);
    const [nbiList, setNbiList] = useState([]);
	const [tableList, setTableList] = useState([]);
	const [showNbiResultDlg, setShowNbiResultDlg] = useState(false);
	const [selectedIds, setSelectedIds] = useState('');
    const [counter, setCounter] = useState(0);
	const [showInitDlg, setShowInitDlg] = useState(false);

    const documentClass = useSelector(storageClass);

	useEffect(() => {
        socket.on("FE-refresh-db", ({type}) => {
            if (type === DB_REFRESH.NBI) setCounter(counter => counter + 1);
        });
        return () => {socket.off("FE-refresh-db");};
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const result = await getAllNotebookInfoByOwner(documentClass._id, isAuth()._id);
            const nbis = result.data;
            setNbiList(nbis);

            const list = [];
            const _nbLst = [];
            const scoreValues = getScoreValues();
            const minValue = Number(scoreValues[scoreValues.length - 1]);

            for (let i = 0; i < nbis.length; i ++) {
                const nbi = nbis[i];
                const notebookId =  nbi.notebook; 
    
                const nb = await getNotebookById(notebookId);
    
                if (nb.titles.subjectId === subjectId) {
                    const timeRun = (new Date(nb.run.runFullDate)).getTime();
                    const timeComplete = (new Date(nb.run.completeFullDate)).getTime();

                    const isCompleteTest = nbi.isComplete; 
                    const isExecutionOutOfLimit = getIsExecutionOutOfLimit(nb, nbi);

                    const result = getScoreText(nb, nbi);

                    const isTimeStarted = getIsTestTimeStarted(timeRun); 
                    const isTimeCompleted = getIsTestTimeCompleted(timeComplete);
                    const inProgress = getIsNbiInProgressByInfo(nb, nbi);

                    if (isTimeStarted) {
                        const status = getNbStudentWorkStatus(nbi.score, isCompleteTest, 
                            isTimeCompleted, isExecutionOutOfLimit, inProgress, minValue);
                   
                        const rec = {
                            name: nb.titles.homeWorkName,
                            item: nb.titles.homeWorkItem,
                            dateStart: printOnlyDate(timeRun),
                            leftDays: getDifferenceInDays(timeComplete),
                            taskAmount : nbi.tasks.length + ' шт.',
                            result: result,
                            status: status,
                            actions: '', //for action buttons
                            id: notebookId + '|' + nbi._id + '|' + isAuth()._id, //notebook Id, notebookInfo id  and user id
                        };
        
                        list.push(rec);
                        _nbLst.push(nb);
                    }
                }
            }

            setTableList(list);
            setNbList(_nbLst);
        };

		fetchData();
    }, [documentClass, counter, subjectId]);

    const getScoreText = (_notebookData, notebookInfo) => {
        const score = notebookInfo.score;
        if (score === 0 || !!score) return getScoreName(score);
        return NB_SPEC_TEXT.INFO_NO_DATA;
    };

    const handleOpenInitDlg = ids => {
        if (!getIsNbiInProgress(nbList, nbiList, ids)) {
            setSelectedIds(ids);
            setShowInitDlg(true);
        } else {
            history.push('/notebooks/' + NB_ACT.RUN_TEST + '/' + ids);
        }
    };

    const handleExecuteTest = () => {
        setShowInitDlg(false);
        history.push('/notebooks/' + NB_ACT.RUN_TEST + '/' + selectedIds); //ids is nbId + '|' + nbiId
    };

    const handleShowDetails = ids => {
        const arr = ids.split('|');
        setSelectedIds(arr[2] + '|' + arr[1]);
        setShowNbiResultDlg(true);
    };

    const specialActionCriteria = (ids, iconName) => {
        const rec = tableList.find(item => item['id'] === ids);
        const status = rec['status'];
        if (status === NB_SPEC_TEXT.NEW && iconName === TBL_ICON_NAME.EXECUTE) 
            return true;
        else if (status === NB_SPEC_TEXT.IN_PROGRESS && iconName === TBL_ICON_NAME.EXECUTE) 
            return true;
        else if (status !== NB_SPEC_TEXT.NEW && status !== NB_SPEC_TEXT.IN_PROGRESS && 
            iconName === TBL_ICON_NAME.SHOW_DETAILS) 
            return true;
        
        return false;
    };

    const getTableHeader = () => {
		const header = [
			{column: 'Название', name: 'name', style: { width: '15%'} }, 
			{column: 'Тема', name: 'item', style: { width: '12%'} }, 
			{column: 'Дата начала', name: 'dateStart', style: { width: '12%'} }, 
			{column: 'Осталось дней', name: 'leftDays', style: { width: '12%'} }, 
			{column: 'Задания', name: 'taskAmount', style: { width: '10%'} }, 
			{column: 'Результат', name: 'leftDays', style: { width: '10%'} }, 
			{column: 'Статус', name: 'status', style: { width: '12%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '17%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
        ];
		return header;
	};

    const getHomeWorkTable = () => {
		return (
		<div className={nb.nb__table}>
			<Table 
				table={{
                    header: getTableHeader(), 
					data: tableList,
                    specialCellStyle: getNbSpecialStudentWorkCellStyle
				}}
				sort={{hasSorting: true, 
                       initSortInd: -3
                }}
				actions={{
					handleExecuteTest: ids => handleOpenInitDlg(ids),
					textExecuteTest: 'Выполнить задание',
                    handleShowDetails: ids => handleShowDetails(ids),
                    textShowDetails: 'Детальный результат',
                    specialActionCriteria: specialActionCriteria
				}}
			/>
		</div>
		);
    };

    return  (
        <>
            <ContentHead column={true}> 
                <Back onClick={() => history.push('/notebooks/' + NB_ACT.SHOW_ALL_NBS)} icon="back"/>
                <ContentTitle>{"Задания по предмету " + getSubjectById(subjectId)}</ContentTitle>
            </ContentHead>
            
            <Content>
                <ContentBody>
                    {getHomeWorkTable()}
                </ContentBody>
                {showNbiResultDlg &&
                <NotebooksViewtResultByTeacherDlg
                    showDlg={showNbiResultDlg}
                    setShowDlg={setShowNbiResultDlg}
                    selectedIds={selectedIds}
                    setScoreUpdated={() => {}}
                />
                }
            </Content>

            {showInitDlg && <NotebooksStudentInitDlg
                showDlg={showInitDlg}
                setShowDlg={setShowInitDlg}
                selectedIds={selectedIds}
                handleExecuteTest={handleExecuteTest}
            />}
        </>
    );
}

export default NotebookStudentHomeWorksOnSubject;
