//ОБРАЗЕЦ ---- https://www.geogebra.org/m/ex7UGVQM
import React, {useState, useRef, useEffect} from "react";
import {CALC_MODEL, SCALEPOS, EXP_STATE, SAVE_DATA_TYPE, GraphicOpts, getSvgWithHeightWidth, getLenseInfo, getCurveByArr, getGrid, getInputRange,
	getScaleData, getScaleValues, getAxis, getStrokes, printDiv,
	getBackground, getBorder, transInnerCoordsToScreenCoords, getScreenAreaPictureById, getCoordLegend, 
	getSvgMouseCrossCursor, getHeaderBox, getModeColorOpts,
	getTimeStringByTime, getPlayPauseExperiment, clearTimer, workProgress, doNextProgressStep, 
	addCalcModelInfo, getCalcModelInfoById, canStartAction, completeAction } from "./cm_utils";
import {getRoundValue, getDropdown} from "../ui/utils/gen_utils";
import SaveXlsFileConfirmDialog from "../ui/ModalDialogs/SaveXlsFileConfirmDialog";
import {isAuth} from "../../helpers/auth";
import {storageClass} from "../../redux/slices/storage";
import {useSelector, useDispatch} from "react-redux";
import {reloadOption} from "../../redux/slices/storage";
import { saveExcel } from './excel_utils';
import { ActionFile, Icon } from '../ui';
import {toast} from "react-toastify";
import { Content, ContentBody, ContentFooter } from "../template/ContentParts";
import Button from "../ui/Button/Button";
import "./CalcModel.scss";

const MAX_STEP_NUMBER = 75; //we show 3/4 screen
const FREQUENCY_IN_MSEC = 100;
const DEFAULT_SVG_X_RANGE2 = 250;
const DEFAULT_SVG_Y_RANGE2 = 60;
const SVG_X_RANGE = [-DEFAULT_SVG_X_RANGE2, DEFAULT_SVG_X_RANGE2];
const SVG_Y_RANGE = [-DEFAULT_SVG_Y_RANGE2, DEFAULT_SVG_Y_RANGE2];
const LENSE_RADIUS_RANGE = [0, 10]; //[-10, 10];
const REFRACTION_RANGE = [1.01, 3.0];
const LIGHT_RADIUS_RANGE = [8, 40];
const RAY_RANGE = [2, 10]; //ray number

const LENSE_TYPE_CONVEX = 1; //выпуклая
const LENSE_TYPE_CONCAVE = 2; //вогнутая
const LENSE_TYPE_FLAT = 3; //плоская

const LENSE_LEFT_INDEX = 1;
const LENSE_RIGHT_INDEX = 2;

const LENSE_HEIGHT = 60;
const LENSE_HEIGHT2 = LENSE_HEIGHT/2;

const idMainSvg ='mainSvg';

const materials = [
	{label: 'Лед (1.31)', value: 1.31},
	{label: 'Стекло (1.52)', value: 1.52},
	{label: 'Кварц (1.54)', value: 1.54},
	{label: 'Янтарь (1.55)', value: 1.55},
	{label: 'Слюда (1.58)', value: 1.58},
	{label: 'Алмаз (2.42)', value: 2.42},
	{label: 'Кремний (3.46)', value: 3.46},
];
const lenseTypes = [
	{label: 'Выпуклая', value: LENSE_TYPE_CONVEX},
	{label: 'Вогнутая', value: LENSE_TYPE_CONCAVE},
	{label: 'Плоская', value: LENSE_TYPE_FLAT},
];

const svgXRange = [-DEFAULT_SVG_X_RANGE2, DEFAULT_SVG_X_RANGE2]; //setSvgXRange
const svgYRange = [-DEFAULT_SVG_Y_RANGE2, DEFAULT_SVG_Y_RANGE2]; //setSvgYRange
const scaleCoordX = 50;

const Lense = ({history, match, cmInfoId, isInnerCM, isLightMode}) => {
	const [lenseLeftRadius, setLenseLeftRadius] = useState( 5); //левый радиус линзы (0  +10 mm)
    const [lenseRightRadius, setLenseRightRadius] = useState( 5); //правый радиус линзы (0  +10 mm)
    const [lensePrevLeftRadius, setLensePrevLeftRadius] = useState( 5); //хранить предыдущее знначение при выборе FLAT
    const [lensePrevRightRadius, setLensePrevRightRadius] = useState( 5); //хранить предыдущее знначение при выборе FLAT
    const [lenseLeftType, setLenseLeftType] = useState( LENSE_TYPE_CONVEX);
    const [lenseRightType, setLenseRightType] = useState( LENSE_TYPE_CONVEX);
    const [refraction, setRefraction] = useState( 1.31); //преломление (>= 1)
    const [lightRadius, setLightRadius] = useState( LIGHT_RADIUS_RANGE[0]); //min 8 mm (2 луча), мах 40 мм (10 лучей))
    const [showBackwardRays, setShowBackwardRays] = useState(true);
    const [drawnBackwardRays, setDrawnBackwardRays] = useState(false);
    const [backwardRays, setBackwardRays] = useState([]);
    const [svgArr, setSvgArr] = useState([]);
    const [svgWidth, setSvgWidth] = useState(undefined);
    const [svgHeight, setSvgHeight] = useState(undefined);
    const [centerX, setCenterX] = useState(0);
    const [centerY, setCenterY] = useState(0);
    const [cursorX, setCursorX] = useState(0);
    const [cursorY, setCursorY] = useState(0);
    const [mouseCoordInfo, setMouseCoordInfo] = useState(undefined);
    const [focalValue, setFocalValue] = useState(false);
	const [experimentResults, setExperimentResults] = useState([]);
	const [svg, setSvg] = useState(undefined);
	const [refreshCount, setRefreshCount] = useState(0);
	const [timerId, setTimerId] = useState(undefined);
	const [currProgressInSec, setCurrProgressInSec] = useState(0);
	const [selectedMaterial, setSelectedMaterial] = useState( 1.31);
	const [resize, setResize] = useState(0);
    const [showConfirmSaveXlsDlg, setShowConfirmSaveXlsDlg] = useState(false);
	const [makePrint, setMakePrint] = useState(false);
	const [makeXls, setMakeXls] = useState(false);
	const [isLightModeChanged, setIsLightModeChanged] = useState(false);
	const [saveDataType, setSaveDataType] = useState(SAVE_DATA_TYPE.XLS);
	//
    const [expState, setExpState] = useState(EXP_STATE.NOT_STARTED);
    const [played, setPlay] = useState(false);
    const [paused, setPaused] = useState(false);
    const [startPlay, setStartPlay] = useState(false);
	
    const documentClass = useSelector(storageClass);
	const dispatch = useDispatch();
	const refMainSvg = useRef();
	const refMainBox = useRef();
	const lenseLeftRadiusRef = useRef();
	const lenseRightRadiusRef = useRef();
	const refractionRef = useRef();
	const lightRadiusRef = useRef();
	
	const handleMouseMoveEvent = (event) => {
		setCursorX(event.clientX);
		setCursorY(event.clientY);
	};

	useEffect(() => {
		const observer = new ResizeObserver((e) => setResize(e[0].contentBoxSize[0].inlineSize))
		observer.observe(refMainBox.current);
		document.getElementById(idMainSvg)?.addEventListener("mousemove", handleMouseMoveEvent);
		return () => {
			observer.disconnect();
			document.getElementById(idMainSvg)?.removeEventListener("mousemove", handleMouseMoveEvent);
		};
	}, []);

	useEffect(() => {
		if (match.params.opt) {
			getCalcModelInfoById(match.params.opt).then((data) => {
				const cmInfoId = data[0].options;
				setLenseRightRadius(cmInfoId.lenseRightRadius);
				setLenseLeftRadius(cmInfoId.lenseLeftRadius);
				setLensePrevLeftRadius(cmInfoId.lenseRightRadius);
				setLensePrevLeftRadius(cmInfoId.lenseLeftRadius);
				setLenseLeftType(cmInfoId.lenseLeftType);
				setLenseRightType(cmInfoId.lenseRightType);
				setRefraction(cmInfoId.refraction);
				setLightRadius(cmInfoId.lightRadius);
				setSelectedMaterial(cmInfoId.refraction);
			});
		}
	}, [match.params.opt]);

	useEffect(() => {
		const svgBox = document.getElementById(idMainSvg);
		const svgR = svgBox?.getBoundingClientRect();

		const innerMainR = document.getElementById('idInnerMainBox')?.getBoundingClientRect();
		const commonR = document.getElementById('idCommon')?.getBoundingClientRect();
		const width = (innerMainR.width - (svgR.left - innerMainR.left));
		const height = innerMainR.height - commonR.height;
		setSvgWidth(width);
		setSvgHeight(height);
		setCenterX(width * 0.5);
		setCenterY(height * 0.5);
	}, [resize]);

	const getRadius = (lenseRadius, lenseType) => {
		return lenseRadius === 0 ? 0.0001 : lenseType === LENSE_TYPE_CONVEX ? lenseRadius : -lenseRadius;
	};
	useEffect(() => {
		const calcFocalValue = (_lenseLeftRadius, _lenseRightRadius, _lenseLeftType, _lenseRightType, _refraction) => {
			const leftRadius = getRadius(_lenseLeftRadius, _lenseLeftType);
			const rightRadius = getRadius(_lenseRightRadius, _lenseRightType);
			if (leftRadius + rightRadius === 0) return 10000;
			const focal = 1 / ((_refraction - 1) * (1 / leftRadius + 1 / rightRadius));
			return focal;
		};
		const _focalValue = calcFocalValue(lenseLeftRadius, lenseRightRadius, lenseLeftType, lenseRightType, refraction);
		setFocalValue(_focalValue);
	
		const mouseInfo = getLenseInfo(svgXRange, svgYRange, svgWidth, svgHeight, cursorX, cursorY);
		setMouseCoordInfo(mouseInfo);
	}, [refraction, lenseLeftRadius, lenseRightRadius, lenseLeftType, lenseRightType, cursorX, cursorY, 
		svgHeight, svgWidth]);		

	const getScalePos = (isHoriz, coord, width) => {
		if (isHoriz)
			return (width-1) * coord * 0.01;
		else 
			return (width-1) * (100 - coord) * 0.01;
	};

	//https://yqnn.github.io/svg-path-editor/ - editing complex svg curves
	//https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d#elliptical_arc_curve - рисование части эллипса

	const getLense = (svgData, xRANGE, yRANGE, _svgWidth, _svgHeight, _centerX, _centerY, 
		_lenseLeftType, _lenseRightType, _lenseLeftRadius, _lenseRightRadius) => {

		const getRadiuses = (_centerY, _lenseRadius) => {
			const calcRadiusY = _centerY * 30 / (3 * Math.abs(_lenseRadius) + 10);	
			const radiuses =  [_centerY, calcRadiusY]; 
			return radiuses;
		};

		const getConvexLense = (isRight, _centerX, _centerY, _lenseRadius) => {  //выпуклая
			const addX = 5 * (isRight ? +1 : -1);
			const start = [_centerX, _centerY - _svgHeight/4];
			const finish = [_centerX, _centerY + _svgHeight/4];
			const radiuses = getRadiuses(_centerY, _lenseRadius);
			const direction = isRight ? 1 : 0; 

			const d = 
					start[0] + " " + start[1] + " L " + 
					(start[0]+addX) + " " + start[1] + " " + 
					"A " + radiuses[0] + " " + radiuses[1] + " , 0, 0 " + direction +", " + 
					(finish[0]+addX) + " " + finish[1] + " L " + 
					finish[0] + " " + finish[1];
			return d;
		};

		const getFlatLense = (isRight, _centerX, _centerY) => {  //плоская
			const addX = 5 * (isRight ? +1 : -1);
			const start = [_centerX, _centerY - _svgHeight/4];
			const finish = [_centerX, _centerY + _svgHeight/4];

			const d = 
					start[0] + " " + start[1] + " L " + 
					(start[0]+addX) + " " + start[1] + " L " + 
					(finish[0]+addX) + " " + finish[1] + " L " + 
					finish[0] + " " + finish[1];
			return d;
		};

		const getConcaveLense = (isRight, _centerX, _centerY, _lenseRadius) => {  //вогнутая
			const addX = 5 * (isRight ? +1 : -1);
			const start = [_centerX + addX, _centerY - _svgHeight/4];
			const finish = [_centerX + addX, _centerY + _svgHeight/4];
			const radiuses = getRadiuses(_centerY, _lenseRadius);

			const sr = (start[1]-_centerY) / radiuses[1];
			const sq = Math.pow(1 - sr * sr, 0.5);
			const c0 = start[0] - _centerX - radiuses[0] * sq;
			const dX = c0 + radiuses[0] - start[0] + _centerX;
	
			const dirYY = isRight ? +1 : -1;
			const start2 = [_centerX + dX * dirYY + addX, start[1]];
			const finish2 = [_centerX + dX * dirYY + addX, finish[1]];
			const direction = isRight ? 0 : 1; 

			const d2 = (start[0]-addX) + " " + start[1] + " L " + 
					   start2[0] + " " + start2[1] + " " + 
					"A " + radiuses[0] + " " + radiuses[1] + " , 0, 0 " + direction +", " + 
					finish2[0] + " " + finish2[1] + 
					" L " + (finish[0]-addX) + " " + finish[1] ;
			return d2;
		};

		const dRight = "M " + (_lenseRightType === LENSE_TYPE_CONVEX ? getConvexLense(true, _centerX, _centerY, _lenseRightRadius) : 
						_lenseRightType === LENSE_TYPE_CONCAVE ? getConcaveLense(true, _centerX, _centerY, -_lenseRightRadius) : 
						getFlatLense(true, _centerX, _centerY));
								
		const dLeft = " L " + (_lenseLeftType === LENSE_TYPE_CONVEX ? getConvexLense(false, _centerX, _centerY, _lenseLeftRadius) : 
						_lenseLeftType === LENSE_TYPE_CONCAVE ? getConcaveLense(false, _centerX, _centerY, _lenseLeftRadius) : 
						getFlatLense(false, _centerX, _centerY));

		const inner = <path fill={GraphicOpts.lenseInnerColor} stroke={GraphicOpts.lenseBorderColor} key="lense01" 
						opacity={GraphicOpts.lenseInnerOpacity} d={dRight+dLeft} />;
		svgData.push(inner);

		//draw the lense border:
		const border = <path fill={'transparent'} stroke={GraphicOpts.lenseBorderColor} key="lense02" 
							strokeWidth={GraphicOpts.lenseBorderWidth} d={dRight+dLeft} />;
		svgData.push(border);
	};

	const getRays = (svgData, xRANGE, yRANGE, _svgWidth, _svgHeight, _centerX, _centerY, 
		_focusValue, _lightRadius, _showBackwardRays, _drawnBackwardRays, _refreshCount) => {

		const lightNumber = RAY_RANGE[0] + (_lightRadius-LIGHT_RADIUS_RANGE[0])/(LIGHT_RADIUS_RANGE[1]-LIGHT_RADIUS_RANGE[0]) * 
				(RAY_RANGE[1]-RAY_RANGE[0]);
		let showFocusPoint = false;
		const yShift = LENSE_HEIGHT2 / RAY_RANGE[1] - 1;
				
		const startX = transInnerCoordsToScreenCoords(xRANGE, yRANGE, _svgWidth, _svgHeight, xRANGE[1]*0.5, 0)[0];
		const distanceX = transInnerCoordsToScreenCoords(xRANGE, yRANGE, _svgWidth, _svgHeight, 2* xRANGE[1], 0)[0]; // - startX;
		const borderX = startX + 0.01 * distanceX * _refreshCount;
		//const borderX = 0.0075 * distanceX * _refreshCount; //will start from 0 point

		const focusX = transInnerCoordsToScreenCoords(xRANGE, yRANGE, _svgWidth, _svgHeight, _focusValue, 0)[0];
		const focusY = _centerY;
		const focusPos = [_centerX + focusX, focusY];
		const xMax = transInnerCoordsToScreenCoords(xRANGE, yRANGE, _svgWidth, _svgHeight, xRANGE[1] * 2, 0)[0];
		let isRedRaysPrepared = false;
		let xyRedList = [];

		for (let i = -lightNumber; i <= lightNumber; i ++) {
			if (i !== 0) {
				let xyList = [];

				//1. start point: 
				const yDist = transInnerCoordsToScreenCoords(xRANGE, yRANGE, _svgWidth, _svgHeight, 0, i * yShift)[1];
				const startPos = [_centerX - startX, _centerY - yDist];
				xyList.push(startPos);

				//2. center point:
				const centerPos = [Math.min(_centerX, borderX), _centerY - yDist];
				xyList.push(centerPos);

				if (borderX > _centerX) {
					//3. focus pont:
					const k = (focusPos[1] - centerPos[1]) / (focusPos[0] - centerPos[0]);
					const b = focusPos[1] - k * focusPos[0];

					if (focusX > 0 && borderX <= _centerX + focusX) {
						xyList.push([borderX, k * borderX + b]);
					}

					//4. end point: 
					if ((focusX < 0 && borderX > _centerX) || (focusX => 0 && borderX > _centerX + focusX))
						showFocusPoint = true;

					if (focusX < 0 && _showBackwardRays && !_drawnBackwardRays) {
						isRedRaysPrepared = true;
						xyRedList.push([_centerX + focusX, _centerY]);
						xyRedList.push([_centerX, k * _centerX + b]);
					} else {
						const [x,y] = borderX <= xMax ? [borderX, k * borderX + b] : [xMax, k * xMax + b];
						xyList.push([x,y]);
					}
				}

				const curve = getCurveByArr(xyList, GraphicOpts.lenseRayColor, GraphicOpts.lenseRayWidth, 'rays01'+i);
				svgData.push(curve);
			}
		}

		if (isRedRaysPrepared) {
			setDrawnBackwardRays(true); 
			setBackwardRays(xyRedList);
		} 

		//if (showFocusPoint) svgData.push(getFocusPoint(xRANGE, yRANGE, _svgWidth, _svgHeight, _centerX, _centerY, _focusValue));
		return showFocusPoint;
	};
	
	useEffect(() => {
		//https://www.w3schools.com/graphics/svg_stroking.asp - svg options
		if (!svgWidth || !svgHeight) return;
		
		//0 - массив для хранения всех элементов svg:
		const svgData = [];

		//1 - get full rectangle with backround color:
		svgData.push(getBackground(svgWidth, svgHeight, GraphicOpts.backgroundOpacity, getModeColorOpts(isLightMode).calcModelBknd));

		//2 - get axises:
		const xScalePos = getScalePos(true, scaleCoordX, svgWidth);
		svgData.push(getAxis(xScalePos, 0, 1, svgHeight, GraphicOpts.scaleLineColor, GraphicOpts.scaleLineWidth, GraphicOpts.scaleLineOpacity, "40,10"));

	  	//const yScalePos = getScalePos(false, scaleCoordY, svgHeight);
		// svgData.push(getAxis(0, yScalePos, svgWidth, 1, GraphicOpts.scaleLineColor, GraphicOpts.scaleLineWidth, GraphicOpts.scaleLineOpacity, "40,10"));

		//3 - initialize scale arrays:
		const horizScaleArr = getScaleData(SCALEPOS.horiz, SCALEPOS.right, 17, svgXRange[0], svgXRange[1],   0, svgWidth, svgHeight * 0.5,    1, 0, 0);
		const vertScaleArr = getScaleData(SCALEPOS.vert, SCALEPOS.right, 9, svgYRange[0], svgYRange[1],   0, svgHeight, svgWidth * 0.5,    6, 0, 0);
		
		//4 - get grid:
		svgData.push(getGrid(horizScaleArr, SCALEPOS.horiz, 0, 0, svgWidth, svgHeight, getModeColorOpts(isLightMode).gridColor, GraphicOpts.gridWidth, GraphicOpts.gridOpacity));
		svgData.push(getGrid(vertScaleArr, SCALEPOS.vert, 0, 0, svgWidth, svgHeight, getModeColorOpts(isLightMode).gridColor, GraphicOpts.gridWidth, GraphicOpts.gridOpacity));

		//5 - get center axises and scales:
		//x-axis:
		const horizStrokes = getStrokes(horizScaleArr, GraphicOpts.timeColor, 1, 0.9, "horiz");
		svgData.push(horizStrokes); //1) strokes
		const horizScaleValues = getScaleValues(horizScaleArr, SCALEPOS.horiz, SCALEPOS.left, GraphicOpts.timeColor, "horiz1", true);
		svgData.push(horizScaleValues); //2) values on xAxis 
		svgData.push(getAxis(0, svgHeight * 0.5, svgWidth, 1, GraphicOpts.scaleLineColor, GraphicOpts.scaleLineWidth, GraphicOpts.scaleLineOpacity)); //3) xAxis

		//y-axis:
		// const vertStrokes = getStrokes(vertScaleArr, GraphicOpts.timeColor, 1, 0.9, "vert");
		// svgData.push(vertStrokes);  //1) strokes
		// const vertScaleValues = getScaleValues(vertScaleArr, SCALEPOS.vert, SCALEPOS.right, GraphicOpts.timeColor, "time1", true);
		// svgData.push(vertScaleValues); //2) values on yAxis 
		// svgData.push(getAxis(svgWidth * 0.5, 0, 1, svgHeight, GraphicOpts.scaleLineColor, GraphicOpts.scaleLineWidth, GraphicOpts.scaleLineOpacity)); //3) yAxis

		//6 - get lense:
		getLense(svgData, SVG_X_RANGE, SVG_Y_RANGE, svgWidth, svgHeight, centerX, centerY, lenseLeftType, lenseRightType, 
			getRadius(lenseLeftRadius, lenseLeftType), getRadius(lenseRightRadius, lenseRightType));
	
	
		let showFocusInfoInLegend = false;
		//7 - get rays and focus point:
		if (expState !== EXP_STATE.NOT_STARTED)
			showFocusInfoInLegend = getRays(svgData, SVG_X_RANGE, SVG_Y_RANGE, svgWidth, svgHeight, centerX, centerY, 
				focalValue, lightRadius, showBackwardRays, drawnBackwardRays, refreshCount);

		if (backwardRays.length > 0 && showBackwardRays) { 
			const focusX = transInnerCoordsToScreenCoords(SVG_X_RANGE, SVG_Y_RANGE, svgWidth, svgHeight, focalValue, 0)[0];
			if (focusX < 0) // show red curves
				svgData.push(getCurveByArr(backwardRays, GraphicOpts.lenseRedRayColor, GraphicOpts.lenseRayWidth, 'redrays10'));
		}

		//8 - get focus:
		//svgData.push(getFocusPoint(SVG_X_RANGE, SVG_Y_RANGE, svgWidth, svgHeight, centerX, centerY, focalValue));

		//9 - get legends:
		getCoordLegend(svgData, mouseCoordInfo, focalValue, showFocusInfoInLegend, isLightMode);
		//svgData.push(getLegend(centerX, centerY, focalValue));

		//10 - get border:
		svgData.push(getBorder(svgWidth, svgHeight, GraphicOpts.innerBoxColor, "_border01"));

		//11 - get moved mouse cursor
		svgData.push(getSvgMouseCrossCursor(cursorX, cursorY, idMainSvg));

		setSvgArr(svgData);
	}, [centerX, centerY, cursorX, cursorY, focalValue, lenseLeftRadius, lenseRightRadius, lenseLeftType, lenseRightType, 
		lightRadius, svgHeight, svgWidth, expState, refreshCount, 
		mouseCoordInfo, showBackwardRays, drawnBackwardRays, backwardRays, isLightMode]);

	const getSvg = (obj) => {
		return getSvgWithHeightWidth(svgHeight, svgWidth, obj, 'svg1');
	};

	const getLenseTypeNameById = type => {
		return lenseTypes.find(item => item.value === type).label;
	};

    const saveExport = (name, description) => {
        if (saveDataType === SAVE_DATA_TYPE.PROFILE) {
            saveProfile(name, description);
        } else {
            saveXLSX(name, description);
        }
    };

	const saveProfile = async (name, description) => {
		const options = {
			lenseLeftRadius: lenseLeftRadius,
			lenseRightRadius: lenseRightRadius, 
			lenseLeftType: lenseLeftType, 
			lenseRightType: lenseRightType,
			refraction: refraction, 
			lightRadius: lightRadius,
		};

        const profile = {
            owner: isAuth()._id,
			room: documentClass._id,
            cmType: CALC_MODEL.LENSE,
            name: name,
            description: description,
			options: options,
        };

        await addCalcModelInfo(profile, documentClass._id);
    };

	const saveXLSX = (workBookName, description) => { 
		const getTableData = (experiment) => {
			const materialName = getMaterialNameById(experiment.refraction)
			return [{
				description: description,
				lenseLeftType: getLenseTypeNameById(experiment.lenseLeftType),
				lenseRightType: getLenseTypeNameById(experiment.lenseRightType),
				lenseLeftRadius: getRoundValue(experiment.lenseLeftRadius, 2),
				lenseRightRadius: getRoundValue(experiment.lenseRightRadius, 2),
				refraction : getRoundValue(experiment.refraction, 2) + (materialName ? ', ' + materialName : ''),
				lightRadius: getRoundValue(experiment.lightRadius, 2),
				focalValue: getRoundValue(experiment.focalValue, 2),
				lense: experiment.focalValue > 0 ? "собирающая" : "рассеивающая",
			}];
		};

		const getGraphicData = (experiment) => {
			return [{
				image: experiment.svg,
				colNum: 16,
			}];
		};

		if (experimentResults.length === 0) return;

		const tableColumns = [
			{ header: 'Описание эксперимента', key: 'description' },
			{ header: 'Левая часть линзы', key: 'lenseLeftType' },
			{ header: 'Правая часть линзы', key: 'lenseRightType' },
			{ header: 'Радиус кривизны левой поверхности, мм', key: 'lenseLeftRadius' },
			{ header: 'Радиус кривизны правой поверхности, Ом', key: 'lenseRightRadius' },
			{ header: 'Показатель преломления материала линзы', key: 'refraction' },
			{ header: 'Пучок света', key: 'lightRadius' },
			{ header: 'Фокус, мм', key: 'focalValue' },
			{ header: 'Тип линзы  ', key: 'lense' }
		];
		
		const tableSheets = [];
		for (let i = 0; i < experimentResults.length; i ++) {
			const tableSheet = {
				workSheetName: "Таблица " + (i+1),
				columns: tableColumns,
				tableData: getTableData(experimentResults[i]),
				graphicData: getGraphicData(experimentResults[i]),
			};
			tableSheets.push(tableSheet);
		}

		saveExcel(workBookName, tableSheets, []);
		setExperimentResults([]);
	};
	
	const handleProfile = () => {
		if (expState === EXP_STATE.NOT_STARTED) 
			toast.warn("Начните эксперимент.");
		 else {
	        setSaveDataType(SAVE_DATA_TYPE.PROFILE);
    	    setShowConfirmSaveXlsDlg(true);
		 }
	};

	const handleExportExperiment = () => {
		if (expState === EXP_STATE.NOT_STARTED) 
			toast.warn("Начните эксперимент.");
		 else {
			setSaveDataType(SAVE_DATA_TYPE.XLS);
			//step 1 of 3: change light mode if needed and request to save svg
			canStartAction(isLightMode, dispatch, reloadOption, setIsLightModeChanged, setMakeXls);
		 }
	};

	useEffect(() => {
		if (!makeXls) return;
		//step 2 of 3: save svg and request to calc expResults
		getScreenAreaPictureById(idMainSvg, img => setSvg(img)); //save image
		//change bknd mode back:
		setTimeout(() => completeAction(dispatch, setMakeXls, isLightModeChanged, setIsLightModeChanged, reloadOption), 100);
	}, [dispatch, isLightModeChanged, makeXls]);

	useEffect(() => {
		//step 3 of 3: prepare expResults and run the export dlg
		if (!svg) return;

		const expData = { 
			lenseLeftRadius: lenseLeftRadius,
			lenseRightRadius: lenseRightRadius,
			lenseLeftType: lenseLeftType,
			lenseRightType: lenseRightType,
			refraction: refraction,
			lightRadius: lightRadius,
			focalValue: focalValue,
			svg: svg
		};
		setExperimentResults([expData]);
		setSvg(undefined);
		setShowConfirmSaveXlsDlg(true);
	}, [experimentResults, lenseLeftRadius, lenseRightRadius, lightRadius, lenseLeftType, lenseRightType, refraction, svg, focalValue]);

	const handleCancelSave = () => {
		setExperimentResults([]);
		setShowConfirmSaveXlsDlg(false);
	};

	const handlePrint = () => {
		canStartAction(isLightMode, dispatch, reloadOption, setIsLightModeChanged, setMakePrint);
	};

    useEffect(() => {
		if (!makePrint) return;

		const materialName = getMaterialNameById(refraction);
		const modelParams = [
			['Левая сторона линзы: ', getLenseTypeNameById(lenseLeftType)],
			['Левый радиус кривизны: ', 'R=' + getRoundValue(lenseLeftRadius, 2) + ' мм'],
			['Правая сторона линзы: ', getLenseTypeNameById(lenseRightType)], 
			['Правый радиус кривизны: ', 'R=' + getRoundValue(lenseRightRadius, 2) + ' мм'],
			['Показатель преломления материала линзы: ', 'n=' + getRoundValue(refraction, 2) + (materialName ? ', ' + materialName : '')],
			['Диаметр падающего пучка: ', 'D=' + getRoundValue(lightRadius, 2) + ' мм'],
		];		

		printDiv([idMainSvg], 'Фокусное расстояние линзы', modelParams, ["./CalcModel.scss"]);
		setTimeout(() => completeAction(dispatch, setMakePrint, isLightModeChanged, setIsLightModeChanged, reloadOption), 100);
	}, [dispatch, isLightModeChanged, lenseLeftRadius, lenseLeftType, lenseRightRadius, lenseRightType, 
		lightRadius, makePrint, refraction]);

	const isInputRangeEnabled = () => {
		return expState === EXP_STATE.NOT_STARTED;
	};

	const clearBackwardRays = () => {
		setDrawnBackwardRays(false); 
		setBackwardRays([]);
	};

	useEffect(() => {
		setCurrProgressInSec(getTimeStringByTime(FREQUENCY_IN_MSEC / 1000. * refreshCount));
		if (refreshCount >= MAX_STEP_NUMBER) {
			setExpState(EXP_STATE.FINISHED);
			clearTimer(timerId, setTimerId);
		}
	}, [refreshCount, timerId]);
    useEffect(() => {
        if (startPlay) {
            setTimeout(() => {setPlay(true); setStartPlay(false);}, 500);
        }
    }, [startPlay]);
  	const handleClearExperimentData = () => {
		if (expState === EXP_STATE.NOT_STARTED) {
			toast.warn("Эксперимент не начат.");
		} else {
			setExpState(EXP_STATE.NOT_STARTED);
			clearTimer(timerId, setTimerId);
			setRefreshCount(0);
			clearBackwardRays();
		}
	};
	const handlePlayPauseExperiment = () => {
		if (expState !== EXP_STATE.FINISHED) { //stop or continue
			doNextProgressStep(played, setStartPlay, paused, setPaused, expState, setExpState, refreshCount, setRefreshCount, timerId, setTimerId);
		} else { //prepare to new experioment:
			handleClearExperimentData();
			setExpState(EXP_STATE.STARTED);
			const _timerId = setInterval(() => workProgress(setRefreshCount), FREQUENCY_IN_MSEC);
			setTimerId(_timerId);
		}
	};

	const handleFinishExperiment = () => {
		cleanExperimentData();
	};

	const cleanExperimentData = () => {
		setExpState(EXP_STATE.NOT_STARTED);
		clearTimer(timerId, setTimerId);
		setRefreshCount(0);
		setPlay(false);
		setPaused(false);
		setStartPlay(false);
		clearBackwardRays();
	};

	const  handleLightRadius = e => {
		let value = Number(e.target.value);
		setLightRadius(value);
		clearBackwardRays();
	};

	const handleRefraction = e => {
		let value = Number(e.target.value);
		setRefraction(value);
		e.target.style.setProperty('--val', value);
		setSelectedMaterial(0);
		clearBackwardRays();
	}
	
	const handleSelectedMaterial = value => { 
		setSelectedMaterial(value);
		setRefraction(Number(value));
		clearBackwardRays();
	};

	const getMaterialNameById = id => {
		const elem = materials.find(item => item.value === id);
		if (!elem) return '';
		const name = elem.label.split(' ')[0];
		return name;
	};

	const  handleLenseRadius = (e, lenseInd) => {
		let value = Number(e.target.value);
		if (lenseInd === LENSE_LEFT_INDEX) 
			setLenseLeftRadius(value);
		else 
			setLenseRightRadius(value);
		clearBackwardRays();
	};

	const handleSelectedLeftLenseType = (value) => {
		doSelectedLenseType(LENSE_LEFT_INDEX, value);
	};

	const handleSelectedRightLenseType = (value) => {
		doSelectedLenseType(LENSE_RIGHT_INDEX, value);
	};

	const doSelectedLenseType = (lenseInd, value) => {
		const setNewLenseType = (currType, newType, prevRadius, currRadius, setPrevRadius, setRadius, setLenseType) => {
			if (currType === LENSE_TYPE_FLAT && newType !== LENSE_TYPE_FLAT) {
				setRadius(prevRadius);
				setPrevRadius(0);
			} else if (currType !== LENSE_TYPE_FLAT && newType === LENSE_TYPE_FLAT) {
				setPrevRadius(currRadius);
				setRadius(0);
			}
			setLenseType(newType);
		};

		const newType = Number(value);

		if (lenseInd === 1) 
			setNewLenseType(lenseLeftType, newType, lensePrevLeftRadius, lenseLeftRadius, 
				setLensePrevLeftRadius, setLenseLeftRadius, setLenseLeftType);
		else 
			setNewLenseType(lenseRightType, newType, lensePrevRightRadius, lenseRightRadius, 
				setLensePrevRightRadius, setLenseRightRadius, setLenseRightType);

		clearBackwardRays();
	}; 
	
	const getLenseFormOpt = (lenseInd)	=> {
		const lenseRadiusData = {
			value: lenseInd === LENSE_LEFT_INDEX ? lenseLeftRadius : lenseRightRadius,
			ref: lenseInd === LENSE_LEFT_INDEX ? lenseLeftRadiusRef : lenseRightRadiusRef, 
			handleChange: handleLenseRadius,
			min: LENSE_RADIUS_RANGE[0],
			max: LENSE_RADIUS_RANGE[1],
			step: 0.01,
			setValue: lenseInd === LENSE_LEFT_INDEX ? setLenseLeftRadius : setLenseRightRadius,
			key: 'radius01',
			handleInput: lenseInd,
			disabled: !isInputRangeEnabled() || 
				(lenseInd === LENSE_LEFT_INDEX && lenseLeftType === LENSE_TYPE_FLAT) ||
				(lenseInd === LENSE_RIGHT_INDEX && lenseRightType === LENSE_TYPE_FLAT) ,
		};

		return (
		<>
		<div className="calcModel__rangeCol">
			<div className="calcModel__flexColumn calcModel__flexColumnCoil">
				<div className="calcModel__label calcModel__stretch calcModel__stretchLeft">
					<span> 
						<b><Icon name={lenseInd === LENSE_LEFT_INDEX ? "lens-left" : "lens-right"} className="calcModel__buttonIcon1 calcModel__buttonIconLense1"/></b>
					</span>
					<span><b>{lenseInd === LENSE_LEFT_INDEX ? 'Левая' : 'Правая'}&nbsp;&nbsp;</b></span>
					<span className="calcModel__range calcModel__rangeCmf1"> 
						{getDropdown(lenseTypes, lenseInd === LENSE_LEFT_INDEX ? lenseLeftType : lenseRightType, 
							lenseInd === LENSE_LEFT_INDEX ? handleSelectedLeftLenseType : handleSelectedRightLenseType, 
							'otCreate__Select otCreate__SelectValues8', 'otCreate__DropDownItem', 'lense01'+lenseInd)}
					</span>
				</div>
			</div>	

			<div className="calcModel__range calcModel__rangeCmf">
				<div className="calcModel__label">{(lenseInd === LENSE_LEFT_INDEX ? 'Левая' : 'Правая') + ' сторона линзы с радиусом кривизны '} 
					<b>R={getRoundValue(lenseInd === LENSE_LEFT_INDEX ? lenseLeftRadius : lenseRightRadius, 2)} мм</b>
				</div>
				{getInputRange(lenseRadiusData)}
			</div>
		</div>
		</>
		);
	};

	const getLenseFormOpts = () => {
		return (
			<div className="calcModel__stretch">
				{getLenseFormOpt(LENSE_LEFT_INDEX)}
				{getLenseFormOpt(LENSE_RIGHT_INDEX)}
			</div>
		);
	};

	const getRefractionOpts = () => {
		const refractionData = {
			value: refraction,
			ref: refractionRef,
			handleChange: handleRefraction,
			min: REFRACTION_RANGE[0],
			max: REFRACTION_RANGE[1],
			step: 0.01,
			setValue: setRefraction,
			className: "calcModel__inputRangeRefraction",
			key: 'refr01',
			disabled: !isInputRangeEnabled(),
		};
		const lightRadiusData = {
			value: lightRadius,
			ref: lightRadiusRef,
			handleChange: handleLightRadius,
			min: LIGHT_RADIUS_RANGE[0],
			max: LIGHT_RADIUS_RANGE[1],
			step: 0.01,
			setValue: setLightRadius,
			key: 'light01',
			disabled: !isInputRangeEnabled(),
		};

		return (
			<div className="calcModel__stretch">
				<div className="calcModel__flexColumn calcModel__flexColumnCoil calcModel__lenseColumnOpts1">
					<div className="calcModel__range calcModel__label calcModel__stretch calcModel__stretchLeft"> 
						<span className="calcModel__label calcModel__labelMaterial1">Материал&nbsp;</span>
						<span className="calcModel__label">
							{getDropdown(materials, selectedMaterial, handleSelectedMaterial, 
								'otCreate__Select otCreate__SelectValues8', 'otCreate__DropDownItem', 'mater01', "top")}
						</span>
					</div>

					<div className={"calcModel__range calcModel__rangeCmf "+ 
							(lenseLeftType === LENSE_TYPE_CONCAVE || lenseRightType === LENSE_TYPE_CONCAVE ? "calcModel__Hidden" : "")}>
						Толщина линзы <b>D={getRoundValue(lenseLeftRadius+lenseRightRadius, 2)} мм</b>
					</div>
				</div>
				<div className="calcModel__flexColumn calcModel__flexColumnCoil calcModel__lenseColumnOpts2">
					<div className="calcModel__range calcModel__rangeCmf"> 
						<div className="calcModel__label">Показатель преломления материала линзы <b>n={getRoundValue(refraction, 2)}</b></div>
						{getInputRange(refractionData)}
					</div>
					<div className="calcModel__range calcModel__rangeCmf"> 
						<div className="calcModel__label">Диаметр падающего пучка <b>D={getRoundValue(lightRadius, 2)} мм</b></div>
						{getInputRange(lightRadiusData)}
					</div>
				</div>
			</div>
 		);
	};

	const getButtons = () => {
		return (
		<>
			<div>
				<span className="calcModel__leftBottomBtn">
					{/* (expState === EXP_STATE.NOT_STARTED ? "Unsel" : "Sel") не понял для чего это условие нужно было в css классе */}
					<Button
						onClick={handleClearExperimentData}
						border={true}
						color="primary"
						className="cor_btn_icon_left"
						size="extraSmall"
						>
						<Icon name="trash"/>
						Очистить
					</Button>
				</span>
				<span className={lenseLeftType === LENSE_TYPE_CONCAVE || lenseRightType === LENSE_TYPE_CONCAVE ? "calcModel__leftBottomBtn" : "calcModel__Hidden"}>
					<span className="calcModel__nowrap calcModel__nowrapTxt">
					<input type="checkbox" key={'rays01'}
						checked={showBackwardRays ? "checked" : ""} onChange={() => setShowBackwardRays(!showBackwardRays)} />
						&nbsp;Показывать обратные лучи
						</span>
				</span>
			</div>

			{getPlayPauseExperiment(isLightMode, startPlay, played, paused, currProgressInSec, handleFinishExperiment, handlePlayPauseExperiment)}

			<ActionFile 
				profile={handleProfile}
				experiment={handleExportExperiment}
				isInnerCM={isInnerCM}
				print={handlePrint}
			/>
		</>
		);
	};

    return (
		<>
			{getHeaderBox(history, match.params.id, isInnerCM, 'Фокусное расстояние линзы')}
			<Content>
				<ContentBody scrolled={false}>
					<div id="idInnerMainBox" ref={refMainBox} className="calcModel">
							<div id={idMainSvg} ref={refMainSvg} className="calcModel__coil calcModel__lense"
								style={{width: svgWidth, height: svgHeight}} 
								>
								{getSvg(svgArr)}
							</div>
							<div id="idCommon" className="calcModel__opts">
								{getLenseFormOpts()}
								{getRefractionOpts()}
							</div>
					</div>
				</ContentBody>
				<div className="calcModel__indent"></div>
				<ContentFooter className="calcModel__footer">{getButtons()}</ContentFooter>
			</Content>

			{showConfirmSaveXlsDlg && 
			<SaveXlsFileConfirmDialog
				showConfirmSaveXlsDlg={showConfirmSaveXlsDlg}
				setShowConfirmSaveXlsDlg={setShowConfirmSaveXlsDlg}
				saveExport={saveExport}
				fileName={'Фокусное расстояние линзы'}
				handleCancelSave={handleCancelSave}
			/>
			}
		</>
    );
} 

export default Lense;
