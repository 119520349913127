import React, {useState, useEffect} from "react";
import { Back, Content, ContentBody, ContentHead, ContentHeader, ContentTitle } from "../template/ContentParts";
import {useSelector, useDispatch} from "react-redux";
import {isAuth} from "../../helpers/auth";
import {storageClass, storageOrg} from "../../redux/slices/storage";
import {fetchclass} from "../../redux/slices/classsline";
import {FEATURES} from "../../api/config";
import ScenarioResultDetailDlg from "./ScenarioResultDetailDlg";
import socket from "../../socket";
import {DB_REFRESH, chkItemDate, getClassStudents} from "../ui/utils/gen_utils";
import {rlsApi, rlsiApi} from "../../api/api";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import {PROFILE_TAB}  from "../template/Dashboard/DashboardProfile";
import Table from "../ui/Table/Table";
import {printOnlyDate} from "../../helpers/text";
import {fullName} from "../../helpers/auth";
import { FilterByDate, FilterByText } from "../ui";

const ScenarioResultList = ({history}) => {
	const [idBeDeleted, setIdBeDeleted] = useState(undefined);
	const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [showConfirmDlg, setShowConfirmDlg] = useState(false);
    const [resultData, setResultData] = useState([]);
    const [filteredResultData, setFilteredResultData] = useState([]);
	const [testFilter, setTestFilter] = useState('');
	const [startDate, setStartDate] = useState(null);
	const [finishDate, setFinishDate] = useState(null);
    const [showTestDetails, setShowTestDetails] = useState(false);
    const [testScenarioInfoId, setTestScenarioInfoId] = useState(undefined);
    const [testPersonId, setTestPersonId] = useState(undefined);
	const [isUpdated, setIsUpdated] = useState(false);
    const [counter, setCounter] = useState(0);

    const documentOrg = useSelector(storageOrg);
	const documentClass = useSelector(storageClass);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchclass(isAuth()._id));
    }, [dispatch, documentOrg._id]);

	useEffect(() => {
        socket.on("FE-refresh-db", ({type}) => {
            if (type === DB_REFRESH.SCI) setCounter(counter => counter + 1);
        });
        return () => {socket.off("FE-refresh-db");};
    }, []);

	useEffect(() => {
		const loadScenarioInfosData = (studentList, cl) => {
			const studentIds = studentList.map(item => item.owner).join('|');
	
			rlsiApi.getAllRlScenarioInfosByAllClassStudents(studentIds).then(({data}) => {
				rlsApi.getScenarios(cl).then(scenarioData => {
					const list = [];
					for (let i = 0; i < data.length; i++) {
						const item = data[i];
	
						list.push({
							author: fullName(item.owner, ''),
							scenarioLink: scenarioData.find(elem => elem._id === item.scenario)?.name,
							date: printOnlyDate(item.updatedAt),
							score: !!item.score ? item.score.toString() : '',
							reportArea: item.report?.answer ? item.report.answer : 'Нет отчета',
							actions: '',
							id: item.owner._id + '|' + item._id,
							fullDate: item.updatedAt,
							personPict: studentList.find(v => v.owner === item.owner._id)?.personPict
						});
					}
					setResultData(list);
				});
			});
		};
	
		const _studentList = getClassStudents(documentClass.classUsers);
		if (_studentList.length > 0)
			loadScenarioInfosData(_studentList, documentClass._id);
		if (isUpdated)
			setIsUpdated(false);
    }, [documentClass.classUsers, documentClass._id, isUpdated, counter]);

	useEffect(() => {
		const data = resultData.filter((row) => (Object.values(row).slice(0, -1).some(
				v => v.toLowerCase().includes(testFilter.toLowerCase()))) &&
				(!startDate || chkItemDate(row.fullDate, startDate, true)) &&
				(!finishDate || chkItemDate(row.fullDate, finishDate, false))
			);
		setFilteredResultData(data);
	}, [startDate, finishDate, testFilter, resultData]);

    const getPersonId = (idArr) => idArr.split('|')[0];
	const getScenarioInfoId = (idArr) => idArr.split('|')[1];

	useEffect(() => {
		if (!deletionConfirmed) return;
		rlsiApi.deleteScenarioInfoById(idBeDeleted).then(()=>{
			setDeletionConfirmed(false);
			setIdBeDeleted(undefined);
			setIsUpdated(true);
			socket.emit('BE-refresh-db', {type: DB_REFRESH.SCI, roomId: documentClass._id});
		});
	}, [deletionConfirmed, idBeDeleted, documentClass]);

	const handleDelete = ids => {
		const id = getScenarioInfoId(ids);
		setIdBeDeleted(id);
		setShowConfirmDlg(true);
	};
	const handleDeleteNo = () => {
		setShowConfirmDlg(false);
		setIdBeDeleted(undefined);
	};
	const handleDeleteYes = () => {
		setShowConfirmDlg(false);
		setDeletionConfirmed(true);
	};

	const handlePerson = ids => {
		history.push(FEATURES['profile'].to + '/' + getPersonId(ids) + '/' + PROFILE_TAB.SCENARIOS);
	};

	const handleDetails = (ids) => {
		setTestScenarioInfoId(getScenarioInfoId(ids));
		setTestPersonId(getPersonId(ids));
		setShowTestDetails(true);
	};

	const handleScoreUpdate = async (score, ids) => {
		const id = getScenarioInfoId(ids);
		const scoreVal = score !== '' ? Number(score) : null;
		await rlsiApi.updateRlScenarioInfoScoreById(id, {score: scoreVal})
			.then(res => setIsUpdated(true));
		socket.emit('BE-refresh-db', {type: DB_REFRESH.SCI, roomId: documentClass._id});
	};			

    const getTable = () => {
		const headerRunning = [
			{column: 'Имя ученика', name: 'author', style: { width: '20%'} }, 
			{column: 'Сценарий', name: 'scenarioLink', style: { width: '20%'} }, 
			{column: 'Отчет', name: 'reportArea', style: { width: '15%'} }, 
			{column: 'Дата сдачи', name: 'date', style: { width: '15%'} }, 
			{column: 'Оценка', name: 'score', style: { width: '15%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '15%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];

	return (
			<Table 
				table={{
					header: headerRunning, 
					data: filteredResultData,
					handleTableDataUpdate: handleScoreUpdate, 
				}}
				person={{handlePerson: handlePerson}}
                link={{handleLink: ids => handleDetails(ids)}}
				sort={{
					hasSorting: true, 
					initSortInd: -3,
				}}
				actions={{
					handleDelete: ids => handleDelete(ids),
				}}
				canEditScore={() => true}
			/>
		);
    };

    return  (
        <>
			<ContentHead flex={false}>
				<Back onClick={() => history.goBack()} icon="back" margin="bottom"/>
				<ContentTitle>Сценарии на проверке</ContentTitle>
			</ContentHead>
		
			<Content>
				<ContentHeader>
					<div className="cor-net__row">
						<div className="cor-net__col col-3">
							<FilterByText
								placeholder={'Введите название или тему задания'}
								value={testFilter}
								setSearch={setTestFilter}
							/>
						</div>
						<div className="cor-net__col col-grow"></div>
						<div className="cor-net__col">
							<FilterByDate 
								startDate={startDate}
								setStartDate={setStartDate}
								finishDate={finishDate}
								setFinishDate={setFinishDate}
							/>
						</div>
					</div>
				</ContentHeader>
				
				<ContentBody>
					{getTable()}
				</ContentBody>
			</Content>
			
			{showConfirmDlg &&
			<ModalConfirmDialog
				showConfirmDlg={showConfirmDlg} 
				handleNo={handleDeleteNo}
				handleYes={handleDeleteYes}
				question={'Вы действительно хотите удалить эту работу?'}
			/>
			}

			{showTestDetails &&
			<ScenarioResultDetailDlg 
				showDlg={showTestDetails}
				setShowDlg={setShowTestDetails}
				selectedStudentId={testPersonId}
				scenarioInfoId={testScenarioInfoId}
				setIsUpdated={setIsUpdated}
			/>
			}
        </>
    );
}

export default ScenarioResultList;