import React, { useState, useRef, useEffect } from 'react';
import { Button, Icon } from '../ui';
import PIOneName from './PIOneName';
import {TABLE_HEADER, INVEST_MODE_TAB, BLANK_FILE, uploadPIFile, getViewTableContent, 
        getCanvasId, getDrawToolImageId, getSizeUnitList, getUnitName, getPiUrl,
        calcImageSize, getTableByMeasures} from "./pi_utils";
import { getDropdown } from "../ui/utils/gen_utils";
import {toast} from "react-toastify";
import { MEASURE_TYPE, DELETE_ALL_MEASURES_MARKER, IMAGE_SHIFT_X, IMAGE_SHIFT_Y, 
    DRAG_ACTION, DEFAULT_SCALE_UNIT_ID, ICON_ACTION,
    getMeasure, findActiveFigure, dragFigure, drawFigures, findFigureIconAction,
    getEmptyFigureOpts, areEqualEmptyFigureOpts, recalcMeasures
    } from '../../rlab/src/components/VideoEditor/utils';
import ModalInputDialog from "../ui/ModalDialogs/ModalInputDialog";
import PIDrawToolDetailsDlg from "./PIDrawToolDetailsDlg";
import {getScreenAreaPictureById} from "../CalcModels/cm_utils";	
import { drawImageScales } from '../../rlab/src/components/VideoEditor/scale_utils';
import '../../rlab/src/components/VideoEditor/VideoEditor.scss';

const DEFAULT_SCALE_SIZE = 1;
const CAN_ADD_COMMENT = true;

const PIOneDrawTool = (
    {isEdit, //is edit mode (true = проведение, false - просмотр)
        uid, id, isTemplate,
        workDrawTool, setWorkDrawTool, 
        drawToolIndex, 
        doDeleteHideSection, 
        drawToolImages, setDrawToolImages,
        tabInd
    }
) => {
	const [resize, setResize] = useState(0);
	const [isResized, setIsResized] = useState(false);
    const [imageSize, setImageSize] = useState([100, 100]);
    const [currScale, setCurrScale] = useState(DEFAULT_SCALE_SIZE);
    const [currUnitId, setCurrUnitId] = useState(DEFAULT_SCALE_UNIT_ID);
    const [isCurrScaleChanged, setIsCurrScaleChanged] = useState(false);
    const [selectedActionType, setSelectedActionType] = useState(MEASURE_TYPE.RECT);
    const [dragging, setDragging] = useState(false);
    //table with data:
    const [tableRows, setTableRows] = useState([]);
    //current figure:
    const [selectedFigureOpts, setSelectedFigureOpts] = useState(getEmptyFigureOpts(selectedActionType));
    const [currFigureRect, setCurrFigureRect] = useState(null);
    const [loadedFileName, setLoadedFileName] = useState('');
    const [loadedTemplateFileName, setLoadedTemplateFileName] = useState('');
    const [isUploadFile, setIsUploadFile] = useState(false);
    const [comment, setComment] = useState('');
    const [measureInd, setMeasureInd] = useState(-1);
    const [showCommentDlg, setShowCommentDlg] = useState(false);
    const [needToRecalc, setNeedToRecalc] = useState(false);
    //
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [showDetailsDlg, setShowDetailsDlg] = useState(false);

	const mainBoxRef = useRef(null);
    const canvasRef = useRef(null);
    const signatureListRef = useRef(null);

    useEffect(() => {
        if (isEdit && isImageLoaded) setIsImageLoaded(false);
    }, [isEdit, isImageLoaded]);

    useEffect(() => {
        setIsUploadFile(false);
    }, [uid]);

	useEffect(() => {
		const handleResize = ev => setResize([window.innerWidth]);
		window.addEventListener('resize', ev => handleResize(ev));
		return () => { window.removeEventListener("resize", handleResize);}
	},[]);
	useEffect(() => {
        const w = window.innerWidth;
        if (w !== resize && !isResized) {
          setResize(w);
          setIsResized(true);
        }
	},[resize, isResized]);

    useEffect(() => { //изменить размер экрана
        if (!workDrawTool?.image) return;
        const imageData = workDrawTool.image;

        let size;
        if (!imageData.frameData) { //Изображение не загружено
            //is it used??
            const mainAreaR = canvasRef.current.getBoundingClientRect();
            const width = mainAreaR.width;
            size = [Math.floor(width - IMAGE_SHIFT_X), Math.floor(0.65 * width - IMAGE_SHIFT_Y)];
        } else {  //Изображение загружено
            size = calcImageSize(imageData.naturalWidth, imageData.naturalHeight, mainBoxRef.current);
        }
    
        if (isResized) {
            setIsResized(false);
            if (isEdit)
                setNeedToRecalc(true);
        }
        
        setImageSize(size);
	},[isResized, workDrawTool, isEdit]);

    //Cохраненные картинки ---
    useEffect(() => { //загрузить сохраненную картинку
        const loadPicture = async () => {
            if (isUploadFile) return;
            let fileName = '';
            if (!workDrawTool.image?.name) { //1/3 - загружаем пустой файл
                //fileName = BLANK_FILE;
            } else if (isTemplate) { //2/3 - загружаем картинку шаблона
                fileName = workDrawTool.image.frameData; //indeed it is content, not name
            } else { //3/3 - загружаем картинку из хранилища
                const fileUrl = await getPiUrl(workDrawTool.image.name);
                if (!!fileUrl) {
                    fileName = workDrawTool.image.name;
                }
            }
    
            if (fileName) {
                setLoadedFileName(fileName);
                setIsUploadFile(true); //файл загружаем только один раз. Сюда больше не приходим.
            }
        };

        loadPicture();
    }, [workDrawTool, isUploadFile, isTemplate]);


    //step 1 of 2: загрузить новую картинку
    const handleAddFile = (event) => {
        if (!isTemplate) {
            uploadPIFile(event.target.files[0], setLoadedFileName);
        } else {
            setLoadedTemplateFileName(event.target.files[0]); //keep the template file name and content
        }
        event.target.value = '';
    };

    //step 2 of 2: или показываем сохраненные картинки или загружаем новые картинки шаблонов
    useEffect(() => {
        const showImage = (fileName, content) => {
            setLoadedFileName('');
            setLoadedTemplateFileName('');
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
    
            const image = new Image();
            image.src = content;
            image.crossOrigin = 'Anonymous'; //get image from canvas - step 1 of 3
    
            image.onload = () => {
                const imgSize = calcImageSize(image.naturalWidth, image.naturalHeight, mainBoxRef.current);
                context.drawImage(image, IMAGE_SHIFT_X, IMAGE_SHIFT_Y, imgSize[0], imgSize[1]);
                const currImageData = workDrawTool.image;
    
                const imgData = {
                    name: fileName,
                    frameData: content,
                    naturalWidth: image.naturalWidth,
                    naturalHeight: image.naturalHeight,
                    measureList: currImageData.measureList,
                };
                updateWDT(workDrawTool, setWorkDrawTool, drawToolIndex, imgData);
            };
        };

        const prepareShowImage = async () => {
            if (!loadedFileName && !loadedTemplateFileName) return;
        
            if (loadedFileName === BLANK_FILE) { //1/4 - показать пустую картинку
                showImage('', loadedFileName);
            } else if (!isTemplate) { //2/4 - показать картинку из хранилища
                const fileUrl = await getPiUrl(loadedFileName);
                if (!fileUrl) { 
                    return; //возможно, files еще не содержит новый файл loadedFileName. Ждем перезагрузки files
                }
                showImage(loadedFileName, fileUrl);
            } else { //TEMPLATE: 2 cases - 
                if (loadedTemplateFileName) { //3/4 - загружаем новый файл шаблона
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        showImage(loadedTemplateFileName.name, event.target.result);
                    };
                    reader.readAsDataURL(loadedTemplateFileName); //if isTemplate the param contains the file (not just its name)
                } else { //4/4 - показываем загруженный файл шаблона
                    const imageData = workDrawTool.image;
                    showImage(imageData.name, imageData.frameData);
                }
            }
        };

        prepareShowImage();
    },[drawToolIndex, imageSize, loadedFileName, loadedTemplateFileName,
        workDrawTool, setWorkDrawTool, isEdit, isTemplate]);

    useEffect(() => {
        //перерисовка картинки, шкалы, фигур и т.д.
        if (!workDrawTool?.image) return;
        if (loadedFileName || loadedTemplateFileName) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        if (isCurrScaleChanged) {
            ctx.clearRect(0, 0, canvas.width + IMAGE_SHIFT_X, canvas.height + IMAGE_SHIFT_Y);
            setIsCurrScaleChanged(false);
        }

        const unitName = getUnitName(currUnitId);
        const imageData = workDrawTool.image;
        const image = new Image();
        image.src = imageData.frameData;
        image.crossOrigin = 'Anonymous';
           
        image.onload = () => {
            ctx.drawImage(image, IMAGE_SHIFT_X, IMAGE_SHIFT_Y, canvas.width, canvas.height);
            drawFigures(ctx, imageData, unitName, currFigureRect, dragging, selectedFigureOpts, 
                false, signatureListRef, imageSize, CAN_ADD_COMMENT);
            drawImageScales(ctx, IMAGE_SHIFT_X, IMAGE_SHIFT_Y, canvas.width, canvas.height, currScale);
            //console.log('has printFrameData=', !!workDrawTool.image.printFrameData);

            if (!workDrawTool.image.printFrameData && tabInd === INVEST_MODE_TAB.VIEW) { 
                //save data for printing
                const printFrameData = canvas.toDataURL('image/img');
                const imgData = {...workDrawTool.image, printFrameData: printFrameData};
                updateWDT(workDrawTool, setWorkDrawTool, drawToolIndex, imgData);
                //console.log('save - picture and FIGURES')
            }
        };
    }, [setWorkDrawTool, drawToolIndex, currFigureRect, dragging, selectedFigureOpts,
        currScale, currUnitId, isCurrScaleChanged, imageSize, tabInd,
        loadedFileName, loadedTemplateFileName]); //don't add workDrawTool

    useEffect(() => {
		const addImage = (uid, img) => {
			const dti = [...drawToolImages];
            const imgTag = '<img' + 
            ' key="' + getDrawToolImageId(uid) + '" ' + 
            ' alt="" width="' + imageSize[0] + '" height="' + imageSize[1] + '" ' + 
            ' src="' + img + '">' + 
            '</img>';
			dti.push({uid: uid, img: imgTag});
			setDrawToolImages(dti);
		};

        if (isEdit || !isImageLoaded) return;  //get image from canvas - step 2 of 3 (set isImageLoaded = true)
        const found = drawToolImages.find(item => item.uid === uid);
        if (found) return;

        getScreenAreaPictureById(getCanvasId(uid), img => addImage(uid, img));  //get image from canvas - step 3 of 3
    }, [isEdit, drawToolImages, imageSize, setDrawToolImages, uid, isImageLoaded]);

    useEffect(() => {
        if (!needToRecalc) return;
        setNeedToRecalc(false);
        const innerImageSize = getInnerImageScale(imageSize);
        const measureList = workDrawTool.image.measureList;
        const newMeasureList = recalcMeasures (measureList, innerImageSize, currScale);
        const imgData = {...workDrawTool.image, measureList: newMeasureList};
        updateWDT(workDrawTool, setWorkDrawTool, drawToolIndex, imgData);
    }, [needToRecalc]); //don't add others

    const getImageData = () => workDrawTool.image;
    const updateWDT = (_workDrawTool, _setWorkDrawTool, _drawToolIndex, imageData) => {
        const wdt = {..._workDrawTool, image: imageData};
        _setWorkDrawTool(_drawToolIndex, wdt);
    };

    const updateDrawToolByName = (isSave, title) => {
        if (isSave) {
            const wdt = {...workDrawTool, title: title};
            setWorkDrawTool(drawToolIndex, wdt);
        }
    };

    useEffect(() => {
        if (workDrawTool.image)
            setTableRows(getTableByMeasures(currUnitId, workDrawTool.image.measureList));
    }, [currUnitId, workDrawTool]);

    const handleSaveMeasureComment = (_comment, measureInd) => {
        const measureList = getImageData().measureList;
        const measure = measureList[measureInd];
        const updMeasure = {...measure, comment: _comment};
        const list = measureList.map((item, ind) => ind !== measureInd ? item : updMeasure);

        const imgData = {...getImageData(), measureList: list};
    
        updateWDT(workDrawTool, setWorkDrawTool, drawToolIndex, imgData);
        setComment('');
    };

    const handleMouseDown = (e) => {
        if (!isEdit) return;
        if (getImageData().frameData === BLANK_FILE) {
            toast.warn("Выберите изображение");
            return;
        }

        const { offsetX, offsetY } = e.nativeEvent;

        if (!!selectedFigureOpts.dragAction) {
            // found when mouse move
            if (selectedFigureOpts.figureInd !== -1) {
                const measure = getImageData().measureList[selectedFigureOpts.figureInd];
                setCurrFigureRect(measure.rectangle);
                setDragging(true);
            }
        } else {
            //const figureInd = findFigureBeDeleted(offsetX, offsetY, signatureListRef);
            const figureActionOpts = findFigureIconAction(offsetX, offsetY, signatureListRef);
            if (!!figureActionOpts) { //can add/edit a comment or delete the figure
                const figureInd = figureActionOpts.figureIndex;
                const iconAction = figureActionOpts.iconAction;
                if (iconAction === ICON_ACTION.EDIT) {
                    const measure = getImageData().measureList[figureInd];
                    setMeasureInd(figureInd);
                    setComment(measure.comment);
                    setShowCommentDlg(true);
                } else { // do deletion:
                    doDeleteMeasure(figureInd);
                }
            } else {
                setSelectedFigureOpts({figureInd: -1, figureActionType: selectedActionType, dragAction: DRAG_ACTION.TOP_LEFT});
                if (offsetX > IMAGE_SHIFT_X && offsetY > IMAGE_SHIFT_Y) { // it is out of the scale place
                    const rect = { x: offsetX, y: offsetY, width: 0, height: 0 }; //new figure depends on ACTION!! change (for circle x,y is center, width is radius)
                    setCurrFigureRect(rect);
                    setDragging(true);
                } 
            }
        }
    };

    const handleMouseMove = (e) => {
        const { offsetX, offsetY } = e.nativeEvent;

        if (!dragging) {
            if (offsetX <= IMAGE_SHIFT_X || offsetY <= IMAGE_SHIFT_Y) { // it is the scale area
                if (selectedFigureOpts.figureInd !== -1) 
                    setSelectedFigureOpts(getEmptyFigureOpts(selectedActionType));
            } else {
                const opts = findActiveFigure(offsetX, offsetY, getImageData(), selectedActionType);
                if (!areEqualEmptyFigureOpts(selectedFigureOpts, opts)) {
                    setSelectedFigureOpts(opts);
                }
            }
        } else {
            const offset0 = [selectedFigureOpts.offsetX, selectedFigureOpts.offsetY];
            dragFigure(offsetX, offsetY, currFigureRect, setCurrFigureRect, offset0, selectedFigureOpts, imageSize);
        }
    };

    const handleMouseUp = () => {
        setDragging(false);

        if (currFigureRect && Math.max(Math.abs(currFigureRect.width), Math.abs(currFigureRect.height)) >= 5) {
            const imgData = getImageInfoWithSavedMeasure(getImageData(), selectedFigureOpts.figureActionType, currFigureRect);
            updateWDT(workDrawTool, setWorkDrawTool, drawToolIndex, imgData);
        }

        setCurrFigureRect(null);
    };

    const getInnerImageScale = (_imageSize) => [_imageSize[0] - IMAGE_SHIFT_X, _imageSize[1] - IMAGE_SHIFT_Y];

    const getImageInfoWithSavedMeasure = (imageData, figureActionType, figureRect) => {
        if (!imageData) return undefined;
        const innerImageSize = getInnerImageScale(imageSize);

        const measure = getMeasure(figureActionType, figureRect, innerImageSize, 1);
        const updImageData = { ...imageData};

        if (selectedFigureOpts.figureInd === -1)  //new figure
            updImageData.measureList.push(measure);
        else { //updated figure
            measure.comment = imageData.measureList[selectedFigureOpts.figureInd].comment;
            updImageData.measureList = updImageData.measureList.map((item, ind) => (ind !== selectedFigureOpts.figureInd ? item : measure));
        }
        return updImageData;
    };

    const doDeleteMeasure = (_selMeasureInd) => {
        const imageData = getImageData();
        const updatedImageData = { ...imageData, measureList: [...imageData.measureList] };
        updatedImageData.measureList = _selMeasureInd !== DELETE_ALL_MEASURES_MARKER ? 
            updatedImageData.measureList.filter((item, ind) => ind !== _selMeasureInd) : [];
        updateWDT(workDrawTool, setWorkDrawTool, drawToolIndex, updatedImageData);
    };

    const handleActionType = at => {
        setSelectedActionType(at);
        setSelectedFigureOpts(getEmptyFigureOpts(at));
    };

    const doSetCurrUnitId = (value) => {
        if (currUnitId === value) return;
        setCurrUnitId(value);
        setNeedToRecalc(true);
    };

    const doSetCurrScale = (value) => {
        if (currScale === value) return;
        setCurrScale(value);
        setNeedToRecalc(true);
    };

    const getScaleOptions = (initScaleValue, scaleValue, setUpdateScaleValue, 
            setSaveScaleValue, unitId, setSaveUnitId) => {
        const handleChangeScaleValue = (val, setUpdateValue) => {
            if (val.match(/^[.0-9]+$/) != null) {
                if (val.toString().length <=5) {
                    if (!isNaN(val) && Number(val)!== 0) {
                        const value = val;
                        setUpdateValue(value);
                        setIsCurrScaleChanged(true);
                    }
                }
            }
        };
        const handleBlurScale = (val, setSaveValue, initValue) => {
            const float = parseFloat(val);
            let valueToSave;
            if (isNaN(float)) valueToSave = initValue;
            else if (float < 0.01) valueToSave = 0.01;
            else if (float > 999) valueToSave = 999;
            else valueToSave = float;
            setSaveValue(valueToSave);
            setIsCurrScaleChanged(true);
        };

        return  (
            <div className={'videoEditor__size'}>
                <span>Размер</span>
                <span>
                    <input type="text" 
                        style={{width: '8em'}}
                        value={scaleValue}
                        onChange={e => handleChangeScaleValue(e.target.value, setUpdateScaleValue)}
                        onBlur={e => handleBlurScale(e.target.value, setSaveScaleValue, initScaleValue)}
                    />
                </span>
                <span>
                    {getDropdown(getSizeUnitList(), currUnitId, val => doSetCurrUnitId(val), '', '', 'sc01')}
                </span>
            </div>
        );
    };

    const getImageTopBar = () => {
        const selectedMeasureClass = (action) => {
            return 'videoEditor__control ' + (action === selectedActionType ? 'active' : '');
        };

        return (
                <>
                    <div className="videoEditor__controls">
                        <div key="title01" onClick={() => handleActionType(MEASURE_TYPE.RECT)}
                            className={selectedMeasureClass(MEASURE_TYPE.RECT)}>
                            <Icon name="square" />
                            Прямоугольник
                        </div>
                        <div key="title02" onClick={() => handleActionType(MEASURE_TYPE.LINE)}
                            className={selectedMeasureClass(MEASURE_TYPE.LINE)}>
                            <Icon name="ruler" />
                            Линейка
                        </div>
                        <div key="title03" onClick={() => handleActionType(MEASURE_TYPE.CIRCLE)}
                            className={selectedMeasureClass(MEASURE_TYPE.CIRCLE)}>
                            <Icon name="protractor" />
                            Радиус
                        </div>
                    </div>

                    <div>
                        {getScaleOptions(DEFAULT_SCALE_SIZE, currScale, doSetCurrScale, doSetCurrScale, 
                            currUnitId, doSetCurrUnitId)}
                    </div>

                    <div>
                        <Button color="primary" size="medium" border={true}  
                            onClick={() => setShowDetailsDlg(true)}>
                            Установка размера изображения
               	        </Button>
                    </div>

                    <div>
                        <label className="cor_btn_add" key='lbl01'>
                            <Icon name="plus_bold" key='lbl02' />
                            <span key='lbl03'>Выберите изображение</span>
                            <input type="file" accept={'image/*'} onChange={(e) => handleAddFile(e)} key='lbl04' />
                        </label>                    
                    </div> 
                </>
            );
    };

    const getCanvas = () => {
        return (
            <div className="videoEditor__content">
                {isEdit && 
                <div className="videoEditor__head">
                    {getImageTopBar()}
                </div>
                }

                <div className="videoEditor__media videoEditor__stretch">
                    <canvas
                        id={getCanvasId(uid)}
                        ref={canvasRef}
                        width={imageSize[0]}
                        height={imageSize[1]}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                    />
                </div>
            </div>
        );
    };

    return (
        <div>
            <div ref={mainBoxRef} className='pi__drawToolFull' id={id}>
                <PIOneName
                    isEdit={isEdit} 
                    initName={workDrawTool.title} 
                    setEditedName={updateDrawToolByName} 
                    uniqueIndex={drawToolIndex}
                    doDeleteHideSection={doDeleteHideSection} 
                />

                <div className={'videoEditor videoEditor_cor'}>
                    {getCanvas()}
                </div>

                {tableRows.length > 0 && getViewTableContent(
                    [], 
                    TABLE_HEADER, 
                    tableRows, 
                    drawToolIndex, 
                    () => {},
                    undefined
                )}
            </div>

            {showCommentDlg &&
            <ModalInputDialog 
                showDlg={showCommentDlg}
                setShowDlg={setShowCommentDlg}
                title={'Комментарий'}
                comment={comment}
                commentInd={measureInd}
                handleSaveText={handleSaveMeasureComment}
            />
            }
            {showDetailsDlg &&
            <PIDrawToolDetailsDlg 
                showDlg={showDetailsDlg}
                setShowDlg={setShowDetailsDlg}
            />
            }
        </div>
    );
};

export default PIOneDrawTool;
